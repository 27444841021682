import React, { Component, Fragment } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import AdvertisingCreativesCarouselSlides from "../advertising-creatives-carouselslides";
import { eventRegister } from "../../services/eventRegister";
import { campaignRegister } from "../../services/campaignRegister";
import { toArray } from "react-emoji-render";
import AdvertisingCreativesAds from "../advertising-creatives-ads";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import AdvertisingCreativesSearch from "../advertising-creatives-search";
import AdvertisingCreativesSearchList from "../advertising-creatives-search-list";
import AdvertisingCreativesSearchAds from "../advertising-creatives-search-ads";
import AdvertisingCreativesSitelinks from "../advertising-creatives-sitelinks";
import Accordion from "../accordion";
import { calls } from "./calls";
import AdvertisingCreativesProducts from "../advertising-creatives-products";
import ReactTooltip from "react-tooltip";
import AdvertisingCreativesCall from "../advertising-creatives-call";
import TargetingLanguages from "../targeting/languages";
import TargetingPlacement from "../targeting/placement";
import TargetingGender from "../targeting/gender";
import TargetingAge from "../targeting/age";
import TargetingWeekdays from "../targeting/weekdays";
import TargetingLocation from "../targeting/location";
import AdvertisingSearchTemplatePreview from "../advertising-search-template-preview";
import Datepicker from "../datepicker";
import { SlideDown } from "react-slidedown";
import AdvertisingSearchTemplate from "../advertising-search-template";
import AdvertisingKeywordsPositiveAdd from "../advertising-keywords-positive-add";
import Keywords from "../../modules/keywords";
import AdvertisingKeywordsNegative from "../advertising-keywords-negative";
import AdvertisingKeywordsNegativeAdd from "../advertising-keywords-negative-add";
import AdvertisingKeywordsNegativeAsset from "../advertising-keywords-negative-asset";
import AdvertisingKeywordsNegativeList from "../advertising-keywords-negative-list";

import AdvertisingKeywordsPositiveItem from "../../components/advertising-keywords-positive-item";
import CSVReader from "react-csv-reader";
import AdvertisingKeywordsIdeas from "../advertising-keywords-ideas";
import AdvertisingKeywordsPositiveList from "../advertising-keywords-positive-list";
import Modal from "../../modules/modal";

var moment = require('moment');

class AdvertisingSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: true,
            settings: {},
            client: {},
            channels: {},
            creatives: {},
            search: {},
            conversion: {},
            site: {},
            error: null,
            errors: [],
            languages: [],
            locations: [],
            template: {},
            search_ideas: "",
            search_selected: "",
            campaign: {},
            keyword_tab: { id: 1, name: 'Selected keywords' },
            order: "desc",
            sort: "index",
            sort_ideas: "monthly_searches",
            order_ideas: 'desc',
            page: 1,
            loaded: false,
            next: false,
            opensearchoverview: false,
            show_templates: true,
            searchsublinks: [
                {
                    id: 1,
                    name: 'Ad templates'
                },
                {
                    id: 2,
                    name: 'Keywords'
                },
                {
                    id: 3,
                    name: 'Negative keywords'
                },
                {
                    id: 4,
                    name: 'Extensions'
                },
                {
                    id: 5,
                    name: 'Targeting'
                },
                {
                    id: 6,
                    name: 'Locations'
                }
            ],
            activesearchsub: 1,
            newTemp: true,
            editKeyword: null,
            open_locations: true
        };
        this.init.data = this.init.data.bind(this);
        this.child = React.createRef();
        this.keyword_list_ref = React.createRef();
        this.keyword_list_negative_ref = React.createRef();
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.step === 'search' && !this.state.loaded) {
            this.init.first();
        }
    }

    componentWillMount() {
        eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        this.init.data();
    }

    init = {
        first: () => {
            let information = campaignRegister.get();
            this.setState({
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                conversion: information ? (information.conversion ? (information.conversion.value ? information.conversion.value : {}) : {}) : {},
                details: information ? (information.details ? (information.details.value ? information.details.value : {}) : {}) : {},
                search: information ? (information.search ? (information.search.value ? information.search.value : {}) : {}) : {},
                budget: information ? (information.budget ? (information.budget.value ? information.budget.value : {}) : {}) : {},
                languages: information ? (information.languages ? (information.languages.value ? information.languages.value : []) : []) : [],
                locations: information ? (information.locations ? (information.locations.value ? information.locations.value : []) : []) : [],
                loaded: true
            }, () => {
                if (this.state.search && !Array.isArray(this.state.search.campaigns)) {
                    this.state.search.campaigns = [];
                    let campaign = {
                        id: Math.floor((Math.random() * 9999999999) + 1),
                        site_links: [],
                        phone_numbers: [],
                        languages: [],
                        shared_budget: false,
                        website: this.state.site.url,
                        display_website: this.state.site.url,
                        name: this.state.details.name,
                        start_date: this.state.details.startDate,
                        end_date: this.state.details.endDate,
                        no_end_date: this.state.details.noEndDate,
                        targeting: {
                            weekdays: {
                                monday: true,
                                tuesday: true,
                                wednesday: true,
                                thursday: true,
                                friday: true,
                                saturday: true,
                                sunday: true,
                                all: true
                            }
                        },
                        budget: {}
                    };

                    for (let channel in this.state.budget) {
                        if (channel === "google" && this.state.channels[channel]) {
                            campaign.budget[channel] = {};
                            if (this.state.budget[channel].daily > 0) {
                                campaign.budget[channel].daily_budget = this.state.budget[channel].daily;
                            }
                            if (this.state.budget[channel].total > 0) {
                                campaign.budget[channel].total_budget = this.state.budget[channel].total;
                            }
                            if (this.state.details.startDate) {
                                campaign.budget[channel].start_date = this.state.details.startDate;
                            }
                            if (this.state.details.endDate) {
                                campaign.budget[channel].end_date = this.state.details.endDate;
                            }
                            if (this.state.details.noEndDate) {
                                campaign.budget[channel].no_end_date = this.state.details.noEndDate;
                            }
                            if (this.state.details.focus && this.state.details.focus.value) {
                                if (this.state.details.focus.value === 'clicks' || this.state.details.focus.value === 'impressions') {
                                    campaign.budget[channel].focus = { name: "Maximize click", value: 'clicks' };
                                }
                                if (this.state.details.focus.value === 'conversion') {
                                    campaign.budget[channel].focus = { name: "Conversion", value: 'conversion' };
                                }
                            }
                        }
                    }

                    this.state.search.campaigns.push(campaign);

                    this.state.search.templates = [];
                    let template = {
                        id: Math.floor((Math.random() * 9999999999) + 1),
                        campaign_id: campaign.id,
                        headline_first_template: { name: 'Keyword', type: 'keyword_insert', bold: true },
                        headline_second_template: { name: 'Headline second', type: 'headline_second' },
                        headline_third_template: { name: 'No third headline', type: 'no_option', bold: true },
                        body: this.state.creatives.body,
                        body_second: this.state.creatives.body_second,
                        template_type: { name: "Expanded text ad", value: "expanded" },
                        adgroup_strategy: { name: "Create an adgroup per keyword", value: "individual" }
                    };
                    this.state.search.templates.push(template);
                    template = {
                        id: Math.floor((Math.random() * 9999999999) + 1),
                        campaign_id: campaign.id,
                        headline_first_template: { name: 'Headline first', type: 'headline' },
                        headline_second_template: { name: 'Headline second', type: 'headline_second' },
                        headline_third_template: { name: 'No third headline', type: 'no_option', bold: true },
                        body: this.state.creatives.body,
                        body_second: this.state.creatives.body_second,
                        template_type: { name: "Expanded text ad", value: "expanded" },
                        adgroup_strategy: { name: "Create an adgroup per keyword", value: "individual" }
                    };
                    this.state.search.templates.push(template);
                    if (this.state.creatives.headline_third && this.state.creatives.headline_third !== "") {
                        template = {
                            id: Math.floor((Math.random() * 9999999999) + 1),
                            campaign_id: campaign.id,
                            headline_first_template: { name: 'Headline first', type: 'headline' },
                            headline_second_template: { name: 'Headline second', type: 'headline_second' },
                            headline_third_template: { name: 'Headline third', type: 'headline_third' },
                            body: this.state.creatives.body,
                            body_second: this.state.creatives.body_second,
                            template_type: { name: "Expanded text ad", value: "expanded" },
                            adgroup_strategy: { name: "Create an adgroup per keyword", value: "individual" }
                        };
                        this.state.search.templates.push(template);
                    }
                    this.setState({
                        search: this.state.search,
                        campaign: campaign
                    }, () => {
                        this.functions.save('search');
                    })
                } else {

                    this.state.search.campaigns = this.state.search.campaigns.map((item) => {
                        if (!item.budget) {
                            item.budget = {};
                            for (let channel in this.state.budget) {
                                if (!item.budget && channel === "google" && this.state.channels[channel]) {
                                    item.budget[channel] = {};
                                    if (this.state.budget[channel].daily > 0) {
                                        item.budget[channel].daily_budget = this.state.budget[channel].daily;
                                    }
                                    if (this.state.budget[channel].total > 0) {
                                        item.budget[channel].total_budget = this.state.budget[channel].total;
                                    }
                                    if (this.state.details.startDate) {
                                        item.budget[channel].start_date = this.state.details.startDate;
                                    }
                                    if (this.state.details.endDate) {
                                        item.budget[channel].end_date = this.state.details.endDate;
                                    }
                                    if (this.state.details.noEndDate) {
                                        item.budget[channel].no_end_date = this.state.details.noEndDate;
                                    }
                                    if (this.state.details.focus && this.state.details.focus.value) {
                                        if (this.state.details.focus.value === 'clicks' || this.state.details.focus.value === 'impressions') {
                                            item.budget[channel].focus = { name: "Maximize click", value: 'clicks' };
                                        }
                                        if (this.state.details.focus.value === 'conversion') {
                                            item.budget[channel].focus = { name: "Conversion", value: 'conversion' };
                                        }
                                    }
                                }
                            }
                        } else {
                            for (let channel in item.budget) {
                                if (!this.state.channels[channel]) {
                                    delete item.budget[channel];
                                }
                            }
                        }
                        return item;
                    });

                    this.setState({
                        search: this.state.search
                    }, () => {
                        let campaign = this.state.search.campaigns[0];
                        this.setState({
                            campaign: campaign
                        });
                    })
                }
            })
        },
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                keywords: information ? (information.keywords ? (information.keywords.value ? information.keywords.value : {}) : {}) : {},
                languages: information ? (information.languages ? (information.languages.value ? information.languages.value : []) : []) : [],
                locations: information ? (information.locations ? (information.locations.value ? information.locations.value : []) : []) : [],
                conversion: information ? (information.conversion ? (information.conversion.value ? information.conversion.value : {}) : {}) : {},
                //search: information ? (information.search ? (information.search.value ? information.search.value : {}) : {}) : {},
            })
        }
    };

    functions = {
        next: () => {
            if (this.renders.errors().length > 0) {
                this.setState({
                    error: true,
                    next: true
                });
            } else {
                this.props.onNext();
            }
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            try {
                this.props.onChange()
            } catch (e) {
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        },
        new: () => {
            let new_item = JSON.parse(JSON.stringify(this.state.campaign));
            new_item.id = Math.floor((Math.random() * 9999999999) + 1);
            new_item.name = this.state.campaign.name;
            new_item.website = this.state.site.url;
            new_item.display_website = this.state.site.url;
            this.state.search.campaigns.push(new_item);
            this.setState({
                campaign: new_item,
                search: this.state.search
            }, () => {
                this.functions.save('search');
            })
        },
        clone: () => {
            let new_item = JSON.parse(JSON.stringify(this.state.campaign));
            new_item.id = Math.floor((Math.random() * 9999999999) + 1);
            new_item.name = this.state.campaign.name + ' clone ';
            let templates = [];
            let keywords = [];
            let negatives = [];
            let site_links = [];
            let phone_numbers = [];
            if (Array.isArray(this.state.search.templates)) {
                this.state.search.templates.map((item) => {
                    if (item.campaign_id === this.state.campaign.id) {
                        let new_template = JSON.parse(JSON.stringify(item));
                        new_template.id = Math.floor((Math.random() * 9999999999) + 1);
                        new_template.campaign_id = new_item.id;
                        templates.push(new_template);
                    }
                });
            }
            if (Array.isArray(this.state.search.keywords)) {
                this.state.search.keywords.map((item) => {
                    if (item.campaign_id === this.state.campaign.id) {
                        let new_keyword = JSON.parse(JSON.stringify(item));
                        new_keyword.id = Math.floor((Math.random() * 9999999999) + 1);
                        new_keyword.campaign_id = new_item.id;

                        //TEMPLATE SPECIFIC KEYWORDS
                        templates.map((template) => {
                            if (template.specific_keywords && template.specific_keywords[item.id]) {
                                template.specific_keywords[new_keyword.id] = new_keyword;
                                delete template.specific_keywords[item.id];
                            }
                        });

                        keywords.push(new_keyword);
                    }
                });
            }
            if (Array.isArray(this.state.search.site_links)) {
                this.state.search.site_links.map((item) => {
                    if (item.campaign_id === this.state.campaign.id) {
                        let new_site_link = JSON.parse(JSON.stringify(item));
                        new_site_link.id = Math.floor((Math.random() * 9999999999) + 1);
                        new_site_link.campaign_id = new_item.id;
                        site_links.push(new_site_link);
                    }
                });
            }
            if (Array.isArray(this.state.search.negatives)) {
                this.state.search.negatives.map((item) => {
                    if (item.campaign_id === this.state.campaign.id) {
                        let new_negative = JSON.parse(JSON.stringify(item));
                        new_negative.id = Math.floor((Math.random() * 9999999999) + 1);
                        new_negative.campaign_id = new_item.id;
                        negatives.push(new_negative);
                    }
                });
            }
            if (Array.isArray(this.state.search.phone_numbers)) {
                this.state.search.phone_numbers.map((item) => {
                    if (item.campaign_id === this.state.campaign.id) {
                        let new_phone_number = JSON.parse(JSON.stringify(item));
                        new_phone_number.id = Math.floor((Math.random() * 9999999999) + 1);
                        new_phone_number.campaign_id = new_item.id;
                        phone_numbers.push(new_phone_number);
                    }
                });
            }
            templates.map((item) => {
                this.state.search.templates.push(item);
            });
            keywords.map((item) => {
                this.state.search.keywords.push(item);
            });
            site_links.map((item) => {
                this.state.search.site_links.push(item);
            });
            negatives.map((item) => {
                this.state.search.negatives.push(item);
            });
            phone_numbers.map((item) => {
                this.state.search.phone_numbers.push(item);
            });
            this.state.search.campaigns.push(new_item);
            this.setState({
                campaign: new_item,
                search: this.state.search
            }, () => {
                this.functions.save('search');
            })
        },
        remove: (campaign, index) => {

            this.state.search.campaigns = this.state.search.campaigns.filter((item) => {
                return item.id !== campaign.id
            });

            if (Array.isArray(this.state.search.templates)) {
                this.state.search.templates = this.state.search.templates.filter((item) => {
                    return item.campaign_id !== campaign.id
                });
            }
            if (Array.isArray(this.state.search.phone_numbers)) {
                this.state.search.phone_numbers = this.state.search.phone_numbers.filter((item) => {
                    return item.campaign_id !== campaign.id
                });
            }
            if (Array.isArray(this.state.search.negatives)) {
                this.state.search.negatives = this.state.search.negatives.filter((item) => {
                    return item.campaign_id !== campaign.id
                });
            }
            if (Array.isArray(this.state.search.site_links)) {
                this.state.search.site_links = this.state.search.site_links.filter((item) => {
                    return item.campaign_id !== campaign.id
                });
            }
            if (Array.isArray(this.state.search.keywords)) {
                this.state.search.keywords = this.state.search.keywords.filter((item) => {
                    return item.campaign_id !== campaign.id
                });
            }

            this.setState({
                //campaign: this.state.campaign,
                search: this.state.search
            }, () => {
                if (this.state.search.campaigns.length > 1 && index > 0) {
                    this.state.campaign = this.state.search.campaigns[index - 1];
                } else {
                    this.state.campaign = this.state.search.campaigns[0];
                }
                this.setState({
                    campaign: this.state.campaign
                }, () => {
                    this.functions.save('search');
                })
            })
        },
        ideas: (language) => {
            if (typeof this.state.campaign === 'object' && typeof this.state.campaign.language === 'object')
                this.state.campaign.ideas = [];
            this.setState({
                loading_ideas: true,
                campaign: this.state.campaign
            }, () => {
                let object = {
                    client: this.state.client.id,
                    page_url: this.state.campaign.website,
                    language_id: this.state.campaign.language.google_id
                };
                calls.getKeywordIdeas(object).then((response) => {
                    this.state.campaign.ideas = response.data;
                    if (Array.isArray(this.state.campaign.ideas)) {
                        this.state.campaign.ideas = this.state.campaign.ideas.filter((item) => {
                            return item.monthly_searches > 50
                        })
                    }
                    for (let i = 0; i < this.state.search.campaigns.length; i++) {
                        if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                            this.state.search.campaigns[i].ideas = this.state.campaign.ideas;
                        }
                    }
                    this.setState({
                        campaign: this.state.campaign,
                        search: this.state.search,
                        loading_ideas: false
                    }, () => {
                        this.functions.save('search');
                    })
                }, (error) => {
                    this.setState({
                        loading_ideas: false
                    })
                });
            })
        },

        setKeywordEdit: (item) => {
            this.setState({
                editKeyword: item
            })
        }
    };

    validators = {};

    renders = {
        subtitle: () => {
            try {
                let string = [];
                return string.join(', ');
            } catch (e) {
                return ""
            }
        },
        characters: (item) => {
            try {
                return item.toString().length
            } catch (e) {
                return 0
            }
        },
        property: (type) => {
            try {
                return this.state.creatives.languages[this.state.creatives.selected_language][type];
            } catch (e) {
                if (type !== 'site_links' && type !== 'extra_headlines') {
                    return ""
                } else {
                    return []
                }
            }
        },
        errors: () => {
            let array = [];
            if (Array.isArray(this.state.search.campaigns)) {
                this.state.search.campaigns.map((item) => {

                    if (!item.name || item.name === "") {
                        array.push({
                            title: 'Campaign title is missing',
                            campaign: item.id,
                            type: 'title',
                            section: 'search',
                            tab: "campaign"
                        });
                    }
                    if (!Array.isArray(item.languages) || (Array.isArray(item.languages) && item.languages.length < 1)) {
                        array.push({
                            title: 'Campaign languages is missing',
                            campaign: item.id,
                            type: 'language',
                            section: 'search',
                            tab: "campaign"
                        });
                    }
                    if (item.budget && typeof item.budget === "object") {
                        for (let key in item.budget) {
                            if (!item.budget[key].disabled && key === "google" && (item.budget[key].daily_budget < 1 || !item.budget[key].daily_budget)) {
                                array.push({
                                    title: 'Daily budget is to low',
                                    campaign: item.id,
                                    channel: key,
                                    type: 'daily_budget',
                                    section: 'search'
                                });
                            }
                        }
                    }
                    if (item.budget && typeof item.budget === "object") {
                        let all_disabled = true;
                        try {
                            for (let key in item.budget) {
                                if (!item.budget[key].disabled && key === "google") {
                                    all_disabled = false;
                                }
                            }
                        } catch (e) {
                        }
                        if (all_disabled) {
                            array.push({
                                title: 'All channels are disabled',
                                campaign: item.id,
                                type: 'channels',
                                section: 'search'
                            });
                        }
                    }


                    //CHECK LOCATIONS
                    let locations = 0;
                    this.state.locations.map((location) => {
                        let include = false;
                        for (let key in item.budget) {
                            let inner_key = "";
                            if (key === "facebook_instagram") {
                                inner_key = "facebook";
                            } else {
                                inner_key = key;
                            }
                            if (location.channels[inner_key] && !location.channels[inner_key].disabled && !item.budget[key].disabled) {
                                include = true;
                            }
                        }
                        if (include) {
                            locations += 1;
                        }
                    });

                    if (item.exclude_locations && Object.keys(item.exclude_locations).filter((key) => {
                        return item.exclude_locations[key]
                    }).length === locations) {
                        array.push({
                            title: 'Minimum one location should be added',
                            campaign: item.id,
                            type: 'geographic',
                            section: 'search',
                            tab: "locations"
                        });
                    }


                    if (Array.isArray(this.state.search.templates)) {
                        this.state.search.templates.filter((template) => {
                            return template.campaign_id === item.id
                        }).map((template) => {

                            if (typeof template.headline_first_template === 'object' && template.headline_first_template.type === "custom" && (typeof template.custom !== 'object' || (typeof template.custom === 'object' && (!template.custom.headline || template.custom.headline === "")))) {
                                array.push({
                                    title: 'Headline is not valid',
                                    campaign: item.id,
                                    template: template.id,
                                    type: 'template_headline',
                                    section: 'search',
                                    tab: "templates"
                                });
                            }
                            if (typeof template.headline_first_template === 'object' && !template.headline_first_template.type) {
                                array.push({
                                    title: 'Headline is not valid',
                                    campaign: item.id,
                                    template: template.id,
                                    type: 'template_headline',
                                    section: 'search',
                                    tab: "templates"
                                });
                            }
                            if (typeof template.headline_first_template === 'object' && template.headline_first_template.type === "custom" && typeof template.custom === 'object' && template.custom.headline && template.custom.headline !== "" && this.renders.invalidCharacters(template.custom.headline)) {
                                array.push({
                                    title: 'Headline contains invalid characters',
                                    campaign: item.id,
                                    template: template.id,
                                    type: 'template_headline',
                                    section: 'search',
                                    tab: "templates"
                                });
                            }
                            if (typeof template.headline_second_template === 'object' && template.headline_second_template.type === "custom" && (typeof template.custom !== 'object' || (typeof template.custom === 'object' && (!template.custom.headline_second || template.custom.headline_second === "")))) {
                                array.push({
                                    title: 'Headline second is not valid',
                                    campaign: item.id,
                                    template: template.id,
                                    type: 'template_headline_second',
                                    section: 'search',
                                    tab: "templates"
                                });
                            }
                            if (typeof template.headline_second_template === 'object' && !template.headline_second_template.type) {
                                array.push({
                                    title: 'Headline second is not valid',
                                    campaign: item.id,
                                    template: template.id,
                                    type: 'template_headline_second',
                                    section: 'search',
                                    tab: "templates"
                                });
                            }
                            if (typeof template.headline_second_template === 'object' && template.headline_second_template.type === "custom" && typeof template.custom === 'object' && template.custom.headline_second && template.custom.headline_second !== "" && this.renders.invalidCharacters(template.custom.headline_second)) {
                                array.push({
                                    title: 'Headline second contains invalid characters',
                                    campaign: item.id,
                                    template: template.id,
                                    type: 'template_headline_second',
                                    section: 'search',
                                    tab: "templates"
                                });
                            }
                            if (typeof template.headline_third_template === 'object' && template.headline_third_template.type === "custom" && (typeof template.custom !== 'object' || (typeof template.custom === 'object' && (!template.custom.headline_third || template.custom.headline_third === "")))) {
                                array.push({
                                    title: 'Headline third is not valid',
                                    campaign: item.id,
                                    template: template.id,
                                    type: 'template_headline_third',
                                    section: 'search',
                                    tab: "templates"
                                });
                            }
                            if (typeof template.headline_third_template === 'object' && !template.headline_third_template.type) {
                                array.push({
                                    title: 'Headline third is not valid',
                                    campaign: item.id,
                                    template: template.id,
                                    type: 'template_headline_third',
                                    section: 'search',
                                    tab: "templates"
                                });
                            }
                            if (typeof template.headline_third_template === 'object' && template.headline_third_template.type === "custom" && typeof template.custom === 'object' && template.custom.headline_third && template.custom.headline_third !== "" && this.renders.invalidCharacters(template.custom.headline_third)) {
                                array.push({
                                    title: 'Headline third contains invalid characters',
                                    campaign: item.id,
                                    template: template.id,
                                    type: 'template_headline_third',
                                    section: 'search',
                                    tab: "templates"
                                });
                            }
                            if ((!template.edit && (!template.body || template.body === "")) || (template.edit && template.temp && (!template.temp.body || template.temp.body === ""))) {
                                array.push({
                                    title: 'Body is not valid',
                                    campaign: item.id,
                                    template: template.id,
                                    type: 'template_body',
                                    section: 'search',
                                    tab: "templates"
                                });
                            }
                            if ((!template.edit && template.template_type && template.template_type.value === "responsive") || (template.edit && template.temp.template_type && template.temp.template_type.value === "responsive")) {
                                if ((!template.edit && typeof template.headline_third_template === 'object' && template.headline_third_template.type === "no_option") ||
                                    (template.edit && template.temp.headline_third_template && template.temp.headline_third_template.type === "no_option")) {
                                    array.push({
                                        title: 'Headline third is not valid',
                                        campaign: item.id,
                                        template: template.id,
                                        type: 'template_headline_third',
                                        section: 'search',
                                        tab: "templates"
                                    });
                                }
                                if ((!template.edit && (!template.body_second || template.body_second === "")) || (template.edit && template.temp && (!template.temp.body_second || template.temp.body_second === ""))) {
                                    array.push({
                                        title: 'Body second is not valid',
                                        campaign: item.id,
                                        template: template.id,
                                        type: 'template_body_second',
                                        section: 'search',
                                        tab: "templates"
                                    });
                                }
                                if ((!template.edit && (Array.isArray(template.extra_descriptions) && template.extra_descriptions.filter((item) => { return item.text === "" }).length > 0)) ||
                                    (template.edit && (Array.isArray(template.temp.extra_descriptions) && template.temp.extra_descriptions.filter((item) => { return item.text === "" }).length > 0))) {
                                    array.push({
                                        title: 'Some extra bodies are invalid',
                                        campaign: item.id,
                                        template: template.id,
                                        type: 'template_body_extra',
                                        section: 'search',
                                        tab: "templates"
                                    });
                                }
                            }
                        })
                    }
                    if (!Array.isArray(this.state.search.templates) || (Array.isArray(this.state.search.templates) && this.state.search.templates.filter((template) => {
                        return template.campaign_id === item.id
                    }).length < 1)) {
                        array.push({
                            title: 'Add minimum one template',
                            campaign: item.id,
                            type: 'templates',
                            section: 'search',
                            tab: "templates"
                        });
                    }
                    if (!Array.isArray(this.state.search.keywords) || (Array.isArray(this.state.search.keywords) && this.state.search.keywords.filter((template) => {
                        return template.campaign_id === item.id
                    }).length < 1)) {
                        array.push({
                            title: 'Add minimum one keyword',
                            campaign: item.id,
                            type: 'keywords',
                            section: 'search',
                            tab: "keywords"
                        });
                    }
                });
            }
            //console.log(array);
            return array;
        },
        markdown: (input) => {
            return { __html: input }
        },
        invalidCharacters: (string) => {
            try {
                var pattern = /[!()/|*@"'_]/;
                return pattern.test(string);
            } catch (e) {
                return true
            }
        },
        checkEmojiHeadline: (item) => {
            try {
                let emoji = false;
                emoji = this.renders.property('headline').match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g);
                return emoji;
            } catch (e) {
                return false
            }
        },
        checkEmojiBody: (item) => {
            try {
                let emoji = false;
                emoji = this.renders.property('body').match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g);
                return emoji;
            } catch (e) {
                return false
            }
        },
        removeEmoji: (input) => {
            try {
                return input.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '');
            } catch (e) {
                return input
            }
        },
        date: (date) => {
            try {
                if (this.state.time) {
                    return moment(date).format('DD MMMM YYYY - LT');
                } else {
                    return moment(date).format('DD MMM YYYY');
                }
            } catch (e) {
            }
        },
        round: (num) => {
            try {
                return Math.round((num + Number.EPSILON) * 100) / 100
            } catch (e) {
                return 0
            }
        },
        baseUrl: (url) => {
            try {
                url = url.replace('https://', "");
                url = url.replace('http://', "");
                url = url.split('/')[0];
                url = 'https://' + url;
                return url;
            } catch (e) {
                return url
            }
        },
        websites: () => {
            let websites = [];
            if (this.state.search && Array.isArray(this.state.search.keywords)) {
                this.state.search.keywords.filter((item) => {
                    return this.state.campaign && item.campaign_id === this.state.campaign.id
                }).map((item) => {
                    websites.push({
                        url: item.url,
                        base: this.renders.baseUrl(item.url),
                        name: item.name,
                        type: "keyword",
                        platform: "search"
                    });
                })
            }
            try {
                websites.map((item) => {
                    if (this.state.campaign.website.toLowerCase() !== item.url.toLowerCase()) {
                        item.not_matching = true;
                    }
                });
            } catch (e) {
            }
            return websites;
        }
    };

    render() {
        return (
            <div className="w-full">
                <div className="flex flex-row mb-3">
                    {
                        this.renders.errors().length < 1 &&
                        <div
                            className="check-sign rounded-full mr-2 md:mr-3 w-8 h-8 bg-green-500 flex items-center justify-center text-white">
                            <FeatherIcon className={"#ffffff"} size={16} icon={'check'} />
                        </div>
                    }
                    <div className="text-2xl font-bold">
                        Step {(this.state.channels.google && (this.state.channels.facebook || this.state.channels.instagram) ? "6" : "5")}:
                        Search campaigns
                    </div>
                </div>
                <div>
                    {
                        this.state.search && Array.isArray(this.state.search.campaigns) && this.state.search.campaigns.length > 0 &&
                        <div className="flex flex-1 flex-row justify-start px-4">
                            {
                                this.state.search.campaigns.map((item, index) => {
                                    return (
                                        <div onClick={() => {
                                            this.setState({
                                                campaign: item
                                            })
                                        }}
                                            className={cx("cursor-pointer overflow-hidden relative max-w-xs bg-white flex items-center hover:text-opacity-100 bg-opacity-50 text-black text-opacity-50 flex-1 items-middle rounded-t-md p-3 text-sm", {
                                                ["bg-opacity-100 text-purple-900 text-opacity-100"]: item.id === this.state.campaign.id,
                                                ["opaxity-25"]: item.id !== this.state.campaign.id,
                                                ["ml-1"]: index !== 0,
                                                ["px-0"]: this.state.search.campaigns.length > 10
                                            })}>
                                            {
                                                this.state.search.campaigns.length < 7 &&
                                                <div className="font-bold">
                                                    <div className={cx("InputContainerTitleIcons")}>
                                                        {
                                                            typeof this.state.campaign.budget === "object" &&
                                                            Object.keys(this.state.campaign.budget).filter((key) => {
                                                                return this.state.channels[key] && key === "google" && !this.state.campaign.budget[key].disabled
                                                            }).map((item) => {
                                                                if (item === 'google') {
                                                                    return (
                                                                        <div className={'w-5 h-5 bg-google-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                                            <img className="w-2" src={require('../../assets/images/google_icon.png')} />
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className={cx("pl-3 pr-3 truncate font-bold", {
                                                ["pl-1"]: this.state.search.campaigns.length > 10,
                                                ["text-red-400"]: this.state.search.campaigns.length > 6 &&
                                                    this.renders.errors().filter((error) => {
                                                        return error.campaign === item.id
                                                    }).length > 0
                                            })}>
                                                {item.name ? item.name : "..."}
                                            </div>
                                            <div className="flex flex-1 justify-end items-center flex-row">
                                                {
                                                    this.renders.errors().filter((error) => {
                                                        return error.campaign === item.id
                                                    }).length > 0 &&
                                                    <div
                                                        className={(item.id === this.state.campaign.id ? "bg-gray-100" : "bg-white") + " close mr-1 w-6 h-6  mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                        <FeatherIcon className="stroke-current" size={15}
                                                            icon="alert-circle" />
                                                    </div>
                                                }
                                                {this.state.search.campaigns.length > 1 &&
                                                    <div
                                                        className={(item.id === this.state.campaign.id ? "bg-gray-100" : "bg-white") + " close w-6 h-6 flex items-center justify-center text-lg rounded-md hover:bg-red-100 hover:text-red-500"}
                                                        onClick={() => {
                                                            this.functions.remove(item, index);
                                                        }}>&times;
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="flex flex-1 justify-start pl-1">
                                <div onClick={() => {
                                    this.functions.clone()
                                }} data-tip={"Clone campaign"}
                                    className="bg-white text-white flex justify-center items-center rounded-t-md cursor-pointer pl-3 pr-3"
                                    style={{ height: '100%' }}>
                                    <div
                                        className={"close w-6 h-6 flex items-center justify-center text-lg rounded-md transition-all duration-200 bg-purple-500 text-white"}>
                                        <FeatherIcon className="stroke-current" size={11}
                                            icon="copy" />
                                        <ReactTooltip />
                                    </div>
                                </div>
                                <div onClick={() => {
                                    this.functions.new()
                                }} data-tip={"New campaign"}
                                    className="ml-1 bg-white text-white flex justify-center items-center rounded-t-md cursor-pointer pl-3 pr-3"
                                    style={{ height: '100%' }}>
                                    <div
                                        className={"close w-6 h-6 flex items-center justify-center text-lg rounded-md transition-all duration-200 bg-purple-500 text-white"}>+
                                    </div>
                                    <ReactTooltip />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="px-4 pb-4 bg-white rounded-md">
                        <div className="pt-4 pb-4">
                            {
                                <div className="overview bg-gray-100 py-4 px-4 rounded-md">
                                    <div className="flex flex-row flex-wrap items-center">
                                        <div>
                                            <h3 className="mb-0 font-bold text-black relative">
                                                Campaign information
                                                {
                                                    this.renders.errors().filter((item) => {
                                                        return (item.tab === 'campaign' || item.type === 'title' || item.type === 'channels' || item.type === 'date') && item.campaign === this.state.campaign.id
                                                    }).length > 0 &&
                                                    <div style={{ right: "-37px" }} className={"bg-white close top-0 mr-1 w-6 h-6  mr-1 flex items-center absolute justify-center text-lg rounded-md bg-white text-red-500"}>
                                                        <FeatherIcon className="stroke-current" size={15} icon="alert-circle" />
                                                    </div>
                                                }
                                            </h3>
                                            {
                                                this.state.campaign && this.state.campaign.budget &&
                                                <p className="text-xxs text-black">
                                                    <span className="font-bold">Channels: </span>{Object.keys(this.state.campaign.budget).filter((key) => { return key === "google" }).join(", ")}{" "}
                                                </p>
                                            }
                                        </div>
                                        <div className="flex flex-1 justify-end">
                                            <button className="h-10 w-10 flex items-center justify-center text-2xl rounded-md transition-all duration-200 bg-purple-500 text-white"
                                                onClick={() => {
                                                    let channel = null;
                                                    for (let key in this.state.campaign.budget) {
                                                        if (key === "google") {
                                                            channel = key;
                                                            break;
                                                        }
                                                    }
                                                    this.setState({
                                                        opensearchoverview: !this.state.opensearchoverview,
                                                        activechannel: channel
                                                    });
                                                }}>
                                                <FeatherIcon className="stroke-current" size={16} icon={'edit'} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {false && !this.state.opensearchoverview && this.renders.errors().filter((item) => {
                                return item.tab === 'campaign' && item.campaign === this.state.campaign.id
                            }).length > 0 &&
                                <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                    <ul className="list-disc">
                                        {
                                            this.renders.errors().filter((item) => {
                                                return item.tab === 'campaign' && item.campaign === this.state.campaign.id
                                            }).map((item, index) => {
                                                return (
                                                    <li className="text-xs text-red-500 font-bold " key={index}>
                                                        {item.title}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            }
                            {
                                this.state.opensearchoverview &&
                                <Modal
                                    title={"Edit campaign information"}
                                    subtitle={this.state.campaign.name}
                                    blueButton={true}
                                    blueButtonText={"Update campaign"}
                                    onBlueButtonClick={() => {
                                        this.setState({
                                            opensearchoverview: false
                                        })
                                    }}
                                    onClose={() => {
                                        this.setState({
                                            opensearchoverview: false
                                        })
                                    }}
                                >
                                    {
                                        <div className="">
                                            <>
                                                {
                                                    <div className="flex flex-1 flex-row">
                                                        <div className="flex flex-1">
                                                            <Input
                                                                title={"Name"}
                                                                sign={false}
                                                                white={false}
                                                                error={this.renders.errors().filter((item) => {
                                                                    return item.type === 'title' && item.campaign === this.state.campaign.id
                                                                }).length > 0}
                                                                value={this.state.campaign.name}
                                                                tight={true}
                                                                type={"text"}
                                                                size={'full'}
                                                                onChange={(value) => {
                                                                    this.state.campaign.name = value.target.value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        if (Array.isArray(this.state.search.campaigns)) {
                                                                            for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.search.campaigns[i].name = this.state.campaign.name;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                search: this.state.search
                                                                            }, () => {
                                                                                this.functions.save('search');
                                                                            })
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.renders.errors().filter((item) => {
                                                        return item.type === 'title' && item.campaign === this.state.campaign.id
                                                    }).length > 0 &&
                                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                        <ul className="list-disc">
                                                            {
                                                                this.renders.errors().filter((item) => {
                                                                    return item.type === 'title' && item.campaign === this.state.campaign.id
                                                                }).map((item, index) => {
                                                                    return (
                                                                        <li className="text-xs text-red-500 font-bold "
                                                                            key={index}>
                                                                            {item.title}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                {
                                                    <div className="flex flex-1 mt-3">
                                                        <Input
                                                            noTitle={false}
                                                            title={'Base website'}
                                                            type={'text'}
                                                            placeholder={'Website'}
                                                            value={this.state.campaign.website}
                                                            size={'full'}
                                                            onChange={(event) => {
                                                                this.state.campaign.website = event.target.value;
                                                                this.setState({
                                                                    campaign: this.state.campaign
                                                                }, () => {
                                                                    if (Array.isArray(this.state.search.campaigns)) {
                                                                        for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                            if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                this.state.search.campaigns[i].website = this.state.campaign.website;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            search: this.state.search
                                                                        }, () => {
                                                                            this.functions.save('search');
                                                                        })
                                                                    }
                                                                });
                                                            }}
                                                            showKeyDown={false}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    this.renders.websites().filter((item) => {
                                                        return item.not_matching
                                                    }).length > 0 &&
                                                    <div className="flex flex-1 bg-yellow-100 mt-3 rounded-md py-4 px-8">
                                                        <ul className="list-disc">
                                                            {
                                                                this.renders.websites().filter((item) => {
                                                                    return item.not_matching && item.platform === "search"
                                                                }).length > 0 &&
                                                                <li className="text-xs text-yellow-500 font-bold">
                                                                    You have {this.renders.websites().filter((item) => {
                                                                        return item.not_matching && item.platform === "search"
                                                                    }).length} keywords not mathing this exact URL
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                {
                                                    <div className="flex flex-1 mt-3 flex-row">
                                                        <div className="flex flex-2 mr-2">
                                                            <Input
                                                                noTitle={false}
                                                                title={'Display website'}
                                                                type={'text'}
                                                                disabled={true}
                                                                placeholder={'Display website'}
                                                                value={this.renders.baseUrl(this.state.campaign.website)}
                                                                size={'full'}
                                                                onChange={(event) => {

                                                                }}
                                                                showKeyDown={false}
                                                            />
                                                        </div>
                                                        <div className="flex flex-1 ml-2 mr-2">
                                                            <Input
                                                                noTitle={false}
                                                                title={'Path 1'}
                                                                type={'text'}
                                                                placeholder={'Path 1'}
                                                                value={this.state.campaign.path1}
                                                                size={'full'}
                                                                onChange={(event) => {
                                                                    this.state.campaign.path1 = event.target.value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        if (Array.isArray(this.state.search.campaigns)) {
                                                                            for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.search.campaigns[i].path1 = this.state.campaign.path1;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                search: this.state.search
                                                                            }, () => {
                                                                                this.functions.save('search');
                                                                            })
                                                                        }
                                                                    });
                                                                }}
                                                                showKeyDown={false}
                                                            />
                                                        </div>
                                                        <div className="flex flex-1">
                                                            <Input
                                                                noTitle={false}
                                                                title={'Path 2'}
                                                                type={'text'}
                                                                placeholder={'Path 2'}
                                                                value={this.state.campaign.path2}
                                                                size={'full'}
                                                                onChange={(event) => {
                                                                    this.state.campaign.path2 = event.target.value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        if (Array.isArray(this.state.search.campaigns)) {
                                                                            for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.search.campaigns[i].path2 = this.state.campaign.path2;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                search: this.state.search
                                                                            }, () => {
                                                                                this.functions.save('search');
                                                                            })
                                                                        }
                                                                    });
                                                                }}
                                                                showKeyDown={false}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="rounded-md bg-gray-100 p-4 pt-0 mt-4">
                                                    {
                                                        this.state.campaign.budget &&
                                                        <div className="mt-3">
                                                            <div className="navigation">
                                                                <nav role="navigation" aria-label="Social sub navigation">
                                                                    <ul className="flex flex-row items-end">
                                                                        {Object.keys(this.state.campaign.budget).filter((key) => {
                                                                            return this.state.channels[key] && key == "google"
                                                                        }).map((key, j) => {
                                                                            return (
                                                                                <li key={j}
                                                                                    className={"capitalize font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.activechannel == key ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            activechannel: key
                                                                                        });
                                                                                    }}>
                                                                                    {key}
                                                                                </li>
                                                                            )
                                                                        })
                                                                        }
                                                                        <li className="flex-grow border-b-2 border-gray-200"></li>
                                                                    </ul>
                                                                </nav>
                                                            </div>
                                                            <div className="bg-white mt-4 rounded-md p-4">
                                                                {
                                                                    this.state.campaign.id && this.state.activechannel &&
                                                                    <div className="">
                                                                        <div className="font-bold text-xs mb-1">
                                                                            Enable channel
                                                                        </div>
                                                                        <div style={{ height: "55px", backgroundColor: "#fcfcfc" }}
                                                                            className="align-middle items-center flex flex-row flex-1 border rounded">
                                                                            <div className="flex flex-row text-xs font-bold pl-4 justify-center items-center">
                                                                                {
                                                                                    this.state.activechannel === "google" &&
                                                                                    <div style={{ marginRight: '3px' }}>
                                                                                        <IconGoogle large={true} noMargin={true} />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    this.state.activechannel === "google" &&
                                                                                    <div className="font-bold text-xs ml-2">Google</div>
                                                                                }
                                                                            </div>
                                                                            <div className="flex align-middle justify-end flex flex-1 items-center pr-4">
                                                                                <Switch
                                                                                    onColor="#1AD5BD"
                                                                                    checked={!this.state.campaign.budget[this.state.activechannel].disabled}
                                                                                    onChange={(checked) => {
                                                                                        this.state.campaign.budget[this.state.activechannel].disabled = !checked;
                                                                                        this.setState({
                                                                                            campaign: this.state.campaign
                                                                                        }, () => {
                                                                                            for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                                if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                                    this.state.search.campaigns[i].budget = this.state.campaign.budget;
                                                                                                }
                                                                                            }
                                                                                            this.setState({
                                                                                                search: this.state.search
                                                                                            }, () => {
                                                                                                this.functions.save('search');
                                                                                            })
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'channels' && item.campaign === this.state.campaign.id
                                                                    }).length > 0 &&
                                                                    <div className="">
                                                                        <div
                                                                            className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                                            <ul className="list-decimal">
                                                                                {
                                                                                    this.renders.errors().filter((item) => {
                                                                                        return item.type === 'channels' && item.campaign === this.state.campaign.id
                                                                                    }).map((item) => {
                                                                                        return (
                                                                                            <li className="text-xs text-red-500 font-bold ">
                                                                                                {item.title}
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.campaign.id && this.state.activechannel && !this.state.campaign.budget[this.state.activechannel].disabled &&
                                                                    <div className="flex flex-1 pt-4">
                                                                        <Datepicker
                                                                            size={'full'}
                                                                            showTime={true}
                                                                            border={true}
                                                                            showNoEndDateSwitch={true}
                                                                            disableEndDate={this.state.campaign.budget[this.state.activechannel].no_end_date}
                                                                            error={this.renders.errors().filter((item) => {
                                                                                return item.type === 'date' && item.campaign === this.state.campaign.id
                                                                            }).length > 0}
                                                                            startDate={this.state.campaign.budget[this.state.activechannel].start_date}
                                                                            endDate={this.state.campaign.budget[this.state.activechannel].end_date}
                                                                            updateNoEndDate={(value) => {
                                                                                this.state.campaign.budget[this.state.activechannel].no_end_date = value;
                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                        if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.search.campaigns[i].budget = this.state.campaign.budget;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        search: this.state.search
                                                                                    }, () => {
                                                                                        this.functions.save('search');
                                                                                    })
                                                                                })
                                                                            }}
                                                                            onStartDate={(value) => {
                                                                                this.state.campaign.budget[this.state.activechannel].start_date = value;
                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                        if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.search.campaigns[i].budget = this.state.campaign.budget;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        search: this.state.search
                                                                                    }, () => {
                                                                                        this.functions.save('search');
                                                                                    })
                                                                                })
                                                                            }}
                                                                            onEndDate={(value) => {
                                                                                this.state.campaign.budget[this.state.activechannel].end_date = value;
                                                                                if (!this.state.campaign.budget[this.state.activechannel].start_date) {
                                                                                    this.state.campaign.budget[this.state.activechannel].start_date = new Date();
                                                                                }
                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                        if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.search.campaigns[i].budget = this.state.campaign.budget;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        search: this.state.search
                                                                                    }, () => {
                                                                                        this.functions.save('search');
                                                                                    })
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'date' && item.campaign === this.state.campaign.id
                                                                    }).length > 0 &&
                                                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                                        <ul className="list-disc">
                                                                            {
                                                                                this.renders.errors().filter((item) => {
                                                                                    return item.type === 'date' && item.campaign === this.state.campaign.id
                                                                                }).map((item, index) => {
                                                                                    return (
                                                                                        <li className="text-xs text-red-500 font-bold "
                                                                                            key={index}>
                                                                                            {item.title}
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.campaign.id && this.state.activechannel && !this.state.campaign.budget[this.state.activechannel].disabled &&
                                                                    <div className="flex flex-1 flex-row pt-4 items-end">
                                                                        {
                                                                            !this.state.campaign.budget[this.state.activechannel].no_end_date &&
                                                                            <div className="flex flex-1">
                                                                                <Input
                                                                                    title={"Total budget"}
                                                                                    sign={false}
                                                                                    white={false}
                                                                                    error={this.renders.errors().filter((item) => {
                                                                                        return this.state.activechannel === item.channel && item.type === 'daily_budget' && item.campaign === this.state.campaign.id
                                                                                    }).length > 0}
                                                                                    value={this.state.campaign.budget[this.state.activechannel].total_budget}
                                                                                    tight={true}
                                                                                    type={"number"}
                                                                                    size={'full'}
                                                                                    icons={false}
                                                                                    onChange={(value) => {

                                                                                        this.state.campaign.budget[this.state.activechannel].total_budget = value.target.value;

                                                                                        //CAMPAIGN DAYS
                                                                                        let dates = [];
                                                                                        if (typeof this.state.details === "object" && this.state.campaign.budget[this.state.activechannel].start_date && this.state.campaign.budget[this.state.activechannel].end_date) {
                                                                                            dates = [];
                                                                                            var currDate = moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day');
                                                                                            var lastDate = moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day');
                                                                                            dates.push(moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day').clone().toDate());
                                                                                            while (currDate.add(1, 'days').diff(lastDate) < 0) {
                                                                                                dates.push(currDate.clone().toDate());
                                                                                            }
                                                                                            dates.push(moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day').clone().toDate());
                                                                                        }

                                                                                        this.state.campaign.budget[this.state.activechannel].daily_budget = this.renders.round(this.state.campaign.budget[this.state.activechannel].total_budget / dates.length);

                                                                                        this.setState({
                                                                                            campaign: this.state.campaign
                                                                                        }, () => {
                                                                                            for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                                if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                                    this.state.search.campaigns[i].budget = this.state.campaign.budget;
                                                                                                }
                                                                                            }
                                                                                            this.setState({
                                                                                                search: this.state.search
                                                                                            }, () => {
                                                                                                this.functions.save('search');
                                                                                            })
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !this.state.campaign.budget[this.state.activechannel].no_end_date &&
                                                                            <div className="ml-2 mr-2"></div>
                                                                        }
                                                                        <div className="flex flex-1">
                                                                            <Input
                                                                                title={"Daily budget"}
                                                                                sign={false}
                                                                                white={false}
                                                                                error={this.renders.errors().filter((item) => {
                                                                                    return this.state.activechannel === item.channel && item.type === 'daily_budget' && item.campaign === this.state.campaign.id
                                                                                }).length > 0}
                                                                                value={this.state.campaign.budget[this.state.activechannel].daily_budget}
                                                                                tight={true}
                                                                                type={"number"}
                                                                                size={'full'}
                                                                                icons={false}
                                                                                onChange={(value) => {

                                                                                    this.state.campaign.budget[this.state.activechannel].daily_budget = value.target.value;

                                                                                    if (!this.state.campaign.budget[this.state.activechannel].no_end_date) {
                                                                                        let dates = [];
                                                                                        if (typeof this.state.details === "object" && this.state.campaign.budget[this.state.activechannel].start_date && this.state.campaign.budget[this.state.activechannel].end_date) {
                                                                                            dates = [];
                                                                                            var currDate = moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day');
                                                                                            var lastDate = moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day');
                                                                                            dates.push(moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day').clone().toDate());
                                                                                            while (currDate.add(1, 'days').diff(lastDate) < 0) {
                                                                                                dates.push(currDate.clone().toDate());
                                                                                            }
                                                                                            dates.push(moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day').clone().toDate());
                                                                                        }

                                                                                        this.state.campaign.budget[this.state.activechannel].total_budget = this.renders.round(this.state.campaign.budget[this.state.activechannel].daily_budget * dates.length);
                                                                                    }

                                                                                    this.setState({
                                                                                        campaign: this.state.campaign
                                                                                    }, () => {
                                                                                        for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                            if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                                this.state.search.campaigns[i].budget = this.state.campaign.budget;
                                                                                            }
                                                                                        }
                                                                                        this.setState({
                                                                                            search: this.state.search
                                                                                        }, () => {
                                                                                            this.functions.save('search');
                                                                                        })
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.renders.errors().filter((item) => {
                                                                        return this.state.activechannel === item.channel && item.type === 'daily_budget' && item.campaign === this.state.campaign.id
                                                                    }).length > 0 &&
                                                                    <div
                                                                        className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                                        <ul className="list-decimal">
                                                                            {
                                                                                this.renders.errors().filter((item) => {
                                                                                    return this.state.activechannel === item.channel && item.type === 'daily_budget' && item.campaign === this.state.campaign.id
                                                                                }).map((item) => {
                                                                                    return (
                                                                                        <li className="text-xs text-red-500 font-bold ">
                                                                                            {item.title}
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.campaign.id && this.state.activechannel && !this.state.campaign.budget[this.state.activechannel].disabled &&
                                                                    <div className="flex flex-1 pt-4">
                                                                        <Dropdown
                                                                            filter={false}
                                                                            title={'Optimization goal'}
                                                                            noTitle={false}
                                                                            size={'full'}
                                                                            error={this.renders.errors().filter((item) => {
                                                                                return item.type === 'focus' && item.campaign === this.state.campaign.id
                                                                            }).length > 0}
                                                                            placeholder={"Select focus"}
                                                                            options={[
                                                                                { name: "Manual CPC", value: 'manual_cpc' },
                                                                                { name: "Enhanced Manual CPC", value: 'enhanced_manual_cpc' },
                                                                                { name: "Maximize click", value: 'clicks' },
                                                                                { name: "Conversion", value: 'conversion' },
                                                                                { name: "Target CPM", value: 'target_cpm' },
                                                                                { name: "Target CPA", value: 'target_cpa' }
                                                                            ]}
                                                                            value={this.state.campaign.budget[this.state.activechannel].focus ? this.state.campaign.budget[this.state.activechannel].focus : {}}
                                                                            onChange={(value) => {
                                                                                this.state.campaign.budget[this.state.activechannel].focus = value;
                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                        if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.search.campaigns[i].budget = this.state.campaign.budget;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        search: this.state.search
                                                                                    }, () => {
                                                                                        this.functions.save('search');
                                                                                    })
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.campaign.budget &&
                                                                    this.state.campaign.budget[this.state.activechannel] &&
                                                                    this.state.campaign.budget[this.state.activechannel].focus &&
                                                                    (
                                                                        this.state.campaign.budget[this.state.activechannel].focus.value === "target_cpa" ||
                                                                        this.state.campaign.budget[this.state.activechannel].focus.value === "manual_cpc" ||
                                                                        this.state.campaign.budget[this.state.activechannel].focus.value === "enhanced_manual_cpc"
                                                                    ) &&
                                                                    <div className="flex flex-1 mt-4">
                                                                        <Input
                                                                            title={"Target cost"}
                                                                            sign={false}
                                                                            white={false}
                                                                            error={false}
                                                                            value={this.state.campaign.budget[this.state.activechannel].target_cost}
                                                                            tight={true}
                                                                            type={"number"}
                                                                            size={'full'}
                                                                            icons={false}
                                                                            onChange={(event) => {
                                                                                this.state.campaign.budget[this.state.activechannel].target_cost = event.target.value;
                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                        if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.search.campaigns[i].budget = this.state.campaign.budget;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        campaign: this.state.campaign,
                                                                                        search: this.state.search
                                                                                    }, () => {
                                                                                        this.functions.save('search');
                                                                                    })
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                {this.renders.errors().filter((item) => {
                                                                    return item.type === 'focus' && item.campaign === this.state.campaign.id
                                                                }).length > 0 &&
                                                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                                        <ul className="list-disc">
                                                                            {
                                                                                this.renders.errors().filter((item) => {
                                                                                    return item.type === 'focus' && item.campaign === this.state.campaign.id
                                                                                }).map((item, index) => {
                                                                                    return (
                                                                                        <li className="text-xs text-red-500 font-bold " key={index}>
                                                                                            {item.title}
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                            {!this.state.opensearchoverview && this.renders.errors().filter((item) => {
                                                return item.tab === 'campaign' && item.campaign === this.state.campaign.id
                                            }).length > 0 &&
                                                <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                    <ul className="list-disc">
                                                        {
                                                            this.renders.errors().filter((item) => {
                                                                return item.tab === 'campaign' && item.campaign === this.state.campaign.id
                                                            }).map((item, index) => {
                                                                return (
                                                                    <li className="text-xs text-red-500 font-bold " key={index}>
                                                                        {item.title}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    }
                                </Modal>
                            }
                        </div>

                        <div className="rounded-md bg-gray-100">
                            <div className="navigation px-4 mb-4">
                                <nav role="navigation" aria-label="Social sub navigation">
                                    <ul className="flex flex-row items-end">
                                        {this.state.searchsublinks.map((item, j) => {
                                            return (
                                                <li key={j}
                                                    className={"font-bold justify-center items-center flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.activesearchsub == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                    onClick={() => {
                                                        this.setState({ activesearchsub: item.id });
                                                    }}>
                                                    {item.name}
                                                    {
                                                        item.id === 1 &&
                                                        this.renders.errors().filter((item) => {
                                                            return item.tab === 'templates' && item.campaign === this.state.campaign.id
                                                        }).length > 0 &&
                                                        <div
                                                            className="relative ml-6 h-full mr-2 flex justify-center items-center">
                                                            <div
                                                                className={"absolute close ml-1 w-6 h-6 mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                                <FeatherIcon className="stroke-current"
                                                                    size={15} icon="alert-circle" />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        item.id === 2 &&
                                                        this.renders.errors().filter((item) => {
                                                            return item.tab === 'keywords' && item.campaign === this.state.campaign.id
                                                        }).length > 0 &&
                                                        <div
                                                            className="relative ml-6 h-full mr-2 flex justify-center items-center">
                                                            <div
                                                                className={"absolute close ml-1 w-6 h-6 mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                                <FeatherIcon className="stroke-current"
                                                                    size={15} icon="alert-circle" />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        item.id === 6 &&
                                                        this.renders.errors().filter((item) => {
                                                            return item.tab === 'locations' && item.campaign === this.state.campaign.id
                                                        }).length > 0 &&
                                                        <div
                                                            className="relative ml-6 h-full mr-2 flex justify-center items-center">
                                                            <div
                                                                className={"absolute close ml-1 w-6 h-6 mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                                <FeatherIcon className="stroke-current"
                                                                    size={15} icon="alert-circle" />
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            )
                                        })
                                        }
                                        <li className="flex-grow border-b-2 border-gray-200"></li>
                                    </ul>
                                </nav>
                            </div>

                            <div className="pb-4 px-4">
                                {this.state.activesearchsub == 1 &&
                                    <div>
                                        {Array.isArray(this.state.search.templates) &&
                                            <div className="mt-3 bg-white rounded-md">
                                                <div className="px-4 py-4 flex-row flex">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="mb-0 font-bold">
                                                            Templates
                                                        </h3>
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Avg. number of search per month in parantheses
                                                        </p>
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                new_template_data: {
                                                                    headline_first_template: {},
                                                                    headline_second_template: {},
                                                                    headline_third_template: {},
                                                                    body: "",
                                                                    body_second: "",
                                                                    overwrite_url: "",
                                                                    template_type: { name: "Expanded text ad", value: "expanded" }
                                                                },
                                                                new_template: true
                                                            })
                                                        }} className="bg-white text-white flex justify-center items-center rounded-t-md cursor-pointer">
                                                            <div className={"close h-10 w-10 flex items-center justify-center text-2xl rounded-md transition-all duration-200 bg-purple-500 text-white"}>
                                                                +
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.renders.errors().filter((item) => {
                                                        return item.campaign === this.state.campaign.id && item.type === 'templates'
                                                    }).length > 0 &&
                                                    <div className="p-4 pt-0">
                                                        <div className="flex w-full bg-red-100 rounded-md py-4 px-8">
                                                            <ul className="list-disc">
                                                                {this.renders.errors().filter((item) => {
                                                                    return item.campaign === this.state.campaign.id && item.type === 'templates'
                                                                }).map((item, index) => {
                                                                    return (
                                                                        <li className="text-xs text-red-500 font-bold "
                                                                            key={index}>
                                                                            {item.title}
                                                                        </li>
                                                                    )
                                                                })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="w-full border-t" />
                                                {
                                                    this.state.search.templates.filter((item) => {
                                                        return item.campaign_id === this.state.campaign.id
                                                    }).length > 0 &&
                                                    <div className="p-2">
                                                        <div className="flex flex-row flex-wrap">
                                                            {this.state.search.templates.filter((item) => {
                                                                return item.campaign_id === this.state.campaign.id
                                                            }).map((template, index) => {
                                                                return (
                                                                    <div className="w-full flex flex-col max-w-1/2">
                                                                        <div className="p-2">
                                                                            <AdvertisingSearchTemplate
                                                                                item={template}
                                                                                index={index}
                                                                                creatives={this.state.creatives}
                                                                                site={this.state.site}
                                                                                campaign={this.state.campaign}
                                                                                path1={this.state.campaign.path1}
                                                                                path2={this.state.campaign.path2}
                                                                                site_links={this.state.search.site_links ? this.state.search.site_links.filter((item) => {
                                                                                    return item.campaign_id === this.state.campaign.id
                                                                                }) : []}
                                                                                phone_numbers={this.state.search.phone_numbers ? this.state.search.phone_numbers.filter((item) => {
                                                                                    return item.campaign_id === this.state.campaign.id
                                                                                }) : []}
                                                                                errors={this.renders.errors().filter((item) => {
                                                                                    return item.campaign === template.campaign_id && template.id === item.template
                                                                                })}
                                                                                onClone={() => {
                                                                                    let new_template = JSON.parse(JSON.stringify(template));
                                                                                    new_template.id = Math.floor((Math.random() * 9999999999) + 1);
                                                                                    this.state.search.templates.push(new_template);
                                                                                    this.setState({
                                                                                        search: this.state.search
                                                                                    }, () => {
                                                                                        this.functions.save('search');
                                                                                    })
                                                                                }}
                                                                                onEdit={(value) => {
                                                                                    template.edit = value;
                                                                                    template.temp = JSON.parse(JSON.stringify(template));
                                                                                    this.setState({
                                                                                        search: this.state.search
                                                                                    }, () => {
                                                                                        this.functions.save('search');
                                                                                    })
                                                                                }}
                                                                                onRemove={(item) => {
                                                                                    this.state.search.templates = this.state.search.templates.filter((inner_item) => {
                                                                                        return item.id !== inner_item.id
                                                                                    });
                                                                                    this.setState({
                                                                                        editTemplate: false,
                                                                                        search: this.state.search
                                                                                    }, () => {
                                                                                        this.functions.save('search');
                                                                                    })
                                                                                }}
                                                                                onChange={(value) => {
                                                                                    template = value;
                                                                                    this.setState({
                                                                                        search: this.state.search
                                                                                    }, () => {
                                                                                        this.functions.save('search');
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {
                                                                            template.edit &&
                                                                            <Modal
                                                                                title={"Edit template"}
                                                                                subtitle={"You have selected 1 template"}
                                                                                blueButton={true}
                                                                                blueButtonText={"Update template"}
                                                                                onBlueButtonClick={() => {
                                                                                    this.state.search.templates = this.state.search.templates.map((item) => {
                                                                                        if (item.id === template.id) {
                                                                                            item = template.temp;
                                                                                            item.edit = false;
                                                                                        }
                                                                                        return item;
                                                                                    });
                                                                                    this.setState({
                                                                                        search: this.state.search
                                                                                    }, () => {
                                                                                        this.functions.save('search');
                                                                                    })
                                                                                }}
                                                                                onClose={() => {
                                                                                    template.edit = false;
                                                                                    template.temp = {};
                                                                                    this.setState({
                                                                                        search: this.state.search
                                                                                    }, () => {
                                                                                        this.functions.save('search');
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <div className="w-full">
                                                                                    <AdvertisingSearchTemplate
                                                                                        item={template.temp ? template.temp : {}}
                                                                                        index={0}
                                                                                        new={true}
                                                                                        edit={true}
                                                                                        campaign={this.state.campaign}
                                                                                        creatives={this.state.creatives}
                                                                                        errors={this.renders.errors().filter((item) => {
                                                                                            //console.log(item);
                                                                                            return item.campaign === template.campaign_id && template.id === item.template
                                                                                        })}
                                                                                        error={true}
                                                                                        site={this.state.site}
                                                                                        site_links={this.state.search.site_links ? this.state.search.site_links.filter((item) => {
                                                                                            return item.campaign_id === this.state.campaign.id
                                                                                        }) : []}
                                                                                        keywords={this.state.search.keywords ? this.state.search.keywords.filter((item) => {
                                                                                            return item.campaign_id === this.state.campaign.id
                                                                                        }) : []}
                                                                                        phone_numbers={this.state.search.phone_numbers ? this.state.search.phone_numbers.filter((item) => {
                                                                                            return item.campaign_id === this.state.campaign.id
                                                                                        }) : []}
                                                                                        onChange={(value) => {
                                                                                            this.state.search.templates = this.state.search.templates.map((item) => {
                                                                                                if (item.id === template.id) {
                                                                                                    item.temp = value;
                                                                                                }
                                                                                                return item;
                                                                                            });
                                                                                            this.setState({
                                                                                                search: this.state.search,
                                                                                            }, () => {
                                                                                                this.functions.save('search');
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </Modal>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            this.state.new_template &&
                                            <Modal
                                                title={"New template"}
                                                subtitle={"Create new ad template"}
                                                blueButton={true}
                                                blueButtonText={"Create template"}
                                                onBlueButtonClick={() => {
                                                    if (!Array.isArray(this.state.new_template_data.errors) || (Array.isArray(this.state.new_template_data.errors) && this.state.new_template_data.errors.length < 1)) {
                                                        this.state.new_template_data.id = Math.floor((Math.random() * 9999999999) + 1);
                                                        this.state.new_template_data.campaign_id = this.state.campaign.id;
                                                        this.state.search.templates.push(this.state.new_template_data);
                                                        this.setState({
                                                            new_template_data: {
                                                                headline_first_template: {},
                                                                headline_second_template: {},
                                                                headline_third_template: {},
                                                                body: "",
                                                                body_second: "",
                                                                overwrite_url: ""
                                                            },
                                                            new_template: false,
                                                            search: this.state.search
                                                        }, () => {
                                                            this.functions.save('search');
                                                        })
                                                    }
                                                }}
                                                onClose={() => {
                                                    this.setState({
                                                        new_template_data: {
                                                            headline_first_template: {},
                                                            headline_second_template: {},
                                                            headline_third_template: {},
                                                            body: "",
                                                            body_second: "",
                                                            overwrite_url: ""
                                                        },
                                                        new_template: false,
                                                    });
                                                }}
                                            >
                                                <div className="w-full">
                                                    <AdvertisingSearchTemplate
                                                        item={this.state.new_template_data}
                                                        index={0}
                                                        new={true}
                                                        error={true}
                                                        campaign={this.state.campaign}
                                                        errors={[]}
                                                        keywords={this.state.search.keywords ? this.state.search.keywords.filter((item) => {
                                                            return item.campaign_id === this.state.campaign.id
                                                        }) : []}
                                                        creatives={this.state.creatives}
                                                        site={this.state.site}
                                                        site_links={this.state.search.site_links ? this.state.search.site_links.filter((item) => {
                                                            return item.campaign_id === this.state.campaign.id
                                                        }) : []}
                                                        phone_numbers={this.state.search.phone_numbers ? this.state.search.phone_numbers.filter((item) => {
                                                            return item.campaign_id === this.state.campaign.id
                                                        }) : []}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                new_template_data: value
                                                            })
                                                        }}
                                                        onCancel={() => {
                                                            this.setState({
                                                                newTemp: false,
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </Modal>
                                        }
                                        {Array.isArray(this.state.search.templates) && false &&
                                            <div className="mt-4 bg-white rounded-md">
                                                <div className="px-4 py-4 flex-row flex">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="mb-0 font-bold">
                                                            New template
                                                        </h3>
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Avg. number of search per month in parantheses
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <button
                                                            className="bg-gray-200 text-gray-900 h-10 w-10 flex justify-center items-center rounded"
                                                            onClick={() => {
                                                                this.setState({
                                                                    new_template: !this.state.new_template
                                                                })
                                                            }}>
                                                            {
                                                                !this.state.new_template &&
                                                                <FeatherIcon className="stroke-current" size={16}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.new_template &&
                                                                <FeatherIcon className="stroke-current" size={16}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                                <SlideDown closed={!this.state.new_template}>
                                                    <div className="w-full border-t" />
                                                    <AdvertisingSearchTemplate
                                                        item={{
                                                            headline_first_template: {},
                                                            headline_second_template: {},
                                                            headline_third_template: {},
                                                            body: "",
                                                            body_second: "",
                                                            overwrite_url: ""
                                                        }}
                                                        campaign={this.state.campaign}
                                                        index={0}
                                                        new={true}
                                                        errors={[]}
                                                        keywords={this.state.search.keywords ? this.state.search.keywords.filter((item) => {
                                                            return item.campaign_id === this.state.campaign.id
                                                        }) : []}
                                                        creatives={this.state.creatives}
                                                        site={this.state.site}
                                                        site_links={this.state.search.site_links ? this.state.search.site_links.filter((item) => {
                                                            return item.campaign_id === this.state.campaign.id
                                                        }) : []}
                                                        phone_numbers={this.state.search.phone_numbers ? this.state.search.phone_numbers.filter((item) => {
                                                            return item.campaign_id === this.state.campaign.id
                                                        }) : []}
                                                        onNew={(value) => {
                                                            value.id = Math.floor((Math.random() * 9999999999) + 1);
                                                            value.campaign_id = this.state.campaign.id;
                                                            this.state.search.templates.push(value);
                                                            this.setState({
                                                                new_template: false,
                                                                show_templates: true,
                                                                search: this.state.search
                                                            }, () => {
                                                                this.functions.save('search');
                                                            })
                                                        }}
                                                        onCancel={() => {
                                                            this.setState({
                                                                newTemp: false,
                                                            })
                                                        }}
                                                    />
                                                </SlideDown>
                                            </div>
                                        }
                                    </div>
                                }
                                {this.state.activesearchsub == 2 &&
                                    <div className="bg-white rounded-md">
                                        <div className="px-4 py-4 flex-row flex">
                                            <div className="flex flex-1 flex-col">
                                                <h3 className="mb-0 font-bold">
                                                    Add keywords
                                                </h3>
                                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                    Avg. number of search per month in parantheses
                                                </p>
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                        <div className="w-full border-t" />
                                        <div className="p-4">
                                            <AdvertisingKeywordsPositiveAdd
                                                ref={this.child}
                                                client={this.state.client}
                                                keywordsdata={this.state.keywordsdata}
                                                channels={this.state.channels}
                                                campaign={this.state.campaign}
                                                creatives={this.state.creatives}
                                                keywords={this.state.search.keywords ? this.state.search.keywords : []}
                                                ideas={this.state.campaign.ideas ? this.state.campaign.ideas : []}
                                                website={this.state.campaign.website ? this.state.campaign.website : null}
                                                language={this.state.campaign.language ? this.state.campaign.language : {}}
                                                languages={this.state.campaign.languages ? this.state.campaign.languages : []}
                                                onChange={(value) => {
                                                    this.state.search.keywords = value;
                                                    this.setState({
                                                        search: this.state.search
                                                    }, () => {
                                                        this.functions.save('search');
                                                        try {
                                                            this.keyword_list_ref.current.functions.get();
                                                        } catch (e) {
                                                            console.log(e);
                                                        }
                                                    })
                                                }}
                                                updateIdeas={(value) => {
                                                    this.state.campaign.ideas = value;
                                                    this.setState({
                                                        campaign: this.state.campaign
                                                    }, () => {
                                                        if (Array.isArray(this.state.search.campaigns)) {
                                                            for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                    this.state.search.campaigns[i].ideas = this.state.campaign.ideas;
                                                                }
                                                            }
                                                            this.setState({
                                                                search: this.state.search
                                                            }, () => {
                                                                this.functions.save('search');
                                                            })
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>
                                        {
                                            this.renders.errors().filter((item) => {
                                                return item.type === 'keywords' && item.campaign === this.state.campaign.id
                                            }).length > 0 &&
                                            <div className="p-4 pt-0">
                                                <div className="flex flex-1 bg-red-100 rounded-md py-4 px-8">
                                                    <ul className="list-disc">
                                                        {
                                                            this.renders.errors().filter((item) => {
                                                                return item.type === 'keywords' && item.campaign === this.state.campaign.id
                                                            }).map((item, index) => {
                                                                return (
                                                                    <li className="text-xs text-red-500 font-bold "
                                                                        key={index}>
                                                                        {item.title}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    this.state.activesearchsub == 2 &&
                                    Array.isArray(this.state.search.keywords) && this.state.search.keywords.filter((item) => {
                                        return item.campaign_id === this.state.campaign.id
                                    }).length > 0 &&
                                    <div className="mt-4">
                                        <AdvertisingKeywordsPositiveList
                                            ref={this.keyword_list_ref}
                                            sort={this.state.sort_keywords}
                                            order={this.state.order_keywords}
                                            filter={this.state.filter_keywords}
                                            search={this.state.search}
                                            creatives={this.state.creatives}
                                            campaign={this.state.campaign}
                                            items={this.state.search.keywords}
                                            onRemove={(keywords) => {
                                                //console.log(keywords);
                                                if (Array.isArray(this.state.search.templates)) {
                                                    this.state.search.templates.map((item) => {
                                                        keywords.map((keyword) => {
                                                            if (item.specific_keywords && item.specific_keywords[keyword.id]) {
                                                                delete item.specific_keywords[keyword.id];
                                                            }
                                                        })
                                                    })
                                                }
                                                this.setState({
                                                    search: this.state.search
                                                }, () => {
                                                    this.functions.save('search');
                                                });
                                            }}
                                            onChange={(value) => {
                                                this.state.search.keywords = value;
                                                this.setState({
                                                    search: this.state.search
                                                }, () => {
                                                    this.functions.save('search');
                                                })
                                            }}
                                            onEditTable={(sort, order, filter) => {
                                                this.setState({
                                                    sort_keywords: sort,
                                                    order_keywords: order,
                                                    filter_keywords: filter
                                                })
                                            }}
                                        />
                                    </div>
                                }

                                {this.state.activesearchsub == 3 &&
                                    <div className="flex flex-1 flex-col">
                                        <div className="flex flex-1 flex-col bg-white rounded-md">
                                            <div className="px-4 py-4 flex-row flex">
                                                <div className="flex flex-1 flex-col">
                                                    <h3 className="mb-0 font-bold">
                                                        Add Negative keywords
                                                    </h3>
                                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                        Avg. number of search per month in parantheses
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="w-full border-t" />
                                            <div className="flex bg-white flex-1 p-4 flex-row rounded-md flex-col">
                                                <div className="">
                                                    <AdvertisingKeywordsNegativeAdd
                                                        keywords={this.state.search.negatives ? this.state.search.negatives : []}
                                                        campaign={this.state.campaign}
                                                        channels={this.state.channels}
                                                        onChange={(value) => {
                                                            this.state.search.negatives = value;
                                                            this.setState({
                                                                search: this.state.search
                                                            }, () => {
                                                                this.functions.save('search');
                                                                try {
                                                                    this.keyword_list_negative_ref.current.functions.get();
                                                                } catch (e) {
                                                                    console.log(e);
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    false &&
                                                    <div className="mt-3">
                                                        <AdvertisingKeywordsNegativeAsset
                                                            keywords={this.state.search.negatives ? this.state.search.negatives : []}
                                                            client={this.state.client}
                                                            campaign={this.state.campaign}
                                                            onChange={(value) => {
                                                                this.state.search.negatives = value;
                                                                this.setState({
                                                                    search: this.state.search
                                                                }, () => {
                                                                    this.functions.save('search');
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            Array.isArray(this.state.search.negatives) && this.state.search.negatives.length > 0 &&
                                            <div className="mt-4">
                                                <AdvertisingKeywordsNegativeList
                                                    ref={this.keyword_list_negative_ref}
                                                    sort={this.state.sort_negative_keywords}
                                                    order={this.state.order_negative_keywords}
                                                    filter={this.state.filter_negative_keywords}
                                                    search={this.state.search}
                                                    campaign={this.state.campaign}
                                                    onEditTable={(sort, order, filter) => {
                                                        this.setState({
                                                            sort_negative_keywords: sort,
                                                            order_negative_keywords: order,
                                                            filter_negative_keywords: filter
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                }

                                {this.state.activesearchsub == 4 &&
                                    <div className="flex flex-1 flex-col">
                                        <div className="flex flex-1 flex-col">
                                            <AdvertisingCreativesSitelinks
                                                site={this.state.site}
                                                search={this.state.search}
                                                campaign={this.state.campaign}
                                                channels={this.state.channels}
                                                onChange={(value) => {
                                                    this.setState({
                                                        search: value
                                                    }, () => {
                                                        this.functions.save('search');
                                                    });
                                                }}
                                                onRemove={(search, sitelink) => {
                                                    if (Array.isArray(search.templates)) {
                                                        search.templates.map((item) => {
                                                            //console.log(item, sitelink.id);
                                                            if (item.specific_sitelinks && item.specific_sitelinks[sitelink.id]) {
                                                                delete item.specific_sitelinks[sitelink.id];
                                                            }
                                                        })
                                                    }
                                                    this.setState({
                                                        search: search
                                                    }, () => {
                                                        this.functions.save('search');
                                                    });
                                                }}
                                            />
                                            <AdvertisingCreativesCall
                                                site={this.state.site}
                                                search={this.state.search}
                                                campaign={this.state.campaign}
                                                channels={this.state.channels}
                                                onChange={(value) => {
                                                    this.setState({
                                                        search: value
                                                    }, () => {
                                                        this.functions.save('search');
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                                {this.state.activesearchsub == 5 &&
                                    <div className="flex flex-1 flex-col">
                                        <div className="flex flex-1 flex-col bg-white rounded-md">
                                            <div className="px-4 py-4 flex-row flex">
                                                <div className="flex flex-1 flex-col">
                                                    <h3 className="mb-0 font-bold">
                                                        Basic
                                                    </h3>
                                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                        Avg. number of search per month in parantheses
                                                    </p>
                                                </div>
                                                {
                                                    false &&
                                                    <div>
                                                        <button
                                                            className="bg-gray-200 text-gray-900 h-10 w-10 flex justify-center items-center rounded"
                                                            onClick={() => {
                                                                this.setState({
                                                                    basic_targeting: !this.state.basic_targeting
                                                                })
                                                            }}>
                                                            {
                                                                !this.state.basic_targeting &&
                                                                <FeatherIcon className="stroke-current" size={16}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.basic_targeting &&
                                                                <FeatherIcon className="stroke-current" size={16}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={false}>
                                                <div className="w-full border-t" />
                                                <div className="flex flex-1 bg-white px-4 py-4 flex-col rounded-md">
                                                    <div className="flex flex-row">
                                                        <div className="flex flex-1 pr-3">
                                                            <TargetingGender
                                                                search={true}
                                                                channels={this.state.channels}
                                                                targeting={this.state.campaign.targeting ? this.state.campaign.targeting : {}}
                                                                onChange={(value) => {
                                                                    this.state.campaign.targeting = value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                            if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                this.state.search.campaigns[i].targeting = this.state.campaign.targeting;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            search: this.state.search
                                                                        }, () => {
                                                                            this.functions.save('search');
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="flex flex-1 pl-3">
                                                            <TargetingAge
                                                                search={true}
                                                                channels={this.state.channels}
                                                                targeting={this.state.campaign.targeting ? this.state.campaign.targeting : {}}
                                                                onChange={(value) => {
                                                                    this.state.campaign.targeting = value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                            if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                this.state.search.campaigns[i].targeting = this.state.campaign.targeting;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            search: this.state.search
                                                                        }, () => {
                                                                            this.functions.save('search');
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        <div className="flex flex-1 pt-3">
                                                            <TargetingLanguages
                                                                error={this.renders.errors().filter((item) => {
                                                                    return item.type === 'language' && item.campaign === this.state.campaign.id
                                                                }).length > 0}
                                                                languages={this.state.campaign.languages ? this.state.campaign.languages : []}
                                                                onChange={(value) => {
                                                                    this.state.campaign.languages = value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        if (Array.isArray(this.state.search.campaigns)) {
                                                                            for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                                if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.search.campaigns[i].languages = this.state.campaign.languages;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                search: this.state.search
                                                                            }, () => {
                                                                                this.functions.save('search');
                                                                                /*
                                                                                if (typeof this.state.campaign.language === "object" && this.state.campaign.language.google_id) {
                                                                                    this.functions.ideas(this.state.campaign.language.google_id);
                                                                                }
                                                                                */
                                                                            })
                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.renders.errors().filter((item) => {
                                                            return item.type === 'language' && item.campaign === this.state.campaign.id
                                                        }).length > 0 &&
                                                        <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                            <ul className="list-disc">
                                                                {
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'language' && item.campaign === this.state.campaign.id
                                                                    }).map((item, index) => {
                                                                        return (
                                                                            <li className="text-xs text-red-500 font-bold " key={index}>
                                                                                {item.title}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    }
                                                    {
                                                        //ADD LATER
                                                        false &&
                                                        <div className="mt-3">
                                                            <TargetingWeekdays
                                                                targeting={this.state.campaign.targeting ? this.state.campaign.targeting : {}}
                                                                onChange={(value) => {
                                                                    this.state.campaign.targeting = value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                            if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                                this.state.search.campaigns[i].targeting = this.state.campaign.targeting;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            search: this.state.search
                                                                        }, () => {
                                                                            this.functions.save('search');
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </SlideDown>
                                        </div>
                                    </div>
                                }
                                {this.state.activesearchsub == 6 &&
                                    <div className="flex flex-1 flex-col">
                                        <div className="flex flex-1 flex-col bg-white rounded-md">
                                            <div className="px-4 py-4 flex-row flex">
                                                <div className="flex flex-1 flex-col">
                                                    <h3 className="mb-0 font-bold">
                                                        Locations
                                                    </h3>
                                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                        Avg. number of search per month in parantheses
                                                    </p>
                                                </div>
                                                {
                                                    false &&
                                                    <div>
                                                        <button
                                                            className="bg-gray-200 text-gray-900 h-10 w-10 flex justify-center items-center rounded"
                                                            onClick={() => {
                                                                this.setState({
                                                                    open_locations: !this.state.open_locations
                                                                })
                                                            }}>
                                                            {
                                                                !this.state.open_locations &&
                                                                <FeatherIcon className="stroke-current" size={16}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.open_locations &&
                                                                <FeatherIcon className="stroke-current" size={16}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={false}>
                                                <div className="w-full border-t" />
                                                <div className="p-4 pb-2">
                                                    <TargetingLocation
                                                        border={true}
                                                        search={true}
                                                        exclude_locations={this.state.campaign.exclude_locations ? this.state.campaign.exclude_locations : {}}
                                                        locations={this.state.locations}
                                                        channels={this.state.campaign.budget}
                                                        updateLocation={(value) => {
                                                            this.state.campaign.exclude_locations = value;
                                                            this.setState({
                                                                campaign: this.state.campaign
                                                            }, () => {
                                                                for (let i = 0; i < this.state.search.campaigns.length; i++) {
                                                                    if (this.state.search.campaigns[i].id === this.state.campaign.id) {
                                                                        this.state.search.campaigns[i].exclude_locations = this.state.campaign.exclude_locations;
                                                                    }
                                                                }
                                                                this.setState({
                                                                    search: this.state.search
                                                                }, () => {
                                                                    this.functions.save('search');
                                                                })
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </SlideDown>
                                            {
                                                this.renders.errors().filter((item) => {
                                                    return item.type === 'geographic' && item.campaign === this.state.campaign.id
                                                }).length > 0 &&
                                                <div className="p-4 pt-0">
                                                    <div className="flex flex-1 bg-red-100 rounded-md py-4 px-8">
                                                        <ul className="list-disc">
                                                            {
                                                                this.renders.errors().filter((item) => {
                                                                    return item.type === 'geographic' && item.campaign === this.state.campaign.id
                                                                }).map((item, index) => {
                                                                    return (
                                                                        <li className="text-xs text-red-500 font-bold "
                                                                            key={index}>
                                                                            {item.title}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.creatives.edit_ads &&
                                    <div>
                                        {
                                            this.renders.errors().map((item) => {
                                                return (
                                                    <div dangerouslySetInnerHTML={this.renders.markdown(item)}
                                                        className="Site-Error" />
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            this.state.next &&
                            this.renders.errors().filter((item) => {
                                return item.campaign === this.state.campaign.id
                            }).length > 0 &&
                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                <ul className="list-disc">
                                    {
                                        this.renders.errors().filter((item) => {
                                            return item.campaign === this.state.campaign.id
                                        }).map((item, index) => {
                                            return (
                                                <li className="text-xs text-red-500 font-bold "
                                                    key={index}>
                                                    {item.title}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div className="flex flex-row mt-4 mb-4">
                    <div className="">
                        <button onClick={() => {
                            try {
                                this.props.onBack();
                            } catch (e) {
                            }
                        }} className="inline-flex items-center text-sm font-bold text-purple-900 py-2 hover:opacity-50">
                            <span className="arrow-right arrow-dark transform rotate-180 mr-2"></span> Previous step
                        </button>
                    </div>

                    <div className="flex flex-1"></div>
                    <div className="text-right">
                        <button onClick={() => {
                            this.functions.next()
                        }} className="btn btn-lg btn-primary inline-flex items-center">
                            Next step: Preview <span className="arrow-right"></span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingSearch;
