import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import AdvertisingCreativesCarouselSlides from "../advertising-creatives-carouselslides";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import {toArray} from "react-emoji-render";
import AdvertisingCreativesAds from "../advertising-creatives-ads";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import AdvertisingCreativesSearchPreview from "../advertising-creatives-search-preview";
import {SlideDown} from "react-slidedown";

class AdvertisingCreativesSearchList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {},
            client: {},
            channels: {},
            creatives: {},
            site: {},
            error: null,
            errors: [],
            languages: [],
            edit: false,
            inner_edit: 'basic',
            selected_language: null
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            channels: nextProps.channels,
            creatives: nextProps.creatives,
            site: nextProps.site,
            languages: nextProps.languages
        })
    }

    componentDidMount() {
        this.setState({
            channels: this.props.channels,
            creatives: this.props.creatives,
            site: this.props.site,
            languages: this.props.languages
        }, () => {
            if(!this.state.selected_language){
                this.setState({
                    selected_language:this.state.languages[0].code
                })
            }
        })
    }

    init = {};

    functions = {};

    validators = {};

    renders = {
        characters: (item) => {
            try{
                return item.toString().length
            }catch (e) {
                return 0
            }
        },
        property: (type) => {
            try{
                return this.state.creatives.search_ads[this.state.selected_language][type];
            }catch (e) {
                if(type !== 'site_links'){
                    return ""
                }else{
                    return []
                }
            }
        }
    };

    render() {
        return (
            <div>
                <div className="Creative-search-item">
                    <div style={{width: '100%', paddingTop: '15px'}} className="Section-Inner">
                        <div className="Creative-search-item-inner">
                            <div className="Creative-search-item-inner-type">

                            </div>
                            <div className="Creative-search-item-inner-type Creative-search-item-inner-type-long">
                                <FeatherIcon color={"#333333"} size={25} icon="layout"/>
                                <div className="Creative-ads-item-top-type-text">Search template</div>
                            </div>
                            <div className="Creative-search-item-inner-values">
                                <div onClick={() => {
                                    this.state.edit = !this.state.edit;
                                    this.setState({
                                        edit: this.state.edit
                                    });
                                }} className="Creative-search-item-inner-values-inner">
                                    Create your Search template here
                                    <div className="Creative-search-item-inner-values-inner-arrow">
                                        <FeatherIcon color={"#333333"} size={22} icon="arrow-right"/>
                                    </div>
                                </div>
                            </div>
                            <div className="Creative-search-item-inner-button">
                                <div onClick={() => {
                                    this.state.edit = !this.state.edit;
                                    this.setState({
                                        edit: this.state.edit
                                    });
                                }} className="Button ButtonNeutral ButtonFull">
                                    {
                                        !this.state.edit &&
                                        <FeatherIcon color={"#333333"} size={25} icon="chevron-down"/>
                                    }
                                    {
                                        this.state.edit &&
                                        <FeatherIcon color={"#333333"} size={25} icon="chevron-up"/>
                                    }
                                </div>
                            </div>
                            <div className="Creative-search-item-inner-button">
                                <div className="Button ButtonRed ButtonFull">

                                </div>
                            </div>
                        </div>
                        <SlideDown closed={!this.state.edit}>
                            <div className="Creative-search-item-bottom">
                                <div className="Creative-search-item-bottom-inner">
                                    <div className="Creative-search-item-bottom-inner-left">
                                        <AdvertisingCreativesSearchPreview
                                            creatives={this.state.creatives}
                                            language={this.state.selected_language}
                                            url={this.state.site.url}
                                            selected={'search_one'}
                                        />
                                    </div>
                                    <div className="Creative-search-item-bottom-inner-right">
                                        <div className="Tabs">
                                            {
                                                this.state.languages.map((item) => {
                                                    return (
                                                        <div onClick={() => {
                                                            this.state.selected_language = item.code;
                                                        }} className={
                                                            cx("Tab",
                                                                {
                                                                    ["Tab-Active"]: item.code === this.state.selected_language,
                                                                })
                                                        }>
                                                            <span style={{textTransform: 'capitalize'}}>{item.name}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        {
                                            <div style={{width: '100%', marginTop: '10px'}}>
                                                <div className="Creative-search-item-bottom-inner-right-item">
                                                    <Input
                                                        title={'Headline first'}
                                                        sign={true}
                                                        white={true}
                                                        signTitle={this.renders.characters(this.renders.property('headline')) +' / 30'}
                                                        maxLength={30}
                                                        error={this.renders.property('headline') === "" && this.state.error}
                                                        value={this.renders.property('headline')}
                                                        placeholder={"Max 30 characters"}
                                                        disabled={false}
                                                        tight={true}
                                                        size={'third'}
                                                        type={"text"}
                                                        onChange={(value) => {
                                                            try{
                                                                this.state.creatives.search_ads[this.state.selected_language].headline = value.target.value;
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }catch (e) {
                                                                console.log(e);
                                                            }
                                                        }}
                                                    />
                                                    <Input
                                                        title={'Headline second'}
                                                        sign={true}
                                                        white={true}
                                                        signTitle={this.renders.characters(this.renders.property('headline_second')) +' / 30'}
                                                        maxLength={30}
                                                        error={this.renders.property('headline_second') === "" && this.state.error}
                                                        value={this.renders.property('headline_second')}
                                                        placeholder={"Max 30 characters"}
                                                        disabled={false}
                                                        tight={true}
                                                        size={'third'}
                                                        type={"text"}
                                                        onChange={(value) => {
                                                            try{
                                                                this.state.creatives.search_ads[this.state.selected_language].headline_second = value.target.value;
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }catch (e) {}
                                                        }}
                                                    />
                                                    <Input
                                                        title={'Headline third (optional)'}
                                                        sign={true}
                                                        white={true}
                                                        signTitle={this.renders.characters(this.renders.property('headline_third')) +' / 30'}
                                                        maxLength={30}
                                                        error={this.renders.property('headline_third') === "" && this.state.error}
                                                        value={this.renders.property('headline_third')}
                                                        placeholder={"Max 30 characters"}
                                                        disabled={false}
                                                        size={'third'}
                                                        type={"text"}
                                                        onChange={(value) => {
                                                            try{
                                                                this.state.creatives.search_ads[this.state.selected_language].headline_third = value.target.value;
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }catch (e) {}
                                                        }}
                                                    />
                                                </div>
                                                <div className="Creative-search-item-bottom-inner-right-item">
                                                    <TextArea
                                                        maxLength={90}
                                                        sign={true}
                                                        signTitle={this.renders.characters(this.renders.property('body')) +' / 90'}
                                                        tiny={true}
                                                        white={true}
                                                        size={"half"}
                                                        title={"Body"}
                                                        placeholder={"Max 90 characters"}
                                                        error={this.renders.property('body') === "" && this.state.error}
                                                        value={this.renders.property('body')}
                                                        onChange={(value) => {
                                                            try{
                                                                this.state.creatives.search_ads[this.state.selected_language].body = value.target.value;
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }catch (e) {}
                                                        }}
                                                    />
                                                    <TextArea
                                                        maxLength={90}
                                                        tiny={true}
                                                        sign={true}
                                                        white={true}
                                                        signTitle={this.renders.characters(this.renders.property('body_second')) +' / 90'}
                                                        size={"half"}
                                                        title={"Body extended (optional)"}
                                                        placeholder={"Max 90 characters"}
                                                        value={this.renders.property('body_second')}
                                                        onChange={(value) => {
                                                            try{
                                                                this.state.creatives.search_ads[this.state.selected_language].body_second = value.target.value;
                                                                this.setState({
                                                                    creatives: this.state.creatives
                                                                }, () => {
                                                                    this.props.onChange(this.state.creatives);
                                                                })
                                                            }catch (e) {}
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.selected_language &&
                                            <div style={{paddingTop: '5px'}} className="Section">
                                                <div className="Section-Inner">
                                                    <div className="Section-InnerTitle">
                                                        <div className={cx("Upload-ImageContainerTitleText")}>
                                                            Add Site Link (optional)
                                                        </div>
                                                    </div>
                                                    {
                                                        this.renders.property('site_links').map((item, index) => {
                                                            return (
                                                                <div className="Site-link-container">
                                                                    <div style={{width: '514px'}} className="Site-link-container-name">
                                                                        <Input
                                                                            noTitle={true}
                                                                            maxLength={25}
                                                                            white={true}
                                                                            value={item.name}
                                                                            placeholder={"Name"}
                                                                            disabled={index > 0 || this.renders.property('site_links').filter((item) => {return item.show}).length > 3}
                                                                            sign={index === 0}
                                                                            signTitle={this.renders.characters(item.name) +' / 25'}
                                                                            size={'full'}
                                                                            type={"text"}
                                                                            onChange={(value) => {
                                                                                item.name = value.target.value;
                                                                                this.props.onChange(this.state.creatives);
                                                                            }}
                                                                            showKeyDown={true}
                                                                            onKeyDown={(value) => {
                                                                                if (value === 13) {
                                                                                    if(item.name !== "" && item.url !== ""){
                                                                                        item.show = true;
                                                                                        if(this.renders.property('site_links').length < 4){
                                                                                            try{
                                                                                                let id = Math.floor((Math.random() * 9999999999) + 1);
                                                                                                this.state.creatives.search_ads[this.state.selected_language].site_links.unshift({
                                                                                                    name: "",
                                                                                                    url: this.state.site.url,
                                                                                                    id: id
                                                                                                });
                                                                                            }catch (e) {}
                                                                                        }
                                                                                        this.props.onChange(this.state.creatives);
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div style={{marginRight: '10px'}} className="Site-link-container-url">
                                                                        <Input
                                                                            noTitle={true}
                                                                            white={true}
                                                                            value={item.url}
                                                                            placeholder={"Url"}
                                                                            sign={index === 0}
                                                                            signTitle={"Url"}
                                                                            disabled={index > 0 || this.renders.property('site_links').filter((item) => {return item.show}).length > 3}
                                                                            size={'full'}
                                                                            type={"text"}
                                                                            onChange={(value) => {
                                                                                item.url = value.target.value;
                                                                                this.props.onChange(this.state.creatives);
                                                                            }}
                                                                            showKeyDown={true}
                                                                            onKeyDown={(value) => {
                                                                                if (value === 13) {
                                                                                    if(item.name !== "" && item.url !== ""){
                                                                                        item.show = true;
                                                                                        if(this.renders.property('site_links').length < 4){
                                                                                            try{
                                                                                                let id = Math.floor((Math.random() * 9999999999) + 1);
                                                                                                this.state.creatives.search_ads[this.state.selected_language].site_links.unshift({
                                                                                                    name: "",
                                                                                                    url: this.state.site.url,
                                                                                                    id: id
                                                                                                });
                                                                                            }catch (e) {}
                                                                                        }
                                                                                        this.props.onChange(this.state.creatives);
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="Site-link-container-add">
                                                                        {
                                                                            index === 0 && this.renders.property('site_links').filter((item) => {return item.show}).length < 4 &&
                                                                            <div onClick={() => {
                                                                                if(item.name !== "" && item.url !== ""){
                                                                                    item.show = true;
                                                                                    if(this.renders.property('site_links').length < 4){
                                                                                        try{
                                                                                            let id = Math.floor((Math.random() * 9999999999) + 1);
                                                                                            this.state.creatives.search_ads[this.state.selected_language].site_links.unshift({
                                                                                                name: "",
                                                                                                url: this.state.site.url,
                                                                                                id: id
                                                                                            });
                                                                                        }catch (e) {}
                                                                                    }
                                                                                    this.props.onChange(this.state.creatives);
                                                                                }
                                                                            }} className="Button ButtonNeutral ButtonFull">
                                                                                <FeatherIcon color={"#333333"} size={25} icon="plus"/>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            (index !== 0 || this.renders.property('site_links').filter((item) => {return item.show}).length > 3) &&
                                                                            <div onClick={() => {
                                                                                try{
                                                                                    this.state.creatives.search_ads[this.state.selected_language].site_links = this.state.creatives.search_ads[this.state.selected_language].site_links.filter((inner_item) => {
                                                                                        return inner_item.id !== item.id
                                                                                    });
                                                                                    if(this.state.creatives.search_ads[this.state.selected_language].site_links.filter((item) => {return !item.show}).length < 1){
                                                                                        let id = Math.floor((Math.random() * 9999999999) + 1);
                                                                                        this.state.creatives.search_ads[this.state.selected_language].site_links.unshift({
                                                                                            name: "",
                                                                                            url: this.state.site.url,
                                                                                            id: id
                                                                                        });
                                                                                    }
                                                                                }catch (e) {}
                                                                                this.props.onChange(this.state.creatives);
                                                                            }} className="Button ButtonRed ButtonFull">
                                                                                <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </SlideDown>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesSearchList;
