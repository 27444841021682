import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import Dropdown from "../dropdown";
import {calls} from "./calls";
import {campaignRegister} from "../../services/campaignRegister";
import {eventRegister} from "../../services/eventRegister";
import SweetAlert from "sweetalert-react";

class AdvertisingCampaignDetailsClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            client: {},
            loading: true,
        };
    };

    componentWillMount() {
        this.init.clients();
        eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        this.init.data();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            error: nextProps.error
        })
    }

    init = {
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                client: information?(information.client?(information.client.value?information.client.value:{}):{}):{},
                next: information?(information.channels?(information.channels.next?information.channels.next:false):false):false
            })
        },
        clients: () => {
            this.setState({
                clients: [],
                loading: true,
            }, () => {
                calls.getClients().then((response) => {
                    console.log(response);
                    this.setState({
                        toggle: this.state.toggle,
                        clients: response.data,
                        loading: false,
                    });
                }, (error) => {
                    this.setState({
                        loading: false,
                    });
                });
            })
        },
        client: (item) => {
            this.setState({
                loading: true,
            }, () => {
                calls.getClient(item.id).then((response) => {
                    this.setState({
                        loading: false,
                        client: response.data
                    }, () => {
                        this.props.onChange(this.state.client);
                    });
                }, (error) => {});
            })
        }
    };

    functions = {
        save: (key) => {
            let information = campaignRegister.get();
            if(!information){
                information = {};
            }
            if(!information[key]){
                information[key] = {};
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        },
        reset: () => {
            let information = campaignRegister.get();
            delete information.client;
            delete information.site;
            delete information.budget;
            delete information.locations;
            delete information.advanced;
            delete information.targeting;
            delete information.channels;
            delete information.conversion;
            delete information.keywords;
            delete information.languages;
            delete information.creatives;
            delete information.preview;
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        }
    };

    renders = {
        clients: () => {
            try{
                return this.state.clients.filter((item) => {
                    return item.facebook || item.instagram || item.google || item.linkedin
                })
            }catch (e) {
                return []
            }
        }
    };

    render() {
        return (
            <div className="w-full">
                <SweetAlert
                    show={this.state.reset_modal}
                    title="Select new Client"
                    type="warning"
                    text={"All steps will be reset"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            reset_modal: false
                        }, () => {
                            this.functions.reset();
                        })
                    }}
                    onCancel={() => {
                        this.setState({reset_modal: false});
                    }}
                />
                {
                    <div className="relative">
                        <Dropdown
                            filter={false}
                            title={'Select client'}
                            icon={'user'}
                            size={'full'}
                            placeholder={(this.renders.clients().length > 0 && !this.state.loading)?"Select client ..":(this.state.loading?"Loading clients":"No valid clients available")}
                            //removeArrow={(this.renders.clients().length < 1 || this.state.client.name) && !this.state.loading}
                            //disabled={this.state.client.name}
                            loading={this.state.loading}
                            error={this.state.error}
                            options={this.renders.clients()}
                            value={this.state.client}
                            onChange={(value) => {
                                this.init.client(value);
                            }}
                        />
                        {/**
                            this.state.client.name &&
                            <button onClick={() => {
                                this.setState({
                                    client: {}
                                }, () => {
                                    this.props.onChange({});
                                });
                            }} className="absolute bg-opacity-25 bg-red-500 text-red-500 bottom-0 mb-3 mr-3 p-2 right-0 rounded transition-all duration-200 hover:bg-opacity-100 hover:text-white">
                                <FeatherIcon className="stroke-current" size={16} icon="trash"/>
                            </button>
                        */}
                    </div>
                }
                {
                    (!this.state.loading && this.renders.clients().length < 1) &&
                    <div className="Section">
                        <div onClick={() => this.props.history.push('/user/clients/all')} className="Button ButtonFull ButtonNeutral">
                            Go to my clients
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingCampaignDetailsClient;
