import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import {calls} from "./calls";
import Loader from "../loader";

class ClientConversionActionItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: null,
            item: {},
            channel: "",
            audience: {},
            error_modal: false,
            error_message: "",
            loading: false
        };
    };

    componentDidMount() {
        this.setState({
            client: this.props.client,
            channel: this.props.channel,
            item: this.props.item
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item
        })
    }

    init = {};

    functions = {
        removeConversionAction: () => {
            this.setState({
                loading: true
            }, () => {
                calls.removeAudience({channel: this.state.channel, client: this.state.client, audience: this.state.item.id}).then((response) => {
                    this.setState({
                        loading: false
                    }, () => {
                        this.props.onRemove(this.state.item);
                    })
                }, (error) => {
                    this.setState({
                        loading: false,
                        error_modal: true,
                        error_message: error.body.message,
                    });
                });
            })
        },
    };

    renders = {};

    render() {
        return (
            <div style={{padding: '0px', width: '100%'}}>
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ error_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove audience"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            warning_modal: false
                        }, () => {
                            this.functions.removeConversionAction();
                        })
                    }}
                    onCancel={() => {
                        this.setState({warning_modal: false});
                    }}
                />
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', position: 'relative', marginBottom: '10px'}}>
                    {
                        this.state.loading &&
                        <div className="Optimize-Query-Item-Values-Loading-searchTerm">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#388f8a'}
                                loading={true}
                            />
                        </div>
                    }
                    <div className="Audience-add-item">
                        <div className="Audience-add-item-text">
                            {this.state.item.name}
                        </div>
                    </div>
                    <div style={{width: '55px', marginLeft: '10px'}}>
                        <div onClick={() => {
                            this.setState({
                                warning_modal: true
                            })
                        }} className="Button ButtonRed ButtonFull">
                            <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ClientConversionActionItem;
