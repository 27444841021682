import React, {Component} from 'react';
import './style.css';
import {BeatLoader} from "react-spinners";
import {calls} from "./calls";
import CampaignsList from "../../../modules/campaignslist";

const queryString = require('query-string');

class UserCampaignsEnded extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            loading: true,
            loading_more: false,
            page: 0,
            page_size: 10,
            total: 0,
            order: "desc",
            sort: "startDate",
            client: {}
        };
    };

    componentWillMount() {
        let query = window.location.search;
        if (query) {
            query = queryString.parse(query);
        }
        this.setState({
            order: query.order ? query.order : 'desc',
            sort: query.sort ? query.sort : 'startDate'
        }, () => {
            this.functions.campaigns();
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    functions = {
        campaigns: () => {
            calls.getCampaigns(this.state.page, this.state.sort, this.state.order, this.state.page_size).then((response) => {
                this.state.campaigns = this.state.campaigns.concat(response.data);
                this.setState({
                    campaigns: this.state.campaigns,
                    total: response.metadata.count,
                    page: response.metadata.page,
                    loading: false,
                    loading_more: false
                });
            }, (error) => {
                this.setState({
                    campaigns: this.state.campaigns,
                    loading: false,
                    loading_more: false
                });
            });
        }
    };

    renders = {};

    render() {
        return (
            <section className="w-full pt-8 pb-8">
                <div className="">
                    {
                        this.state.loading &&
                        <div className="mt-48 mb-48">
                            <div className="justify-center align-middle flex flex-col text-center">
                                <div className="font-bold mb-2">Loading Campaigns ...</div>
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={17}
                                    color={'#060534'}
                                    loading={true}
                                />
                            </div>
                        </div>
                    }
                    <div className="">
                        {!this.state.loading && this.state.campaigns.length > 0 &&
                        <div className="">
                            {
                                !this.state.loading &&
                                <div className="flex items-center justify-center">
                                    <div className="flex flex-1">
                                        <div>
                                            <h3 className="mb-0 font-bold">
                                                Ended campaigns
                                            </h3>
                                            <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                There are <span
                                                className="font-bold ml-1 mr-1">{this.state.total}</span> drafts
                                                available
                                            </p>
                                        </div>
                                    </div>
                                    <a href="#" onClick={(e) => {
                                        try {
                                            e.preventDefault();
                                            this.props.history.push('/user/campaigns/new');
                                        } catch (e) {}
                                    }} className="btn inline-block btn-primary">+ Create new</a>
                                </div>
                            }
                            {
                                <CampaignsList
                                    campaignitems={this.state.campaigns}
                                    properties={{
                                        name: true,
                                        client: true,
                                        status: false,
                                        start_date: true,
                                        end_date: true,
                                        channels: true,
                                        actions: {open: true, preview: true}
                                    }}
                                    total={this.state.total}
                                    order={this.state.order}
                                    sort={this.state.sort}
                                    callfunc={(item) => {
                                        this.props.history.push('/user/campaigns/' + item.id);
                                    }}
                                    updateSort={(sort, order) => {
                                        this.setState({
                                            campaigns: ((sort !== this.state.sort || order !== this.state.order) && this.state.campaigns.length < this.state.total)?[]:this.state.campaigns,
                                            loading: ((sort !== this.state.sort || order !== this.state.order) && this.state.campaigns.length < this.state.total),
                                            page: ((sort !== this.state.sort || order !== this.state.order) && this.state.campaigns.length < this.state.total)?0:this.state.page,
                                            sort: sort,
                                            order: order
                                        }, () => {
                                            this.props.history.push('?sort='+sort+'&order='+order);
                                            if(this.state.campaigns.length === 0){
                                                this.functions.campaigns();
                                            }
                                        })
                                    }}
                                    clientname={this.state.client.name ? this.state.client.name : ''}
                                />
                            }
                            {
                                !this.state.loading && this.state.campaigns.length < this.state.total &&
                                <div className="mt-5 text-center flex-1 flex align-middle justify-center">
                                    <div onClick={(e) => {
                                        this.setState({
                                            loading_more: true
                                        }, () => {
                                            this.functions.campaigns();
                                        })
                                    }} className="btn btn-primary btn-lg max-w-md flex-1">
                                        {
                                            !this.state.loading_more &&
                                            <div>
                                                Load more ({this.state.campaigns.length}/{this.state.total})
                                            </div>
                                        }
                                        {
                                            this.state.loading_more &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={10}
                                                color={'#ffffff'}
                                                loading={true}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        }
                        {!this.state.loading && this.state.campaigns.length < 1 &&
                        <div className="background-Loader">
                            <div className="background-LoaderText">
                                You dont have any <span className="font-bold text-purple-500">Ended</span> campaigns
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default UserCampaignsEnded;
