import React, {Component} from 'react';
import './style.css';
import {calls} from "./calls";
import Loader from "../../../components/loader";
import CampaignDonuts from "../../../components/campaign-donuts";
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import ReportsList from "../../../modules/reportslist";
import ReportsChart from "../../../modules/reportschart";

class Report extends Component {

    constructor(props) {
        super(props);
        this.state = {
            level: {},
            report: {},
            ad: {},
            include: {
                daily: true,
                gender: true,
                age: true,
                weekday: true
            },
            channels: [],
            properties: [],
            loading: true,
            navToggleActive: "daily",
            navToggle: [
                {
                    title: 'Daily',
                    id: 'daily',
                    icon: 'bar-chart-2'
                },
                {
                    title: 'Gender',
                    id: 'gender',
                    icon: 'users'
                },
                {
                    title: 'Age',
                    id: 'age',
                    icon: 'pie-chart'
                },
                {
                    title: 'Hourly',
                    id: 'hourly',
                    icon: 'watch'
                },
                {
                    title: 'Region',
                    id: 'region',
                    icon: 'map'
                },
                {
                    title: 'Search adgroups',
                    id: 'adgroups',
                    icon: 'search',
                    req: 'google',
                    hide: true
                },
                {
                    title: 'Social ads',
                    id: 'ads',
                    icon: 'grid',
                    req: 'facebook',
                    hide: true
                },
                {
                    title: 'Quality score',
                    id: 'quality',
                    icon: 'star',
                    req: 'google'
                }
            ]
        };
    };

    componentWillMount(){
        this.init.data();
    }

    init = {
        data: () => {
            calls.getReport(this.props.match.params.id).then((response) => {
                this.setState({
                    loading: false,
                    logo: response.data.logo,
                    text: response.data.text,
                    title: response.data.title,
                    status: response.data.status,
                    channels: response.data.channels,
                    level: 'campaign',
                    report: response.data.report,
                    properties: response.data.properties?response.data.properties:[]
                }, () => {
                    if(this.state.properties.filter((item) => {return item === 'date'}).length < 1){
                        this.state.properties.unshift('date');
                    }
                    this.setState({
                        properties: this.state.properties
                    })
                })
            }, (error) => {
                this.setState({
                    loading: false
                })
            });
        }
    };

    functions = {};

    renders = {};

    render(){
        return (
            <div className="flex justify-center flex-col align-middle flex-1 items-center">
                <div className="max-w-6xl w-full mt-8 px-5 box-border">
                    {
                        this.state.loading &&
                        <Loader
                            title={"Loading report"}
                        />
                    }
                    {
                        this.state.logo &&
                        <div className="mt-0 mb-5 max-w-xs">
                            <img style={{width: '100%'}} src={this.state.logo}/>
                        </div>
                    }
                    {
                        this.state.title && this.state.title !== "" &&
                        <h3 className="mb-0 text-2xl mb-3">{this.state.title}</h3>
                    }
                    {
                        this.state.text && this.state.text !== "" &&
                        <p>{this.state.text}</p>
                    }
                    {
                        this.state.status === 'enabled' &&
                        <div className="mt-8">
                            <CampaignDonuts
                                data={this.state.report ? this.state.report : {}}
                                showchannels={false}
                                loading={this.state.loading_report}
                                noShadow={true}
                                properties={this.state.properties}
                            />
                        </div>
                    }
                    {
                        this.state.status === 'enabled' && false &&
                        <div className="flex flex-row items-center flex-wrap">
                            {this.state.report.facebook &&
                            <div
                                className="bg-facebook-100 text-facebook-500 flex flex-row items-center text-xs mr-2 mb-2 font-bold py-1 pl-1 pr-2 rounded">
                                <span className="bg-facebook-500 w-4 h-4 rounded mr-2"></span> Facebook</div>
                            }
                            {this.state.report.instagram &&
                            <div
                                className="bg-instagram-100 text-instagram-500 flex flex-row items-center text-xs mr-2 mb-2 font-bold py-1 pl-1 pr-2 rounded">
                                <span className="bg-instagram-500 w-4 h-4 rounded mr-2"></span> Instagram</div>
                            }
                            {this.state.report.google &&
                            <div
                                className="bg-google-100 text-google-500 flex flex-row items-center text-xs mb-2 font-bold py-1 pl-1 pr-2 rounded">
                                <span className="bg-google-500 w-4 h-4 rounded mr-2"></span> Google</div>
                            }
                        </div>
                    }
                    {
                        this.state.status === 'enabled' &&
                        <nav className="py-2">
                            <ul className="flex flex-row items-center overflow-scroll w-full whitespace-no-wrap">
                                {this.state.navToggle.filter((item) => {
                                    return !(item.req && item.req.length > 0 && !this.state.report[item.req]) && !item.hide
                                }).map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <button onClick={() => {
                                                this.setState({
                                                    navToggleActive: item.id
                                                })
                                            }}
                                                    className={cx("cursor-pointer py-2 px-4 hover:opacity-100 font-bold text-sm border-b-2 flex flex-row items-center focus:outline-none", {
                                                        ["border-purple-500 opacity-100 text-purple-500"]: item.id === this.state.navToggleActive,
                                                        ["border-gray-300 opacity-50"]: item.id != this.state.navToggleActive,
                                                        //["hidden"]: item.req && item.req.length > 0 && !this.state.report[item.req]
                                                    })}>
                                                <FeatherIcon className="stroke-current mr-2" size={15}
                                                             icon={item.icon}/>
                                                <span className="text-gray-900">{item.title}</span>
                                            </button>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>
                    }

                    {/* Daily */}
                    {this.state.status === 'enabled' && this.state.navToggleActive == "daily" &&
                    <ReportsList
                        headline="Daily statistics"
                        report={this.state.report}
                        level={'campaign'}
                        properties={this.state.properties}
                        toggleChart={true}
                        showChartType={true}
                    />
                    }

                    {/* Gender */}
                    {this.state.status === 'enabled' &&this.state.navToggleActive == "gender" &&
                    <ReportsChart
                        headline="Gender"
                        report={this.state.report}
                        level={'campaign'}
                        properties={this.state.properties.filter((key) => {return key !== 'date'})}
                        type={'gender'}
                    />
                    }
                    {/* Age */}
                    {this.state.status === 'enabled' &&this.state.navToggleActive == "age" &&
                    <ReportsChart
                        headline="Age"
                        report={this.state.report}
                        level={'campaign'}
                        properties={this.state.properties.filter((key) => {return key !== 'date'})}
                        type={'age'}
                    />
                    }
                    {/* Hourly */}
                    {this.state.status === 'enabled' &&this.state.navToggleActive == "hourly" &&
                    <ReportsChart
                        headline="Hourly"
                        report={this.state.report}
                        level={'campaign'}
                        properties={this.state.properties.filter((key) => {return key !== 'date'})}
                        type={'hourly'}
                    />
                    }
                    {/* Region */}
                    {this.state.status === 'enabled' &&this.state.navToggleActive == 'region' &&
                    <ReportsChart
                        headline="Region"
                        report={this.state.report}
                        level={'campaign'}
                        properties={this.state.properties.filter((key) => {return key !== 'date'})}
                        type={'region'}
                        channels={Object.keys(this.state.report).map((channel) => {
                            return {name: channel, value: channel}
                        })}
                    />
                    }
                </div>
            </div>
        )
    }
}

export default Report;
