import React, {Component, useRef} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from 'feather-icons-react';
import {BeatLoader} from "react-spinners";
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconLinkedin from "../icons/linkedin";

const fuzzysort = require('fuzzysort');

class DropdownMultiSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showType: false,
            showSearch: false,
            placeholderType: "",
            placeholderSearch: "",
            search: "",
            title: "",
            type: {},
            types: [],
            selected: [],
            options: [],
            channels: [],
            skeleton_items: [
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
                {name: "Adcredo advertising made simple"},
            ]
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            loading: nextProps.loading,
            showType: nextProps.showType,
            showSearch: nextProps.showSearch,
            placeholderType: nextProps.placeholderType,
            placeholderSearch: nextProps.placeholderSearch,
            search: nextProps.search,
            title: nextProps.title,
            type: nextProps.type,
            types: nextProps.types,
            selected: nextProps.selected,
            options: nextProps.options,
            channels: nextProps.channels
        }, () => {
            //console.log(this.state);
        })
    }

    componentDidMount() {
        this.setState({
            loading: this.props.loading,
            showType: this.props.showType,
            showSearch: this.props.showSearch,
            placeholderType: this.props.placeholderType,
            placeholderSearch: this.props.placeholderSearch,
            search: this.props.search,
            title: this.props.title,
            type: this.props.type,
            types: this.props.types,
            selected: this.props.selected,
            options: this.props.options,
            channels: this.props.channels
        }, () => {
            //console.log(this.state);
        })
    }

    init = {};

    functions = {
        focus: () => {
            try{
                setTimeout(() => {
                    document.getElementById("inputField").focus();
                }, 100);
            }catch (e) {
                console.log(e);
            }
        }
    };

    renders = {
        name: (name) => {
            try {
                const n = name.toUpperCase();
                const q = this.state.search.toUpperCase();
                const x = n.indexOf(q);
                if (!q || x === -1) {
                    return name; // bail early
                }
                const l = q.length;
                return '<span class="opacity-75">' + name.substr(0, x) + '</span>' + '<span class="">' + name.substr(x, l) + '</span>' + '<span class="opacity-75">' + name.substr(x + l) + '</span>';
            } catch (e) {
                return name;
            }
        },
        markdown: (input) => {
            return {__html: input}
        },
        selected: (item) => {
            try{
                return this.state.selected.filter((inner_item) => {
                    return item.id === inner_item.id
                }).length > 0
            }catch (e) {
                return false;
            }
        },
        options: (options) => {
            let keywords = [];
            if(this.props.showAllOnFocus && this.state.on_focus && this.state.search === ""){
                keywords = options;
            }else{
                let result = fuzzysort.go(this.state.search, options.map((item) => {return item.name}));
                let old_keywords = JSON.parse(JSON.stringify(options));
                result.map((item) => {
                    for(let i = 0; i < old_keywords.length; i++){
                        if(item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()){
                            let html_string = "";
                            if(Array.isArray(item.indexes) && item.indexes.length > 0){
                                for (let m = 0; m < item.target.length; m++) {
                                    if(!item.indexes.includes(m)){
                                        html_string = html_string  + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                    }else{
                                        html_string = html_string  + '<span class="">' + item.target.charAt(m) + '</span>';
                                    }
                                }
                            }
                            old_keywords[i].html = html_string;
                            keywords.push(old_keywords[i]);
                            old_keywords.splice(i, 1);
                            break;
                        }
                    }
                });
            }
            return keywords;
        }
    };

    render() {
        return (
            <div className="flex w-full flex-col">
                {
                    <div className="flex flex-row">
                        <div className={
                            cx("font-bold text-xs mb-1",
                                {
                                    ["text-red-500"]: this.state.error
                                })
                        }>
                            {this.state.title}
                        </div>
                        {
                            Array.isArray(this.state.channels) &&
                            <div className={cx("InputContainerTitleIcons")}>
                                {
                                    this.state.channels.map((item) => {
                                        if (item === 'google') {
                                            return (
                                                <div style={{marginLeft: '3px'}}>
                                                    <IconGoogle noMargin={true}/>
                                                </div>
                                            )
                                        }
                                        if (item === 'facebook') {
                                            return (
                                                <div style={{marginLeft: '3px'}}>
                                                    <IconFacebook noMargin={true}/>
                                                </div>
                                            )
                                        }
                                        if (item === 'instagram') {
                                            return (
                                                <div style={{marginLeft: '3px'}}>
                                                    <IconInstagram noMargin={true}/>
                                                </div>
                                            )
                                        }
                                        if (item === 'linkined') {
                                            return (
                                                <div style={{marginLeft: '3px'}}>
                                                    <IconLinkedin noMargin={true}/>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }
                    </div>
                }
                {
                    this.state.selected.length > 0 &&
                    <div style={{minHeight: "55px"}} className={((this.state.options.length > 0 || this.state.loading) ?"":"rounded-t") +" rounded-t flex px-2 pt-2 pb-0 flex-row border-l flex-wrap border-r border-t flex-1 align-middle items-center"}>
                        {
                            this.state.selected.map((item) => {
                                return (
                                    <div>
                                        <div className="flex flex-1 mb-2">
                                            {
                                                item.parent &&
                                                <div className="bg-purple-300 px-2 flex flex-row rounded-l align-middle items-center">
                                                    <div className="text-white font-bold text-xs">
                                                        {item.parent}
                                                    </div>
                                                </div>
                                            }
                                            <div className={(item.parent?"rounded-r":"rounded")+" bg-purple-500 px-2 flex flex-row mr-2 align-middle items-center"}>
                                                <div className="text-white font-bold text-xs mr-3">
                                                    {item.name}
                                                </div>
                                                <div onClick={() => {
                                                    if(this.props.onRemove){
                                                        this.props.onRemove(item);
                                                    }
                                                }} className="close cursor-pointer text-white flex items-center justify-center text-xl pr-1">
                                                    &times;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                <div className={"border " + (!Array.isArray(this.state.selected) || this.state.selected.length < 1?" rounded-t ":"") + (!Array.isArray(this.state.options) || this.state.options.length < 1 ? " rounded-b ":"") + "flex flex-row"}>
                    {
                        this.state.showType &&
                        <div className="relative flex flex-1">
                            <input
                                type={'text'}
                                autoComplete="off"
                                readOnly={true}
                                placeholder={this.state.placeholderType}
                                disabled={this.state.disabled}
                                onChange={(value) => {

                                }}
                                value={this.state.type.name?this.state.type.name:""}
                                onFocus={() => {
                                    this.setState({
                                        open: true
                                    })
                                }}
                                onBlur={() => {
                                    this.setState({
                                        open: false
                                    })
                                }}
                                className={
                                    cx("w-full transition-all rounded-l border-0 cursor-pointer duration-200 py-4 px-6 text-purple-900",
                                        {
                                            ["bg-white"]: this.props.white,
                                            //["rounded-r"]: !this.state.type.name,
                                            //["rounded-t"]: this.state.selected.length < 1
                                        })
                                }
                            />
                            <div className="DropdownContainerSelectorArrow top-0">
                                <FeatherIcon className="cursor-pointer" color={"#333333"} size={25} icon="chevron-down"
                                             onClick={() => {
                                                 this.setState({
                                                     open: !this.state.open
                                                 })
                                             }}/>
                            </div>
                            {
                                this.state.open && this.state.types.length > 0 &&
                                <div style={{maxHeight: '300px', backgroundColor: "#fcfcfc", top: `calc(100%)`}} className="z-10 w-full border overflow-scroll shadow-lg w-full absolute rounded-b">
                                    {
                                        this.state.types.map((item, i) => {
                                            return (
                                                <div key={i} onMouseDown={() => {
                                                    if (this.props.onType) {
                                                        this.props.onType(item);
                                                    }
                                                }} className="DropdownContainerDropdownItem">
                                                    {item.name}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.showType && this.state.type.name && this.state.showSearch &&
                        <div className="border-r"/>
                    }
                    {
                        this.state.showSearch &&
                        <div className={((!this.state.showType || this.state.type.name)?"flex-1":"w-0") + " relative flex overflow-hidden"}>
                            <input
                                id={'inputField'}
                                type={'text'}
                                autoComplete="off"
                                onFocus={() => {
                                    this.setState({
                                        on_focus: true
                                    })
                                }}
                                placeholder={this.state.placeholderSearch}
                                disabled={this.state.disabled}
                                value={this.state.search}
                                onChange={(value) => {
                                    if (this.props.onSearch) {
                                        this.props.onSearch(value.target.value);
                                    }
                                }}
                                className={
                                    cx("rounded w-full border-0 transition-all duration-200 py-4 px-6 text-purple-900",
                                        {
                                            ["bg-white"]: this.props.white
                                        })
                                }
                            />
                            <div className="DropdownContainerSelectorArrow top-0">
                                {
                                    ((!this.state.on_focus && this.props.showAllOnFocus) || (!this.props.showAllOnFocus && this.state.search === "")) &&
                                    <FeatherIcon className="cursor-pointer" color={"#333333"} size={25} icon="search"/>
                                }
                                {
                                    ((this.state.on_focus && this.props.showAllOnFocus) || (!this.props.showAllOnFocus && this.state.search !== "")) &&
                                    <FeatherIcon onClick={() => {
                                        this.setState({
                                            on_focus: false
                                        }, () => {
                                            if(this.props.onSearch){
                                                this.props.onSearch("");
                                            }
                                        })
                                    }} className="cursor-pointer" color={"#333333"} size={25} icon="x"/>
                                }
                            </div>
                        </div>
                    }
                </div>
                {
                    ((this.state.on_focus && this.props.showAllOnFocus && this.state.showSearch) || (this.state.options.length > 0 || this.state.loading) && ((this.state.search && this.state.search !== "" && this.state.showSearch) || !this.state.showSearch)) &&
                    <div className="relative flex flex-row border-l flex-wrap border-r border-b flex-1 align-middle items-center p-4 pb-2 rounded-b">
                        {
                            this.state.loading &&
                            <div style={{backdropFilter: `blur(5px)`}} className="absolute left-0 right-0 top-0 bottom-0 z-10 flex justify-center align-middle items-center">
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={12}
                                    color={'#060534'}
                                    loading={true}
                                />
                            </div>
                        }
                        {
                            (this.state.loading?this.state.skeleton_items:this.renders.options(this.state.options)).map((item) => {
                                return(
                                    <div className="flex flex-row w-1/4 items-center align-middle mb-2 truncate pr-2">
                                        <div onClick={() => {
                                            if(this.props.onSelect){
                                                if(this.renders.selected(item)){
                                                    this.props.onRemove(item);
                                                }else{
                                                    this.props.onSelect(item);
                                                }
                                            }
                                        }} style={{minHeight: "2rem", minWidth: '2rem'}} className={(this.renders.selected(item) ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                            {
                                                this.renders.selected(item) &&
                                                <FeatherIcon className={'stroke-current'} size={14} icon="check"/>
                                            }
                                        </div>
                                        <div dangerouslySetInnerHTML={this.renders.markdown(item.html?item.html:this.renders.name(item.name))} className="font-bold text-xs ml-2 truncate"/>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

export default DropdownMultiSelect;
