import React, { Component } from 'react';
import { CameraIcon } from '@heroicons/react/solid'
import { ChevronRightIcon, PlayIcon, PauseIcon, RefreshIcon } from '@heroicons/react/outline';

class PreviewSnapchatStories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: {},
            video: null,
            picture: null,
            body: "",
            cta: {},
            title: "",
            ad: {},
            brandname: "",
            attachment: {},
            show_overlay: false,
            video_ended: false,
            video_playing: false,
        }
    }

    async componentDidMount() {
        await this.promisedSetState({
            page: this.props.page,
            video: this.props.video,
            picture: this.props.picture,
            body: this.props.body,
            cta: this.props.cta,
            title: this.props.title,
            ad: this.props.ad,
            brandname: this.props.brandname,
            attachment: this.props.attachment,
            show_overlay: this.props.show_overlay,
            video_ended: this.props.video_ended,
            video_playing: this.props.video_playing,
        })

        setTimeout(() => {
            try {
                if (this.refs.video) {
                    this.refs.video.setAttribute("muted", true);
                }
                this.refs.video.addEventListener('ended', this.functions.videoEnded, false);
            } catch (error) {
                console.log(error)
            }
        }, 200)
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            page: nextProps.page,
            video: nextProps.video,
            picture: nextProps.picture,
            body: nextProps.body,
            cta: nextProps.cta,
            title: nextProps.title,
            ratio: nextProps.ratio,
            ad: nextProps.ad,
            brandname: nextProps.brandname,
            attachment: nextProps.attachment,
            show_overlay: nextProps.show_overlay,
            video_ended: nextProps.video_ended,
            video_playing: nextProps.video_playing,
        })

    };

    functions = {
        handleClickVideo: async () => {
            if (this.refs.video.ended) {
                await this.promisedSetState({ video_ended: true, video_playing: false });
                this.refs.video.currentTime = 0;
                this.refs.video.play();
                await this.promisedSetState({ video_ended: false, video_playing: true });
            } else if (this.refs.video.paused) {
                this.refs.video.play();
                await this.promisedSetState({ video_playing: true });
            } else {
                this.refs.video.pause();
                await this.promisedSetState({ video_playing: false });
            }
        },
        videoEnded: async () => {
            await this.promisedSetState({ video_ended: true, video_playing: false })
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    truncText(string, maxLength) {
        if (string.length > maxLength) {
            return string.substring(0, maxLength) + "..."
        }
        else {
            return string
        }
    }

    render() {
        return (
            <>
                <div
                    className='flex flex-col justify-center'
                >
                    <div className="relative flex flex-col border w-80 rounded-md overflow-hidden ">
                        <div
                            style={{ zIndex: 1 }}
                            className="absolute top-0 flex p-2 w-full">
                            <div className="h-10 w-10 mr-2">
                                {
                                    !this.state.page.image &&
                                    <div className='border rounded-full h-10 w-10'></div>
                                }
                                {
                                    this.state.page.image &&
                                    <img src={this.state.page.image}
                                        className="rounded-full"
                                        alt={this.state.page.name}
                                    />
                                }

                            </div>
                            <div className="text-sm text-white">
                                <div>
                                    {this.state.brandname && this.state.brandname !== "" ? this.truncText(this.state.brandname, 32) : this.state.page.name}
                                </div>
                                <div className="text-xxs">{this.truncText(this.state.title && this.state.title ? this.state.title : "Headline required...", 34)}</div>
                            </div>
                        </div>
                        <div
                            onMouseEnter={async () => {
                                await this.promisedSetState({
                                    show_overlay: true
                                });
                            }}
                            onMouseLeave={async () => {
                                await this.promisedSetState({
                                    show_overlay: false
                                });
                            }}
                            style={{ marginBottom: "73px", minHeight: "550px" }}
                            className="flex justify-center items-center w-full h-full"
                        >
                            {
                                this.state.video &&
                                <>
                                    {
                                        this.state.show_overlay &&
                                        <div
                                            className="absolute z-50 h-full w-full flex flex-col justify-center items-center bg-black bg-opacity-0 hover:bg-opacity-25 transition-all duration-300">
                                            <div
                                                onClick={() => this.functions.handleClickVideo()}
                                                className="z-60 flex flex-col justify-center items-center w-12 h-12 rounded-full bg-white opacity-75 cursor-pointer">
                                                {
                                                    !this.state.video_playing && !this.state.video_ended &&
                                                    <PlayIcon className="text-gray-600 h-12 w-12 stroke-1" />
                                                }
                                                {
                                                    this.state.video_playing &&
                                                    <PauseIcon className="text-gray-600 h-12 w-12 stroke-1" />
                                                }
                                                {
                                                    this.state.video_ended && !this.state.video_playing &&
                                                    <RefreshIcon className="text-gray-600 h-10 w-10 stroke-1" />
                                                }
                                            </div>
                                        </div>
                                    }
                                    <video src={this.state.video} autoplay={false} controls={false} muted className="w-full" ref="video" />
                                </>
                            }
                            {
                                this.state.picture && this.state.picture !== null &&
                                <img src={this.state.picture} className='w-full' />
                            }
                        </div>
                        {
                            this.state.attachment && this.state.attachment.value === "WEB_VIEW" &&
                            <div className='absolute bottom-0 w-full'>
                                <div className='flex m-2 justify-between'>
                                    <div className='flex h-20 w-5/6 p-2 rounded-lg bg-black bg-opacity-25 items-center justify-center'>
                                        <div className='bg-black w-1/4 rounded-md'>
                                            <img src={this.state.page.image} className='w-full rounded-md' />
                                        </div>
                                        <div className="flex flex-col flex-1 self-center p-1 text-sm text-white">
                                            <div className='pl-1 text-sm text-left'>
                                                {this.state.brandname && this.state.brandname ? this.truncText(this.state.brandname, 32) : this.state.page.name}
                                                <div className="text-xxs">
                                                    {this.truncText(this.state.title && this.state.title ? this.state.title : "Headline required...", 34)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-0 align-middle'>
                                            <ChevronRightIcon className='w-5 text-white' />
                                        </div>
                                    </div>
                                    <div className='text-xxs text-white self-end'>
                                        Ad
                                    </div>
                                </div>
                                <div className='flex flex-col w-full rounded-t-lg pb-4 bg-black'>
                                    <div className='bg-gray-500 rounded-full h-1 w-10 mx-auto my-1.5'></div>
                                    <div className="flex justify-between items-center px-2">
                                        <div className="py-3 px-4 rounded-full bg-gray-200 bg-opacity-25">
                                            <CameraIcon className='w-5 text-white' />
                                        </div>
                                        <div
                                            style={{ width: 185 }}
                                            className="py-3 rounded-full text-white text-center font-semibold text-sm bg-gray-200 bg-opacity-25">
                                            {this.state.cta ? this.state.cta.name : ''}
                                        </div>
                                        <div className="py-3 px-4 rounded-full bg-gray-200 bg-opacity-25">
                                            <ChevronRightIcon className='w-5 text-white' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.attachment && this.state.attachment.value === "NONE" &&
                            <div className='absolute bottom-0 w-full'>
                                <div className='flex m-2 justify-end'>
                                    <div className='text-xxs text-white'>
                                        Ad
                                    </div>
                                </div>
                                <div className='flex flex-col w-full rounded-t-lg pb-4 bg-black'>
                                    <div className='bg-gray-500 rounded-full h-1 w-1/3 mx-auto mt-2'></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>


        )
    }
}

export default PreviewSnapchatStories;