import React, { Component, Fragment } from 'react';
import { ChevronDownIcon, SearchIcon, SortAscendingIcon, PlusIcon, ChevronUpIcon, TrashIcon, UserGroupIcon, IdentificationIcon, ArrowLeftIcon, ArrowRightIcon, CheckIcon, CalendarIcon, CheckCircleIcon, ExclamationCircleIcon, AdjustmentsIcon, ClipboardListIcon, LockClosedIcon, LockOpenIcon, MinusCircleIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { UserIcon } from '@heroicons/react/outline';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import { Menu, Transition } from '@headlessui/react';
import cn from "classnames";
import moment from "moment";
import { clientRegister } from '../services/clientRegister';
import CreateClient from '../moduleFiles/createClient';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import { Doughnut, Line } from 'react-chartjs-2';
import InputTailwind from '../moduleFiles/inputTailwind';

class ExternalClientPerformanceList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            view: { id: 1, name: "Clients", value: "Clients" },
            logo: "",
            password: "",
            correct_password: false,
            password_needed: true,
            loading: false,
            error: false,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            sort: "spend",
            direction: "descending",
            search: "",
            dateSpan: { id: 1, name: "This month (default)", value: "this_month" },
            metric: { id: 1, name: "Spend", value: "spend" },
            channel: { id: 1, name: "All channels", value: "all" },
            columns: [
                { name: 'name', value: 'name', min_width: '350px', border: true },
                { name: 'Spend', value: 'spend', min_width: '200px', border: true },
                { name: 'Impressions', value: 'impressions', min_width: '200px', border: true },
                { name: 'Click', value: 'clicks', min_width: '200px', border: true },
                { name: 'CTR', value: 'ctr', min_width: '200px', border: true },
                { name: 'CPC', value: 'cpc', min_width: '200px', border: true },
                { name: 'CPM', value: 'cpm', min_width: '200px', border: true },
            ],
            client: {},
            doughnuts: {},
            doughnut: {
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                tooltips: {
                    enabled: false,
                    custom: function (tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            tooltipEl.style.backgroundColor = "#FFFFFF";
                            tooltipEl.style.borderRadius = "5px";
                            tooltipEl.style.boxShadow = "0 3px 30px -5px rgba(0,0,0,.1)";
                            tooltipEl.style.fontWeight = "bold";
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(getBody);

                            var innerHtml = '<thead>';

                            titleLines.forEach(function (title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(function (body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var style = 'background:' + colors.backgroundColor;
                                style += '; border-color:' + colors.borderColor;
                                style += '; border-width: 2px';
                                var span = '<span style="' + style + '"></span>';
                                innerHtml += '<tr><td>' + span + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                },
                cutoutPercentage: 55,
            },
            chart_metric: "spend",
            chart: {}
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.functions.getAgencyPublic();
    }

    functions = {
        getAgencyPublic: async () => {
            await this.promisedSetState({ loading: true });
            try {
                let agency = window.location.pathname.replace("/performance/", "");
                let response = await this.calls.getAgencyPublic(agency);
                await this.promisedSetState({
                    logo: response.data.logo
                });
            } catch (error) {
                this.setState({
                    error: true,
                    error_message: "Not a valid link"
                });
            }
            await this.promisedSetState({ loading: false });
        },
        getExternalClientPerformance: async (init, partial) => {
            await this.promisedSetState({ loading_password: init, loading_partial: !init, loading_chart: true });
            try {
                let agency = window.location.pathname.replace("/performance/", "");
                let response = await this.calls.getExternalClientPerformance(agency, partial);
                await this.promisedSetState({
                    doughnuts: response.data.charts && response.data.charts.donuts ? response.data.charts.donuts : {},
                    total: response.data.charts && response.data.charts.total ? response.data.charts.total : {},
                    chart: response.data.charts && response.data.charts.main ? response.data.charts.main : {},
                    clients: response.data.clients.map((item) => {
                        if ("total_clicks" in item) {
                            item.clicks = item.total_clicks;
                        }
                        if (item.metrics && "clicks" in item.metrics) {
                            item.clicks = item.metrics.clicks;
                        }
                        if ("total_impressions" in item) {
                            item.impressions = item.total_impressions;
                        }
                        if (item.metrics && "impressions" in item.metrics) {
                            item.impressions = item.metrics.impressions;
                        }
                        if ("total_spend" in item) {
                            item.spend = item.total_spend;
                        }
                        if (item.metrics && "spend" in item.metrics) {
                            item.spend = item.metrics.spend;
                        }
                        if ("total_ctr" in item) {
                            item.ctr = item.total_ctr;
                        }
                        if (item.metrics && "ctr" in item.metrics) {
                            item.ctr = item.metrics.ctr;
                        }
                        if ("total_cpc" in item) {
                            item.cpc = item.total_cpc;
                        }
                        if (item.metrics && "cpc" in item.metrics) {
                            item.cpc = item.metrics.cpc;
                        }
                        if ("total_cpm" in item) {
                            item.cpm = item.total_cpm;
                        }
                        if (item.metrics && "cpm" in item.metrics) {
                            item.cpm = item.metrics.cpm;
                        }
                        return item;
                    }),
                    meta: response.meta,
                    correct_password: response.data.correct_password
                });
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_password: false, loading_partial: false, loading_chart: false });
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
    };

    calls = {
        getAgencyPublic: (agency) => {
            let options = apiRegister.options(null, 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgencyPublic?agency=" + agency;
            return apiRegister.call(options, url);
        },
        getExternalClientPerformance: (agency, partial) => {
            let options = apiRegister.options(null, 'POST', { password: this.state.password });
            let url = apiRegister.url.api + "/v3/adcredo/getExternalClientPerformance?agency=" + agency + (partial ? "&partial=" + partial : "") + "&limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&dateSpan=" + this.state.dateSpan.value + "&view=" + this.state.view.value.toLowerCase();
            return apiRegister.call(options, url);
        }
    }

    renders = {
        convertToDecimal: (value) => {
            try {
                value = +value;
                return value.toFixed(2);
            } catch (error) {
                return value;
            }
        },
        addCommas(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ' ' + '$2');
            }
            return x1 + x2;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="relative flex flex-1 flex-col">

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {/*ERROR*/}
                {
                    !this.state.loading &&
                    this.state.error &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div className="font-semibold text-lg mt-3">Not a valid link</div>
                    </div>
                }

                {/*PASSWORD VIEW*/}
                {
                    !this.state.error &&
                    !this.state.loading &&
                    !this.state.correct_password &&
                    <div className="fixed left-0 right-0 bottom-0 top-0 flex justify-center items-center">
                        <div className="flex flex-col justify-center w-full max-w-md">
                            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                <img
                                    className="mx-auto h-20 w-auto"
                                    src={this.state.logo}
                                    alt="Workflow"
                                />
                            </div>
                            <div className="mt-8 w-full">
                                <div className="bg-white p-6 shadow rounded-lg">
                                    <div className="">
                                        <div className="mt-1">
                                            <InputTailwind
                                                label={"Password"}
                                                value={this.state.password}
                                                onChange={(value) => {
                                                    this.setState({
                                                        password: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="w-full mt-6">
                                            <button
                                                onClick={() => {
                                                    if (this.state.password !== "") {
                                                        this.functions.getExternalClientPerformance(true, false);
                                                    }
                                                }}
                                                className={(this.state.password !== "" ?
                                                    "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " w-full shadow relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                }
                                            >
                                                Verify
                                                {
                                                    this.state.loading_password &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                        <div>
                                            <div>
                                                {this.state.error && (
                                                    <div
                                                        className="block text-sm font-medium text-red-700 text-center"
                                                    >
                                                        {this.state.error_message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*VIEW*/}
                {
                    !this.state.error &&
                    this.state.correct_password &&
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        <div className="mb-4 shadow bg-white rounded-lg w-full flex flex-col">
                            <div className="sm:h-16 py-4 sm:py-0 px-4 border-b flex flex-col sm:flex-row sm:items-center">
                                <div className="flex flex-row flex-1 items-center mb-4 sm:mb-0">
                                    <div className="mr-4">
                                        <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                            <img src={this.state.logo} className="bg-cover" />
                                        </div>
                                    </div>
                                    <div className="font-semibold text-sm">
                                        {this.state.view && this.state.view.value ? this.state.view.name : "Clients"} performance data
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <Menu as="div" className="sm:ml-3 relative">
                                        <div>
                                            <Menu.Button className="max-w-xs h-10 px-4 border-1.5 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-1 rounded-md bg-gray-100 bg-opacity-50">
                                                <ClipboardListIcon className="w-5 h-5" />
                                                <span className="ml-3 text-gray-700 text-sm font-medium block">
                                                    {this.state.view && this.state.view.value ? this.state.view.name : "Clients"}
                                                </span>
                                                <ChevronDownIcon
                                                    className="flex-shrink-0 ml-2 h-5 w-5 text-gray-700 block"
                                                    aria-hidden="true"
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="origin-top-right absolute overflow-hidden z-50 border-1.5 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {
                                                    [
                                                        { id: 1, name: "Clients", value: "Clients" },
                                                        { id: 2, name: "Accounts", value: "Accounts" },
                                                        //{ id: 3, name: "Campaigns", value: "Campaigns" }
                                                    ].map((item) => {
                                                        return (
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <div
                                                                        onClick={async (e) => {
                                                                            await this.promisedSetState({
                                                                                view: item,
                                                                                limit: 10,
                                                                                page: 1,
                                                                                sort: "spend",
                                                                                direction: "descending",
                                                                            });
                                                                            this.functions.getExternalClientPerformance();
                                                                        }}
                                                                        className={classNames(
                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                            'block px-4 py-3 text-sm cursor-pointer'
                                                                        )}
                                                                    >
                                                                        {item.name}
                                                                    </div>
                                                                )}
                                                            </Menu.Item>
                                                        )
                                                    })
                                                }
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                                {
                                    false &&
                                    this.state.view.value === "Accounts" &&
                                    <div className="flex items-center">
                                        <Menu as="div" className="sm:ml-3 relative">
                                            <div>
                                                <Menu.Button className="max-w-xs h-10 px-4 border-1.5 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-1 rounded-md bg-gray-100 bg-opacity-50">
                                                    <ClipboardListIcon className="w-5 h-5" />
                                                    <span className="ml-3 text-gray-700 text-sm font-medium block">
                                                        {this.state.channel && this.state.channel.value ? this.state.channel.name : "All"}
                                                    </span>
                                                    <ChevronDownIcon
                                                        className="flex-shrink-0 ml-2 h-5 w-5 text-gray-700 block"
                                                        aria-hidden="true"
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="origin-top-right absolute overflow-hidden z-50 border-1.5 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {
                                                        [
                                                            { id: 1, name: "Total & channels", value: "all" },
                                                            { id: 2, name: "Total only", value: "total" },
                                                            { id: 3, name: "Google only", value: "google" },
                                                            { id: 4, name: "Facebook only", value: "facebook" },
                                                        ].map((item) => {
                                                            return (
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <div
                                                                            onClick={async (e) => {
                                                                                await this.promisedSetState({
                                                                                    channel: item
                                                                                });
                                                                            }}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                'block px-4 py-3 text-sm cursor-pointer'
                                                                            )}
                                                                        >
                                                                            {item.name}
                                                                        </div>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        })
                                                    }
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                }
                                <div className="flex flex-row mt-2 sm:mt-0">
                                    <div className="flex items-center">
                                        <Menu as="div" className="ml-3 relative">
                                            <div>
                                                <Menu.Button className="max-w-xs h-10 px-4 border-1.5 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-1 rounded-md bg-gray-100 bg-opacity-50">
                                                    <CalendarIcon className="w-5 h-5" />
                                                    <span className="ml-3 text-gray-700 text-sm font-medium block">
                                                        {this.state.dateSpan && this.state.dateSpan.value ? this.state.dateSpan.name : "Last 7 days"}
                                                    </span>
                                                    <ChevronDownIcon
                                                        className="flex-shrink-0 ml-2 h-5 w-5 text-gray-700 block"
                                                        aria-hidden="true"
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="origin-top-right absolute overflow-hidden z-50 border-1.5 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    {
                                                        [
                                                            { id: 1, name: "This month (default)", value: "this_month" },
                                                            { id: 2, name: "This week", value: "this_week" },
                                                            { id: 3, name: "Yesterday", value: "yesterday" },
                                                            { id: 4, name: "Last week", value: "last_week" },
                                                            { id: 5, name: "Last month", value: "last_month" }
                                                        ].map((item) => {
                                                            return (
                                                                <Menu.Item>
                                                                    {({ active }) => (
                                                                        <div
                                                                            onClick={async (e) => {
                                                                                await this.promisedSetState({
                                                                                    dateSpan: item,
                                                                                    page: 1
                                                                                });
                                                                                this.functions.getExternalClientPerformance(false, false);
                                                                            }}
                                                                            className={classNames(
                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                'block px-4 py-3 text-sm cursor-pointer'
                                                                            )}
                                                                        >
                                                                            {item.name}
                                                                        </div>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        })
                                                    }
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*DONUTS*/}
                        {
                            this.state.view.value === "Accounts" &&
                            <div className="grid grid-cols-5 gap-4 mb-4">
                                {
                                    ["impressions", "clicks", "spend", "CTR", "CPC"].map((metric) => {
                                        return (
                                            <div className="col-span-1 relative">
                                                {
                                                    this.state.loading_chart &&
                                                    <div className="text-center absolute top-0 bottom-0 z-51 bg-white rounded-lg  bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <div
                                                    onClick={async () => {
                                                        await this.promisedSetState({
                                                            disable_chart: true,
                                                            chart_metric: metric
                                                        })
                                                        await this.functions.sleep(1000);
                                                        await this.promisedSetState({
                                                            disable_chart: false,
                                                        })
                                                    }}
                                                    className={"bg-white shadow rounded-lg relative px-4 py-6 flex w-full h-full cursor-pointer"}>
                                                    <div className="data--item__donut flex flex-row justify-between items-center w-full">
                                                        <div className="mr-4 leading-none">
                                                            <div className="font-bold text-xl md:text-xl xl:text-2xl">{this.state.total && this.state.total[metric.toLowerCase()] ? this.renders.addCommas(this.state.total[metric.toLowerCase()]) : "-"}</div>
                                                            <span className="text-xs xl:text-sm capitalize">{metric}</span>
                                                        </div>
                                                        <div className="flex">
                                                            <Doughnut
                                                                width={100}
                                                                height={100}
                                                                options={this.state.doughnut}
                                                                data={this.state.doughnuts[metric.toLowerCase()]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="p-4 pb-2 absolute bottom-0 left-0 right-0">
                                                        <div className={(this.state.chart_metric == metric ? "bg-purple-500" : (this.state.hover == metric ? "bg-purple-200" : "bg-white")) + " h-0.5 w-full rounded"}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }

                        {
                            this.state.view.value === "Accounts" &&
                            this.state.chart &&
                            this.state.chart[this.state.chart_metric.toLowerCase()] &&
                            <div className="mb-4 shadow bg-white rounded-lg w-full flex flex-col overflow-hidden relative">
                                {
                                    (this.state.loading_chart || this.state.disable_chart) &&
                                    <div className="text-center absolute top-0 bottom-0 z-51 bg-white rounded-lg  bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                        <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                                <div className="p-4 pb-1 text-xs capitalize">
                                    {this.state.chart_metric} per channel/day
                                </div>
                                <div className="w-full h-64 p-4">
                                    {
                                        !this.state.disable_chart &&
                                        <Line
                                            options={{
                                                tooltips: {
                                                    mode: 'index'
                                                },
                                                maintainAspectRatio: false,
                                                legend: {
                                                    display: false
                                                },
                                                layout: {
                                                    padding: {
                                                        left: 0,
                                                        right: 0,
                                                        top: 0,
                                                        bottom: 0
                                                    }
                                                },
                                                scales: {
                                                    yAxes: [{
                                                        ticks: {
                                                            fontSize: 8,
                                                            beginAtZero: true,
                                                            precision: 0,
                                                        }
                                                    }],
                                                    xAxes: [{
                                                        ticks: {
                                                            fontSize: 8
                                                        }
                                                    }]
                                                }
                                            }}
                                            data={this.state.chart[this.state.chart_metric.toLowerCase()] ? this.state.chart[this.state.chart_metric.toLowerCase()] : {}}
                                        />
                                    }
                                </div>
                            </div>
                        }

                        <div className="mb-4 shadow bg-white rounded-lg w-full flex flex-col overflow-hidden">
                            <div className="">
                                {/*TABLE*/}
                                {
                                    this.state.clients.length > 0 &&
                                    <div className="relative overflow-scroll pb-32">

                                        {/*LOADER PARTIAL*/}
                                        {
                                            this.state.loading_partial &&
                                            <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }

                                        <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                            <thead className="bg-white">
                                                <tr>
                                                    {
                                                        this.state.columns.filter((item) => {
                                                            if (this.state.channel.value === "all") {
                                                                return true;
                                                            } else if (this.state.channel.value === "total") {
                                                                return item.value.indexOf("total_") !== -1 || item.value.indexOf("name") !== -1 || item.value.indexOf("synced") !== -1
                                                            } else if (this.state.channel.value === "facebook") {
                                                                return item.value.indexOf("facebook_") !== -1 || item.value.indexOf("name") !== -1 || item.value.indexOf("facebook_synced") !== -1
                                                            } else if (this.state.channel.value === "google") {
                                                                return item.value.indexOf("google_") !== -1 || item.value.indexOf("name") !== -1 || item.value.indexOf("google_synced") !== -1
                                                            } else {
                                                                return true;
                                                            }
                                                        }).map((item, index) => {
                                                            return (
                                                                <th
                                                                    onClick={async () => {
                                                                        if (!item.noSort) {
                                                                            await this.promisedSetState({
                                                                                page: 1,
                                                                                sort: item.value,
                                                                                direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                            });
                                                                            this.functions.getExternalClientPerformance(false, true);
                                                                        }
                                                                    }}
                                                                    style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                    scope="col"
                                                                    className={(index === 0 ? "sm:sticky sm:z-50 sm:left-0 bg-white " : "") + (item.border ? "border-r " : "") + (item.value == this.state.sort ? " bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? " hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                                >
                                                                    <div className="flex flex-row items-center">
                                                                        {
                                                                            item.value.indexOf("facebook") !== -1 &&
                                                                            <div style={{ padding: "0.28rem" }}
                                                                                className="w-6 h-6 bg-facebook-500 rounded-full border-2 border-white mr-2">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            item.value.indexOf("google") !== -1 &&
                                                                            <div style={{ padding: "0.3rem" }}
                                                                                className={'w-6 h-6 bg-google-500 rounded-full p-1 border-2 border-white mr-2'}>
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        <div className="mr-4">{item.name}</div>
                                                                        {
                                                                            !item.noSort &&
                                                                            <div className="flex flex-col">
                                                                                <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                                <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </th>
                                                            )
                                                        })}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-gray-300">
                                                {
                                                    this.state.clients.map((item, index) => {
                                                        return (
                                                            <Fragment>
                                                                <tr className="border-b" key={item.id}>
                                                                    <td onClick={() => {
                                                                        //this.props.history.push('/v2/clients/' + item.id);
                                                                    }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " border-r sm:sticky sm:z-50 sm:left-0 bg-white px-6 py-4 font-medium cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                        <div className="flex flex-row items-center">
                                                                            {
                                                                                this.state.view.value === "Clients" &&
                                                                                <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                                                                    <img src={item.logo} className="bg-cover" />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.view.value === "Accounts" &&
                                                                                <div className="pl-2">
                                                                                    {
                                                                                        (item.channel === "facebook") &&
                                                                                        < div
                                                                                            className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.channel === "linkedin") &&
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.channel === "google") &&
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.channel === "tiktok") &&
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.channel === "twitter") &&
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div className="ml-4">
                                                                                {item.name ? item.name : "-"}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        (this.state.channel.value === "total" || this.state.channel.value === "all") &&
                                                                        <td className={(this.state.sort === 'spend' ? "bg-gray-50" : "") + " border-r px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                            {item.spend ? this.renders.convertToDecimal(item.spend) : "-"}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        (this.state.channel.value === "total" || this.state.channel.value === "all") &&
                                                                        <td className={(this.state.sort === 'impressions' ? "bg-gray-50" : "") + " border-r px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                            {item.impressions ? item.impressions : "-"}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        (this.state.channel.value === "total" || this.state.channel.value === "all") &&
                                                                        <td className={(this.state.sort === 'clicks' ? "bg-gray-50" : "") + " border-r px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                            {item.clicks ? item.clicks : "-"}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        (this.state.channel.value === "total" || this.state.channel.value === "all") &&
                                                                        <td className={(this.state.sort === 'ctr' ? "bg-gray-50" : "") + " border-r px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                            {item.ctr ? this.renders.convertToDecimal(item.ctr) : "-"}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        (this.state.channel.value === "total" || this.state.channel.value === "all") &&
                                                                        <td className={(this.state.sort === 'cpc' ? "bg-gray-50" : "") + " border-r px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                            {item.cpc ? this.renders.convertToDecimal(item.cpc) : "-"}
                                                                        </td>
                                                                    }
                                                                    {
                                                                        (this.state.channel.value === "total" || this.state.channel.value === "all") &&
                                                                        <td className={(this.state.sort === 'cpm' ? "bg-gray-50" : "") + " border-r px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                            {item.cpm ? this.renders.convertToDecimal(item.cpm) : "-"}
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {/* PAGINATION */}
                                {
                                    this.state.clients.length > 0 &&
                                    <div className="flex flex-row p-4 items-center">
                                        <div className="flex justify-center items-center">
                                            <button
                                                type="button"
                                                onClick={async () => {
                                                    if (this.state.page !== 1) {
                                                        await this.promisedSetState({
                                                            page: this.state.page - 1
                                                        });
                                                        this.functions.getExternalClientPerformance(false, true);
                                                    }
                                                }}
                                                className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                                Previous
                                            </button>
                                        </div>
                                        <div className="flex flex-1 sm:hidden"></div>
                                        <div className="hidden sm:flex flex-1 items-center justify-center">
                                            {
                                                (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                                <p className="text-sm text-gray-700">
                                                    Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                                    <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                                </p>
                                            }
                                        </div>
                                        <div className="mr-2 hidden sm:flex">
                                            <Menu as="div" className="relative inline-block text-left mr-2">
                                                <div>
                                                    <Menu.Button className={"cursor-pointer inline-flex relative justify-center items-center w-full h-10 rounded-md  border-1.5 px-4 bg-white text-sm font-medium text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                        Page limit: {this.state.limit}
                                                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="border absolute z-50 mt-2 w-56 max-h-40 overflow-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="py-1">
                                                            {
                                                                [5, 10, 20, 30, 50, 100, 500].map((item) => {
                                                                    return (
                                                                        <Menu.Item>
                                                                            {({ active }) => (
                                                                                <div
                                                                                    onClick={async () => {
                                                                                        await this.promisedSetState({
                                                                                            page: 1,
                                                                                            limit: item
                                                                                        });
                                                                                        this.functions.getExternalClientPerformance(false, true);
                                                                                    }}
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                        'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                        this.state.limit == item ? "text-purple-500" : ""
                                                                                    )}
                                                                                >
                                                                                    {item}
                                                                                </div>
                                                                            )}
                                                                        </Menu.Item>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <div className="">
                                                <button
                                                    onClick={async () => {
                                                        if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                            await this.promisedSetState({
                                                                page: this.state.page + 1
                                                            });
                                                            this.functions.getExternalClientPerformance(false, true);
                                                        }
                                                    }}
                                                    className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                >
                                                    <span>Next</span>
                                                    <ArrowRightIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.clients.length > 0 &&
                                    <div className="flex sm:hidden mb-4 flex-1 items-center justify-center">
                                        {
                                            (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                            <p className="text-sm text-gray-700">
                                                Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                                <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                            </p>
                                        }
                                    </div>
                                }
                            </div>

                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default ExternalClientPerformanceList;