import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import AdvertisingLocation from "../../../components/advertising-location";
import AdvertisingBudget from "../../../components/advertising-budget";
import AdvertisingCampaignDetails from "../../../components/advertising-campaigndetails";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {calls} from "./calls";
import Loader from "../../../components/loader";
import AdvertisingPreview from "../../../components/advertising-preview";
import {eventRegister} from "../../../services/eventRegister";
import {campaignRegister} from "../../../services/campaignRegister";
import CampaignErrors from "../../../modules/campaignerrors";
import AdvertisingSocial from "../../../components/advertising-social";
import AdvertisingSearch from "../../../components/advertising-search";
import AdvertisingCreativeOverview from "../../../components/advertising-creative-overview";
import {userRegister} from "../../../services/userRegister";
import Logo from "../../../modules/logo";
import cx from "classnames";
import AdvertisingChannelBudgetNew from "../../../components/advertising-channel-budget-new";
import AdvertisingShopping from "../../../components/advertising-shopping";

var moment = require('moment');

class UserAdvertising extends Component {

    constructor(props) {
        super(props);
        this.state = {
            extra: {},
            errors: [],
            details: {},
            client: {},
            site: {},
            settings: {},
            channels: {},
            custom: {},
            conversion: {},
            creatives: {},
            keywords: {},
            locations: {},
            languages: {},
            targeting: {},
            advanced: {},
            search: {},
            shopping: {},
            social: {},
            preview: {},
            overview: {},
            budget: {},
            step_id: 'campaign_details',
            steps: []
        };
        this.campaign_details_ref = React.createRef();
        this.channels_ref = React.createRef();
        this.locations_ref = React.createRef();
        this.creative_overview_ref = React.createRef();
        this.social_ref = React.createRef();
        this.search_ref = React.createRef();
        this.shopping_ref = React.createRef();
    };

    componentWillMount() {
        eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        this.init.first();
        this.init.data();
    }

    init = {
        first: () => {
            let information = campaignRegister.get();

            try{
                information.locations.value = information.locations.value.map((location) => {
                    if(location.custom && !location.channels){
                        location.channels = {
                            facebook: {},
                            google: {},
                            google_shopping: {}
                        };
                    }
                    return location;
                })
            }catch (e) {}

            this.setState({
                details: information ? (information.details ? information.details : {}) : {},
                channels: information ? (information.channels ? information.channels : {}) : {},
                errors: information ? (information.errors ? information.errors : []) : [],
            }, () => {
                try{
                    if (window.location.search.indexOf('step=') >= 0) {
                        let step = this.functions.getQuery('step');
                        this.state.step_id = step;
                    }
                }catch (e) {}
                this.setState({
                    step_id: this.state.step_id,
                    details: this.state.details
                }, () => {
                    this.functions.setSteps();
                    this.functions.save('details');
                })
            })
        },
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                extra: information ? (information.extra ? information.extra : {}) : {},
                errors: information ? (information.errors ? information.errors : []) : [],
                details: information ? (information.details ? information.details : {}) : {},
                locations: information ? (information.locations ? information.locations : {}) : {},
                languages: information ? (information.languages ? information.languages : {}) : {},
                targeting: information ? (information.targeting ? information.targeting : {}) : {},
                advanced: information ? (information.advanced ? information.advanced : {}) : {},
                preview: information ? (information.preview ? information.preview : {}) : {},
                budget: information ? (information.budget ? information.budget : {}) : {},
                channels: information ? (information.channels ? information.channels : {}) : {},
                custom: information ? (information.custom ? information.custom : {}) : {},
                conversion: information ? (information.conversion ? information.conversion : {}) : {},
                site: information ? (information.site ? information.site : {}) : {},
                overview: information ? (information.overview ? information.overview : {}) : {},
                creatives: information ? (information.creatives ? information.creatives : {}) : {},
                social: information ? (information.social ? information.social : {}) : {},
                search: information ? (information.search ? information.search : {}) : {},
                shopping: information ? (information.shopping ? information.shopping : {}) : {},
                keywords: information ? (information.keywords ? information.keywords : {}) : {},
                client: information ? (information.client ? information.client : {}) : {},
            })
        }
    };

    functions = {
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            information[key] = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        },
        getQuery: (q) => {
            return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [, null])[1];
        },
        reset: () => {
            let information = campaignRegister.get();
            delete information.budget;
            delete information.extra;
            delete information.site;
            delete information.client;
            delete information.errors;
            delete information.details;
            delete information.locations;
            delete information.languages;
            delete information.keywords;
            delete information.advanced;
            delete information.targeting;
            delete information.channels;
            delete information.custom;
            delete information.creatives;
            delete information.conversion;
            delete information.search;
            delete information.shopping;
            delete information.social;
            delete information.overview;
            delete information.preview;
            information.details = {open: true, value: {}};
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        },
        cancel: () => {
            this.props.history.push('/user/dashboard');
        },
        draft: () => {
            if (!this.state.loading) {

                let data = campaignRegister.get();

                //REMOVE LOCATION COORDINATES
                try {
                    delete data.creatives.value.facebook_pages;
                    delete data.creatives.value.instagram_page_accounts;
                    delete data.creatives.value.instagram_business_accounts;
                } catch (e) {
                    //console.log(e);
                }

                //REMOVE KEYWORDS IDEAS
                try{
                    data.search.value.campaigns.map((item) => {
                        delete item.ideas;
                    })
                }catch (e) {
                    //console.log(e);
                }

                //REMOVE ERROR EXTRA
                try {
                    delete data.extra;
                } catch (e) {}

                let campaign = {
                    version: '2',
                    campaign_name: this.state.details.value.name,
                    onboarding: data,
                    client: this.state.client.value.id
                };
                this.setState({
                    loading: true
                }, () => {
                    calls.createDraft(campaign).then((response) => {
                        this.setState({
                            loading: false
                        }, () => {
                            campaignRegister.remove();
                            this.props.history.push('/user/campaigns/drafted');
                        })
                    }, (error) => {
                        this.setState({
                            error_message: error.body.message,
                            error_modal: true,
                            loading: false
                        })
                    });
                })
            }
        },
        setSteps: () => {
            let steps = [];
            steps.push({step: 1, step_id: 'campaign_details', title: 'Campaign details', checked: this.renders.checked('campaign_details'), next: false});
            steps.push({step: 2, step_id: 'channels', title: 'Channels', checked: this.renders.checked('channels'), next: false});
            steps.push({step: 3, step_id: 'locations', title: 'Locations', checked: this.renders.checked('locations'), next: false});
            //steps.push({step: 4, step_id: 'creative_overview', title: 'Creative overview', checked: this.renders.checked('creative_overview'), next: false});
            if(this.state.channels.value && (this.state.channels.value.facebook || this.state.channels.value.instagram || this.state.channels.value.linkedin || this.state.channels.value.twitter)){
                steps.push({step: 4, step_id: 'creative_overview', title: 'Creative overview', checked: this.renders.checked('creative_overview'), next: false});
                steps.push({step: steps.length + 1, step_id: 'social', title: 'Social', checked: this.renders.checked('social'), next: false});
            }
            if(this.state.channels.value && this.state.channels.value.google){
                steps.push({step: steps.length + 1, step_id: 'search', title: 'Search', checked: this.renders.checked('search'), next: false});
            }
            if(this.state.channels.value && this.state.channels.value.google_shopping){
                steps.push({step: steps.length + 1, step_id: 'shopping', title: 'Shopping', checked: this.renders.checked('shopping'), next: false});
            }
            steps.push({step: steps.length + 1, step_id: 'preview', title: 'Preview', checked: this.renders.checked('preview'), next: false});
            this.setState({
                steps: steps
            });
        }
    };

    validators = {};

    renders = {
        steps: () => {
            let step = "";
            return step;
        },
        errors: () => {
            try {
                let error = {errorsExist: false, errors: []};
                let items = {};
                for (let i = 0; i < this.state.errors.length; i++) {
                    if (!items[this.state.errors[i].id]) {
                        items[this.state.errors[i].id] = {channel: this.state.errors[i].channel, errors: []};
                        items[this.state.errors[i].id].errors.push(this.state.errors[i]);
                    } else {
                        items[this.state.errors[i].id].errors.push(this.state.errors[i]);
                    }
                }
                for (let key in items) {
                    error.errors.push(items[key]);
                }
                error.errorsExist = error.errors.length > 0;
                return error;
            } catch (e) {
                return {errorsExist: false, errors: []}
            }
        },
        checked: (step) => {
            if(step === 'campaign_details'){
                try{
                    return this.campaign_details_ref.current.renders.errors().length < 1
                }catch (e) {
                    return false
                }
            }
            if(step === 'channels'){
                try{
                    //console.log(this.channels_ref.current.renders.errors());
                    return this.channels_ref.current.renders.errors().length < 1
                }catch (e) {
                    //console.log(e);
                    return false
                }
            }
            if(step === 'locations'){
                try{
                    return this.locations_ref.current.renders.errors().length < 1
                }catch (e) {
                    //console.log(e);
                    return false
                }
            }
            if(step === 'creative_overview'){
                try{
                    return this.creative_overview_ref.current.renders.errors().length < 1
                }catch (e) {
                    //console.log(e);
                    return false
                }
            }
            if(step === 'social'){
                try{
                    return this.social_ref.current.renders.errors().length < 1
                }catch (e) {
                    //console.log(e);
                    return false
                }
            }
            if(step === 'search'){
                try{
                    return this.search_ref.current.renders.errors().length < 1
                }catch (e) {
                    //console.log(e);
                    return false
                }
            }
            if(step === 'shopping'){
                try{
                    return this.search_ref.current.renders.errors().length < 1
                }catch (e) {
                    //console.log(e);
                    return false
                }
            }
        },
        campaigns: () => {
            let information = campaignRegister.get();
            let budgets = [];
            try{
                if(Array.isArray(information.search.value.campaigns)){
                    information.search.value.campaigns.map((item) => {
                        budgets.push({name: item.name, channel: "google", total_budget: item.budget.google.total_budget});
                    })
                }
            }catch (e) {}
            try{
                if(Array.isArray(information.shopping.value.campaigns)){
                    information.shopping.value.campaigns.map((item) => {
                        budgets.push({name: item.name, channel: "google_shopping", total_budget: item.budget.google_shopping.total_budget});
                    })
                }
            }catch (e) {}
            try{
                if(Array.isArray(information.creatives.value.campaigns)){
                    information.creatives.value.campaigns.map((item) => {
                        for(let channel in item.budget){
                            budgets.push({name: item.name, channel: channel, total_budget: item.budget[channel].total_budget});
                        }
                    })
                }
            }catch (e) {}
            return budgets;
        },
        budget: () => {
            let information = campaignRegister.get();
            try{
                return information.budget.value.total;
            }catch (e) {
                return 0;
            }
        },
        noEndDate: () => {
            try{
                return this.state.details.value.noEndDate;
            }catch (e) {
                return false
            }
        },
        totalBudget:() => {
            let information = campaignRegister.get();
            let budget = 0;
            try{
                if(Array.isArray(information.search.value.campaigns)){
                    information.search.value.campaigns.map((item) => {
                        budget = budget + +item.budget.google.total_budget;
                    })
                }
            }catch (e) {}
            try{
                if(Array.isArray(information.shopping.value.campaigns)){
                    information.shopping.value.campaigns.map((item) => {
                        budget = budget + +item.budget.google_shopping.total_budget;
                    })
                }
            }catch (e) {}
            try{
                if(Array.isArray(information.creatives.value.campaigns)){
                    information.creatives.value.campaigns.map((item) => {
                        for(let channel in item.budget){
                            budget = budget + +item.budget[channel].total_budget;
                        }
                    })
                }
            }catch (e) {}
            return budget;
        }
    };

    render() {
        return (
            <div className="dashboard--wrapper w-full pt-12 h-full">
                {
                    this.state.loading &&
                    <Loader
                        title={'Saving draft ...'}
                        subtitle={'Saving draft ...'}
                    />
                }
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />
                <SweetAlert
                    show={this.state.date_modal}
                    title="Start date is not valid"
                    type="warning"
                    text={"Edit start and end date"}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            date_modal: false
                        })
                    }}
                />
                <SweetAlert
                    show={this.state.draft_modal}
                    title="Save draft"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            draft_modal: false
                        }, () => {
                            this.functions.draft();
                        })
                    }}
                    onCancel={() => {
                        this.setState({draft_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Reset"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            warning_modal: false
                        }, () => {
                            this.functions.reset();
                        })
                    }}
                    onCancel={() => {
                        this.setState({warning_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.cancel_modal}
                    title="Cancel"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            cancel_modal: false
                        }, () => {
                            this.functions.cancel();
                        })
                    }}
                    onCancel={() => {
                        this.setState({cancel_modal: false});
                    }}
                />
                <div className="campaigns-body h-full">
                    {
                        this.renders.errors().errorsExist &&
                        <CampaignErrors
                            campaignDataErrors={this.renders.errors()}
                            toggle={true}
                        />
                    }
                    <div style={this.state.step_id === 'campaign_details'?{}:{maxHeight: "0px", height: "0px", opacity: "0", overflow: "hidden"}} className="flex flex-1">
                        <AdvertisingCampaignDetails
                            ref={this.campaign_details_ref}
                            step={this.state.step_id}
                            onSteps={() => {
                                this.functions.setSteps();
                            }}
                            onNext={() => {
                                this.state.details.edited = true;
                                this.setState({
                                    details: this.state.details,
                                    step_id: 'channels'
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                    this.functions.save('details');
                                })
                            }}
                        />
                    </div>
                    <div style={this.state.step_id === 'channels'?{}:{maxHeight: "0px", height: "0px", opacity: "0", overflow: "hidden"}} className="flex flex-1">
                        <AdvertisingChannelBudgetNew
                            ref={this.channels_ref}
                            step={this.state.step_id}
                            onChange={() => {
                                this.functions.setSteps();
                            }}
                            onBack={() => {
                                this.setState({
                                    step_id: 'campaign_details'
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                })
                            }}
                            onNext={() => {
                                this.state.channels.edited = true;
                                this.setState({
                                    channels: this.state.channels,
                                    step_id: 'locations'
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                    this.functions.save('channels');
                                })
                            }}
                        />
                    </div>
                    <div style={this.state.step_id === 'locations'?{}:{maxHeight: "0px", height: "0px", opacity: "0", overflow: "hidden"}} className="flex flex-1">
                        <AdvertisingLocation
                            ref={this.locations_ref}
                            step={this.state.step_id}
                            onChange={() => {
                                this.functions.setSteps();
                            }}
                            onBack={() => {
                                this.setState({
                                    step_id: 'channels'
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                })
                            }}
                            onNext={() => {
                                this.state.locations.edited = true;

                                /*
                                let next_step = 'creative_overview';
                                if (this.state.channels.value.google || this.state.channels.value.facebook || this.state.channels.value.instagram || this.state.channels.value.linkedin || this.state.channels.value.twitter) {
                                    next_step = 'creative_overview';
                                }else if(this.state.channels.value.google_shopping){
                                    next_step = 'shopping';
                                }
                                */

                                let next_step = 'creative_overview';
                                if (this.state.channels.value.facebook || this.state.channels.value.instagram || this.state.channels.value.linkedin || this.state.channels.value.twitter) {
                                    next_step = 'creative_overview';
                                }else if(this.state.channels.value.google){
                                    next_step = 'search';
                                }else if(this.state.channels.value.google_shopping){
                                    next_step = 'shopping';
                                }

                                this.setState({
                                    locations: this.state.locations,
                                    step_id: next_step
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                    this.functions.save('locations');
                                })
                            }}
                        />
                    </div>
                    <div style={this.state.step_id === 'creative_overview'?{}:{maxHeight: "0px", height: "0px", opacity: "0", overflow: "hidden"}} className="flex flex-1">
                        <AdvertisingCreativeOverview
                            ref={this.creative_overview_ref}
                            step={this.state.step_id}
                            onChange={() => {
                                this.functions.setSteps();
                            }}
                            onBack={() => {
                                this.setState({
                                    step_id: 'locations'
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                })
                            }}
                            onNext={() => {
                                this.state.overview.edited = true;
                                if (this.state.channels.value.facebook || this.state.channels.value.instagram || this.state.channels.value.linkedin || this.state.channels.value.twitter) {
                                    this.state.step_id = 'social';
                                }else{
                                    this.state.step_id = 'search';
                                }
                                this.setState({
                                    overview: this.state.overview,
                                    step_id: this.state.step_id
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                    this.functions.save('overview');
                                })
                            }}
                        />
                    </div>
                    <div style={this.state.step_id === 'social'?{}:{maxHeight: "0px", height: "0px", opacity: "0", overflow: "hidden"}} className="flex flex-1">
                        <AdvertisingSocial
                            ref={this.social_ref}
                            step={this.state.step_id}
                            onChange={() => {
                                this.functions.setSteps();
                            }}
                            onBack={() => {
                                this.setState({
                                    step_id: 'creative_overview'
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                })
                            }}
                            onNext={() => {
                                this.state.social.edited = true;
                                if (this.state.channels.value.google) {
                                    this.state.step_id = 'search';
                                }else{
                                    this.state.step_id = 'preview';
                                }
                                this.setState({
                                    social: this.state.social,
                                    step_id: this.state.step_id
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step);
                                    this.functions.save('social');
                                })
                            }}
                        />
                    </div>
                    <div style={this.state.step_id === 'search'?{}:{maxHeight: "0px", height: "0px", opacity: "0", overflow: "hidden"}} className="flex flex-1">
                        <AdvertisingSearch
                            ref={this.search_ref}
                            step={this.state.step_id}
                            onChange={() => {
                                this.functions.setSteps();
                            }}
                            onBack={() => {
                                if (this.state.channels.value.facebook || this.state.channels.value.instagram || this.state.channels.value.linkedin || this.state.channels.value.twitter) {
                                    this.state.step_id = 'social';
                                }else{
                                    this.state.step_id = 'locations';
                                }
                                this.setState({
                                    step_id: this.state.step_id
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                })
                            }}
                            onNext={() => {
                                this.state.search.edited = true;
                                this.setState({
                                    search: this.state.search,
                                    step_id: this.state.channels.value.google_shopping?"shopping":'preview'
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                })
                            }}
                        />
                    </div>
                    <div style={this.state.step_id === 'shopping'?{}:{maxHeight: "0px", height: "0px", opacity: "0", overflow: "hidden"}} className="flex flex-1">
                        <AdvertisingShopping
                            ref={this.shopping_ref}
                            step={this.state.step_id}
                            onChange={() => {
                                this.functions.setSteps();
                            }}
                            onBack={() => {
                                if(this.state.channels.value.google ){
                                    this.state.step_id = 'search';
                                } else if (this.state.channels.value.facebook || this.state.channels.value.instagram || this.state.channels.value.linkedin || this.state.channels.value.twitter) {
                                    this.state.step_id = 'social';
                                }else{
                                    this.state.step_id = 'creative_overview';
                                }
                                this.setState({
                                    step_id: this.state.step_id
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                })
                            }}
                            onNext={() => {
                                this.state.shopping.edited = true;
                                this.setState({
                                    shopping: this.state.shopping,
                                    step_id: 'preview'
                                }, () => {
                                    this.props.history.push('?step=' + this.state.step_id);
                                })
                            }}
                        />
                    </div>
                    <div style={this.state.step_id === 'preview'?{}:{maxHeight: "0px", height: "0px", opacity: "0", overflow: "hidden"}} className="flex flex-1">
                        <AdvertisingPreview
                            onBack={() => {

                            }}
                            onNext={() => {

                            }}
                        />
                    </div>
                    <div style={this.state.step_id === 'preview'?{}:{maxHeight: "0px", height: "0px", opacity: "0", overflow: "hidden"}} className="flex flex-1">
                        <AdvertisingBudget
                            history={this.props.history}
                            open={this.state.budget.open}
                        />
                    </div>

                    {/*SIDE MENU*/}
                    <aside style={{zIndex: 10}} className="py-2 bg-white top-0 fixed flex flex-col md:w-2/12 lg:w-2/12 left-0 h-full">
                        <div className="md:px-4 px-4">
                            <div style={{height: '56px'}} className="logo w-40 md:pl-5">
                                {
                                    !userRegister.get().logo &&
                                    <div className="logo--adcredo h-8 md:pt-2">
                                        <Logo/>
                                    </div>
                                }
                                {
                                    userRegister.get().logo &&
                                    <img src={userRegister.get().logo}
                                         className="w-auto h-auto inline-block md:max-h-8 max-w-full"/>
                                }
                            </div>
                        </div>
                        <div className="bg-gray-100 flex flex-1 pl-3 pr-2 flex-col">
                            <div className={(!this.renders.noEndDate()?"rounded-t-md":"rounded-md")+" bg-purple-900 pt-5 w-full pl-4 pr-4 mt-12 pb-2"}>
                                <div className="font-bold mb-4 text-white">
                                    Set up campaign
                                </div>
                                {
                                    this.state.steps.map((item, index) => {
                                        return (
                                            <div key={index} onClick={() => {
                                                this.setState({
                                                    step_id: item.step_id
                                                }, () => {
                                                    this.props.history.push('?step=' + this.state.step_id);
                                                })
                                            }} className={cx("flex flex-row items-center mb-3 cursor-pointer", {
                                                ["opacity-50"]: this.state.step_id !== item.step_id
                                            })}>
                                                {
                                                    !item.checked &&
                                                    <div
                                                        className={cx("flex rounded-full w-5 h-5 justify-center items-center text-xs font-bold", {
                                                            ["text-white bg-gray-500"]: this.state.step_id !== item.step_id,
                                                            ["text-black bg-white"]: this.state.step_id === item.step_id
                                                        })}>
                                                        {item.step}
                                                    </div>
                                                }
                                                {
                                                    item.checked &&
                                                    <div className={cx("flex bg-green-500 rounded-full w-5 h-5 justify-center items-center text-xs font-bold")}>
                                                        <FeatherIcon color={"#ffffff"} size={13} icon={'check'}/>
                                                    </div>
                                                }
                                                <div className="ml-2 text-xs font-bold text-white flex flex-1">
                                                    {item.title}
                                                </div>
                                                {
                                                    this.state.step_id !== item.step_id &&
                                                    <FeatherIcon color={"#000000"} size={13} icon="chevron-right"/>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                this.renders.campaigns().length > 0 && !this.renders.noEndDate() &&
                                <div className="rounded-b-md bg-white pt-5 w-full">
                                    <div className="pl-4 pr-4 pb-2">
                                        <div className="font-bold mb-4">
                                            Budgets
                                        </div>
                                        {
                                            this.renders.campaigns().map((item) => {
                                                return(
                                                    <div className="flex flex-row items-center mb-3 cursor-pointer">
                                                        <div className="truncate">
                                                            <div className="text-xs font-bold capitalize pr-2 box-border truncate">
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                        <div className="ml-2 text-xs font-bold flex flex-1 justify-end">
                                                            {item.total_budget>0?item.total_budget:0}Kr
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="w-full border-t pt-2 pb-2"></div>
                                    <div className="flex flex-1 pl-4 pr-4 pb-4 flex-col">
                                        <div className="flex flex-row">
                                            <div className="text-xs font-bold">Sum</div>
                                            <div className="flex flex-1"></div>
                                            <div className="text-xs font-bold">{this.renders.totalBudget()}Kr</div>
                                        </div>
                                    </div>
                                    <div className="w-full border-t pt-2 pb-2"></div>
                                    <div className="flex flex-1 pl-4 pr-4 pb-4">
                                        <div className="text-xs font-bold">Total budget</div>
                                        <div className="flex flex-1"></div>
                                        <div className="text-xs font-bold">{this.renders.budget()}Kr</div>
                                    </div>
                                    {
                                        this.renders.totalBudget() !== this.renders.budget() &&
                                        <div className="flex flex-1 pl-4 pr-4 pb-4 flex-col">
                                            {
                                                this.renders.totalBudget() > this.renders.budget() &&
                                                <div className="text-xs font-bold mt-2 text-red-500">
                                                    Sum of campaigns is {this.renders.totalBudget() - this.renders.budget()}Kr higher than total budget
                                                </div>
                                            }
                                            {
                                                this.renders.totalBudget() < this.renders.budget() &&
                                                <div className="text-xs font-bold mt-2 text-red-500">
                                                    Sum of campaigns are lower than total budget
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            <div className="flex flex-1"></div>
                        </div>
                    </aside>

                    {/*TOPNAV*/}
                    <aside style={{zIndex: 52}}
                           className="bg-white top-0 fixed flex flex-row items-center justify-end lg:w-6/12 md:w-6/12 px-6 py-2 md:py-3 right-0 w-full">
                        <button onClick={() => this.setState({
                            cancel_modal: true
                        })} className="bg-transparent text-red-500 font-bold text-sm py-2 px-4 rounded-full mr-4 hover:underline inline-flex justify-center items-center">
                            <FeatherIcon className="stroke-current mr-1" size={13} icon="x-circle"/>
                            Cancel
                        </button>
                        <button onClick={() => this.setState({
                            warning_modal: true
                        })} className="bg-yellow-100 hover:bg-yellow-500 hover:text-white text-yellow-600 font-bold text-sm py-2 px-4 rounded-full mr-4 inline-flex justify-center items-center">
                            <FeatherIcon className="stroke-current mr-1" size={13} icon="rotate-ccw"/>
                            Reset
                        </button>
                        <button onClick={() => this.setState({
                            draft_modal: true
                        })} className="bg-green-100 text-green-500 hover:bg-green-500 hover:text-white font-bold text-sm py-2 px-4 rounded-full">
                            Save as draft
                        </button>
                    </aside>
                </div>
            </div>
        )
    }
}

export default UserAdvertising;
