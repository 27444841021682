import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import Dropdown from "../../dropdown";
import ErrorAlert from "../../../modules/error-alert";
import { calls } from "./calls";
import DropdownMultiSelect from "../../dropdown-multiselect";
import Input from '../../input';

class TargetingLinkedin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            targeting: {},
            client: {},
            channels: {},
            errors: [],
            targeting_audience: [
                {
                    category: "Custom audience",
                    disabled: false,
                    items: [],
                    name: "audienceMatchingSegments",
                    URN: "urn:li:adTargetingFacet:audienceMatchingSegments",
                    TYPEAHEAD: false
                },
                {
                    category: "Custom audience",
                    disabled: false,
                    items: [],
                    name: "dynamicSegments",
                    URN: "urn:li:adTargetingFacet:dynamicSegments",
                    TYPEAHEAD: false
                },
            ],
            targeting_types: [
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company follower of",
                    value: "followedCompanies",
                    URN: "urn:li:adTargetingFacet:followedCompanies",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company Connections",
                    value: "firstDegreeConnectionCompanies",
                    URN: "urn:li:adTargetingFacet:firstDegreeConnectionCompanies",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company industries",
                    value: "industries",
                    URN: "urn:li:adTargetingFacet:industries",
                    TYPEAHEAD: true
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company growth rate",
                    value: "growthRate",
                    URN: "urn:li:adTargetingFacet:growthRate",
                    TYPEAHEAD: false
                },
                {
                    category: "Company",
                    disabled: false,
                    items: [],
                    name: "Company categories",
                    value: "companyCategory",
                    URN: "urn:li:adTargetingFacet:companyCategory",
                    TYPEAHEAD: true
                },
                {
                    category: "Education",
                    disabled: false,
                    items: [],
                    name: "Education Degrees",
                    value: "degrees",
                    URN: "urn:li:adTargetingFacet:degrees",
                    TYPEAHEAD: true
                },
                {
                    category: "Education",
                    disabled: false,
                    items: [],
                    name: "Education fields of study",
                    value: "fieldsOfStudy",
                    URN: "urn:li:adTargetingFacet:fieldsOfStudy",
                    TYPEAHEAD: true
                },
                {
                    category: "Education",
                    disabled: false,
                    items: [],
                    name: "Education member schools",
                    value: "schools",
                    URN: "urn:li:adTargetingFacet:schools",
                    TYPEAHEAD: true
                },

                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Job experience functions",
                    value: "jobFunctions",
                    URN: "urn:li:adTargetingFacet:jobFunctions",
                    TYPEAHEAD: false
                },
                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Job titles",
                    value: "titlesPast",
                    URN: "urn:li:adTargetingFacet:titlesPast",
                    TYPEAHEAD: true
                },
                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Job seniorities",
                    value: "seniorities",
                    URN: "urn:li:adTargetingFacet:seniorities",
                    TYPEAHEAD: false
                },
                {
                    category: "Job experience",
                    disabled: false,
                    items: [],
                    name: "Member skills",
                    value: "skills",
                    URN: "urn:li:adTargetingFacet:skills",
                    TYPEAHEAD: true
                },


                {
                    disabled: true,
                    name: "staffCountRanges",
                    URN: "urn:li:adTargetingFacet:staffCountRanges",
                    TYPEAHEAD: false
                },
                { disabled: true, name: "titles", URN: "urn:li:adTargetingFacet:titles", TYPEAHEAD: true },
                { disabled: true, name: "groups", URN: "urn:li:adTargetingFacet:groups", TYPEAHEAD: true },
                { disabled: true, name: "employersPast", URN: "urn:li:adTargetingFacet:employersPast", TYPEAHEAD: true },
                {
                    disabled: true,
                    name: "yearsOfExperienceRanges",
                    URN: "urn:li:adTargetingFacet:yearsOfExperienceRanges",
                    TYPEAHEAD: false
                },

                { disabled: true, name: "titlesAll", URN: "urn:li:adTargetingFacet:titlesAll", TYPEAHEAD: true },
                { disabled: true, name: "employers", URN: "urn:li:adTargetingFacet:employers", TYPEAHEAD: true },
                { disabled: true, name: "employersAll", URN: "urn:li:adTargetingFacet:employersAll", TYPEAHEAD: true },

                {
                    disabled: true,
                    name: "interfaceLocales",
                    URN: "urn:li:adTargetingFacet:interfaceLocales",
                    TYPEAHEAD: false
                },
                { disabled: true, name: "interests", URN: "urn:li:adTargetingFacet:interests", TYPEAHEAD: true },
                {
                    disabled: true,
                    name: "memberBehaviors",
                    URN: "urn:li:adTargetingFacet:memberBehaviors",
                    TYPEAHEAD: true
                },
            ],
            targeting_type: {},
            selected_type: {},
            search: ""
        };
        this.multiSelect = React.createRef();
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            targeting: nextProps.targeting,
            client: nextProps.client,
            channels: nextProps.channels,
            errors: nextProps.errors
        });
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            targeting: this.props.targeting,
            client: this.props.client,
            channels: this.props.channels,
            errors: this.props.errors
        });
    }

    init = {};

    functions = {
        targeting: () => {
            if (!this.state.targeting_type.TYPEAHEAD || (this.state.targeting_type.TYPEAHEAD && this.state.search !== "")) {
                this.state.targeting_type.loading = true;
                this.setState({
                    targeting_types: this.state.targeting_types
                }, () => {
                    calls.getTargeting({
                        urn: this.state.targeting_type.URN,
                        client: this.state.client.id,
                        search: this.state.targeting_type.TYPEAHEAD ? this.state.search : ""
                    }).then((response) => {
                        this.state.targeting_type.loading = false;
                        this.state.targeting_type.items = response.data;
                        this.setState({
                            targeting_type: this.state.targeting_type
                        })
                    }, (error) => {
                        this.state.targeting_type.loading = false;
                        this.state.targeting_type.items = [];
                        this.setState({
                            targeting_type: this.state.targeting_type
                        })
                    });
                })
            }
        },
        remove: (item) => {
            try {
                this.state.targeting.linkedin.targeting_items = this.state.targeting.linkedin.targeting_items.filter((inner_item) => {
                    return item.urn !== inner_item.urn;
                });
                this.setState({
                    targeting: this.state.targeting
                }, () => {
                    this.props.onChange(this.state.targeting);
                });
            } catch (e) { }
        }
    };

    renders = {
        languages: () => {
            let languages = [
                { name: "English", value: "en_US" },
                { name: "Arabic", value: "ar_AE" },
                { name: "Chinese - Simplified", value: "zh_CN" },
                { name: "Chinese - Traditional", value: "zh_TW" },
                { name: "Czech", value: "cs_CZ" },
                { name: "Danish", value: "da_DK" },
                { name: "Indonesian", value: "in_ID" },
                { name: "Malaysian", value: "ms_MY" },
                { name: "Dutch", value: "nl_NL" },
                { name: "French", value: "fr_FR" },
                { name: "Finnish", value: "fi_FI" },
                { name: "German", value: "de_DE" },
                { name: "Italian", value: "it_IT" },
                { name: "Japanese", value: "ja_JP" },
                { name: "Korean", value: "ko_KR" },
                { name: "Norwegian", value: "no_NO" },
                { name: "Polish", value: "pl_PL" },
                { name: "Portuguese", value: "pt_BR" },
                { name: "Romanian", value: "ro_RO" },
                { name: "Russian", value: "ru_RU" },
                { name: "Spanish", value: "es_ES" },
                { name: "Swedish", value: "sv_SE" },
                { name: "Thai", value: "th_TH" },
                { name: "Filipino", value: "tl_PH" },
                { name: "Turkish", value: "tr_TR" }
            ];
            return languages;
        },
        countries: () => {
            let countries = [];
            return countries;
        },
        selectedTargeting: () => {
            try {
                let selected = [];
                if (this.state.targeting.linkedin && Array.isArray(this.state.targeting.linkedin.targeting_items)) {
                    selected = this.state.targeting.linkedin.targeting_items;
                }
                return selected;
            } catch (e) {
                return [];
            }
        }
    };

    render() {
        return (
            <div className="flex flex-1 flex-col">
                <div className="flex flex-row pb-3">
                    <Dropdown
                        title={"Budget planing"}
                        size={'full'}
                        search={false}
                        searchFilter={false}
                        showError={false}
                        filter={false}
                        placeholder={"Click here ..."}
                        options={[
                            { name: "Total budget", value: 'total_budget' },
                            { name: "Daily budget", value: 'daily_budget' }
                        ]}
                        value={(this.state.targeting.linkedin && this.state.targeting.linkedin.budget_type) ? this.state.targeting.linkedin.budget_type : { name: "Daily budget", value: 'daily_budget' }}
                        onChange={(value) => {
                            this.state.targeting.linkedin.budget_type = value;
                            this.setState({
                                targeting: this.state.targeting
                            }, () => {
                                this.props.onChange(this.state.targeting);
                            })
                        }}
                    />
                </div>
                {
                    this.state.targeting.linkedin && this.state.targeting.linkedin.budget_type && this.state.targeting.linkedin.budget_type.value === "total_budget" &&
                    <div className="flex flex-row pb-3">
                        <Input
                            noBorder={false}
                            noTitle={false}
                            title={"Total budget"}
                            type={'number'}
                            white={false}
                            placeholder={''}
                            disabled={false}
                            value={(this.state.targeting.linkedin && this.state.targeting.linkedin.total_budget) ? this.state.targeting.linkedin.total_budget : 0}
                            size={'full'}
                            onChange={(event) => {
                                this.state.targeting.linkedin.total_budget = +event.target.value;
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    this.props.onChange(this.state.targeting);
                                })
                            }}
                        />
                    </div>
                }
                {
                    this.state.targeting.linkedin &&
                    (!this.state.targeting.linkedin.budget_type || (this.state.targeting.linkedin.budget_type && this.state.targeting.linkedin.budget_type.value === "daily_budget")) &&
                    <div className="flex flex-row pb-3">
                        <Input
                            noBorder={false}
                            noTitle={false}
                            title={"Daily budget"}
                            type={'number'}
                            white={false}
                            placeholder={''}
                            disabled={false}
                            value={(this.state.targeting.linkedin && this.state.targeting.linkedin.daily_budget) ? this.state.targeting.linkedin.daily_budget : 0}
                            size={'full'}
                            onChange={(event) => {
                                this.state.targeting.linkedin.daily_budget = +event.target.value;
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    this.props.onChange(this.state.targeting);
                                })
                            }}
                        />
                    </div>
                }
                {
                    <div className="mb-4">
                        <DropdownMultiSelect
                            ref={this.multiSelect}
                            title={"Targeting"}
                            search={this.state.search}
                            showType={true}
                            showSearch={this.state.targeting_type.TYPEAHEAD}
                            placeholderType={"Select targeting type"}
                            placeholderSearch={"Search " + this.state.targeting_type.name}
                            type={this.state.targeting_type}
                            types={this.state.targeting_types.filter((item) => {
                                return !item.disabled
                            })}
                            loading={this.state.targeting_type.loading}
                            options={this.state.targeting_type.items ? this.state.targeting_type.items.map((item) => {
                                item.id = item.urn;
                                return item;
                            }) : []}
                            selected={this.renders.selectedTargeting()}
                            onSelect={(value) => {
                                try {
                                    value.parent = this.state.targeting_type.name;
                                    value.id = value.urn;
                                    if (this.state.targeting.linkedin && !Array.isArray(this.state.targeting.linkedin.targeting_items)) {
                                        this.state.targeting.linkedin.targeting_items = [];
                                    }
                                    this.state.targeting.linkedin.targeting_items.push(value);
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        this.props.onChange(this.state.targeting);
                                    })
                                } catch (e) { }
                            }}
                            onRemove={(value) => {
                                if (this.state.targeting.linkedin && Array.isArray(this.state.targeting.linkedin.targeting_items)) {
                                    this.state.targeting.linkedin.targeting_items = this.state.targeting.linkedin.targeting_items.filter((item) => {
                                        return item.urn !== value.urn;
                                    })
                                }
                                this.setState({
                                    targeting: this.state.targeting
                                }, () => {
                                    this.props.onChange(this.state.targeting);
                                })
                            }}
                            onType={(value) => {
                                value.items = [];
                                this.setState({
                                    search: "",
                                    targeting_type: value
                                }, () => {
                                    if (!this.state.targeting_type.TYPEAHEAD) {
                                        this.functions.targeting();
                                    } else {
                                        try {
                                            this.multiSelect.current.functions.focus();
                                        } catch (e) {
                                            console.log(e);
                                        }
                                    }
                                })
                            }}
                            onSearch={(value) => {
                                this.setState({
                                    search: value
                                }, () => {
                                    this.functions.targeting();
                                })
                            }}
                        />
                    </div>
                }
                {
                    this.state.targeting && this.state.targeting.linkedin &&
                    <div className="">
                        <div className="Section-Inner">
                            <Dropdown
                                title={"Select language"}
                                size={'full'}
                                search={false}
                                searchFilter={false}
                                showError={false}
                                filter={false}
                                placeholder={"Click here ..."}
                                options={this.renders.languages()}
                                value={this.state.targeting.linkedin.language ? this.state.targeting.linkedin.language : {}}
                                onChange={(value) => {
                                    this.state.targeting.linkedin.language = value;
                                    this.setState({
                                        targeting: this.state.targeting
                                    }, () => {
                                        this.props.onChange(this.state.targeting);
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    this.state.errors.length > 0 &&
                    <ErrorAlert
                        items={this.state.errors}
                    />
                }
            </div>
        )
    }
}

export default TargetingLinkedin;
