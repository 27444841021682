import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import AdvertisingCreativesCarouselSlides from "../advertising-creatives-carouselslides";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import {toArray} from "react-emoji-render";
import AdvertisingCreativesAds from "../advertising-creatives-ads";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import AdvertisingCreativesSearch from "../advertising-creatives-search";
import AdvertisingCreativesSearchList from "../advertising-creatives-search-list";
import AdvertisingCreativesSearchAds from "../advertising-creatives-search-ads";
import AdvertisingCreativesSitelinks from "../advertising-creatives-sitelinks";
import Accordion from "../accordion";
import {calls} from "./calls";
import AdvertisingCreativesProducts from "../advertising-creatives-products";
import ReactTooltip from "react-tooltip";
import AdvertisingCreativesCall from "../advertising-creatives-call";

class AdvertisingCreatives extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: true,
            settings: {},
            client: {},
            channels: {},
            creatives: {},
            conversion: {},
            site: {},
            error: null,
            errors: [],
            languages: [],
            campaign: {},
            adset: {},
            adsets: [{id: 123, title: 'test'}]
        };
        this.init.data = this.init.data.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            open: nextProps.open,
            campaign: nextProps.campaign
        })
    }

    componentWillMount() {
        eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        this.init.first();
        this.init.data();
    }

    init = {
        first: () => {
            let information = campaignRegister.get();
            this.setState({
                open: this.props.open,
                campaign: this.props.campaign,
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                conversion: information ? (information.conversion ? (information.conversion.value ? information.conversion.value : {}) : {}) : {},
                languages: information ? (information.languages ? (information.languages.value ? information.languages.value : []) : []) : [],
            }, () => {
                if(!this.state.creatives.languages || (Object.keys(this.state.creatives.languages).length === 0 && this.state.creatives.languages.constructor === Object)){
                    this.state.creatives.languages = {};
                    for(let i = 0; i < this.state.languages.length; i++){
                        this.state.creatives.selected_language = this.state.languages[i].code;
                        this.state.creatives.languages[this.state.languages[i].code] = {
                            headline: "",
                            headline_second: "",
                            headline_third: "",
                            body: "",
                            body_second: "",
                            site_links: [],
                            extra_headlines: [],
                            phone: "",
                            phone_code: "",
                            company_name: ""
                        };
                    }
                    this.setState({
                        creatives: this.state.creatives
                    }, () => {
                        this.functions.save('creatives');
                    })
                }
            })
        },
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                campaign: this.props.campaign,
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                keywords: information ? (information.keywords ? (information.keywords.value ? information.keywords.value : {}) : {}) : {},
                languages: information ? (information.languages ? (information.languages.value ? information.languages.value : []) : []) : [],
                conversion: information ? (information.conversion ? (information.conversion.value ? information.conversion.value : {}) : {}) : {},
                current: information ? (information.creatives ? (information.creatives ? information.creatives.open : false) : false) : false,
            })
        }
    };

    functions = {
        next: () => {
            if (this.renders.errors().length > 0) {
                this.setState({
                    error: true
                });
            } else {
                this.props.onNext();
            }
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        }
    };

    validators = {};

    renders = {
        subtitle: () => {
            try {
                let string = [];
                return string.join(', ');
            } catch (e) {
                return ""
            }
        },
        characters: (item) => {
            try{
                return item.toString().length
            }catch (e) {
                return 0
            }
        },
        property: (type) => {
            try{
                return this.state.creatives.languages[this.state.creatives.selected_language][type];
            }catch (e) {
                if(type !== 'site_links' && type !== 'extra_headlines'){
                    return ""
                }else{
                    return []
                }
            }
        },
        errorsSocial: () => {
            let errors = [];
            try{
                if(this.state.channels.facebook || this.state.channels.instagram){
                    for(let i = 0; i < this.state.languages.length; i ++){
                        let lang = this.state.languages[i];
                        if(!this.state.creatives.languages[lang.code].headline || this.state.creatives.languages[lang.code].headline === ""){
                            errors.push("Headline first is missing (" + lang.name + ")");
                        }
                        if(!this.state.creatives.languages[lang.code].body || this.state.creatives.languages[lang.code].body === ""){
                            errors.push("Body is missing (" + lang.name + ")");
                        }
                    }
                }
            }catch (e) {}
            try{
                if(this.state.channels.facebook || this.state.channels.instagram){
                    this.state.creatives.image_ads.filter((item) => {
                        return item.show
                    }).map((item, index) => {
                        if(!item.title || item.title === ""){
                            errors.push('Title for Image ' + (index+1) + " is missing");
                        }
                        if(item.internal_adset && item.internal_adset.custom && (item.internal_adset.value === "" || !item.internal_adset.value)){
                            if(!item.title || item.title === ""){
                                errors.push('Group name for Image ' + (index+1) + " is missing");
                            }else{
                                errors.push('Group name for ' + item.title + " is missing");
                            }
                        }
                        for(let i = 0; i < this.state.languages.length; i ++){
                            if(!item.languages[this.state.languages[i].code].headline || item.languages[this.state.languages[i].code].headline === ""){
                                errors.push('Headline for <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                            }
                            if(!item.languages[this.state.languages[i].code].body || item.languages[this.state.languages[i].code].body === ""){
                                errors.push('Body for <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                            }
                            if(!item.languages[this.state.languages[i].code].website || item.languages[this.state.languages[i].code].website === ""){
                                errors.push('Website for <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                            }
                        }
                    })
                }
            }catch (e) {
                //console.log(e);
            }
            try{
                if(this.state.channels.facebook || this.state.channels.instagram){
                    this.state.creatives.video_ads.filter((item) => {
                        return item.show
                    }).map((item, index) => {
                        if(!item.title || item.title === ""){
                            errors.push('Title for Video ' + (index+1) + " is missing");
                        }
                        if(item.internal_adset && item.internal_adset.custom && (item.internal_adset.value === "" || !item.internal_adset.value)){
                            if(!item.title || item.title === ""){
                                errors.push('Group name for Video ' + (index+1) + " is missing");
                            }else{
                                errors.push('Group name for ' + item.title + " is missing");
                            }
                        }
                        for(let i = 0; i < this.state.languages.length; i ++){
                            if(!item.languages[this.state.languages[i].code].headline || item.languages[this.state.languages[i].code].headline === ""){
                                errors.push('Headline for <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                            }
                            if(!item.languages[this.state.languages[i].code].body || item.languages[this.state.languages[i].code].body === ""){
                                errors.push('Body for <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                            }
                            if(!item.languages[this.state.languages[i].code].website || item.languages[this.state.languages[i].code].website === ""){
                                errors.push('Website for <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                            }
                            if(!item.languages[this.state.languages[i].code].creative.thumbnail){
                                errors.push('Thumbnail for <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                            }
                        }
                    })
                }
            }catch (e) {}
            try{
                if(this.state.channels.facebook || this.state.channels.instagram){
                    this.state.creatives.carousel_ads.filter((item) => {
                        return item.show
                    }).map((item, index) => {
                        if(!item.title || item.title === ""){
                            errors.push('Title for Carousel ' + (index+1) + " is missing");
                        }
                        if(item.internal_adset && item.internal_adset.custom && (item.internal_adset.value === "" || !item.internal_adset.value)){
                            if(!item.title || item.title === ""){
                                errors.push('Group name for Carousel ' + (index+1) + " is missing");
                            }else{
                                errors.push('Group name for ' + item.title + " is missing");
                            }
                        }
                        for(let i = 0; i < this.state.languages.length; i ++){
                            if(!item.languages[this.state.languages[i].code].body || item.languages[this.state.languages[i].code].body === ""){
                                errors.push('Body for <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                            }
                            if(!item.languages[this.state.languages[i].code].website || item.languages[this.state.languages[i].code].website === ""){
                                errors.push('Website for <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                            }
                            item.languages[this.state.languages[i].code].items.map((inner_item, inner_index) => {
                                if(!inner_item.headline || inner_item.headline === ""){
                                    errors.push('Headline for slide ' + (inner_index+1) + ' in <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                                }
                                if(!inner_item.description || inner_item.description === ""){
                                    errors.push('Description for slide ' + (inner_index+1) + ' in <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                                }
                                if(!inner_item.website || inner_item.website === ""){
                                    errors.push('Website for slide ' + (inner_index+1) + ' in <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                                }
                                if(inner_item.video && !inner_item.creative.thumbnail){
                                    errors.push('Video thumbnail video for slide ' + (inner_index+1) + ' in <b>'+item.title+'</b> is missing (' + this.state.languages[i].name + ')');
                                }
                            });
                            if(item.languages[this.state.languages[i].code].items.filter((inner_item) => {
                                return !inner_item.error
                            }).length < 2){
                                errors.push('Minimum slides is 2 for <b>'+item.title+'</b> (' + this.state.languages[i].name + ')');
                            }
                            if(item.languages[this.state.languages[i].code].items.filter((inner_item) => {
                                return !inner_item.error
                            }).length > 10){
                                errors.push('Maximum slides is 10 for <b>'+item.title+'</b> (' + this.state.languages[i].name + ')');
                            }
                        }
                    })
                }
            }catch (e) {}
            try{
                if(this.state.channels.facebook || this.state.channels.instagram){
                    let images = 0;
                    let videos = 0;
                    let carousels = 0;
                    let dynamic = 0;
                    if(this.state.creatives.carousel_ads){
                        this.state.creatives.carousel_ads.map((item) => {
                            if(item.show){
                                carousels = carousels + 1;
                            }
                        })
                    }
                    if(this.state.creatives.video_ads){
                        this.state.creatives.video_ads.map((item) => {
                            if(item.show){
                                videos = videos + 1;
                            }
                        })
                    }
                    if(this.state.creatives.image_ads){
                        this.state.creatives.image_ads.map((item) => {
                            if(item.show){
                                images = images + 1;
                            }
                        })
                    }
                    if(this.state.creatives.dynamic_ads){
                        this.state.creatives.dynamic_ads.map((item) => {
                            if(item.show){
                                dynamic = dynamic + 1;
                            }
                        })
                    }
                    if((images+videos+carousels+dynamic) < 1){
                        errors.push('Social content is missing');
                    }
                }
            }catch (e) {}
            try{
                if(this.state.channels.facebook || this.state.channels.instagram){
                    if(this.state.creatives.dynamic_ads){
                        this.state.creatives.dynamic_ads.map((item) => {
                            if(item.show && !item.catalog){
                                errors.push('Select catalog for ' + item.title);
                            }
                            if(item.show && !item.product_set){
                                errors.push('Select product set for ' + item.title);
                            }
                        })
                    }
                }
            }catch (e) {
                //console.log(e);
            }
            return errors;
        },
        errorsSearch: () => {
            let errors = [];
            try{
                if(this.state.channels.google){
                    for(let i = 0; i < this.state.languages.length; i ++){
                        let lang = this.state.languages[i];
                        if(!this.state.creatives.languages[lang.code].headline || this.state.creatives.languages[lang.code].headline === ""){
                            errors.push("Headline first is missing (" + lang.name + ")");
                        }
                        if(!this.state.creatives.languages[lang.code].headline_second || this.state.creatives.languages[lang.code].headline_second === ""){
                            errors.push("Headline second is missing (" + lang.name + ")");
                        }
                        if(!this.state.creatives.languages[lang.code].body || this.state.creatives.languages[lang.code].body === ""){
                            errors.push("Body is missing (" + lang.name + ")");
                        }
                    }
                }
            }catch (e) {}
            try{
                if(this.state.channels.google){
                    this.state.creatives.search_ads.map((item) => {
                        if(item.error){
                            errors.push(item.error_message);
                        }
                    })
                }
            }catch (e) {}
            return errors;
        },
        errorsCommon: () => {
            let errors = [];
            try{
                if(this.state.channels.google){
                    for(let i = 0; i < this.state.languages.length; i ++){
                        let lang = this.state.languages[i];
                        if(!this.state.creatives.languages[lang.code].headline || this.state.creatives.languages[lang.code].headline === ""){
                            errors.push("Headline first is missing (" + lang.name + ")");
                        }
                        if(!this.state.creatives.languages[lang.code].headline_second || this.state.creatives.languages[lang.code].headline_second === ""){
                            errors.push("Headline second is missing (" + lang.name + ")");
                        }
                        if(!this.state.creatives.languages[lang.code].body || this.state.creatives.languages[lang.code].body === ""){
                            errors.push("Body is missing (" + lang.name + ")");
                        }
                    }
                }
                if(this.state.channels.facebook || this.state.channels.instagram){
                    for(let i = 0; i < this.state.languages.length; i ++){
                        let lang = this.state.languages[i];
                        if(!this.state.creatives.languages[lang.code].headline || this.state.creatives.languages[lang.code].headline === ""){
                            errors.push("Headline first is missing (" + lang.name + ")");
                        }
                        if(!this.state.creatives.languages[lang.code].body || this.state.creatives.languages[lang.code].body === ""){
                            errors.push("Body is missing (" + lang.name + ")");
                        }
                    }
                }
            }catch (e) {}
            return errors;
        },
        errors: () => {
            let search = this.renders.errorsSearch();
            let social = this.renders.errorsSocial();
            let errors = search.concat(social);
            return errors
        },
        mandatory: () => {
            try{
                let show = true;
                if(this.state.channels.google){
                    for(let lang in this.state.creatives.languages){
                        if(!this.state.creatives.languages[lang].headline || this.state.creatives.languages[lang].headline === ""){
                            show = false;
                        }
                        if(!this.state.creatives.languages[lang].headline_second || this.state.creatives.languages[lang].headline_second === ""){
                            show = false;
                        }
                        if(!this.state.creatives.languages[lang].body || this.state.creatives.languages[lang].body === ""){
                            show = false;
                        }
                    }
                }
                if(this.state.channels.facebook || this.state.channels.instagram){
                    for(let lang in this.state.creatives.languages){
                        if(!this.state.creatives.languages[lang].headline || this.state.creatives.languages[lang].headline === ""){
                            show = false;
                        }
                        if(!this.state.creatives.languages[lang].body || this.state.creatives.languages[lang].body === ""){
                            show = false;
                        }
                    }
                }
                return show;
            }catch (e) {
                return false
            }
        },
        markdown: (input) => {
            return { __html: input }
        },
        checkEmojiHeadline: () => {
            try{
                let emoji = false;
                emoji = this.renders.property('headline').match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g);
                return emoji;
            }catch (e) {
                return false
            }
        },
        checkEmojiBody: () => {
            try{
                let emoji = false;
                emoji = this.renders.property('body').match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g);
                return emoji;
            }catch (e) {
                return false
            }
        },
        removeEmoji: (input) => {
            try{
                return input.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '');
            }catch (e) {
                return input
            }
        }
    };

    render() {
        return (
            <AccordionFixed
                noPadding={true}
                icon={this.renders.errors().length > 0 ? "" : "check"}
                icon_color={"#0185fe"}
                open={this.state.open}
                title={this.renders.subtitle().length > 0 ? "Step 4: Creatives" : "Step 4: Set creatives"}
                subtitle={this.renders.subtitle()}
                edit={this.state.edit}
                onEdit={() => {
                    this.props.onEdit(!this.state.open);
                }}
            >
                <div style={{backgroundColor: '#ffffff', padding: '15px', borderRadius: '5px', marginTop: '30px', paddingTop: '0px'}}>
                    <div>
                        {
                            <div className="Tabs">
                                {
                                    this.state.languages.map((item) => {
                                        return (
                                            <div onClick={() => {
                                                this.state.creatives.selected_language = item.code;
                                                this.setState({
                                                    creatives: this.state.creatives
                                                }, () => {
                                                    this.functions.save('creatives');
                                                });
                                            }} className={
                                                cx("Tab",
                                                    {
                                                        ["Tab-Active"]: item.code === this.state.creatives.selected_language,
                                                    })
                                            }>
                                                <span style={{textTransform: 'capitalize'}}>{item.name}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            false &&
                            <nav className="py-2">
                                <ul className="flex flex-row items-center overflow-scroll w-full whitespace-no-wrap">
                                    {this.state.adsets.map((item, i) => {
                                        return (
                                            <li key={i}>
                                                <button onClick={() => {
                                                    this.setState({
                                                        selected: item.id,
                                                        adset: item,
                                                    })
                                                }}
                                                        className={cx("cursor-pointer py-2 px-4 hover:opacity-100 font-bold text-sm border-b-2 flex flex-row items-center focus:outline-none", {
                                                            ["border-purple-500 opacity-100 text-purple-500"]: item.id === this.state.selected,
                                                            ["border-gray-300 opacity-50"]: item.id != this.state.selected
                                                        })}>
                                                    <span className="text-gray-900">{item.title}</span>
                                                </button>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </nav>
                        }
                        {
                            false &&
                            <div style={{width: '100%', marginTop: '15px'}}>
                                <Input
                                    title={"Adset Title"}
                                    sign={false}
                                    maxLength={30}
                                    white={false}
                                    error={!this.state.adset.title || this.state.adset.title === ""}
                                    value={this.state.adset.title}
                                    placeholder={"Max 30 characters"}
                                    tight={true}
                                    type={"text"}
                                    size={'full'}
                                    icons={false}
                                    onChange={(value) => {
                                        this.state.adset.title = value.target.value;
                                        this.setState({
                                            adset: this.state.adset
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.creatives.languages &&
                            <div style={{width: '100%', marginTop: '15px'}}>
                                <div className="Creative-search-item-bottom-inner-right-item">
                                    <div style={{flex: '1', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <Input
                                            title={this.state.channels.google?'Headline first':"Headline"}
                                            sign={true}
                                            maxLength={30}
                                            white={false}
                                            signTitle={this.renders.characters(this.renders.property('headline')) +' / 30'}
                                            error={(this.renders.property('headline') === "" && (this.state.error || this.state.creatives.edit_ads)) || this.renders.characters(this.renders.property('headline')) > 30}
                                            value={this.renders.property('headline')}
                                            placeholder={"Max 30 characters"}
                                            tight={true}
                                            type={"text"}
                                            size={!this.state.channels.google?'full':'third'}
                                            icons={this.state.channels.google}
                                            //showSugestions={true}
                                            //sugestions={["smarteyes", "köp glasogon idag, med din rabattkod"]}
                                            channels={Object.keys(this.state.channels).filter((key) => {
                                                return this.state.channels[key]
                                            })}
                                            onChange={(value) => {
                                                try{
                                                    this.state.creatives.languages[this.state.creatives.selected_language].headline = value.target.value;
                                                    this.setState({
                                                        creatives: this.state.creatives
                                                    }, () => {
                                                        this.functions.save('creatives');
                                                    });
                                                }catch (e) {
                                                   // console.log(e);
                                                }
                                            }}
                                        />
                                        {
                                            this.state.channels.google &&
                                            <Input
                                                title={'Headline second'}
                                                sign={true}
                                                maxLength={30}
                                                white={false}
                                                size={'third'}
                                                signTitle={this.renders.characters(this.renders.property('headline_second')) +' / 30'}
                                                error={(this.renders.property('headline_second') === "" && (this.state.error || this.state.creatives.edit_ads)) || this.renders.characters(this.renders.property('headline_second')) > 30}
                                                value={this.renders.property('headline_second')}
                                                placeholder={"Max 30 characters"}
                                                tight={true}
                                                noBorderRight={false}
                                                type={"text"}
                                                icons={true}
                                                channels={Object.keys(this.state.channels).filter((key) => {
                                                    return this.state.channels[key] && key === 'google'
                                                })}
                                                onChange={(value) => {
                                                    try{
                                                        this.state.creatives.languages[this.state.creatives.selected_language].headline_second = this.renders.removeEmoji(value.target.value);
                                                        this.setState({
                                                            creatives: this.state.creatives
                                                        }, () => {
                                                            this.functions.save('creatives');
                                                        });
                                                    }catch (e) {
                                                       // console.log(e);
                                                    }
                                                }}
                                            />
                                        }
                                        {
                                            this.state.channels.google &&
                                            <Input
                                                title={'Headline third (optional)'}
                                                sign={true}
                                                maxLength={30}
                                                white={false}
                                                signTitle={this.renders.characters(this.renders.property('headline_third')) +' / 30'}
                                                value={this.renders.property('headline_third')}
                                                placeholder={"Max 30 characters"}
                                                tight={true}
                                                noBorderRight={false}
                                                size={'third'}
                                                type={"text"}
                                                icons={true}
                                                error={this.renders.characters(this.renders.property('headline_third')) > 30}
                                                channels={Object.keys(this.state.channels).filter((key) => {
                                                    return this.state.channels[key] && key === 'google'
                                                })}
                                                onChange={(value) => {
                                                    try{
                                                        this.state.creatives.languages[this.state.creatives.selected_language].headline_third = this.renders.removeEmoji(value.target.value);
                                                        this.setState({
                                                            creatives: this.state.creatives
                                                        }, () => {
                                                            this.functions.save('creatives');
                                                        });
                                                    }catch (e) {
                                                        //console.log(e);
                                                    }
                                                }}
                                            />
                                        }
                                    </div>
                                    {
                                        false &&
                                        <div style={{minWidth: '55px', marginLeft: '10px', marginTop: '26px'}}>
                                            <div onClick={() => {
                                                try{
                                                    if(this.state.creatives.languages[this.state.creatives.selected_language].extra_headlines){
                                                        this.state.creatives.languages[this.state.creatives.selected_language].extra_headlines.push("");
                                                    }else{
                                                        this.state.creatives.languages[this.state.creatives.selected_language].extra_headlines = [];
                                                        this.state.creatives.languages[this.state.creatives.selected_language].extra_headlines.push("");
                                                    }
                                                    this.setState({
                                                        creatives: this.state.creatives
                                                    }, () => {
                                                        this.functions.save('creatives');
                                                    });
                                                }catch (e) {}
                                            }} data-tip={"Add extra headline"} className="Button ButtonFull ButtonNeutral">
                                                <FeatherIcon color={"#333333"} size={25} icon="plus"/>
                                                <ReactTooltip />
                                            </div>
                                        </div>
                                    }
                                </div>
                                {
                                    this.state.channels.google && this.renders.checkEmojiHeadline() &&
                                    <div style={{paddingTop: '15px'}} className="SectionError Section">
                                        Emojis will be removed in Google Headline
                                    </div>
                                }
                                {
                                    false &&
                                    this.renders.property('extra_headlines').length > 0 &&
                                    <div style={{paddingTop: '15px'}} className="Section">
                                        <div className="Section-Inner">
                                            <div className="Section-InnerTitle">
                                                Extra headlines
                                            </div>
                                            <div style={{width: '100%'}}>
                                                {
                                                    this.renders.property('extra_headlines').map((item, index) => {
                                                        return(
                                                            <div style={index !== 0 ? {width: '100%', display: 'flex', flexDirection: 'row', marginTop: '10px'}: {width: '100%', display: 'flex', flexDirection: 'row'}}>
                                                                <Input
                                                                    noTitle={true}
                                                                    title={'Extra headlines'}
                                                                    icons={false}
                                                                    sign={true}
                                                                    signTitle={this.renders.characters(item) +' / 30'}
                                                                    maxLength={30}
                                                                    placeholder={"Max 30 characters"}
                                                                    disabled={false}
                                                                    size={'full'}
                                                                    type={"text"}
                                                                    value={item}
                                                                    onChange={(value) => {
                                                                        try{
                                                                            this.state.creatives.languages[this.state.creatives.selected_language].extra_headlines[index] = value.target.value;
                                                                            this.setState({
                                                                                creatives: this.state.creatives
                                                                            }, () => {
                                                                                this.functions.save('creatives');
                                                                            });
                                                                        }catch (e) {
                                                                            //console.log(e);
                                                                        }
                                                                    }}
                                                                />
                                                                <div style={{minWidth: '55px', marginLeft: '10px'}}>
                                                                    <div onClick={() => {
                                                                        try{
                                                                            this.state.creatives.languages[this.state.creatives.selected_language].extra_headlines.splice(index, 1);
                                                                            this.setState({
                                                                                creatives: this.state.creatives
                                                                            }, () => {
                                                                                this.functions.save('creatives');
                                                                            });
                                                                        }catch (e) {}
                                                                    }} className="Button ButtonFull ButtonRed">
                                                                        <FeatherIcon color={"#ffffff"} size={23} icon="trash"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="Creative-search-item-bottom-inner-right-item">
                                    <TextArea
                                        sign={true}
                                        maxLength={this.state.channels.google?90:125}
                                        signTitle={this.renders.characters(this.renders.property('body')) + (this.state.channels.google?' / 90':' / 125')}
                                        tiny={this.state.channels.google}
                                        white={false}
                                        size={!this.state.channels.google?"full":"half"}
                                        title={"Body"}
                                        placeholder={this.state.channels.google?"Max 90 characters":"Max 125 characters"}
                                        error={((this.renders.property('body') === "" || !this.renders.property('body')) && (this.state.error || this.state.creatives.edit_ads))}
                                        value={this.renders.property('body')}
                                        icons={this.state.channels.google}
                                        channels={Object.keys(this.state.channels).filter((key) => {
                                            return this.state.channels[key]
                                        })}
                                        onChange={(value) => {
                                            try{
                                                this.state.creatives.languages[this.state.creatives.selected_language].body = value.target.value;
                                                this.setState({
                                                    creatives: this.state.creatives
                                                }, () => {
                                                    this.functions.save('creatives');
                                                });
                                            }catch (e) {}
                                        }}
                                    />
                                    {
                                        this.state.channels.google &&
                                        <TextArea
                                            maxLength={90}
                                            tiny={true}
                                            sign={true}
                                            white={false}
                                            signTitle={this.renders.characters(this.renders.property('body_second')) +' / 90'}
                                            size={"half"}
                                            title={"Body extended (optional)"}
                                            placeholder={"Max 90 characters"}
                                            value={this.renders.property('body_second')}
                                            icons={true}
                                            error={this.renders.characters(this.renders.property('body_second')) > 90}
                                            channels={Object.keys(this.state.channels).filter((key) => {
                                                return this.state.channels[key] && key === 'google'
                                            })}
                                            onChange={(value) => {
                                                try{
                                                    this.state.creatives.languages[this.state.creatives.selected_language].body_second = this.renders.removeEmoji(value.target.value);
                                                    this.setState({
                                                        creatives: this.state.creatives
                                                    }, () => {
                                                        this.functions.save('creatives');
                                                    });
                                                }catch (e) {}
                                            }}
                                        />
                                    }
                                </div>
                                {
                                    this.state.channels.google && this.renders.checkEmojiBody() &&
                                    <div style={{paddingTop: '15px'}} className="SectionError Section">
                                        Emojis will be removed in Google Body
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {
                        this.state.channels.google &&
                        <div className="Section">
                            <div className="Section-Inner">
                                <div className="Section-InnerTitle">
                                    <div>
                                        Extensions
                                    </div>
                                    {
                                        <div className={cx("InputContainerTitleIcons")}>
                                            <div style={{marginLeft: '3px'}}>
                                                <IconGoogle noMargin={true}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.channels.google &&
                        <div style={{marginTop: '0px'}} className="mt-4">
                            <div className="Advertising-Creatives-ads-title">
                                <FeatherIcon color={"#333333"} size={24} icon="link"/>
                                <div className="Advertising-Creatives-ads-title-text">
                                    Site links
                                </div>
                                <div className="Advertising-Creatives-ads-title-slider">
                                    <Switch
                                        onColor="#24bda8"
                                        checked={this.state.creatives.show_sitelinks}
                                        disabled={false}
                                        onChange={(checked) => {
                                            this.state.creatives.show_sitelinks = checked;
                                            try{
                                                if(!checked){
                                                    this.state.creatives.languages[this.state.creatives.selected_language].site_links = [];
                                                }
                                            }catch (e) {}
                                            this.setState({
                                                creatives: this.state.creatives
                                            }, () => {
                                                this.functions.save('creatives');
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.channels.google &&
                        <div className="mt-4">
                            <div className="Advertising-Creatives-ads-title">
                                <FeatherIcon color={"#333333"} size={24} icon="phone"/>
                                <div className="Advertising-Creatives-ads-title-text">
                                    Phone number
                                </div>
                                <div className="Advertising-Creatives-ads-title-slider">
                                    <Switch
                                        onColor="#24bda8"
                                        checked={this.state.creatives.show_call}
                                        disabled={false}
                                        onChange={(checked) => {
                                            this.state.creatives.show_call = checked;
                                            if(!checked){
                                                this.state.creatives.phone_code = {};
                                                this.state.creatives.phone_number = "";
                                            }
                                            this.setState({
                                                creatives: this.state.creatives
                                            }, () => {
                                                this.functions.save('creatives');
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.channels.google && this.state.creatives.show_sitelinks &&
                        <div className="mt-4">
                            <AdvertisingCreativesSitelinks
                                site={this.state.site}
                                creatives={this.state.creatives}
                                channels={this.state.channels}
                                onChange={(value) => {
                                    this.setState({
                                        creatives: value
                                    }, () => {
                                        this.functions.save('creatives');
                                    });
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.channels.google && this.state.creatives.show_call &&
                        <div className="mt-4">
                            <AdvertisingCreativesCall
                                site={this.state.site}
                                creatives={this.state.creatives}
                                channels={this.state.channels}
                                onChange={(value) => {
                                    this.setState({
                                        creatives: value
                                    }, () => {
                                        this.functions.save('creatives');
                                    });
                                }}
                            />
                        </div>
                    }

                    {
                        !this.state.creatives.edit_ads &&
                        <div>
                            {
                                this.state.error &&
                                this.renders.errorsCommon().map((item) => {
                                    return(
                                        <div className="Site-Error">
                                            {item}
                                        </div>
                                    )
                                })
                            }

                            <div className="mt-4 text-right">
                                <button onClick={() => {
                                    if(this.renders.errorsCommon().length < 1){
                                        this.state.creatives.edit_ads = true;
                                    }
                                    this.setState({
                                        creatives: this.state.creatives,
                                        error: this.renders.errorsCommon().length > 0
                                    }, () => {
                                        this.functions.save('creatives');
                                    });
                                }} className="btn btn-lg btn-primary inline-flex items-center">
                                    Next <span className="arrow-right"></span>
                                </button>
                            </div>
                        </div>
                    }
                    {
                        (this.state.channels.facebook || this.state.channels.instagram) && this.state.channels.google && this.renders.mandatory() && this.state.creatives.edit_ads &&
                        <Accordion
                            inner={true}
                            icon_color={"#0185fe"}
                            title={"Click here to edit your Search Ads"}
                            noPadding={true}
                            channels={this.state.channels}
                            type={'search'}
                            open={this.renders.mandatory() && this.state.creatives.edit_ads}
                            noBottomBorder={(this.state.channels.facebook || this.state.channels.instagram)}
                        >
                            <div style={{width: '100%', height: '15px'}}></div>
                            <AdvertisingCreativesSearchAds
                                channels={this.state.channels}
                                creatives={this.state.creatives}
                                languages={this.state.languages}
                                site={this.state.site}
                                onChange={(value) => {
                                    this.state.creatives = value;
                                    this.functions.save('creatives');
                                }}
                            />
                        </Accordion>
                    }
                    {
                        (!this.state.channels.facebook && !this.state.channels.instagram) && this.state.channels.google && this.renders.mandatory() && this.state.creatives.edit_ads &&
                        <div style={{paddingTop: '20px', boxSizing: 'border-box'}}>
                            <AdvertisingCreativesSearchAds
                                channels={this.state.channels}
                                creatives={this.state.creatives}
                                languages={this.state.languages}
                                site={this.state.site}
                                onChange={(value) => {
                                    this.state.creatives = value;
                                    this.functions.save('creatives');
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.channels.google && (this.state.channels.facebook || this.state.channels.instagram) && this.renders.mandatory() && this.state.creatives.edit_ads &&
                        <Accordion
                            inner={true}
                            icon_color={"#0185fe"}
                            title={"Click here to edit your Social Ads"}
                            channels={this.state.channels}
                            type={'social'}
                            open={!this.state.channels.google && this.renders.mandatory() && this.state.creatives.edit_ads}
                            noPadding={true}
                        >
                            <AdvertisingCreativesUpload
                                title={"Upload content - mp4, jpg, png"}
                                size={"full"}
                                cover={false}
                                site={this.state.site}
                                client={this.state.client}
                                channels={this.state.channels}
                                creatives={this.state.creatives}
                                languages={this.state.languages}
                                onChange={(value) => {
                                    this.state.creatives = value;
                                    this.functions.save('creatives');
                                }}
                            />
                            <AdvertisingCreativesAds
                                channels={this.state.channels}
                                error={this.state.error}
                                client={this.state.client}
                                site={this.state.site}
                                languages={this.state.languages}
                                creatives={this.state.creatives}
                                onChange={(value) => {
                                    this.state.creatives = value;
                                    this.functions.save('creatives');
                                }}
                            />
                            <AdvertisingCreativesProducts
                                channels={this.state.channels}
                                error={this.state.error}
                                client={this.state.client}
                                site={this.state.site}
                                languages={this.state.languages}
                                creatives={this.state.creatives}
                                onChange={(value) => {
                                    this.state.creatives = value;
                                    this.functions.save('creatives');
                                }}
                            />
                        </Accordion>
                    }
                    {
                        !this.state.channels.google && (this.state.channels.facebook || this.state.channels.instagram) && this.renders.mandatory() && this.state.creatives.edit_ads &&
                        <div style={{paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box'}}>
                            <AdvertisingCreativesUpload
                                title={"Upload content - mp4, jpg, png"}
                                size={"full"}
                                cover={false}
                                site={this.state.site}
                                client={this.state.client}
                                channels={this.state.channels}
                                creatives={this.state.creatives}
                                languages={this.state.languages}
                                onChange={(value) => {
                                    this.state.creatives = value;
                                    this.functions.save('creatives');
                                }}
                            />
                            <AdvertisingCreativesAds
                                channels={this.state.channels}
                                error={this.state.error}
                                client={this.state.client}
                                site={this.state.site}
                                languages={this.state.languages}
                                creatives={this.state.creatives}
                                onChange={(value) => {
                                    this.state.creatives = value;
                                    this.functions.save('creatives');
                                }}
                            />
                            <AdvertisingCreativesProducts
                                channels={this.state.channels}
                                error={this.state.error}
                                client={this.state.client}
                                site={this.state.site}
                                languages={this.state.languages}
                                creatives={this.state.creatives}
                                onChange={(value) => {
                                    this.state.creatives = value;
                                    this.functions.save('creatives');
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.creatives.edit_ads &&
                        <div style={{paddingLeft: '15px', paddingRight: '15px', boxSizing: 'border-box'}}>
                            {
                                this.renders.mandatory() &&
                                this.renders.errors().map((item) => {
                                    return(
                                        <div dangerouslySetInnerHTML={this.renders.markdown(item)} className="Site-Error"/>
                                    )
                                })
                            }
                            {
                                !this.renders.mandatory() &&
                                this.renders.errorsCommon().map((item) => {
                                    return(
                                        <div dangerouslySetInnerHTML={this.renders.markdown(item)} className="Site-Error"/>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        this.renders.mandatory() && this.state.creatives.edit_ads &&
                        <div className="mt-4 text-right">
                            <button onClick={() => {
                                this.functions.next()
                            }} className="btn btn-lg btn-primary inline-flex items-center">
                                Next step <span className="arrow-right"></span>
                            </button>
                        </div>
                    }
                </div>
            </AccordionFixed>
        )
    }
}

export default AdvertisingCreatives;
