import moment from 'moment';
import React, { Component } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';
import formated_timezones from '../assets/json/google_timezones.json';
import SwitchTailwind from './switchTailwind';

class UpdateConversionAction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            conversion_action: {}
        };
    }

    componentDidMount() {
        this.setState({
            conversion_action: this.props.conversionAction,
        });
    }

    functions = {
        createAccount: async () => {

        }
    };

    calls = {
        createAccount: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/createAccount?client=" + this.state.client.id + "&manager=" + this.state.manager.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">
                    <div className="flex flex-1">
                        <div className="w-full">
                            <div className="grid-cols-12 gap-4 grid mb-8">
                                <div className={"col-span-12"}>
                                    <InputTailwind
                                        label={"Name"}
                                        locked={true}
                                        readOnly={true}
                                        value={this.state.conversion_action.conversionAction ? this.state.conversion_action.conversionAction.name : ""}
                                        onChange={(value) => {

                                        }}
                                    />
                                </div>
                                {
                                    this.state.conversion_action.conversionAction &&
                                    Array.isArray(this.state.conversion_action.conversionAction.tagSnippets) &&
                                    this.state.conversion_action.conversionAction.tagSnippets.map((item) => {  
                                        return (
                                            <div className="col-span-12">
                                                <div class="text-xxs font-medium mb-1">{item.type + " - " + item.pageFormat}</div>
                                                {
                                                    item.globalSiteTag &&
                                                    <div className="border mb-4 text-sm rounded-md bg-custom-input p-4">
                                                    {
                                                        item.globalSiteTag
                                                    }
                                                    </div>
                                                }
                                                <div className="border text-sm rounded-md bg-custom-input p-4">
                                                    {
                                                        item.eventSnippet
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default UpdateConversionAction;