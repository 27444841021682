import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingTargetingFacebook from "../advertising-targeting-facebook";
import languages from '../../assets/json/languages';
import {calls} from "./calls";
import ReactTooltip from "react-tooltip";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";
import CSVReader from 'react-csv-reader'
import AdvertisingKeywordsIdeas from "../advertising-keywords-ideas";
import AdvertisingKeywordsLanguage from "../advertising-keywords-language";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import {SlideDown} from "react-slidedown";
import AdvertisingKeywordsPositiveAdd from "../advertising-keywords-positive-add";
import AdvertisingCreativesSearchPreview from "../advertising-creatives-search-preview";
import AdvertisingSearchTemplatePreview from "../advertising-search-template-preview";

class AdvertisingKeywordsPositiveItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            multiple: false,
            keyword: {},
            site: {},
            creatives: {},
            search: {},
            campaign: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            multiple: nextProps.multiple,
            keyword: nextProps.keyword,
            campaign: nextProps.campaign,
            search: nextProps.search
        });
    }

    componentDidMount() {
        this.setState({
            multiple: this.props.multiple,
            keyword: this.props.keyword,
            campaign: this.props.campaign,
            search: this.props.search
        })
    }

    init = {};

    functions = {};

    validators = {};

    renders = {
        characters: () => {
            try {
                let prefix = this.state.keyword.prefix ? this.state.keyword.prefix.length : 0;
                let keyword = this.state.keyword.name.length;
                let suffix = this.state.keyword.suffix ? this.state.keyword.suffix.length : 0;
                return prefix + keyword + suffix;
            } catch (e) {
                return 0
            }
        },
        insert: () => {
            try{
                return Array.isArray(this.state.search.templates)?this.state.search.templates.filter((item) => {
                    return item.campaign_id === this.state.campaign.id && (item.headline_third_template.type === 'keyword_insert' || item.headline_second_template.type === 'keyword_insert' || item.headline_first_template.type === 'keyword_insert')
                }):[]
            }catch (e) {
                return []
            }
        },
        all: () => {
            try{
                return this.state.search.templates?this.state.search.templates.filter((item) => {
                    let keyword_insert = item.headline_first_template.type === 'keyword_insert' || item.headline_second_template.type === 'keyword_insert' || item.headline_third_template.type === 'keyword_insert';
                    return (!this.state.keyword.skip_insert_keyword || (this.state.keyword.skip_insert_keyword && !keyword_insert)) && item.campaign_id === this.state.campaign.id
                }):[]
            }catch (e) {
                return []
            }
        },
        focus: () => {
            try{
                //console.log(this.state.campaign);
                return this.state.campaign.budget.google.focus.value === 'manual_cpc' || this.state.campaign.budget.google.focus.value === 'enhanced_manual_cpc';
            } catch (e) {
                return false;
            }
        },
        phoneNumber: () => {
            try{
                if(this.state.search.phone_numbers.filter((item) => {
                    return item.campaign_id === this.state.campaign.id
                }).length > 0){
                    return this.state.search.phone_numbers.filter((item) => {
                        return item.campaign_id === this.state.campaign.id
                    })[0].number
                }
            }catch (e) {
                return false
            }
        },
        baseUrl: (url) => {
            try{
                url = url.replace('https://', "");
                url = url.replace('http://', "");
                url = url.split( '/' )[0];
                url = 'https://' + url;
                return url;
            }catch (e) {
                return url
            }
        }
    };

    render() {
        return (
            <div className="w-full">
                <div className="flex flex-row overflow-scroll space-x-2">
                    {
                        false &&
                        this.renders.all().map((item, index) => {
                            return(
                                <div className="p-4 rounded-sm bg-google-100">
                                    <AdvertisingSearchTemplatePreview
                                        item={item?item:{}}
                                        url={this.state.keyword.url}
                                        path1={this.state.keyword.path1}
                                        path2={this.state.keyword.path2}
                                        keyword={this.state.keyword}
                                        site_links={Array.isArray(this.state.search.site_links)?this.state.search.site_links.filter((item) => {
                                            return item.campaign_id === this.state.campaign.id
                                        }):[]}
                                        phone={this.renders.phoneNumber()}
                                        creatives={this.state.creatives}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
                {
                    !this.state.multiple &&
                    <div className="flex flex-1 mb-4">
                        <Input
                            title={'Keyword'}
                            value={this.state.keyword.name}
                            disabled={false}
                            size={'full'}
                            type={"text"}
                            white={true}
                            onChange={(value) => {
                                this.state.keyword.name = value.target.value;
                                let new_value = this.state.keyword.suffix.length + this.state.keyword.prefix.length + value.target.value.length;
                                if(new_value > 30){
                                    this.state.keyword.skip_insert_keyword = true;
                                }
                                this.setState({
                                    keyword: this.state.keyword
                                }, () => {
                                    this.props.onChange(this.state.keyword);
                                })
                            }}
                        />
                    </div>
                }
                <div className="flex flex-row">
                    <div className="flex flex-row flex-1">
                        <div className="flex flex-row mr-4">
                            <div className="flex flex-col mr-2">
                                <div className="font-bold text-xs mb-1">
                                    Exact
                                </div>
                                <div style={{height: '55px'}} className="flex border rounded px-4 justify-center items-center">
                                    <Switch
                                        onColor="#1AD5BD"
                                        checked={this.state.keyword.exact}
                                        onChange={(checked) => {
                                            this.state.keyword.exact = checked;
                                            this.setState({
                                                keyword: this.state.keyword
                                            }, () => {
                                                this.props.onChange(this.state.keyword);
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="font-bold text-xs mb-1">
                                    Phrase
                                </div>
                                <div style={{height: '55px'}} className="flex border rounded px-4 justify-center items-center">
                                    <Switch
                                        onColor="#1AD5BD"
                                        checked={this.state.keyword.phrase}
                                        onChange={(checked) => {
                                            this.state.keyword.phrase = checked;
                                            this.setState({
                                                keyword: this.state.keyword
                                            }, () => {
                                                this.props.onChange(this.state.keyword);
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col ml-2">
                                <div className="font-bold text-xs mb-1">
                                    Broad
                                </div>
                                <div style={{height: '55px'}} className="flex border rounded px-4 justify-center items-center">
                                    <Switch
                                        onColor="#1AD5BD"
                                        checked={this.state.keyword.broad}
                                        onChange={(checked) => {
                                            this.state.keyword.broad = checked;
                                            this.setState({
                                                keyword: this.state.keyword
                                            }, () => {
                                                this.props.onChange(this.state.keyword);
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row flex-1">
                            {
                                !this.state.keyword.skip_insert_keyword &&
                                <div className="flex flex-1 mr-2">
                                    <Input
                                        title={'Prefix'}
                                        sign={true}
                                        signTitle={this.renders.characters() +' / 29'}
                                        maxLength={29}
                                        value={this.state.keyword.prefix}
                                        disabled={this.renders.insert().length < 1}
                                        placeholder={(this.renders.insert().length < 1)?"No keyword":""}
                                        size={'full'}
                                        type={"text"}
                                        white={true}
                                        onChange={(value) => {
                                            let new_value = this.state.keyword.name.length + this.state.keyword.suffix.length + value.target.value.length;
                                            if(new_value <= 29){
                                                this.state.keyword.prefix = value.target.value;
                                                this.setState({
                                                    keyword: this.state.keyword
                                                }, () => {
                                                    this.props.onChange(this.state.keyword);
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            }
                            {
                                !this.state.keyword.skip_insert_keyword &&
                                <div className="flex flex-1 ml-2">
                                    <Input
                                        title={'Sufix'}
                                        sign={true}
                                        signTitle={this.renders.characters() +' / 29'}
                                        maxLength={29}
                                        value={this.state.keyword.suffix}
                                        placeholder={(this.renders.insert().length < 1)?"No keyword":""}
                                        disabled={this.renders.insert().length < 1}
                                        size={'full'}
                                        type={"text"}
                                        white={true}
                                        onChange={(value) => {
                                            let new_value = this.state.keyword.name.length + this.state.keyword.prefix.length + value.target.value.length;
                                            if(new_value <= 29){
                                                this.state.keyword.suffix = value.target.value;
                                                this.setState({
                                                    keyword: this.state.keyword
                                                }, () => {
                                                    this.props.onChange(this.state.keyword);
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="mt-4 flex flex-row">
                    <div className="flex flex-1 mr-2">
                        <Input
                            title={'Website'}
                            value={this.state.keyword.url}
                            disabled={false}
                            size={'full'}
                            type={"text"}
                            tight={true}
                            white={true}
                            onChange={(value) => {
                                this.state.keyword.url = value.target.value;
                                this.setState({
                                    keyword: this.state.keyword
                                }, () => {
                                    this.props.onChange(this.state.keyword);
                                })
                            }}
                        />
                    </div>
                    <div className="flex flex-1 flex-row ml-2">
                        <div className="flex flex-2 mr-2">
                            <Input
                                title={'Display website'}
                                value={this.renders.baseUrl(this.state.keyword.url)}
                                size={'full'}
                                type={"text"}
                                tight={true}
                                disabled={true}
                                white={true}
                                onChange={(value) => {

                                }}
                            />
                        </div>
                        <div className="flex flex-1 mr-2">
                            <Input
                                title={'Path 1'}
                                value={this.state.keyword.path1}
                                size={'full'}
                                maxLength={15}
                                type={"text"}
                                tight={true}
                                white={true}
                                onChange={(value) => {
                                    this.state.keyword.path1 = value.target.value;
                                    this.setState({
                                        keyword: this.state.keyword
                                    }, () => {
                                        this.props.onChange(this.state.keyword);
                                    })
                                }}
                            />
                        </div>
                        <div className="flex flex-1">
                            <Input
                                title={'Path 2'}
                                value={this.state.keyword.path2}
                                size={'full'}
                                maxLength={15}
                                type={"text"}
                                tight={true}
                                white={true}
                                onChange={(value) => {
                                    this.state.keyword.path2 = value.target.value;
                                    this.setState({
                                        keyword: this.state.keyword
                                    }, () => {
                                        this.props.onChange(this.state.keyword);
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-4 flex flex-row">
                    <div className="flex flex-1 mr-2">
                        <Input
                            title={'Tracking template'}
                            value={this.state.keyword.tracking_url_template}
                            disabled={false}
                            size={'full'}
                            type={"text"}
                            tight={true}
                            white={true}
                            onChange={(value) => {
                                this.state.keyword.tracking_url_template = value.target.value;
                                this.setState({
                                    keyword: this.state.keyword
                                }, () => {
                                    this.props.onChange(this.state.keyword);
                                })
                            }}
                        />
                    </div>
                    {
                        this.renders.focus() &&
                        <div className="flex flex-1 ml-2 mr-2">
                            <Input
                                title={'Max CPC'}
                                value={this.state.keyword.cpc}
                                disabled={false}
                                size={'full'}
                                type={"number"}
                                tight={true}
                                white={true}
                                onChange={(value) => {
                                    this.state.keyword.cpc = value.target.value;
                                    this.setState({
                                        keyword: this.state.keyword
                                    }, () => {
                                        this.props.onChange(this.state.keyword);
                                    })
                                }}
                            />
                        </div>
                    }
                    {
                        this.renders.insert().length > 0 && !this.state.multiple &&
                        <div className="flex flex-col flex-1 ml-2">
                            <div style={{backgroundColor: '#ffffff', width: '100%', marginTop: '22px', height: '55px'}} className="px-4 border rounded">
                                <div className="Variations-ItemLine">
                                    <div className="text-xs font-bold">
                                        Skip keyword insert
                                    </div>
                                    <div className="flex flex-1"></div>
                                    <Switch
                                        onColor="#1AD5BD"
                                        checked={this.state.keyword.skip_insert_keyword}
                                        disabled={this.renders.characters() > 30}
                                        onChange={(checked) => {
                                            this.state.keyword.skip_insert_keyword = checked;
                                            this.setState({
                                                keyword: this.state.keyword
                                            }, () => {
                                                this.props.onChange(this.state.keyword);
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default AdvertisingKeywordsPositiveItem;
