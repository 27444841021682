import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import {dataRegister} from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BeatLoader} from "react-spinners";

class AdvertisingSearchTemplatePreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            url: "",
            selected_keyword: {},
            phone: {},
            creatives: {},
            campaign: {},
            site_links: [],
            path1: '',
            path2: ''
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item,
            site_links: nextProps.site_links,
            phone: nextProps.phone,
            campaign: nextProps.campaign,
            url: nextProps.url,
            path1: nextProps.path1,
            path2: nextProps.path2,
            selected_keyword: nextProps.keyword,
            creatives: nextProps.creatives
        });
    }

    componentDidMount() {
        this.setState({
            item: this.props.item,
            site_links: this.props.site_links,
            phone: this.props.phone,
            campaign: this.props.campaign,
            url: this.props.url,
            path1: this.props.path1,
            path2: this.props.path2,
            selected_keyword: this.props.keyword,
            creatives: this.props.creatives,
        });
    }

    functions = {};

    init = {};

    renders = {
        site: () => {
            try{

                let url = this.state.url;
                let path1 =this.state.path1;
                let path2 = this.state.path2;

                if(this.state.item.overwrite){
                    url = this.state.item.overwrite_url;
                    if((this.state.item.overwrite_url_path1 && this.state.item.overwrite_url_path1 !== "")){
                        path1 = this.state.item.overwrite_url_path1;
                    }else{
                        path1 = "";
                    }
                    if((this.state.item.overwrite_url_path2 && this.state.item.overwrite_url_path2 !== "")){
                        path2 = this.state.item.overwrite_url_path2;
                    }else{
                        path2 = "";
                    }
                }

                if((path1 && path1 !== "") || (path2 && path2 !== "")){
                    url = url.replace('https://', "");
                    url = url.replace('http://', "");
                    url = url.split( '/' )[0];
                    url = 'https://' + url;
                    if(path1 && path1 !== ""){
                        url = url + '/' + path1;
                    }
                    if(path2 && path2 !== ""){
                        url = url + '/' + path2;
                    }
                }
                return url.toLowerCase();
            }catch (e) {
                return "wwww.example.com"
            }
        },
        variations: () => {
            try {

                let string = "";

                if(this.state.item.headline_first_template){
                    if(this.state.item.headline_first_template.type === 'no_option'){
                        string = string + "Headline first | ";
                    }else if(this.state.item.headline_first_template.type === 'headline'){
                        if(this.state.creatives.headline.trim() !== ""){
                            string = string + this.state.creatives.headline.trim() + ' | ';
                        }else{
                            string = string + "Headline first | ";
                        }
                    }else if(this.state.item.headline_first_template.type === 'headline_second'){
                        if(this.state.creatives.headline_second.trim() !== ""){
                            string = string + this.state.creatives.headline_second.trim() + ' | ';
                        }else{
                            string = string + "Headline first | ";
                        }
                    }else if(this.state.item.headline_first_template.type === 'headline_third'){
                        if(this.state.creatives.headline_third.trim() !== ""){
                            string = string + this.state.creatives.headline_third.trim() + ' | ';
                        }else{
                            string = string + "Headline first | ";
                        }
                    }else if(this.state.item.headline_first_template.type === 'keyword_insert'){
                        if(!this.state.selected_keyword){
                            string = string + "<b>&#123;keyword&#125;</b>" + " | ";
                        }else{
                            string = string + (this.state.selected_keyword.prefix !== ""?(this.state.selected_keyword.prefix+" "):"") + this.state.selected_keyword.name + (this.state.selected_keyword.suffix !== ""?(" "+this.state.selected_keyword.suffix):"") + " | ";
                        }
                    }else if(this.state.item.headline_first_template.type === 'location_insert'){
                        if(this.state.item.headline_first_template.location){
                            string = string + this.state.item.headline_first_template.location + " | ";
                        }else{
                            string = string + "<b>&#123;location&#125;</b>" + " | ";
                        }
                    }else if(this.state.item.headline_first_template.type === 'custom'){
                        if(this.state.item.custom){
                            string = string + this.state.item.custom.headline + ' | ';
                        }else{
                            string = string + "Headline first | ";
                        }
                    }else{
                        string = string + "Headline first | ";
                    }
                }else{
                    string = string + "Headline first | ";
                }

                if(this.state.item.headline_second_template){
                    if(this.state.item.headline_second_template.type === 'no_option'){
                        string = string + "Headline second";
                    }else if(this.state.item.headline_second_template.type === 'headline'){
                        if(this.state.creatives.headline.trim() !== ""){
                            string = string + this.state.creatives.headline.trim();
                        }else{
                            string = string + "Headline second";
                        }
                    }else if(this.state.item.headline_second_template.type === 'headline_second'){
                        if(this.state.creatives.headline_second.trim() !== ""){
                            string = string + this.state.creatives.headline_second.trim();
                        }else{
                            string = string + "Headline second";
                        }
                    }else if(this.state.item.headline_second_template.type === 'headline_third'){
                        if(this.state.creatives.headline_third.trim() !== ""){
                            string = string + this.state.creatives.headline_third.trim();
                        }else{
                            string = string + "Headline second";
                        }
                    }else if(this.state.item.headline_second_template.type === 'keyword_insert'){
                        if(!this.state.selected_keyword){
                            string = string + "<b>&#123;keyword&#125;</b>";
                        }else{
                            string = string + (this.state.selected_keyword.prefix !== ""?(this.state.selected_keyword.prefix+" "):"") + this.state.selected_keyword.name + (this.state.selected_keyword.suffix !== ""?(" "+this.state.selected_keyword.suffix):"");
                        }
                    }else if(this.state.item.headline_second_template.type === 'location_insert'){
                        if(this.state.item.headline_first_template.location){
                            string = string + this.state.item.headline_first_template.location;
                        }else{
                            string = string + "<b>&#123;location&#125;</b>";
                        }
                    }else if(this.state.item.headline_second_template.type === 'custom'){
                        if(this.state.item.custom){
                            string = string + this.state.item.custom.headline_second;
                        }else{
                            string = string + "Headline second";
                        }
                    }else{
                        string = string + "Headline second";
                    }
                }else{
                    string = string + "Headline second";
                }

                if(this.state.item.headline_third_template){
                    if(this.state.item.headline_third_template.type === 'no_option'){
                        string = string + "";
                    }else if(this.state.item.headline_third_template.type === 'headline'){
                        if(this.state.creatives.headline.trim() !== ""){
                            string = string +  " | " + this.state.creatives.headline.trim();
                        }else{
                            string = string + "";
                        }
                    }else if(this.state.item.headline_third_template.type === 'headline_second'){
                        if(this.state.creatives.headline_second.trim() !== ""){
                            string = string +  " | " + this.state.creatives.headline_second.trim();
                        }else{
                            string = string + "";
                        }
                    }else if(this.state.item.headline_third_template.type === 'headline_third'){
                        if(this.state.creatives.headline_third.trim() !== ""){
                            string = string +  " | " + this.state.creatives.headline_third.trim();
                        }else{
                            string = string + "";
                        }
                    }else if(this.state.item.headline_third_template.type === 'keyword_insert'){
                        if(!this.state.selected_keyword){
                            string = string +  " | " + "<b>&#123;keyword&#125;</b>";
                        }else{
                            string = string +  " | " + (this.state.selected_keyword.prefix !== ""?(this.state.selected_keyword.prefix+" "):"") + this.state.selected_keyword.name + (this.state.selected_keyword.suffix !== ""?(" "+this.state.selected_keyword.suffix):"");
                        }
                    }else if(this.state.item.headline_third_template.type === 'location_insert'){
                        if(this.state.item.headline_first_template.location){
                            string = string +  " | "  + this.state.item.headline_first_template.location;
                        }else{
                            string = string +  " | " + "<b>&#123;location&#125;</b>";
                        }
                    }else if(this.state.item.headline_third_template.type === 'custom'){
                        if(this.state.item.custom){
                            string = string +  " | " + this.state.item.custom.headline_third;
                        }else{
                            string = string +  " | " + "Headline third";
                        }
                    }else{
                        string = string +  " | " + "Headline third";
                    }
                }else{
                    string = string +  " | " + "Headline third";
                }
                return this.renders.removeEmoji(string);
            }catch (e) {
                console.log(e);
                return "";
            }
        },
        bodyOne: () => {
            try{
                if(this.state.item.body && this.state.item.body !== ''){
                    return this.renders.removeEmoji(this.state.item.body);
                }else{
                    return "Body text ...";
                }
            }catch (e) {
                return "";
            }
        },
        bodyTwo: () => {
            try{
                return this.state.item.body_second;
            }catch (e) {
                return "";
            }
        },
        markdown: (input) => {
            return { __html: input }
        },
        removeEmoji: (input) => {
            try{
                return input.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, '');
            }catch (e) {
                return input
            }
        },
        phone: () => {
            try{
                if(this.state.phone){
                    //console.log(this.state.phone);
                    return this.state.phone
                }else {
                    return null
                }
            }catch (e) {
                return null
            }
        }
    };

    render() {
        return (
            <div style={{minWidth: "450px"}} className="preview__block--wrapper flex-1">
                <div className={
                    cx("",
                        {
                            [""]: this.props.noContainer,
                        })
                }>
                    <div style={{maxWidth: "650px"}} className="">
                        <div className="flex flex-row items-center text-xs text-gray-800 mb-3">
                            <div className="font-semibold">
                                Ad
                            </div>
                            <div className="mx-1">&bull;</div>
                            <div className="">
                                {this.renders.site()}
                            </div>
                            {
                                typeof this.renders.phone() === "string" &&
                                <div className="ml-3 font-bold">
                                    {this.renders.phone()}
                                </div>
                            }
                        </div>
                        <div dangerouslySetInnerHTML={this.renders.markdown(this.renders.variations())} className="text-google-500 font-semibold"/>
                        <div className="text-gray-800 text-sm">
                            {this.renders.bodyOne()}{" "}{this.renders.bodyTwo()}
                        </div>
                        {
                            this.state.item && !this.state.item.no_sitelinks &&
                            Array.isArray(this.state.site_links) &&
                            <div className="flex flex-1 flex-row flex-wrap px-4 mt-2">
                                {
                                    this.state.site_links.map((item) => {
                                        return(
                                            <div className="w-3/6 pr-2">
                                                <div className="text-google-500 font-semibold mt-2">
                                                    {item.name}
                                                </div>
                                                {
                                                    item.description !== "" &&
                                                    <div className="text-xs">
                                                        {item.description}
                                                    </div>
                                                }
                                                {
                                                    item.description2 !== "" &&
                                                    <div className="text-xs">
                                                        {item.description2}
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingSearchTemplatePreview;
