import React, { Component, Fragment } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import AdvertisingCreativesCarouselSlides from "../advertising-creatives-carouselslides";
import { eventRegister } from "../../services/eventRegister";
import { campaignRegister } from "../../services/campaignRegister";
import { toArray } from "react-emoji-render";
import AdvertisingCreativesAds from "../advertising-creatives-ads";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import AdvertisingCreativesSearch from "../advertising-creatives-search";
import AdvertisingCreativesSearchList from "../advertising-creatives-search-list";
import AdvertisingCreativesSearchAds from "../advertising-creatives-search-ads";
import AdvertisingCreativesSitelinks from "../advertising-creatives-sitelinks";
import Accordion from "../accordion";
import { calls } from "./calls";
import AdvertisingCreativesProducts from "../advertising-creatives-products";
import ReactTooltip from "react-tooltip";
import AdvertisingCreativesCall from "../advertising-creatives-call";
import TargetingLanguages from "../targeting/languages";
import TargetingPlacement from "../targeting/placement";
import TargetingGender from "../targeting/gender";
import TargetingAge from "../targeting/age";
import TargetingWeekdays from "../targeting/weekdays";
import TargetingLocation from "../targeting/location";
import AdvertisingSearchTemplatePreview from "../advertising-search-template-preview";
import Datepicker from "../datepicker";
import { SlideDown } from "react-slidedown";
import AdvertisingSearchTemplate from "../advertising-search-template";
import AdvertisingKeywordsPositiveAdd from "../advertising-keywords-positive-add";
import Keywords from "../../modules/keywords";
import AdvertisingKeywordsNegative from "../advertising-keywords-negative";
import AdvertisingKeywordsNegativeAdd from "../advertising-keywords-negative-add";
import AdvertisingKeywordsNegativeAsset from "../advertising-keywords-negative-asset";
import AdvertisingKeywordsNegativeList from "../advertising-keywords-negative-list";

import AdvertisingKeywordsPositiveItem from "../../components/advertising-keywords-positive-item";
import CSVReader from "react-csv-reader";
import AdvertisingKeywordsIdeas from "../advertising-keywords-ideas";
import AdvertisingKeywordsPositiveList from "../advertising-keywords-positive-list";
import Modal from "../../modules/modal";
import IconGoogleShopping from "../icons/google-shopping";
import AdvertisingPreviewShoppingAll from '../advertising-preview-shopping-all';

var moment = require('moment');

class AdvertisingShopping extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: true,
            settings: {},
            client: {},
            channels: {},
            creatives: {},
            search: {},
            shopping: {},
            conversion: {},
            site: {},
            error: null,
            errors: [],
            languages: [],
            locations: [],
            products: [],
            template: {},
            search_ideas: "",
            search_selected: "",
            campaign: {},
            keyword_tab: { id: 1, name: 'Selected keywords' },
            order: "desc",
            sort: "index",
            sort_ideas: "monthly_searches",
            order_ideas: 'desc',
            page: 1,
            loaded: false,
            next: false,
            opensearchoverview: false,
            show_templates: true,
            searchsublinks: [
                {
                    id: 1,
                    name: 'Products'
                }
            ],
            activesearchsub: 1,
            newTemp: true,
            editKeyword: null,
            open_locations: true
        };
        this.init.data = this.init.data.bind(this);
        this.child = React.createRef();
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.step === 'shopping' && !this.state.loaded) {
            this.init.first();
        }
    }

    componentWillMount() {
        eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        this.init.data();
    }

    init = {
        first: () => {
            let information = campaignRegister.get();
            this.setState({
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                conversion: information ? (information.conversion ? (information.conversion.value ? information.conversion.value : {}) : {}) : {},
                details: information ? (information.details ? (information.details.value ? information.details.value : {}) : {}) : {},
                shopping: information ? (information.shopping ? (information.shopping.value ? information.shopping.value : {}) : {}) : {},
                budget: information ? (information.budget ? (information.budget.value ? information.budget.value : {}) : {}) : {},
                languages: information ? (information.languages ? (information.languages.value ? information.languages.value : []) : []) : [],
                locations: information ? (information.locations ? (information.locations.value ? information.locations.value : []) : []) : [],
                loaded: true
            }, () => {

                this.functions.products();

                if (this.state.shopping && !Array.isArray(this.state.shopping.campaigns)) {
                    this.state.shopping.campaigns = [];
                    let campaign = {
                        id: Math.floor((Math.random() * 9999999999) + 1),
                        languages: [],
                        shared_budget: false,
                        name: this.state.details.name,
                        start_date: this.state.details.startDate,
                        end_date: this.state.details.endDate,
                        no_end_date: this.state.details.noEndDate,
                        targeting: {
                            weekdays: {
                                monday: true,
                                tuesday: true,
                                wednesday: true,
                                thursday: true,
                                friday: true,
                                saturday: true,
                                sunday: true,
                                all: true
                            }
                        },
                        budget: {}
                    };

                    for (let channel in this.state.budget) {
                        if (channel === "google_shopping" && this.state.channels[channel]) {
                            campaign.budget[channel] = {};
                            if (this.state.budget[channel].daily > 0) {
                                campaign.budget[channel].daily_budget = this.state.budget[channel].daily;
                            }
                            if (this.state.budget[channel].total > 0) {
                                campaign.budget[channel].total_budget = this.state.budget[channel].total;
                            }
                            if (this.state.details.startDate) {
                                campaign.budget[channel].start_date = this.state.details.startDate;
                            }
                            if (this.state.details.endDate) {
                                campaign.budget[channel].end_date = this.state.details.endDate;
                            }
                            if (this.state.details.noEndDate) {
                                campaign.budget[channel].no_end_date = this.state.details.noEndDate;
                            }
                            if (this.state.details.focus && this.state.details.focus.value) {
                                if (this.state.details.focus.value === 'clicks' || this.state.details.focus.value === 'impressions') {
                                    campaign.budget[channel].focus = { name: "Maximize click", value: 'clicks' };
                                }
                                if (this.state.details.focus.value === 'conversion') {
                                    campaign.budget[channel].focus = { name: "Conversion", value: 'conversion' };
                                }
                            }
                        }
                    }

                    //console.log(campaign);

                    this.state.shopping.campaigns.push(campaign);

                    this.setState({
                        shopping: this.state.shopping,
                        campaign: campaign
                    }, () => {
                        this.functions.save('shopping');
                    })
                } else {

                    this.state.shopping.campaigns = this.state.shopping.campaigns.map((item) => {
                        if (!item.budget) {
                            item.budget = {};
                            for (let channel in this.state.budget) {
                                if (!item.budget && channel === "google_shopping" && this.state.channels[channel]) {
                                    item.budget[channel] = {};
                                    if (this.state.budget[channel].daily > 0) {
                                        item.budget[channel].daily_budget = this.state.budget[channel].daily;
                                    }
                                    if (this.state.budget[channel].total > 0) {
                                        item.budget[channel].total_budget = this.state.budget[channel].total;
                                    }
                                    if (this.state.details.startDate) {
                                        item.budget[channel].start_date = this.state.details.startDate;
                                    }
                                    if (this.state.details.endDate) {
                                        item.budget[channel].end_date = this.state.details.endDate;
                                    }
                                    if (this.state.details.noEndDate) {
                                        item.budget[channel].no_end_date = this.state.details.noEndDate;
                                    }
                                    if (this.state.details.focus && this.state.details.focus.value) {
                                        if (this.state.details.focus.value === 'clicks' || this.state.details.focus.value === 'impressions') {
                                            item.budget[channel].focus = { name: "Maximize click", value: 'clicks' };
                                        }
                                        if (this.state.details.focus.value === 'conversion') {
                                            item.budget[channel].focus = { name: "Conversion", value: 'conversion' };
                                        }
                                    }
                                }
                            }
                        } else {
                            for (let channel in item.budget) {
                                if (!this.state.channels[channel]) {
                                    delete item.budget[channel];
                                }
                            }
                        }
                        return item;
                    });

                    this.setState({
                        shopping: this.state.shopping
                    }, () => {
                        let campaign = this.state.shopping.campaigns[0];
                        this.setState({
                            campaign: campaign
                        });
                    })
                }
            })
        },
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                channels: information ? (information.channels ? (information.channels.value ? information.channels.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                keywords: information ? (information.keywords ? (information.keywords.value ? information.keywords.value : {}) : {}) : {},
                languages: information ? (information.languages ? (information.languages.value ? information.languages.value : []) : []) : [],
                locations: information ? (information.locations ? (information.locations.value ? information.locations.value : []) : []) : [],
                conversion: information ? (information.conversion ? (information.conversion.value ? information.conversion.value : {}) : {}) : {},
                //search: information ? (information.search ? (information.search.value ? information.search.value : {}) : {}) : {},
            })
        }
    };

    functions = {
        products: () => {
            //console.log(this.state.shopping);
            if (!Array.isArray(this.state.shopping.products) || (Array.isArray(this.state.shopping.products) && this.state.shopping.products.length < 1)) {
                this.setState({
                    loading_products: true
                }, () => {
                    calls.getProducts({ client: this.state.client.id }).then((response) => {
                        this.state.shopping.products = response.data;
                        this.setState({
                            shopping: this.state.shopping,
                            loading_products: false
                        }, () => {
                            this.functions.save('shopping');
                        });
                    }, (error) => {
                        this.setState({
                            loading_products: false
                        });
                    });
                });
            }
        },
        next: () => {
            if (this.renders.errors().length > 0) {
                this.setState({
                    error: true,
                    next: true
                });
            } else {
                this.props.onNext();
            }
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            try {
                this.props.onChange()
            } catch (e) {
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        },
        new: () => {
            let new_item = JSON.parse(JSON.stringify(this.state.campaign));
            new_item.id = Math.floor((Math.random() * 9999999999) + 1);
            new_item.name = this.state.campaign.name;
            new_item.website = this.state.site.url;
            new_item.display_website = this.state.site.url;
            this.state.shopping.campaigns.push(new_item);
            this.setState({
                campaign: new_item,
                search: this.state.shopping
            }, () => {
                this.functions.save('shopping');
            })
        },
        clone: () => {
            let new_item = JSON.parse(JSON.stringify(this.state.campaign));
            new_item.id = Math.floor((Math.random() * 9999999999) + 1);
            new_item.name = this.state.campaign.name + ' clone ';
            let templates = [];
            let keywords = [];
            let negatives = [];
            let site_links = [];
            let phone_numbers = [];
            this.state.shopping.campaigns.push(new_item);
            this.setState({
                campaign: new_item,
                search: this.state.shopping
            }, () => {
                this.functions.save('shopping');
            })
        },
        remove: (campaign, index) => {

            this.state.shopping.campaigns = this.state.shopping.campaigns.filter((item) => {
                return item.id !== campaign.id
            });

            this.setState({
                search: this.state.shopping
            }, () => {
                if (this.state.shopping.campaigns.length > 1 && index > 0) {
                    this.state.campaign = this.state.shopping.campaigns[index - 1];
                } else {
                    this.state.campaign = this.state.shopping.campaigns[0];
                }
                this.setState({
                    campaign: this.state.campaign
                }, () => {
                    this.functions.save('shopping');
                })
            })
        }
    };

    validators = {};

    renders = {
        subtitle: () => {
            try {
                let string = [];
                return string.join(', ');
            } catch (e) {
                return ""
            }
        },
        characters: (item) => {
            try {
                return item.toString().length
            } catch (e) {
                return 0
            }
        },
        property: (type) => {
            try {
                return this.state.creatives.languages[this.state.creatives.selected_language][type];
            } catch (e) {
                if (type !== 'site_links' && type !== 'extra_headlines') {
                    return ""
                } else {
                    return []
                }
            }
        },
        errors: () => {
            let array = [];
            if (Array.isArray(this.state.shopping.campaigns)) {
                this.state.shopping.campaigns.map((item) => {

                    if (!item.name || item.name === "") {
                        array.push({
                            title: 'Campaign title is missing',
                            campaign: item.id,
                            type: 'title',
                            section: 'search',
                            tab: "campaign"
                        });
                    }
                    if (!item.shopping_campaigntype) {
                        array.push({
                            title: 'Shopping campaign type is missing',
                            campaign: item.id,
                            type: 'title',
                            section: 'search',
                            tab: "campaign"
                        });
                    }
                    if (!item.shopping_country) {
                        array.push({
                            title: 'Sales country is missing',
                            campaign: item.id,
                            type: 'title',
                            section: 'search',
                            tab: "campaign"
                        });
                    }
                    if (item.budget && typeof item.budget === "object") {
                        for (let key in item.budget) {
                            if (!item.budget[key].disabled && key === "google" && (item.budget[key].daily_budget < 1 || !item.budget[key].daily_budget)) {
                                array.push({
                                    title: 'Daily budget is to low',
                                    campaign: item.id,
                                    channel: key,
                                    type: 'daily_budget',
                                    section: 'search'
                                });
                            }
                        }
                    }
                    if (item.budget && typeof item.budget === "object") {
                        let all_disabled = true;
                        try {
                            for (let key in item.budget) {
                                if (!item.budget[key].disabled && key === "google_shopping") {
                                    all_disabled = false;
                                }
                            }
                        } catch (e) {
                        }
                        if (all_disabled) {
                            array.push({
                                title: 'All channels are disabled',
                                campaign: item.id,
                                type: 'channels',
                                section: 'search'
                            });
                        }
                    }

                });
            }
            return array;
        },
        markdown: (input) => {
            return { __html: input }
        },
        invalidCharacters: (string) => {
            try {
                var pattern = /[!()/|*@"'_]/;
                return pattern.test(string);
            } catch (e) {
                return true
            }
        },
        date: (date) => {
            try {
                if (this.state.time) {
                    return moment(date).format('DD MMMM YYYY - LT');
                } else {
                    return moment(date).format('DD MMM YYYY');
                }
            } catch (e) {
            }
        },
        round: (num) => {
            try {
                return Math.round((num + Number.EPSILON) * 100) / 100
            } catch (e) {
                return 0
            }
        },
        baseUrl: (url) => {
            try {
                url = url.replace('https://', "");
                url = url.replace('http://', "");
                url = url.split('/')[0];
                url = 'https://' + url;
                return url;
            } catch (e) {
                return url
            }
        }
    };

    render() {
        return (
            <div className="w-full">
                <div className="flex flex-row mb-3">
                    {
                        this.renders.errors().length < 1 &&
                        <div
                            className="check-sign rounded-full mr-2 md:mr-3 w-8 h-8 bg-green-500 flex items-center justify-center text-white">
                            <FeatherIcon className={"#ffffff"} size={16} icon={'check'} />
                        </div>
                    }
                    <div className="text-2xl font-bold">
                        Step {(this.state.channels.google_shopping && (this.state.channels.facebook || this.state.channels.instagram) ? "6" : "5")}:
                        Shopping campaigns
                    </div>
                </div>
                <div>
                    {
                        this.state.shopping && Array.isArray(this.state.shopping.campaigns) && this.state.shopping.campaigns.length > 0 &&
                        <div className="flex flex-1 flex-row justify-start px-4">
                            {
                                this.state.shopping.campaigns.map((item, index) => {
                                    return (
                                        <div onClick={() => {
                                            this.setState({
                                                campaign: item
                                            })
                                        }}
                                            className={cx("cursor-pointer overflow-hidden relative max-w-xs bg-white flex items-center hover:text-opacity-100 bg-opacity-50 text-black text-opacity-50 flex-1 items-middle rounded-t-md p-3 text-sm", {
                                                ["bg-opacity-100 text-purple-900 text-opacity-100"]: item.id === this.state.campaign.id,
                                                ["opaxity-25"]: item.id !== this.state.campaign.id,
                                                ["ml-1"]: index !== 0,
                                                ["px-0"]: this.state.shopping.campaigns.length > 10
                                            })}>
                                            {
                                                this.state.shopping.campaigns.length < 7 &&
                                                <div className="font-bold">
                                                    <div className={cx("InputContainerTitleIcons")}>
                                                        {
                                                            typeof this.state.campaign.budget === "object" &&
                                                            Object.keys(this.state.campaign.budget).filter((key) => {
                                                                return this.state.channels[key] && key === "google" && !this.state.campaign.budget[key].disabled
                                                            }).map((item) => {
                                                                if (item === 'google') {
                                                                    return (
                                                                        <div
                                                                            className={'w-5 h-5 bg-google-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                                            <img className="w-2"
                                                                                src={require('../../assets/images/google_icon.png')} />
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className={cx("pl-3 pr-3 truncate font-bold", {
                                                ["pl-1"]: this.state.shopping.campaigns.length > 10,
                                                ["text-red-400"]: this.state.shopping.campaigns.length > 6 &&
                                                    this.renders.errors().filter((error) => {
                                                        return error.campaign === item.id
                                                    }).length > 0
                                            })}>
                                                {item.name ? item.name : "..."}
                                            </div>
                                            <div className="flex flex-1 justify-end items-center flex-row">
                                                {
                                                    this.renders.errors().filter((error) => {
                                                        return error.campaign === item.id
                                                    }).length > 0 &&
                                                    <div
                                                        className={(item.id === this.state.campaign.id ? "bg-gray-100" : "bg-white") + " close mr-1 w-6 h-6  mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                        <FeatherIcon className="stroke-current" size={15}
                                                            icon="alert-circle" />
                                                    </div>
                                                }
                                                {this.state.shopping.campaigns.length > 1 &&
                                                    <div
                                                        className={(item.id === this.state.campaign.id ? "bg-gray-100" : "bg-white") + " close w-6 h-6 flex items-center justify-center text-lg rounded-md hover:bg-red-100 hover:text-red-500"}
                                                        onClick={() => {
                                                            this.functions.remove(item, index);
                                                        }}>&times;
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="flex flex-1 justify-start pl-1">
                                <div onClick={() => {
                                    this.functions.clone()
                                }} data-tip={"Clone campaign"}
                                    className="bg-white text-white flex justify-center items-center rounded-t-md cursor-pointer pl-3 pr-3"
                                    style={{ height: '100%' }}>
                                    <div
                                        className={"close w-6 h-6 flex items-center justify-center text-lg rounded-md transition-all duration-200 bg-purple-500 text-white"}>
                                        <FeatherIcon className="stroke-current" size={11}
                                            icon="copy" />
                                        <ReactTooltip />
                                    </div>
                                </div>
                                <div onClick={() => {
                                    this.functions.new()
                                }} data-tip={"New campaign"}
                                    className="ml-1 bg-white text-white flex justify-center items-center rounded-t-md cursor-pointer pl-3 pr-3"
                                    style={{ height: '100%' }}>
                                    <div
                                        className={"close w-6 h-6 flex items-center justify-center text-lg rounded-md transition-all duration-200 bg-purple-500 text-white"}>+
                                    </div>
                                    <ReactTooltip />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="px-4 pb-4 bg-white rounded-md">
                        <div className="pt-4 pb-4">
                            {
                                <div className="overview bg-gray-100 py-4 px-4 rounded-md">
                                    <div className="flex flex-row items-center">
                                        <div>
                                            <h3 className="mb-0 font-bold text-black relative">
                                                Campaign information
                                                {
                                                    this.renders.errors().filter((item) => {
                                                        return (item.tab === 'campaign' || item.type === 'title' || item.type === 'channels' || item.type === 'date') && item.campaign === this.state.campaign.id
                                                    }).length > 0 &&
                                                    <div style={{ right: "-37px" }}
                                                        className={"bg-white close top-0 mr-1 w-6 h-6  mr-1 flex items-center absolute justify-center text-lg rounded-md bg-white text-red-500"}>
                                                        <FeatherIcon className="stroke-current" size={15}
                                                            icon="alert-circle" />
                                                    </div>
                                                }
                                            </h3>
                                            {
                                                this.state.campaign && this.state.campaign.budget &&
                                                <p className="text-xxs text-black">
                                                    <span
                                                        className="font-bold">Channels: </span>{Object.keys(this.state.campaign.budget).filter((key) => {
                                                            return key === "google_shopping"
                                                        }).map((item) => {
                                                            if (item === "google_shopping") {
                                                                return "google shopping"
                                                            } else {
                                                                return item;
                                                            }
                                                        }).join(", ")}{" "}
                                                </p>
                                            }
                                        </div>
                                        {

                                            <div className="flex flex-1 justify-end">
                                                <button
                                                    className="h-10 w-10 flex items-center justify-center text-2xl rounded-md transition-all duration-200 bg-purple-500 text-white"
                                                    onClick={() => {
                                                        let channel = null;
                                                        for (let key in this.state.campaign.budget) {
                                                            if (key === "google_shopping") {
                                                                channel = key;
                                                                break;
                                                            }
                                                        }
                                                        this.setState({
                                                            opensearchoverview: !this.state.opensearchoverview,
                                                            activechannel: channel
                                                        });
                                                    }}>
                                                    <FeatherIcon className="stroke-current" size={16} icon={'edit'} />
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.opensearchoverview &&
                                <Modal
                                    title={"Edit campaign information"}
                                    subtitle={this.state.campaign.name}
                                    blueButton={true}
                                    blueButtonText={"Update campaign"}
                                    onBlueButtonClick={() => {
                                        this.setState({
                                            opensearchoverview: false
                                        })
                                    }}
                                    onClose={() => {
                                        this.setState({
                                            opensearchoverview: false
                                        })
                                    }}
                                >
                                    {
                                        <div className="">
                                            <>
                                                {
                                                    <div className="flex flex-1 flex-row">
                                                        <div className="flex flex-1">
                                                            <Input
                                                                title={"Name"}
                                                                sign={false}
                                                                white={false}
                                                                error={this.renders.errors().filter((item) => {
                                                                    return item.type === 'title' && item.campaign === this.state.campaign.id
                                                                }).length > 0}
                                                                value={this.state.campaign.name}
                                                                tight={true}
                                                                type={"text"}
                                                                size={'full'}
                                                                onChange={(value) => {
                                                                    this.state.campaign.name = value.target.value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        if (Array.isArray(this.state.shopping.campaigns)) {
                                                                            for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.shopping.campaigns[i].name = this.state.campaign.name;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                shopping: this.state.shopping
                                                                            }, () => {
                                                                                this.functions.save('shopping');
                                                                            })
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.renders.errors().filter((item) => {
                                                        return item.type === 'title' && item.campaign === this.state.campaign.id
                                                    }).length > 0 &&
                                                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                        <ul className="list-disc">
                                                            {
                                                                this.renders.errors().filter((item) => {
                                                                    return item.type === 'title' && item.campaign === this.state.campaign.id
                                                                }).map((item, index) => {
                                                                    return (
                                                                        <li className="text-xs text-red-500 font-bold "
                                                                            key={index}>
                                                                            {item.title}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                <div className="rounded-md bg-gray-100 p-4 pt-0 mt-4">
                                                    {
                                                        this.state.campaign.budget &&
                                                        <div className="mt-3">
                                                            <div className="navigation">
                                                                <nav role="navigation"
                                                                    aria-label="Social sub navigation">
                                                                    <ul className="flex flex-row items-end">
                                                                        {Object.keys(this.state.campaign.budget).filter((key) => {
                                                                            return this.state.channels[key] && key == "google_shopping"
                                                                        }).map((key, j) => {
                                                                            return (
                                                                                <li key={j}
                                                                                    className={"capitalize font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.activechannel == key ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            activechannel: key
                                                                                        });
                                                                                    }}>
                                                                                    {key === "google_shopping" ? "Google Shopping" : key}
                                                                                </li>
                                                                            )
                                                                        })
                                                                        }
                                                                        <li className="flex-grow border-b-2 border-gray-200"></li>
                                                                    </ul>
                                                                </nav>
                                                            </div>
                                                            <div className="bg-white mt-4 rounded-md p-4">
                                                                {
                                                                    this.state.campaign.id && this.state.activechannel &&
                                                                    <div className="">
                                                                        <div className="font-bold text-xs mb-1">
                                                                            Enable channel
                                                                        </div>
                                                                        <div style={{
                                                                            height: "55px",
                                                                            backgroundColor: "#fcfcfc"
                                                                        }}
                                                                            className="align-middle items-center flex flex-row flex-1 border rounded">
                                                                            <div
                                                                                className="flex flex-row text-xs font-bold pl-4 justify-center items-center">
                                                                                {
                                                                                    this.state.activechannel === "google_shopping" &&
                                                                                    <div style={{ marginRight: '3px' }}>
                                                                                        <IconGoogleShopping large={true}
                                                                                            noMargin={true} />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    this.state.activechannel === "google_shopping" &&
                                                                                    <div
                                                                                        className="font-bold text-xs ml-2">Google
                                                                                        Shopping</div>
                                                                                }
                                                                            </div>
                                                                            <div
                                                                                className="flex align-middle justify-end flex flex-1 items-center pr-4">
                                                                                <Switch
                                                                                    onColor="#1AD5BD"
                                                                                    checked={!this.state.campaign.budget[this.state.activechannel].disabled}
                                                                                    onChange={(checked) => {
                                                                                        this.state.campaign.budget[this.state.activechannel].disabled = !checked;
                                                                                        this.setState({
                                                                                            campaign: this.state.campaign
                                                                                        }, () => {
                                                                                            for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                                if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                                    this.state.shopping.campaigns[i].budget = this.state.campaign.budget;
                                                                                                }
                                                                                            }
                                                                                            this.setState({
                                                                                                shopping: this.state.shopping
                                                                                            }, () => {
                                                                                                this.functions.save('shopping');
                                                                                            })
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'channels' && item.campaign === this.state.campaign.id
                                                                    }).length > 0 &&
                                                                    <div className="">
                                                                        <div
                                                                            className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                                            <ul className="list-decimal">
                                                                                {
                                                                                    this.renders.errors().filter((item) => {
                                                                                        return item.type === 'channels' && item.campaign === this.state.campaign.id
                                                                                    }).map((item) => {
                                                                                        return (
                                                                                            <li className="text-xs text-red-500 font-bold ">
                                                                                                {item.title}
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    false &&
                                                                    this.state.campaign.id && this.state.activechannel && !this.state.campaign.budget[this.state.activechannel].disabled &&
                                                                    <div className="flex flex-1 pt-4">
                                                                        <Datepicker
                                                                            size={'full'}
                                                                            showTime={true}
                                                                            border={true}
                                                                            showNoEndDateSwitch={true}
                                                                            disableEndDate={this.state.campaign.budget[this.state.activechannel].no_end_date}
                                                                            error={this.renders.errors().filter((item) => {
                                                                                return item.type === 'date' && item.campaign === this.state.campaign.id
                                                                            }).length > 0}
                                                                            startDate={this.state.campaign.budget[this.state.activechannel].start_date}
                                                                            endDate={this.state.campaign.budget[this.state.activechannel].end_date}
                                                                            updateNoEndDate={(value) => {
                                                                                this.state.campaign.budget[this.state.activechannel].no_end_date = value;
                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                        if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.shopping.campaigns[i].budget = this.state.campaign.budget;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        shopping: this.state.shopping
                                                                                    }, () => {
                                                                                        this.functions.save('shopping');
                                                                                    })
                                                                                })
                                                                            }}
                                                                            onStartDate={(value) => {
                                                                                this.state.campaign.budget[this.state.activechannel].start_date = value;
                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                        if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.shopping.campaigns[i].budget = this.state.campaign.budget;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        shopping: this.state.shopping
                                                                                    }, () => {
                                                                                        this.functions.save('shopping');
                                                                                    })
                                                                                })
                                                                            }}
                                                                            onEndDate={(value) => {
                                                                                this.state.campaign.budget[this.state.activechannel].end_date = value;
                                                                                if (!this.state.campaign.budget[this.state.activechannel].start_date) {
                                                                                    this.state.campaign.budget[this.state.activechannel].start_date = new Date();
                                                                                }
                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                        if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.shopping.campaigns[i].budget = this.state.campaign.budget;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        shopping: this.state.shopping
                                                                                    }, () => {
                                                                                        this.functions.save('shopping');
                                                                                    })
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'date' && item.campaign === this.state.campaign.id
                                                                    }).length > 0 &&
                                                                    <div
                                                                        className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                                        <ul className="list-disc">
                                                                            {
                                                                                this.renders.errors().filter((item) => {
                                                                                    return item.type === 'date' && item.campaign === this.state.campaign.id
                                                                                }).map((item, index) => {
                                                                                    return (
                                                                                        <li className="text-xs text-red-500 font-bold "
                                                                                            key={index}>
                                                                                            {item.title}
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.campaign.id && this.state.activechannel && !this.state.campaign.budget[this.state.activechannel].disabled &&
                                                                    <div
                                                                        className="flex flex-1 flex-row pt-4 items-end">
                                                                        {
                                                                            false &&
                                                                            !this.state.campaign.budget[this.state.activechannel].no_end_date &&
                                                                            <div className="flex flex-1">
                                                                                <Input
                                                                                    title={"Total budget"}
                                                                                    sign={false}
                                                                                    white={false}
                                                                                    error={this.renders.errors().filter((item) => {
                                                                                        return this.state.activechannel === item.channel && item.type === 'daily_budget' && item.campaign === this.state.campaign.id
                                                                                    }).length > 0}
                                                                                    value={this.state.campaign.budget[this.state.activechannel].total_budget}
                                                                                    tight={true}
                                                                                    type={"number"}
                                                                                    size={'full'}
                                                                                    icons={false}
                                                                                    onChange={(value) => {

                                                                                        this.state.campaign.budget[this.state.activechannel].total_budget = value.target.value;

                                                                                        //CAMPAIGN DAYS
                                                                                        let dates = [];
                                                                                        if (typeof this.state.details === "object" && this.state.campaign.budget[this.state.activechannel].start_date && this.state.campaign.budget[this.state.activechannel].end_date) {
                                                                                            dates = [];
                                                                                            var currDate = moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day');
                                                                                            var lastDate = moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day');
                                                                                            dates.push(moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day').clone().toDate());
                                                                                            while (currDate.add(1, 'days').diff(lastDate) < 0) {
                                                                                                dates.push(currDate.clone().toDate());
                                                                                            }
                                                                                            dates.push(moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day').clone().toDate());
                                                                                        }

                                                                                        this.state.campaign.budget[this.state.activechannel].daily_budget = this.renders.round(this.state.campaign.budget[this.state.activechannel].total_budget / dates.length);

                                                                                        this.setState({
                                                                                            campaign: this.state.campaign
                                                                                        }, () => {
                                                                                            for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                                if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                                    this.state.shopping.campaigns[i].budget = this.state.campaign.budget;
                                                                                                }
                                                                                            }
                                                                                            this.setState({
                                                                                                shopping: this.state.shopping
                                                                                            }, () => {
                                                                                                this.functions.save('shopping');
                                                                                            })
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            !this.state.campaign.budget[this.state.activechannel].no_end_date &&
                                                                            <div className="ml-2 mr-2"></div>
                                                                        }
                                                                        <div className="flex flex-1">
                                                                            <Input
                                                                                title={"Daily budget"}
                                                                                sign={false}
                                                                                white={false}
                                                                                error={this.renders.errors().filter((item) => {
                                                                                    return this.state.activechannel === item.channel && item.type === 'daily_budget' && item.campaign === this.state.campaign.id
                                                                                }).length > 0}
                                                                                value={this.state.campaign.budget[this.state.activechannel].daily_budget}
                                                                                tight={true}
                                                                                type={"number"}
                                                                                size={'full'}
                                                                                icons={false}
                                                                                onChange={(value) => {

                                                                                    this.state.campaign.budget[this.state.activechannel].daily_budget = value.target.value;

                                                                                    if (this.state.campaign.budget[this.state.activechannel].focus.value === "target_roas" || this.state.campaign.budget[this.state.activechannel].focus.value === "conversion_roas") {
                                                                                        if (!this.state.campaign.budget[this.state.activechannel].target_sales || this.state.campaign.budget[this.state.activechannel].target_sales == 0) {
                                                                                            this.state.campaign.budget[this.state.activechannel].target_sales = this.state.campaign.budget[this.state.activechannel].daily_budget;
                                                                                        }
                                                                                        this.state.campaign.budget[this.state.activechannel].target_roas = (this.state.campaign.budget[this.state.activechannel].target_sales / this.state.campaign.budget[this.state.activechannel].daily_budget);
                                                                                        this.state.campaign.budget[this.state.activechannel].target_roas = this.state.campaign.budget[this.state.activechannel].target_roas.toFixed(1);
                                                                                    }


                                                                                    if (!this.state.campaign.budget[this.state.activechannel].no_end_date) {
                                                                                        let dates = [];
                                                                                        if (typeof this.state.details === "object" && this.state.campaign.budget[this.state.activechannel].start_date && this.state.campaign.budget[this.state.activechannel].end_date) {
                                                                                            dates = [];
                                                                                            var currDate = moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day');
                                                                                            var lastDate = moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day');
                                                                                            dates.push(moment(this.state.campaign.budget[this.state.activechannel].start_date).startOf('day').clone().toDate());
                                                                                            while (currDate.add(1, 'days').diff(lastDate) < 0) {
                                                                                                dates.push(currDate.clone().toDate());
                                                                                            }
                                                                                            dates.push(moment(this.state.campaign.budget[this.state.activechannel].end_date).startOf('day').clone().toDate());
                                                                                        }

                                                                                        this.state.campaign.budget[this.state.activechannel].total_budget = this.renders.round(this.state.campaign.budget[this.state.activechannel].daily_budget * dates.length);
                                                                                    }

                                                                                    this.setState({
                                                                                        campaign: this.state.campaign
                                                                                    }, () => {
                                                                                        for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                            if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                                this.state.shopping.campaigns[i].budget = this.state.campaign.budget;
                                                                                            }
                                                                                        }
                                                                                        this.setState({
                                                                                            shopping: this.state.shopping
                                                                                        }, () => {
                                                                                            this.functions.save('shopping');
                                                                                        })
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.renders.errors().filter((item) => {
                                                                        return this.state.activechannel === item.channel && item.type === 'daily_budget' && item.campaign === this.state.campaign.id
                                                                    }).length > 0 &&
                                                                    <div
                                                                        className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                                        <ul className="list-decimal">
                                                                            {
                                                                                this.renders.errors().filter((item) => {
                                                                                    return this.state.activechannel === item.channel && item.type === 'daily_budget' && item.campaign === this.state.campaign.id
                                                                                }).map((item) => {
                                                                                    return (
                                                                                        <li className="text-xs text-red-500 font-bold ">
                                                                                            {item.title}
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className="mt-4">
                                                                        <Dropdown
                                                                            filter={false}
                                                                            title={'Sales country'}
                                                                            size={'full'}
                                                                            icons={false}
                                                                            placeholder={"Select country"}
                                                                            options={[{ name: "United States", value: "US" }, { name: "Sweden", value: "SE" }]}
                                                                            value={(this.state.campaign && this.state.campaign.shopping_country) ? this.state.campaign.shopping_country : {}}
                                                                            onChange={(value) => {
                                                                                this.state.campaign.shopping_country = value;
                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                        if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.shopping.campaigns[i].shopping_country = this.state.campaign.shopping_country;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        shopping: this.state.shopping
                                                                                    }, () => {
                                                                                        this.functions.save('shopping');
                                                                                    })
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.campaign && this.state.campaign.shopping_country &&
                                                                    <div className="mt-4">
                                                                        <Dropdown
                                                                            filter={false}
                                                                            title={'Select shopping campaign type'}
                                                                            size={'full'}
                                                                            icons={false}
                                                                            placeholder={"Select type"}
                                                                            options={[{ name: "Regular", value: "regular" }, { name: "Smart", value: "smart" }]}
                                                                            value={(this.state.campaign && this.state.campaign.shopping_campaigntype) ? this.state.campaign.shopping_campaigntype : {}}
                                                                            onChange={(value) => {
                                                                                this.state.campaign.shopping_campaigntype = value;
                                                                                this.state.campaign.budget[this.state.activechannel].focus = null;
                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                        if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.shopping.campaigns[i].shopping_campaigntype = this.state.campaign.shopping_campaigntype;
                                                                                            this.state.shopping.campaigns[i].budget = this.state.campaign.budget;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        shopping: this.state.shopping
                                                                                    }, () => {
                                                                                        this.functions.save('shopping');
                                                                                    })
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.campaign.shopping_campaigntype &&
                                                                    this.state.campaign && this.state.campaign.shopping_country &&
                                                                    this.state.campaign.id && this.state.activechannel && !this.state.campaign.budget[this.state.activechannel].disabled &&
                                                                    <div className="flex flex-1 pt-4">
                                                                        <Dropdown
                                                                            filter={false}
                                                                            title={'Optimization goal'}
                                                                            noTitle={false}
                                                                            size={'full'}
                                                                            error={this.renders.errors().filter((item) => {
                                                                                return item.type === 'focus' && item.campaign === this.state.campaign.id
                                                                            }).length > 0}
                                                                            placeholder={"Select focus"}
                                                                            options={this.state.campaign.shopping_campaigntype && this.state.campaign.shopping_campaigntype.value === "regular" ? [
                                                                                {
                                                                                    name: "Manual CPC",
                                                                                    value: 'manual_cpc'
                                                                                },
                                                                                {
                                                                                    name: "Enhanced Manual CPC",
                                                                                    value: 'enhanced_manual_cpc'
                                                                                },
                                                                                {
                                                                                    name: "Target spend",
                                                                                    value: 'clicks'
                                                                                },

                                                                                {
                                                                                    name: "Target ROAS",
                                                                                    value: 'target_roas'
                                                                                }
                                                                            ] : [
                                                                                {
                                                                                    name: "Conversion (target ROAS)",
                                                                                    value: 'conversion'
                                                                                },
                                                    
                                                                                {
                                                                                    name: "Target ROAS",
                                                                                    value: 'target_roas'
                                                                                }
                                                                            ]}
                                                                            value={this.state.campaign.budget[this.state.activechannel].focus ? this.state.campaign.budget[this.state.activechannel].focus : {}}
                                                                            onChange={(value) => {
                                                                                this.state.campaign.budget[this.state.activechannel].focus = value;

                                                                                this.state.campaign.budget[this.state.activechannel].target_cost = 0;

                                                                                if (this.state.campaign.budget[this.state.activechannel].focus.value === "target_roas" || this.state.campaign.budget[this.state.activechannel].focus.value === "conversion") {
                                                                                    if (!this.state.campaign.budget[this.state.activechannel].target_sales || this.state.campaign.budget[this.state.activechannel].target_sales == 0) {
                                                                                        this.state.campaign.budget[this.state.activechannel].target_sales = this.state.campaign.budget[this.state.activechannel].daily_budget;
                                                                                    }
                                                                                    this.state.campaign.budget[this.state.activechannel].target_roas = (this.state.campaign.budget[this.state.activechannel].target_sales / this.state.campaign.budget[this.state.activechannel].daily_budget);
                                                                                    this.state.campaign.budget[this.state.activechannel].target_roas = this.state.campaign.budget[this.state.activechannel].target_roas.toFixed(1);
                                                                                }else{
                                                                                    this.state.campaign.budget[this.state.activechannel].target_roas = 0; 
                                                                                }

                                                                                if(this.state.campaign.budget[this.state.activechannel].focus.value === "clicks"){
                                                                                    this.state.campaign.budget[this.state.activechannel].target_cost = 10;
                                                                                }

                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                        if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.shopping.campaigns[i].budget = this.state.campaign.budget;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        shopping: this.state.shopping
                                                                                    }, () => {
                                                                                        this.functions.save('shopping');
                                                                                    })
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.campaign.shopping_campaigntype &&
                                                                    this.state.campaign && this.state.campaign.shopping_country &&
                                                                    this.state.campaign.budget &&
                                                                    this.state.campaign.budget[this.state.activechannel] &&
                                                                    this.state.campaign.budget[this.state.activechannel].focus &&
                                                                    (
                                                                        this.state.campaign.budget[this.state.activechannel].focus.value === "target_roas" ||
                                                                        this.state.campaign.budget[this.state.activechannel].focus.value === "conversion" ||
                                                                        this.state.campaign.budget[this.state.activechannel].focus.value === "manual_cpc" ||
                                                                        this.state.campaign.budget[this.state.activechannel].focus.value === "enhanced_manual_cpc"
                                                                    ) &&
                                                                    <div className="flex flex-1 mt-4">
                                                                        {
                                                                            (
                                                                                this.state.campaign.budget[this.state.activechannel].focus.value === "target_roas" ||
                                                                                this.state.campaign.budget[this.state.activechannel].focus.value === "conversion"
                                                                            ) &&
                                                                            <div className="flex flex-1">
                                                                                <Input
                                                                                    title={"Target sales pr day"}
                                                                                    sign={false}
                                                                                    white={false}
                                                                                    error={false}
                                                                                    value={this.state.campaign.budget[this.state.activechannel].target_sales}
                                                                                    tight={true}
                                                                                    type={"number"}
                                                                                    size={'full'}
                                                                                    icons={false}
                                                                                    onChange={(event) => {

                                                                                        this.state.campaign.budget[this.state.activechannel].target_sales = event.target.value;
                                                                                        this.state.campaign.budget[this.state.activechannel].target_roas = (event.target.value / this.state.campaign.budget[this.state.activechannel].daily_budget);
                                                                                        this.state.campaign.budget[this.state.activechannel].target_roas = this.state.campaign.budget[this.state.activechannel].target_roas.toFixed(1);

                                                                                        this.setState({
                                                                                            campaign: this.state.campaign
                                                                                        }, () => {
                                                                                            for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                                if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                                    this.state.shopping.campaigns[i].budget = this.state.campaign.budget;
                                                                                                }
                                                                                            }
                                                                                            this.setState({
                                                                                                campaign: this.state.campaign,
                                                                                                shopping: this.state.shopping
                                                                                            }, () => {
                                                                                                this.functions.save('shopping');
                                                                                            })
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            (
                                                                                this.state.campaign.budget[this.state.activechannel].focus.value === "target_roas" ||
                                                                                this.state.campaign.budget[this.state.activechannel].focus.value === "conversion"
                                                                            ) &&
                                                                            <div className="flex flex-1 ml-4 mr-4">
                                                                                <Input
                                                                                    title={"Daily budget"}
                                                                                    sign={false}
                                                                                    white={false}
                                                                                    error={false}
                                                                                    value={this.state.campaign.budget[this.state.activechannel].daily_budget}
                                                                                    tight={true}
                                                                                    type={"number"}
                                                                                    size={'full'}
                                                                                    icons={false}
                                                                                    disabled={true}
                                                                                    onChange={(event) => {

                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            (
                                                                                this.state.campaign.budget[this.state.activechannel].focus.value === "target_roas" ||
                                                                                this.state.campaign.budget[this.state.activechannel].focus.value === "conversion"
                                                                            ) &&
                                                                            <div className="flex flex-1">
                                                                                <Input
                                                                                    title={"Target ROAS"}
                                                                                    sign={false}
                                                                                    white={false}
                                                                                    error={false}
                                                                                    value={this.state.campaign.budget[this.state.activechannel].target_roas}
                                                                                    tight={true}
                                                                                    type={"number"}
                                                                                    size={'full'}
                                                                                    icons={false}
                                                                                    disabled={true}
                                                                                    onChange={(event) => {

                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            (
                                                                                this.state.campaign.budget[this.state.activechannel].focus.value === "manual_cpc" ||
                                                                                this.state.campaign.budget[this.state.activechannel].focus.value === "enhanced_manual_cpc"
                                                                            ) &&
                                                                            <div className="flex flex-1">
                                                                                <Input
                                                                                    title={"Target cost"}
                                                                                    sign={false}
                                                                                    white={false}
                                                                                    error={false}
                                                                                    value={this.state.campaign.budget[this.state.activechannel].target_cost}
                                                                                    tight={true}
                                                                                    type={"number"}
                                                                                    size={'full'}
                                                                                    icons={false}
                                                                                    onChange={(event) => {
                                                                                        this.state.campaign.budget[this.state.activechannel].target_cost = event.target.value;
                                                                                        this.setState({
                                                                                            campaign: this.state.campaign
                                                                                        }, () => {
                                                                                            for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                                if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                                    this.state.shopping.campaigns[i].budget = this.state.campaign.budget;
                                                                                                }
                                                                                            }
                                                                                            this.setState({
                                                                                                campaign: this.state.campaign,
                                                                                                shopping: this.state.shopping
                                                                                            }, () => {
                                                                                                this.functions.save('shopping');
                                                                                            })
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }

                                                                {
                                                                    this.state.campaign.shopping_campaigntype &&
                                                                    this.state.campaign && this.state.campaign.shopping_country &&
                                                                    this.state.campaign.budget &&
                                                                    this.state.campaign.budget[this.state.activechannel] &&
                                                                    this.state.campaign.budget[this.state.activechannel].focus &&
                                                                    this.state.campaign && this.state.campaign.shopping_campaigntype && this.state.campaign.shopping_campaigntype.value === "regular" &&
                                                                    <div className="mt-4">
                                                                        <Dropdown
                                                                            filter={false}
                                                                            title={'Campaign priority'}
                                                                            size={'full'}
                                                                            icons={false}
                                                                            placeholder={"Select priority"}
                                                                            options={[{ name: "0", value: "0" }, { name: "1", value: "1" }, { name: "2", value: "2" }]}
                                                                            value={(this.state.campaign && this.state.campaign.shopping_priority) ? this.state.campaign.shopping_priority : {}}
                                                                            onChange={(value) => {
                                                                                this.state.campaign.shopping_priority = value;
                                                                                this.setState({
                                                                                    campaign: this.state.campaign
                                                                                }, () => {
                                                                                    for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                        if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                            this.state.shopping.campaigns[i].shopping_priority = this.state.campaign.shopping_priority;
                                                                                        }
                                                                                    }
                                                                                    this.setState({
                                                                                        shopping: this.state.shopping
                                                                                    }, () => {
                                                                                        this.functions.save('shopping');
                                                                                    })
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                }
                                                                {this.renders.errors().filter((item) => {
                                                                    return item.type === 'focus' && item.campaign === this.state.campaign.id
                                                                }).length > 0 &&
                                                                    <div
                                                                        className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                                        <ul className="list-disc">
                                                                            {
                                                                                this.renders.errors().filter((item) => {
                                                                                    return item.type === 'focus' && item.campaign === this.state.campaign.id
                                                                                }).map((item, index) => {
                                                                                    return (
                                                                                        <li className="text-xs text-red-500 font-bold "
                                                                                            key={index}>
                                                                                            {item.title}
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </>
                                            {!this.state.opensearchoverview && this.renders.errors().filter((item) => {
                                                return item.tab === 'campaign' && item.campaign === this.state.campaign.id
                                            }).length > 0 &&
                                                <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                                    <ul className="list-disc">
                                                        {
                                                            this.renders.errors().filter((item) => {
                                                                return item.tab === 'campaign' && item.campaign === this.state.campaign.id
                                                            }).map((item, index) => {
                                                                return (
                                                                    <li className="text-xs text-red-500 font-bold "
                                                                        key={index}>
                                                                        {item.title}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    }
                                </Modal>
                            }
                        </div>

                        <div className="rounded-md bg-gray-100">
                            <div className="navigation px-4 mb-4">
                                <nav role="navigation" aria-label="Social sub navigation">
                                    <ul className="flex flex-row items-end">
                                        {this.state.searchsublinks.map((item, j) => {
                                            return (
                                                <li key={j}
                                                    className={"font-bold justify-center items-center flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.activesearchsub == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                    onClick={() => {
                                                        this.setState({
                                                            activesearchsub: item.id
                                                        }, () => {
                                                            if (this.state.activesearchsub == 1) {
                                                                this.functions.products();
                                                            }
                                                        });
                                                    }}>
                                                    {item.name}
                                                    {
                                                        item.id === 6 &&
                                                        this.renders.errors().filter((item) => {
                                                            return item.tab === 'locations' && item.campaign === this.state.campaign.id
                                                        }).length > 0 &&
                                                        <div
                                                            className="relative ml-6 h-full mr-2 flex justify-center items-center">
                                                            <div
                                                                className={"absolute close ml-1 w-6 h-6 mr-1 flex items-center justify-center text-lg rounded-md bg-white text-red-500"}>
                                                                <FeatherIcon className="stroke-current" size={15}
                                                                    icon="alert-circle" />
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            )
                                        })
                                        }
                                        <li className="flex-grow border-b-2 border-gray-200"></li>
                                    </ul>
                                </nav>
                            </div>

                            {

                                <div className="pb-4 px-4">
                                    {
                                        this.state.activesearchsub == 1 &&
                                        <div className="flex flex-row">
                                            <div className="bg-white rounded flex p-2 flex-2 overflow-scroll">
                                                {
                                                    false &&
                                                    !this.state.loading_products &&
                                                    Array.isArray(this.state.shopping.products) &&
                                                    this.state.shopping.products.map((item) => {
                                                        return (
                                                            <div className="h-40 w-40 flex justify-center rounded-md overflow-hidden relative mr-2 ml-2 mb-2 mt-2 border">
                                                                {
                                                                    <img src={item.imageLink} style={{ height: '100%' }} />
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {
                                                    !this.state.loading_products &&
                                                    this.state.campaign.shopping_campaigntype && this.state.campaign.shopping_campaigntype.value &&
                                                    this.state.campaign.shopping_country && this.state.campaign.shopping_country.value &&
                                                    <div className={(this.state.campaign.shopping_campaigntype.value === "smart" ? "flex justify-center align-middle items-center " : "") + " w-full overflow-scroll"}>
                                                        <AdvertisingPreviewShoppingAll
                                                            shoppingName={this.state.client.google_shopping ? this.state.client.google_shopping.accountName : ""}
                                                            campaign={this.state.campaign}
                                                            shopping={this.state.shopping}
                                                            width={"full"}
                                                        />
                                                    </div>

                                                }
                                                {
                                                    this.state.loading_products &&
                                                    <div className="h-full w-full justify-center align-middle flex flex-col text-center">
                                                        <div className="font-bold mb-2">Loading Products ...</div>
                                                        <BeatLoader
                                                            sizeUnit={"px"}
                                                            size={17}
                                                            color={'#060534'}
                                                            loading={true}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            {
                                                false &&
                                                <div className="flex-1 bg-white rounded p-4 ml-4">
                                                    {
                                                        false &&
                                                        this.state.campaign && this.state.campaign.shopping_country &&
                                                        this.state.campaign && this.state.campaign.shopping_campaigntype &&
                                                        (
                                                            (this.state.campaign && this.state.campaign.shopping_campaigntype && this.state.campaign.shopping_campaigntype.value === "regular" && this.state.campaign && this.state.campaign.shopping_priority)
                                                            ||
                                                            (this.state.campaign && this.state.campaign.shopping_campaigntype && this.state.campaign.shopping_campaigntype.value !== "regular")
                                                        ) &&
                                                        <div className="mt-4">
                                                            <Dropdown
                                                                filter={false}
                                                                title={'Filter by kind'}
                                                                size={'full'}
                                                                icons={false}
                                                                placeholder={"Select kind"}
                                                                options={(this.state.shopping && Array.isArray(this.state.shopping.products)) ? this.state.shopping.products.map((item) => {
                                                                    return item.kind
                                                                }).filter(function (item, pos, self) {
                                                                    return self.indexOf(item) == pos;
                                                                }).map((item) => {
                                                                    return { name: item, value: item }
                                                                }) : []}
                                                                value={(this.state.campaign && this.state.campaign.shopping_kind) ? this.state.campaign.shopping_kind : {}}
                                                                onChange={(value) => {
                                                                    this.state.campaign.shopping_kind = value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                            if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                this.state.shopping.campaigns[i].shopping_kind = this.state.campaign.shopping_kind;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            shopping: this.state.shopping
                                                                        }, () => {
                                                                            this.functions.save('shopping');
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        false &&
                                                        this.state.campaign && this.state.campaign.shopping_country &&
                                                        this.state.campaign && this.state.campaign.shopping_campaigntype &&
                                                        this.state.campaign && this.state.campaign.shopping_priority &&
                                                        (
                                                            (this.state.campaign && this.state.campaign.shopping_campaigntype && this.state.campaign.shopping_campaigntype.value === "regular" && this.state.campaign && this.state.campaign.shopping_priority)
                                                            ||
                                                            (this.state.campaign && this.state.campaign.shopping_campaigntype && this.state.campaign.shopping_campaigntype.value !== "regular")
                                                        ) &&
                                                        <div className="mt-4">
                                                            <Dropdown
                                                                filter={false}
                                                                title={'Filter by condition'}
                                                                size={'full'}
                                                                icons={false}
                                                                placeholder={"Select condition"}
                                                                options={(this.state.shopping && Array.isArray(this.state.shopping.products)) ? this.state.shopping.products.map((item) => {
                                                                    return item.condition
                                                                }).filter(function (item, pos, self) {
                                                                    return self.indexOf(item) == pos;
                                                                }).map((item) => {
                                                                    return { name: item, value: item }
                                                                }) : []}
                                                                value={(this.state.campaign && this.state.campaign.shopping_condition) ? this.state.campaign.shopping_condition : {}}
                                                                onChange={(value) => {
                                                                    this.state.campaign.shopping_condition = value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                            if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                this.state.shopping.campaigns[i].shopping_condition = this.state.campaign.shopping_condition;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            shopping: this.state.shopping
                                                                        }, () => {
                                                                            this.functions.save('shopping');
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        false &&
                                                        this.state.campaign && this.state.campaign.shopping_country &&
                                                        this.state.campaign && this.state.campaign.shopping_campaigntype &&
                                                        this.state.campaign && this.state.campaign.shopping_priority &&
                                                        (
                                                            (this.state.campaign && this.state.campaign.shopping_campaigntype && this.state.campaign.shopping_campaigntype.value === "regular" && this.state.campaign && this.state.campaign.shopping_priority)
                                                            ||
                                                            (this.state.campaign && this.state.campaign.shopping_campaigntype && this.state.campaign.shopping_campaigntype.value !== "regular")
                                                        ) &&
                                                        <div className="mt-4">
                                                            <Dropdown
                                                                filter={false}
                                                                title={'Filter by channel'}
                                                                size={'full'}
                                                                icons={false}
                                                                disabled={this.state.campaign && this.state.campaign.shopping_campaigntype && this.state.campaign.shopping_campaigntype.value === "smart"}
                                                                placeholder={"Select channel"}
                                                                options={(this.state.shopping && Array.isArray(this.state.shopping.products)) ? this.state.shopping.products.map((item) => {
                                                                    return item.channel
                                                                }).filter(function (item, pos, self) {
                                                                    return self.indexOf(item) == pos;
                                                                }).map((item) => {
                                                                    return { name: item, value: item }
                                                                }) : []}
                                                                value={(this.state.campaign && this.state.campaign.shopping_channel) ? this.state.campaign.shopping_channel : {}}
                                                                onChange={(value) => {
                                                                    this.state.campaign.shopping_channel = value;
                                                                    this.setState({
                                                                        campaign: this.state.campaign
                                                                    }, () => {
                                                                        for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                            if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                this.state.shopping.campaigns[i].shopping_channel = this.state.campaign.shopping_channel;
                                                                            }
                                                                        }
                                                                        this.setState({
                                                                            shopping: this.state.shopping
                                                                        }, () => {
                                                                            this.functions.save('shopping');
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.activesearchsub == 5 &&
                                        <div className="flex flex-1 flex-col">
                                            <div className="flex flex-1 flex-col bg-white rounded-md">
                                                <div className="px-4 py-4 flex-row flex">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="mb-0 font-bold">
                                                            Basic
                                                        </h3>
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Avg. number of search per month in parantheses
                                                        </p>
                                                    </div>
                                                </div>
                                                <SlideDown closed={false}>
                                                    <div className="w-full border-t" />
                                                    <div className="flex flex-1 bg-white px-4 py-4 flex-col rounded-md">
                                                        <div className="flex flex-row">
                                                            <div className="flex flex-1 pr-3">
                                                                <TargetingGender
                                                                    search={true}
                                                                    channels={this.state.channels}
                                                                    targeting={this.state.campaign.targeting ? this.state.campaign.targeting : {}}
                                                                    onChange={(value) => {
                                                                        this.state.campaign.targeting = value;
                                                                        this.setState({
                                                                            campaign: this.state.campaign
                                                                        }, () => {
                                                                            for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.shopping.campaigns[i].targeting = this.state.campaign.targeting;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                shopping: this.state.shopping
                                                                            }, () => {
                                                                                this.functions.save('shopping');
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="flex flex-1 pl-3">
                                                                <TargetingAge
                                                                    search={true}
                                                                    channels={this.state.channels}
                                                                    targeting={this.state.campaign.targeting ? this.state.campaign.targeting : {}}
                                                                    onChange={(value) => {
                                                                        this.state.campaign.targeting = value;
                                                                        this.setState({
                                                                            campaign: this.state.campaign
                                                                        }, () => {
                                                                            for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.shopping.campaigns[i].targeting = this.state.campaign.targeting;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                shopping: this.state.shopping
                                                                            }, () => {
                                                                                this.functions.save('shopping');
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            //ADD LATER
                                                            false &&
                                                            <div className="mt-3">
                                                                <TargetingWeekdays
                                                                    targeting={this.state.campaign.targeting ? this.state.campaign.targeting : {}}
                                                                    onChange={(value) => {
                                                                        this.state.campaign.targeting = value;
                                                                        this.setState({
                                                                            campaign: this.state.campaign
                                                                        }, () => {
                                                                            for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                                if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                                    this.state.shopping.campaigns[i].targeting = this.state.campaign.targeting;
                                                                                }
                                                                            }
                                                                            this.setState({
                                                                                shopping: this.state.shopping
                                                                            }, () => {
                                                                                this.functions.save('shopping');
                                                                            })
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </SlideDown>
                                            </div>
                                        </div>
                                    }
                                    {this.state.activesearchsub == 6 &&
                                        <div className="flex flex-1 flex-col">
                                            <div className="flex flex-1 flex-col bg-white rounded-md">
                                                <div className="px-4 py-4 flex-row flex">
                                                    <div className="flex flex-1 flex-col">
                                                        <h3 className="mb-0 font-bold">
                                                            Locations
                                                        </h3>
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Avg. number of search per month in parantheses
                                                        </p>
                                                    </div>
                                                </div>
                                                <SlideDown closed={false}>
                                                    <div className="w-full border-t" />
                                                    <div className="p-4 pb-2">
                                                        <TargetingLocation
                                                            border={true}
                                                            search={true}
                                                            exclude_locations={this.state.campaign.exclude_locations ? this.state.campaign.exclude_locations : {}}
                                                            locations={this.state.locations}
                                                            channels={this.state.campaign.budget}
                                                            updateLocation={(value) => {
                                                                this.state.campaign.exclude_locations = value;
                                                                this.setState({
                                                                    campaign: this.state.campaign
                                                                }, () => {
                                                                    for (let i = 0; i < this.state.shopping.campaigns.length; i++) {
                                                                        if (this.state.shopping.campaigns[i].id === this.state.campaign.id) {
                                                                            this.state.shopping.campaigns[i].exclude_locations = this.state.campaign.exclude_locations;
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        shopping: this.state.shopping
                                                                    }, () => {
                                                                        this.functions.save('shopping');
                                                                    })
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </SlideDown>
                                                {
                                                    this.renders.errors().filter((item) => {
                                                        return item.type === 'geographic' && item.campaign === this.state.campaign.id
                                                    }).length > 0 &&
                                                    <div className="p-4 pt-0">
                                                        <div className="flex flex-1 bg-red-100 rounded-md py-4 px-8">
                                                            <ul className="list-disc">
                                                                {
                                                                    this.renders.errors().filter((item) => {
                                                                        return item.type === 'geographic' && item.campaign === this.state.campaign.id
                                                                    }).map((item, index) => {
                                                                        return (
                                                                            <li className="text-xs text-red-500 font-bold "
                                                                                key={index}>
                                                                                {item.title}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                </div>
                            }
                        </div>
                        {
                            this.state.next &&
                            this.renders.errors().filter((item) => {
                                return item.campaign === this.state.campaign.id
                            }).length > 0 &&
                            <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                                <ul className="list-disc">
                                    {
                                        this.renders.errors().filter((item) => {
                                            return item.campaign === this.state.campaign.id
                                        }).map((item, index) => {
                                            return (
                                                <li className="text-xs text-red-500 font-bold "
                                                    key={index}>
                                                    {item.title}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <div className="flex flex-row mt-4 mb-4">
                    <div className="">
                        <button onClick={() => {
                            try {
                                this.props.onBack();
                            } catch (e) {
                            }
                        }} className="inline-flex items-center text-sm font-bold text-purple-900 py-2 hover:opacity-50">
                            <span className="arrow-right arrow-dark transform rotate-180 mr-2"></span> Previous step
                        </button>
                    </div>

                    <div className="flex flex-1"></div>
                    <div className="text-right">
                        <button onClick={() => {
                            this.functions.next()
                        }} className="btn btn-lg btn-primary inline-flex items-center">
                            Next step: Preview <span className="arrow-right"></span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingShopping;
