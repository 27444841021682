import React, {Component} from 'react';
import './style.css';
import {SlideDown} from 'react-slidedown'
import 'sweetalert/dist/sweetalert.css'
import Dropdown from "../dropdown";
import Input from "../input";
import TextArea from "../text-area";
import AdvertisingCreativesFacebookImage from "../advertising-creatives-facebook-image";
import AdvertisingCreativesFacebookVideo from "../advertising-creatives-facebook-video";
import AdvertisingCreativesFacebookCarousel from "../advertising-creatives-facebook-carousel";
import AdvertisingCreativesGoogleSearch from "../advertising-creatives-google-search";

class AdvertisingCreativesGoogle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creative: this.props.creative,
            client: this.props.client,
            site: this.props.site,
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            creative: nextProps.creative,
            client: nextProps.client,
            site: nextProps.site
        });
    }

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            creative: this.props.creative,
            client: this.props.client,
            site: this.props.site
        });
    }

    init = {};

    functions = {};

    validators = {};

    renders = {};

    render() {
        return (
            <SlideDown closed={false}>
                {
                    <div className="Section">
                        <AdvertisingCreativesGoogleSearch
                            creative={this.state.creative}
                            client={this.state.client}
                            title={this.state.creative.title}
                            url={this.state.site.hostname}
                            body={this.state.creative.body}
                            extended={this.state.creative.body_extended}
                        />
                    </div>
                }
            </SlideDown>
        )
    }
}

export default AdvertisingCreativesGoogle;
