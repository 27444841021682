import React, { Component } from 'react';
import {
    PlayIcon,
    PauseIcon,
    ClockIcon,
    ExclamationIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    CubeIcon,
    PencilAltIcon,
    ClipboardListIcon,
    LightningBoltIcon,
} from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import moment from 'moment';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { clientRegister } from '../services/clientRegister';
import { userRegister } from '../services/userRegister';
import ReactTooltip from 'react-tooltip'

const months = [
    { id: 1, name: 'January', value: "01", unix: 0 },
    { id: 2, name: 'February', value: "02", unix: 1 },
    { id: 3, name: 'March', value: "03", unix: 2 },
    { id: 4, name: 'April', value: "04", unix: 3 },
    { id: 5, name: 'May', value: "05", unix: 4 },
    { id: 6, name: 'June', value: "06", unix: 5 },
    { id: 7, name: 'July', value: "07", unix: 6 },
    { id: 8, name: 'August', value: "08", unix: 7 },
    { id: 9, name: 'September', value: "09", unix: 8 },
    { id: 10, name: 'October', value: "10", unix: 9 },
    { id: 11, name: 'November', value: "11", unix: 10 },
    { id: 12, name: 'December', value: "12", unix: 11 }
];

const years = [
    { id: 2020, name: '2020', value: "2020" },
    { id: 2021, name: '2021', value: "2021" },
    { id: 2022, name: '2022', value: "2022" },
    { id: 2023, name: '2023', value: "2023" },
    { id: 2024, name: '2024', value: "2024" },
    { id: 2025, name: '2025', value: "2025" }
];

class Dashboard extends Component {

    constructor(props) {
        super(props);
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        this.state = {
            loading: true,
            loading_partial: false,
            year: { id: year, name: year, value: year },
            years: years,
            month: months.filter((item) => { return item.unix === month })[0],
            months: months,
            days: [],
            meta: {},
            orders_statistics: {},
            wizards_statistics: {},
            client: {},
            user: {},
            statistic: 'campaign'
        };
    }

    componentDidMount() {
        this.functions.client();
        // this.functions.orders(true);
        this.functions.campaigns(true);
        this.functions.ordersStatistics();
        this.functions.wizardStatistics();
        this.props.updateStatistics();
        this.functions.user();
    }

    functions = {
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get()
            });
        },
        client: () => {
            this.setState({
                client: clientRegister.get()
            });
        },
        orders: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init, days: this.functions.getMonthDetails(this.state.year.value, this.state.month.unix) });
            try {
                let response = await this.calls.orders();

                //ADD ORDERS TO DAYS
                response.data.map((order) => {
                    this.state.days = this.state.days.map((day) => {
                        if (moment(order.startDate).format("YYYY-MM-DD") == day.date) {
                            day.events.push(order);
                        }
                        return day;
                    });
                });

                await this.promisedSetState({ orders: response.data, days: this.state.days });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        campaigns: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init, days: this.functions.getMonthDetails(this.state.year.value, this.state.month.unix) });
            try {
                let response = await this.calls.campaigns();

                //ADD CAMPAIGNS TO DAYS
                response.data.map((campaign) => {
                    campaign.startDate = campaign.campaignStartDate;
                    campaign.endDate = campaign.campaignEndDate;

                    this.state.days = this.state.days.map((day) => {
                        if (moment(campaign.startDate).format("YYYY-MM-DD") == day.date) {
                            let index = day.events.findIndex(item => item.channel === campaign.channel)
                            if (index >= 0) {
                                day.events[index].channel_ids.push(campaign._id);
                                day.events[index].channel_names.push(campaign.campaignName);
                            } else {
                                campaign.channel_ids = [campaign._id];
                                campaign.channel_names = [campaign.campaignName];
                                day.events.push(campaign);
                            }
                        }
                        return day;
                    });
                });

                await this.promisedSetState({ campaigns: response.data, days: this.state.days });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        ordersStatistics: async () => {
            await this.promisedSetState({ loading_orders_statistics: true });
            try {
                let response = await this.calls.ordersStatistics();
                await this.promisedSetState({ orders_statistics: response.data });
            } catch (error) { }
            await this.promisedSetState({ loading_orders_statistics: false });
        },
        wizardStatistics: async () => {
            await this.promisedSetState({ loading_wizards_statistics: true });
            try {
                let response = await this.calls.wizardStatistics();
                await this.promisedSetState({ wizards_statistics: response.data });
            } catch (error) { }
            await this.promisedSetState({ loading_wizards_statistics: false });
        },
        getMonthDetails: (year, month) => {
            let firstDay = (new Date(year, month)).getDay() - 1;
            let numberOfDays = this.functions.getNumberOfDays(year, month);
            let monthArray = [];
            let rows = 6;
            let currentDay = null;
            let index = 0;
            let cols = 7;
            for (let row = 0; row < rows; row++) {
                for (let col = 0; col < cols; col++) {
                    currentDay = this.functions.getDayDetails({
                        index,
                        numberOfDays,
                        firstDay,
                        year,
                        month
                    });
                    monthArray.push(currentDay);
                    index++;
                }
            }
            return monthArray;
        },
        getDayDetails: (args) => {
            let date = args.index - args.firstDay;
            let day = args.index % 7;
            let prevMonth = args.month - 1;
            let prevYear = args.year;
            if (prevMonth < 0) {
                prevMonth = 11;
                prevYear--;
            }
            let prevMonthNumberOfDays = this.functions.getNumberOfDays(prevYear, prevMonth);
            let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
            let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
            args.month = args.month + month;
            let date_correct_format = moment(new Date(args.year, args.month, _date)).format('YYYY-MM-DD')
            return {
                date: date_correct_format,
                day,
                isCurrentMonth: month === 0,
                isToday: date_correct_format === moment().format('YYYY-MM-DD'),
                events: []
            }
        },
        getNumberOfDays: (year, month) => {
            return 40 - new Date(year, month, 40).getDate();
        }
    };

    calls = {
        orders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?year=" + this.state.year.value + (this.state.days.length > 0 ? ("&startSpan=" + this.state.days[0].date + "&endSpan=" + this.state.days[(this.state.days.length - 1)].date) : "") + (this.state.client.id && this.state.client.id !== 0 ? ("&client=" + this.state.client.id) : "");
            return apiRegister.call(options, url);
        },
        campaigns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/exernal-campaigns-list?year=" + this.state.year.value + (this.state.days.length > 0 ? ("&startSpan=" + this.state.days[0].date + "&endSpan=" + this.state.days[(this.state.days.length - 1)].date) : "") + (this.state.client.id && this.state.client.id !== 0 ? ("&client=" + this.state.client.id) : "");
            return apiRegister.call(options, url);
        },
        ordersStatistics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/ordersStatistics" + (this.state.client.id && this.state.client.id !== 0 ? ("?client=" + this.state.client.id) : "");
            return apiRegister.call(options, url);
        },
        wizardStatistics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getWizardStatistics" + (this.state.client.id && this.state.client.id !== 0 ? ("?client=" + this.state.client.id) : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="relative flex-1 flex flex-col">

                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        miniMizeOnLoad={false}
                        minimizeMenu={(option) => {
                            if (this.props.minimizeMenu) {
                                this.props.minimizeMenu(option);
                            }
                        }}
                        onButton={(type) => {

                        }}
                        breadcrumb="Dashboard"
                        hideUserDropdown={true}
                        history={this.props.history}
                        buttons={[]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showUser={true}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client: client
                            });
                            this.functions.campaigns();
                            this.functions.orders(true);
                            this.functions.ordersStatistics();
                            this.functions.wizardStatistics();
                            this.props.updateStatistics(client);
                        }}
                        client={this.state.client}
                    />
                </div>
                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {/*CONTENT*/}
                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className="flex flex-1 flex-col">
                            <div className={"grid grid-cols-1 sm:grid-cols-4 gap-4 mb-4"}>
                                <div onClick={() => {
                                    this.props.history.push("/v2/orders/active")
                                }} className="col-span-1 cursor-pointer bg-white rounded-lg shadow p-6 flex flex-row items-center">
                                    <div className="flex flex-1 flex-col">
                                        <div class="leading-6 text-4xl font-bold mb-2 pt-1">{this.state.orders_statistics.active}</div>
                                        <p class="mt-1 text-sm text-gray-700">Active orders</p>
                                    </div>
                                    <div className="bg-green-100 h-16 w-16 flex justify-center items-center rounded-full">
                                        <PlayIcon className="h-8 w-8 text-green-500" />
                                    </div>
                                </div>
                                <div onClick={() => {
                                    this.props.history.push("/v2/orders/paused")
                                }} className="col-span-1 cursor-pointer bg-white rounded-lg shadow p-6 flex flex-row">
                                    <div className="flex flex-1 flex-col">
                                        <div class="leading-6 text-4xl font-bold mb-2 pt-1">{this.state.orders_statistics.paused}</div>
                                        <p class="mt-1 text-sm text-gray-700">Paused orders</p>
                                    </div>
                                    <div className="bg-orange-100 h-16 w-16  flex justify-center items-center rounded-full">
                                        <PauseIcon className="h-8 w-8 text-orange-500" />
                                    </div>
                                </div>
                                <div onClick={() => {
                                    this.props.history.push("/v2/orders/upcoming")
                                }} className="col-span-1 cursor-pointer bg-white rounded-lg shadow p-6 flex flex-row">
                                    <div className="flex flex-1 flex-col">
                                        <div class="leading-6 text-4xl font-bold mb-2 pt-1">{this.state.orders_statistics.upcoming}</div>
                                        <p class="mt-1 text-sm text-gray-700">Upcoming orders</p>
                                    </div>
                                    <div className="bg-purple-100 h-16 w-16  flex justify-center items-center rounded-full">
                                        <ClockIcon className="h-8 w-8 text-purple-500" />
                                    </div>
                                </div>
                                {
                                    false &&
                                    <div onClick={() => {
                                        this.props.history.push("/v2/actions")
                                    }} className="col-span-1 cursor-pointer bg-white rounded-lg shadow p-6 flex flex-row">
                                        <div className="flex flex-1 flex-col">
                                            <div class="leading-6 text-4xl font-bold mb-2 pt-1">{this.state.orders_statistics.actions}</div>
                                            <p class="mt-1 text-sm text-gray-700">Actions</p>
                                        </div>
                                        <div className="bg-blue-100 h-16 w-16  flex justify-center items-center rounded-full">
                                            <LightningBoltIcon className="h-8 w-8 text-blue-500" />
                                        </div>
                                    </div>
                                }
                                <div onClick={() => {
                                    if (this.state.user && this.state.user.userRole && this.state.user.userRole === "sales") {

                                    } else this.props.history.push("/v2/warnings")
                                }} className={"col-span-1 bg-white rounded-lg shadow p-6 flex flex-row" + (this.state.user && this.state.user.userRole && this.state.user.userRole === "sales" ? " cursor-not-allowed" : " cursor-pointer")}>
                                    <div className="flex flex-1 flex-col">
                                        <div class="leading-6 text-4xl font-bold mb-2 pt-1">{this.state.wizards_statistics.error}</div>
                                        <p class="mt-1 text-sm text-gray-700">Errors</p>
                                    </div>
                                    <div className="bg-red-100 h-16 w-16  flex justify-center items-center rounded-full">
                                        <ExclamationIcon className="h-8 w-8 text-red-500" />
                                    </div>
                                </div>
                            </div>

                            <div className={"lg:flex lg:h-full lg:flex-col bg-white rounded-lg shadow overflow-hidden flex flex-1 flex-col"}>
                                <div class="p-4 flex flex-row border-b items-center">
                                    <div className="flex flex-1 flex-col justify-start items-start">
                                        <div className="font-bold text-lg">{this.state.month.name}</div>
                                        <div className="font-medium text-gray-600 text-xs"> {this.state.year.name}</div>
                                    </div>
                                    <div className='flex flex-row'>
                                        {
                                            false &&
                                            <select
                                                value={this.state.statistic}
                                                onChange={(e) => {
                                                    e.target.value === 'order' ? this.functions.orders() : this.functions.campaigns()
                                                    this.promisedSetState({ statistic: e.target.value })
                                                }
                                                }
                                                className='inline-flex items-center mr-2 mt-0 px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none border-1.5 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                            >
                                                <option value="order">Order</option>
                                                <option value="campaign">Campaign</option>
                                            </select>
                                        }
                                        <button
                                            type="button"
                                            onClick={async () => {
                                                if (!this.state.loading_partial) {
                                                    let month = null;
                                                    let year = null;
                                                    if (this.state.month.id === 1) {
                                                        month = { id: 12, name: 'December', value: "12", unix: 11 };
                                                        year = years.filter((item) => { return item.id == (+this.state.year.id - 1) })[0];
                                                    } else {
                                                        month = months.filter((item) => { return item.id == (+this.state.month.id - 1) })[0];
                                                    }
                                                    await this.promisedSetState({
                                                        month: month ? month : this.state.month,
                                                        year: year ? year : this.state.year
                                                    });
                                                    this.state.statistic === 'order' ? this.functions.orders() : this.functions.campaigns();
                                                }
                                            }}
                                            className="inline-flex items-center mr-2 px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none border-1.5 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                            Previous month
                                        </button>
                                        <button
                                            type="button"
                                            onClick={async () => {
                                                if (!this.state.loading_partial) {
                                                    let month = null;
                                                    let year = null;
                                                    if (this.state.month.id === 12) {
                                                        month = { id: 1, name: 'January', value: "01", unix: 0 };
                                                        year = years.filter((item) => { return item.id == (+this.state.year.id + 1) })[0];
                                                    } else {
                                                        month = months.filter((item) => { return item.id == (+this.state.month.id + 1) })[0];
                                                    }
                                                    await this.promisedSetState({
                                                        month: month ? month : this.state.month,
                                                        year: year ? year : this.state.year
                                                    });
                                                    this.state.statistic === 'order' ? this.functions.orders() : this.functions.campaigns();
                                                }
                                            }}
                                            className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none border-1.5 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Next month
                                            <ArrowRightIcon className="ml-2 h-5 w-5" />
                                        </button>
                                    </div>
                                </div>

                                {/*CALENDAR*/}
                                <div className="ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col relative">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                                        <div className="bg-white py-2">
                                            M<span className="sr-only sm:not-sr-only">on</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            T<span className="sr-only sm:not-sr-only">ue</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            W<span className="sr-only sm:not-sr-only">ed</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            T<span className="sr-only sm:not-sr-only">hu</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            F<span className="sr-only sm:not-sr-only">ri</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            S<span className="sr-only sm:not-sr-only">at</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            S<span className="sr-only sm:not-sr-only">un</span>
                                        </div>
                                    </div>
                                    <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                                        <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                                            {this.state.days.map((day, index) => (
                                                <div
                                                    onClick={() => {
                                                        let campaigns = [];
                                                        if (Array.isArray(day.events) && day.events.length > 0) {
                                                            day.events.map((event) => {
                                                                campaigns = campaigns.concat(event.channel_ids);
                                                            });
                                                            this.props.history.push("/v2/reports/campaigns?ids=" + campaigns.join(','));
                                                        }
                                                    }}
                                                    key={index}
                                                    className={classNames(
                                                        day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                                                        'relative py-2 px-3 hover:bg-gray-50 font-medium cursor-pointer'
                                                    )}
                                                >
                                                    <time
                                                        dateTime={day.date}
                                                        className={
                                                            day.isToday
                                                                ? 'flex h-6 w-6 items-center justify-center text-purple-500'
                                                                : undefined
                                                        }
                                                    >
                                                        {day.date.split('-').pop().replace(/^0/, '')}
                                                    </time>
                                                    <ol className="mt-3 mb-3 flex flex-row">
                                                        {Array.isArray(day.events) && day.events.map((event) => (
                                                            <li key={event.id}>
                                                                <div onClick={() => {
                                                                    //this.props.history.push("/v2/orders/" + event.id);
                                                                }} data-tip='' data-for={event._id} className="group relative flex cursor-pointer">
                                                                    {
                                                                        this.state.statistic === 'order' && <>
                                                                            <p className="flex-auto truncate font-medium text-gray-900 hover:text-purple-600">
                                                                                {event.name}
                                                                            </p>
                                                                            <div
                                                                                className="ml-3 flex-none text-gray-500"
                                                                            >
                                                                                {moment(event.startDate).format("HH:mm")}
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    {this.state.statistic === 'campaign' && <div className="flex flex-row">
                                                                        {
                                                                            Array.isArray(event.channel_ids) &&
                                                                            event.channel_ids.map((item, index) => {
                                                                                let image_path = "";
                                                                                if (event.channel == 'linkedin' || event.channel == 'snapchat') {
                                                                                    image_path = event.channel + "_icon.svg"
                                                                                } else {
                                                                                    image_path = event.channel + "_icon.png"
                                                                                }
                                                                                return (
                                                                                    <div className={(index !== 0 ? "-ml-3 " : "") + ""}>
                                                                                        <div className={`w-7 h-7 bg-${event.channel}-500 rounded-full p-1.5 border-1.5 border-white`}>
                                                                                            <div className="block w-full h-full bg-contain bg-no-repeat bg-center" style={{ backgroundImage: "url(" + require(`../assets/images/${image_path}`) + ")" }}></div>
                                                                                        </div>
                                                                                        {
                                                                                            false &&
                                                                                            <div className="text-white ml-2 text-xs font-medium"> {event.channel_ids.length} </div>
                                                                                        }
                                                                                        {
                                                                                            false &&
                                                                                            <div className="text-white ml-1 text-xs font-medium mr-2"> {event.channel} </div>
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </div>
                                                                    }
                                                                    {
                                                                        Array.isArray(event.channel_names) &&
                                                                        this.state.statistic === 'campaign' &&
                                                                        <ReactTooltip effect='solid' id={event._id}>
                                                                            <ul>
                                                                                {event.channel_names.map(name => <li className="flex-row flex">&#x2022;<div className="w-1"></div>{name}</li>)}
                                                                            </ul>
                                                                        </ReactTooltip>
                                                                    }
                                                                </div>
                                                            </li>
                                                        ))}
                                                        {
                                                            day.show_all &&
                                                            <li onClick={() => {
                                                                day.show_all = !day.show_all;
                                                                this.setState({
                                                                    days: this.state.days
                                                                });
                                                            }} className="text-gray-500 cursor-pointer hover:text-purple-600">
                                                                Show less
                                                            </li>
                                                        }
                                                        {!day.show_all && Array.isArray(day.events) && day.events.length > 2 && <li
                                                            onClick={() => {
                                                                day.show_all = !day.show_all;
                                                                this.setState({
                                                                    days: this.state.days
                                                                });
                                                            }} className="text-gray-500 cursor-pointer hover:text-purple-600">+ {day.events.length - 2} more</li>}
                                                    </ol>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                                            {Array.isArray(this.state.days) && this.state.days.map((day, index) => (
                                                <button
                                                    key={index}
                                                    type="button"
                                                    className={classNames(
                                                        day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                                        (day.isSelected || day.isToday) && 'font-semibold',
                                                        day.isSelected && 'text-white',
                                                        !day.isSelected && day.isToday && 'text-indigo-600',
                                                        !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                                        !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                                                        'flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10'
                                                    )}
                                                >
                                                    <time
                                                        dateTime={day.date}
                                                        className={classNames(
                                                            day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                                                            day.isSelected && day.isToday && 'bg-indigo-600',
                                                            day.isSelected && !day.isToday && 'bg-gray-900',
                                                            'ml-auto'
                                                        )}
                                                    >
                                                        {day.date.split('-').pop().replace(/^0/, '')}
                                                    </time>
                                                    <span className="sr-only">{day.events.length} events</span>
                                                    {day.events.length > 0 && (
                                                        <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                                            {day.events.filter((item, index) => {
                                                                return index < 3
                                                            }).map((event) => (
                                                                <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                                                            ))}
                                                        </span>
                                                    )}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                {false && (
                                    <div className="py-10 px-4 sm:px-6 lg:hidden">
                                        <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
                                            {[].map((event) => (
                                                <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                                                    <div className="flex-auto">
                                                        <p className="font-semibold text-gray-900">{event.name}</p>
                                                        <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                                                            <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            {event.time}
                                                        </time>
                                                    </div>
                                                    <a
                                                        href={event.href}
                                                        className="ml-6 flex-none self-center rounded-md border border-gray-300 bg-white py-2 px-3 font-semibold text-gray-700 opacity-0 shadow-sm hover:bg-gray-50 focus:opacity-100 group-hover:opacity-100"
                                                    >
                                                        Edit<span className="sr-only">, {event.name}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ol>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default Dashboard;