import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { AdjustmentsIcon, BeakerIcon, CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ClipboardListIcon, CogIcon, CursorClickIcon, ExclamationIcon, LocationMarkerIcon, LockClosedIcon, MenuAlt1Icon, MinusIcon, PlusIcon, ViewGridAddIcon } from '@heroicons/react/outline'

import FeatherIcon from 'feather-icons-react';

import SingleDatepicker from "../modules/singleDatepicker";
import fuzzysort from 'fuzzysort';
import moment from 'moment';
import cn from "classnames";

class negativeKeywordList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: false
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            list: this.props.list ? this.props.list : this.state.list,
        })
        this.props.onDisable(true);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            //dates: nextProps.dates
        })
    }

    functions = {
        create: async () => {
            try {
                //this.props.onUpdate(this.state.dates);
            } catch (error) {
                console.log(error, "error");
            }
        }
    };

    calls = {

    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={`h-full flex flex-col overflow-y-scroll`}>
                <>
                    <div className={"px-2"}>
                        {
                            this.state.list &&
                            <>
                                <div className='flex flex-1 flex-col items-center'>
                                    {
                                        Array.isArray(this.state.list.keywords) && this.state.list.keywords.length > 0 &&
                                        this.state.list.keywords.map((item, index) => {
                                            return (
                                                <div key={index} className='flex flex-1 flex-row items-center w-full justify-between border-b py-3'>
                                                    <div className="flex flex-col flex-1 ml-3">
                                                        <h3 className="mb-0">
                                                            {item.text}
                                                        </h3>
                                                    </div>
                                                    <div className="flex flex-col ml-3">
                                                        <h3 className="mb-0">
                                                            {item.matchType}
                                                        </h3>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        }
                    </div>
                </>
            </div >
        )
    }
}

export default negativeKeywordList;
