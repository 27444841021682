import React, {Component} from 'react';
import './style.css';
import Card from "../../components/card";
import {Elements, StripeProvider} from 'react-stripe-elements';

class UserPaymentMethod extends Component {
    render(){
        return (
            <StripeProvider apiKey="pk_test_sB2Y8BtdfUt0LrEbRr1D45P500xIZTxmoE">
                <div className="PaymentMethods">
                    <div className="PaymentMethods-Inner">
                        <Elements>
                            <Card/>
                        </Elements>
                    </div>
                </div>
            </StripeProvider>
        )
    }
}

export default UserPaymentMethod;
