import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { AdjustmentsIcon, BeakerIcon, BellIcon, ChartPieIcon, XMarkIcon, CheckCircleIcon, ChevronDownIcon, ChevronRightIcon, DocumentIcon, FolderIcon, HomeIcon, MenuIcon, UserIcon, VariableIcon, XIcon } from '@heroicons/react/outline';
import { orderRegister } from '../services/orderRegister';
import { userRegister } from '../services/userRegister';
import SideNavigation from '../moduleFiles/sideNavigation';
import TopNavigation from '../moduleFiles/topNavigation';
import Dashboard from './dashboard';
import OrderList from './orderList';
import CreativeList from './creativeList';
import SideNavigationAgent from '../moduleFiles/sideNavigationAgent';
import CreateOrderClient from './createOrderClient';
import CreateOrderInformation from './createOrderInformation';
import OrderOverview from './orderOverview';
import OrderDocuments from './orderDocuments';
import OrderDrafts from './orderDrafts';
import OrderCreatives from './orderCreatives';
import OrderCampaigns from './orderCampaigns';
import CampaignList from './campaignList';
import CreateDraftOrder from './createDraftOrder';
import CreateDraftClient from './createDraftClient';
import CreateDraftChannels from './createDraftChannels';
import CreateDraftCreatives from './createDraftCreatives';
import CreateDraftPreview from './createDraftPreview';
import { draftRegister } from '../services/draftRegister';
import WizardList from './wizardList';
import CreateDraftChannel from './createDraftChannel';
import OrderOptimize from './orderOptimize';
import ClientList from './clientList';
import Client from './client';
import ClientNew from './clientNew';
import ReportAdvancedList from './reportAdvancedList';
import ReportStandardList from './reportStandardList';
import ReportTemplateList from './reportTemplateList';
import MetricList from './metricList';
import ReportStandardInternal from '../pages/report-standard-internal';
import AdvancedReportInternal from './advancedReportInternal';
import UserProfilePassword from '../pages/user-profile-password';
import UserProfileSettings from '../pages/user-profile-settings';
import UserProfileAccount from './account';
import UserUsersAllClient from '../pages/user-users-all-client';
import UserUsersNewClient from "../pages/user-users-new-client";
import Optimize from './optimize';
import OrderImportCampaigns from './orderImportCampaigns';
import TemplateList from './templateList';
import OrderOptimizeNew from './orderOptimizeNew';
import OrderABTest from './orderABTest';
import OrderActions from './orderActions';
import AgentList from './agentList';
import ReportList from './reportList';
import ClientPerformanceList from './clientPerformanceList';
import ClientWarningList from './clientWarningList';
import AccountPerformanceList from './accountPerformanceList';
import CampaignPerformanceList from './campaignPerformanceList';
import Connections from './connections';
import Settings from './settings';
import CustomDatasourcesList from './customDatasourcesList';
import CustomPlatformsList from './customPlatformsList';
import OrderPerformance from './orderPerformance';
import OrderBudgetPlanerTemp from './orderBudgetPlanerTemp';
import OrderBudgetPlaner from './orderBudgetPlaner';
import Actions from './actions';
import ClientBudgetList from './clientBudgetList';
import ClientAccountList from './clientAccountList';
import Changelog from './changelog';

class LoggedInAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    };

    componentWillMount() { }

    componentDidMount() { }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (window.location.pathname.indexOf("/v2/orders/new") === -1) {
            orderRegister.remove();
        }
        if (window.location.pathname.indexOf("/v2/campaigns/new") === -1) {
            draftRegister.remove();
        }
    }

    functions = {};

    renders = {};

    render() {
        return (
            <>
                <div className="min-h-full h-full">

                    <div className="min-h-full h-full">
                        <div className="min-h-full h-full flex flex-row">
                            <div className="">
                                <SideNavigationAgent
                                    ref={"navigation"}
                                    history={this.props.history}
                                    trigger={(option) => {
                                        if (option == "newOrder") {
                                            try {
                                                this.refs.activeOrders.functions.newOrder();
                                            } catch (error) {
                                                console.log(error);
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div className="flex flex-1 flex-col relative overflow-x-hidden">
                                <div className="flex flex-1 overflow-y-scroll">
                                    <div className="w-full relative h-full flex flex-col">
                                        <Switch>

                                            {/* USER */}
                                            <Route exact path="/v2/profile/account" component={UserProfileAccount} />
                                            <Route exact path="/v2/profile/connections" component={Connections} />
                                            <Route exact path="/v2/profile/settings" component={Settings} />
                                            <Route exact path="/v2/profile/agents" component={AgentList} />

                                            {/* DASHBOARD */}
                                            <Route
                                                render={(props) => <Dashboard
                                                    {...props}
                                                    minimizeMenu={(option) => {
                                                        this.refs.navigation.functions.minimizeMenu(option);
                                                    }}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                                }
                                                exact
                                                path="/v2/dashboard"
                                            />

                                            {/* REPORT - ADVANCED */}
                                            <Route key="reports-advanced" exact path="/v2/reports/advanced" >
                                                <ReportList
                                                    history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>
                                            <Route exact path="/v2/reports/new/advanced/:id" component={AdvancedReportInternal} />
                                            <Route
                                                render={(props) => <AdvancedReportInternal {...props} minimizeMenu={(option) => {
                                                    this.refs.navigation.functions.minimizeMenu(option);
                                                }} />}
                                                exact
                                                path="/v2/reports/advanced/:id/:report"
                                            />

                                            {/* REPORT - STANDARD */}
                                            <Route key="reports-standard" exact path="/v2/reports/standard" >
                                                <ReportList
                                                    history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>
                                            <Route exact path="/v2/reports/new/standard/:id" component={ReportStandardInternal} />
                                            <Route exact path="/v2/reports/standard/:id/:report" component={ReportStandardInternal} />

                                            {/* REPORT - TEMPLATE */}
                                            <Route key="reports-templates" exact path="/v2/reports/template" >
                                                <ReportList
                                                    history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>

                                            {/* PERFORMANCE MANAGERS */}
                                            <Route exact path="/v2/reports/accounts">
                                                <AccountPerformanceList history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>

                                            {/* PERFORMANCE MANAGERS */}
                                            <Route exact path="/v2/reports/campaigns">
                                                <CampaignPerformanceList history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>

                                            {/* OPTIMIZE */}
                                            <Route exact path="/v2/optimize" component={OrderImportCampaigns} />

                                            {/* METRIC - LIST */}
                                            <Route key="reports-metrics-custom" exact path="/v2/metrics/custom">
                                                <MetricList
                                                    history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>
                                            <Route key="reports-metrics-calculated" exact path="/v2/metrics/calculated">
                                                <MetricList
                                                    history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>

                                            {/* CLIENTS */}
                                            {/* <Route exact path="/v2/clients">
                                                <ClientList history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route> */}

                                            {/* NEW CLIENT */}
                                            <Route exact path="/v2/clients/new">
                                                <ClientNew history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>

                                            {/* CLIENTS */}

                                            <Route key="all" exact path="/v2/clients/all">
                                                <ClientList history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.campaignsStatistics(client)
                                                    }}
                                                />
                                            </Route>
                                            <Route key="active" exact path="/v2/clients/active">
                                                <ClientList history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.campaignsStatistics(client)
                                                    }}
                                                />
                                            </Route>
                                            <Route key="disabled" exact path="/v2/clients/disabled">
                                                <ClientList history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.campaignsStatistics(client)
                                                    }}
                                                />
                                            </Route>
                                            <Route key="archived" exact path="/v2/clients/archived">
                                                <ClientList history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.campaignsStatistics(client)
                                                    }}
                                                />
                                            </Route>
                                            <Route key="budgets" exact path="/v2/clients/budgets">
                                                <ClientBudgetList history={this.props.history}
                                                    updateStatistics={() => {
                                                        this.refs.navigation.functions.campaignsStatistics()
                                                    }}
                                                />
                                            </Route>
                                            <Route key="accounts" exact path="/v2/clients/accounts">
                                                <ClientAccountList history={this.props.history}
                                                    updateStatistics={() => {
                                                        this.refs.navigation.functions.campaignsStatistics()
                                                    }}
                                                />
                                            </Route>

                                            <Route exact path="/v2/client/:id">
                                                <Client history={this.props.history} updateStatistics={() => {
                                                    this.refs.navigation.functions.ordersStatistics()
                                                }} />
                                            </Route>

                                            {/* ORDERS */}
                                            <Route key="unassigned-orders" exact path="/v2/orders/unassigned">
                                                <OrderList history={this.props.history} updateStatistics={(client) => {
                                                    this.refs.navigation.functions.ordersStatistics(client);
                                                    this.refs.navigation.functions.campaignsStatistics(client);
                                                }}
                                                    view_name={"unassigned-orders"}
                                                />
                                            </Route>
                                            <Route key="active-orders" exact path="/v2/orders/active">
                                                <OrderList
                                                    ref={"activeOrders"}
                                                    history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                    view_name={"active-orders"}
                                                />
                                            </Route>
                                            <Route key="paused-orders" exact path="/v2/orders/paused">
                                                <OrderList history={this.props.history} updateStatistics={(client) => {
                                                    this.refs.navigation.functions.ordersStatistics(client);
                                                    this.refs.navigation.functions.campaignsStatistics(client);
                                                }}
                                                    view_name={"paused-orders"}
                                                />
                                            </Route>
                                            <Route key="upcoming-orders" exact path="/v2/orders/upcoming">
                                                <OrderList history={this.props.history} updateStatistics={(client) => {
                                                    this.refs.navigation.functions.ordersStatistics(client);
                                                    this.refs.navigation.functions.campaignsStatistics(client);
                                                }}
                                                    view_name={"upcoming-orders"}
                                                />
                                            </Route>
                                            <Route key="ended-orders" exact path="/v2/orders/ended">
                                                <OrderList history={this.props.history} updateStatistics={(client) => {
                                                    this.refs.navigation.functions.ordersStatistics(client);
                                                    this.refs.navigation.functions.campaignsStatistics(client);
                                                }}
                                                    view_name={"ended-orders"}
                                                />
                                            </Route>
                                            <Route key="archived-orders" exact path="/v2/orders/archived">
                                                <OrderList history={this.props.history} updateStatistics={(client) => {
                                                    this.refs.navigation.functions.ordersStatistics(client);
                                                    this.refs.navigation.functions.campaignsStatistics(client);
                                                }}
                                                    view_name={"archived-orders"}
                                                />
                                            </Route>

                                            {/* NEW ORDER */}
                                            <Route exact path="/v2/orders/new">
                                                <CreateOrderClient history={this.props.history} />
                                            </Route>
                                            <Route exact path="/v2/orders/new/information">
                                                <CreateOrderInformation history={this.props.history} />
                                            </Route>

                                            {/* OPEN ORDER */}
                                            <Route key="campaigns-primary" exact path="/v2/orders/:id">
                                                <OrderOptimizeNew
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route key="campaigns-secondary" exact path="/v2/orders/:id/campaigns">
                                                <OrderOptimizeNew
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route key="abtest" exact path="/v2/orders/:id/abtest">
                                                <OrderABTest
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route key="actions" exact path="/v2/orders/:id/actions">
                                                <OrderActions
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route key="performance" exact path="/v2/orders/:id/performance">
                                                <OrderPerformance
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route key="budgetplaner" exact path="/v2/orders/:id/budgetplaner">
                                                {
                                                    false &&
                                                    <OrderBudgetPlaner
                                                        history={this.props.history}
                                                        setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                        updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                    />
                                                }
                                                {
                                                    //false &&
                                                    <OrderBudgetPlanerTemp
                                                        history={this.props.history}
                                                        setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                        updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                    />
                                                }
                                            </Route>
                                            <Route key="import" exact path="/v2/orders/:id/campaigns/import">
                                                <OrderImportCampaigns
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route key="drafts" exact path="/v2/orders/:id/drafts">
                                                <OrderDrafts
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route key="pending-drafts" exact path="/v2/orders/:id/drafts/pending">
                                                <OrderDrafts
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route key="error-drafts" exact path="/v2/orders/:id/drafts/error">
                                                <OrderDrafts
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route exact path="/v2/orders/:id/documents">
                                                <OrderDocuments
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route exact path="/v2/orders/:id/creatives">
                                                <OrderCreatives
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route exact path="/v2/orders/:id/import">
                                                <OrderImportCampaigns
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route exact path="/v2/orders/:id/settings">
                                                <OrderOverview
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>
                                            <Route exact path="/v2/orders/:id/optimize">
                                                <OrderOptimizeNew
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => {
                                                        this.refs.navigation.functions.orderStatistics()
                                                    }}
                                                />
                                            </Route>
                                            <Route key="changelog" exact path="/v2/orders/:id/changelog">
                                                <Changelog
                                                    history={this.props.history}
                                                    setOrder={(order) => { this.refs.navigation.functions.setOrder(order) }}
                                                    updateStatistics={() => { this.refs.navigation.functions.orderStatistics() }}
                                                />
                                            </Route>

                                            {/* WIZARDS/DRAFTS */}
                                            <Route key="drafts" exact path="/v2/drafts">
                                                <WizardList
                                                    history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>

                                            {/* ACIONS */}
                                            <Route exact path="/v2/actions">
                                                <Actions
                                                    history={this.props.history}
                                                    updateStatistics={(client) => { this.refs.navigation.functions.ordersStatistics(client) }}
                                                />
                                            </Route>

                                            {/* TEMPLATES */}
                                            <Route exact path="/v2/templates">
                                                <TemplateList
                                                    history={this.props.history}
                                                    updateStatistics={(client) => { this.refs.navigation.functions.ordersStatistics(client) }}
                                                />
                                            </Route>

                                            {/* PERFORMANCE MANAGERS */}
                                            <Route exact path="/v2/performance/accounts">
                                                <AccountPerformanceList history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>

                                            {/* PERFORMANCE CLIENTS */}
                                            <Route exact path="/v2/performance/clients">
                                                <ClientPerformanceList history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>

                                            {/* PERFORMANCE MANAGERS */}
                                            <Route exact path="/v2/performance/campaigns">
                                                <CampaignPerformanceList history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>

                                            {/* CUSTOM DATASOURCES */}
                                            <Route exact path="/v2/custom-datasources/datasources">
                                                <CustomDatasourcesList history={this.props.history}
                                                    updateStatistics={(client) => { }}
                                                />
                                            </Route>

                                            {/* CUSTOM PLATFORMS */}
                                            <Route exact path="/v2/custom-datasources/platforms">
                                                <CustomPlatformsList history={this.props.history}
                                                    updateStatistics={(client) => { }}
                                                />
                                            </Route>

                                            {/* WARNINGS */}
                                            <Route exact path="/v2/warnings">
                                                <ClientWarningList history={this.props.history}
                                                    updateStatistics={(client) => {
                                                        this.refs.navigation.functions.ordersStatistics(client);
                                                        this.refs.navigation.functions.campaignsStatistics(client);
                                                    }}
                                                />
                                            </Route>

                                            {/* NEW DRAFT */}
                                            <Route exact path="/v2/campaigns/new/client">
                                                <CreateDraftClient history={this.props.history} setSteps={() => { this.refs.navigation.functions.steps() }} />
                                            </Route>
                                            <Route exact path="/v2/campaigns/new/order">
                                                <CreateDraftOrder history={this.props.history} setSteps={() => { this.refs.navigation.functions.steps() }} />
                                            </Route>
                                            <Route exact path="/v2/campaigns/new/channels">
                                                <CreateDraftChannels history={this.props.history} setSteps={() => { this.refs.navigation.functions.steps() }} />
                                            </Route>
                                            <Route exact path="/v2/campaigns/new/creatives">
                                                <CreateDraftCreatives history={this.props.history} setSteps={() => { this.refs.navigation.functions.steps() }} />
                                            </Route>
                                            <Route key="facebook" exact path="/v2/campaigns/new/facebook">
                                                <CreateDraftChannel channel={"facebook"} history={this.props.history} setSteps={() => { this.refs.navigation.functions.steps() }} />
                                            </Route>
                                            <Route key="tiktok" exact path="/v2/campaigns/new/tiktok">
                                                <CreateDraftChannel channel={"tiktok"} history={this.props.history} setSteps={() => { this.refs.navigation.functions.steps() }} />
                                            </Route>
                                            <Route key="snapchat" exact path="/v2/campaigns/new/snapchat">
                                                <CreateDraftChannel channel={"snapchat"} history={this.props.history} setSteps={() => { this.refs.navigation.functions.steps() }} />
                                            </Route>
                                            <Route key="twitter" exact path="/v2/campaigns/new/twitter">
                                                <CreateDraftChannel channel={"twitter"} history={this.props.history} setSteps={() => { this.refs.navigation.functions.steps() }} />
                                            </Route>
                                            <Route key="linkedin" exact path="/v2/campaigns/new/linkedin">
                                                <CreateDraftChannel channel={"linkedin"} history={this.props.history} setSteps={() => { this.refs.navigation.functions.steps() }} />
                                            </Route>
                                            <Route key="google" exact path="/v2/campaigns/new/google">
                                                <CreateDraftChannel channel={"google"} history={this.props.history} setSteps={() => { this.refs.navigation.functions.steps() }} />
                                            </Route>
                                            <Route exact path="/v2/campaigns/new/preview">
                                                <CreateDraftPreview history={this.props.history} setSteps={() => { this.refs.navigation.functions.steps() }} />
                                            </Route>

                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

/*
    <div className="flex flex-row flex-wrap h-full">
                <div className={(window.location.href.indexOf("v2/orders/new") !== -1 ? "md:w-20 lg:w-20" : "md:w-2/12 lg:w-2/12") + " w-full"}>
                    <SideNavigation history={this.props.history} />
                </div>
                <main className={(window.location.href.indexOf("v2/orders/new") !== -1 ? "" : "md:w-10/12 lg:w-10/12") + " w-full"}>
                    <div className={(window.location.href.indexOf("v2/orders/new") !== -1 ? "md:pl-20" : "") + " h-full"}>
                        <Switch>
                            <Route exact path="/v2/dashboard" component={Dashboard} />
                            <Route exact path="/v2/orders" component={OrderList} />
                            <Route exact path="/v2/orders/new" component={CreateOrder} />
                        </Switch>
                    </div>
                </main>
            </div>
*/

export default LoggedInAgent;
