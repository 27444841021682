import { ChevronRightIcon, InformationCircleIcon, MenuIcon, SearchIcon, PhotographIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';

class DisplayBannerBillboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: false,
            logo: false,
            headlines: [],
            bodies: [],
            cta: {},
            error: false,
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            image: this.props.image,
            logo: this.props.logo,
            headlines: this.props.headlines,
            bodies: this.props.bodies,
            cta: this.props.cta,
            main_color: this.props.main_color,
            accent_color: this.props.accent_color,
            error: this.props.error,
            subType: this.props.subType,
            preview_placement: this.props.preview_placement
        })

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            image: nextProps.image,
            logo: nextProps.logo,
            headlines: nextProps.headlines,
            bodies: nextProps.bodies,
            cta: nextProps.cta,
            main_color: nextProps.main_color,
            accent_color: nextProps.accent_color,
            error: nextProps.error,
            subType: nextProps.subType,
            preview_placement: this.props.preview_placement
        })
    }

    renders = {
        headline: () => {
            try {
                if (this.state.headlines.length > 0 && this.state.headlines[0] !== "") {
                    return this.state.headlines[0]
                } else {
                    return "Headline ..."
                }
            } catch (error) {
                return "Headline ..."
            }
        },
        body: () => {
            try {
                if (this.state.bodies.length > 0 && this.state.bodies[0] !== "") {
                    return this.state.bodies[0]
                } else {
                    return "Description ..."
                }
            } catch (error) {
                return "Description ..."
            }
        },
        image: () => {
            try {
                if (this.state.image !== "") {
                    return this.state.image
                } else {
                    return ""
                }
            } catch (error) {
                return ""
            }
        },
        logo: () => {
            try {
                if (this.state.logo !== "") {
                    return this.state.logo
                } else {
                    return ""
                }
            } catch (error) {
                return ""
            }
        },
    }

    functions = {
        truncText(string, maxLength) {
            if (string.length > maxLength) {
                return string.substring(0, maxLength) + "..."
            }
            else {
                return string
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div
                className={`${this.state.subType === "IMAGE_AD" ? "flex flex-col justify-center w-full" : "flex flex-col justify-center"}`}
                style={this.state.subType === "IMAGE_AD" ? { maxWidth: 600 } : {}}

            >
                {
                    this.state.subType == "IMAGE_AD" &&
                    <div>
                        <div className='border-2 border-gray-400 rounded-lg overflow-hidden w-full'
                            style={{ maxHeight: 500 }}
                        >
                            <div className='p-1 bg-gray-800 h-full'>
                                <div className='bg-white h-full w-full rounded-lg px-12 py-4'>
                                    <div>
                                        <img className="w-full object-cover" src={this.renders.image()} />
                                    </div>
                                    <div className='flex mt-4 space-x-4'>
                                        <div className='h-full w-full'>
                                            <div className='flex flex-col space-y-2'>
                                                <div className='bg-gray-300 w-full h-4'></div>
                                                <div className='bg-gray-300 w-full h-4'></div>
                                                <div className='bg-gray-300 w-full flex flex-col flex-1 items-center justify-center py-3'>
                                                    <PhotographIcon className='h-10 w-10 text-gray-500' />
                                                </div>
                                                <div className='bg-gray-300 w-full h-4'></div>
                                                <div className='bg-gray-300 w-full h-4'></div>
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <div className='flex h-full bg-gray-300 w-full items-center justify-center'>
                                                <PhotographIcon className='h-10 w-10 text-gray-500' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center'>
                            <div className='flex justify-center items-center h-5 bg-gray-800 w-full rounded-b-lg rounded-t-sm'>
                                <div className='h-3 rounded-b-lg bg-gray-600'
                                    style={{ width: "20%" }}></div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.subType !== "IMAGE_AD" &&
                    <div
                        style={{ maxWidth: "320px" }}
                        className="w-80 border rounded-md bg-white">
                        <div className="h-10 w-full flex flex-row bg-gray-200 items-center">
                            <div className="px-4">
                                <MenuIcon className="w-5 h-5" />
                            </div>
                            <div className="flex flex-1 justify-center items-center">
                                <div className="h-2 bg-gray-500 w-32"></div>
                            </div>
                            <div className="px-4">
                                <SearchIcon className="w-5 h-5" />
                            </div>
                        </div>
                        <div className="px-2 pt-3 mb-3">
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                        </div>
                        <div className="px-4">
                            <div className="w-full relative">
                                <div className='absolute top-0 right-0'>
                                    <div className='flex flex-col items-center justify-center w-5 h-5 bg-white'>
                                        <InformationCircleIcon className="w-5 h-5 text-google-500" />
                                    </div>
                                </div>
                                {
                                    !this.state.image &&
                                    <div className='h-40 flex flex-col items-center justify-center w-full rounded-t-lg bg-white'>
                                        <div className='text-sm'>Problem loading image data</div>
                                    </div>
                                }
                                <img
                                    style={{ maxHeight: 200 }}
                                    className="w-full object-cover"
                                    src={this.renders.image()} />
                                <div className='bg-white w-full p-2'>
                                    <div className="text-lg text-black font-bold mb-1">
                                        {this.functions.truncText(this.renders.headline(), 30)}
                                    </div>
                                    <div className='grid grid-cols-5 gap-2 items-center'>
                                        <div className='col-span-1'>
                                            {
                                                this.state.logo &&
                                                <img className='w-full' src={this.renders.logo()} />
                                            }
                                            {
                                                !this.state.logo &&
                                                <div className='border rounded-full h-10 w-10'></div>
                                            }
                                        </div>
                                        <div className='col-span-3'>
                                            <div className='text-sm text-gray-700 ml-2'>
                                                {this.functions.truncText(this.renders.body(), 90)}
                                            </div>
                                        </div>
                                        <div className='col-span-1 flex justify-center items-center'>
                                            <div className="w-8 h-8 bg-purple-500 rounded-full flex items-center justify-center shadow">
                                                <ChevronRightIcon className="w-5 h-5 text-white" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 pt-3">
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                            <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default DisplayBannerBillboard;
