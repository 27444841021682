import React, {Component} from 'react';
import './style.css';
import Menu from "../../components/menu";
import AdvertisingCreativesFacebookImage from "../../components/advertising-creatives-facebook-image";
import AdvertisingCreativesFacebookVideo from "../../components/advertising-creatives-facebook-video";
import AdvertisingCreativesFacebookCarousel from "../../components/advertising-creatives-facebook-carousel";
import AdvertisingCreativesInstagramImage from "../../components/advertising-creatives-instagram-image";
import AdvertisingCreativesInstagramVideo from "../../components/advertising-creatives-instagram-video";
import AdvertisingCreativesInstagramCarousel from "../../components/advertising-creatives-instagram-carousel";
import AdvertisingPreviewSearch from "../../components/advertising-preview-search";
import AccordionFixed from "../../components/accordion-fixed";
import FeatherIcon from "feather-icons-react";
const queryString = require('query-string');

class PreviewPdf extends Component {

    constructor(props) {
        super(props);
        this.state = {
            body: "",
            channels: {},
            page: {},
            site: {},
            creatives: []
        };
    };

    componentWillMount(){
        this.init.data();
    }

    init = {
        data: () => {
            try{
                let query = window.location.search;
                if(query){
                    query = queryString.parse(query);
                    this.setState({
                        body: query.body,
                        headline: query.headline,
                        headline_second: query.headline_second,
                        headline_third: query.headline_third,
                        search_body: query.search_body,
                        search_body_second: query.search_body_second,
                        logo: query.logo,
                        text: query.text,
                        name: query.name,
                        channels: query.channels?JSON.parse(query.channels):{},
                        site: query.site?JSON.parse(query.site):{},
                        page: query.page?JSON.parse(query.page):{},
                        creatives: query.creatives?JSON.parse(query.creatives):[],
                        ad_templates: query.ad_templates?JSON.parse(query.ad_templates):{},
                        keywords: query.keywords?JSON.parse(query.keywords):[]
                    })
                }
            }catch (e) {
                console.log(e);
            }
        }
    };

    functions = {};

    renders = {
        search: () => {
            try{
                let templates = [];
                if(this.state.ad_templates.search_one){
                    templates.push(
                        {ad_template: "search_one"}
                    );
                };
                for(let i = 0; i < this.state.keywords.length; i++){
                    if(this.state.ad_templates.search_two){
                        templates.push(
                            {ad_template: "search_two", keyword: this.state.keywords[i]}
                        );
                    }
                    if(this.state.ad_templates.search_three){
                        templates.push(
                            {ad_template: "search_three", keyword: this.state.keywords[i]}
                        );
                    }
                    if(this.state.ad_templates.search_four){
                        templates.push(
                            {ad_template: "search_four", keyword: this.state.keywords[i]}
                        );
                    }
                }
                return templates
            }catch (e) {
                return []
            }
        }
    };

    render(){
        return (
            <div style={{width: '100%'}}>
                {
                    <div className="preview">
                        {
                            this.state.logo &&
                            <div className="preview-logo">
                                <img style={{width: '100%'}} src={this.state.logo}/>
                            </div>
                        }
                        {
                            (this.state.name || this.state.text) &&
                            <div className="preview-item">
                                {
                                    this.state.name &&
                                    <div className="preview-name">
                                        {this.state.name}
                                    </div>
                                }
                                {
                                    this.state.text &&
                                    <div className="preview-text">
                                        {this.state.text}
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                {
                    this.state.creatives.filter((item) => {return item.show && item.image}).length > 0 && this.state.channels.facebook &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.facebook_image}
                            title={"Facebook image ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    facebook_image: !this.state.facebook_image
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.state.creatives.filter((item) => {
                                        return !item.button && !item.error && item.show && item.image
                                    }).map((item) => {
                                        let creatives = [];
                                        creatives.push(item);
                                        return (
                                            <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                <AdvertisingCreativesFacebookImage
                                                    page={this.state.page}
                                                    creatives={creatives}
                                                    url={this.state.site.hostname}
                                                    description={this.state.site.description}
                                                    noContainer={true}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.state.creatives.filter((item) => {return item.show && item.video}).length > 0 && this.state.channels.facebook &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.facebook_video}
                            title={"Facebook video ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    facebook_video: !this.state.facebook_video
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.state.creatives.filter((item) => {
                                        return !item.button && !item.error && item.show && item.video
                                    }).map((item) => {
                                        let creatives = [];
                                        creatives.push(item);
                                        return (
                                            <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                <AdvertisingCreativesFacebookVideo
                                                    page={this.state.page}
                                                    creatives={creatives}
                                                    url={this.state.site.hostname}
                                                    description={this.state.site.description}
                                                    noContainer={true}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.state.creatives.filter((item) => {return item.carousel_show}).length > 0 && this.state.channels.facebook &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.facebook_carousel}
                            title={"Facebook carousel ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    facebook_carousel: !this.state.facebook_carousel
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                        <AdvertisingCreativesFacebookCarousel
                                            page={this.state.page}
                                            content={this.state.creatives}
                                            body={this.state.body}
                                            noContainer={true}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.state.creatives.filter((item) => {return item.show && item.image}).length > 0 && this.state.channels.instagram &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.instagram_image}
                            title={"Instagram image ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    instagram_image: !this.state.instagram_image
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.state.creatives.filter((item) => {
                                        return !item.button && !item.error && item.show && item.image
                                    }).map((item) => {
                                        let creatives = [];
                                        creatives.push(item);
                                        return (
                                            <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                <AdvertisingCreativesInstagramImage
                                                    page={this.state.page}
                                                    creatives={creatives}
                                                    url={this.state.site.hostname}
                                                    description={this.state.site.description}
                                                    noContainer={true}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.state.creatives.filter((item) => {return item.show && item.video}).length > 0 && this.state.channels.instagram &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.instagram_video}
                            title={"Instagram video ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    instagram_video: !this.state.instagram_video
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.state.creatives.filter((item) => {
                                        return !item.button && !item.error && item.show && item.video
                                    }).map((item) => {
                                        let creatives = [];
                                        creatives.push(item);
                                        return (
                                            <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                                <AdvertisingCreativesInstagramVideo
                                                    page={this.state.page}
                                                    creatives={creatives}
                                                    url={this.state.site.hostname}
                                                    description={this.state.site.description}
                                                    noContainer={true}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.state.creatives.filter((item) => {return item.carousel_show}).length > 0 && this.state.channels.instagram &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.instagram_carousel}
                            title={"Instagram carousel ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    instagram_carousel: !this.state.instagram_carousel
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                        <AdvertisingCreativesInstagramCarousel
                                            page={this.state.page}
                                            content={this.state.creatives}
                                            body={this.state.body}
                                            noContainer={true}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>
                    </div>
                }
                {
                    this.state.channels.google &&
                    <div className="preview">
                        <AccordionFixed
                            open={this.state.google_search}
                            title={"Google ads"}
                            edit={true}
                            onEdit={() => {
                                this.setState({
                                    google_search: !this.state.google_search
                                })
                            }}
                        >
                            <div className="preview-Items">
                                {
                                    this.renders.search().map((item) => {return (
                                        <div style={{marginBottom: '0px', marginRight: '15px', marginTop: '15px'}}>
                                            <AdvertisingPreviewSearch
                                                site={this.state.site}
                                                targeting={this.state.targeting}
                                                selected={item.ad_template}
                                                selectedKeyword={item.keyword}
                                                creatives={{
                                                    title: this.state.headline,
                                                    title_second: this.state.headline_second,
                                                    title_third: this.state.headline_third,
                                                    search_body: this.state.search_body,
                                                    search_body_second: this.state.search_body_second
                                                }}
                                                noContainer={true}
                                            />
                                        </div>
                                    )})
                                }
                            </div>
                            <div className="preview-Scroll">
                                <div className="preview-Scroll-Text">
                                    Scroll right
                                </div>
                                <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                            </div>
                        </AccordionFixed>

                    </div>
                }
            </div>
        )
    }
}

export default PreviewPdf;
