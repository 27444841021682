import React, { Component, Fragment } from 'react';
import AdvertisingCreativesFacebookVideo from '../components/advertising-creatives-facebook-video';
import AdvertisingCreativesInstagramVideo from '../components/advertising-creatives-instagram-video';
import AdvertisingCreativesFacebookImage from '../components/advertising-creatives-facebook-image';
import AdvertisingCreativesInstagramImage from '../components/advertising-creatives-instagram-image';
import AdvertisingCreativesFacebookCarousel from '../components/advertising-creatives-facebook-carousel';
import AdvertisingCreativesInstagramCarousel from '../components/advertising-creatives-instagram-carousel';
import AdvertisingCreativesVertical from '../components/advertising-creatives-vertical';
import PreviewTikTokFeed from '../previews/tiktok/feed';
import PreviewTwitterCarousel from '../previews/twitter/carousel';
import PreviewTwitterFeed from '../previews/twitter/feed';

class PreviewSectionTwitter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ad: {},
            channel: {},
            for_report: false,
            preview_placement: ""
        };
    }

    componentWillMount() {
    }

    async componentDidMount() {
        await this.promisedSetState({
            ad: this.props.ad,
            channel: this.props.channel,
            for_report: this.props.for_report,
            preview_placement: this.props.preview_placement
        });
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            ad: nextProps.ad,
            channel: nextProps.channel,
            for_report: nextProps.for_report,
            preview_placement: nextProps.preview_placement
        });
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    functions = {

    }

    renders = {
        title: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.headlines)) {
                        let headline = "";
                        item.headlines.map((item) => {
                            headline = item.value;
                        });
                        return headline;
                    }
                } else if ('title' in item) {
                    return item.title;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        body: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.bodies)) {
                        let body = "";
                        item.bodies.map((item) => {
                            body = item.value;
                        });
                        return body;
                    }
                } else if ('body' in item) {
                    return item.body;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        description: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.descriptions)) {
                        let description = "";
                        item.descriptions.map((item) => {
                            description = item.value;
                        });
                        return description;
                    }
                } else if ('description' in item) {
                    return item.description;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        caption: (item) => {
            try {
                if ('caption' in item) {
                    return item.caption;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        link: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.links)) {
                        let link = "";
                        item.links.map((item) => {
                            link = item.value;
                        });
                        return link;
                    }
                } else if ('link' in item) {
                    return item.link;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        cta: (item) => {
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.call_to_actions)) {
                        let cta = { id: 0, name: "No Button", type: "NO_BUTTON" };
                        item.call_to_actions.map((item) => {
                            cta = item.value;
                        });
                        return cta;
                    }
                } else if (item.cta) {
                    return item.cta;
                } else {
                    return { id: 0, name: "No Button", type: "NO_BUTTON" }
                }
            } catch (error) {
                return { id: 0, name: "No Button", type: "NO_BUTTON" };
            }
        },
        page: (item) => {
            try {
                if (item.page) {
                    return item.page
                } else {
                    return { id: 0, name: "Select page" };
                }
            } catch (error) {
                return { id: 0, name: "Select page" };
            }
        },
        creative: (item, placement_format) => {
            let creative = null;
            try {
                if (this.state.for_report && this.state.ad.type === "carousel") {
                    return item.items
                }
            } catch (eror) {
                console.log(eror)
            }
            try {
                if (this.state.for_report && item.type === "video") {
                    creative = item.video;
                } else if (this.state.for_report && item.type === "image") {
                    creative = item.image;
                }
            } catch (eror) {
                console.log(eror)
            }
            try {
                if (item.ad_type && item.ad_type.value === "video_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "video" && !item.files)) {
                    if (item.video) {
                        creative = { thumbnail: item.thumbnail ? item.thumbnail : "", video: item.video };
                    }
                }
            } catch (eror) {
                console.log(eror)

            }
            try {
                if (item.ad_type && item.ad_type.value === "image_ad" || (item.ad_type && item.ad_type.value === "posts" && item.ad_type.type === "image" && !item.files)) {
                    if (item.picture && item.picture !== "") {
                        creative = item.picture;
                    }
                }
            } catch (eror) {
                console.log(eror)

            }
            try {
                if (item.type === "posts" && (item.ad_type === "image" || item.ad_type === "video")) {
                    if (item.ad_type === "image" && item.picture && item.picture !== "") {
                        creative = item.picture;
                    }
                    if (item.ad_type === "video") {
                        creative = item.url;
                    }
                }
            } catch (error) {
                console.log(error)
            }
            try {
                if ((item.ad_type && item.ad_type.value === "posts" && item.files)) {
                    if (Array.isArray(item.files)) {
                        creative = item.files.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: (item.type === "VIDEO" || item.type === "video") ? true : false,
                                image: (item.type === "IMAGE" || item.type === "image") ? true : false,
                                loading: item.loading,
                                creative: {
                                    thumbnail: { url: item.thumbnail ? item.thumbnail : "" },
                                    url: item.url,
                                    ratio: "1:1"
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "carousel_ad") {
                    if (Array.isArray(item.slides)) {
                        creative = item.slides.map((item) => {
                            return {
                                headline: item.headline,
                                description: item.body,
                                video: item.video_id || item.video,
                                image: !item.video_id && !item.video,
                                loading: item.loading,
                                creative: { thumbnail: { url: item.thumbnail }, url: item.url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "merge_ad") {
                    if (Array.isArray(item.slides)) {
                        item.slides.map((slide) => {
                            if (item.preview && item.preview.ratio === slide.ratio) {
                                if (slide.type === 'image') {
                                    creative = slide.picture;
                                } else {
                                    creative = { thumbnail: slide.thumbnail, video: slide.url };
                                }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "catalog_ad") {
                    if (Array.isArray(item.preview_products)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            try {
                if (item.ad_type && item.ad_type.value === "search_ad") {
                    if (Array.isArray(item.combinations)) {
                        creative = item.preview_products.map((item) => {
                            return {
                                headline: item.name,
                                description: item.price,
                                image: true,
                                loading: item.loading,
                                cta: item.cta,
                                creative: { url: item.image_url, ratio: "1:1" }
                            }
                        });
                    }
                }
            } catch (eror) {
            }
            return creative;
        }
    }

    render() {

        let ad = this.state.ad;

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>
                {
                    !this.state.for_report && ad.preview && ad.preview.value === "carousel_feed" &&
                    <PreviewTwitterCarousel
                        page={this.renders.page(ad)}
                        body={this.renders.body(ad)}
                        content={{
                            body: this.renders.body(ad),
                            items: this.renders.creative(ad)
                        }}
                        ad={ad}
                        is_post={ad.type === "posts" ? true : ad.ad_type.value === "posts" ? true : false}


                    />
                }
                {
                    !this.state.for_report && ad.preview && (ad.preview.value === "image_feed" || ad.preview.value === "video_feed") &&
                    <PreviewTwitterFeed
                        video={ad.type !== "posts" && ad.preview.value === "video_feed" ? this.renders.creative(ad).video : ad.type === "posts" && ad.preview.value === "video_feed" ? this.renders.creative(ad) : null}
                        image={ad.preview.value === "image_feed" ? this.renders.creative(ad) : null}
                        link={this.renders.link(ad)}
                        body={this.renders.body(ad)}
                        headline={this.renders.title(ad)}
                        page={this.renders.page(ad)}
                        ad={ad}
                        is_post={ad.type === "posts" ? true : ad.ad_type.value === "posts" ? true : false}

                    />
                }

                {/* FOR REPORT */}
                {
                    this.state.for_report &&
                    this.state.preview_placement === "feed" &&
                    this.state.ad && this.state.ad.type !== "carousel" &&
                    <PreviewTwitterFeed
                        video={ad.type === "video" ? this.renders.creative(ad) : null}
                        image={ad.type === "image" ? this.renders.creative(ad) : null}
                        link={this.renders.link(ad)}
                        body={this.renders.body(ad)}
                        headline={this.renders.title(ad)}
                        page={this.renders.page(ad)}
                        for_report={this.state.for_report}
                        is_post={this.state.ad && this.state.ad.is_post ? true : false}


                    />
                }
                {
                    this.state.for_report &&
                    this.state.preview_placement === "feed" &&
                    this.state.ad && this.state.ad.type === "carousel" &&
                    <PreviewTwitterCarousel
                        page={this.renders.page(ad)}
                        body={this.renders.body(ad)}
                        link={this.renders.link(ad)}
                        content={{
                            body: this.renders.body(ad),
                            items: this.renders.creative(ad)
                        }}
                        for_report={this.state.for_report}
                        is_post={this.state.ad && this.state.ad.is_post ? true : false}

                    />
                }

                {/* IMAGE NO FRAME */}
                {
                    this.state.for_report &&
                    this.state.ad && this.state.ad.image &&
                    this.state.preview_placement === "no_frame" &&
                    <div>
                        <img
                            className="h-full object-contain"
                            src={this.renders.creative(ad)}
                            alt={"Creative media"}
                        />
                    </div>
                }

                {/* VIDEO NO FRAME */}
                {
                    this.state.for_report &&
                    this.state.ad.video &&
                    this.state.preview_placement === "no_frame" &&
                    <div>
                        <video className="w-full h-full" autoplay={false} controls={true} loop={true} muted={true}>
                            <source src={this.renders.creative(ad)} type="video/mp4" />
                        </video>
                    </div>
                }

                {/* CAROUSEL NO FRAME */}
                {
                    this.state.for_report &&
                    this.state.preview_placement === "no_frame" &&
                    this.state.ad && this.state.ad.type === "carousel" &&
                    <div
                        id={"carousel_container"}
                        className='flex flex-col overflow-x-auto justify-center cursor-pointer select-none'>
                        <div className='flex space-x-1'>
                            {
                                Array.isArray(ad.items) && ad.items.length > 0 &&
                                ad.items.map((slide, index) => {
                                    return (
                                        <span key={index}
                                            className='flex min-w-68 rounded'>
                                            {slide.type === "IMAGE" &&
                                                <img src={slide.image} alt={slide.name ? slide.name : "Alt text"}
                                                    className="w-full overflow-hidden rounded"
                                                />
                                            }
                                            {slide.type === "VIDEO" &&
                                                <video src={slide.url} loop autoplay={false} controls={true}
                                                    className="h-full w-full overflow-hidden rounded"
                                                />
                                            }
                                        </span>
                                    )
                                }
                                )
                            }
                        </div>
                    </div>
                }
            </>
        )
    }
}

export default PreviewSectionTwitter;
