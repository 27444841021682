import React from "react";

function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1369.5 231.16">
      <defs>
        <linearGradient
          id="adgradient_14"
          x1="248.56"
          x2="254.53"
          y1="214.38"
          y2="47.61"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d9d0f7"></stop>
          <stop offset="1" stopColor="#babaf2"></stop>
        </linearGradient>
        <linearGradient
          id="adgradient_5"
          x1="72.96"
          x2="211.93"
          y1="225.94"
          y2="24.79"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#b7b8fe"></stop>
          <stop offset="1" stopColor="#d1d0f2"></stop>
        </linearGradient>
      </defs>
      <g data-name="Lager 2">
        <g data-name="Layer 1">
          <path
            d="M207.33 230.66l-.49-205.53L298.41 0l.39 164.93s.16 65.52-60.36 65.66z"
            className="adcredo-1"
          ></path>
          <path
            d="M0 231.16l61-.16s51.07-.74 80.88-37.56S298.41 0 298.41 0l-61.66.15S186.3-.36 149 45.82 0 231.16 0 231.16z"
            className="adcredo-2"
          ></path>
          <path
            d="M453.44 43.69l55.06 140.18-30.92 3.81-12.07-32h-52.94l-11.86 32h-31.13l55.48-144zm-14.82 40.44l-17.16 46.59h34.73zm148.23 103.55h-55.7v-144h55.7q33.66 0 50.39 20T654 114.84q0 33.45-18 53.15t-49.15 19.69zm.42-118.37h-25.2v93h23.72q16.08 0 26-11.12t10-36.31q0-21-8.47-33.25t-26.05-12.32zm158.39-2.54q-15.67 0-24.77 13.34t-9.11 36q0 20.76 8.26 34.62t25 13.87a64.69 64.69 0 0016.52-2 76.39 76.39 0 0013.76-5L787 180.91a79 79 0 01-19.95 7.09 97.47 97.47 0 01-21.39 2.65q-34.1 0-50.18-21.39t-16.1-52.94q0-33.24 17.47-54.42t49.24-21.18a94.08 94.08 0 0119.16 2.44 69.41 69.41 0 0120.44 7.73l-9.53 21.81q-10-3.18-15.89-4.56a66.27 66.27 0 00-14.61-1.37zm155.22 120.91q-7.2-12.7-14.19-25.83t-14-25.84a40.28 40.28 0 01-4.24.22h-21.6v51.45H816v-144h50.19q23.72 0 40.77 11t17 34.94q0 13.33-6.46 23a44.79 44.79 0 01-16.41 14.93q8.48 15 17 30.18t17 30zM865.09 69.1h-18.21v42.14h18.64q11.22 0 18.52-5.19t7.31-15.57q0-11.22-7-16.3t-19.26-5.08zm195.46 118.58H958.27v-144h89.14q.85 6.14 1.91 12.28t2.12 12.28h-62.26V103h40.45l4.23 24.56h-44.68v36h67.13zm81.1 0H1086v-144h55.7q33.66 0 50.39 20t16.73 51.14q0 33.45-18 53.15t-49.17 19.71zm.42-118.37h-25.2v93h23.72q16.08 0 26-11.12t10-36.31q0-21-8.47-33.25t-26.05-12.32zm159.67-28.8q34.3 0 51 21.71t16.73 52.62q0 33.45-17.9 54.63t-49.86 21.18q-33.88 0-50.72-21.71t-16.84-52.62q0-33.24 17.9-54.42t49.69-21.39zm-.22 124.09q16.53 0 26.16-12.28t9.63-37.48q0-21-8.36-34.52t-26.79-13.55q-15.89 0-25.73 13.34t-9.85 36q0 21.18 8.37 34.83t26.57 13.66z"
            className="adcredo-3"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Logo;
