import React, {Component} from 'react';
import './style.css';
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Switch from "react-ios-switch";
import Input from "../input";
import Accordion from "../accordion";
import Datepicker from "../datepicker";
import SweetAlert from "sweetalert-react";
import Loader from "../loader";
import {calls} from "./calls";
var moment = require('moment');

class CampaignEditDuration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            duration: {},
            init_duration: {},
            errors: [],
            loading: false
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign,
            duration: this.props.duration,
            init_duration: JSON.parse(JSON.stringify(this.props.duration))
        })
    }

    init = {};

    functions = {
        update: () => {
            if(this.renders.change()){
                this.setState({
                    loading: true
                }, () => {
                    let start_date = this.state.duration.start_date;
                    let end_date = this.state.duration.end_date;
                    calls.updateDuration(this.state.campaign, {id: this.state.campaign, start_date: start_date, end_date: end_date}).then((response) => {
                        this.state.errors = [];
                        this.setState({
                            loading: false,
                            duration: {start_date: start_date, end_date: end_date},
                            init_duration: {start_date: start_date, end_date: end_date},
                            errors: this.state.errors,
                            success_modal: this.state.errors.length < 1
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    })
                })
            }else{
                this.setState({
                    warning_modal: true
                })
            }
        }
    };

    renders = {
        change: () => {
            try{
                //console.log(this.state.duration.start_date);
                //console.log(this.state.init_duration.start_date);
                //console.log(moment(this.state.init_duration.start_date).isSame(this.state.duration.start_date));
                return (!moment(this.state.init_duration.start_date).isSame(this.state.duration.start_date)) || (!moment(this.state.init_duration.end_date).isSame(this.state.duration.end_date));
            }catch (e) {
                //console.log(e);
                return false
            }
        }
    };

    render() {
        return (
            <div className='campaign-edit-duration'>
                {
                    this.state.loading &&
                    <Loader
                        title={'Updating budget'}
                    />
                }
                <SweetAlert
                    show={this.state.success_modal}
                    title="Date updated"
                    type="success"
                    text={"Click OK to continue"}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({success_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.warning_modal}
                    title="No change to duration"
                    type="warning"
                    text={"Click OK to continue"}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({warning_modal: false});
                    }}
                />
                <div className='campaign-edit-duration-top'>
                    DURATION
                </div>
                <div className="campaign-edit-duration-inner">
                    <Datepicker
                        size={'full'}
                        showTime={true}
                        disabled={false}
                        startDate={this.state.duration.start_date}
                        endDate={this.state.duration.end_date}
                        onStartDate={(value) => {
                            this.state.duration.start_date = value;
                            this.setState({
                                duration: this.state.duration
                            });
                        }}
                        onEndDate={(value) => {
                            this.state.duration.end_date = value;
                            this.setState({
                                duration: this.state.duration
                            });
                        }}
                    />
                    {
                        this.state.errors.length > 0 &&
                        <div style={{marginTop: '15px'}} className="SectionError">
                            {
                                this.state.errors.map((item) => {
                                    return(
                                        <div><b>{item.channel}</b>{" "}{item.message}</div>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        <div onClick={() => {
                            if(!this.state.loading){
                                this.functions.update();
                            }
                        }} style={{marginTop: '15px'}} className="Button ButtonFull ButtonNeutral">
                            <div>Update duration</div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default CampaignEditDuration;
