import React, { Component } from 'react';
import { BeatLoader } from "react-spinners";
import './style.css';

import Input from "../../../components/input";
import AccordionStatic from "../../../components/accordion-static";
import Dropdown from "../../../components/dropdown";
import UploadImage from "../../../components/image-upload";
import { calls } from "./calls";
import FeatherIcon from "feather-icons-react";
import Loader from "../../../components/loader";

class AgencyEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agency: {},
            loading: true,
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.functions.agency();
    }

    init = {};

    functions = {
        agency: () => {
            this.setState({
                loading: true,
            }, () => {
                calls.getAgency(this.props.match.params.id).then((response) => {
                    this.setState({
                        agency: response.data
                    }, () => {

                        if (!this.state.agency.status) {
                            this.state.agency.status = { name: "Active", value: "active" };
                        }

                        if (this.state.agency.showPremium) {
                            this.state.agency.premium = { name: "Enabled", value: true };
                        } else {
                            this.state.agency.premium = { name: "Disabled", value: false };
                        }

                        if (!this.state.agency.test) {
                            this.state.agency.test = { id: 0, name: "Live account", value: false };
                        } else {
                            this.state.agency.test = { id: 1, name: "Test account", value: true };
                        }

                        this.renders.stats().map((item) => {
                            if (item.value === this.state.agency.status) {
                                this.state.agency.status = item;
                            }
                        });
                        if (!this.state.agency.fee) {
                            this.state.agency.fee = 0;
                        }
                        this.renders.fees().map((item) => {
                            if (+item.value === +this.state.agency.fee) {
                                this.state.agency.fee = item;
                            }
                        });
                        this.setState({
                            loading: false,
                            agency: this.state.agency
                        })
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            });
        },
        update: () => {
            this.setState({
                loading_update: true,
            }, () => {
                let update_agency = JSON.parse(JSON.stringify(this.state.agency));
                update_agency.fee = update_agency.fee.value;
                update_agency.status = update_agency.status.value;
                update_agency.show_premium = update_agency.premium.value;
                update_agency.test = update_agency.test.value;
                calls.updateAgency(update_agency).then((response) => {
                    this.setState({
                        agency: response.data
                    }, () => {
                        if (!this.state.agency.test) {
                            this.state.agency.test = { id: 0, name: "Live account", value: false };
                        } else {
                            this.state.agency.test = { id: 1, name: "Test account", value: true };
                        }
                        if (!this.state.agency.status) {
                            this.state.agency.status = { name: "Active", value: "active" };
                        }
                        if (this.state.agency.showPremium) {
                            this.state.agency.premium = { name: "Enabled", value: true };
                        } else {
                            this.state.agency.premium = { name: "Disabled", value: false };
                        }
                        this.renders.stats().map((item) => {
                            if (item.value === this.state.agency.status) {
                                this.state.agency.status = item;
                            }
                        });
                        if (!this.state.agency.fee) {
                            this.state.agency.fee = 0;
                        }
                        this.renders.fees().map((item) => {
                            if (+item.value === +this.state.agency.fee) {
                                this.state.agency.fee = item;
                            }
                        });
                        this.setState({
                            loading_update: false,
                            agency: this.state.agency
                        })
                    })
                }, (error) => {
                    this.setState({
                        loading_update: false
                    })
                });
            });
        }
    };

    renders = {
        fees: () => {
            let fees = [];
            for (let i = 0; i < 101; i++) {
                fees.push({ name: i + '%', value: i });
            }
            return fees;
        },
        stats: () => {
            let stats = [
                { name: "Active", value: "active" },
                { name: "Disabled", value: "disabled" }
            ];
            return stats;
        },
        premium: () => {
            let stats = [
                { name: "Active", value: true },
                { name: "Disabled", value: false }
            ];
            return stats;
        }
    };

    validators = {
        email: (value) => {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(value).toLowerCase());
        }
    };

    render() {
        return (
            <div className="">
                {
                    this.state.loading_update &&
                    <Loader
                        title={"Updating user"}
                    />
                }
                {
                    this.state.loading &&
                    <div className="background-Loader">
                        <div className="justify-center align-middle flex flex-col text-center">
                            <div className="font-bold mb-2">Loading Agency ...</div>
                            <BeatLoader
                                sizeUnit={"px"}
                                size={17}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="flex items-center justify-center mb-4 mt-8">
                        <div className="flex flex-1">
                            <div>
                                <h3 className="mb-0 font-bold">
                                    Edit agency
                                </h3>
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    #{this.state.agency._id}
                                </p>
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.state.loading &&
                    <div className="bg-white rounded-md p-4 mb-8 shadow-xl">
                        <div className="flex flex-row justify-between mb-4">
                            <Input
                                title={'Name'}
                                value={this.state.agency.name}
                                error={this.state.error === "name" && this.state.agency.name.length < 1}
                                type={"text"}
                                maxLength={40}
                                onChange={(value) => {
                                    this.state.agency.name = value.target.value;
                                    this.setState({
                                        agency: this.state.agency
                                    })
                                }}
                            />
                            <Input
                                title={'Email'}
                                value={this.state.agency.email}
                                error={this.state.error === "email" && !this.validators.email(this.state.agency.email)}
                                placeholder={""}
                                type={"text"}
                                onChange={(value) => {
                                    this.state.agency.email = value.target.value;
                                    this.setState({
                                        agency: this.state.agency
                                    })
                                }}
                            />
                        </div>
                        <div className="flex flex-row justify-between mb-4">
                            <Input
                                title={'Company name'}
                                value={this.state.agency.company}
                                size={'half'}
                                error={this.state.error === "company" && this.state.agency.company.length < 1}
                                placeholder={""}
                                type={"text"}
                                onChange={(value) => {
                                    this.state.agency.company = value.target.value;
                                    this.setState({
                                        agency: this.state.agency
                                    })
                                }}
                            />
                            <Dropdown
                                filter={false}
                                title={'System fee'}
                                size={'half'}
                                placeholder={"Select system fee"}
                                error={this.state.error === "fee" && !this.state.agency.fee}
                                options={this.renders.fees()}
                                value={this.state.agency.fee ? this.state.agency.fee : {}}
                                onChange={(value) => {
                                    this.state.agency.fee = value;
                                    this.setState({
                                        agency: this.state.agency
                                    })
                                }}
                            />
                        </div>
                        <div className="flex flex-row mb-4">
                            <Dropdown
                                filter={false}
                                title={'Status'}
                                size={'full'}
                                placeholder={"Select system fee"}
                                error={this.state.error === "fee" && !this.state.agency.status}
                                options={this.renders.stats()}
                                value={this.state.agency.status ? this.state.agency.status : {}}
                                onChange={(value) => {
                                    this.state.agency.status = value;
                                    this.setState({
                                        agency: this.state.agency
                                    })
                                }}
                            />
                        </div>
                        <div className="flex flex-row mb-4">
                            <Dropdown
                                filter={false}
                                title={'Show advanced report'}
                                size={'full'}
                                placeholder={"Select system fee"}
                                options={this.renders.premium()}
                                value={this.state.agency.premium ? this.state.agency.premium : {}}
                                onChange={(value) => {
                                    this.state.agency.premium = value;
                                    this.setState({
                                        agency: this.state.agency
                                    })
                                }}
                            />
                        </div>
                        <div className="flex flex-row mb-4">
                            <Dropdown
                                filter={false}
                                title={'Account type'}
                                size={'full'}
                                placeholder={"Select type"}
                                options={[
                                    { id: 0, name: "Live account", value: false },
                                    { id: 1, name: "Test account", value: true }
                                ]}
                                value={this.state.agency.test && this.state.agency.test.value ? { id: 1, name: "Test account", value: true } : { id: 0, name: "Live account", value: false }}
                                onChange={(value) => {
                                    this.state.agency.test = value;
                                    this.setState({
                                        agency: this.state.agency
                                    })
                                }}
                            />
                        </div>
                        <div className="mt-4 justify-end flex">
                            <button onClick={() => {
                                this.functions.update();
                            }}
                                className={"flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 bg-purple-500 bg-opacity-100 text-white"}>
                                <span>Update agency</span>
                            </button>
                        </div>
                        {
                            false &&
                            <div className="">
                                <div onClick={() => {
                                    this.functions.update();
                                }} className="ButtonFull Button">
                                    {
                                        !this.state.loading &&
                                        <div>Update</div>
                                    }
                                    {
                                        this.state.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default AgencyEdit;
