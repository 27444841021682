import {apiRegister} from "../../../services/apiRegister";
import {tokenRegister} from "../../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getDraft(id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/draft/" + id + '?campaign=true';
        return apiRegister.call(options, url);
    }

    static getCampaign(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/campaign/" + data.id + "?insights=true&information=true";
        return apiRegister.call(options, url);
    }

    static getAdgroups(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/adgroup/" + data.id + "?channel=" + data.channel + "&campaign=" + data.campaign +"&insights=true";
        return apiRegister.call(options, url);
    }

    static getAds(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/ad/" + data.id + "?channel=" + data.channel + "&campaign=" + data.campaign + "&adgroup=" + data.adgroup +"&insights=true&creative=true";
        return apiRegister.call(options, url);
    }

    static getAd(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/ad/" + data.id + "?channel=" + data.channel + "&campaign=" + data.campaign + "&ad=" + data.ad;
        return apiRegister.call(options, url);
    }

    static updateCampaign(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + "/v2/campaign/" + data.id;
        return apiRegister.call(options, url);
    }

    static updateAdgroup(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + "/v2/adgroup/" + data.id;
        return apiRegister.call(options, url);
    }

    static updateAd(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + "/v2/ad/" + data.id;
        return apiRegister.call(options, url);
    }

    static removeCampaign(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
        let url = calls.url.api + "/v2/campaign/" + data.id;
        return apiRegister.call(options, url);
    }

    static removeAdgroup(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
        let url = calls.url.api + "/v2/adgroup/" + data.id;
        return apiRegister.call(options, url);
    }

    static removeAd(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
        let url = calls.url.api + "/v2/ad/" + data.id;
        return apiRegister.call(options, url);
    }

    static removeInternalCampaign(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/v2/internal/" + data.id;
        return apiRegister.call(options, url);
    }

    static getAllExternalCampaigns(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/import?campaign=" + data.id;
        return apiRegister.call(options, url);
    }

    static getAllInternalCampaigns() {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/internal?page=" + 0 +'&sort=' + "name" + '&order=' + "asc" + '&page_size=' + 10000000000000;
        return apiRegister.call(options, url);
    }

    static updateStatusInternalCampaign(id, data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + "/v2/internal/" + id;
        return apiRegister.call(options, url);
    }

    static importCampaign(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/import";
        return apiRegister.call(options, url);
    }

    static addKeyword(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/keywords";
        return apiRegister.call(options, url);
    }

    static removeCampaignReference(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
        let url = calls.url.api + "/v2/import/" + data.id + "?channel=" + data.channel + "&external_campaign=" + data.campaign;
        return apiRegister.call(options, url);
    }

}

export {calls}