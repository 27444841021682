import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";
import AccordionStatic from "../../components/accordion-static";
import AccordionFixed from "../../components/accordion-fixed";
import Dropdown from "../../components/dropdown";
import SlideDown from "react-slidedown";
import Datepicker from "../../components/datepicker";
import {Doughnut} from "react-chartjs-2";
import Switch from "react-ios-switch";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import queryString from "query-string";
import Carousel from "re-carousel";
import FeatherIcon from "feather-icons-react";
import cx from "classnames";
import ReactTooltip from "react-tooltip";

class OptimizeSearchKeywordsPositiveItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: this.props.campaign ? this.props.campaign : {},
            item: this.props.item ? this.props.item : {},
            sort: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign ? nextProps.campaign : {},
            item: nextProps.item ? nextProps.item : {},
            sort: nextProps.sort
        })
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign ? this.props.campaign : {},
            item: this.props.item ? this.props.item : {},
            sort: this.props.sort
        });
    }

    init = {};

    functions = {
        changeStatus: () => {
            this.setState({
                loading: true
            }, () => {
                let status = null;
                if(this.state.item.status === 'paused'){
                    status = 'active'
                }else{
                    status = 'paused'
                }
                calls.changeStatus({
                    channels: {google: true},
                    campaign_id: this.state.campaign.id,
                    adgroup_id: this.state.item.adgroup_id,
                    status: status
                }).then((response) => {
                    this.state.item.status = status;
                    this.setState({
                        item: this.state.item,
                        loading: false
                    })
                }, (error) => {
                    this.setState({
                        loading: false,
                        error_message: error.body.message,
                        error_modal: true
                    })
                });
            })
        }
    };

    renders = {
        width: (key) => {
            if (key === 'text') {
                return {width: '270px', minWidth: '270px'}
            } else if (key === 'type') {
                return {width: '65px', minWidth: '65px'}
            } else if (key === 'level') {
                return {width: '100px', minWidth: '100px'}
            } else if (key === 'impressions') {
                return {width: '80px', minWidth: '80px'}
            } else if (key === 'click') {
                return {width: '70px', minWidth: '70px'}
            } else if (key === 'ctr') {
                return {width: '60px', minWidth: '60px'}
            } else if (key === 'cpc') {
                return {width: '70px', minWidth: '70px'}
            } else if (key === 'spend') {
                return {width: '80px', minWidth: '80px'}
            } else if (key === 'conversions') {
                return {width: '80px', minWidth: '80px'}
            }
        },
        ending: (key) => {
            if (key === 'ctr') {
                return "%"
            } else if (key === 'spend' || key === 'cpc' || key === 'cost_pr_conversion') {
                return this.state.item.currency
            } else {
                return "";
            }
        },
        keys: () => {
            return ['text', 'type', 'level', 'impressions', 'click', 'ctr', 'cpc', 'spend', 'conversions'];
        },
        status: () => {
            try{
                if(this.state.item.status === 'active'){
                    return true
                }else{
                    return false
                }
            }catch (e) {
                return false
            }
        }
    };

    render() {
        return (
            <div className="Optimize-Query-Item-Container">
                <div className="Optimize-Query-Item-Container-Channel">
                    {
                        this.state.item.channel === 'google' &&
                        <div className="Optimize-Query-Item-Container-Channel-Google">
                            <img style={{width: '25px'}} src={require('../../assets/images/google_icon.svg')}/>
                        </div>
                    }
                    {
                        this.state.item.channel === 'facebook' &&
                        <div className="Optimize-Query-Item-Container-Channel-Facebook">
                            <img style={{width: '12px'}} src={require('../../assets/images/facebook_icon.svg')}/>
                        </div>
                    }
                    {
                        this.state.item.channel === 'instagram' &&
                        <div className="Optimize-Query-Item-Container-Channel-Instagram">
                            <img style={{width: '25px'}} src={require('../../assets/images/instagram_icon.svg')}/>
                        </div>
                    }
                </div>
                <div className="Optimize-Query-Item">
                    <SweetAlert
                        show={this.state.error_modal}
                        title="Error"
                        type="error"
                        text={this.state.error_message}
                        confirmButtonText="Ok"
                        onConfirm={() => {
                            this.setState({
                                error_modal: false
                            })
                        }}
                    />
                    {
                        this.state.loading &&
                        <div className="Optimize-Query-Item-Values-Loading">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#388f8a'}
                                loading={true}
                            />
                        </div>
                    }
                    {
                        this.renders.keys().map((key) => {
                            return (
                                <div style={this.renders.width(key)} className={
                                    cx("Optimize-Query-Item-Section",
                                        {
                                            ["Optimize-Query-Item-Section-Selected"]: this.state.sort.value === key,
                                        })
                                }>
                                    <div className="Optimize-Query-Item-Section-Title">
                                        {key}
                                    </div>
                                    {
                                        <div className="Optimize-Search-Item-Section-Value">
                                            {this.state.item[key]}{" "}{this.renders.ending(key)}
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                    <div className="Optimize-Query-Item-Section Optimize-Query-Item-Section-Slider">
                        <Switch
                            onColor="#24bda8"
                            checked={this.renders.status()}
                            onChange={(checked) => {
                                this.functions.changeStatus()
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default OptimizeSearchKeywordsPositiveItem;
