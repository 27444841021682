import moment from 'moment';
import React, { Component } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';
import formated_timezones from '../assets/json/google_timezones.json';
import SwitchTailwind from './switchTailwind';

class UpdateConversionAction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client:{},
            bidding_strategy: {},
            updated: {},
            strategies: [
                { id: 2, name: "Conversions", value: "MAXIMIZE_CONVERSIONS", label: "Set a target cost per action (optional)", input: "Target CPA" },
                { id: 3, name: "Conversions value", value: "MAXIMIZE_CONVERSION_VALUE", label: "Set a target return on ad spend (optional)", input: "Percent (%) target ROAS" },
                { id: 4, name: "Impression share", value: "TARGET_IMPRESSION_SHARE", label: "", input: "" },
                { id: 5, name: "Manual CPC", value: "MANUAL_CPC", label: "Help increase conversions with Enhanced CPC", input: "" },
                { id: 7, name: "Maximize clicks", value: "TARGET_SPEND", label: "Set a maximum cost per click bid limit", input: "Maximum CPC bid limit" },
            ]
        };
    }

    componentDidMount() {
        this.setState({
            bidding_strategy: this.props.biddingStrategy,
            client: this.props.client
        });
    }

    functions = {
        update: async () => {
            try {
                let body = {
                    operations: [
                        {
                            updateMask: "",
                            update: {
                                resourceName: this.state.bidding_strategy.resourceName,
                            }
                        }
                    ]

                };
                if (this.state.bidding_strategy.type === "MAXIMIZE_CONVERSIONS" && this.renders.biddingSubType().value && "value" in this.state.updated) {
                    body.operations[0].updateMask = "maximizeConversions.targetCpaMicros";
                    body.operations[0].update.maximizeConversions = {
                        "targetCpaMicros": this.state.updated.value * 1000000
                    }
                } else if (this.state.bidding_strategy.type === "MAXIMIZE_CONVERSION_VALUE" && this.renders.biddingSubType().value && "value" in this.state.updated) {
                    body.operations[0].updateMask = "maximizeConversionValue.targetRoas";
                    body.operations[0].update.maximizeConversionValue = {
                        "targetRoas": this.state.updated.value / 100
                    }
                } else if (this.state.bidding_strategy.type === "TARGET_IMPRESSION_SHARE") {
                    if ("locationType" in this.state.updated) {
                        body.operations[0].updateMask = "targetImpressionShare.location,targetImpressionShare.locationFractionMicros,targetImpressionShare.cpcBidCeilingMicros";
                        body.operations[0].update.targetImpressionShare = {
                            location: this.renders.locationType().value,
                            locationFractionMicros: this.renders.targetImpressionShareFraction() * 10000,
                            cpcBidCeilingMicros: this.renders.targetImpressionShareCeiling() * 1000000
                        }
                    }
                } else if (this.state.bidding_strategy.type === "TARGET_SPEND" && this.renders.biddingSubType().value && "value" in this.state.updated) {
                    body.operations[0].updateMask = "targetSpend.cpcBidCeilingMicros";
                    body.operations[0].update.targetSpend = {
                        cpcBidCeilingMicros: this.state.updated.value * 1000000
                    }
                }
                if (Object.keys(body.operations[0].update).length > 1) {
                    let response = await this.calls.update(body);
                    this.props.onUpdated();
                }
            } catch (error) {

            }

        }
    };

    renders = {
        biddingSubType: () => {
            try {
                if (this.state.strategies.filter((item) => { return item.value === this.state.bidding_strategy.type }).length > 0) {
                    let type = this.state.bidding_strategy.type;
                    if (type === "MAXIMIZE_CONVERSIONS") {
                        if (this.state.bidding_strategy.maximizeConversions) {
                            return { id: 2, name: "Enabled", value: true };
                        } else {
                            return { id: 1, name: "Disabled", value: false };
                        }
                    } else if (type === "MAXIMIZE_CONVERSION_VALUE") {
                        if (this.state.bidding_strategy.maximizeConversionValue) {
                            return { id: 2, name: "Enabled", value: true };
                        } else {
                            return { id: 1, name: "Disabled", value: false };
                        }
                    } else if (type === "TARGET_IMPRESSION_SHARE") {
                        if (this.state.bidding_strategy.targetImpressionShare) {
                            return { id: 2, name: "Enabled", value: true };
                        } else {
                            return { id: 1, name: "Disabled", value: false };
                        }
                    } else if (type === "MANUAL_CPC") {
                        return { id: 1, name: "Disabled", value: false };
                    } else if (type === "TARGET_SPEND") {
                        if (this.state.bidding_strategy.targetSpend) {
                            return { id: 2, name: "Enabled", value: true };
                        } else {
                            return { id: 1, name: "Disabled", value: false };
                        }
                    } else {
                        return { id: 1, name: "Disabled", value: false };
                    }
                } else {
                    return { id: 0, name: "...", value: false };
                }
            } catch (error) {
                return { id: 0, name: "...", value: false };
            }
        },
        value: () => {
            try {
                if ("value" in this.state.updated) {
                    return this.state.updated.value;
                } else {
                    if (this.state.strategies.filter((item) => { return item.value === this.state.bidding_strategy.type }).length > 0) {
                        let type = this.state.bidding_strategy.type;
                        if (type === "MAXIMIZE_CONVERSIONS") {
                            if (this.state.bidding_strategy.maximizeConversions) {
                                return +this.state.bidding_strategy.maximizeConversions.targetCpaMicros / 1000000;
                            }
                        } else if (type === "MAXIMIZE_CONVERSION_VALUE") {
                            if (this.state.bidding_strategy.maximizeConversionValue) {
                                return +this.state.bidding_strategy.maximizeConversionValue.targetRoas * 100
                            }
                        } else if (type === "MANUAL_CPC") {
                            return "";
                        } else if (type === "TARGET_SPEND") {
                            if (this.state.bidding_strategy.targetSpend) {
                                return +this.state.bidding_strategy.targetSpend.cpcBidCeilingMicros / 1000000;
                            }
                        } else {
                            return "";
                        }
                    } else {
                        return "";
                    }
                }
            } catch (error) {
                return "";
            }
        },
        locationType: () => {
            try {
                if ("locationType" in this.state.updated) {
                    return this.state.updated.locationType;
                } else {
                    return [
                        { id: 1, name: "Anywhere on results page", value: "ANYWHERE_ON_PAGE" },
                        { id: 2, name: "Top of results page", value: "TOP_OF_PAGE" },
                        { id: 3, name: "Absolute top of results page", value: "ABSOLUTE_TOP_OF_PAGE" }
                    ].filter((item) => { return item.value === this.state.bidding_strategy.targetImpressionShare.location })[0];
                }
            } catch (error) {
                return { id: 0, name: "...", value: false };
            }
        },
        targetImpressionShareFraction: () => {
            try {
                if ("targetImpressionShareFraction" in this.state.updated) {
                    return this.state.updated.targetImpressionShareFraction;
                } else {
                    return this.state.bidding_strategy.targetImpressionShare.locationFractionMicros / 10000;
                }
            } catch (error) {
                return "";
            }
        },
        targetImpressionShareCeiling: () => {
            try {
                if ("targetImpressionShareCeiling" in this.state.updated) {
                    return this.state.updated.targetImpressionShareCeiling;
                } else {
                    return this.state.bidding_strategy.targetImpressionShare.cpcBidCeilingMicros / 1000000;
                }
            } catch (error) {
                return "";
            }
        },
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateBiddingStrategy?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">
                    <div className="flex flex-1">
                        <div className="w-full">
                            <div className="grid-cols-12 gap-4 grid mb-8">
                                <div className={"col-span-12"}>
                                    <InputTailwind
                                        label={"Name"}
                                        locked={true}
                                        readOnly={true}
                                        value={this.state.bidding_strategy.name ? this.state.bidding_strategy.name : ""}
                                        onChange={(value) => {

                                        }}
                                    />
                                </div>
                                <div className={"col-span-12"}>
                                    <InputTailwind
                                        label={"Type"}
                                        locked={true}
                                        readOnly={true}
                                        value={this.state.strategies.filter((item) => { return item.value === this.state.bidding_strategy.type }).length > 0 ? this.state.strategies.filter((item) => { return item.value === this.state.bidding_strategy.type })[0].name : this.state.bidding_strategy.type}
                                        onChange={(value) => {
                                        }}
                                    />
                                </div>
                                {
                                    this.state.bidding_strategy.type !== "TARGET_IMPRESSION_SHARE" &&
                                    <div className={"col-span-6"}>
                                        <DropdownTailwind
                                            label={this.state.strategies.filter((item) => { return item.value === this.state.bidding_strategy.type }).length > 0 ? this.state.strategies.filter((item) => { return item.value === this.state.bidding_strategy.type })[0].label : ""}
                                            locked={true}
                                            readOnly={true}
                                            selected={this.renders.biddingSubType()}
                                            options={[]}
                                            onChange={(value) => {
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    this.state.bidding_strategy.type === "TARGET_IMPRESSION_SHARE" && this.renders.biddingSubType().value &&
                                    <>
                                        <div className={"col-span-6"}>
                                            <DropdownTailwind
                                                label={"Where do you want your ads to appear"}
                                                selected={this.renders.locationType()}
                                                options={[
                                                    { id: 1, name: "Anywhere on results page", value: "ANYWHERE_ON_PAGE" },
                                                    { id: 2, name: "Top of results page", value: "TOP_OF_PAGE" },
                                                    { id: 3, name: "Absolute top of results page", value: "ABSOLUTE_TOP_OF_PAGE" }
                                                ]}
                                                onChange={async (value) => {
                                                    this.state.updated.locationType = value;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className={"col-span-6"}>
                                            <InputTailwind
                                                label={"Percent (%) impression share to target"}
                                                value={this.renders.targetImpressionShareFraction()}
                                                onChange={async (value) => {
                                                    this.state.updated.targetImpressionShareFraction = value;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    });

                                                }}
                                            />
                                        </div>
                                        <div className={"col-span-6"}>
                                            <InputTailwind
                                                label={"Maximum CPC bid limit"}
                                                value={this.renders.targetImpressionShareCeiling()}
                                                onChange={async (value) => {
                                                    this.state.updated.targetImpressionShareCeiling = value;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    });
                                                }}
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    this.state.bidding_strategy.type !== "TARGET_IMPRESSION_SHARE" && this.state.bidding_strategy.type !== "MANUAL_CPC" &&
                                    <div className={"col-span-6"}>
                                        <InputTailwind
                                            label={this.state.strategies.filter((item) => { return item.value === this.state.bidding_strategy.type }).length > 0 ? this.state.strategies.filter((item) => { return item.value === this.state.bidding_strategy.type })[0].input : "value"}
                                            value={this.renders.value()}
                                            onChange={async (value) => {
                                                this.state.updated.value = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default UpdateConversionAction;