import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";
import AccordionStatic from "../../components/accordion-static";
import AccordionFixed from "../../components/accordion-fixed";
import Dropdown from "../../components/dropdown";
import SlideDown from "react-slidedown";
import Datepicker from "../../components/datepicker";
import {Doughnut} from "react-chartjs-2";
import Switch from "react-ios-switch";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import queryString from "query-string";
import Carousel from "re-carousel";
import FeatherIcon from "feather-icons-react";
import OptimizeSearchItemQuery from "../optimize-search-item-query";
import OptimizeSearchItemNegative from "../optimize-search-item-negative";
import ReactTooltip from 'react-tooltip'
import cx from "classnames";

class OptimizeSearchItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item ? this.props.item : {},
            sort: this.props.sort ? this.props.sort : {},
            sort_extra: {name: 'Click', value: 'click'},
            order_extra: {name: 'Ascending', value: 'asc'},
            queries: [],
            negatives: [],
            loading: true,
            loading_extra: false,
            limit: 10,
            page: 1
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item,
            sort: nextProps.sort,
        })
    }

    componentDidMount() {
        this.setState({
            item: this.props.item,
            sort: this.props.sort,
        }, () => {
            this.init.adgroup();
        });
    }

    init = {
        adgroup: () => {
            this.setState({
                loading: true
            }, () => {
                calls.getAdset(this.state.item.internal, this.state.item.id).then((response) => {
                    try {
                        this.state.item.type = this.state.item.item.type;
                    }catch (e) {}
                    this.state.item.status = response.data.status;
                    this.state.item.impressions = response.data.impressions;
                    this.state.item.click = response.data.click;
                    this.state.item.cpc = response.data.cpc;
                    this.state.item.ctr = response.data.ctr;
                    this.state.item.spend = response.data.spend;
                    this.state.item.conversions = response.data.conversions;
                    this.state.item.currency = response.data.currency;
                    this.state.item.channel = response.data.channel;
                    this.setState({
                        item: this.state.item,
                        loading: false
                    }, () => {
                        this.props.onChange(this.state.item);
                    });
                }, (error) => {
                    this.state.item.error = true;
                    this.state.item.error_message = error.body.message;
                    this.setState({
                        item: this.state.item,
                        loading: false
                    }, () => {
                        this.props.onChange(this.state.item);
                    });
                });
            })
        }
    };

    functions = {
        open: () => {
            this.setState({
                open: !this.state.open
            }, () => {
                if(this.state.open){
                    this.functions.extras();
                }else{
                    this.setState({
                        queries: [],
                        negatives: []
                    })
                }
            })
        },
        extras: () => {
            this.setState({
                loading_extra: true
            }, () => {
                calls.getExtra(this.state.item.internal, this.state.item.id, this.state.sort_extra.value, this.state.order_extra.value).then((response) => {
                    this.setState({
                        queries_count: response.data.count,
                        queries: response.data.queries,
                        loading_extra: false
                    })
                }, (error) => {

                });
            })
        },
        status: () => {
            this.setState({
                loading: true
            }, () => {
                let status = 2;
                if(this.state.item.status.toLowerCase() === 'enabled'){
                    status = 3;
                }
                calls.updateStatus(this.state.item.internal, this.state.item.id, {status: status}).then((response) => {
                    if(response.data.status){
                        this.state.item.status = response.data.status;
                    }
                    this.setState({
                        item: this.state.item,
                        loading: false
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        }
    };

    renders = {
        width: (key) => {
            if(key === 'name'){
                return {width: '100px'}
            }
            else if(key === 'type'){
                return {width: '100px'}
            }
            else if(key === 'impressions'){
                return {width: '100px'}
            }
            else if(key === 'click'){
                return {width: '100px'}
            }
            else if(key === 'ctr'){
                return {width: '100px'}
            }
            else if(key === 'cpc'){
                return {width: '100px'}
            }
            else if(key === 'spend'){
                return {width: '100px'}
            }
            else if(key === 'conversions'){
                return {width: '100px'}
            }
        },
        ending: (key) => {
            if(key === 'ctr'){
                return "%"
            }else if(key === 'spend' || key === 'cpc' || key === 'cost_pr_conversion'){
                return this.state.item.currency
            }else{
                return  "";
            }
        },
        keys: () => {
            return ['name', 'type', 'impressions', 'click', 'ctr', 'cpc', 'spend', 'conversions'];
        },
        active: () => {
            try {
                return this.state.item.status.toLowerCase() === 'active' || this.state.item.status.toLowerCase() === 'enabled'
            } catch (e) {
                return false;
            }
        },
    };

    render() {
        return (
            <div style={{width: '100%'}}>
                <div className="Optimize-Search-Item">
                    <div className="Optimize-Search-Item-Channel">
                        {
                            this.state.item.channel === 'google' &&
                            <div className="Optimize-Search-Item-Channel-Google">
                                <img style={{width: '25px'}} src={require('../../assets/images/google_icon.svg')}/>
                            </div>
                        }
                    </div>
                    <div className="Optimize-Search-Item-Values">
                        {
                            this.state.loading &&
                            <div className="Optimize-Search-Item-Values-Loading">
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={10}
                                    color={'#388f8a'}
                                    loading={true}
                                />
                            </div>
                        }
                        {
                            this.renders.keys().map((key) => {
                                return(
                                    <div style={this.renders.width(key)} className={
                                        cx("Optimize-Search-Item-Section",
                                            {
                                                ["Optimize-Search-Item-Section-Selected"]: this.state.sort.value === key,
                                            })
                                    }>
                                        <div className="Optimize-Search-Item-Section-Title">
                                            {key}
                                        </div>
                                        <div data-tip-disable={key !== 'name'} data-tip={(this.state.item[key] + ' ' + this.renders.ending(key))} className="Optimize-Search-Item-Section-Value">
                                            {this.state.item[key]}{" "}{this.renders.ending(key)}
                                            <ReactTooltip />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="Optimize-Search-Item-Section Optimize-Search-Item-Section-Slider">
                            <Switch
                                onColor="#24bda8"
                                checked={this.renders.active()}
                                onChange={(checked) => {
                                    this.functions.status();
                                }}
                            />
                        </div>
                    </div>
                    <div onClick={() => {
                        if(!this.state.loading && !this.state.loading_extra){
                            this.functions.open();
                        }
                    }} className="Optimize-Search-Item-Button">
                        {
                            !this.state.open && !this.state.loading &&
                            <FeatherIcon size={20} color={'#333333'} icon={'plus'}/>
                        }
                        {
                            this.state.open &&  !this.state.loading &&
                            <FeatherIcon size={20} color={'#333333'} icon={'minus'}/>
                        }
                    </div>
                </div>
                {
                    this.state.open &&
                    <div style={{paddingTop: '0px', marginBottom: '10px', width: '100%'}} className="Section">
                        <Dropdown
                            filter={false}
                            noTitle={true}
                            title={'Sort'}
                            size={'half'}
                            placeholder={"Sort by"}
                            tiny={true}
                            disabled={this.state.loading_extra}
                            removeArrow={this.state.loading_extra}
                            options={[
                                {name: 'Impressions', value: 'impressions'},
                                {name: 'Click', value: 'click'},
                                {name: 'Ctr', value: 'ctr'},
                                {name: 'Cpc', value: 'cpc'},
                                {name: 'Spend', value: 'spend'},
                                {name: 'Conversions', value: 'conversions'}
                            ]}
                            value={this.state.sort_extra}
                            onChange={(value) => {
                                this.setState({
                                    sort_extra: value
                                }, () => {
                                    if(this.state.queries_count > (this.state.limit * this.state.page)){
                                        this.setState({
                                            page: 1
                                        }, () => {
                                            this.functions.extras();
                                        })
                                    }
                                })
                            }}
                        />
                        <Dropdown
                            noTitle={true}
                            filter={false}
                            title={'Order'}
                            size={'half'}
                            tiny={true}
                            placeholder={"Order by"}
                            disabled={this.state.loading_extra}
                            removeArrow={this.state.loading_extra}
                            options={[
                                {name: 'Ascending', value: 'asc'},
                                {name: 'Descending', value: 'desc'}
                            ]}
                            value={this.state.order_extra}
                            onChange={(value) => {
                                this.setState({
                                    order_extra: value
                                }, () => {
                                    if(this.state.queries_count > (this.state.limit * this.state.page)){
                                        this.setState({
                                            page: 1
                                        }, () => {
                                            this.functions.extras();
                                        })
                                    }
                                })
                            }}
                        />
                    </div>
                }
                {
                    this.state.open && this.state.loading_extra &&
                    <div className="Optimize-Search-Item-Loader">
                        <BeatLoader
                            sizeUnit={"px"}
                            size={10}
                            color={'#388f8a'}
                            loading={true}
                        />
                    </div>
                }
                {
                    this.state.open && !this.state.loading_extra && this.state.queries.length > 0 &&
                    <div style={{width: '100%'}}>
                        {
                            this.state.queries.filter((item, index) => {
                                return index < this.state.page * this.state.limit;
                            }).sort((a, b) => {
                                if (this.state.sort_extra.name) {
                                    var nameA = null;
                                    var nameB = null;
                                    nameA = a[this.state.sort_extra.value];
                                    nameB = b[this.state.sort_extra.value];
                                    if (this.state.order_extra.value === 'desc') {
                                        if (nameA > nameB)
                                            return -1;
                                        if (nameA < nameB)
                                            return 1;
                                        return 0
                                    } else {
                                        if (nameA < nameB)
                                            return -1;
                                        if (nameA > nameB)
                                            return 1;
                                        return 0
                                    }
                                } else {
                                    return 1
                                }
                            }).map((item) => {
                                return(
                                    <OptimizeSearchItemQuery
                                        adset={this.state.item}
                                        item={item}
                                        sort={this.state.sort_extra}
                                    />
                                )
                            })
                        }
                    </div>
                }
                {
                    ((this.state.limit * this.state.page) < this.state.queries_count) && this.state.open && !this.state.loading_extra && this.state.queries.length > 0 &&
                    <div style={{width: '100%', marginBottom: '10px'}}>
                        <div onClick={() => {
                            this.setState({
                                page: this.state.page + 1
                            })
                        }} className="ButtonFull Button ButtonNeutral">
                            Load more ({this.state.limit * this.state.page}/{this.state.queries_count})
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default OptimizeSearchItem;
