import React, {Component} from 'react';
import './style.css';
import Menu from "../../components/menu";
import FaqItem from "../../components/faq-item";

class Faq extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    componentWillMount(){
        this.init.title();
    }

    init = {
        title: () => {
            document.title = "FAQ | Light Sites";
        }
    };

    functions = {};

    renders = {};

    render(){
        return (
            <div className="Admin">
                <div className="Admin-Inner">
                    <div className="Admin-InnerTitle">
                        Frequently Asked Questions
                    </div>
                    <Menu history={this.props.history}/>
                    <FaqItem title={"Can I use EmailJS for free?"}>
                        You can send up to 200 emails per month completely for free. If you need to send more emails, want to use advanced features like file attachments or remove our footer from the emails – please take a look at our plans
                    </FaqItem>
                    <FaqItem title={"Can I use EmailJS for free?"}>
                        You can send up to 200 emails per month completely for free. If you need to send more emails, want to use advanced features like file attachments or remove our footer from the emails – please take a look at our plans
                    </FaqItem>
                    <FaqItem title={"Can I use EmailJS for free?"}>
                        You can send up to 200 emails per month completely for free. If you need to send more emails, want to use advanced features like file attachments or remove our footer from the emails – please take a look at our plans
                    </FaqItem>
                    <FaqItem title={"Can I use EmailJS for free?"}>
                        You can send up to 200 emails per month completely for free. If you need to send more emails, want to use advanced features like file attachments or remove our footer from the emails – please take a look at our plans
                    </FaqItem>
                    <div className="ButtonFaq">
                        ASK A QUESTION
                    </div>
                </div>
            </div>
        )
    }
}

export default Faq;
