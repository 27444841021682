import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../../accordion-fixed";
import Switch from 'react-ios-switch';
import IconFacebook from "../../icons/facebook";
import IconInstagram from "../../icons/instagram";
import Input from "../../input";
import {eventRegister} from "../../../services/eventRegister";
import {campaignRegister} from "../../../services/campaignRegister";
import SweetAlert from "sweetalert-react";
import {calls} from "./calls";
import {BeatLoader} from "react-spinners";
import Dropdown from "../../dropdown";
import Accordion from "../../accordion";

var moment = require('moment');

class ConversionStrategy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: {},
            channels: {},
            conversion: {},
            creatives: {},
            adset: {},
            error: false,
            loading_conversion_actions: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            conversion: nextProps.conversion,
            client: nextProps.client,
            creatives: nextProps.creatives,
            adset: nextProps.adset,
            channels: nextProps.channels
        })
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            conversion: this.props.conversion,
            client: this.props.client,
            creatives: this.props.creatives,
            adset: this.props.adset,
            channels: this.props.channels
        })
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div className="w-full">
                {
                    [
                        {title: 'Prospect', value: 'prospect_default'},
                        {title: 'Look a like', value: 'lookalike_default'},
                        {title: 'Remarketing', value: 'remarketing_default'}
                    ].map((item) => {
                        return(
                            <div className="flex flex-row flex-1 items-center border mb-2 bg-white px-4 py-1 rounded-md">
                                <div className="Variations-ItemLineTitle">
                                    <div>{item.title}</div>
                                </div>
                                <div className="Variations-ItemLineSlider">
                                    <Switch
                                        onColor="#1AD5BD"
                                        checked={this.state.conversion[item.value]}
                                        onChange={(checked) => {
                                            this.state.conversion[item.value] = checked;
                                            if(!checked && (item.value === "prospect_default" || item.value === "lookalike_default")){
                                                if(item.value === "prospect_default"){
                                                    this.state.conversion.lookalike_default = checked;
                                                }else{
                                                    if(typeof this.state.conversion.audiences === "object" && Array.isArray(this.state.conversion.audiences.facebook)){
                                                        this.state.conversion.audiences.facebook = this.state.conversion.audiences.facebook.map((item) => {
                                                            item.look_a_like_selected = false;
                                                            return item;
                                                        });

                                                    }
                                                }
                                            }else if(!checked && item.value === "remarketing_default"){
                                                if(typeof this.state.conversion.audiences === "object" && Array.isArray(this.state.conversion.audiences.facebook)){
                                                    this.state.conversion.audiences.facebook = this.state.conversion.audiences.facebook.map((item) => {
                                                        item.remarketing_selected = false;
                                                        return item;
                                                    });

                                                }
                                            }
                                            this.setState({
                                                conversion: this.state.conversion
                                            }, () => {
                                                this.props.updateConversion(this.state.conversion);
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default ConversionStrategy;
