class searchGoogle {
    static validate(ad) {
        let error = {
            name: false,
            bodies: false,
            headlines: false,
            website: false,
        };
        if (!ad.name || ad.name === "") {
            error.name = true;
        }
        if (ad.bodies.filter((item) => { return item.text !== "" }).length < 2 || ad.bodies.filter((item) => { return item.text.length > 90 }).length > 0) {
            error.bodies = true;
        }
        if (ad.bodies.map((item) => { return item.text }).filter((item, index, array) => { return array.indexOf(item) !== index }).length > 0) {
            error.bodies = true;
        }
        if (ad.headlines.filter((item) => { return item.text !== "" }).length < 3 || ad.headlines.filter((item) => { return item.text.length > 30 }).length > 0) {
            error.headlines = true;
        }
        if (ad.headlines.map((item) => { return item.text }).filter((item, index, array) => { return array.indexOf(item) !== index }).length > 0) {
            error.headlines = true;
        }
        if (ad.headlines.filter((item) => { return item.text.includes("!") }).length > 0) {
            error.headlines = true;
        }
        //console.log(ad.headlines.map((item) => { return item.text }).filter((item, index, array) => { return array.indexOf(item) !== index }), "UNIQUE")
        

        if (!ad.link || ad.link === "" || (!ad.link.includes("https://") && !ad.link.includes("http://"))) {
            error.website = true;
        }

        return error;
    }
}

export { searchGoogle }