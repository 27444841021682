import React, { Component, Fragment } from 'react';
import FeatherIcon from 'feather-icons-react';
import { BeatLoader } from "react-spinners";
import { SlideDown } from "react-slidedown";
import { calls } from "./calls";
import cx from "classnames";
import { userRegister } from "../../services/userRegister";
var moment = require('moment');

class AddKeyword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: true,
            open: {
                campaigns: true,
                keyword: false
            },
            selected_external: {},
            campaigns: [],
            keyword_type: {},
            keyword: ""
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            closed: nextProps.closed
        })
    }

    componentWillMount() { }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    functions = {
        init: (keyword) => {
            console.log(keyword);
            console.log(this.props.negative);
            if (this.props.negative) {
                let selected_external = {};
                if (Array.isArray(this.props.campaigns)) {
                    this.props.campaigns.map((item) => {
                        selected_external[item.id] = item;
                    })
                }
                this.setState({
                    keyword_type: { "0": true },
                    keyword: keyword,
                    selected_external: selected_external,
                    campaigns: this.props.campaigns ? this.props.campaigns : [],
                    open: {
                        campaigns: true,
                        keyword: false
                    },
                }, () => {
                    console.log(this.state.keyword);
                })
            } else {
                let selected_external = {};
                if (Array.isArray(this.props.campaigns)) {
                    this.props.campaigns.map((item) => {
                        selected_external[item.id] = item;
                    })
                }
                this.setState({
                    keyword_type: { "0": true },
                    keyword: "",
                    adgroup: keyword,
                    campaigns: [],
                    open: {
                        campaigns: false,
                        keyword: true
                    },
                })
            }
        },
        create: () => {
            this.setState({
                loading: true,
            }, () => {
                let keyword_data = {
                    keyword: this.state.keyword,
                    exact: this.state.keyword_type["0"],
                    phrase: this.state.keyword_type["1"],
                    broad: this.state.keyword_type["2"],
                    negative: this.props.negative,
                    client: this.props.client.id,
                    campaigns: Object.keys(this.state.selected_external),
                };
                if(this.state.adgroup){
                    delete keyword_data.campaigns;
                    keyword_data.adgroups = [this.state.adgroup.id];
                    keyword_data.positive = true;
                }
                calls.addKeyword(keyword_data).then((response) => {
                    this.setState({
                        loading: false
                    }, () => {
                        console.log(response);
                        if (this.props.onChange && response && Array.isArray(response.keywords)) {
                            this.props.onChange(response.keywords);
                        }
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        },
    };

    renders = {
        stepValid: (step) => {
            if(this.props.negative){
                if (step === "keyword") {
                    return this.state.selected_external && Object.keys(this.state.selected_external).length > 0
                } else {
                    return false
                }
            }else{
                return true;
            }
        },
        valid: () => {
            if(this.props.negative){
                return this.state.keyword !== "" && Object.keys(this.state.keyword_type).length > 0 && this.state.selected_external && Object.keys(this.state.selected_external).length > 0
            }else{
                return this.state.keyword !== "" && Object.keys(this.state.keyword_type).length > 0;
            }
        }
    };

    render() {
        return (
            <div className={`h-full flex flex-col overflow-y-scroll`}>
                {
                    this.props.negative &&
                    <div className="border-b">
                        <div className="flex flex-1 flex-row justify-center items-center p-6">
                            <div className="flex flex-col flex-1">
                                <h3 className="mb-0 font-bold text-sm">
                                    Select campaigns
                                </h3>
                                {
                                    Object.keys(this.state.selected_external).length > 0 && !this.state.loading_externals && !this.state.loading_internals &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        You have selected <span className="font-bold ml-1 mr-1">{Object.keys(this.state.selected_external).length}</span> campaigns
                                    </p>
                                }
                                {
                                    Object.keys(this.state.selected_external).length < 1 && !this.state.loading_externals && !this.state.loading_internals &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Select campaigns below
                                    </p>
                                }
                                {
                                    (this.state.loading_externals || this.state.loading_internals) &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Loading ...
                                    </p>
                                }
                            </div>
                            <div className="flex flex-row">
                                {
                                    Object.keys(this.state.selected_external).length > 0 &&
                                    <div
                                        className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={14}
                                            icon={"check"} />
                                    </div>
                                }
                                {
                                    (this.state.loading_externals || this.state.loading_internals) &&
                                    <div
                                        className="cursor-pointer h-8 w-8 rounded text-purple-500 bg-purple-100 flex justify-center align-middle items-center">
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={4}
                                            color={'#060534'}
                                            loading={true}
                                        />
                                    </div>
                                }
                                {
                                    !this.state.loading_externals && !this.state.loading_internals &&
                                    <div className="flex flex-row">
                                        <div onClick={() => {
                                            this.state.open.campaigns = !this.state.open.campaigns;
                                            this.setState({
                                                open: this.state.open
                                            })
                                        }}
                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                            {
                                                !this.state.open.campaigns &&
                                                <FeatherIcon className={'stroke-current'}
                                                    size={14}
                                                    icon={"chevron-down"} />
                                            }
                                            {
                                                this.state.open.campaigns &&
                                                <FeatherIcon className={'stroke-current'}
                                                    size={14} icon={"chevron-up"} />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <SlideDown closed={!this.state.open.campaigns}>
                            {
                                this.state.campaigns.map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div className={"flex flex-1 flex-row justify-center mb-2 px-6"}>
                                                {
                                                    <div
                                                        className="bg-google-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                        <img className="w-3"
                                                            src={require('../../assets/images/google_icon.svg')} />
                                                    </div>
                                                }
                                                {
                                                    <div onClick={() => {
                                                        if (!item.disabled) {
                                                            if (!this.state.selected_external) {
                                                                this.state.selected_external = {};
                                                                this.state.selected_external[item.id] = item;
                                                            } else if (!this.state.selected_external[item.id]) {
                                                                this.state.selected_external[item.id] = item;
                                                            } else {
                                                                delete this.state.selected_external[item.id];
                                                            }
                                                            this.setState({
                                                                selected_external: this.state.selected_external
                                                            })
                                                        }
                                                    }}
                                                        className={(item.disabled ? "opacity-50 cursor-not-allowed " : "") + ((this.state.selected_external && this.state.selected_external[item.id]) ? " border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                        {
                                                            this.state.selected_external && this.state.selected_external[item.id] &&
                                                            <FeatherIcon
                                                                className={'stroke-current'}
                                                                size={14}
                                                                icon="check" />
                                                        }
                                                    </div>
                                                }
                                                <div
                                                    className="flex flex-1 flex-col truncate">
                                                    <div className="font-bold text-xs truncate">
                                                        {item.name}
                                                    </div>
                                                    <div className="text-xxs">
                                                        {item.id}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                            {
                                Object.keys(this.state.selected_external).length > 0 &&
                                <div className="flex justify-center items-center">
                                    <div onClick={() => {
                                        this.state.open.campaigns = false;
                                        this.state.open.keyword = true;
                                        this.setState({
                                            open: this.state.open
                                        })
                                    }}
                                        className="font-bold text-xs text-white cursor-pointer mt-4 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                    </div>
                                </div>
                            }
                            {
                                <div className="w-full h-6"></div>
                            }
                        </SlideDown>
                    </div>
                }
                {
                    <div className={(this.renders.stepValid('keyword') ? "" : "opacity-50 ") + "border-b"}>
                        <div className="flex flex-1 flex-row justify-center items-center px-4 sm:px-6 pt-6 pb-6">
                            <div className="flex flex-col flex-1">
                                <h3 className="mb-0 font-bold text-sm">
                                    Set keyword
                                </h3>
                                {
                                    Object.keys(this.state.keyword_type).length > 0 &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        You have selected <span className="font-bold ml-1 mr-1">{Object.keys(this.state.selected_external).length}</span> campaigns
                                    </p>
                                }
                                {
                                    Object.keys(this.state.keyword_type).length < 1 &&
                                    <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                        Set keyword and select type
                                    </p>
                                }
                            </div>
                            <div className="flex flex-row">
                                {
                                    Object.keys(this.state.keyword_type).length > 0 && this.state.keyword !== "" &&
                                    <div
                                        className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        <FeatherIcon className={'stroke-current'} size={14}
                                            icon={"check"} />
                                    </div>
                                }
                                {
                                    this.renders.stepValid('keyword') &&
                                    <div className="flex flex-row">
                                        <div onClick={() => {
                                            this.state.open.keyword = !this.state.open.keyword;
                                            this.setState({
                                                open: this.state.open
                                            })
                                        }}
                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                            {
                                                !this.state.open.keyword &&
                                                <FeatherIcon className={'stroke-current'}
                                                    size={14}
                                                    icon={"chevron-down"} />
                                            }
                                            {
                                                this.state.open.keyword &&
                                                <FeatherIcon className={'stroke-current'}
                                                    size={14} icon={"chevron-up"} />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <SlideDown closed={!this.state.open.keyword}>
                            <div className="">
                                <div className="px-6">
                                    <div className="text-xs font-bold mb-1">
                                        Keyword
                                    </div>
                                    <div className="flex flex-1 flex-col justify-center mb-4">
                                        <input
                                            onChange={(event) => {
                                                this.setState({
                                                    keyword: event.target.value
                                                });
                                            }}
                                            placeholder={"Keyword"}
                                            value={this.state.keyword}
                                            className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    {
                                        [
                                            { id: 0, name: "Exact match", subtitle: "Recommended" },
                                            { id: 1, name: "Phrase match", subtitle: "Allows blocking of other keywords" },
                                            { id: 2, name: "Broad match", subtitle: "used with care" }
                                        ].map((item, i) => {
                                            return (
                                                <Fragment key={i}>
                                                    <div className={(item.disabled ? "opacity-50 cursor-not-allowed" : "") + " flex flex-1 flex-row justify-center mb-2 px-6"}>
                                                        <div onClick={() => {
                                                            if (this.state.keyword_type[item.id]) {
                                                                delete this.state.keyword_type[item.id];
                                                            } else {
                                                                this.state.keyword_type[item.id] = item;
                                                            }
                                                            this.setState({
                                                                keyword_type: this.state.keyword_type
                                                            })
                                                        }}
                                                            className={(item.disabled ? "opacity-50 cursor-not-allowed " : "") + ((this.state.keyword_type && this.state.keyword_type[item.id]) ? " border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                            {
                                                                this.state.keyword_type && this.state.keyword_type[item.id] &&
                                                                <FeatherIcon
                                                                    className={'stroke-current'}
                                                                    size={14}
                                                                    icon="check" />
                                                            }
                                                        </div>
                                                        <div
                                                            className="flex flex-1 flex-col truncate">
                                                            <div className="font-bold text-xs truncate">
                                                                {item.name}
                                                            </div>
                                                            <div className="text-xxs">
                                                                {item.subtitle}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {
                                Object.keys(this.state.keyword_type).length > 0 && this.state.keyword !== "" &&
                                <div className="flex justify-center items-center">
                                    <div onClick={() => {
                                        this.state.open.keyword = false;
                                        this.setState({
                                            open: this.state.open
                                        })
                                    }}
                                        className="font-bold text-xs text-white cursor-pointer mt-4 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                    </div>
                                </div>
                            }
                            {
                                <div className="w-full h-6"></div>
                            }
                        </SlideDown>
                    </div>
                }
                <div className="w-full h-6" />
                <div className="px-4">
                    <div onClick={() => {
                        if (this.renders.valid()) {
                            this.functions.create();
                        }
                    }} className={(this.renders.valid() ? "bg-green-500 text-white cursor-pointer" : "bg-gray-100 text-gray-500 cursor-not-allowed") + " py-3 px-4 transition-all duration-200 rounded-full flex rounded font-bold justify-center align-middle items-center font-bold w-full text-sm text-center"}>
                        {
                            !this.state.loading &&
                            <div>Add keyword</div>
                        }
                        {
                            this.state.loading &&
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#ffffff'}
                                loading={true}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default AddKeyword;
