import React, {Component} from 'react';
import './style.css';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Campaign from "../../components/campaign";
import {calls} from "./calls";
import {userRegister} from "../../services/userRegister";
import AccordionStatic from "../../components/accordion-static";
import AccordionFixed from "../../components/accordion-fixed";
import Dropdown from "../../components/dropdown";
import SlideDown from "react-slidedown";
import Datepicker from "../../components/datepicker";
import {Doughnut} from "react-chartjs-2";
import Switch from "react-ios-switch";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import queryString from "query-string";
import Carousel from "re-carousel";
import FeatherIcon from "feather-icons-react";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import Input from "../input";

class OptimizeSearchQueriesItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: this.props.campaign ? this.props.campaign : {},
            item: this.props.item ? this.props.item : {},
            sort: {},
            setting: {},
            edit: false,
            query: "",
            type: {},
            level: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign ? nextProps.campaign : {},
            item: nextProps.item ? nextProps.item : {},
            sort: nextProps.sort,
            setting: nextProps.setting
        })
    }

    componentDidMount() {
        this.setState({
            campaign: this.props.campaign ? this.props.campaign : {},
            item: this.props.item ? this.props.item : {},
            sort: this.props.sort,
            setting: this.props.setting
        });
    }

    init = {};

    functions = {
        addNegative: () => {
            this.setState({
                loading: true,
                warning_modal: false,
            }, () => {
                calls.createNegative({
                    keyword: this.state.query,
                    type: this.state.type.value,
                    adgroup_id: this.state.item.adgroup_id,
                    campaign_id: this.state.campaign.id,
                    level: this.state.level.value,
                    channels: {google: true}
                }).then((response) => {
                    this.setState({
                        loading: false,
                        edit: false
                    }, () => {
                        this.state.item.negative_keywords = {};
                        for(let key in response.data){
                            this.state.item.negative_keywords[key] = {};
                            this.state.item.negative_keywords[key] = response.data[key];
                            if(!response.data[key].error){
                                this.state.item.negative_keywords[key].channel = key;
                                this.state.item.negative_keywords[key].adgroup_id = this.state.item.adgroup_id;
                                this.state.item.negative_keywords[key].criterion_id = response.data[key].criterion_id;
                                this.state.item.negative_keywords[key].type = this.state.type.value;
                                this.state.item.negative_keywords[key].level = this.state.level.value;
                                this.state.item.negative_keywords[key].text = this.state.query;
                            }
                        }
                        this.props.onRemove(this.state.item);
                    })
                }, (error) => {
                    this.setState({
                        loading: false,
                        error_message: error.body.message,
                        error_modal: true,
                    })
                });
            })
        }
    };

    renders = {
        width: (key) => {
            if (key === 'query') {
                return {width: '270px', minWidth: '270px'}
            } else if (key === 'type') {
                return {width: '65px', minWidth: '65px'}
            } else if (key === 'keyword') {
                return {width: '100px', minWidth: '100px'}
            } else if (key === 'impressions') {
                return {width: '80px', minWidth: '80px'}
            } else if (key === 'click') {
                return {width: '70px', minWidth: '70px'}
            } else if (key === 'ctr') {
                return {width: '60px', minWidth: '60px'}
            } else if (key === 'cpc') {
                return {width: '70px', minWidth: '70px'}
            } else if (key === 'spend') {
                return {width: '80px', minWidth: '80px'}
            } else if (key === 'conversions') {
                return {width: '80px', minWidth: '80px'}
            }
        },
        ending: (key) => {
            if (key === 'ctr') {
                return "%"
            } else if (key === 'spend' || key === 'cpc' || key === 'cost_pr_conversion') {
                return this.state.item.currency
            } else {
                return "";
            }
        },
        keys: () => {
            return ['query', 'type', 'keyword', 'impressions', 'click', 'ctr', 'cpc', 'spend', 'conversions'];
        },
        query: () => {
            try {
                let res = this.state.item.query.toLowerCase().replace(this.state.item.keyword.toLowerCase(), '<b>' + this.state.item.keyword + '</b>');
                return res;
            } catch (e) {
                return this.state.item.query
            }
        },
        markdown: (input) => {
            return {__html: input}
        },
        valid: () => {
            try {
                return this.state.item.query.toLowerCase() === this.state.item.matched_string.toLowerCase()
            } catch (e) {
                return false
            }
        }
    };

    render() {
        return (
            <div className="Optimize-Query-Item-Container-searchterms">
                <div className="Optimize-Query-Item-Container-top">
                    <div className="Optimize-Query-Item-Container-Channel">
                        {
                            this.state.item.channel === 'google' &&
                            <div className="Optimize-Query-Item-Container-Channel-Google">
                                <img style={{width: '25px'}} src={require('../../assets/images/google_icon.svg')}/>
                            </div>
                        }
                    </div>
                    <div className="Optimize-Query-Item">
                        <SweetAlert
                            show={this.state.error_modal}
                            title="Error"
                            type="error"
                            text={this.state.error_message}
                            confirmButtonText="Ok"
                            onConfirm={() => {
                                this.setState({
                                    error_modal: false
                                })
                            }}
                        />
                        {
                            this.renders.keys().map((key) => {
                                return (
                                    <div style={this.renders.width(key)} className={
                                        cx("Optimize-Query-Item-Section",
                                            {
                                                ["Optimize-Query-Item-Section-Selected"]: this.state.sort.value === key,
                                            })
                                    }>
                                        <div className="Optimize-Query-Item-Section-Title">
                                            {key}
                                        </div>
                                        {
                                            key !== 'query' &&
                                            <div className="Optimize-Search-Item-Section-Value">
                                                {this.state.item[key]}{" "}{this.renders.ending(key)}
                                            </div>
                                        }
                                        {
                                            key === 'query' &&
                                            <div dangerouslySetInnerHTML={this.renders.markdown(this.renders.query())} className="Optimize-Search-Item-Section-Value"/>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="Optimize-Query-Button">
                        <div onClick={() => {
                            this.setState({
                                edit: !this.state.edit,
                                query: this.state.item.query,
                            }, () => {
                                this.setState({
                                    type: {name: 'Exact match', value: 'exact'},
                                    level: {name: 'Add to Campaign', value: 'campaign'}
                                })
                            })
                        }} className="Button ButtonFull ButtonRed">
                            {
                                !this.state.edit &&
                                <FeatherIcon color={"#ffffff"} size={23} icon="thumbs-down"/>
                            }
                            {
                                this.state.edit &&
                                <FeatherIcon color={"#ffffff"} size={23} icon="minus"/>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.edit &&
                    <div className="Optimize-Query-Item-Container-Inner">
                        {
                            this.state.loading &&
                            <div className="Optimize-Query-Item-Values-Loading-searchTerm">
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={10}
                                    color={'#388f8a'}
                                    loading={true}
                                />
                            </div>
                        }
                        <div className="Optimize-Query-Item-Container-Inner-input">
                            <Input
                                noTitle={true}
                                sign={true}
                                signTitle={'Edit query'}
                                type={'text'}
                                value={this.state.query}
                                size={'full'}
                                onChange={(event) => this.setState({query: event.target.value})}
                            />
                        </div>
                        <div className="Optimize-Query-Item-Container-Inner-dropdown">
                            <Dropdown
                                noTitle={true}
                                filter={false}
                                disabled={false}
                                size={'full'}
                                options={[{name: 'Exact match', value: 'exact'}, {name: 'Phrase match', value: 'phrase'}, {name: 'Broad match', value: 'broad'}]}
                                value={this.state.type}
                                onChange={(value) => {
                                    this.setState({
                                        type: value
                                    })
                                }}
                            />
                        </div>
                        <div className="Optimize-Query-Item-Container-Inner-dropdown">
                            <Dropdown
                                noTitle={true}
                                filter={false}
                                disabled={false}
                                size={'full'}
                                options={[{name: 'Add to Campaign', value: 'campaign'}]}
                                value={this.state.level}
                                onChange={(value) => {
                                    this.setState({
                                        level: value
                                    })
                                }}
                            />
                        </div>
                        <div className="Optimize-Query-Item-Container-Inner-Button">
                            <div onClick={() => {
                                this.functions.addNegative();
                            }} className="Button ButtonFull ButtonNeutral">
                                <FeatherIcon color={"#333333"} size={23} icon="plus"/>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default OptimizeSearchQueriesItem;
