import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import Input from "../input";
import ClientFacebook from "../client-facebook";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import UploadImage from "../image-upload";
import AgencyUsers from "../agency-users";
import {calls} from "./calls";

class Agency extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            open: false,
            loading: false,
            warning_modal: false
        };
    };

    componentWillMount() {
        this.setState({
            item: this.props.item
        })
    }

    init = {};

    functions = {
        remove: () => {
            this.setState({
                loading: true,
                warning_modal: false,
            }, () => {
                calls.removeAgency(this.state.item.id).then((response) => {
                    this.setState({
                        loading: false
                    }, () => {
                        this.props.onRemove();
                    })
                }, (error) => {
                    this.setState({
                        loading: false
                    })
                });
            })
        }
    };

    renders = {};

    render() {
        return (
            <div className="Client">
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove agency"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.remove();
                    }}
                    onCancel={() => {
                        this.setState({warning_modal: false});
                    }}
                />
                <div className="Client-InnerContainerInner">
                    <div onClick={() => {
                        this.setState({open: !this.state.open})
                    }} className="Client-InnerContainerInnerTop">
                        <div className="Client-InnerContainerInnerTopTitle">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Name
                            </div>
                            <div className="Client-InnerContainerInnerTopTitleValue">
                                {this.state.item.name}
                            </div>
                        </div>
                        <div className="Client-InnerContainerInnerTopTitle">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Company
                            </div>
                            <div className="Client-InnerContainerInnerTopTitleValue">
                                {this.state.item.company}
                            </div>
                        </div>
                        <div className="Client-InnerContainerInnerTopTitle">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Users
                            </div>
                            <div className="Client-InnerContainerInnerTopTitleValue">
                                {this.state.item.users?this.state.item.users:'0'}
                            </div>
                        </div>
                        <div className="Client-InnerContainerInnerTopArrowContainer">
                            <FeatherIcon className={
                                cx("Client-InnerContainerInnerTopArrow",
                                    {
                                        ["Client-InnerContainerInnerTopArrowOpen"]: this.state.open,
                                    })
                            } color={"#333333"} size={20} icon="plus"/>
                            <div className="Client-InnerContainerInnerTopMinusContainer">
                                <FeatherIcon className={
                                    cx("Client-InnerContainerInnerTopMinus",
                                        {
                                            ["Client-InnerContainerInnerTopMinusOpen"]: this.state.open,
                                        })
                                } color={"#333333"} size={20} icon="minus"/>
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div style={{padding: '15px', paddingTop: '20px'}} className="Section">
                            <Input
                                title={'Name'}
                                value={this.state.item.name}
                                error={this.state.error === "name"}
                                placeholder={""}
                                disabled={true}
                                size={'half'}
                                type={"text"}
                                onChange={(value) => {
                                    this.state.item.name = value.target.value;
                                    this.setState({item: this.state.item});
                                }}
                            />
                            <Input
                                title={'Company'}
                                value={this.state.item.company}
                                error={this.state.error === "company"}
                                size={"half"}
                                placeholder={""}
                                disabled={true}
                                type={"text"}
                            />
                        </div>
                        <div style={{padding: '15px', paddingTop: '0px'}} className="Section">
                            <Input
                                title={'Email'}
                                value={this.state.item.email}
                                error={this.state.error === "email"}
                                placeholder={""}
                                disabled={true}
                                size={'half'}
                                type={"text"}
                            />
                            <Input
                                title={'System fee'}
                                value={this.state.item.fee}
                                error={this.state.error === "fee"}
                                placeholder={""}
                                disabled={true}
                                size={'half'}
                                type={"text"}
                            />
                        </div>
                        <div style={{padding: '15px', paddingTop: '0px'}} className="Section">
                            <UploadImage
                                title={"Logo"}
                                size={"full"}
                                cover={false}
                                logo={true}
                                disabled={true}
                                value={this.state.item.logo}
                                error={this.state.error === "logo" && !this.state.logo}
                                onChange={(url) => this.setState({logo: url}, () => {

                                })}
                            />
                        </div>
                        <div style={{padding: '15px', paddingTop: '0px'}} className="Section">
                            <div className="Button ButtonNeutral">
                                Edit
                            </div>
                            <div onClick={() => {
                                this.setState({
                                    warning_modal: true
                                })
                            }} className="Button ButtonRed">
                                Remove
                            </div>
                        </div>
                        {
                            this.state.open &&
                            <AgencyUsers item={this.state.item}/>
                        }
                    </SlideDown>
                </div>
            </div>
        )
    }
}

export default Agency;
