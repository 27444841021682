import React, { Component } from 'react';
import InputTailwind from './inputTailwind';
import DropdownTailwind from './dropdownTailwind';
import SwitchTailwind from './switchTailwind';
import TextAreaTailwind from './textAreaTailwind';
import cn from 'classnames';
import moment from 'moment';
import UploadImage from '../components/image-upload';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTimepickerTailwind from './inputTimepickerTailwind';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';

class ExternalReportSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: {},
            errors: {},
            open: {},
            title: "",
            logo: { id: 3, name: 'No logo', value: "none" },
            description: "",
            public: false,
            shadows: false,
            datelock: false,
            pdfDownload: false,
            preSync: false,
            pageSections: false,
            password: "",
            public_password: false,
            disable_comparison: false,
            transparent_cells: false,
            background_color: "f5f5f5",
            navbar_color: "ffffff",
            accent_color: "453FF1",
            font_color: "333333",
            logo_size: { id: 2, name: 'Medium', value: "h-5" },
            selectedId: "",
            tabs: [
                { id: 0, name: "General" },
                { id: 1, name: "Navbar" },
                { id: 2, name: "Footer" },
                { id: 3, name: "Email settings" }
            ],
            tab: { id: 0, name: "General" },
            inherit_styles: false,
            footerSettings: {},
            emailSettings: {},
            reportId: null
        }
    }

    async componentDidMount() {
        await this.promisedSetState({
            title: JSON.parse(JSON.stringify(this.props.title)),
            logo: JSON.parse(JSON.stringify(this.props.logo)),
            description: JSON.parse(JSON.stringify(this.props.description)),
            public: JSON.parse(JSON.stringify(this.props.public)),
            master_template: this.props.masterTemplate,
            template: this.props.template,
            ...this.props.professional && { reportId: this.props.reportId },
            ...this.props.professional && { shadows: JSON.parse(JSON.stringify(this.props.shadows)) },
            ...this.props.professional && { disable_comparison: JSON.parse(JSON.stringify(this.props.disable_comparison)) },
            ...this.props.professional && { public_password: JSON.parse(JSON.stringify(this.props.public_password)) },
            ...this.props.professional && { password: JSON.parse(JSON.stringify(this.props.password)) },
            ...this.props.professional && { datelock: JSON.parse(JSON.stringify(this.props.datelock)) },
            ...this.props.professional && { pdfDownload: JSON.parse(JSON.stringify(this.props.pdfDownload)) },
            ...this.props.professional && { preSync: JSON.parse(JSON.stringify(this.props.preSync)) },
            ...this.props.professional && { pageSections: JSON.parse(JSON.stringify(this.props.pageSections)) },
            ...this.props.professional && { transparent_cells: JSON.parse(JSON.stringify(this.props.transparent_cells)) },
            ...this.props.professional && { logo_size: JSON.parse(JSON.stringify(this.props.logo_size)) },
            ...this.props.professional && { background_color: this.props.background_color ? JSON.parse(JSON.stringify(this.props.background_color)) : this.state.background_color },
            ...this.props.professional && { navbar_color: this.props.navbar_color ? JSON.parse(JSON.stringify(this.props.navbar_color)) : this.state.navbar_color },
            ...this.props.professional && { accent_color: this.props.accent_color ? JSON.parse(JSON.stringify(this.props.accent_color)) : this.state.accent_color },
            ...this.props.professional && { font_color: this.props.font_color ? JSON.parse(JSON.stringify(this.props.font_color)) : this.state.font_color },
            ...this.props.professional && { inherit_styles: JSON.parse(JSON.stringify(this.props.inherit_styles)) },
            ...this.props.professional && { footerSettings: this.props.footerSettings ? JSON.parse(JSON.stringify(this.props.footerSettings)) : this.state.footerSettings },
            ...this.props.professional && { emailSettings: this.props.emailSettings ? JSON.parse(JSON.stringify(this.props.emailSettings)) : this.state.emailSettings },
        })
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            title: JSON.parse(JSON.stringify(nextProps.title)),
            logo: JSON.parse(JSON.stringify(nextProps.logo)),
            description: JSON.parse(JSON.stringify(nextProps.description)),
            public: JSON.parse(JSON.stringify(nextProps.public)),
            master_template: nextProps.masterTemplate,
            template: nextProps.template,
            ...this.props.professional && { reportId: nextProps.reportId },
            ...this.props.professional && { shadows: JSON.parse(JSON.stringify(nextProps.shadows)) },
            ...this.props.professional && { disable_comparison: JSON.parse(JSON.stringify(nextProps.disable_comparison)) },
            ...this.props.professional && { public_password: JSON.parse(JSON.stringify(nextProps.public_password)) },
            ...this.props.professional && { password: JSON.parse(JSON.stringify(nextProps.password)) },
            ...this.props.professional && { datelock: JSON.parse(JSON.stringify(nextProps.datelock)) },
            ...this.props.professional && { pdfDownload: JSON.parse(JSON.stringify(nextProps.pdfDownload)) },
            ...this.props.professional && { preSync: JSON.parse(JSON.stringify(nextProps.preSync)) },
            ...this.props.professional && { pageSections: JSON.parse(JSON.stringify(nextProps.pageSections)) },
            ...this.props.professional && { transparent_cells: JSON.parse(JSON.stringify(nextProps.transparent_cells)) },
            ...this.props.professional && { logo_size: JSON.parse(JSON.stringify(nextProps.logo_size)) },
            ...this.props.professional && { background_color: nextProps.background_color ? JSON.parse(JSON.stringify(nextProps.background_color)) : this.state.background_color },
            ...this.props.professional && { navbar_color: nextProps.navbar_color ? JSON.parse(JSON.stringify(nextProps.navbar_color)) : this.state.navbar_color },
            ...this.props.professional && { accent_color: nextProps.accent_color ? JSON.parse(JSON.stringify(nextProps.accent_color)) : this.state.accent_color },
            ...this.props.professional && { font_color: nextProps.font_color ? JSON.parse(JSON.stringify(nextProps.font_color)) : this.state.font_color },
            ...this.props.professional && { inherit_styles: JSON.parse(JSON.stringify(nextProps.inherit_styles)) },
            ...this.props.professional && { footerSettings: nextProps.footerSettings ? JSON.parse(JSON.stringify(nextProps.footerSettings)) : this.state.footerSettings },
            ...this.props.professional && { emailSettings: nextProps.emailSettings ? JSON.parse(JSON.stringify(nextProps.emailSettings)) : this.state.emailSettings },
        })
    }

    functions = {
        update: async () => {
            let updated_settings = {
                title: this.state.title,
                logo: this.state.logo,
                description: this.state.description,
                public: this.state.public,
                background_color: this.state.background_color,
                shadows: this.state.shadows,
                datelock: this.state.datelock,
                pdfDownload: this.state.pdfDownload,
                preSync: this.state.preSync,
                pageSections: this.state.pageSections,
                transparent_cells: this.state.transparent_cells,
                navbar_color: this.state.navbar_color,
                accent_color: this.state.accent_color,
                font_color: this.state.font_color,
                logo_size: this.state.logo_size,
                public_password: this.state.public_password,
                password: this.state.password,
                disable_comparison: this.state.disable_comparison,
                inherit_styles: this.state.inherit_styles,
                footerSettings: this.state.footerSettings,
                emailSettings: this.state.emailSettings,
            };
            this.props.onSave(updated_settings)
        },
        sendEmail: async () => {
            this.setState({
                loadingSender: true
            });
            try {
                await this.calls.sendEmail();
                this.setState({
                    senderWarning: false,
                    senderSuccess: true
                });
            } catch (error) {
                this.setState({
                    senderSuccess: false,
                    senderWarning: error && error.body ? error.body.message : "Something went wrong"
                });
            }
            this.setState({
                loadingSender: false
            });
        }
    }

    calls = {
        sendEmail: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {
                reciever: this.state.emailSettings.reciever
            });
            let url = apiRegister.url.api + "/v3/adcredo/sendReport?report=" + this.state.reportId;
            return apiRegister.call(options, url);
        }
    }

    renders = {}

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className='flex flex-col px-6'>
                <div className="col-span-6 mb-4">
                    <div className="block overflow-scroll">
                        <nav className="flex space-x-4" aria-label="Tabs">
                            {
                                this.state.tabs.filter((item) => {
                                    if (this.props.professional && (!this.state.master_template || (this.state.master_template && this.state.template))) {
                                        return true;
                                    } else {
                                        return item.id == 0
                                    }
                                }).map((tab, index) => {
                                    //if (!this.state.public && tab.id !== 0) { return }
                                    return (
                                        <div
                                            onClick={() => (
                                                this.state.inherit_styles && (tab.id !== 0 && tab.id !== 3) ? null :
                                                    this.setState({
                                                        tab: tab
                                                    })
                                            )}
                                            key={tab.name}
                                            style={{ whiteSpace: "nowrap" }}
                                            className={cn(
                                                tab.id == this.state.tab.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex',
                                                this.state.inherit_styles && (tab.id !== 0 && tab.id !== 3) && "cursor-not-allowed"
                                            )}
                                            aria-current={tab.id == this.state.tab.id ? 'page' : undefined}
                                        >
                                            {tab.name}
                                        </div>
                                    )
                                })
                            }
                        </nav>
                    </div>
                </div>

                {
                    this.state.tab.id === 0 &&
                    <>
                        <div className='grid grid-cols-3 gap-4'>

                            <div className="col-span-1">
                                <div className='flex flex-col w-full'>
                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>External report</div>
                                    <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 ml-5 text-sm">
                                            {this.state.public ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.public ? true : false}
                                                onSwitch={async () => {
                                                    this.promisedSetState({ public: !this.state.public })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                (!this.state.master_template || (this.state.master_template && this.state.template)) &&
                                !this.props.professional &&
                                <div className={`col-span-2 ${!this.state.public && "opacity-50"}`}>
                                    <InputTailwind
                                        disabled={!this.state.public}
                                        value={this.state.title}
                                        label='Title'
                                        placeholder='Title'
                                        onChange={(value) => {
                                            this.promisedSetState({
                                                title: value
                                            })
                                        }}
                                    />
                                </div>
                            }

                            {
                                (!this.state.master_template || (this.state.master_template && this.state.template)) &&
                                this.props.professional &&
                                <div className={`col-span-1 ${!this.state.public && "opacity-50"}`}>
                                    <div className='flex flex-col w-full'>
                                        <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Password protect</div>
                                        <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 ml-5 text-sm">
                                                {this.state.public_password ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    disabled={!this.state.public}
                                                    value={this.state.public_password ? true : false}
                                                    onSwitch={async () => {
                                                        this.promisedSetState({ public_password: !this.state.public_password })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                (!this.state.master_template || (this.state.master_template && this.state.template)) &&
                                this.props.professional &&
                                <div className={`col-span-1 ${(!this.state.public || !this.state.public_password) && "opacity-50"}`}>
                                    <div className='flex flex-col w-full'>
                                        <InputTailwind
                                            disabled={!(this.state.public && this.state.public_password)}
                                            value={this.state.public_password ? this.state.password : ""}
                                            label='Password'
                                            placeholder='Password'
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    password: value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            }

                        </div>

                        {
                            (!this.state.master_template || (this.state.master_template && this.state.template)) &&
                            this.props.professional &&
                            <>
                                <div className={`grid grid-cols-1 gap-4 mb-4 ${!this.state.public && "opacity-50"}`}>
                                    <div className='col-span-1'>
                                        <InputTailwind
                                            disabled={!this.state.public}
                                            value={this.state.title}
                                            label='Title'
                                            placeholder='Title'
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    title: value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>

                                {
                                    this.props.professional &&
                                    <div className='grid grid-cols-2 gap-4 mb-4'>
                                        <div className='col-span-1'>
                                            {
                                                this.props.professional &&
                                                <div className={`col-span-1 ${!this.state.inherit_styles ? "opacity-100" : "opacity-50"}`}>
                                                    <div className="text-xs font-medium mb-1">
                                                        Background color
                                                    </div>
                                                    <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "background_color" ? "border-purple-500" : ""}`}>
                                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                                            <div
                                                                style={{ backgroundColor: "#fcfcfc" }}
                                                                className="flex items-center col-span-3 p-1">
                                                                <span className={`pl-3`}>#</span>
                                                                <input
                                                                    id="color_navbar"
                                                                    className={`w-full border-none p-2 bg-transparent ${!!this.state.inherit_styles && "cursor-not-allowed"}`}
                                                                    type="text"
                                                                    placeholder='000000'
                                                                    disabled={!this.state.inherit_styles ? false : true}
                                                                    value={this.state.background_color ? this.state.background_color : "ffffff"}
                                                                    onClick={(e) => {
                                                                        this.setState({ selectedId: e.target.id })
                                                                    }}
                                                                    onChange={async (e) => {
                                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                                        this.state.background_color = e.target.value;
                                                                        await this.promisedSetState({
                                                                            background_color: this.state.background_color
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                                <input
                                                                    style={{ width: 300, height: 65, bottom: -10 }}
                                                                    className={`absolute ${!this.state.inherit_styles ? "cursor-pointer" : "cursor-not-allowed"}`}
                                                                    type="color"
                                                                    value={"#" + (this.state.background_color ? this.state.background_color : "ffffff")}
                                                                    disabled={!this.state.inherit_styles ? false : true}
                                                                    onChange={async (e) => {
                                                                        let color_string = e.target.value.slice(1)
                                                                        this.state.background_color = color_string;
                                                                        await this.promisedSetState({
                                                                            background_color: this.state.background_color
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={`flex flex-col w-full ${this.state.public ? "opacity-100" : "opacity-50"}`}>
                                            <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Inherit agency style settings</div>
                                            <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 text-sm ml-5">
                                                    {this.state.inherit_styles ? "Enabled" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        disabled={!this.state.public}
                                                        value={this.state.inherit_styles ? true : false}
                                                        onSwitch={async () => {
                                                            this.state.inherit_styles = !this.state.inherit_styles;
                                                            await this.promisedSetState({
                                                                inherit_styles: this.state.inherit_styles
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className='grid grid-cols-4 gap-4 mb-4'>
                                    <div className={`col-span-1${!this.state.public && "opacity-50"}`}>
                                        <div className={`w-full ${!this.state.public && "opacity-50"}`}>
                                            <div className='flex flex-col w-full'>
                                                <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Allow date change</div>
                                                <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className={`flex flex-1 text-sm ml-5 ${!this.state.public && "opacity-50"}`}>
                                                        {this.state.datelock ? "Enabled" : "Disabled"}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            disabled={this.state.public ? false : true}
                                                            value={this.state.datelock ? true : false}
                                                            onSwitch={async () => {
                                                                this.promisedSetState({ datelock: !this.state.datelock })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-span-1${!this.state.public && "opacity-50"}`}>
                                        <div className={`w-full ${!this.state.public && "opacity-50"}`}>
                                            <div className='flex flex-col w-full'>
                                                <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Allow pdf download</div>
                                                <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className={`flex flex-1 text-sm ml-5 ${!this.state.public && "opacity-50"}`}>
                                                        {this.state.pdfDownload ? "Enabled" : "Disabled"}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            disabled={this.state.public ? false : true}
                                                            value={this.state.pdfDownload ? true : false}
                                                            onSwitch={async () => {
                                                                this.promisedSetState({ pdfDownload: !this.state.pdfDownload })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-span-1${!this.state.public && "opacity-50"}`}>
                                        <div className={`w-full ${!this.state.public && "opacity-50"}`}>
                                            <div className='flex flex-col w-full'>
                                                <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Date comparison</div>
                                                <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className={`flex flex-1 text-sm ml-5 ${!this.state.public && "opacity-50"}`}>
                                                        {!this.state.disable_comparison ? "Enabled" : "Disabled"}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            disabled={this.state.public ? false : true}
                                                            value={!this.state.disable_comparison ? true : false}
                                                            onSwitch={async () => {
                                                                this.promisedSetState({ disable_comparison: !this.state.disable_comparison })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-span-1${!this.state.public && "opacity-50"}`}>
                                        <div className={`w-full ${!this.state.public && "opacity-50"}`}>
                                            <div className='flex flex-col w-full'>
                                                <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Pre-load external report (beta)</div>
                                                <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className={`flex flex-1 text-sm ml-5 ${!this.state.public && "opacity-50"}`}>
                                                        {this.state.preSync ? "Enabled" : "Disabled"}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            disabled={this.state.public ? false : true}
                                                            value={this.state.preSync ? true : false}
                                                            onSwitch={async () => {
                                                                this.promisedSetState({ preSync: !this.state.preSync })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`${!this.state.public && "opacity-50"}`}>
                                    <TextAreaTailwind
                                        disabled={!this.state.public}
                                        label='Description'
                                        placeholder='Description'
                                        textarea={true}
                                        rows={5}
                                        value={this.state.description}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                description: value
                                            })
                                        }}
                                    />
                                </div>

                            </>
                        }

                    </>
                }

                {
                    this.props.professional && this.state.tab.id === 1 &&
                    <>
                        <div className={`${this.state.public ? "grid-cols-6" : "grid-cols-4"} grid gap-4 mb-4 ${!this.state.public && "opacity-50"}`}>
                            <div className={`${!this.props.professional ? "col-span-4" : "col-span-2"}`}>
                                <div className='flex flex-col w-full'>
                                    <DropdownTailwind
                                        disabled={!this.state.public}
                                        label='Logo'
                                        selected={this.state.logo && this.state.logo.value ? this.state.logo : { id: 3, name: 'No logo', value: "none" }}
                                        options={[
                                            { id: 1, name: "Agency logo", value: "agency" },
                                            { id: 2, name: "Client logo", value: "client" },
                                            { id: 3, name: "No logo", value: "none" },
                                        ]}
                                        onChange={(value) => {
                                            this.promisedSetState({
                                                logo: value
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                this.props.professional &&
                                <div className="col-span-2">
                                    <div className='flex flex-col w-full'>
                                        <DropdownTailwind
                                            disabled={!this.state.public}
                                            label='Logo size'
                                            selected={this.state.logo_size && this.state.logo_size.value ? this.state.logo_size : { id: 2, name: 'Medium', value: "h-5" }}
                                            options={[
                                                { id: 1, name: "Large", value: "h-8" },
                                                { id: 2, name: "Medium", value: "h-5" },
                                                { id: 3, name: "Small", value: "h-3" },
                                            ]}
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    logo_size: value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.props.professional &&
                                <div className="col-span-2">
                                    <div className='flex flex-col w-full'>
                                        <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Show page sections</div>
                                        <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                            <div className="flex flex-1 text-sm ml-5">
                                                {this.state.pageSections && this.state.pageSections ? "Enabled" : "Disabled"}
                                            </div>
                                            <div className="relative overflow-hidden mr-5">
                                                <SwitchTailwind
                                                    value={this.state.pageSections}
                                                    onSwitch={async () => {
                                                        this.state.pageSections = !this.state.pageSections;
                                                        await this.promisedSetState({
                                                            pageSections: this.state.pageSections
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className='grid grid-cols-3 gap-4'>
                            <div className='flex flex-col w-full'>
                                <div className={` text-xs font-medium text-gray-700 flex flex-1 ${!this.state.public && "opacity-50"}`}>Accent color</div>
                                <div className='flex w-full mt-2 space-x-4 items-end'>
                                    <div className={`w-full border-1.5 rounded-md ${!this.state.public && "opacity-50"} ${this.state.selectedId === "accent_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#fcfcfc" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="accent_color"
                                                    className={`w-full border-none p-2 bg-transparent ${!this.state.public && "cursor-not-allowed"}`}
                                                    type="text"
                                                    placeholder='453ff1'
                                                    disabled={this.state.public ? false : true}
                                                    value={this.state.accent_color}
                                                    onClick={(e) => {
                                                        this.promisedSetState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        await this.promisedSetState({ accent_color: e.target.value })
                                                    }}
                                                />
                                            </div>

                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 300, height: 65, bottom: -10 }}
                                                    className={`absolute ${this.state.public ? "cursor-pointer" : "cursor-not-allowed"}`}
                                                    type="color"
                                                    disabled={this.state.public ? false : true}
                                                    value={"#" + this.state.accent_color}
                                                    onChange={async (e) => {

                                                        let color_string = e.target.value.slice(1)
                                                        await this.promisedSetState({ accent_color: color_string })
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-col w-full'>
                                <div className={` text-xs font-medium text-gray-700 flex flex-1 ${!this.state.public && "opacity-50"}`}>Font color</div>
                                <div className='flex w-full mt-2 space-x-4 items-end'>
                                    <div className={`w-full border-1.5 rounded-md ${!this.state.public && "opacity-50"} ${this.state.selectedId === "font_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#fcfcfc" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="font_color"
                                                    className={`w-full border-none p-2 bg-transparent ${!this.state.public && "cursor-not-allowed"}`}
                                                    type="text"
                                                    placeholder='333333'
                                                    disabled={this.state.public ? false : true}
                                                    value={this.state.font_color}
                                                    onClick={(e) => {
                                                        this.promisedSetState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        await this.promisedSetState({ font_color: e.target.value, selected: true })
                                                    }}
                                                />
                                            </div>

                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 300, height: 65, bottom: -10 }}
                                                    className={`absolute ${this.state.public ? "cursor-pointer" : "cursor-not-allowed"}`}
                                                    type="color"
                                                    disabled={this.state.public ? false : true}
                                                    value={"#" + this.state.font_color}
                                                    onChange={async (e) => {
                                                        let color_string = e.target.value.slice(1)
                                                        await this.promisedSetState({ font_color: color_string })
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-col w-full'>
                                <div className={` text-xs font-medium text-gray-700 flex flex-1 ${!this.state.public && "opacity-50"}`}>Navbar color</div>
                                <div className='flex w-full mt-2 space-x-4 items-end'>
                                    <div className={`w-full border-1.5 rounded-md ${!this.state.public && "opacity-50"} ${this.state.selectedId === "navbar_color" ? "border-purple-500" : ""}`}>
                                        <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                            <div
                                                style={{ backgroundColor: "#fcfcfc" }}
                                                className="flex items-center col-span-3 p-1">
                                                <span className={`pl-3`}>#</span>
                                                <input
                                                    id="navbar_color"
                                                    className={`w-full border-none p-2 bg-transparent ${!this.state.public && "cursor-not-allowed"}`}
                                                    type="text"
                                                    placeholder='ffffff'
                                                    disabled={this.state.public ? false : true}
                                                    value={this.state.navbar_color}
                                                    onClick={(e) => {
                                                        this.promisedSetState({ selectedId: e.target.id })
                                                    }}
                                                    onChange={async (e) => {
                                                        e.target.value = e.target.value.replace(/#/g, "")
                                                        await this.promisedSetState({ navbar_color: e.target.value })
                                                    }}
                                                />
                                            </div>

                                            <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                                <input
                                                    style={{ width: 300, height: 65, bottom: -10 }}
                                                    className={`absolute ${this.state.public ? "cursor-pointer" : "cursor-not-allowed"}`}
                                                    type="color"
                                                    disabled={this.state.public ? false : true}
                                                    value={"#" + this.state.navbar_color}
                                                    onChange={async (e) => {
                                                        let color_string = e.target.value.slice(1)
                                                        await this.promisedSetState({ navbar_color: color_string })
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {
                    this.state.tab.id === 2 &&
                    <>
                        <div className='grid grid-cols-5 gap-4 mb-4'>
                            <div className='col-span-1'>
                                <div className='flex flex-col w-full'>
                                    <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Footer</div>
                                    <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 text-sm ml-5">
                                            {this.state.footerSettings && this.state.footerSettings.enabled ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.footerSettings && this.state.footerSettings.enabled ? true : false}
                                                onSwitch={async () => {
                                                    this.state.footerSettings.enabled = !this.state.footerSettings.enabled;
                                                    await this.promisedSetState({
                                                        footerSettings: this.state.footerSettings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-span-1 ${this.state.footerSettings && this.state.footerSettings.enabled ? "opacity-100" : "opacity-50"}`}>
                                <div className="text-xs font-medium mb-1">
                                    Footer color
                                </div>
                                <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_navbar" ? "border-purple-500" : ""}`}>
                                    <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                        <div
                                            style={{ backgroundColor: "#fcfcfc" }}
                                            className="flex items-center col-span-3 p-1">
                                            <span className={`pl-3`}>#</span>
                                            <input
                                                id="color_navbar"
                                                className={`w-full border-none p-2 bg-transparent ${this.state.footerSettings && !this.state.footerSettings.enabled && "cursor-not-allowed"}`}
                                                type="text"
                                                placeholder='000000'
                                                disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                                value={this.state.footerSettings && this.state.footerSettings.color_footer ? this.state.footerSettings.color_footer : "ffffff"}
                                                onClick={(e) => {
                                                    this.setState({ selectedId: e.target.id })
                                                }}
                                                onChange={async (e) => {
                                                    e.target.value = e.target.value.replace(/#/g, "")
                                                    this.state.footerSettings.color_footer = e.target.value;
                                                    await this.promisedSetState({
                                                        footerSettings: this.state.footerSettings
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                            <input
                                                style={{ width: 300, height: 65, bottom: -10 }}
                                                className={`absolute ${this.state.footerSettings && this.state.footerSettings.enabled ? "cursor-pointer" : "cursor-not-allowed"}`}
                                                type="color"
                                                value={"#" + (this.state.footerSettings && this.state.footerSettings.color_footer ? this.state.footerSettings.color_footer : "ffffff")}
                                                disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                                onChange={async (e) => {
                                                    let color_string = e.target.value.slice(1)
                                                    this.state.footerSettings.color_footer = color_string;
                                                    await this.promisedSetState({
                                                        footerSettings: this.state.footerSettings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`col-span-1 ${this.state.footerSettings && this.state.footerSettings.enabled ? "opacity-100" : "opacity-50"}`}>
                                <div className="text-xs font-medium mb-1">
                                    Font color
                                </div>
                                <div className={`w-full border-1.5 rounded-md ${this.state.selectedId === "color_footer_font" ? "border-purple-500" : ""}`}>
                                    <div className='grid grid-cols-4 overflow-hidden rounded-md'>
                                        <div
                                            style={{ backgroundColor: "#fcfcfc" }}
                                            className="flex items-center col-span-3 p-1">
                                            <span className={`pl-3`}>#</span>
                                            <input
                                                id="color_footer_font"
                                                className={`w-full border-none p-2 bg-transparent ${this.state.footerSettings && !this.state.footerSettings.enabled && "cursor-not-allowed"}`}
                                                type="text"
                                                placeholder='333333'
                                                disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                                value={this.state.footerSettings && this.state.footerSettings.color_footer_font ? this.state.footerSettings.color_footer_font : "333333"}
                                                onClick={(e) => {
                                                    this.setState({ selectedId: e.target.id })
                                                }}
                                                onChange={async (e) => {
                                                    e.target.value = e.target.value.replace(/#/g, "")
                                                    this.state.footerSettings.color_footer_font = e.target.value;
                                                    await this.promisedSetState({
                                                        footerSettings: this.state.footerSettings
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="relative overflow-hidden col-span-1 border-l-1.5">
                                            <input
                                                style={{ width: 300, height: 65, bottom: -10 }}
                                                className={`absolute ${this.state.footerSettings && this.state.footerSettings.enabled ? "cursor-pointer" : "cursor-not-allowed"}`}
                                                type="color"
                                                disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                                value={"#" + (this.state.footerSettings && this.state.footerSettings.color_footer_font ? this.state.footerSettings.color_footer_font : "333333")}
                                                onChange={async (e) => {
                                                    // if (!this.state.footerSettings) {
                                                    //     this.state.footerSettings = { color_accent: "" };
                                                    // }
                                                    let color_string = e.target.value.slice(1)
                                                    this.state.footerSettings.color_footer_font = color_string;
                                                    await this.promisedSetState({
                                                        footerSettings: this.state.footerSettings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="w-full grid grid-cols-5 gap-4">
                            <div className={`col-span-1 ${this.state.footerSettings && this.state.footerSettings.enabled ? "opacity-100" : "opacity-50"}`}>
                                <div className="flex flex-col space-y-2">
                                    <div>
                                        <InputTailwind
                                            label="Link 1 text"
                                            disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                            value={this.state.footerSettings && this.state.footerSettings.link_1 && this.state.footerSettings.link_1.name ? this.state.footerSettings.link_1.name : ""}
                                            onChange={async (value) => {
                                                if (!this.state.footerSettings.link_1) {
                                                    this.state.footerSettings.link_1 = { name: "", url: "" };
                                                }
                                                this.state.footerSettings.link_1.name = value
                                                await this.promisedSetState({
                                                    footerSettings: this.state.footerSettings
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <InputTailwind
                                            label="URL"
                                            disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                            value={this.state.footerSettings && this.state.footerSettings.link_1 && this.state.footerSettings.link_1.url ? this.state.footerSettings.link_1.url : ""}
                                            onChange={async (value) => {
                                                if (!this.state.footerSettings.link_1) {
                                                    this.state.footerSettings.link_1 = { name: "", url: "" };
                                                }
                                                this.state.footerSettings.link_1.url = value
                                                await this.promisedSetState({
                                                    footerSettings: this.state.footerSettings
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`col-span-1 ${this.state.footerSettings && this.state.footerSettings.enabled ? "opacity-100" : "opacity-50"}`}>
                                <div className="flex flex-col space-y-2">
                                    <div>
                                        <InputTailwind
                                            label="Link 2"
                                            disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                            value={this.state.footerSettings && this.state.footerSettings.link_2 && this.state.footerSettings.link_2.name ? this.state.footerSettings.link_2.name : ""}
                                            onChange={async (value) => {
                                                if (!this.state.footerSettings.link_2) {
                                                    this.state.footerSettings.link_2 = { name: "", url: "" };
                                                }
                                                this.state.footerSettings.link_2.name = value
                                                await this.promisedSetState({
                                                    footerSettings: this.state.footerSettings
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <InputTailwind
                                            label="URL"
                                            disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                            value={this.state.footerSettings && this.state.footerSettings.link_2 && this.state.footerSettings.link_2.url ? this.state.footerSettings.link_2.url : ""}
                                            onChange={async (value) => {
                                                if (!this.state.footerSettings.link_2) {
                                                    this.state.footerSettings.link_2 = { name: "", url: "" };
                                                }
                                                this.state.footerSettings.link_2.url = value
                                                await this.promisedSetState({
                                                    footerSettings: this.state.footerSettings
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`col-span-1 ${this.state.footerSettings && this.state.footerSettings.enabled ? "opacity-100" : "opacity-50"}`}>
                                <div className="flex flex-col space-y-2">
                                    <div>
                                        <InputTailwind
                                            label="Link 3 text"
                                            disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                            value={this.state.footerSettings && this.state.footerSettings.link_3 && this.state.footerSettings.link_3.name ? this.state.footerSettings.link_3.name : ""}
                                            onChange={async (value) => {
                                                if (!this.state.footerSettings.link_3) {
                                                    this.state.footerSettings.link_3 = { name: "", url: "" };
                                                }
                                                this.state.footerSettings.link_3.name = value
                                                await this.promisedSetState({
                                                    footerSettings: this.state.footerSettings
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <InputTailwind
                                            label="URL"
                                            disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                            value={this.state.footerSettings && this.state.footerSettings.link_3 && this.state.footerSettings.link_3.url ? this.state.footerSettings.link_3.url : ""}
                                            onChange={async (value) => {
                                                if (!this.state.footerSettings.link_3) {
                                                    this.state.footerSettings.link_3 = { name: "", url: "" };
                                                }
                                                this.state.footerSettings.link_3.url = value
                                                await this.promisedSetState({
                                                    footerSettings: this.state.footerSettings
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`col-span-1 ${this.state.footerSettings && this.state.footerSettings.enabled ? "opacity-100" : "opacity-50"}`}>
                                <div className="flex flex-col space-y-2">
                                    <div>
                                        <InputTailwind
                                            label="Link 4 text"
                                            disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                            value={this.state.footerSettings && this.state.footerSettings.link_4 && this.state.footerSettings.link_4.name ? this.state.footerSettings.link_4.name : ""}
                                            onChange={async (value) => {
                                                if (!this.state.footerSettings.link_4) {
                                                    this.state.footerSettings.link_4 = { name: "", url: "" };
                                                }
                                                this.state.footerSettings.link_4.name = value
                                                await this.promisedSetState({
                                                    footerSettings: this.state.footerSettings
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <InputTailwind
                                            label="URL"
                                            disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                            value={this.state.footerSettings && this.state.footerSettings.link_4 && this.state.footerSettings.link_4.url ? this.state.footerSettings.link_4.url : ""}
                                            onChange={async (value) => {
                                                if (!this.state.footerSettings.link_4) {
                                                    this.state.footerSettings.link_4 = { name: "", url: "" };
                                                }
                                                this.state.footerSettings.link_4.url = value
                                                await this.promisedSetState({
                                                    footerSettings: this.state.footerSettings
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={`col-span-1 ${this.state.footerSettings && this.state.footerSettings.enabled ? "opacity-100" : "opacity-50"}`}>
                                <div className="flex flex-col space-y-2">
                                    <div>
                                        <InputTailwind
                                            label="Link 5 text"
                                            disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                            value={this.state.footerSettings && this.state.footerSettings.link_5 && this.state.footerSettings.link_5.name ? this.state.footerSettings.link_5.name : ""}
                                            onChange={async (value) => {
                                                if (!this.state.footerSettings.link_5) {
                                                    this.state.footerSettings.link_5 = { name: "", url: "" };
                                                }
                                                this.state.footerSettings.link_5.name = value
                                                await this.promisedSetState({
                                                    footerSettings: this.state.footerSettings
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <InputTailwind
                                            label="URL text"
                                            disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                            value={this.state.footerSettings && this.state.footerSettings.link_5 && this.state.footerSettings.link_5.url ? this.state.footerSettings.link_5.url : ""}
                                            onChange={async (value) => {
                                                if (!this.state.footerSettings.link_5) {
                                                    this.state.footerSettings.link_5 = { name: "", url: "" };
                                                }
                                                this.state.footerSettings.link_5.url = value
                                                await this.promisedSetState({
                                                    footerSettings: this.state.footerSettings
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className='grid w-full grid-cols-5 mt-4 gap-4'>

                            <div className={`col-span-4 ${this.state.footerSettings && this.state.footerSettings.enabled ? "opacity-100" : "opacity-50"}`}>
                                <InputTailwind
                                    label="Footer text"
                                    disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                    value={this.state.footerSettings && this.state.footerSettings.footer_text ? this.state.footerSettings.footer_text : ""}
                                    onChange={async (value) => {
                                        if (!this.state.footerSettings.footer_text) {
                                            this.state.footerSettings.footer_text = "";
                                        }
                                        this.state.footerSettings.footer_text = value
                                        await this.promisedSetState({
                                            footerSettings: this.state.footerSettings
                                        })
                                    }}
                                />
                            </div>

                            <div className={`col-span-1 ${this.state.footerSettings && this.state.footerSettings.enabled ? "opacity-100" : "opacity-50"}`}>
                                <div className='flex flex-col w-full'>
                                    <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Copyright icon & year</div>
                                    <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className="flex flex-1 text-sm ml-5">
                                            {this.state.footerSettings && this.state.footerSettings.copyright ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                disabled={this.state.footerSettings && this.state.footerSettings.enabled ? false : true}
                                                value={this.state.footerSettings && this.state.footerSettings.copyright ? true : false}
                                                onSwitch={async () => {
                                                    this.state.footerSettings.copyright = !this.state.footerSettings.copyright;
                                                    await this.promisedSetState({
                                                        footerSettings: this.state.footerSettings
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                }

                {
                    this.props.professional &&
                    this.state.tab.id === 3 &&
                    <div className="">
                        <div className='grid w-full grid-cols-12 gap-4'>

                            <div className='col-span-4 relative'>
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Automated email</div>
                                <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5 text-sm font-medium">
                                        {this.state.emailSettings.automatedEmails ? "Active" : "Disabled"}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.state.emailSettings.automatedEmails}
                                            onSwitch={async () => {
                                                this.state.emailSettings.automatedEmails = !this.state.emailSettings.automatedEmails;
                                                await this.promisedSetState({
                                                    emailSettings: this.state.emailSettings
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {
                                !this.state.master_template &&
                                <div className='col-span-4 relative'>
                                    {
                                        !this.state.emailSettings.automatedEmails &&
                                        <div className="bg-white bg-opacity-75 z-30 absolute left-0 right-0 top-0 bottom-0"> </div>
                                    }
                                    <InputTailwind
                                        value={this.state.emailSettings.reciever}
                                        label='Reciever email'
                                        onChange={(value) => {
                                            this.state.emailSettings.reciever = value;
                                            this.setState({
                                                emailSettings: this.state.emailSettings
                                            })
                                        }}
                                    />
                                </div>
                            }

                            {
                                this.state.master_template &&
                                <div className='col-span-4 relative'>
                                    {
                                        !this.state.emailSettings.automatedEmails &&
                                        <div className="bg-white bg-opacity-75 z-30 absolute left-0 right-0 top-0 bottom-0"> </div>
                                    }
                                    <InputTailwind
                                        readOnly={true}
                                        locked={true}
                                        value={"<client email>"}
                                        label='Reciever email'
                                        onChange={(value) => {

                                        }}
                                    />
                                </div>
                            }

                            {
                                <div className={(this.state.emailSettings.automatedEmailsFrequency && this.state.emailSettings.automatedEmailsFrequency.value == "once_week" ? "col-span-4" : 'col-span-4') + " relative"}>
                                    {
                                        !this.state.emailSettings.automatedEmails &&
                                        <div className="bg-white bg-opacity-75 z-30 absolute left-0 right-0 top-0 bottom-0"> </div>
                                    }
                                    <DropdownTailwind
                                        label='Frequency'
                                        selected={this.state.emailSettings.automatedEmailsFrequency ? this.state.emailSettings.automatedEmailsFrequency : { id: 0, name: 'No frequency', value: "no_frequency" }}
                                        options={[
                                            { id: 0, name: 'No frequency', value: "no_frequency" },
                                            //{ id: 3, name: 'Once', value: "once" },
                                            { id: 1, name: 'Once a month (the 1st)', value: "once_month_start" },
                                            //{ id: 2, name: 'Once a week', value: "once_week" },
                                        ]}
                                        onChange={async (value) => {
                                            this.state.emailSettings.automatedEmailsFrequency = value;
                                            await this.promisedSetState({
                                                emailSettings: this.state.emailSettings
                                            })
                                        }}
                                    />
                                </div>
                            }

                            {
                                this.state.emailSettings.lastEmailSend &&
                                <div className="col-span-12">
                                    <div className="bg-blue-100 text-blue-500 font-medium text-sm p-4 rounded-md">
                                        Last email was send {this.state.emailSettings.lastEmailSend}
                                    </div>
                                </div>
                            }

                            {
                                !this.state.loadingSender &&
                                this.state.senderWarning &&
                                <div className="col-span-12">
                                    <div className="bg-red-100 text-red-500 font-medium text-sm p-4 rounded-md">
                                        {this.state.senderWarning}
                                    </div>
                                </div>
                            }

                            {
                                !this.state.loadingSender &&
                                this.state.senderSuccess &&
                                <div className="col-span-12">
                                    <div className="bg-green-100 text-green-500 font-medium text-sm p-4 rounded-md">
                                        Email send!
                                    </div>
                                </div>
                            }

                            {
                                this.state.emailSettings.automatedEmails &&
                                <div className="col-span-12">
                                    <button
                                        className="rounded-md shadow relative overflow-hidden whitespace-no-wrap bg-purple-500 py-2 px-4 text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                        onClick={() => {
                                            if (!this.state.loadingSender) {
                                                this.functions.sendEmail();
                                            }
                                        }}
                                    >
                                        Send report (email)
                                        {
                                            this.state.loadingSender &&
                                            <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                </div>
                            }

                            {
                                //this.state.emailSettings.automatedEmails &&
                                false &&
                                this.state.emailSettings.automatedEmailsFrequency && this.state.emailSettings.automatedEmailsFrequency.value == "once_week" &&
                                <div className='col-span-4 relative'>
                                    {
                                        (!this.state.emailSettings.overWrite || !this.state.emailSettings.automatedEmails) &&
                                        <div className="bg-white bg-opacity-75 z-30 absolute left-0 right-0 top-0 bottom-0"> </div>
                                    }
                                    <DropdownTailwind
                                        locked={!this.state.emailSettings.overWrite}
                                        readOnly={!this.state.emailSettings.overWrite}
                                        label='Week day'
                                        selected={this.state.emailSettings.automatedEmailsWeekday ? this.state.emailSettings.automatedEmailsWeekday : { id: 0, name: 'Monday', value: "monday" }}
                                        options={[
                                            { id: 0, name: 'Monday', value: "monday" },
                                            { id: 1, name: 'Tuesday', value: "tuesday" },
                                            { id: 2, name: 'Wednesday', value: "wednesday" },
                                            { id: 3, name: 'Thursday', value: "thursday" },
                                            { id: 4, name: 'Friday', value: "friday" },
                                            { id: 5, name: 'Saturday', value: "saturday" },
                                            { id: 6, name: 'Sunday', value: "sunday" }
                                        ]}
                                        onChange={async (value) => {
                                            this.state.emailSettings.automatedEmailsWeekday = value;
                                            await this.promisedSetState({
                                                emailSettings: this.state.emailSettings
                                            })
                                        }}
                                    />
                                </div>
                            }

                            {
                                //this.state.emailSettings.automatedEmails &&
                                false &&
                                this.state.emailSettings.automatedEmailsFrequency && this.state.emailSettings.automatedEmailsFrequency.value == "once" &&
                                <div className="col-span-2 relative">
                                    {
                                        (!this.state.emailSettings.overWrite || !this.state.emailSettings.automatedEmails) &&
                                        <div className="bg-white bg-opacity-75 z-30 absolute left-0 right-0 top-0 bottom-0"> </div>
                                    }
                                    <InputDatepickerTailwind
                                        locked={!this.state.emailSettings.overWrite}
                                        readOnly={!this.state.emailSettings.overWrite}
                                        label={"Date"}
                                        value={this.state.emailSettings.automatedEmailsNow ? moment(this.state.emailSettings.automatedEmailsNow).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                                        onChange={async (value) => {
                                            let time = moment().format("HH:mm");
                                            if (this.state.emailSettings.automatedEmailsNow) {
                                                time = moment(this.state.emailSettings.automatedEmailsNow).format("HH:mm");
                                            }
                                            let new_date = value + " " + time;
                                            this.state.emailSettings.automatedEmailsNow = new_date;
                                            await this.promisedSetState({
                                                emailSettings: this.state.emailSettings
                                            })
                                        }}
                                    />
                                </div>
                            }

                            {
                                //this.state.emailSettings.automatedEmails &&
                                false &&
                                this.state.emailSettings.automatedEmailsFrequency && this.state.emailSettings.automatedEmailsFrequency.value == "once" &&
                                <div className="col-span-2 relative">
                                    {
                                        (!this.state.emailSettings.overWrite || !this.state.emailSettings.automatedEmails) &&
                                        <div className="bg-white bg-opacity-75 z-30 absolute left-0 right-0 top-0 bottom-0"> </div>
                                    }
                                    <InputTimepickerTailwind
                                        locked={!this.state.emailSettings.overWrite}
                                        readOnly={!this.state.emailSettings.overWrite}
                                        label={"Time"}
                                        value={this.state.emailSettings.automatedEmailsNow ? moment(this.state.emailSettings.automatedEmailsNow).format("HH:mm") : moment().format("HH:mm")}
                                        onChange={async (value) => {
                                            let date = moment().format("YYYY-MM-DD");
                                            if (this.state.emailSettings.automatedEmailsNow) {
                                                date = moment(this.state.emailSettings.automatedEmailsNow).format("YYYY-MM-DD");
                                            }
                                            let new_date = date + " " + value;
                                            this.state.emailSettings.automatedEmailsNow = new_date;
                                            await this.promisedSetState({
                                                emailSettings: this.state.emailSettings
                                            })
                                        }}
                                    />
                                </div>
                            }

                            {
                                false &&
                                //this.state.emailSettings.automatedEmails &&
                                <div className='col-span-4 relative'>
                                    {
                                        (!this.state.emailSettings.overWrite || !this.state.emailSettings.automatedEmails) &&
                                        <div className="bg-white bg-opacity-75 z-30 absolute left-0 right-0 top-0 bottom-0"> </div>
                                    }
                                    <InputTailwind
                                        readOnly={true}
                                        value={this.state.emailSettings.lastEmailSend}
                                        label='Last email send'
                                        onChange={(value) => {

                                        }}
                                    />
                                </div>
                            }

                        </div>
                    </div>
                }

            </div>
        );
    }
}

export default ExternalReportSettings;
