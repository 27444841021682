import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton, TumblrIcon, TumblrShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";
import TextArea from "../text-area";
import Dropdown from "../../components/dropdown";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Datepicker from "../datepicker";
import IconGoogle from "../advertising-budget";
import CampaignStatus from "../campaign-status";
var moment = require('moment');

class DashboardListItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {}
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.init.values();
    }

    init = {
        values: () => {
            this.setState({
                item: this.props.item
            })
        }
    };

    functions = {
        open: () => {

        }
    };

    renders = {
        date: (date) => {
            try {
                return moment(date).format('DD.MM.YYYY');
            }catch (e) {}
        },
        channel: (channel) => {
            try{
                return this.state.item.channels[channel]
            }catch (e) {}
        }
    };

    render() {
        return (
            <div style={{width: '100%'}}>
                <div className="Site DashboardListItem-container">
                    <div className="Site-InnerContainerInner DashboardListItem-containerInner">
                        <div className={cx("Site-InnerContainerInnerTop")}>
                            <div className="Campaign-InnerContainerInnerTopTitle">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    Name
                                </div>
                                <div className="Site-InnerContainerInnerTopTitleValue">
                                    {this.state.item.name}
                                </div>
                            </div>
                            <div className="Campaign-InnerContainerInnerTopTitle">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    Client
                                </div>
                                <div className="Site-InnerContainerInnerTopTitleValue">
                                    {this.state.item.client}
                                </div>
                            </div>
                            <div className="Campaign-InnerContainerInnerTopTitle">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    Channels
                                </div>
                                <div className="Site-InnerContainerInnerTopTitleValueIcons">
                                    {
                                        this.renders.channel('facebook') &&
                                        <IconFacebook noMargin={true}/>
                                    }
                                    {
                                        this.renders.channel('instagram') &&
                                        <IconInstagram/>
                                    }
                                </div>
                            </div>
                            <div className="Campaign-InnerContainerInnerTopTitle">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    Start
                                </div>
                                <div className="Site-InnerContainerInnerTopTitleValue">
                                    {this.renders.date(this.state.item.startDate)}
                                </div>
                            </div>
                            <div className="Campaign-InnerContainerInnerTopTitle">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    End
                                </div>
                                <div className="Site-InnerContainerInnerTopTitleValue">
                                    {this.renders.date(this.state.item.endDate)}
                                </div>
                            </div>
                            <div className="Campaign-InnerContainerInnerTopTitle">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    Impressions
                                </div>
                                <div className="Site-InnerContainerInnerTopTitleValue">
                                    {this.state.item.impressions}
                                </div>
                            </div>
                            <div className="Campaign-InnerContainerInnerTopTitle">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    Click
                                </div>
                                <div className="Site-InnerContainerInnerTopTitleValue">
                                    {this.state.item.click}
                                </div>
                            </div>
                            <div className="Campaign-InnerContainerInnerTopTitle-Last">
                                <div onClick={() => this.functions.open()}
                                     className="Site-InnerContainerInnerTopArrowContainer">
                                    <FeatherIcon color={"#333333"} size={20} icon="arrow-right"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DashboardListItem;
