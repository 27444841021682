import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import { dataRegister } from "../../services/dataRegister";
import Dropdown from "../dropdown";
import AdvertisingCampaignDetailsClient from "../advertising-campaigndetails-client";
import AdvertisingCampaignDetailSite from "../advertising-campaigndetails-site";
import Input from "../input";
import Datepicker from "../datepicker";
import { campaignRegister } from "../../services/campaignRegister";
import { eventRegister } from "../../services/eventRegister";
import ErrorAlert from "../../modules/error-alert";
import { BeatLoader } from "react-spinners";
import { calls } from "./calls";
import { validateBBox } from '@turf/helpers';

class AdvertisingCampaignDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: true,
            details: {},
            client: {},
            site: {},
            campaigns: {},
            campaigns: {},
            creatives: {},
            loaded: false,
            submenu: "New order"
        };
        this.init.data = this.init.data.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.step === 'campaign_details' && !this.state.loaded) {
            this.init.first();
        }
    }

    componentWillMount() {
        eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        this.init.data();
    }

    init = {
        first: () => {
            let information = campaignRegister.get();
            this.setState({
                details: information ? (information.details ? (information.details.value ? information.details.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                loaded: true
            })
        },
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                details: information ? (information.details ? (information.details.value ? information.details.value : {}) : {}) : {},
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                client: information ? (information.client ? (information.client.value ? information.client.value : {}) : {}) : {},
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
            })
        }
    };

    functions = {
        next: () => {
            if (this.renders.errors().length > 0) {
                this.setState({
                    error: true,
                    next: true
                });
            } else {
                this.props.onNext();
            }
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        }
    };

    validators = {};

    renders = {
        errors: () => {
            let array = [];
            if (!this.state.details.name || this.state.details.name === "") {
                array.push({ title: 'Name is not valid', type: 'name', section: 1 });
            }
            if (!this.state.client.id) {
                array.push({ title: 'Select a client', type: 'client', section: 1 });
            }
            if (!this.state.site.checked && !this.state.site.error) {
                array.push({ title: 'Check website', type: 'site', section: 1 });
            }
            if (!this.state.details.startDate) {
                array.push({ title: 'Select start date', type: 'date', section: 1 });
            }
            if (!this.state.details.endDate && !this.state.details.noEndDate) {
                array.push({ title: 'Select end date', type: 'date', section: 1 });
            }
            if (!this.state.details.focus || (typeof this.state.details.focus === 'object' && !this.state.details.focus.value)) {
                array.push({ title: 'Select campaign focus', type: 'focus', section: 1 });
            }
            return array;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="h-full flex flex-1 flex-col pb-5">
                <div className="flex flex-row mb-3">
                    {
                        this.renders.errors().length < 1 &&
                        <div className="check-sign rounded-full mr-2 md:mr-3 w-8 h-8 bg-green-500 flex items-center justify-center text-white">
                            <FeatherIcon className={"#ffffff"} size={16} icon={'check'} />
                        </div>
                    }
                    <div className="text-2xl font-bold">
                        Step 1: Campaign details
                    </div>
                </div>
                <div>
                    <div className="rounded-md py-4 h-full w-full bg-white py-4 px-4">
                        <div className="flex flex-1">
                            <Input
                                title={'Campaign name'}
                                placeholder={'Enter your internal campaign name'}
                                icon={'edit-2'}
                                type={'text'}
                                value={this.state.details.name}
                                error={this.state.next && this.renders.errors().filter((item) => { return item.type === 'name' }).length > 0}
                                size={'full'}
                                onChange={(event) => {
                                    this.state.details.name = event.target.value;
                                    this.setState({
                                        details: this.state.details
                                    }, () => {
                                        this.functions.save('details');
                                        this.props.onSteps();
                                    })
                                }}
                            />
                        </div>
                        {
                            this.state.next && this.renders.errors().filter((item) => { return item.type === 'name' }).length > 0 &&
                            <ErrorAlert
                                items={this.renders.errors().filter((item) => { return item.type === 'name' })}
                            />
                        }
                        <div className="mt-3">
                            <AdvertisingCampaignDetailsClient
                                error={this.state.next && this.renders.errors().filter((item) => { return item.type === 'client' }).length > 0}
                                onChange={(value) => {
                                    if (value.id !== this.state.client.id) {
                                        delete this.state.creatives.facebook_events;
                                        delete this.state.creatives.facebook_pages;
                                        delete this.state.creatives.instagram_page_accounts;
                                        if (Array.isArray(this.state.creatives.image_ads)) {
                                            this.state.creatives.image_ads = this.state.creatives.image_ads.map((item) => {
                                                delete item.facebook_page;
                                                delete item.instagram_account;
                                                delete item.facebook_event;
                                                return item;
                                            })
                                        }
                                        if (Array.isArray(this.state.creatives.video_ads)) {
                                            this.state.creatives.video_ads = this.state.creatives.video_ads.map((item) => {
                                                delete item.facebook_page;
                                                delete item.instagram_account;
                                                delete item.facebook_event;
                                                return item;
                                            })
                                        }
                                        if (Array.isArray(this.state.creatives.carousel_ads)) {
                                            this.state.creatives.carousel_ads = this.state.creatives.carousel_ads.map((item) => {
                                                delete item.facebook_page;
                                                delete item.instagram_account;
                                                delete item.facebook_event;
                                                return item;
                                            })
                                        }
                                        if (Array.isArray(this.state.creatives.event_ads)) {
                                            this.state.creatives.event_ads = this.state.creatives.event_ads.map((item) => {
                                                delete item.facebook_page;
                                                delete item.instagram_account;
                                                delete item.facebook_event;
                                                return item;
                                            })
                                        }
                                        if (Array.isArray(this.state.creatives.post_ads)) {
                                            this.state.creatives.post_ads = this.state.creatives.post_ads.map((item) => {
                                                delete item.facebook_page;
                                                delete item.instagram_account;
                                                delete item.facebook_event;
                                                return item;
                                            })
                                        }
                                        if (Array.isArray(this.state.creatives.dynamic_ads)) {
                                            this.state.creatives.dynamic_ads = this.state.creatives.dynamic_ads.map((item) => {
                                                delete item.facebook_page;
                                                delete item.instagram_account;
                                                delete item.facebook_event;
                                                return item;
                                            })
                                        }
                                    }
                                    this.setState({
                                        client: value,
                                        creatives: this.state.creatives
                                    }, () => {
                                        this.functions.save('creatives');
                                        this.functions.save('client');
                                        this.props.onSteps();
                                    })
                                }}
                            />
                        </div>
                        {
                            this.state.next && this.renders.errors().filter((item) => { return item.type === 'client' }).length > 0 &&
                            <ErrorAlert
                                items={this.renders.errors().filter((item) => { return item.type === 'client' })}
                            />
                        }
                        {
                            this.state.client && this.state.client.name &&
                            <div className="bg-gray-100 p-4 mt-4 rounded-md pt-0">
                                <div className="navigation mb-4">
                                    <nav role="navigation" aria-label="Social sub navigation">
                                        <ul className="flex flex-row items-end">
                                            {["New order"].map((key, j) => {
                                                return (
                                                    <li key={j}
                                                        className={"capitalize font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == key ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                                        onClick={() => {
                                                            this.setState({
                                                                submenu: key
                                                            });
                                                        }}>
                                                        {key}
                                                    </li>
                                                )
                                            })
                                            }
                                            <li className="flex-grow border-b-2 border-gray-200"></li>
                                        </ul>
                                    </nav>
                                </div>
                                {
                                    this.state.submenu === "New order" &&
                                    <div className="bg-white p-4 rounded-md">
                                        <div className="">
                                            <Dropdown
                                                filter={false}
                                                title={'Campaign delivery focus'}
                                                icon={'crosshair'}
                                                error={this.state.next && this.renders.errors().filter((item) => { return item.type === 'focus' }).length > 0}
                                                noTitle={false}
                                                size={'full'}
                                                placeholder={"Select focus ..."}
                                                options={[
                                                    { name: "Impression focused", value: 'impressions' },
                                                    { name: "Click focused", value: 'clicks' },
                                                    { name: "Conversion focused", value: 'conversion' }
                                                ]}
                                                value={this.state.details.focus ? this.state.details.focus : {}}
                                                onChange={(value) => {
                                                    this.state.details.focus = value;
                                                    this.setState({
                                                        details: this.state.details
                                                    }, () => {
                                                        this.functions.save('details');
                                                        this.props.onSteps();
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.next && this.renders.errors().filter((item) => { return item.type === 'focus' }).length > 0 &&
                                            <ErrorAlert
                                                items={this.renders.errors().filter((item) => { return item.type === 'focus' })}
                                            />
                                        }
                                        <div className="mt-3">
                                            <AdvertisingCampaignDetailSite
                                                error={this.state.next && this.renders.errors().filter((item) => { return item.type === 'site' }).length > 0}
                                                onChange={(value) => {
                                                    this.setState({
                                                        site: value
                                                    }, () => {
                                                        this.functions.save('site');
                                                        this.props.onSteps();
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.next && this.renders.errors().filter((item) => { return item.type === 'site' }).length > 0 &&
                                            <ErrorAlert
                                                items={this.renders.errors().filter((item) => { return item.type === 'site' })}
                                            />
                                        }
                                        <div className="mt-4">
                                            <Datepicker
                                                size={'full'}
                                                showTime={true}
                                                border={true}
                                                disableEndDate={this.state.details.noEndDate}
                                                showNoEndDateSwitch={true}
                                                error={this.state.next && this.renders.errors().filter((item) => { return item.type === 'date' }).length > 0}
                                                startDate={this.state.details.startDate}
                                                endDate={this.state.details.endDate}
                                                updateNoEndDate={(value) => {
                                                    this.state.details.noEndDate = value;

                                                    //CHECK IF CAMPAIGNS, OVERWRITE WITH NEW DATE
                                                    try {
                                                        if (this.state.creatives && Array.isArray(this.state.creatives.campaigns)) {
                                                            this.state.creatives.campaigns = this.state.creatives.campaigns.map((campaign) => {
                                                                for (let key in campaign.budget) {
                                                                    campaign.budget[key].no_end_date = value;
                                                                }
                                                                return campaign;
                                                            })
                                                        }
                                                    } catch (error) { }

                                                    try {
                                                        if (this.state.creatives && Array.isArray(this.state.creatives.adsets)) {
                                                            this.state.creatives.adsets = this.state.creatives.adsets.map((adset) => {
                                                                if (adset.targeting && adset.targeting.facebook && adset.targeting.facebook) {
                                                                    adset.targeting.facebook.no_end_date = value;
                                                                }
                                                                return adset;
                                                            })
                                                        }
                                                    } catch (error) { }

                                                    this.setState({
                                                        creatives: this.state.creatives,
                                                        details: this.state.details
                                                    }, () => {
                                                        this.functions.save('creatives');
                                                        this.functions.save('details');
                                                        this.props.onSteps();
                                                    })
                                                }}
                                                onStartDate={(value) => {
                                                    this.state.details.startDate = value;

                                                    //CHECK IF CAMPAIGNS, OVERWRITE WITH NEW DATE
                                                    try {
                                                        if (this.state.creatives && Array.isArray(this.state.creatives.campaigns)) {
                                                            this.state.creatives.campaigns = this.state.creatives.campaigns.map((campaign) => {
                                                                for (let key in campaign.budget) {
                                                                    campaign.budget[key].start_date = value;
                                                                }
                                                                return campaign;
                                                            })
                                                        }
                                                    } catch (error) { }

                                                    try {
                                                        if (this.state.creatives && Array.isArray(this.state.creatives.adsets)) {
                                                            this.state.creatives.adsets = this.state.creatives.adsets.map((adset) => {
                                                                if (adset.targeting && adset.targeting.facebook && adset.targeting.facebook) {
                                                                    adset.targeting.facebook.start_date = value;
                                                                }
                                                                return adset;
                                                            })
                                                        }
                                                    } catch (error) { }

                                                    this.setState({
                                                        creatives: this.state.creatives,
                                                        details: this.state.details
                                                    }, () => {
                                                        this.functions.save('creatives');
                                                        this.functions.save('details');
                                                        this.props.onSteps();
                                                    })
                                                }}
                                                onEndDate={(value) => {
                                                    this.state.details.endDate = value;
                                                    if (!this.state.details.startDate) {
                                                        this.state.details.startDate = new Date();
                                                    }

                                                    //CHECK IF CAMPAIGNS, OVERWRITE WITH NEW DATE
                                                    try {
                                                        if (this.state.creatives && Array.isArray(this.state.creatives.campaigns)) {
                                                            this.state.creatives.campaigns = this.state.creatives.campaigns.map((campaign) => {
                                                                for (let key in campaign.budget) {
                                                                    campaign.budget[key].end_date = value;
                                                                }
                                                                return campaign;
                                                            })
                                                        }
                                                    } catch (error) { }

                                                    try {
                                                        if (this.state.creatives && Array.isArray(this.state.creatives.adsets)) {
                                                            this.state.creatives.adsets = this.state.creatives.adsets.map((adset) => {
                                                                if (adset.targeting && adset.targeting.facebook && adset.targeting.facebook) {
                                                                    adset.targeting.facebook.end_date = value;
                                                                }
                                                                return adset;
                                                            })
                                                        }
                                                    } catch (error) { }

                                                    this.setState({
                                                        creatives: this.state.creatives,
                                                        details: this.state.details
                                                    }, () => {
                                                        this.functions.save('creatives');
                                                        this.functions.save('details');
                                                        this.props.onSteps();
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.next && this.renders.errors().filter((item) => { return item.type === 'date' }).length > 0 &&
                                            <ErrorAlert
                                                items={this.renders.errors().filter((item) => { return item.type === 'date' })}
                                            />
                                        }
                                    </div>
                                }
                                {
                                    this.state.submenu === "Import external campaigns" &&
                                    <div className="bg-white p-4 rounded-md">
                                        <ImportCampaign
                                            client={this.state.client}
                                            campaigns={this.state.campaigns}
                                            selected={this.state.import_campaigns}
                                            updateCampaigns={(value) => {
                                                this.setState({
                                                    campaigns: value
                                                })
                                            }}
                                            updateSelected={(value) => {
                                                this.setState({
                                                    import_campaigns: value
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="mt-4 text-right">
                    <div onClick={() => {
                        this.functions.next();
                        this.props.onSteps();
                    }} className="btn btn-lg btn-primary inline-flex items-center">
                        Next step: Channels <span className="arrow-right"></span>
                    </div>
                </div>
            </div>
        )
    }
}

class ImportCampaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading_import: false,
            channel: {},
            campaigns: {},
            campaign: {},
            client: {},
            selected: []
        };
    };

    componentDidMount() {
        this.setState({
            client: this.props.client ? this.props.client : {},
            campaigns: this.props.campaigns ? this.props.campaigns : {},
            selected: this.props.selected ? this.props.selected : []
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            client: nextProps.client,
            //campaigns: nextProps.campaigns
        })
    }

    functions = {
        externalCampaigns: () => {
            if (this.state.campaigns && Object.keys(this.state.campaigns).length < 1) {
                this.setState({
                    loading: true
                }, () => {
                    calls.getAllExternalCampaigns({ id: this.state.client.id }).then((response) => {
                        this.setState({
                            loading: false,
                            campaigns: response.data
                        }, () => {
                            if (this.props.updateCampaigns) {
                                this.props.updateCampaigns(this.state.campaigns);
                            }
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                });
            }
        },
        import: () => {

        },
    };

    renders = {
        channels: () => {
            let channels = [];
            if (this.state.client.facebook) {
                channels.push({ name: "Facebook", value: "facebook" });
            }
            if (this.state.client.google) {
                channels.push({ name: "Google", value: "google" });
            }
            if (this.state.client.linkedin) {
                channels.push({ name: "Linkedin", value: "linkedin" });
            }
            return channels;
        },
        campaigns: () => {
            let campaigns = [];
            try {
                if (Array.isArray(this.state.campaigns[this.state.channel.value])) {
                    campaigns = JSON.parse(JSON.stringify(this.state.campaigns[this.state.channel.value]));
                    campaigns = campaigns.filter((campaign) => {
                        return this.state.selected.filter((item) => {
                            return item.id === campaign.id;
                        }).length < 1
                    })
                }
            } catch (e) { }
            return campaigns;
        }
    };

    render() {
        return (
            <>
                <div className="relative">
                    {
                        this.state.loading_import &&
                        <div
                            className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-white rounded flex justify-center items-center bg-opacity-75">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    }
                    <div className="">
                        <div className="">
                            <Dropdown
                                filter={false}
                                noBorder={false}
                                white={false}
                                title={'Select channel'}
                                size={'full'}
                                placeholder={"Click here ..."}
                                options={this.renders.channels()}
                                value={this.state.channel}
                                onChange={(value) => {
                                    this.setState({
                                        channel: value
                                    }, () => {
                                        this.functions.externalCampaigns();
                                    })
                                }}
                            />
                        </div>
                        {
                            this.state.channel.value &&
                            <div className="mt-4">
                                <Dropdown
                                    filter={true}
                                    search={true}
                                    noBorder={false}
                                    white={false}
                                    title={'Select campaign'}
                                    size={'full'}
                                    loading={this.state.loading}
                                    placeholder={this.state.loading ? "Loading campaigns ..." : "Click here ..."}
                                    options={this.renders.campaigns()}
                                    value={{}}
                                    onChange={(value) => {
                                        if (this.state.selected.filter((campaign) => {
                                            return campaign.id === value.id;
                                        }).length < 1) {
                                            value.channel = this.state.channel.value;
                                            this.state.selected.push(value);
                                            this.setState({
                                                selected: this.state.selected
                                            }, () => {
                                                if (this.props.updateSelected) {
                                                    this.props.updateSelected(this.state.selected);
                                                }
                                            });
                                        }
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.selected.map((item) => {
                                return (
                                    <div style={{ backgroundColor: "#fcfcfc", height: "55px" }} className={"flex flex-row flex-1 items-center border mt-2 bg-white px-4 py-1 rounded"}>
                                        <div className="flex flex-row">
                                            <div className="flex flex-row">
                                                {item.channel === 'google' &&
                                                    <div
                                                        className="px-2 py-2 border-2 border-white h-8 w-8 rounded-full mr-2 flex items-center justify-center bg-google-500">
                                                        <img className="w-3"
                                                            src={require('../../assets/images/google_icon.svg')} />
                                                    </div>
                                                }
                                                {item.channel === 'linkedin' &&
                                                    <div
                                                        className="px-2 py-2 h-8 w-8 border-2 border-white rounded-full mr-2 flex items-center justify-center bg-linkedin-500">
                                                        <img className="w-3"
                                                            src={require('../../assets/images/linkedin_icon.svg')} />
                                                    </div>
                                                }
                                                {item.channel === 'twitter' &&
                                                    <div
                                                        className="px-2 py-2 h-8 w-8 border-2 border-white rounded-full mr-2 flex items-center justify-center bg-twitter-500">
                                                        <img className="w-3"
                                                            src={require('../../assets/images/twitter_icon.svg')} />
                                                    </div>
                                                }
                                                {(item.channel === 'facebook') &&
                                                    <div className="px-2 py-2 h-8 w-8 border-2 border-white rounded-full mr-1 flex items-center justify-center bg-facebook-500">
                                                        <img style={{ width: "6px" }}
                                                            src={require('../../assets/images/facebook_icon.svg')} />
                                                    </div>
                                                }
                                                {(item.channel === 'facebook') &&
                                                    <div className="px-2 border-2 border-white -ml-3 py-2 h-8 w-8 rounded-full mr-2 flex items-center justify-center bg-instagram-500">
                                                        <img className="w-3"
                                                            src={require('../../assets/images/instagram_icon.svg')} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="text-sm font-bold">{item.name}</div>
                                        <div className="flex flex-1"></div>
                                        <button className="text-red-500 h-8 w-8 rounded bg-red-100 justify-center align-middle items-center flex"
                                            onClick={() => {
                                                this.setState({
                                                    selected: this.state.selected.filter((campaign) => {
                                                        return campaign.id !== item.id;
                                                    })
                                                }, () => {
                                                    if (this.props.updateSelected) {
                                                        this.props.updateSelected(this.state.selected);
                                                    }
                                                });
                                            }}>
                                            <FeatherIcon className="stroke-current" size={16} icon={'trash'} />
                                        </button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        )
    }

}

export default AdvertisingCampaignDetails;
