import moment from 'moment';
import React, { Component } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';
import phone_codes from '../assets/json/phone-countries.json';

class CreateClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            company: "",
            phone: "",
            loading: false,
            phone_error: false,
            phone_exists: false,
            phone_codes: phone_codes,
            country_code: { id: 0, name: "Select..." },
            languages: [
                { id: 1, name: "English", value: "en" },
                { id: 2, name: "Svenska", value: "sv" },
                { id: 3, name: "Dansk", value: "dk" },
                { id: 4, name: "Norsk", value: "no" }
            ],
            language: { id: 0, name: "Select..." },
            templates: [],
            templates_search: '',
            templates_limit: 10,
            templates_page: 1,
            templates_total: 0,
            selected_template: {},
        };
    }

    componentDidMount() {
        this.functions.getTemplates(true);
    }

    functions = {
        createClient: async () => {
            await this.promisedSetState({ loading: true });
            let client = {
                name: this.state.name,
                email: this.state.email,
                company: this.state.company,
                phone: this.state.phone,
                country_code: this.state.country_code.id === 0 ? "46" : this.state.country_code,
                language: this.state.language.id === 0 ? { id: 1, name: "English", value: "en" } : this.state.language,
                master_template: this.state.selected_template && this.state.selected_template.id && this.state.selected_template.id !== 1 ? this.state.selected_template : null
            };
            try {
                let response = await this.calls.createClient(client);
                this.props.onCreated(response.data);
            } catch (error) {
                console.log(error);
                this.setState({
                    error: error.body.message
                });
                this.props.onError();
            }
        },
        getTemplates: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_templates_pagination: paginaton,
                loading_templates: init
            });
            try {
                let response = await this.calls.getTemplates();
                if (!search_value || (search_value && search_value == this.state.templates_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            templates: response.data,
                            templates_total: response.meta.total
                        });
                    } else {
                        this.state.templates = this.state.templates.concat(response.data);
                        await this.promisedSetState({
                            templates: this.state.templates,
                            templates_total: response.meta.total
                        });
                    }
                }
            } catch (error) {
                console.log(error)
            }
            await this.promisedSetState({
                loading_templates_search: false,
                loading_templates_pagination: false,
                loading_templates: false
            });
        }
    };

    calls = {
        createClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createClient";
            return apiRegister.call(options, url);
        },
        getTemplates: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listReports?template=true&master=true&limit=" + this.state.templates_limit + "&page=" + this.state.templates_page + "&status=" + this.state.type + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.templates_search !== "" ? ("&search=" + this.state.templates_search) : "");
            return apiRegister.call(options, url);
        }
    };

    renders = {
        countryCode: () => {
            try {
                if (this.state.country_code.id !== 0) {
                    let buff = this.state.phone_codes.filter(country => {
                        return country.code === this.state.country_code
                    })
                    if (buff.length > 0) {
                        return { name: `${buff[0].iso} (${buff[0].code})`, value: buff[0].code, id: buff[0].code }
                    } else {
                        return { id: 0, name: "Select..." }
                    }
                } else {
                    return { id: 0, name: "Select..." }
                }
            } catch (error) {
                console.log(error);
            }

        },
        language: () => {
            try {
                if (this.state.language.id !== 0) {
                    return this.state.language;
                } else {
                    return { id: 0, name: "Select ..." }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div className="flex flex-col">
                    <div className="flex flex-1">
                        <div className="w-full">
                            <div className="grid-cols-12 gap-4 grid">
                                <div className={"col-span-6"}>
                                    <InputTailwind
                                        label={"Name"}
                                        value={this.state.name}
                                        onChange={(value) => {
                                            this.promisedSetState({
                                                name: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className={"col-span-6"}>
                                    <InputTailwind
                                        label={"Company name"}
                                        value={this.state.company}
                                        onChange={(value) => {
                                            this.promisedSetState({
                                                company: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className={"col-span-6"}>
                                    <InputTailwind
                                        label={"Email"}
                                        value={this.state.email}
                                        onChange={(value) => {
                                            this.promisedSetState({
                                                email: value
                                            })
                                        }}
                                    />
                                </div>
                                <div className={"col-span-6"}>
                                    <DropdownTailwind
                                        label={"Default language"}
                                        selected={this.renders.language()}
                                        options={this.state.languages}
                                        onChange={async (language) => {
                                            await this.promisedSetState({
                                                language: language
                                            })
                                        }}
                                    />
                                </div>
                                {
                                    false &&
                                    <div className={"col-span-3"}>
                                        <DropdownTailwind
                                            label={"Country code"}
                                            small={false}
                                            searchInput={true}
                                            selected={this.renders.countryCode()}
                                            options={this.state.phone_codes.map((item) => {
                                                return { name: `${item.iso} (${item.code})`, value: item.code, id: item.code };
                                            })}
                                            onChange={async (country_code) => {
                                                await this.promisedSetState({
                                                    country_code: country_code.value
                                                });
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    false &&
                                    <div className={"col-span-9 -ml-4"}>
                                        <InputTailwind
                                            label={"Phone"}
                                            value={this.state.phone}
                                            error={this.state.phone_error}
                                            phone_codes={true}
                                            onChange={(value) => {
                                                if (value.length > 0 && /[^0-9]/.test(value)) {
                                                    this.state.phone_error = true
                                                } else {
                                                    this.state.phone_error = false
                                                    this.state.phone_exists = false
                                                }
                                                this.promisedSetState({
                                                    phone: value
                                                })
                                            }}
                                            type={"tel"}
                                        />
                                    </div>
                                }
                                {
                                    Array.isArray(this.state.templates) &&
                                    this.state.templates.length > 0 &&
                                    <div className={"col-span-12"}>
                                        <DropdownTailwind
                                            label={"Scale-report template (optional)"}
                                            small={false}
                                            skipInternalSearch={true}
                                            loader={this.state.loading_templates}
                                            loadingPagination={this.state.loading_templates_pagination}
                                            loadingSearch={this.state.loading_templates_search}
                                            total={this.state.templates_total}
                                            removeButton={this.state.selected_template.id && this.state.selected_template.id !== 1}
                                            searchInput={true}
                                            placeholder={"Search ..."}
                                            pagination={this.state.templates_total > (this.state.templates_page * this.state.templates_limit)}
                                            selected={this.state.selected_template.id && this.state.selected_template.id !== 1 ? this.state.selected_template : { id: 1, name: "Select template" }}
                                            options={this.state.templates.length > 0 ? this.state.templates : []}
                                            onRemove={async () => {
                                                await this.promisedSetState({
                                                    selected_template: {},
                                                }); 
                                            }}
                                            onChange={async (value) => {
                                                this.state.selected_template = value;
                                                await this.promisedSetState({
                                                    selected_template: value,
                                                    open: this.state.open
                                                });

                                            }}
                                            onPagination={async () => {
                                                if (!this.state.loading_templates_pagination) {
                                                    await this.promisedSetState({
                                                        templates_page: this.state.templates_page + 1
                                                    });
                                                    this.functions.getTemplates(false, true, false);
                                                }
                                            }}
                                            onSearch={async (value) => {
                                                await this.promisedSetState({
                                                    loading_templates_search: true,
                                                    templates_search: value
                                                });
                                                setTimeout(async () => {
                                                    if (value === this.state.templates_search) {
                                                        await this.promisedSetState({
                                                            templates_page: 1
                                                        });
                                                        this.functions.getTemplates(false, false, true, value);
                                                    }
                                                }, 400);
                                            }}
                                        />
                                    </div>
                                }

                                {
                                    this.state.error &&
                                    <div className={"col-span-12"}>
                                        <div className="bg-red-100 p-4 rounded-md">
                                            <div className="text-sm text-red-500">
                                                {this.state.error}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CreateClient;