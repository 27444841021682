import { ChevronLeftIcon, ChevronRightIcon, DotsVerticalIcon, MenuIcon, PlayIcon, SearchIcon, TrashIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';

class PreviewGooglePerformanceMaxDiscover extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channel: {},
            videos: [],
            images: [],
            headlines: [],
            descriptions: [],
            long_headlines: [],
            business: "",
            website: "",
            cta: {}
        }
    };

    componentDidMount() {
        this.setState({
            videos: this.props.videos,
            images: this.props.images,
            headlines: this.props.headlines,
            descriptions: this.props.descriptions,
            long_headlines: this.props.long_headlines,
            business: this.props.business,
            website: this.props.website,
            cta: this.props.cta
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            videos: nextProps.videos,
            images: nextProps.images,
            headlines: nextProps.headlines,
            descriptions: nextProps.descriptions,
            long_headlines: nextProps.long_headlines,
            business: nextProps.business,
            website: nextProps.website,
            cta: nextProps.cta
        })
    }

    renders = {
        truncate: (text, chars) => {
            try {
                var myTruncatedString = text.substring(0, chars);
                return myTruncatedString + (chars > 30 ? " ..." : "");
            } catch (error) {
                return text;
            }
        }
    }

    render() {
        return (
            <div style={{ maxWidth: "400px" }} className={"w-full border rounded-md bg-white"}>
                <div className="h-10 w-full flex flex-row bg-gray-200 items-center">

                </div>
                <div className="px-4 pt-3">
                    <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                    <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                    <div className="w-full h-2 bg-gray2-100 mb-2"></div>
                    <div className="mt-6 mb-6">
                        <div className="w-full flex flex-row items-center">
                            <div className="h-5 w-5 bg-gray2-500 rounded-full mr-2"></div>
                            <div className="w-32 h-2 bg-gray2-100"></div>
                            <div className="flex flex-1"></div>
                        </div>
                    </div>
                </div>
                <div className="px-4">
                    <div className="shadow rounded-lg overflow-hidden">
                        <div className="w-full bg-gray2-500">
                            {
                                Array.isArray(this.state.images) &&
                                this.state.images.filter((item) => {
                                    return item.type === "landscape"
                                }).map((item, index) => {
                                    if (index === 0) {
                                        return (
                                            <img className="w-full" src={item.url} />
                                        )
                                    } else {
                                        return ("");
                                    }
                                })
                            }
                        </div>
                        <div className="p-4">
                            <div className="text-sm">
                            {Array.isArray(this.state.headlines) && this.state.headlines.length > 0 ? this.state.headlines[0].text : "Headline"} | {Array.isArray(this.state.headlines) && this.state.headlines.length > 1 ? this.state.headlines[1].text : "Headline"}
                            </div>
                            <div className="flex flex-row">
                                <div className="font-semibold text-xs mr-1">
                                    Ad •
                                </div>
                                <div className="text-xs">
                                    {this.state.business !== "" ? this.state.business : "Business"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-2 pt-3 h-16">

                </div>
                <div className="w-full flex flex-row justify-center items-center mb-4">
                    <div className="flex flex-1"></div>
                    <div className="border flex-row items-center flex rounded-full px-4 py-1">
                        <StarIcon className="h-5 w-5 mr-2 text-gray2-400" />
                        <div className="h-2 bg-gray2-200 w-14"></div>
                    </div>
                    <div className="flex flex-1 justify-end">
                        <DotsVerticalIcon className="h-5 w-5 mr-2 text-gray2-400" />
                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewGooglePerformanceMaxDiscover;
