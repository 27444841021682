import {apiRegister} from "../../../../services/apiRegister";
import {tokenRegister} from "../../../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static uploadImage(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
        let url = calls.url.api + "/image?report=true";
        return apiRegister.call(options, url);
    }

    static uploadVideo(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
        let url = calls.url.api + "/video?report=true";
        return apiRegister.call(options, url);
    }

}

export {calls}