import React, {Component} from 'react';
import './style.css';
import {SlideDown} from 'react-slidedown'
import 'sweetalert/dist/sweetalert.css'
import Dropdown from "../dropdown";
import Input from "../input";
import TextArea from "../text-area";
import AdvertisingCreativesFacebookImage from "../advertising-creatives-facebook-image";
import AdvertisingCreativesFacebookVideo from "../advertising-creatives-facebook-video";
import AdvertisingCreativesFacebookCarousel from "../advertising-creatives-facebook-carousel";
import AdvertisingCreativesVertical from "../advertising-creatives-vertical";
import FeatherIcon from "feather-icons-react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import AdvertisingCreativesSearchPreview from "../advertising-creatives-search-preview";
import AdvertisingSearchTemplatePreview from "../advertising-search-template-preview";

class AdvertisingPreviewSearchAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creatives: {},
            search: {},
            campaign: {},
            locations: []
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            creatives: nextProps.creatives,
            search: nextProps.search,
            campaign: nextProps.campaign,
            locations: nextProps.locations
        });
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            creatives: this.props.creatives,
            search: this.props.search,
            campaign: this.props.campaign,
            locations: this.props.locations
        });
    }

    init = {};

    functions = {};

    validators = {};

    renders = {
        templates: () => {
            try {
                let templates = [];
                if (Array.isArray(this.state.search.campaigns)) {
                    this.state.search.campaigns.filter((item) => {
                        return item.id === this.state.campaign.id
                    }).map((campaign) => {
                        for (let m = 0; m < this.state.search.templates.length; m++) {
                            if (campaign.id === this.state.search.templates[m].campaign_id) {
                                if (
                                    (this.state.search.templates[m].overwrite_url_path1 && this.state.search.templates[m].overwrite_url_path1.indexOf("{keyword}") !== -1) ||
                                    (this.state.search.templates[m].overwrite_url_path2 && this.state.search.templates[m].overwrite_url_path2.indexOf("{keyword}") !== -1) ||
                                    (this.state.search.templates[m].body && this.state.search.templates[m].body.indexOf("{keyword}") !== -1) ||
                                    (this.state.search.templates[m].body_second && this.state.search.templates[m].body_second.indexOf("{keyword}") !== -1) ||
                                    this.state.search.templates[m].headline_first_template.type === 'keyword_insert' ||
                                    this.state.search.templates[m].headline_second_template.type === 'keyword_insert' ||
                                    this.state.search.templates[m].headline_third_template.type === 'keyword_insert'
                                ) {
                                    this.state.search.keywords.map((keyword) => {
                                        if (!this.state.search.templates[m].specific_keywords || (this.state.search.templates[m].specific_keywords && this.state.search.templates[m].specific_keywords[keyword.id])) {

                                            if (campaign.id === keyword.campaign_id && !keyword.skip_insert_keyword) {
                                                if (keyword.name.indexOf("{location}") !== -1) {
                                                    this.state.locations.map((location) => {
                                                        if (!campaign.exclude_locations || (campaign.exclude_locations && !campaign.exclude_locations[location.id])) {
                                                            let new_keyword = JSON.parse(JSON.stringify(keyword));
                                                            new_keyword.name = new_keyword.name.replace(/{location}/gi, location.name);
                                                            let template = JSON.parse(JSON.stringify(this.state.search.templates[m]));

                                                            //SET KEYWORD INSERT
                                                            if(typeof template.overwrite_url_path1 === "string"){
                                                                template.overwrite_url_path1 = template.overwrite_url_path1.replace(/{keyword}/gi, new_keyword.name);
                                                            }
                                                            if(typeof template.overwrite_url_path2 === "string"){
                                                                template.overwrite_url_path2 = template.overwrite_url_path2.replace(/{keyword}/gi, new_keyword.name);
                                                            }
                                                            if(typeof template.body === "string"){
                                                                template.body = template.body.replace(/{keyword}/gi, new_keyword.name);
                                                            }
                                                            if(typeof template.body_second === "string"){
                                                                template.body_second = template.body_second.replace(/{keyword}/gi, new_keyword.name);
                                                            }

                                                            template.url = new_keyword.url;
                                                            template.path1 = new_keyword.path1;
                                                            template.path2 = new_keyword.path2;

                                                            //SET KEYWORD INSERT
                                                            if(typeof template.path1 === "string"){
                                                                template.path1 = new_keyword.path1.replace(/{keyword}/gi, new_keyword.name);
                                                            }
                                                            if(typeof template.path2 === "string"){
                                                                template.path2 = new_keyword.path2.replace(/{keyword}/gi, new_keyword.name);
                                                            }

                                                            template.keyword = new_keyword;
                                                            template.exclude_locations = campaign.exclude_locations;
                                                            template.site_links = Array.isArray(this.state.search.site_links) ? this.state.search.site_links.filter((item) => {
                                                                return item.campaign_id === campaign.id && !template.no_sitelinks && (!template.specific_sitelinks || (template.specific_sitelinks && template.specific_sitelinks[item.id]))
                                                            }) : [];
                                                            template.phone_numbers = Array.isArray(this.state.search.phone_numbers) ? this.state.search.phone_numbers.filter((item) => {
                                                                return item.campaign_id === campaign.id
                                                            }) : [];
                                                            templates.push(template);
                                                        }
                                                    });
                                                } else {
                                                    let template = JSON.parse(JSON.stringify(this.state.search.templates[m]));

                                                    //SET KEYWORD INSERT
                                                    if(typeof template.overwrite_url_path1 === "string"){
                                                        template.overwrite_url_path1 = template.overwrite_url_path1.replace(/{keyword}/gi, keyword.name);
                                                    }
                                                    if(typeof template.overwrite_url_path2 === "string"){
                                                        template.overwrite_url_path2 = template.overwrite_url_path2.replace(/{keyword}/gi, keyword.name);
                                                    }
                                                    if(typeof template.body === "string"){
                                                        template.body = template.body.replace(/{keyword}/gi, keyword.name);
                                                    }
                                                    if(typeof template.body_second === "string"){
                                                        template.body_second = template.body_second.replace(/{keyword}/gi, keyword.name);
                                                    }

                                                    template.url = keyword.url;
                                                    template.path1 = keyword.path1;
                                                    template.path2 = keyword.path2;

                                                    //SET KEYWORD INSERT
                                                    if(typeof template.path1 === "string"){
                                                        template.path1 = keyword.path1.replace(/{keyword}/gi, keyword.name);
                                                    }
                                                    if(typeof template.path2 === "string"){
                                                        template.path2 = keyword.path2.replace(/{keyword}/gi, keyword.name);
                                                    }

                                                    template.keyword = keyword;
                                                    template.exclude_locations = campaign.exclude_locations;
                                                    template.site_links = Array.isArray(this.state.search.site_links) ? this.state.search.site_links.filter((item) => {
                                                        return item.campaign_id === campaign.id && !template.no_sitelinks && (!template.specific_sitelinks || (template.specific_sitelinks && template.specific_sitelinks[item.id]))
                                                    }) : [];
                                                    template.phone_numbers = Array.isArray(this.state.search.phone_numbers) ? this.state.search.phone_numbers.filter((item) => {
                                                        return item.campaign_id === campaign.id
                                                    }) : [];
                                                    templates.push(template);
                                                }
                                            }

                                        }
                                    })
                                } else {
                                    let template = JSON.parse(JSON.stringify(this.state.search.templates[m]));
                                    template.url = campaign.website;
                                    template.path1 = campaign.path1;
                                    template.path2 = campaign.path2;
                                    template.exclude_locations = campaign.exclude_locations;
                                    template.site_links = Array.isArray(this.state.search.site_links) ? this.state.search.site_links.filter((item) => {
                                        return item.campaign_id === campaign.id && !template.no_sitelinks && (!template.specific_sitelinks || (template.specific_sitelinks && template.specific_sitelinks[item.id]))
                                    }) : [];
                                    template.phone_numbers = Array.isArray(this.state.search.phone_numbers) ? this.state.search.phone_numbers.filter((item) => {
                                        return item.campaign_id === campaign.id
                                    }) : [];
                                    templates.push(template);
                                }
                            }
                        }
                    });
                }

                //CHECK IF CONTAINS LOCATION INSERT
                let new_templates = [];
                templates = templates.filter((template) => {
                    let location_insert = false;
                    for (let key in template) {
                        if (key === "custom") {
                            for(let inner_key in template[key]){
                                if(template[key][inner_key].indexOf("{location}") !== -1){
                                    location_insert = true;
                                }
                            }
                        }
                        if(key === "headline_first_template"){
                            if(template[key]["type"] === "location_insert"){
                                location_insert = true;
                            }
                        }
                        if(key === "headline_second_template"){
                            if(template[key]["type"] === "location_insert"){
                                location_insert = true;
                            }
                        }
                        if(key === "headline_third_template"){
                            if(template[key]["type"] === "location_insert"){
                                location_insert = true;
                            }
                        }
                        try {
                            if (template[key].indexOf("{location}") !== -1) {
                                location_insert = true;
                            }
                        } catch (e) {
                            //console.log(e);
                        }
                    }
                    if (location_insert) {
                        this.state.locations.map((location) => {
                            if (!template.exclude_locations || (template.exclude_locations && !template.exclude_locations[location.id])) {
                                let new_template = JSON.parse(JSON.stringify(template));
                                //console.log(new_template);
                                for (let key in new_template) {
                                    try {
                                        if (key === "custom") {
                                            if (new_template[key].headline.indexOf("{location}") !== -1) {
                                                new_template[key].headline = new_template[key].headline.replace(/{location}/gi, location.name);
                                            }
                                            if (new_template[key].headline_second.indexOf("{location}") !== -1) {
                                                new_template[key].headline_second = new_template[key].headline_second.replace(/{location}/gi, location.name);
                                            }
                                            if (new_template[key].headline_third.indexOf("{location}") !== -1) {
                                                new_template[key].headline_third = new_template[key].headline_third.replace(/{location}/gi, location.name);
                                            }
                                        }else if(key === "headline_first_template"){
                                            new_template[key].location = location.name;
                                        } else if(key === "headline_second_template"){
                                            new_template[key].location = location.name;
                                        }else if(key === "headline_third_template"){
                                            new_template[key].location = location.name;
                                        }
                                    } catch (e) {
                                        //console.log(e);
                                    }
                                    try{
                                        if (new_template[key].indexOf("{location}") !== -1) {
                                            new_template[key] = new_template[key].replace(/{location}/gi, location.name);
                                        }
                                    }catch (e) {
                                        //console.log(e);
                                    }
                                }
                                new_templates.push(new_template);
                            }
                        });
                        return false;
                    } else {
                        return true;
                    }
                });
                templates = templates.concat(new_templates);

                return templates;
            } catch (e) {
                //console.log(e);
                return []
            }
        },
        phoneNumber: (numbers) => {
            try {
                if (numbers.length > 0) {
                    return numbers[0].number
                }
            } catch (e) {
                return false
            }
        },
    };

    render() {
        return (
            <div className="">
                <div className="flex flex-row space-x-2 overflow-scroll">
                    {
                        this.renders.templates().map((item) => {
                            return (
                                <div className="flex-1 p-4 rounded-sm rounded-md bg-white text-left">
                                    <AdvertisingSearchTemplatePreview
                                        item={item ? item : {}}
                                        noContainer={true}
                                        url={item.url}
                                        path1={item.path1}
                                        path2={item.path2}
                                        keyword={item.keyword}
                                        site_links={Array.isArray(item.site_links) ? item.site_links : []}
                                        phone={this.renders.phoneNumber(item.phone_numbers)}
                                        creatives={this.state.creatives}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default AdvertisingPreviewSearchAll;
