import React, {Component, Fragment} from 'react';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import IconFacebook from "../../components/icons/facebook";
import IconInstagram from "../../components/icons/instagram";
import IconGoogle from "../../components/icons/google";
import {BeatLoader} from "react-spinners";
import {SlideDown} from "react-slidedown";
import CampaignDonuts from "../../components/campaign-donuts";
import {calls} from "./calls";

class UsersList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    };

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            items: this.props.items
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            items: nextProps.items
        })
    }

    functions = {};

    renders = {};

    render() {
        return (
            <div className="flex flex-col my-5">
                {this.state.items.length > 0 &&
                <div className="-my-2 overflow-x-auto">
                    <div className="py-2 align-middle inline-block min-w-full">
                        <div className="shadow-lg overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 bg-white">
                                <thead>
                                <tr>
                                    {this.props.properties && this.props.properties.name &&
                                    <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        Email
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.name &&
                                    <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        Name
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.status &&
                                    <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>
                                    }
                                    {this.props.properties && this.props.properties.actions &&
                                    <th className="px-6 py-3 text-xs text-right leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                        Actions
                                    </th>
                                    }
                                </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                {this.state.items.map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <tr>
                                                {this.props.properties && this.props.properties.email &&
                                                <td className="px-6 py-4 whitespace-no-wrap max-w-xs">
                                                    <div className="text-sm leading-5 font-bold text-gray-900 truncate">
                                                        {item.email}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.name &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className="text-sm leading-5 font-bold text-gray-900">
                                                        {item.name}
                                                    </div>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.status &&
                                                <td className="px-6 py-4 whitespace-no-wrap capitalize">
                                                        <span
                                                            className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                {
                                                                    ["bg-green-100 text-green-500"]: item.status === "active",
                                                                    ["bg-yellow-100 text-yellow-600"]: item.status === "pending",
                                                                })
                                                            }>
                                                            {item.status}
                                                        </span>
                                                </td>
                                                }
                                                {this.props.properties && this.props.properties.actions &&
                                                    <td className="px-6 py-4 whitespace-no-wrap text-right text-xs leading-5 font-medium text-gray-500">
                                                        <div className="flex flex-row items-center justify-end">
                                                            {this.props.properties && this.props.properties.actions && this.props.properties.actions.preview &&
                                                            <button onClick={() => {
                                                                item.open = !item.open;
                                                                this.setState({
                                                                    campaignitems: this.state.campaignitems
                                                                }, () => {
                                                                    this.functions.campaign(item);
                                                                });
                                                            }}
                                                                    className="ml-2 bg-black-200 bg-opacity-25 text-black-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-black hover:bg-opacity-25 hover:text-white">
                                                                <span>Preview</span>
                                                                <FeatherIcon className="stroke-current ml-2" size={15}
                                                                             icon="chevron-down"/>
                                                            </button>
                                                            }
                                                            {this.props.properties && this.props.properties.actions && this.props.properties.actions.delete &&
                                                            <button onClick={() => {
                                                                try {
                                                                    this.props.delete(item)
                                                                } catch (e) {
                                                                }
                                                            }}
                                                                    className="ml-2 bg-red-200 bg-opacity-25 text-red-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white">
                                                                <span>Delete</span>
                                                                <FeatherIcon className="stroke-current ml-2" size={15}
                                                                             icon="trash"/>
                                                            </button>
                                                            }
                                                            {this.props.properties && this.props.properties.actions && this.props.properties.actions.open &&
                                                            <button onClick={() => {
                                                                try {
                                                                    this.props.callfunc(item)
                                                                } catch (e) {
                                                                }
                                                            }}
                                                                    className="ml-2 bg-purple-200 bg-opacity-25 text-purple-500 flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 hover:bg-purple-500 hover:bg-opacity-100 hover:text-white">
                                                                <span>Select this</span>
                                                                <FeatherIcon className="stroke-current ml-2" size={16}
                                                                             icon="arrow-right-circle"/>
                                                            </button>
                                                            }
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                            {this.props.properties && this.props.properties.actions && this.props.properties.actions.preview &&
                                            <tr style={{borderTop: 'none'}}>
                                                <td colspan="10">
                                                    <SlideDown closed={!item.open}>
                                                        <div className="flex flex-1 pl-2 pr-2 box-border">

                                                        </div>
                                                    </SlideDown>
                                                </td>
                                            </tr>
                                            }
                                        </Fragment>
                                    )
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default UsersList;