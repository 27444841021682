import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import { FolderIcon, PencilAltIcon, DuplicateIcon, FolderAddIcon, PlusCircleIcon, ViewGridIcon, CameraIcon, VideoCameraIcon, CollectionIcon, ArrowUpIcon, ArrowDownIcon, EyeIcon, ArrowNarrowRightIcon, ViewGridAddIcon, TemplateIcon, ChevronLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import TextAreaTailwind from '../moduleFiles/textAreaTailwind';
import PreviewSection from '../moduleFiles/previewSection';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { CalendarIcon, ChevronDownIcon, LockClosedIcon, MenuIcon, RefreshIcon, XIcon } from '@heroicons/react/outline';
import FacebookIcon from "../assets/images/facebook_icon.svg"
import LinkedinIcon from "../assets/images/linkedin_icon.svg"
import TwitterIcon from "../assets/images/twitter_icon.svg"
import TiktokIcon from "../assets/images/tiktok_icon.png"
import GoogleIcon from "../assets/images/google_icon.svg"
import SnapchatIcon from "../assets/images/snapchat.svg"
import InstagramIcon from "../assets/images/instagram_icon.svg"
import { ChevronRightIcon } from '@heroicons/react/solid';


class ExternalPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            adgroups: [],
            ads: [],
            channels: {},
            campaign: {},
            adgroup: {},
            channel: {},
            wizard: {},
            channel_tabs: [
                { id: 1, name: "Facebook", value: "facebook" },
                { id: 7, name: "Instagram", value: "instagram" },
                { id: 2, name: "Linkedin", value: "linkedin" },
                { id: 3, name: "Twitter", value: "twitter" },
                { id: 4, name: "TikTok", value: "tiktok" },
                { id: 5, name: "Google", value: "google" },
                { id: 6, name: "Snapchat", value: "snapchat" },
            ],
            selected_channel_tab: {},
            selected_tab: {},
            selected_campaign: {},
            selected_adgroup: {},
            temp: {},
            loading_partial: false,
            hovered_channel_tab: {},
            whitelabel_settings: {},
            loading: false
        };
    }

    componentWillMount() { }

    componentDidMount() {
        this.functions.getWizard(window.location.pathname.replace("/preview/v2/", ""));
    }

    functions = {
        getWizard: async (id) => {
            await this.promisedSetState({ loading: true });
            try {
                let response = await this.calls.getWizard(id);
                await this.promisedSetState({
                    whitelabel_settings: response.data.whitelabel ? response.data.whitelabel : {},
                    wizard: response.data.wizard ? response.data.wizard : {},
                    campaigns: response.data.campaigns ? response.data.campaigns : [],
                    adgroups: response.data.adgroups ? response.data.adgroups : [],
                    ads: response.data.ads ? response.data.ads : [],
                    channels: response.data.channels ? response.data.channels.facebook ? { ...response.data.channels, instagram: true } : response.data.channels : {},
                    selected_channel_tab: response.data.channels ? this.state.channel_tabs.filter(channel => { return channel.value === Object.keys(response.data.channels)[0] })[0] : {},
                    selected_campaign: response.data.campaigns ? response.data.campaigns[0] : {},
                    selected_adgroup: response.data.adgroups ? response.data.adgroups[0] : {},
                });
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading: false });
        },
        classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        },
        selectChannel: async (channel) => {
            await this.promisedSetState({
                selected_channel_tab: channel,
                selected_campaign: this.state.campaigns.filter((campaign) => {
                    if (channel.value === "facebook" ||
                        channel.value === "instagram") {
                        return campaign.channel === "facebook" || campaign.channel === "instagram"
                    }
                    return campaign.channel === channel.value
                })[0],
                selected_adgroup: this.state.adgroups.filter((adgroup => {
                    if (channel.value === "facebook" ||
                        channel.value === "instagram") {
                        return adgroup.channel === "facebook" || adgroup.channel === "instagram"
                    }
                    return adgroup.channel === channel.value
                }))[0]
            })
        },
        selectCampaign: async (campaign) => {
            await this.promisedSetState({
                selected_campaign: campaign,
                selected_adgroup: this.state.adgroups.filter((adgroup => { return adgroup.campaign === campaign.id }))[0]
            })
        },
        selectAdgroup: async (adgroup) => {
            await this.promisedSetState({
                selected_adgroup: adgroup
            })
        }
    };

    calls = {
        getWizard: (id) => {
            let options = apiRegister.options(null, 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getWizardPreview?id=" + id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <Disclosure as="nav" className="shadow sticky top-0 z-50"
                        style={this.state.whitelabel_settings.color_navbar ? { backgroundColor: "#" + this.state.whitelabel_settings.color_navbar } : { "backgroundColor": "#ffffff" }}
                    >
                        {({ open }) => (
                            <>
                                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                                    <div className="relative w-full flex h-16 justify-between">

                                        {/* MOBILE TOP MENU */}
                                        <div className="w-full absolute inset-y-0 left-0 flex items-center sm:hidden justify-between">
                                            <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500"
                                            >
                                                <span className="absolute -inset-0.5" />
                                                <span className="sr-only">Open main menu</span>
                                                {open ? (
                                                    <XIcon className="block h-6 w-6" aria-hidden="true"
                                                        style={this.state.whitelabel_settings.color_font ? { color: "#" + this.state.whitelabel_settings.color_font } : {}}

                                                    />
                                                ) : (
                                                    <MenuIcon className="block h-6 w-6" aria-hidden="true"
                                                        style={this.state.whitelabel_settings.color_font ? { color: "#" + this.state.whitelabel_settings.color_font } : {}}
                                                    />
                                                )}
                                            </Disclosure.Button>
                                        </div>
                                        {/* MOBILE TOP MENU END */}

                                        {/* DESKTOP TOP MENU */}
                                        <div className="flex w-full items-center justify-center h-full">
                                            {
                                                this.state.wizard && this.state.wizard.logo &&
                                                <img
                                                    className={`${this.state.whitelabel_settings.logo_size ? this.state.whitelabel_settings.logo_size.value : "h-5"} w-auto`}
                                                    // src={this.state.whitelabel_settings.logo_primary !== "" ? this.state.whitelabel_settings.logo_primary : this.state.wizard.logo}
                                                    src={this.state.wizard.logo}
                                                />
                                            }
                                            <div className="hidden flex-1 sm:ml-6 sm:flex sm:space-x-8 h-full">
                                                {
                                                    (Object.keys(this.state.channels).length > 0 ? this.state.channel_tabs.filter((channel => {
                                                        return Object.keys(this.state.channels).includes(channel.value)
                                                    })) : []).map((channel, index) => {
                                                        return (
                                                            <div
                                                                className="flex min-w-32 h-full justify-center overflow-hidden truncate items-center border-b-4 border-transparent cursor-pointer px-1 pt-1 text-sm font-medium"
                                                                style={channel.id === this.state.selected_channel_tab.id ? { borderColor: "#" + this.state.whitelabel_settings.color_accent } : {}}
                                                            >
                                                                <div onClick={(e) => {
                                                                    this.functions.selectChannel(channel)
                                                                }} className={`font-medium inline-flex flex-row truncate overflow-hidden p-2 px-3 text-sm ${channel.id === this.state.selected_channel_tab.id ? "opacity-100" : "opacity-50 hover:opacity-100"}`} title={channel.name}
                                                                    style={this.state.whitelabel_settings.color_font ? { color: "#" + this.state.whitelabel_settings.color_font } : {}}
                                                                >
                                                                    <div
                                                                        // style={this.state.whitelabel_settings.color_font ? { backgroundColor: "#" + this.state.whitelabel_settings.color_font } : {}}
                                                                        className={`bg-${channel.value}-500 w-5 h-5 ${channel.id === this.state.selected_channel_tab.id ? "opacity-100" : "opacity-50 hover:opacity-100"}` + " flex items-center rounded-full mr-3 justify-center"} >
                                                                        {
                                                                            channel.value === 'google' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/google_icon.png')} />
                                                                        }
                                                                        {
                                                                            channel.value === 'facebook' &&
                                                                            <img className="w-1"
                                                                                alt=""
                                                                                src={require('../assets/images/facebook_icon.svg')} />
                                                                        }
                                                                        {
                                                                            channel.value === 'linkedin' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/linkedin_icon.svg')} />
                                                                        }
                                                                        {
                                                                            channel.value === 'twitter' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/twitter_icon.svg')} />
                                                                        }
                                                                        {
                                                                            channel.value === 'tiktok' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/tiktok_icon.png')} />
                                                                        }
                                                                        {
                                                                            channel.value === 'instagram' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/instagram_icon.svg')} />
                                                                        }
                                                                        {
                                                                            channel.value === 'snapchat' &&
                                                                            <img className="w-2"
                                                                                alt=""
                                                                                src={require('../assets/images/snapchat.svg')} />
                                                                        }
                                                                    </div>
                                                                    <div className="truncate whitespace-no-wrap"> {channel.name}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        {/* DESKTOP TOP MENU END */}

                                    </div>
                                </div>

                                {/* MOBILE VIEW */}
                                <Disclosure.Panel className="sm:hidden">
                                    <div className="space-y-1 pb-4 pt-2">
                                        {
                                            (Object.keys(this.state.channels).length > 0 ? this.state.channel_tabs.filter((channel => {
                                                return Object.keys(this.state.channels).includes(channel.value)
                                            })) : []).map((channel, index) => {
                                                return (
                                                    <div
                                                        className="block border-l-4 border-transparent py-2 cursor-pointer pl-3 pr-4 text-sm font-medium"
                                                        style={channel.id === this.state.selected_channel_tab.id ? { borderColor: "#" + this.state.whitelabel_settings.color_accent } : {}}
                                                    >
                                                        <div onClick={async (e) => {
                                                            this.functions.selectChannel(channel)
                                                        }} className={`font-medium truncate flex flex-row overflow-hidden p-2 px-3 text-sm ${channel.id === this.state.selected_channel_tab.id ? "opacity-100" : "opacity-50 hover:opacity-100"}`} title={channel.name}
                                                            style={this.state.whitelabel_settings.color_font ? { color: "#" + this.state.whitelabel_settings.color_font } : {}}

                                                        >
                                                            <div
                                                                style={this.state.whitelabel_settings.color_font ? { backgroundColor: "#" + this.state.whitelabel_settings.color_font } : {}}
                                                                className={`w-5 h-5 ${channel.id === this.state.selected_channel_tab.id ? "opacity-100" : "opacity-50 hover:opacity-100"}` + " flex items-center rounded-full mr-3 justify-center"} >
                                                                {
                                                                    channel.value === 'google' &&
                                                                    <img className="w-2"
                                                                        alt=""
                                                                        src={require('../assets/images/google_icon.png')} />
                                                                }
                                                                {
                                                                    channel.value === 'facebook' &&
                                                                    <img className="w-1"
                                                                        alt=""
                                                                        src={require('../assets/images/facebook_icon.svg')} />
                                                                }
                                                                {
                                                                    channel.value === 'linkedin' &&
                                                                    <img className="w-2"
                                                                        alt=""
                                                                        src={require('../assets/images/linkedin_icon.svg')} />
                                                                }
                                                                {
                                                                    channel.value === 'twitter' &&
                                                                    <img className="w-2"
                                                                        alt=""
                                                                        src={require('../assets/images/twitter_icon.svg')} />
                                                                }
                                                                {
                                                                    channel.value === 'tiktok' &&
                                                                    <img className="w-2"
                                                                        alt=""
                                                                        src={require('../assets/images/tiktok_icon.png')} />
                                                                }
                                                                {
                                                                    channel.value === 'instagram' &&
                                                                    <img className="w-2"
                                                                        alt=""
                                                                        src={require('../assets/images/instagram_icon.svg')} />
                                                                }
                                                                {
                                                                    channel.value === 'snapchat' &&
                                                                    <img className="w-2"
                                                                        alt=""
                                                                        src={require('../assets/images/snapchat.svg')} />
                                                                }
                                                            </div>
                                                            <div className="truncate whitespace-no-wrap"> {channel.name}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Disclosure.Panel>

                            </>
                        )}
                    </Disclosure>
                }

                {/* TOP MENU */}
                {
                    !this.state.loading &&
                    <div>

                        {/* PREVIEWS */}
                        <div className="h-full w-full"
                            style={this.state.whitelabel_settings.color_background ? { backgroundColor: "#" + this.state.whitelabel_settings.color_background } : { "backgroundColor": "#ffffff" }}
                        >

                            {/* TITLE AND DESCRIPTION */}
                            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                                {
                                    ((this.state.wizard && this.state.wizard.previewHeadline && this.state.wizard.previewHeadline !== "") || (this.state.wizard && this.state.wizard.previewDescription && this.state.wizard.previewDescription !== "")) &&
                                    <div className="py-4">
                                        {
                                            this.state.wizard && this.state.wizard.previewHeadline && this.state.wizard.previewHeadline !== "" &&
                                            <h3 className="text-base font-semibold leading-6"
                                                style={{ color: "#" + this.state.whitelabel_settings.color_generic_font }}
                                            > {this.state.wizard.previewHeadline}</h3>
                                        }
                                        {
                                            this.state.wizard && this.state.wizard.previewDescription && this.state.wizard.previewDescription !== "" &&
                                            <p className="mt-2 max-w-4xl text-sm text-gray-500"
                                                style={{ color: "#" + this.state.whitelabel_settings.color_generic_font }}
                                            >
                                                {this.state.wizard.previewDescription}
                                            </p>
                                        }
                                    </div>
                                }
                            </div>

                            {/* CAMPAIGN TABS*/}
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <nav className="flex flex-wrap" aria-label="Tabs">
                                    {this.state.campaigns.filter(campaign => {
                                        if (this.state.selected_channel_tab.value === "facebook" || this.state.selected_channel_tab.value === "instagram") {
                                            return campaign.channel === "facebook" || campaign.channel === "instagram"
                                        }
                                        return campaign.channel === this.state.selected_channel_tab.value
                                    }).map((tab) => (
                                        <div
                                            key={tab.name}
                                            className={classNames(
                                                this.state.selected_campaign.id == tab.id ? '' : 'opacity-50 hover:opacity-100',
                                                'rounded-md px-3 py-2 text-sm font-medium inline-flex flex-row cursor-pointer whitespace-no-wrap my-2 mr-4'
                                            )}
                                            onClick={() => {
                                                this.functions.selectCampaign(tab)
                                            }}
                                            style={this.state.whitelabel_settings.color_tab_font && this.state.selected_campaign.id == tab.id ? {
                                                color: "#" + this.state.whitelabel_settings.color_tab_font,
                                                backgroundColor: "#" + this.state.whitelabel_settings.color_tab_font + "10"
                                            } : {
                                                color: "#" + this.state.whitelabel_settings.color_tab_font
                                            }}
                                            aria-current={this.state.selected_campaign.id == tab.id ? 'page' : undefined}
                                        >
                                            <span className="mr-2"><FolderIcon className="w-5"></FolderIcon></span>{tab.name}
                                        </div>
                                    ))}
                                </nav>
                            </div>

                            {/* ADGROUP TABS*/}
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <nav className="flex border-t py-4">
                                    <ul
                                        role="list"
                                        className="flex min-w-full flex-wrap flex-none px-2 text-sm"
                                    >
                                        {this.state.adgroups.filter(adgroup => {
                                            return adgroup.campaign === this.state.selected_campaign.id
                                        }).map((tab) => (
                                            <li key={tab.name}>
                                                <div onClick={() => {
                                                    this.functions.selectAdgroup(tab)
                                                }}
                                                    style={{ color: "#" + this.state.whitelabel_settings.color_tab_font }}
                                                    className={(this.state.selected_adgroup.id == tab.id ? '' : 'opacity-50 hover:opacity-100') + "mr-6 whitespace-no-wrap inline-flex flex-row items-center justify-center cursor-pointer"}>
                                                    <span className="mr-2"><ViewGridIcon className="w-5"></ViewGridIcon></span>{tab.name}
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>

                            {/* ADS */}
                            <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-4 '>
                                <div className='flex sm:inline-flex flex-col sm:flex-row sm:flex-wrap'
                                    style={this.state.whitelabel_settings && this.state.whitelabel_settings.color_background ? { backgroundColor: "#" + this.state.whitelabel_settings.color_background } : { backgroundColor: "#ffffff" }}
                                >
                                    <PreviewSection
                                        campaigns={JSON.parse(JSON.stringify(this.state.campaigns))}
                                        adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                                        ads={JSON.parse(JSON.stringify(this.state.ads))}
                                        wizard={JSON.parse(JSON.stringify(this.state.wizard))}
                                        channels={JSON.parse(JSON.stringify(this.state.channels))}
                                        is_external={true}
                                        ext_selected_channel_tab={this.state.selected_channel_tab}
                                        ext_selected_campaign={this.state.selected_campaign}
                                        ext_selected_adgroup={this.state.selected_adgroup}
                                        whitelabel_settings={this.state.whitelabel_settings}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                }

            </>
        )
    }
}

export default ExternalPreview;