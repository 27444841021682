import React, {Component, Fragment} from "react";
import {
    withGoogleMap,
    GoogleMap,
    withScriptjs,
    Marker,
    Circle,
    Polygon
} from "react-google-maps";

let countries_coordinates = {};

class FacebookLocationMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            center: this.props.center,
            zoom: this.props.zoom,
            places: this.props.places,
            countries_coordinates: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            center: this.state.center !== nextProps.center ? nextProps.center : this.state.center,
            zoom: this.state.zoom !== nextProps.zoom ? nextProps.zoom : this.state.zoom,
            places: this.state.places !== nextProps.places ? nextProps.places : this.state.places
        }, () => {
            //console.log(this.state.places)
        });
    }

    componentDidMount() {
        this.setState({
            center: this.props.center,
            zoom: this.props.zoom,
            places: this.props.places
        }, () => {
            //console.log(this.state.places)
        })
    }

    renders = {
        coordinates: (place) => {
            try{
                let coordinates = [];
                for(let i = 0; countries_coordinates.features.length; i++){
                    if(countries_coordinates.features[i].properties.ADMIN.toLowerCase() === place.name.toLowerCase()){
                        for(let m = 0; m < countries_coordinates.features[m].geometry.coordinates[0].length; m++){
                            let coordinate = {lat: countries_coordinates.features[m].geometry.coordinates[0][0], lng: countries_coordinates.features[m].geometry.coordinates[0][1]};
                            coordinates.push(coordinate);
                        }
                    }
                }
                //console.log(coordinates);
                return coordinates;
            }catch (e) {
                return []
            }
        }
    };

    render() {
        const defaultMapOptions = {
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
        };
        return (
            <GoogleMap
                center={this.state.center}
                zoom={this.state.zoom}
                defaultZoom={this.state.zoom}
                defaultCenter={this.state.center}
                defaultOptions={defaultMapOptions}
            >
                {this.state.places.map(place => {
                    if(place.custom){
                        return (
                            <Fragment key={place.id}>
                                {
                                    false &&
                                    <Marker
                                        position={{
                                            lat: parseFloat(place.lat),
                                            lng: parseFloat(place.lng)
                                        }}
                                    />
                                }
                                {
                                    <Circle
                                        defaultCenter={{
                                            lat: parseFloat(place.lat),
                                            lng: parseFloat(place.lng)
                                        }}
                                        radius={place.radius}
                                        options={{
                                            strokeWeight: "2",
                                            strokeColor: "#0185fe",
                                            fillColor: '#0185fe'
                                        }}
                                    />
                                }
                            </Fragment>
                        )
                    }else{
                        return(
                            <Fragment key={place.id}>
                                <Polygon
                                    path={this.renders.coordinates(place)}
                                    options={{
                                        strokeWeight: "2",
                                        strokeColor: "#0185fe",
                                        fillColor: '#0185fe'
                                    }} />
                            </Fragment>
                        )
                    }
                })}
            </GoogleMap>
        );
    }

}

export default withScriptjs(withGoogleMap(FacebookLocationMap));