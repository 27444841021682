import React, { Component } from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from 'feather-icons-react';
import { BeatLoader } from "react-spinners";
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconLinkedin from "../icons/linkedin";

class Dropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: {},
            options: [],
            search: "",
            placeholder: this.props.placeholder,
            open: false,
            success: false,
            filter: false,
            searchFilter: this.props.searchFilter,
            searchList: this.props.searchList,
            error: false,
            loading: this.props.loading,
            disabled: this.props.disabled,
            removeArrow: this.props.removeArrow,
            extraItem: this.props.extraItem,
            extraSecondItem: this.props.extraSecondItem,
            noTitle: this.props.noTitle,
            icons: this.props.icons,
            channels: this.props.channels,
            extraItemTitle: this.props.extraItemTitle,
            white: this.props.white,
            double: this.props.double,
            bold: this.props.bold,
            lineIcons: this.props.lineIcons,
            showError: this.props.showError,
            requests: this.props.requests ? this.props.requests : {},
            requestSuccess: this.props.requestSuccess
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.props.searchFilter) {
            this.setState({
                search: nextProps.value.name ? nextProps.value.name : "",
                value: nextProps.value,
                options: nextProps.options,
                error: nextProps.error,
                success: nextProps.success,
                placeholder: nextProps.placeholder,
                filter: nextProps.filter,
                loading: nextProps.loading,
                removeArrow: nextProps.removeArrow,
                disabled: nextProps.disabled,
                icons: nextProps.icons,
                channels: nextProps.channels,
                white: nextProps.white,
                bold: nextProps.bold,
                request: nextProps.request,
                border: nextProps.border,
                requests: nextProps.requests ? nextProps.requests : {},
                requestSuccess: nextProps.requestSuccess
            }, () => {
                //console.log(this.state.options);
                if (this.state.disabled) {
                    this.setState({
                        open: false
                    })
                }
            })
        } else {
            this.setState({
                value: nextProps.value,
                options: nextProps.options,
                error: nextProps.error,
                success: nextProps.success,
                placeholder: nextProps.placeholder,
                filter: nextProps.filter,
                loading: nextProps.loading,
                removeArrow: nextProps.removeArrow,
                disabled: nextProps.disabled,
                icons: nextProps.icons,
                channels: nextProps.channels,
                bold: nextProps.bold,
                request: nextProps.request,
                requests: nextProps.requests ? nextProps.requests : {},
                requestSuccess: nextProps.requestSuccess
            }, () => {
                //console.log(this.state.options);
            })
        }
    }

    componentDidMount() {
        this.setState({
            value: this.props.value,
            options: this.props.options,
            placeholder: this.props.placeholder,
            searchFilter: this.props.searchFilter,
            searchList: this.props.searchList,
            loading: this.props.loading,
            removeArrow: this.props.removeArrow,
            disabled: this.props.disabled,
            icons: this.props.icons,
            channels: this.props.channels,
            white: this.props.white,
            bold: this.props.bold,
            filter: this.props.filter,
            request: this.props.request,
            border: this.props.border,
            requests: this.props.requests ? this.props.requests : {},
            requestSuccess: this.props.requestSuccess
        }, () => {
            this.setState({
                search: this.state.value.name,
            })
        })
    }

    init = {};

    functions = {
        select: (value) => {
            if (this.props.searchFilter) {
                this.setState({
                    search: this.state.searchList ? value.name : (this.props.searchKeepValue ? this.state.search : ""),
                    value: this.state.searchList ? value : {},
                }, () => {
                    this.props.onChange(value);
                });
            } else {
                this.setState({
                    search: value.name,
                    value: value
                }, () => {
                    this.props.onChange(value);
                });
            }
        }
    };

    renders = {
        name: (name) => {
            try {
                const n = name.toUpperCase();
                const q = this.state.search.toUpperCase();
                const x = n.indexOf(q);
                if (!q || x === -1) {
                    return name; // bail early
                }
                const l = q.length;
                return name.substr(0, x) + '<b>' + name.substr(x, l) + '</b>' + name.substr(x + l);
            } catch (e) {
                return name;
            }
        },
        markdown: (input) => {
            return { __html: input }
        },
    };

    render() {
        return (
            <div className={
                cx("DropdownContainer",
                    {
                        ["DropdownContainerFull"]: this.props.size === 'full',
                        ["DropdownContainerTiny"]: this.props.tiny,
                    })
            }>
                {
                    !this.state.noTitle &&
                    <div className={cx("DropdownContainerTitle")}>
                        <div style={{ paddingTop: "1px" }} className={
                            cx("font-bold text-xs",
                                {
                                    ["text-red-500"]: this.state.error
                                })
                        }>
                            {this.props.title}
                        </div>
                        {
                            this.state.icons &&
                            <div className="flex flex-row ml-2">
                                {
                                    this.state.channels.map((item) => {
                                        if (item === 'google') {
                                            return (
                                                <div className={'w-5 h-5 bg-google-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                    <img className="w-2" src={require('../../assets/images/google_icon.png')} />
                                                </div>
                                            )
                                        }
                                        if (item === 'google_shopping') {
                                            return (
                                                <div className={'w-5 h-5 bg-googleshopping-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                    <img className="w-2" src={require('../../assets/images/google-shopping.png')} />
                                                </div>
                                            )
                                        }
                                        if (item === 'facebook') {
                                            return (
                                                <div className="flex-row flex">
                                                    <div className={'w-5 h-5 bg-facebook-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                        <img style={{ width: "4px" }} src={require('../../assets/images/facebook_icon.svg')} />
                                                    </div>
                                                    <div className={'w-5 h-5 bg-instagram-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                        <img className="w-2" src={require('../../assets/images/instagram_icon.svg')} />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        if (item === 'dont_show') {
                                            return (
                                                <div className={'w-5 h-5 bg-instagram-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                    <img className="w-2" src={require('../../assets/images/instagram_icon.svg')} />
                                                </div>
                                            )
                                        }
                                        if (item === 'linkedin') {
                                            return (
                                                <div className={'w-5 h-5 bg-linkedin-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                    <img className="w-2" src={require('../../assets/images/linkedin_icon.svg')} />
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }
                        {
                            this.props.rightTitle &&
                            <div className="font-bold text-xs flex flex-1 justify-end text-purple-500">
                                <div onClick={() => {
                                    try {
                                        this.props.onRightTitleClick();
                                    } catch (e) { }
                                }} className="cursor-pointer">
                                    {this.props.rightTitleText}
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className={
                    cx("relative",
                        {
                            ["DropdownContainerSelectorError"]: this.state.error,
                            ["opacity-75"]: this.state.disabled,
                        })
                }>
                    <input
                        type={'text'}
                        autocomplete="off"
                        readOnly={!this.state.filter}
                        placeholder={this.state.placeholder}
                        disabled={this.state.disabled}
                        value={this.state.search}
                        onChange={(event) => this.setState({ search: event.target.value }, () => {
                            try {
                                this.props.onSearch(this.state.search);
                            } catch (e) { }
                        })}
                        onFocus={() => { this.setState({ open: true }) }}
                        onBlur={() => this.setState({ open: false })}
                        className={
                            cx("rounded w-full transition-all duration-200 py-4 px-6 text-purple-900",
                                {
                                    ["InputContainerInputError border"]: this.state.error,
                                    ["DropdownContainerSelectorInputWhite"]: this.state.white,
                                    ["DropdownContainerSelectorInputBold"]: this.state.bold,
                                    ["pl-12"]: this.props.icon,
                                    ["border-0"]: this.props.noBorder,
                                    ["border"]: !this.props.noBorder,
                                    ["bg-white"]: this.props.white
                                })
                        }
                    />
                    {this.props.icon &&
                        <span className='absolute top-0 left-0 mt-5 ml-6 inline-flex items-center justify-center text-gray-500'>
                            {this.state.search
                                ? <span className="text-green-500"><FeatherIcon className="stroke-current mr-2" size={13} icon="check-circle" /></span>
                                : <FeatherIcon className="stroke-current mr-2" size={13} icon={this.props.icon} />
                            }
                        </span>
                    }
                    {
                        !this.state.removeArrow && !this.state.requestSuccess &&
                        <div className="DropdownContainerSelectorArrow top-0">
                            {
                                (!this.state.searchFilter || this.state.searchList) && !this.state.loading &&
                                <FeatherIcon className="cursor-pointer hover:opacity-50" color={"#333333"} size={25} icon="chevron-down" onClick={() => { this.setState({ open: !this.state.open }) }} />
                            }
                            {
                                this.state.success && !this.state.loading &&
                                <FeatherIcon color={"#333333"} size={25} icon="check" />
                            }
                            {
                                !this.state.searchList && !this.state.success && this.state.searchFilter && !this.state.loading &&
                                <FeatherIcon color={"#333333"} size={25} icon="search" />
                            }
                            {
                                this.state.loading &&
                                <BeatLoader
                                    sizeUnit={"px"}
                                    size={8}
                                    color={'#000'}
                                    loading={true}
                                />
                            }
                        </div>
                    }
                    {
                        this.state.requestSuccess &&
                        <div className="DropdownContainerSelectorArrow">
                            <FeatherIcon color={"#333333"} size={25} icon="check" />
                        </div>
                    }
                </div>
                {
                    !this.state.disabled && this.state.open && this.state.options.sort().filter((item) => {
                        //console.log(item);
                        if (this.state.filter) {
                            try {
                                return item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                            } catch (e) {
                                return true;
                            }
                        } else {
                            return true;
                        }
                    }).length > 0 &&
                    <div className={
                        cx("DropdownContainerDropdown",
                            {
                                ["DropdownContainerDropdownNoTitle"]: this.state.noTitle,
                            })
                    }>
                        {
                            this.state.options.sort().filter((item) => {
                                if (this.state.filter) {
                                    try {
                                        return item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                                    } catch (e) {
                                        return true;
                                    }
                                } else {
                                    return true;
                                }
                            }).map((item, i) => {
                                if (this.props.searchFilter) {
                                    return (
                                        <div key={i} onMouseDown={() => this.functions.select(item)} className="DropdownContainerDropdownItem">
                                            <div dangerouslySetInnerHTML={this.renders.markdown(this.renders.name(item.name))} />{" "}
                                            {this.props.showType &&
                                                <span
                                                    className={cx("px-4 py-1 inline-flex text-xs font-bold rounded-full ml-3",
                                                        {
                                                            ["bg-green-100 text-green-500"]: item.type === "LOOKALIKE",
                                                            ["bg-purple-100 text-purple-500"]: item.type === "WEBSITE",
                                                            ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.type === "SHOPPING"
                                                        })
                                                    }>
                                                    {item.type}
                                                </span>
                                            }
                                            {
                                                item.suffix && item.suffix !== "" &&
                                                <span>{item.suffix}</span>
                                            }
                                            {
                                                item.country_name && item.level < "4" && item.level && false &&
                                                <span>,{" "}{item.country_name}</span>
                                            }
                                            {
                                                item.level && item.level === "1" && false &&
                                                <div className="DropdownContainerDropdownItemExtraRight"><b style={{ marginLeft: '5px' }}>Area</b></div>
                                            }
                                            {
                                                item.level && item.level === "2" && false &&
                                                <div className="DropdownContainerDropdownItemExtraRight"><b style={{ marginLeft: '5px' }}>City</b></div>
                                            }
                                            {
                                                item.level && item.level === "3" && false &&
                                                <div className="DropdownContainerDropdownItemExtraRight"><b style={{ marginLeft: '5px' }}>State/County</b></div>
                                            }
                                            {
                                                item.level && item.level === "4" && false &&
                                                <div className="DropdownContainerDropdownItemExtraRight"><b style={{ marginLeft: '5px' }}>Country</b></div>
                                            }
                                            {
                                                this.state.lineIcons &&
                                                <div className="DropdownContainerDropdownItemIcons">
                                                    {
                                                        item.channels.facebook &&
                                                        <div style={{ marginLeft: '3px' }}>
                                                            <IconFacebook noMargin={true} />
                                                        </div>
                                                    }
                                                    {
                                                        item.channels.instagram &&
                                                        <div style={{ marginLeft: '3px' }}>
                                                            <IconInstagram noMargin={true} />
                                                        </div>
                                                    }
                                                    {
                                                        item.channels.google &&
                                                        <div style={{ marginLeft: '3px' }}>
                                                            <IconGoogle noMargin={true} />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                this.state.showError && item.status_code !== 200 &&
                                                <div className="DropdownContainerDropdownItemError">
                                                    {item.status}
                                                </div>
                                            }
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={i} onMouseDown={() => this.functions.select(item)} className="DropdownContainerDropdownItem">
                                            {
                                                item.bold &&
                                                <b>{item.name}</b>
                                            }
                                            {
                                                !item.bold &&
                                                <span>{item.name}</span>
                                            }
                                            {
                                                this.state.extraSecondItem === 'funding_source' &&
                                                <div className="DropdownContainerDropdownItemExtraRight">{item[this.state.extraItem]}{" "}/{" "}{item[this.state.extraSecondItem] ? 'Payment method exist' : 'No payment method exist'}</div>
                                            }
                                            {
                                                this.state.extraItem && this.state.extraItemTitle &&
                                                <div className="DropdownContainerDropdownItemExtraRight"><span className="DropdownContainerDropdownItemExtraRightBold">{item[this.state.extraItem]}</span>{" "}{this.state.extraItemTitle}</div>
                                            }
                                        </div>
                                    )
                                }

                            })
                        }
                    </div>
                }
                {
                    !this.state.disabled && this.state.open && this.state.options.sort().filter((item) => {
                        if (this.state.filter) {
                            try {
                                return item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                            } catch (e) {
                                return true
                            }
                        } else {
                            return true;
                        }
                    }).length < 1 && this.state.request && this.state.search !== "" && !this.state.loading &&
                    <div className={
                        cx("DropdownContainerDropdown",
                            {
                                ["DropdownContainerDropdownNoTitle"]: this.state.noTitle,
                            })
                    }>
                        <div className="DropdownContainerDropdownRequest">
                            {
                                !this.state.requests[this.state.search] &&
                                <div onMouseDown={() => {
                                    try {
                                        let search = JSON.parse(JSON.stringify(this.state.search));
                                        this.setState({
                                            search: ""
                                        }, () => {
                                            this.props.onRequest(search);
                                        })
                                    } catch (e) { }
                                }} className="DropdownContainerDropdownRequestText">
                                    Cant find<b style={{ paddingLeft: '5px' }}>{this.state.search}</b>? <span style={{ paddingLeft: '2px', paddingRight: '2px', textDecoration: 'underline' }}>Click here</span> to request
                                </div>
                            }
                            {
                                this.state.requests[this.state.search] &&
                                <div className="DropdownContainerDropdownRequestText">
                                    Request already send for<b style={{ paddingLeft: '5px' }}>{this.state.search}</b>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Dropdown;
