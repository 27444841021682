import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import Datepicker from "../datepicker";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";
import {campaignRegister} from "../../services/campaignRegister";
import {calls} from "./calls";
import Loader from "../loader";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {userRegister} from "../../services/userRegister";

var moment = require('moment');

class AdvertisingBudget extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            edit: true,
            loading: false
        };
    };

    componentDidMount() {}

    init = {};

    functions = {
        create: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true
                }, () => {
                    let campaign = campaignRegister.exportV2();
                    campaign.test = this.state.test;
                    calls.createCampaign(campaign).then((response) => {
                        this.setState({
                            loading: false
                        }, () => {
                            campaignRegister.remove();
                            this.props.history.push('/user/campaigns/pending');
                        })
                    }, (error) => {
                        this.setState({
                            error_message: error.body.message,
                            error_modal: true,
                            loading: false
                        })
                    });
                })
            }
        },
    };

    renders = {
        wizard: () => {
            try{
                let accepted = true;

                //CHECK ALL ERRORS

                return accepted
            }catch (e) {
                return false
            }
        }
    };

    render() {
        return (
            <div style={{width: '100%'}}>
                {
                    this.state.loading &&
                    <Loader
                        title={'Creating campaign ...'}
                    />
                }
                <SweetAlert
                    show={this.state.create_modal}
                    title="Create campaign"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            create_modal: false
                        }, () => {
                            this.functions.create();
                        })
                    }}
                    onCancel={() => {
                        this.setState({create_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="Ok"
                    onConfirm={() => {
                        this.setState({
                            error_modal: false
                        })
                    }}
                />
                {
                    false &&
                    <div className="flex bg-white rounded-md px-4 py-4 align-middle items-center flex-row mt-4">
                        <div className="font-bold text-sm">
                            Test Mode
                        </div>
                        <div className="flex flex-1">

                        </div>
                        <div>
                            <Switch
                                onColor="#24bda8"
                                checked={this.state.test}
                                onChange={(checked) => {
                                    this.setState({
                                        test: checked
                                    })
                                }}
                            />
                        </div>
                    </div>
                }
                <div className="pb-8 mt-4">
                    <button onClick={() => {
                        this.setState({
                            create_modal: true
                        });
                    }} className="bg-green-500 text-white py-4 px-6 w-full rounded-full font-bold hover:bg-green-700 transition-all duration-200 cursor-pointer">
                        + Create campaign
                    </button>
                </div>
            </div>
        )
    }
}

export default AdvertisingBudget;
