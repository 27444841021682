import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingSites from "../advertising-sites";
import {eventRegister} from "../../services/eventRegister";
import {campaignRegister} from "../../services/campaignRegister";
import SweetAlert from "sweetalert-react";

class AdvertisingCampaignDetailSite extends Component {

    constructor(props) {
        super(props);
        this.state = {
            site: {},
            creative: {},
            url: "",
            loading: false,
            error: false,
            error_message: ""
        };
        this.init.data = this.init.data.bind(this);
    };

    componentWillMount() {
        eventRegister.on('CAMPAIGN_UPDATED', this.init.data);
    }

    componentDidMount() {
        this.init.data();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            error: nextProps.error
        })
    }

    init = {
        data: () => {
            let information = campaignRegister.get();
            this.setState({
                site: information ? (information.site ? (information.site.value ? information.site.value : {}) : {}) : {},
                creatives: information ? (information.creatives ? (information.creatives.value ? information.creatives.value : {}) : {}) : {},
                search: information ? (information.search ? (information.search.value ? information.search.value : {}) : {}) : {},
                next: information?(information.channels?(information.channels.next?information.channels.next:false):false):false
            })
        }
    };

    functions = {
        checkUrl: () => {
            this.setState({
                loading: true
            }, () => {
                dataRegister.getUrlData({
                    url: this.state.url
                }).then((response) => {
                    this.state.site = response;
                    this.state.site.custom = true;
                    this.state.site.checked = true;
                    this.state.site.error = false;
                    this.state.site.url = this.state.url;
                    if(response.description){
                        this.state.site.description = response.description;
                    }
                    this.setState({
                        site: this.state.site,
                        loading: false,
                        error: false,
                        error_message: ""
                    }, () => {
                        this.functions.save('site');
                        try{
                            this.props.onSite();
                        }catch (e) {}
                    })
                }, (error) => {
                    this.state.site.url = this.state.url;
                    this.state.site.error = true;
                    this.state.site.url = this.state.url;
                    this.state.site.error_message = error.body.message;
                    this.setState({
                        site: this.state.site,
                        loading: false
                    }, () => {
                        this.functions.save('site');
                        try{
                            this.props.onSite();
                        }catch (e) {}
                    })
                })
            });
        },
        forceUrl: () => {
            this.state.site = {
                url: this.state.url,
                title: "",
                description: "",
                origin: "",
                hostname: "",
                destination: ""
            };
            this.state.site.custom = true;
            this.state.site.checked = true;
            this.setState({
                site: this.state.site,
                loading: false,
                url: "",
                error: null,
                error_message: ""
            }, () => {
                this.functions.save('site');
                try{
                    this.props.onSite();
                }catch (e) {}
            })
        },
        resetUrl: () => {
            this.setState({
                error: false,
                site: {}
            }, () => {
                this.functions.save('site');
                this.functions.reset();
            })
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        },
        reset: () => {
            let information = campaignRegister.get();
            delete information.budget;
            delete information.locations;
            delete information.advanced;
            delete information.targeting;
            delete information.channels;
            delete information.languages;
            delete information.conversion;
            delete information.creatives;
            delete information.preview;
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        }
    };

    renders = {
        baseUrl: (url) => {
            try{
                url = url.replace('https://', "");
                url = url.replace('http://', "");
                url = url.split( '/' )[0];
                url = 'https://' + url;
                return url;
            }catch (e) {
                return url
            }
        },
        websites: () => {
            let websites = [];
            if(this.state.creatives && Array.isArray(this.state.creatives.image_ads)){
                this.state.creatives.image_ads.map((item) => {
                    websites.push({url: item.website, base: this.renders.baseUrl(item.website), name: item.name, type: "image", platform: "social"});
                });
            }
            if(this.state.creatives && Array.isArray(this.state.creatives.video_ads)){
                this.state.creatives.video_ads.map((item) => {
                    websites.push({url: item.website, base: this.renders.baseUrl(item.website), name: item.name, type: "video", platform: "social"});
                });
            }
            if(this.state.creatives && Array.isArray(this.state.creatives.carousel_ads)){
                this.state.creatives.carousel_ads.map((item) => {
                    websites.push({url: item.website, base: this.renders.baseUrl(item.website), name: item.name, type: "carousel", platform: "social"});
                });
            }
            if(this.state.creatives && Array.isArray(this.state.creatives.dynamic_ads)){
                this.state.creatives.dynamic_ads.map((item) => {
                    websites.push({url: item.website, base: this.renders.baseUrl(item.website), name: item.name, type: "dynamic", platform: "social"});
                });
            }
            if(this.state.search && Array.isArray(this.state.search.keywords)){
                this.state.search.keywords.map((item) => {
                    websites.push({url: item.url, base: this.renders.baseUrl(item.url), name: item.name, type: "keyword", platform: "search"});
                })
            }
            try{
                websites.map((item) => {
                    if(this.state.site.url.toLowerCase() !== item.url.toLowerCase()){
                        item.not_matching = true;
                    }
                });
            }catch (e) {
                console.log(e);
            }
            return websites;
        }
    };

    render() {
        return (
            <div className="w-full">
                <div className="relative">
                    {
                        <div>
                            <Input
                                title={'Website'}
                                value={(!this.state.site.checked||this.state.site.error)?this.state.url:this.state.site.url}
                                name={'website'}
                                icon={'globe'}
                                error={this.state.error || this.state.site.error}
                                placeholder={"https://www.website.com"}
                                disabled={this.state.loading || this.state.site.checked || this.state.site.error}
                                size={'full'}
                                type={"text"}
                                rightTitle={this.state.site.checked && !this.state.site.error}
                                rightTitleText={"Click here to RESET website"}
                                onRightTitleClick={(value) => {
                                    this.setState({
                                        error: false,
                                        site: {}
                                    }, () => {
                                        this.functions.save('site');
                                        try{
                                            this.props.onSite();
                                        }catch (e) {}
                                    })
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        url: e.target.value
                                    });
                                }}
                                showKeyDown={true}
                                onKeyDown={(value) => {
                                    if (value === 13) {
                                        this.functions.checkUrl()
                                    }
                                }}
                                onBlur={() => {
                                    this.functions.checkUrl()
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.site.checked && false &&
                        <div className="">
                            <div className="Section-Inner">
                                <div className="font-bold text-xs mb-1">
                                    Approved URL
                                </div>
                                <div className="relative">
                                    <div style={{backgrounColor: "#fcfcfc"}} className="border rounded-md text-sm rounded w-full border-0 transition-all duration-200 py-4 pr-6 pl-12 text-purple-900">
                                        {this.state.site.url}
                                    </div>
                                    <span className='absolute top-0 left-0 mt-5 ml-6 inline-flex items-center justify-center text-green-500'>
                                        <FeatherIcon className="stroke-current mr-2" size={13} icon="check-circle"/>
                                    </span>
                                    <button onClick={() => {
                                        this.setState({
                                            error: false,
                                            site: {}
                                        }, () => {
                                            this.functions.save('site');
                                            try{
                                                this.props.onSite();
                                            }catch (e) {}
                                        })
                                    }} className="absolute font-bold text-xs text-red-500 inline-flex justify-center items-center top-0 mt-2 mr-2 p-2 right-0 rounded transition-all duration-200 hover:underline">
                                        <FeatherIcon className="stroke-current mr-2" size={13} icon="trash"/> Reset fetched site data
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.site.error &&
                        <div style={{top: "22px", bottom: "0"}} className="flex justify-center items-center absolute pr-3 right-0">
                            <button onClick={() => {
                                this.setState({
                                    error: false,
                                    site: {}
                                }, () => {
                                    this.functions.save('site');
                                    try{
                                        this.props.onSite();
                                    }catch (e) {}
                                })
                            }} className="text-xs font-bold flex items-center px-4 h-8 justify-center bg-opacity-25 bg-red-500 text-red-500 rounded transition-all duration-200 hover:bg-opacity-100 hover:text-white">
                                <FeatherIcon className="stroke-current mr-2" size={13} icon="trash"/> Edit website
                            </button>
                        </div>
                    }
                    {
                        this.state.loading &&
                        <div style={{top: "22px", bottom: "0"}} className="flex justify-center items-center absolute pr-3 right-0">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={8}
                                color={'#000000'}
                                loading={true}
                            />
                        </div>
                        
                    }
                </div>
                {
                    this.renders.websites().filter((item) => {
                    return item.not_matching
                    }).length > 0 && this.state.site.checked &&
                    <div className="flex flex-1 bg-yellow-100 mt-3 rounded-md py-4 px-8">
                        <ul className="list-disc">
                            {
                                this.renders.websites().filter((item) => {
                                    return item.not_matching && item.platform === "search"
                                }).length > 0 &&
                                <li className="text-xs text-yellow-500 font-bold">
                                    You have {this.renders.websites().filter((item) => {
                                    return item.not_matching && item.platform === "search"
                                }).length} keywords not mathing this exact URL
                                </li>
                            }
                            {
                                this.renders.websites().filter((item) => {
                                    return item.not_matching && item.platform === "social" && item.type === "image"
                                }).length > 0 &&
                                <li className="text-xs text-yellow-500 font-bold">
                                    You have {this.renders.websites().filter((item) => {
                                    return item.not_matching && item.platform === "social" && item.type === "image"
                                }).length} image ads not mathing this exact URL
                                </li>
                            }
                            {
                                this.renders.websites().filter((item) => {
                                    return item.not_matching && item.platform === "social" && item.type === "video"
                                }).length > 0 &&
                                <li className="text-xs text-yellow-500 font-bold">
                                    You have {this.renders.websites().filter((item) => {
                                    return item.not_matching && item.platform === "social" && item.type === "video"
                                }).length} video ads not mathing this exact URL
                                </li>
                            }
                            {
                                this.renders.websites().filter((item) => {
                                    return item.not_matching && item.platform === "social" && item.type === "carousel"
                                }).length > 0 &&
                                <li className="text-xs text-yellow-500 font-bold">
                                    You have {this.renders.websites().filter((item) => {
                                    return item.not_matching && item.platform === "social" && item.type === "carousel"
                                }).length} carousel ads not mathing this exact URL
                                </li>
                            }
                            {
                                this.renders.websites().filter((item) => {
                                    return item.not_matching && item.platform === "social" && item.type === "dynamic"
                                }).length > 0 &&
                                <li className="text-xs text-yellow-500 font-bold">
                                    You have {this.renders.websites().filter((item) => {
                                    return item.not_matching && item.platform === "social" && item.type === "dynamic"
                                }).length} dynamic ads not mathing this exact URL
                                </li>
                            }
                        </ul>
                    </div>
                }
                {
                    this.state.site.error &&
                    <div className="flex flex-1 bg-red-100 mt-3 rounded-md py-4 px-8">
                        <ul className="list-decimal">
                            <li className="text-xs text-red-500 font-bold">
                                {this.state.site.error_message}
                            </li>
                            {
                                this.state.url.length > 3 &&
                                <li className="text-xs text-red-500 font-bold">
                                    <div className="mt-2">Are you sure that it's correct? <span className="cursor-pointer text-black" onClick={() => {this.functions.forceUrl()}}>Click here to force approval</span></div>
                                </li>
                            }
                        </ul>
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingCampaignDetailSite;
