import React, { PureComponent } from 'react';
import pptxgen from 'pptxgenjs';
import moment from 'moment';
import {
    firstPresentationSlide,
    secondPresentationSlide,
    thirdPresentationSlide,
    setTabNameBeforeContent,
    chartNumber,
    chartBar,
    chartImg,
    chartHtmlText,
    chartLine,
    chartDonut, chartTable
} from './functions';

export default class PptsGenJs extends PureComponent {
    functions = {
        createNewPptx: async ({ initialFakeData, tabs, selected_logo = '', title, summary }) => {

            let slide = [];
            let params = {};

            const logoUrl = selected_logo;

            let pptx = new pptxgen();

            // Set max width & height of pptx document
            const documentWidth = 14.74;
            const documentHeight = 7.5;
            pptx.defineLayout({ name: 'TST', width: documentWidth, height: documentHeight });
            pptx.layout = 'TST';
            pptx.author = 'User';
            pptx.company = 'Adcredo';

            // Set max width & height of grid
            let blockWidth = documentWidth / 8.01;
            let blockHeight = documentHeight / 6.01;
            let initSizes = { documentWidth: documentWidth, documentHeight: documentHeight, blockWidth: blockWidth, blockHeight: blockHeight };

            // yDifference - difference of 'y' coordinate after creating a new slide. it needs to calculate new 'y' coordinate of element on new slide
            let yDifference = null;

            // 1. Check, - sould we make first slide with logo & text ?
            if ((selected_logo && selected_logo !== "")) {
                await firstPresentationSlide({
                    slide: pptx.addSlide(` ${0}_SLIDE`),
                    initSizes: initSizes,
                    logoUrl: logoUrl,
                    title: title
                });
            }

            if (summary && summary !== "") {
                secondPresentationSlide({
                    slide: pptx.addSlide(` ${0}_SLIDE`),
                    initSizes: initSizes,
                    logoUrl: logoUrl,
                    summary: 'SUMMARY'
                });
                thirdPresentationSlide({
                    slide: pptx.addSlide(` ${0}_SLIDE`),
                    initSizes: initSizes,
                    logoUrl: logoUrl,
                    summary: summary
                });
            }

            tabs.map((tab, index) => {

                //SET TAB NAME
                setTabNameBeforeContent({
                    slide: pptx.addSlide(`${index}_SLIDE`),
                    tabName: tab.title,
                    initSizes: initSizes
                });

                //DIVIDE CELLS INTO GROUPS
                let groups = [];
                tab.groups.filter((item) => {

                    //REMOVE GROUP (PAGE) IF MORE THAN 6 ROWS
                    return item.rows === 6

                }).map((item, index) => {
                    let group = { index: index, cells: [] };
                    tab.grid_data.map((cell) => {
                        if (cell.group === item.id) {
                            group.cells.push(cell);
                        }
                    })
                    groups.push(group);
                });

                let i = 2;
                groups.map((group) => {

                    //CREATE NEW PAGE
                    slide[i] = pptx.addSlide(` ${i}_SLIDE`);
                    slide[i].background = { fill: 'E8E7F2' };

                    //ADD CELLS TO SLIDE
                    for (let j = 0; j < group.cells.length; j++) {
                        let format = group.cells[j].format;
                        params = {
                            pptx: pptx,
                            slide: slide[i],
                            data: group.cells[j],
                            initSizes: initSizes,
                            yDifference: 0,
                            format: format
                        };
                        if (group.cells[j].typeOfBlock === "chart") {
                            if (group.cells[j].chart && group.cells[j].chart.value === "bar") {
                                chartBar(params);
                            } else if (group.cells[j].chart && group.cells[j].chart.value === "line") {
                                chartLine(params);
                            } else if (group.cells[j].chart && group.cells[j].chart.value === "donut") {
                                chartDonut(params);
                            }
                        }
                        else if (group.cells[j].typeOfBlock === "table") {
                            chartTable(params);
                        }
                        else if (group.cells[j].typeOfBlock === "img") {
                            chartImg(params);
                        }
                        else if (group.cells[j].typeOfBlock === "number") {
                            chartNumber(params);
                            
                        }
                        else if (group.cells[j].typeOfBlock === "input") {
                            chartHtmlText(params);
                        }
                    }

                    i++;

                })

            });

            console.log(pptx)

            pptx.writeFile(title).then(function (fileName) {
                console.log('Saved! File Name: ' + fileName);
            });

        }
    };

    render() {
        return <></>;
    }
}
