import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getClient(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', data);
        let url = calls.url.api + '/client';
        return apiRegister.call(options, url);
    }

    static getCampaignsByClient(id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/internal?type=active&client="+id+"&page_size=100000&external_campaigns=true";
        return apiRegister.call(options, url);
    }

    static getCampaigns() {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/internal?type=active&page_size=100000";
        return apiRegister.call(options, url);
    }

    static getAdgroups(id, channel, campaigns, client) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/adgroup/"+id+"?campaigns="+campaigns+"&channel=" + channel + "&client=" + client;
        return apiRegister.call(options, url);
    }

    static getCampaign(id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/internal/" + id;
        return apiRegister.call(options, url);
    }

    static getSearchterms(id, campaigns, sort, order) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/searchterms?client=" + id + "&campaigns=" + campaigns + "&sort=" + sort + "&order=" + order;
        return apiRegister.call(options, url);
    }

    static addNegativeKeyword(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/v2/keywords";
        return apiRegister.call(options, url);
    }

    static getAdset(internal, id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/adset/" + internal + '/' + id;
        return apiRegister.call(options, url);
    }

    static getAd(internal, id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/ad/" + internal + '/' + id;
        return apiRegister.call(options, url);
    }

    static updateAd(internal, id, status) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
        let url = calls.url.api + "/ad/" + internal + '/' + id + '?status=' + status;
        return apiRegister.call(options, url);
    }

    static createNegative(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/keyword/negative";
        return apiRegister.call(options, url);
    }

    static getNegativeKeywords(client, campaigns) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/keywords?campaigns=" + campaigns + "&client=" + client + "&negative=true";
        return apiRegister.call(options, url);
    }

    static getPositiveKeywords(client, campaigns) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/v2/keywords?campaigns=" + campaigns + "&client=" + client + "&positive=true";
        return apiRegister.call(options, url);
    }

    static removeKeyword(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
        let url = calls.url.api + "/v2/keywords";
        return apiRegister.call(options, url);
    }

    static updateAdgroup(data) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
        let url = calls.url.api + "/v2/adgroup/" + data.id;
        return apiRegister.call(options, url);
    }

    static removeAdgroup(data) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
        let url = calls.url.api + "/v2/adgroup/" + data.id;
        return apiRegister.call(options, url);
    }

}

export {calls}