class adTwitter {

    static validate(ad) {
        let error = {
            name: false,
            body: false,
            headline: false,
            page: false,
            website: false,
        };

        if (ad) {
            if (!ad.name || ad.name === "") {
                error.name = true;
            }
            if (!ad.page || ad.page.id === 0) {
                error.page = true;
            }

            if (!ad.link || ad.link === "" || !ad.link.includes("https://")) {
                error.website = true;
            }
            if (!ad.body || ad.body === "") {
                error.body = true;
            }
            if (!ad.title || ad.title === "") {
                if (ad.type !== "posts") error.headline = true;
                if (ad.type === "carousel") error.headline = false;
            }

        }

        return error;
    }
}
export { adTwitter }