import React, { Component } from 'react';
import DropdownTailwind from './dropdownTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import OauthPopup from "react-oauth-popup";
import currencies from "../assets/json/currencies.json";
import SwitchTailwind from './switchTailwind';
import CopyButton from './copyButton';

class AccountTiktok extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            agency: {},
            agency_user: {},
            message: "",
            loading: false,
            loading_account: false,
            loading_advertisers: false,
            advertiser: {},
            advertisers: [],
            healthcheck: {},
            owner: false,
            loading_accounts: false,
            account: {},
            accounts: [],
            me: {},
            manager: {},
            managers: [],
            throttling: {},
            loading_managers: false,
            loading_customers: false,
            is_manager: false,
            agents: [],
            agent: { id: 0, name: "Select agent" },
            ocid: "",
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            syncMessage: "",
            syncManagers: false,
            currency: {},
            currencies: currencies
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user,
            me: this.props.me,
            agency: this.props.agency ? this.props.agency : {},
            agency_user: this.props.agencyUser ? this.props.agencyUser : {},
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.tiktok && !this.state.agency.tiktok) {
            this.functions.getAgents(true);
        }
    }

    async componentWillReceiveProps(nextprops) {
        await this.promisedSetState({
            is_manager: nextprops.isManager,
            user: nextprops.user,
            me: nextprops.me,
            owner: nextprops.owner,
            agency: nextprops.agency ? nextprops.agency : {},
            agency_user: nextprops.agencyUser ? nextprops.agencyUser : {},
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    componentWillUnmount() {

    }

    functions = {
        login: async (token) => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true
                });
                await self.calls.connect({
                    channel: "tiktok",
                    token: token
                });
                await self.promisedSetState({
                    user: { ...this.state.user, ...{ tiktok: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "tiktok"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ tiktok: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        listAdvertisers: async () => {
            await this.promisedSetState({
                loading_advertisers: true
            });
            try {
                let response = await this.calls.listAdvertisers();
                await this.promisedSetState({
                    advertisers: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_advertisers: false
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    advertiser: {
                        id: this.state.advertiser.id,
                        name: this.state.advertiser.name,
                        currency: this.state.currency.id,
                        timezone: this.state.advertiser.display_timezone
                    }
                });
                this.state.client.tiktok = {
                    defaultAgent: this.state.agent,
                    adaccountId: this.state.advertiser.id,
                    adaccountName: this.state.advertiser.name,
                    currency: this.state.currency.id
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    advertiser: {},
                    currency: {},
                    client: this.state.client
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        updateMaxBudget: async () => {
            await this.promisedSetState({
                loading_budget: true,
                error: false
            });
            try {
                let response = await this.calls.updateCientPlatformBudget({
                    channel: "tiktok",
                    enabled: this.state.client.tiktok.maxBudgetEnabled,
                    budget: this.state.client.tiktok.maxBudget
                });
                this.state.client.tiktok.campaignsWithSchedule = response.data.campaignsWithSchedule;
                await this.promisedSetState({
                    client: this.state.client
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error && error.body && error.body.message ? error.body.message : "Something went wrong"
                });
            }
            await this.promisedSetState({
                loading_budget: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.tiktok;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    advertiser: {},
                    currency: {},
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        connectToAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                let response = await this.calls.connectToAgency({
                    channel: 'tiktok'
                });
                this.state.agency.tiktok = {
                    tiktok_connected: response.data.tiktok_connected,
                    matching_managers: response.data.matching_managers,
                    matching_accounts: response.data.matching_accounts,
                    campaigns: response.data.campaigns,
                    authenticated: true
                };
                this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromAgency({
                    channel: 'tiktok'
                });
                this.state.agency.tiktok = { authenticated: false }
                await this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        }
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/tiktok/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateCientPlatformBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateCientPlatformBudget?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/tiktok/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        connectToAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/connectAgencyAccount";
            return apiRegister.call(options, url);
        },
        disconnectFromAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgencyAccount";
            return apiRegister.call(options, url);
        },
        listAdvertisers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/listAdvertisers?client=" + this.state.client.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=tiktok";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative">

                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Signout Tik Tok"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-tiktok-500 rounded-md flex justify-center items-center">
                        <div className="w-6 h-6">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            Tik Tok
                        </div>
                        {
                            !this.state.agency.tiktok &&
                            !this.state.user &&
                            <div className="text-sm text-gray-500">
                                Connect Tik Tok to this client
                            </div>
                        }
                        {
                            !this.state.agency.tiktok &&
                            this.state.user &&
                            <div className="text-sm text-gray-500">
                                Connect Tik Tok to this account
                            </div>
                        }
                        {
                            this.state.agency.tiktok &&
                            <div className="text-sm text-gray-500">
                                Connect TikTok to agency
                            </div>
                        }
                    </div>
                    {
                        (
                            this.state.agency.tiktok ||
                            (
                                !this.state.user &&
                                this.state.client.tiktok &&
                                (this.state.is_manager || (!this.state.is_manager && this.state.client.tiktok.defaultAgent && this.state.client.tiktok.defaultAgent.id === this.state.me.id))
                            )
                        ) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={!this.state.agency.tiktok ? (this.state.client.tiktok ? true : false) : this.state.agency.tiktok.authenticated}
                                onSwitch={async () => {
                                    if (this.state.agency.tiktok) {
                                        if (!this.state.agency.tiktok.authenticated) {
                                            /*
                                            this.state.agency.google.authenticated = true;
                                            await this.promisedSetState({
                                                agency: this.state.agency
                                            });
                                            this.functions.listManagers();
                                            */
                                            this.functions.connectToAgency();
                                        } else {
                                            this.functions.disconnectFromAgency();
                                            /*
                                            if (this.state.agency.google.authenticated) {
                                                this.functions.disconnectFromAgency();
                                            } else {
                                                this.state.agency.google.authenticated = false;
                                                await this.promisedSetState({
                                                    agency: this.state.agency
                                                });
                                            }
                                            */
                                        }
                                    } else {
                                        if (!this.state.client.tiktok) {
                                            this.state.client.tiktok = {};
                                            await this.promisedSetState({
                                                client: this.state.client
                                            });
                                        } else {
                                            this.functions.disconnectFromClient();
                                        }
                                    }
                                }}
                            />
                            {
                                (this.state.loading_account || this.state.syncManagers) &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>

                    }
                </div>
                {
                    !this.state.agency.tiktok &&
                    !this.state.user &&
                    < div className="mt-5">
                        <DropdownTailwind
                            label={"Default agent"}
                            small={false}
                            locked={this.state.client.tiktok}
                            skipInternalSearch={this.state.is_manager}
                            loader={this.state.loading_agents}
                            loadingPagination={this.state.loading_agents_pagination}
                            loadingSearch={this.state.loading_agents_search}
                            total={this.state.total}
                            searchInput={true}
                            placeholder={"..."}
                            defaultOptions={this.state.me.tiktok && this.state.me.tiktok.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                            pagination={this.state.total > (this.state.page * this.state.limit)}
                            selected={this.state.client.tiktok && this.state.client.tiktok.defaultAgent ? this.state.client.tiktok.defaultAgent : this.state.agent}
                            options={this.state.agents}
                            onChange={async (value) => {
                                await this.promisedSetState({
                                    agent: value,
                                    advertiser: {}
                                });
                                this.functions.listAdvertisers();
                            }}
                            onPagination={async () => {
                                if (!this.state.loading_agents_pagination) {
                                    await this.promisedSetState({
                                        page: this.state.page + 1
                                    });
                                    this.functions.getAgents(false, true, false);
                                }
                            }}
                            onSearch={async (value) => {
                                if (value && value === "") {
                                    await this.promisedSetState({
                                        search: "",
                                        page: 1
                                    });
                                    this.functions.getAgents(false, false, true);
                                } else {
                                    if (this.state.throttling.current) {
                                        //SKIP
                                    } else {
                                        if (value && value === "") {
                                            await this.promisedSetState({
                                                search: value,
                                                page: 1
                                            });
                                            this.functions.getAgents(false, false, true);
                                        } else {
                                            this.state.throttling.current = true;
                                            await this.promisedSetState({
                                                search: value,
                                                throttling: this.state.throttling
                                            });
                                            setTimeout(async () => {
                                                this.state.throttling.current = false;
                                                await this.promisedSetState({
                                                    throttling: this.state.throttling
                                                });
                                                await this.promisedSetState({
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            }, 300);
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                }
                {
                    !this.state.agency.tiktok &&
                    this.state.client.id &&
                    (this.state.client.tiktok || this.state.agent.id !== 0) &&
                    <div className="flex mt-5 items-center w-full">
                        <div className="flex-1">
                            <DropdownTailwind
                                label={this.state.client.tiktok ? "Advertiser" : "Advertisers"}
                                loader={this.state.loading_advertisers}
                                locked={this.state.client.tiktok}
                                selected={this.state.client.tiktok && this.state.client.tiktok.adaccountId ? { name: this.state.client.tiktok.adaccountName } : (this.state.advertiser.id ? this.state.advertiser : { name: "Select account" })}
                                options={this.state.advertisers.map((item) => {
                                    item.id = item.advertiser_id;
                                    return item;
                                })}
                                onChange={async (value) => {

                                    //PRESET CURRENCY
                                    let currency = null;
                                    this.state.currencies.map((item) => {
                                        if (item.id == value.currency) {
                                            currency = item;
                                        }
                                    });
                                    if (currency) {
                                        this.state.currency = currency;
                                    }

                                    this.setState({
                                        advertiser: value
                                    });
                                }}
                            />
                        </div>
                        <CopyButton
                            buttonText="Copy"
                            disabled={!((this.state.client.tiktok && this.state.client.tiktok.adaccountId) || this.state.advertiser.id)}
                            copyText={(this.state.client.tiktok && this.state.client.tiktok.adaccountId) ? this.state.client.tiktok.adaccountId : this.state.advertiser.id}
                        />
                    </div>
                }
                {
                    !this.state.agency.tiktok &&
                    this.state.client.id &&
                    (this.state.advertiser.id || (this.state.client.tiktok && this.state.client.tiktok.adaccountId)) &&
                    <div className="mt-5">
                        <DropdownTailwind
                            searchInput={true}
                            label={"Currency"}
                            locked={this.state.client.tiktok}
                            selected={this.state.client.tiktok && this.state.client.tiktok.adaccountId ? { name: this.state.client.tiktok.currency } : (this.state.currency.id ? this.state.currency : { name: "Select currency" })}
                            options={this.state.currencies}
                            onChange={async (value) => {
                                this.setState({
                                    currency: value
                                });
                            }}
                        />
                    </div>
                }
                {
                    false &&
                    !this.state.agency.tiktok &&
                    this.state.client.id &&
                    this.state.client.tiktok && this.state.client.tiktok.adaccountId &&
                    <div className="mt-5 border-t flex items-center flex-row pt-5 w-full">
                        <div className="flex flex-2">
                            <InputTailwind
                                progressBar={this.state.client.tiktok && this.state.client.tiktok.maxBudgetEnabled && +this.state.client.tiktok.maxBudget > 0}
                                progressBarValue={this.state.client.tiktok.totalBudgetThisMonth ? +this.state.client.tiktok.totalBudgetThisMonth : 0}
                                progressBarLowestValue={0}
                                progressBarTopValue={this.state.client.tiktok.maxBudget}
                                progressBarPercentage={(+this.state.client.tiktok.totalBudgetThisMonth / +this.state.client.tiktok.maxBudget) * 100}
                                leftSection={true}
                                leftSectionLabel={this.state.client.tiktok.currency ? this.state.client.tiktok.currency : "-"}
                                onBlur={() => {
                                    this.functions.updateMaxBudget();
                                }}
                                onFocus={() => {

                                }}
                                loading={this.state.loading_budget}
                                full={true}
                                label={"Monthly max budget"}
                                disabled={!this.state.client.tiktok.maxBudgetEnabled || this.state.loading_budget}
                                value={this.state.client.tiktok && this.state.client.tiktok.maxBudgetEnabled ? this.state.client.tiktok.maxBudget : ""}
                                onChange={async (value) => {
                                    this.state.client.tiktok.maxBudget = value;
                                    this.setState({
                                        client: this.state.client
                                    });
                                }}
                            />
                        </div>
                        <div className="flex justify-end pt-5 pl-10">
                            <SwitchTailwind
                                value={this.state.client.tiktok.maxBudgetEnabled}
                                onSwitch={async () => {
                                    if (!this.state.loading_budget) {
                                        this.state.client.tiktok.maxBudgetEnabled = !this.state.client.tiktok.maxBudgetEnabled;
                                        if (!this.state.client.tiktok.maxBudgetEnabled) {
                                            this.state.client.tiktok.maxBudget = 0;
                                            this.functions.updateMaxBudget();
                                        }
                                        this.setState({
                                            client: this.state.client
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                }
                {
                    !this.state.agency.tiktok &&
                    this.state.client.id &&
                    this.state.client.tiktok && this.state.client.tiktok.adaccountId &&
                    this.state.client.tiktok.maxBudgetEnabled &&
                    this.state.client.tiktok.campaignsWithSchedule < 1 &&
                    <div className="mt-5 border-t pt-5 w-full">
                        <div className="bg-orange-100 p-4 rounded-md text-sm text-orange-500">
                            <div>No connected campaigns with Schedule activated</div>
                        </div>
                    </div>
                }
                {
                    !this.state.agency.tiktok &&
                    this.state.client.id &&
                    !this.state.client.tiktok &&
                    this.state.advertiser.id &&
                    <div className="">
                        <button
                            onClick={() => {
                                if (!this.state.loading_account) {
                                    this.functions.connectToClient();
                                }
                            }}
                            className={(this.state.advertiser.id ? "bg-tiktok-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Save Tik Tok account</span>
                            {
                                this.state.loading_account &&
                                <div className={"bg-tiktok-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    !this.state.agency.tiktok &&
                    !this.state.client.id &&
                    (!this.state.user.tiktok || (this.state.user.tiktok && !this.state.user.tiktok.authenticated)) &&
                    (window.location.hostname.indexOf("localhost") !== -1 || window.location.hostname.indexOf("dev.") !== -1) &&
                    <div className="mt-5">
                        <InputTailwind
                            label={"Auth code (only visible on local and dev)"}
                            rightLabel={true}
                            rightLabelText={"Get access token"}
                            onRightLabelClick={() => {
                                this.functions.login(this.state.code);
                            }}
                            value={this.state.code}
                            onChange={(value) => {
                                this.setState({
                                    code: value
                                })
                            }}
                        />
                    </div>
                }
                {
                    !this.state.agency.tiktok &&
                    !this.state.client.id &&
                    (!this.state.user.tiktok || (this.state.user.tiktok && !this.state.user.tiktok.authenticated)) &&
                    <OauthPopup
                        url={"https://ads.tiktok.com/marketing_api/auth?app_id=6919514014611406850&redirect_uri=https%3A%2F%2Fapp.adcredo.io%2Fcallback%2Ftiktok&rid=fg98qet8cq"}
                        onCode={(code) => {
                            if (code) {
                                this.functions.login(code);
                            }
                        }}
                        onClose={() => {
                            console.log('closed')
                        }}
                    >
                        <button className={(!(this.state.user.tiktok && this.state.user.tiktok.authenticated) ?
                            "cursor-pointer bg-tiktok-500 text-white" : " bg-red-500 text-white cursor-pointer") + " mt-5 shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                        }
                        >
                            <span>Login with Tik Tok</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.tiktok && this.state.user.tiktok.authenticated) ? "bg-tiktok-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </OauthPopup>
                }
                {
                    !this.state.agency.tiktok &&
                    !this.state.client.id &&
                    this.state.user.tiktok && this.state.user.tiktok.authenticated &&
                    <div className="mt-5">
                        <button
                            onClick={() => {
                                this.setState({
                                    remove: true
                                });
                            }}
                            className={(!(this.state.user.tiktok && this.state.user.tiktok.authenticated) ?
                                "cursor-pointer bg-tiktok-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Remove</span>
                            {
                                this.state.loading &&
                                <div className={(!(this.state.user.tiktok && this.state.user.tiktok.authenticated) ? "bg-tiktok-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    false &&
                    this.state.agency.tiktok &&
                    this.state.agency.tiktok.authenticated &&
                    <div className="mt-5 w-full">
                        <dl className={"mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4"}>
                            {[
                                { name: "Clients", stat: this.state.agency.tiktok.tiktok_connected },
                                { name: "Managers", stat: this.state.agency.tiktok.matching_managers },
                                { name: "Ad accounts", stat: this.state.agency.tiktok.matching_accounts },
                                { name: "Campaigns", stat: this.state.agency.tiktok.campaigns }
                            ].map((item) => (
                                <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-5">
                                    <dt className="text-sm font-medium text-gray-500">{item.name}</dt>
                                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                }
                {
                    false &&
                    this.state.agency.tiktok &&
                    this.state.agency.tiktok.authenticated &&
                    <div className="mt-5">
                        <button
                            onClick={() => {
                                if (!this.state.loading_last7 && !this.state.syncManagers) {
                                    this.functions.fetchLast7Days();
                                }
                            }}
                            className={"shadow bg-tiktok-500 inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                        >
                            <span>Fetch last 7 days</span>
                            {
                                (this.state.loading_last7 || this.state.syncManagers) &&
                                <div className={" w-full h-full absolute bg-tiktok-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                    <div style={{ borderTopColor: "transparent" }}
                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </button>
                    </div>
                }
                {
                    this.state.agency.tiktok &&
                    this.state.agency.tiktok.authenticated &&
                    this.state.syncManagers &&
                    <div className="bg-yellow-100 p-4 mt-4 text-yellow-500 text-sm font-medium rounded-md">
                        {this.state.syncMessage}
                    </div>
                }
                {
                    this.state.error &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        {this.state.message}
                    </div>
                }
                {
                    !this.state.agency.tiktok &&
                    this.state.healthcheck && this.state.healthcheck.tiktok && this.state.healthcheck.tiktok.status != 200 &&
                    <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                        Token Check: {this.state.healthcheck.tiktok.message}. We recommend to sign out and sign in again.
                    </div>
                }
                {
                    !this.state.agency.tiktok &&
                    !this.state.user &&
                    this.state.client.tiktok &&
                    !(this.state.is_manager || (!this.state.is_manager && this.state.client.tiktok && this.state.client.tiktok.defaultAgent && this.state.client.tiktok.defaultAgent.id === this.state.me.id)) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You are not "default agent" or "agency manager"
                    </div>
                }
                {
                    !this.state.agency.tiktok &&
                    !this.state.user &&
                    !this.state.is_manager &&
                    !this.state.client.tiktok &&
                    !(this.state.me.tiktok && this.state.me.tiktok.authenticated) &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! You have not authenticated Tiktok, go to My Profile.
                    </div>
                }
                {
                    !this.state.agency.tiktok &&
                    !this.state.user &&
                    this.state.is_manager &&
                    !this.state.client.tiktok &&
                    this.state.agents.length < 1 &&
                    <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                        OBS! No available agents who has authenticated Tiktok
                    </div>
                }
            </div>
        )
    }
}

export default AccountTiktok;
