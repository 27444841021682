import React, { Component, Fragment } from 'react';
import { ChevronDownIcon, SearchIcon, SortAscendingIcon, PlusIcon, ChevronUpIcon, TrashIcon, UserGroupIcon, IdentificationIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { UserIcon } from '@heroicons/react/outline';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import { Menu, Transition } from '@headlessui/react';
import cn from "classnames";
import { clientRegister } from '../services/clientRegister';
import CreateClient from '../moduleFiles/createClient';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import SwitchTailwind from '../moduleFiles/switchTailwind';

class JobList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            loading: false,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            sort: "created",
            direction: "descending",
            search: "",
            columns: [
                { name: 'type', value: 'type', min_width: '250px' },
                { name: 'status', value: 'status', min_width: '75px' },
                { name: 'runnings', value: 'runAmount', min_width: '50px' },
                { name: 'priority', value: 'priority', min_width: '75px' },
                { name: 'created', value: 'created', min_width: '150px' },
                { name: 'updated', value: 'updated', min_width: '150px' },
                { name: 'message', value: 'message', noSort: true },
                { name: 'platform', value: 'apiPlatform', min_width: '150px', noSort: true },
                { name: 'params', value: 'params', noSort: true },
                { name: 'agency', value: 'agency', min_width: '250px' },
                { name: 'client', value: 'client', min_width: '250px' },
                { name: 'agent', value: 'agent', min_width: '250px' }
            ],
            chart: {},
            client: { id: 0 },
            job_type_data: {},
            job_types: [
                { id: "campaignSchedule", value: "campaign_schedule", name: "Campaign Schedule" },
                { id: "adgroupSchedule", value: "adgroup_schedule", name: "Adgroup Schedule" },
                { id: "campaignBudgetPlaner", value: "campaign_planer", name: "Campaign Budgetplanner" },
                { id: "adgroupBudgetPlaner", value: "adgroup_planer", name: "Adgroup Budgetplanner" },
                { id: "checkErrors", value: "check_errors", name: "Check errors" },
                { id: "checkUserPlatformToken", value: "check_user_platform_tokens", name: "Check User platform tokens" },
                { id: "checkClientMaxBudget", value: "check_client_max_budget", name: "Check client max budget" },
                { id: "checkOrderMaxBudget", value: "check_order_max_budget", name: "Check order max budget" },
                { id: "checkGoogleAccountBudgetLimit", value: "check_google_account_budget", name: "Check google account budget" },
                { id: "campaignInformation", value: "campaign_information", name: "Campaign information" },
                { id: "campaignPerformanceDate", value: "campaign_performance", name: "Campaign performance" },
                { id: "checkBingCampaignInSync", value: "check_bing_campaign_sync", name: "Check Bing campaign sync" },
                //{ id: "campaignPerformanceGoogle", name: "Campaign performance Google" },
            ],
            advancedFilters: [
                {
                    type: "status",
                    placeholder: "All stats (default)",
                    defaultOptions: [],
                    options: [
                        { id: 0, name: "All stats (default)", value: "all" },
                        { id: 1, name: "Pending", value: "0" },
                        { id: 2, name: "Success", value: "1" },
                        { id: 3, name: "Error", value: "2" }
                    ],
                    selected: { id: 3, name: "Error", value: "2" }
                },
                {
                    type: "date",
                    placeholder: "Today",
                    defaultOptions: [],
                    options: [
                        { id: 0, name: "Today", value: "today" },
                        { id: 1, name: "Yesterday", value: "yesterday" },
                        { id: 2, name: "2 days ago", value: "2_days" },
                        { id: 3, name: "3 days ago", value: "3_days" },
                    ],
                    selected: { id: 0, name: "Today", value: "today" }
                },
                {
                    type: "priority",
                    placeholder: "All priorities (default)",
                    options: [
                        { id: 0, name: "All priorities (default)", value: "all" },
                        { id: 1, name: "High", value: "1" },
                        { id: 2, name: "Medium", value: "2" },
                        { id: 3, name: "Low", value: "3" }
                    ],
                    selected: { id: 0, name: "All priorities (default)", value: "all" }
                },
                {
                    type: "runAmount",
                    placeholder: "All runnings (default)",
                    options: [
                        { id: 0, name: "All runnings (default)", value: "all" },
                        { id: 1, name: "Less than 3 runnings", value: "less" },
                        { id: 2, name: "3 runnings or more", value: "more" }
                    ],
                    selected: { id: 0, name: "All runnings (default)", value: "all" }
                },
                {
                    type: "platform",
                    placeholder: "All platforms",
                    options: [
                        { id: 0, name: "All platforms (default)", value: "all" },
                        { id: 1, name: "Google", value: "google" },
                        { id: 2, name: "Facebook", value: "facebook" },
                        { id: 3, name: "Linkedin", value: "linkedin" },
                        { id: 4, name: "Tiktok", value: "tiktok" },
                        { id: 5, name: "Snapchat", value: "snapchat" },
                        { id: 6, name: "Bing", value: "bing" }
                    ],
                    selected: { id: 0, name: "All platforms (default)", value: "all" }
                },
                {
                    type: "type",
                    placeholder: "All types (default)",
                    options: [
                        { id: 0, name: "All types (default)", value: "all" },
                        { id: 1, name: "campaignSchedule", value: "campaignSchedule" },
                        { id: 2, name: "adgroupSchedule", value: "adgroupSchedule" },
                        { id: 13, name: "checkBudgetPlans", value: "checkBudgetPlans" },
                        { id: 5, name: "checkErrors", value: "checkErrors" },
                        { id: 20, name: "createWarnings", value: "createWarnings" },
                        { id: 6, name: "checkUserPlatformToken", value: "checkUserPlatformToken" },
                        { id: 12, name: "maxOrderBudget", value: "maxOrderBudget" },
                        { id: 9, name: "campaignInformation", value: "campaignInformation" },
                        { id: 9, name: "campaignPerformanceDate", value: "campaignPerformanceDate" },
                        { id: 15, name: "checkBingCampaignInSync", value: "checkBingCampaignInSync" },
                        { id: 16, name: "firstOfMonthOrderManager", value: "firstOfMonthOrderManager" },
                        { id: 20, name: "createNextMonthAccountBudgets", value: "createNextMonthAccountBudgets" },
                        { id: 21, name: "getClientAccountBudget", value: "getClientAccountBudget" },
                        { id: 22, name: "aggregateData", value: "aggregateData" },
                        { id: 23, name: "createNextMonthAccountBudgets", value: "createNextMonthAccountBudgets" },
                        { id: 24, name: "reportSendReportEmail", value: "reportSendReportEmail" },
                        { id: 24, name: "orderCampaignBudgetAdjust", value: "orderCampaignBudgetAdjust" }
                    ],
                    selected: { id: 0, name: "All types (default)", value: "all" }
                }
            ]
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.functions.logSegments(true);
        this.functions.checkUrl();
    }

    functions = {
        logs: async (init, search) => {
            await this.promisedSetState({ loading_partial: true });
            try {
                let params = "";
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.getJobs(params);
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({
                        logs: response.data,
                        meta: response.meta
                    });
                }
            } catch (error) { }
            await this.promisedSetState({ loading_partial: false });
        },
        logSegments: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.getJobSegments();
                await this.promisedSetState({
                    job_type_data: response.data
                });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
            this.functions.logs();
        },
        updateSegment: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.updateSegment();
                await this.promisedSetState({
                    job_type_data: response.data
                });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
            this.functions.logs();
        },
        run: async (temp) => {
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.logs.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ logs: this.state.logs });
            try {
                let response = await this.calls.runJob(temp.id);
                temp = response.data;
            } catch (error) {
                console.log(error);
            }
            this.state.logs = this.state.logs.map((item) => {
                if (item.id === temp.id) {
                    item.message = temp.message;
                    item.runAmount = temp.runAmount;
                    item.status = temp.status;
                    item.updated = temp.updated;
                    item.loading = false;
                }
                return item
            });
            await this.promisedSetState({ logs: this.state.logs });
        },
        checkUrl: async () => {
            try {
                let captured_url = new URL(window.location.href);
                if (captured_url.href.includes("?")) {
                    let buff = captured_url.href.split("?")[1]
                    let filter = this.state.advancedFilters.find(object => object["type"] === "type")
                    if (filter) {
                        filter.selected = filter.options.find(object => object.value === buff)
                    }
                    await this.promisedSetState({})
                }
            } catch (error) {
                console.log(error)
            }
        }
    };


    renders = {
        status: (status) => {
            if (status == 0) {
                return "pending"
            } else if (status == 1) {
                return "success"
            } else if (status == 2) {
                return "error"
            } else {
                return "-";
            }
        },
        priority: (priority) => {
            if (priority == 1) {
                return "High"
            } else if (priority == 2) {
                return "Medium"
            } else if (priority == 3) {
                return "Low"
            } else {
                return "-";
            }
        }
    };

    calls = {
        getJobs: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listJobs?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + params;
            return apiRegister.call(options, url);
        },
        getJobSegments: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getJobSegments";
            return apiRegister.call(options, url);
        },
        updateSegment: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateSegment";
            return apiRegister.call(options, url);
        },
        runJob: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/runJob?id=" + id;
            //let url = "https://apidev.adcredo.io/api" + "/v3/adcredo/runJob?id=" + id;
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                {/*TOP MENU*/}
                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        miniMizeOnLoad={false}
                        minimizeMenu={(option) => {
                            if (this.props.minimizeMenu) {
                                this.props.minimizeMenu(option);
                            }
                        }}
                        onButton={(type) => {

                        }}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            this.promisedSetState({
                                page: 1,
                                limit: 10,
                                advancedFilters: this.state.advancedFilters,
                            });
                            this.functions.logs();
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.logs();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.logs();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.logs();
                        }}
                        onSearch={async (value) => {

                        }}
                        showAdvanced={true}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta ? this.state.meta["total"] : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={false}
                        showFilters={!this.state.loading}
                        hideUserDropdown={true}
                        buttons={[]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                page: 1,
                                client: client
                            });
                            this.functions.logs();
                        }}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading jobs ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        {/*NO ORDERS*/}

                        {/* <div className="grid grid-cols-12 gap-4 mb-4">
                            {
                                this.state.job_types.map((item) => {
                                    return (
                                        <div className="shadow bg-white rounded-lg w-full overflow-hidden col-span-4">
                                            <div className="w-full border-b p-4 text flex flex-row items-center">
                                                <div className="text-sm font-medium flex flex-1">
                                                    {item.name}
                                                </div>
                                                <div>
                                                    <SwitchTailwind
                                                        value={item.enabled}
                                                        onSwitch={async () => {
                                                            item.enabled = !item.enabled;

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="p-4 grid grid-cols-12 gap-4">
                                                <div className="col-span-4 flex justify-center items-center flex-col">
                                                    <div className="text-2xl font-semibold">{this.state.job_type_data && this.state.job_type_data[item.value] ? this.state.job_type_data[item.value].pending : "0"}</div>
                                                    <div className="text-xs font-medium">pending</div>
                                                </div>
                                                <div className="col-span-4 flex justify-center items-center flex-col">
                                                    <div className="text-2xl font-semibold">{this.state.job_type_data && this.state.job_type_data[item.value] ? this.state.job_type_data[item.value].success : "0"}</div>
                                                    <div className="text-xs font-medium">success</div>
                                                </div>
                                                <div className="col-span-4 flex justify-center items-center flex-col">
                                                    <div className="text-2xl font-semibold">{this.state.job_type_data && this.state.job_type_data[item.value] ? this.state.job_type_data[item.value].error : "0"}</div>
                                                    <div className="text-xs font-medium">error</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div> */}

                        <div className="shadow bg-white rounded-lg w-full overflow-hidden">

                            {/*NO ORDERS*/}
                            {
                                this.state.logs.length < 1 &&
                                <div className="border-t border-gray-200 h-170 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No jobs available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.logs.length > 0 &&
                                <div className="relative overflow-scroll">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className=""></th>
                                                {this.state.columns.map((item, index) => {
                                                    return (
                                                        <th
                                                            onClick={async () => {
                                                                if (!item.noSort) {
                                                                    await this.promisedSetState({
                                                                        page: 1,
                                                                        sort: item.value,
                                                                        direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                    });
                                                                    this.functions.logs();
                                                                }
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                {
                                                                    !item.noSort &&
                                                                    <div className="flex flex-col">
                                                                        <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                        <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.logs.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr className="border-b" key={item.id}>
                                                                <td style={{ width: "100px", maxWidth: "100px" }} className={(item.hover ? "bg-gray-50 " : "bg-white ") + " px-4 sm:px-6 py-4 flex flex-row items-center justify-center  border-gray-300"}>
                                                                    <div onClick={() => {
                                                                        if (!item.loading) {
                                                                            this.functions.run(item);
                                                                        }
                                                                    }} className="cursor-pointer px-5 flex relative h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                                        Run
                                                                        {
                                                                            item.loading &&
                                                                            <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(this.state.sort === 'type' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.type ? item.type : "-"}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {this.renders.status(item.status)}
                                                                </td>
                                                                <td className={(this.state.sort === 'runAmount' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.runAmount}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(this.state.sort === 'priority' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {this.renders.priority(item.priority)}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(this.state.sort === 'created' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {typeof item.createdLocal === "string" && item.createdLocal !== "" ? item.createdLocal : "-"}
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(this.state.sort === 'updated' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.updated !== item.created ? item.updatedLocal : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'message' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 whitespace-no-wrap font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.message ? item.message : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'apiPlatform' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 whitespace-no-wrap font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.apiPlatform ? item.apiPlatform : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'params' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 whitespace-no-wrap font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.params ? JSON.stringify(item.params) : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'agency' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 whitespace-no-wrap font-medium cursor-pointer  items-center text-sm  border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                                                            {
                                                                                item.agency && item.agency.logo &&
                                                                                <img src={item.agency.logo} className="bg-cover" />
                                                                            }
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            {item.agency && item.agency.name ? item.agency.name : "-"}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className={(this.state.sort === 'client' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 whitespace-no-wrap font-medium cursor-pointer  items-center text-sm  border-gray-300"}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                                                            {
                                                                                item.client && item.client.logo &&
                                                                                <img src={item.client.logo} className="bg-cover" />
                                                                            }
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            {item.client && item.client.name ? item.client.name : "-"}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className={(this.state.sort === 'agent' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 whitespace-no-wrap font-medium cursor-pointer items-center text-sm   border-gray-300"}>
                                                                    {item.agent && item.agent.email ? item.agent.email : "-"}
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {/* PAGINATION */}
                            {
                                this.state.logs.length > 0 &&
                                <div className="flex flex-row p-4 items-center">
                                    <div className="flex justify-center items-center">
                                        <button
                                            type="button"
                                            onClick={async () => {
                                                if (this.state.page !== 1) {
                                                    await this.promisedSetState({
                                                        page: this.state.page - 1
                                                    });
                                                    this.functions.logs();
                                                }
                                            }}
                                            className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                            Previous
                                        </button>
                                    </div>
                                    <div className="flex flex-1 items-center justify-center">
                                        {
                                            (this.state.meta ? +this.state.meta.total : 0) !== 0 &&
                                            <p className="text-sm text-gray-700">
                                                Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > (this.state.meta ? +this.state.meta.total : 0)) ? (this.state.meta ? +this.state.meta.total : 0) : this.state.page * this.state.limit}</span> of{' '}
                                                <span className="font-medium">{(this.state.meta ? +this.state.meta.total : 0)}</span> results
                                            </p>
                                        }
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <div className="">
                                            <button
                                                onClick={async () => {
                                                    if (!((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0))) {
                                                        await this.promisedSetState({
                                                            page: this.state.page + 1
                                                        });
                                                        this.functions.logs();
                                                    }
                                                }}
                                                className={(((this.state.page * this.state.limit) >= (this.state.meta ? +this.state.meta.total : 0)) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                <span>Next</span>
                                                <ArrowRightIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default JobList;