import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import { SlideDown } from 'react-slidedown'
import { dataRegister } from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import { BeatLoader } from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import { calls } from "./calls";
import { tokenRegister } from "../../services/tokenRegister";
import ClientFacebookPixel from "../client-facebook-pixel";
import ClientGooglePixel from "../client-google-pixel";
import ClientFacebookAdaccount from "../client-facebook-adaccount";
import DropdownTailwind from '../../moduleFiles/dropdownTailwind';
import InputTailwind from '../../moduleFiles/inputTailwind';

class ClientGoogle extends Component {

    googleSDK = () => {
        window['googleSDKLoaded'] = () => {
            window['gapi'].load('auth2', () => {
                window.test_auth2 = window['gapi'].auth2.init({
                    client_id: '308101956397-dmf2hvrqrfgpgvttb22t2eljgelp6ook.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin',
                    scope: 'https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/content'
                });
                //console.log(window.test_auth2);
                //this.prepareLoginButton();
            });
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
    };

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            token: null,
            open: false,
            loading: false,
            managers: [],
            clients: [],
            manager: {},
            client: {},
            managers_loading: false,
            clients_loading: false,
            submenu: 0,
            ocid: ""
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        }, () => {
            this.googleSDK();
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item ? nextProps.item : {}
        });
    }

    init = {};

    functions = {
        login: () => {
            let self = this;
            if (!self.state.loading) {
                self.setState({
                    loading: true
                }, () => {
                    window.test_auth2.grantOfflineAccess().then(res => {
                        calls.getToken({ code: res.code }).then((response) => {
                            //console.log(response);
                            self.setState({
                                token: response.data,
                                loading: false
                            }, () => {
                                self.functions.managers();
                            })
                        }, (error) => {
                            self.setState({
                                loading: false
                            })
                        });
                    }).catch(function (error) {
                        console.log(error);
                        self.setState({
                            loading: false
                        })
                    })
                })
            }
        },
        logout: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    remove_modal: false
                }, () => {
                    calls.remove(this.state.item.id).then((response) => {
                        delete this.state.item.google;
                        this.setState({
                            item: this.state.item,
                            loading: false
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        create: () => {
            if (!this.state.loading_create) {
                this.setState({
                    loading_create: true
                }, () => {
                    calls.create({
                        client: this.state.item.id,
                        manager: this.state.manager,
                        customer: this.state.client,
                        token: this.state.token,
                        ocid: this.state.ocid
                    }).then((response) => {
                        this.state.item.google = response.data;
                        this.setState({
                            token: null,
                            customer: "",
                            manager: "",
                            item: this.state.item,
                            loading_create: false,
                            open: false
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading_create: false
                        })
                    });
                })
            }
        },
        managers: () => {
            this.setState({
                managers_loading: true
            }, () => {
                calls.getManagers({ token: this.state.token, client: this.state.item.id }).then((response) => {
                    this.setState({
                        managers: response.data,
                        managers_loading: false
                    })
                }, (error) => {
                    this.setState({
                        managers: [],
                        managers_loading: false
                    })
                });
            })
        },
        clients: () => {
            this.setState({
                clients_loading: true
            }, () => {
                calls.getClients({ token: this.state.token, manager_id: this.state.manager.id }).then((response) => {
                    this.setState({
                        clients: response.data,
                        clients_loading: false
                    })
                }, (error) => {
                    this.setState({
                        clients: [],
                        clients_loading: false
                    })
                });
            })
        }
    };

    renders = {
        submenus: () => {
            let array = [
                { id: 0, name: "Add adaccount" }
            ];
            if (this.state.token || this.state.item.google) {
                array.push({ id: 1, name: "Setup pixel" });
            }
            return array;
        }
    };

    render() {
        return (
            <div>
                <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                    <ul className="flex flex-row items-end">
                        {this.renders.submenus().map((item, j) => {
                            return (
                                <li key={j}
                                    className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                    onClick={() => {
                                        this.setState({
                                            submenu: item.id
                                        });
                                    }}>
                                    {item.name}
                                </li>
                            )
                        })
                        }
                        <li className="flex-grow border-b-2 border-gray-200"></li>
                    </ul>
                </nav>
                <SweetAlert
                    show={this.state.remove_modal}
                    title="Signout Google"
                    type="warning"
                    text={"You wont be able access any Google campaigns created with this account"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.logout();
                    }}
                    onCancel={() => {
                        this.setState({ remove_modal: false });
                    }}
                />
                {
                    this.state.submenu === 0 &&
                    <div className="bg-white rounded-md px-4 py-4">
                        {
                            !this.state.item.google && !this.state.token && false &&
                            <div className="Section">
                                <div onClick={() => this.functions.login()} className="Button ButtonFull ButtonGoogle">
                                    {
                                        !this.state.loading &&
                                        <div>login Google</div>
                                    }
                                    {
                                        this.state.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {
                            !this.state.item.google && !this.state.token &&
                            <div className="w-full justify-center align-middle items-center flex">
                                <img onClick={() => this.functions.login()} style={{ width: "200px" }} className="cursor-pointer" src={require("../..//assets/images/google_signin_button.png")} />
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.google &&
                            <DropdownTailwind
                                searchInput={true}
                                label={"Select manager"}
                                selected={this.state.manager}
                                options={this.state.managers}
                                placeholder={"Search managers"}
                                loader={this.state.managers_loading}
                                onChange={(value) => {
                                    this.setState({ manager: value, client: {} }, () => {
                                        this.functions.clients();
                                    })
                                }}
                            />
                        }
                        {
                            (this.state.item.google) &&
                            <div className="w-full" style={{ paddingTop: '0px' }}>
                                <InputTailwind
                                    label={"Manager account"}
                                    locked={true}
                                    disabled={true}
                                    value={this.state.item.google.managerName + ' - ' + this.state.item.google.managerId}
                                    onChange={(value) => {

                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.google && this.state.manager.name &&
                            <div className="mt-4">
                                <div className="w-full">
                                    <DropdownTailwind
                                        searchInput={true}
                                        label={"Select client"}
                                        selected={this.state.client}
                                        options={this.state.clients}
                                        placeholder={"Search clients"}
                                        loader={this.state.clients_loading}
                                        onChange={(value) => {
                                            this.setState({ client: value })
                                        }}
                                    />
                                </div>
                            </div>
                        }
                        {
                            (this.state.item.google) &&
                            <div className="w-full mt-4">
                                <InputTailwind
                                    label={"Client account"}
                                    locked={true}
                                    disabled={true}
                                    value={this.state.item.google.clientName + ' - ' + this.state.item.google.clientId}
                                    onChange={(value) => {

                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.google && this.state.manager.name &&
                            <div className="w-full mt-4 flex flex-row">
                                <div className="flex flex-1">
                                    <div className="w-full">
                                        <InputTailwind
                                            label={"Enable deep-linking to Google ads (OCID)"}
                                            locked={false}
                                            disabled={false}
                                            value={this.state.ocid}
                                            onChange={(value) => {
                                                this.setState({
                                                    ocid: value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="ml-4 pt-5.5">
                                    <button
                                        onClick={() => {
                                            window.open("https://ads.google.com/nav/selectaccount");
                                        }}
                                        className={"ml-4 h-12 inline-flex shadow relative rounded-md border border-transparent bg-purple-500 items-center justify-center px-12 text-sm font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                    >
                                        Open Adwords
                                    </button>
                                </div>
                            </div>
                        }
                        {
                            (this.state.item.google) &&
                            <div className="w-full mt-4">
                                <InputTailwind
                                    label={"Enable deep-linking to Google ads (OCID)"}
                                    locked={true}
                                    disabled={true}
                                    value={this.state.ocid}
                                    onChange={(value) => {

                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.google && this.state.manager.id && this.state.client.id &&
                            <div className="Section">
                                <div onClick={() => this.functions.create()} className="Button ButtonFull">
                                    {
                                        !this.state.loading_create &&
                                        <div>add Google user</div>
                                    }
                                    {
                                        this.state.loading_create &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        <div>
                            {
                                this.state.item.google &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        remove_modal: true
                                    })} className="Button ButtonFull ButtonGoogle">
                                        {
                                            !this.state.loading &&
                                            <div>signout Google</div>
                                        }
                                        {
                                            this.state.loading &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={'#ffffff'}
                                                loading={true}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.token && !this.state.item.google &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        token: null,
                                        manager: {
                                            input: "",
                                            checked: false,
                                            loading: false,
                                            data: {}
                                        },
                                        client: {
                                            input: "",
                                            checked: false,
                                            loading: false,
                                            data: {}
                                        }
                                    })} className="Button ButtonFull ButtonGoogle">
                                        signout Google
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    this.state.submenu === 1 &&
                    <ClientGooglePixel
                        client={this.state.item.id}
                        item={this.state.item.google}
                        onChange={(value) => {
                            this.state.item.google = value;
                            this.setState({
                                item: this.state.item
                            })
                        }}
                    />
                }
            </div>
        )
    }
}

export default ClientGoogle;
