import React, { Component, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Dialog, Menu, Transition } from '@headlessui/react'
import { MenuIcon, BellIcon, CalendarIcon, ChartPieIcon, CogIcon, DocumentDuplicateIcon, FolderIcon, HomeIcon, UsersIcon, XIcon, CubeIcon, UserIcon, ChatAltIcon, LogoutIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from "../services/userRegister";
import { clientRegister } from "../services/clientRegister";
import ClientReportList from './clientReportList';
import ClientOrderList from './clientOrderList';
import ClientDashboard from './clientDashboard';
import ClientProfile from './clientProfile';
import ClientOrderStepOne from './clientOrderStepOne';
import ClientOrderStepTwo from './clientOrderStepTwo';
import ClientOrderStepThree from './clientOrderStepThree';
import ClientOrderStepFour from './clientOrderStepFour';
import ClientOrderStepFive from './clientOrderStepFive';
import ClientOrderStepSummary from './clientOrderStepSummary';
import ClientOrderStepCheck from './clientOrderStepCheck';

import translations from "../assets/json/onboarding_translation.json";
import ClientSettings from './clientSettings';

const navigation = [
    { id: 1, name: 'Dashboard', link: '/client', icon: HomeIcon, current: true },
    { id: 2, name: 'Orders', link: '/client/orders', icon: CubeIcon, current: false },
    { id: 3, name: 'Reports??', link: '/client/reports', icon: ChartPieIcon, current: false },
    { id: 5, name: 'Profile', link: '/client/profile', icon: UserIcon, current: false },
]

class LoggedInClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            sidebarOpen: false,
            loading: false,
            reports: [],
            orders: [],
            whitelabel_settings: {},
            selected_nav: { id: 1, name: 'Dashboard', link: '/client', icon: HomeIcon, current: true },
        }
    };

    componentWillMount() {
        this.init.user();
    }

    componentDidMount() {
    }

    componentWillUpdate(nextProps, nextState, nextContext) {

    }

    init = {
        user: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get(),
                    whitelabel_settings: { ...userRegister.get().whitelabel }
                })
            }
        },
        client: () => {
            this.setState({
                client: clientRegister.get()
            });
        }
    };

    functions = {
        setSidebarOpen: async () => {
            this.setState({ sidebarOpen: !this.state.sidebarOpen })
        },
        handleNewOrderButton: async () => {
            this.functions.setSidebarOpen()
            this.props.history.push("/client/order/step-one")
        },
        handleLogout: async () => {
            await this.calls.logout()
            this.props.history.push("/test-onboarding")
        }
    };

    renders = {};

    calls = {
        getReports: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/client/listReports?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction;
            return apiRegister.call(options, url);
        },
        getOrders: async () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/client/listOrders";
            return apiRegister.call(options, url);
        },
        logout: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/client/logout";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    translate = (text) => {
        let lang;
        let key = "menu"

        if (this.state.user && this.state.user.language) {
            lang = this.state.user.language.value
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            lang = this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage ? this.state.whitelabel_settings.defaultLanguage.value : { id: 1, name: "English", value: "en" }
        }

        if (!this.state.whitelabel_settings.defaultLanguage) {
            return translations.en[key][text]
            // IF THERE IS A USER SELECTED LANGUAGE 
        } else if (this.state.user && this.state.user.language) {
            return translations[lang][key][text]
            // IF THERE NO USER SELECTED LANGUAGE, BUT THERE IS DEFAULT LANGUAGE THEN USE DEFAULT LANGUAGE
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            return translations[lang][key][text]
        }

    }

    render() {
        let t = this.translate
        return (
            <>

                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"
                            style={this.state.whitelabel_settings.color_accent !== "" && { border: "2px solid #" + this.state.whitelabel_settings.color_accent, borderTopColor: "transparent" }}></div>
                        <div className="font-semibold mt-3">{t("loading")} ...</div>
                    </div>
                }

                {!this.state.loading &&
                    <>
                        <div>
                            {/* MOBILE SIDE MENU*/}
                            <Transition.Root show={this.state.sidebarOpen} as={Fragment}>
                                <Dialog as="div" className="relative z-50 lg:hidden" onClose={() => { this.functions.setSidebarOpen() }}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="transition-opacity ease-linear duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="transition-opacity ease-linear duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0 bg-gray-700 opacity-75" />
                                    </Transition.Child>

                                    <div className="fixed inset-0 flex">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="transition ease-in-out duration-300 transform"
                                            enterFrom="-translate-x-full"
                                            enterTo="translate-x-0"
                                            leave="transition ease-in-out duration-300 transform"
                                            leaveFrom="translate-x-0"
                                            leaveTo="-translate-x-full"
                                        >
                                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="ease-in-out duration-300"
                                                    enterFrom="opacity-0"
                                                    enterTo="opacity-100"
                                                    leave="ease-in-out duration-300"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <div className="absolute top-0 flex w-16 justify-center pt-5"
                                                        style={{ left: "100%" }}
                                                    >
                                                        <button type="button" className="-m-2.5 p-2.5" onClick={() => this.functions.setSidebarOpen()}>
                                                            <span className="sr-only">Close sidebar</span>
                                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </Transition.Child>
                                                <div className="flex flex-grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                                    {
                                                        this.state.whitelabel_settings && this.state.whitelabel_settings.logo_secondary && this.state.whitelabel_settings.logo_secondary !== "" &&
                                                        <div className="flex h-16 shrink-0 items-center">
                                                            <img
                                                                className="h-12 w-auto"
                                                                src={this.state.whitelabel_settings.logo_secondary ? this.state.whitelabel_settings.logo_secondary : null}
                                                                alt="Your Company"
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        this.state.whitelabel_settings && !this.state.whitelabel_settings.logo_secondary &&
                                                        <div className="flex h-16 shrink-0 items-center">
                                                            <div className='flex flex-col justify-center items-center text-sm font-bold h-12 w-12 rounded-full border-gray-300 bg-gray-200 mr-2 text-black'>
                                                                {this.state.user && this.state.user.name ? this.state.user.name.charAt(0).toUpperCase() : "A B"}
                                                            </div>
                                                        </div>
                                                    }
                                                    <nav className="flex flex-1 flex-col">
                                                        <ul role="list" className="flex flex-1 flex-col gap-y-7 justify-between">
                                                            <div>
                                                                <li>
                                                                    <ul role="list" className="-mx-2 space-y-1">
                                                                        {navigation.map((item, index) => (
                                                                            <li key={item.name}
                                                                                onClick={async (e) => {
                                                                                    if (item.id !== this.state.selected_nav.id) {
                                                                                        await this.promisedSetState({ selected_nav: item });
                                                                                    }
                                                                                    // AUTO CLOSE SIDE MENU BUT CAUSES SOME FLASHING
                                                                                    this.functions.setSidebarOpen()
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    onClick={() => this.props.history.push(item.link)}
                                                                                    href={item.link}
                                                                                    className={this.classNames(
                                                                                        item.link === window.location.pathname
                                                                                            ? 'bg-gray-50 cursor-pointer'
                                                                                            : 'text-gray-400 hover:text-gray-700 hover:bg-gray-50',
                                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer'
                                                                                    )}
                                                                                >
                                                                                    <item.icon
                                                                                        className={this.classNames(
                                                                                            item.link === window.location.pathname ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                                                            'h-6 w-6 shrink-0'
                                                                                        )}
                                                                                        aria-hidden="true"
                                                                                        style={{ color: this.state.whitelabel_settings.color_accent !== "" && item.link === window.location.pathname ? "#" + this.state.whitelabel_settings.color_accent : null }}
                                                                                    />
                                                                                    {t("menu_" + (index + 1))}
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </li>

                                                                {/* CREATE ORDER BUTTON */}
                                                                <li>
                                                                    <div
                                                                        onClick={() => (this.functions.handleNewOrderButton())}
                                                                        className='px-3 rounded-md transition-opacity h-10 flex items-center overflow-hidden transform whitespace-no-wrap text-sm font-medium shadow justify-center cursor-pointer text-center relative  text-white bg-purple-500 mt-4'
                                                                        style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                                                    >
                                                                        <span
                                                                            style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}>
                                                                            {t("order_button")}
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            </div>


                                                            <li>
                                                                <ul role="list" className="-mx-2 space-y-1">
                                                                    <li>
                                                                        <div
                                                                            onClick={() => this.functions.handleLogout()}
                                                                            className="text-gray-400 hover:text-gray-700 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer"
                                                                        >
                                                                            <LogoutIcon
                                                                                className="text-gray-400 h-6 w-6"
                                                                            // style={{ color: this.state.whitelabel_settings.color_accent !== "" ? "#" + this.state.whitelabel_settings.color_accent : null }}
                                                                            />
                                                                            {t("logout")}
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </li>

                                                        </ul>
                                                    </nav>
                                                </div>
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </Dialog>
                            </Transition.Root>

                            {/* DESKTOP SIDE MENU*/}

                            <div className={`hidden lg:fixed lg:inset-y-0 lg:flex lg:z-50 lg:w-72 lg:flex-col ${window.location.pathname.includes("/order/") ? "lg:hidden" : "lg:flex"}`}>
                                <div className={`flex grow h-full flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4`}>
                                    {
                                        this.state.whitelabel_settings && this.state.whitelabel_settings.logo_primary && this.state.whitelabel_settings.logo_primary !== "" &&
                                        <div className="flex h-16 shrink-0 items-center">
                                            <img
                                                className={`w-auto ${this.state.whitelabel_settings.logo_size ? this.state.whitelabel_settings.logo_size.value : "h-8"}`}
                                                src={this.state.whitelabel_settings.logo_primary ? this.state.whitelabel_settings.logo_primary : null}
                                                alt="Your Company"
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.whitelabel_settings && !this.state.whitelabel_settings.logo_primary &&
                                        <div className="flex h-16 shrink-0 items-center">
                                            <div className='flex flex-col justify-center items-center text-sm font-bold h-10 w-10 rounded-full border-gray-300 bg-gray-200 mr-2 text-black'>
                                                {this.state.user && this.state.user.name ? this.state.user.name.charAt(0).toUpperCase() : "A B"}
                                            </div>
                                        </div>
                                    }
                                    <nav className="flex flex-1 flex-col">
                                        <ul role="list" className="flex flex-1 flex-col gap-y-7 justify-between">
                                            <div>
                                                <li>
                                                    <ul role="list" className="-mx-2 space-y-1">
                                                        {navigation.map((item, index) => (
                                                            <li key={item.name}
                                                                onClick={async (e) => {
                                                                    if (item.id !== this.state.selected_nav.id) {
                                                                        await this.promisedSetState({ selected_nav: item });
                                                                    }
                                                                }}
                                                            >
                                                                <div
                                                                    onClick={() => this.props.history.push(item.link)}
                                                                    href={item.link}
                                                                    className={this.classNames(
                                                                        item.link === window.location.pathname
                                                                            ? 'bg-gray-50 cursor-pointer'
                                                                            : 'text-gray-400 hover:text-gray-700 hover:bg-gray-50',
                                                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer'
                                                                    )}
                                                                >
                                                                    <item.icon
                                                                        className={this.classNames(
                                                                            item.link === window.location.pathname ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                                            'h-6 w-6 shrink-0'
                                                                        )}
                                                                        aria-hidden="true"
                                                                        style={{ color: this.state.whitelabel_settings.color_accent !== "" && item.link === window.location.pathname ? "#" + this.state.whitelabel_settings.color_accent : null }}
                                                                    />
                                                                    {t("menu_" + (index + 1))}
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>

                                                {/* CREATE ORDER BUTTON */}
                                                <li>
                                                    <div
                                                        onClick={() => this.functions.handleNewOrderButton()}
                                                        style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                                        className='px-3 rounded-md transition-opacity h-10 flex items-center overflow-hidden transform whitespace-no-wrap text-sm font-medium shadow justify-center cursor-pointer text-center relative bg-purple-500 text-white mt-4'
                                                    >
                                                        <span
                                                            style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}>
                                                            {t("order_button")}
                                                        </span>
                                                    </div>
                                                </li>
                                            </div>


                                            <li>
                                                <ul role="list" className="-mx-2 space-y-1">
                                                    <li>
                                                        <div
                                                            onClick={() => this.functions.handleLogout()}
                                                            className="text-gray-400 hover:text-gray-700 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer"
                                                        >
                                                            <LogoutIcon
                                                                className="text-gray-400 h-6 w-6"
                                                            // style={{ color: this.state.whitelabel_settings.color_accent !== "" ? "#" + this.state.whitelabel_settings.color_accent : null }}
                                                            />
                                                            {t("logout")}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>

                                        </ul>
                                    </nav>
                                </div>
                            </div>


                            <div className={`relative h-screen ${window.location.pathname.includes("/order/") ? "lg:pl-0" : "lg:pl-72"}`}>
                                {/* <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"> */}
                                {
                                    !window.location.pathname.includes("/order/") &&
                                    <button type="button"
                                        className="absolute ml-4 mt-5 text-gray-700 lg:hidden"
                                        onClick={() => this.functions.setSidebarOpen()}>
                                        <span className="sr-only">Open sidebar</span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                }

                                {/* </div> */}

                                <main className="flex flex-col h-full">
                                    {/* CONTENT GOES HERE */}
                                    <Switch>
                                        <Route exact path="/client" component={ClientDashboard} />
                                        {/* USE THIS TO SEND PROPS VIA REACT ROUTER  */}
                                        {/* <Route exact path="/client" render={(props) => <ClientDashboard {...props} user={this.state.user} />} /> */}
                                        <Route exact path="/client/reports" component={ClientReportList} />
                                        <Route exact path="/client/orders" component={ClientOrderList} />
                                        <Route exact path="/client/profile" component={ClientProfile} />
                                        <Route exact path="/client/settings" component={ClientSettings} />
                                        <Route exact path="/client/order/step-one" component={ClientOrderStepOne} />
                                        <Route exact path="/client/order/step-two" component={ClientOrderStepTwo} />
                                        <Route exact path="/client/order/step-three" component={ClientOrderStepThree} />
                                        <Route exact path="/client/order/step-four" component={ClientOrderStepFour} />
                                        <Route exact path="/client/order/step-five" component={ClientOrderStepFive} />
                                        <Route exact path="/client/order/step-summary" component={ClientOrderStepSummary} />
                                        <Route exact path="/client/order/:id" component={ClientOrderStepCheck} />
                                    </Switch>
                                </main>
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }

}


export default LoggedInClient;
