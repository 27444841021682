import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import {dataRegister} from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BeatLoader} from "react-spinners";

class FacebookImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            image: this.props.image,
            size: this.props.size,
            page: this.props.page,
            src: null,
            crop: {
                unit: '%',
                width: 100,
                aspect: 1.91/1,
            },
            error: this.props.error,
            errorMessage: ""
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            image: this.state.image !== nextProps.image ? nextProps.image : this.state.image,
            page: this.state.page !== nextProps.page ? nextProps.page : this.state.page,
            size: this.state.size !== nextProps.size ? nextProps.size : this.state.size,
            error: this.state.error !== nextProps.error ? nextProps.error : this.state.error
        }, () => {
            this.state.crop.aspect = this.state.size;
            this.setState({
                crop: this.state.crop
            })
        });
    }

    componentDidMount() {
        this.setState({
            image: this.props.image,
            page: this.props.page,
            size: this.props.size,
            error: this.props.error
        }, () => {
            this.state.crop.aspect = this.state.size;
            this.setState({
                crop: this.state.crop
            })
        })
    }

    functions = {
        uploadImage: () => {
            this.setState({
                src: null,
                error: false,
                errorMessage: ""
            }, () => {
                const formData = new FormData();
                formData.append('file', this.state.image);
                this.setState({loading: true}, () => {
                    dataRegister.uploadImage(formData).then((response) => {
                        this.setState({
                            loading: false
                        }, () => {
                            this.props.onChange(response.url);
                        });
                    }, (error) => {
                        this.setState({
                            image: null,
                            loading: false,
                            error: true,
                            errorMessage: error.body.message
                        }, () => {
                            //this.props.onChange(null);
                        });
                    });
                });
            });
        },
        onSelectFile: (e) => {
            if (e.target.files && e.target.files.length > 0) {
                const reader = new FileReader();
                reader.addEventListener('load', () =>
                    this.setState({ src: reader.result })
                );
                reader.readAsDataURL(e.target.files[0]);
            }
        }
    };

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'image.png'
            );
            this.setState({image: croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/png', 1);
        });
    }

    init = {};

    renders = {};

    render() {
        return (
            <div className={cx("FacebookImage-PreviewContainer")}>
                {
                    false &&
                    <div className={cx("FacebookImage-PreviewContainerTitle")}>
                        Preview
                    </div>
                }
                <div className={
                    cx("FacebookImage-PreviewBasicContainerItem",
                        {
                            ["FacebookImage-PreviewBasicContainerItemError"]: this.state.error,
                        })
                }>
                    <div className={cx("FacebookImage-PreviewBasicContainerItemInner")}>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerHeader">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderPage">
                                <div style={{backgroundImage: 'url('+this.state.page.image+')'}} className="FacebookImage-PreviewBasicContainerItemInnerHeaderPageIcon"></div>
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderText">
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextTop">
                                    {this.state.page.name}
                                </div>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottom">
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomSponsored">
                                        Sponsored
                                    </div>
                                    <div className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomDot"/>
                                    <img className="FacebookImage-PreviewBasicContainerItemInnerHeaderTextBottomGlobe" src={require('../../assets/images/globe.png')}/>
                                </div>
                            </div>
                        </div>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerPrimaryText">
                            {this.props.body}
                        </div>
                        <div style={{backgroundImage: 'url('+this.state.image+')'}} className={
                            cx("FacebookImage-PreviewBasicContainerItemInnerImage",
                                {
                                    ["FacebookImage-PreviewBasicContainerItemInnerImageSquare"]: this.state.size === 1/1,
                                })
                        }>
                            <div className={
                                cx("FacebookImage-PreviewBasicContainerItemInnerImageOverlay",
                                    {
                                        ["FacebookImage-PreviewBasicContainerItemInnerImageOverlayNoImage"]: !this.state.image || this.state.loading,
                                    })
                            }>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerImageOverlayButton">
                                    {
                                        !this.state.loading &&
                                        <div>Upload image</div>
                                    }
                                    {
                                        this.state.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={10}
                                            color={'#388f8a'}
                                            loading={true}
                                        />
                                    }
                                    {
                                        !this.state.loading &&
                                        <input ref="uploadImageFile" onClick={() => this.refs.uploadImageFile.value = null}
                                               onChange={(e) => this.functions.onSelectFile(e)} type="file"
                                               accept={"image/png, image/jpg, image/jpeg"}
                                               className="FacebookImage-PreviewBasicContainerItemInnerImageOverlayButtonUpload"/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerBottom">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeft">
                                <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftUrl">
                                    {"DISPLAYURL.COM"}
                                </div>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftHeadline">
                                    {this.props.title}
                                </div>
                                <div className="FacebookImage-PreviewBasicContainerItemInnerBottomLeftDescription">
                                    {this.props.description}
                                </div>
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerBottomRight">
                                <div className="FacebookImage-PreviewBasicContainerItemInnerBottomRightButton">
                                    Learn more
                                </div>
                            </div>
                        </div>
                        <div className="FacebookImage-PreviewBasicContainerItemInnerShare">
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemLike" src={require('../../assets/images/like.png')}/>
                                Like
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemComment" src={require('../../assets/images/comment.png')}/>
                                Comment
                            </div>
                            <div className="FacebookImage-PreviewBasicContainerItemInnerShareItem">
                                <img className="FacebookImage-PreviewBasicContainerItemInnerShareItemShare" src={require('../../assets/images/share.png')}/>
                                Share
                            </div>
                        </div>
                    </div>
                    {
                        this.state.error &&
                        <div className="Section SectionError">
                            {this.state.errorMessage}
                        </div>
                    }
                </div>
                {
                    this.state.src &&
                    <div className={cx("FacebookImage-PreviewContainerCropper")}>
                        <ReactCrop
                            style={{maxWidth: '50%'}}
                            src={this.state.src}
                            crop={this.state.crop}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                        <div className={cx("FacebookImage-PreviewContainerCropperButtons")}>
                            <div onClick={() => {
                                this.setState({
                                    src: null
                                });
                            }} className="FacebookImage-PreviewContainerCropperButton FacebookImage-PreviewContainerCropperButtonRed">
                                Cancel
                            </div>
                            <div onClick={() => {
                                this.functions.uploadImage()
                            }} className={cx("FacebookImage-PreviewContainerCropperButton")}>
                                Done
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default FacebookImage;
