import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import { dataRegister } from "../../services/dataRegister";
import { BeatLoader } from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingCreativesFacebook from "../advertising-creatives-facebook";
import TextArea from "../text-area";
import UploadImage from "../image-upload";
import AdvertisingCreativesUpload from "../advertising-creatives-upload";
import AdvertisingCreativesVariations from "../advertising-creatives-variations";
import AdvertisingCreativesInstagram from "../advertising-creatives-instagram";
import AdvertisingCreativesGoogle from "../advertising-creatives-google";
import IconInstagram from "../icons/instagram";
import IconFacebook from "../icons/facebook";
import IconGoogle from "../icons/google";
import AdvertisingCreativesVariationsSearch from "../advertising-creatives-variations-search";
import Carousel from "re-carousel";
import { toArray } from "react-emoji-render";
import AdvertisingCreativesFacebookImage from "../advertising-creatives-facebook-image";
import callToAction from '../../assets/json/call_to_action';
import callToActionLinkedin from '../../assets/json/call_to_action_linkedin';
import AdvertisingCreativesInstagramImage from "../advertising-creatives-instagram-image";
import AdvertisingCreativesFacebookVideo from "../advertising-creatives-facebook-video";
import AdvertisingCreativesInstagramVideo from "../advertising-creatives-instagram-video";
import { SlideDown } from "react-slidedown";
import SweetAlert from "sweetalert-react";
import AdvertisingCreativesFacebookImageMobile from "../advertising-creatives-facebook-image-mobile";
import AdvertisingCreativesAdsItemCreative from "../advertising-creatives-ads-item-creative";
import ErrorAlert from "../../modules/error-alert";

class AdvertisingCreativesAdsItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            edit: this.props.edit,
            item: this.props.item,
            index: this.props.index,
            channels: this.props.channels,
            variations: this.props.variations,
            content: this.props.content,
            client: this.props.client,
            site: this.props.site,
            error: this.props.error,
            creatives: {},
            channel: null,
            call_to_actions: callToAction,
            call_to_actions_linledin: callToActionLinkedin,
            copy_from: {},
            languages: [],
            dynamic: false,
            catalogs: [],
            catalog: {},
            adset: {},
            videos: [],
            images: [],
            carousels: [],
            errors: [],
            selected: null,
            campaign: {}
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            content: nextProps.content,
            campaign: nextProps.campaign,
            edit: nextProps.edit,
            item: nextProps.item,
            index: nextProps.index,
            open: nextProps.open,
            adset: nextProps.adset,
            client: nextProps.client,
            site: nextProps.site,
            variations: nextProps.variations,
            channels: nextProps.channels,
            dynamic: nextProps.dynamic,
            catalogs: nextProps.catalogs,
            creatives: nextProps.creatives,
            images: nextProps.images ? nextProps.images : [],
            videos: nextProps.videos ? nextProps.videos : [],
            carousels: nextProps.carousels ? nextProps.carousels : [],
            languages: nextProps.languages ? nextProps.languages : [],
            error: nextProps.error,
            errors: nextProps.errors ? nextProps.errors : []
        });
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            content: this.props.content,
            campaign: this.props.campaign,
            channels: this.props.channels,
            item: this.props.item,
            index: this.props.index,
            adset: this.props.adset,
            client: this.props.client,
            site: this.props.site,
            dynamic: this.props.dynamic,
            catalogs: this.props.catalogs,
            creatives: this.props.creatives,
            images: this.props.images ? this.props.images : [],
            videos: this.props.videos ? this.props.videos : [],
            carousels: this.props.carousels ? this.props.carousels : [],
            languages: this.props.languages ? this.props.languages : [],
            error: this.props.error,
            errors: this.props.errors ? this.props.errors : []
        });
    }

    init = {};

    functions = {
        remove: (id) => {
            let content = this.state.content.filter((item) => {
                return item.id !== id
            });
            console.log(content);
            /*
            if(this.state.item.video && content.length < 1 && this.state.images.length < 1 && this.state.carousels.length < 1){
                this.state.creatives.adsets = this.state.creatives.adsets.filter((item) => {
                    return item.internal_adset && item.internal_adset.id !== this.state.adset.id;
                });
                try{
                    this.props.updateCreatives(this.state.creatives);
                }catch (e) {}
            }
            if(this.state.item.image && content.length < 1 && this.state.videos.length < 1 && this.state.carousels.length < 1){
                this.state.creatives.adsets = this.state.creatives.adsets.filter((item) => {
                    return item.internal_adset && item.internal_adset.id !== this.state.adset.id;
                });
                try{
                    this.props.updateCreatives(this.state.creatives);
                }catch (e) {}
            }
            if(this.state.item.carousel && content.length < 1 && this.state.videos.length < 1 && this.state.images.length < 1){
                this.state.creatives.adsets = this.state.creatives.adsets.filter((item) => {
                    return item.internal_adset && item.internal_adset.id !== this.state.adset.id;
                });
                try{
                    this.props.updateCreatives(this.state.creatives);
                }catch (e) {}
            }
            if(this.state.item.dynamic && content.length < 1){
                this.state.creatives.adsets = this.state.creatives.adsets.filter((item) => {
                    return item.internal_adset && item.internal_adset.id !== this.state.adset.id;
                });
                try{
                    this.props.updateCreatives(this.state.creatives);
                }catch (e) {}
            }
            */
            this.setState({
                warning_modal: false,
                selected: null
            }, () => {
                this.props.onChange(content);
            })
        }
    };

    validators = {};

    renders = {
        type: (item) => {
            try {
                if (item.video) {
                    return 'Video Ad'
                } else if (item.image) {
                    return 'Image Ad'
                } else if (item.carousel) {
                    return 'Carousel Ad'
                } else if (item.dynamic) {
                    return 'Dynamic Ad'
                }
            } catch (e) {
                return ""
            }
        },
        ratio: (item) => {
            try {
                if (item.carousel || item.dynamic) {
                    return '1:1'
                } else {
                    return item.creative.ratio
                }
            } catch (e) {
                return ""
            }
        },
        emoji: (value) => {
            try {
                const emojisArray = toArray(value);
                const newValue = emojisArray.reduce((previous, current) => {
                    if (typeof current === "string") {
                        return previous + current;
                    }
                    return previous + current.props.children;
                }, "");
                return newValue;
            } catch (e) {
                return value
            }
        },
        tabs: () => {
            let keys = [];
            for (let key in this.state.channels) {
                if (this.state.channels[key] && (key === 'instagram' || key === 'facebook')) {
                    keys.push(key);
                }
            }
            return keys;
        },
        page: (channel) => {
            try {
                let page = {
                    name: this.state.client[channel].facebookPageName,
                    image: this.state.client[channel].facebookPageImage
                };
                return page
            } catch (e) {
                return {}
            }
        },
        string: (item) => {
            try {
                return item.length
            } catch (e) {
                return 0
            }
        },
        item: (item) => {
            try {
                let array = [];
                array.push(item);
                return array;
            } catch {
                return []
            }
        },
        groups: () => {
            let standard = [
                { name: 'Group 1', value: '1', type: 'normal' },
                { name: 'Group 2', value: '2', type: 'normal' },
                { name: 'Group 3', value: '3', type: 'normal' },
                { name: 'Group 4', value: '4', type: 'normal' },
                { name: 'Group 5', value: '5', type: 'normal' },
                { name: 'Group 6', value: '6', type: 'normal' },
                { name: 'Group 7', value: '7', type: 'normal' },
                { name: 'Group 8', value: '8', type: 'normal' },
                { name: 'Group 9', value: '9', type: 'normal' },
                { name: 'Group 10', value: '10', type: 'normal' }
            ];
            //console.log(this.state.images);
            for (let i = 0; i < this.state.videos.length; i++) {
                if (this.state.videos[i].internal_adset.custom && !this.state.videos[i].internal_adset.custom_extra) {
                    let extra = JSON.parse(JSON.stringify(this.state.videos[i].internal_adset));
                    extra.custom_extra = true;
                    extra.name = extra.value;
                    extra.bold = false;
                    standard.unshift(extra);
                }
            }
            for (let i = 0; i < this.state.images.length; i++) {
                if (this.state.images[i].internal_adset.custom && !this.state.images[i].internal_adset.custom_extra) {
                    let extra = JSON.parse(JSON.stringify(this.state.images[i].internal_adset));
                    extra.custom_extra = true;
                    extra.name = extra.value;
                    extra.bold = false;
                    standard.unshift(extra);
                }
            }
            for (let i = 0; i < this.state.carousels.length; i++) {
                if (this.state.carousels[i].internal_adset.custom && !this.state.carousels[i].internal_adset.custom_extra) {
                    let extra = JSON.parse(JSON.stringify(this.state.carousels[i].internal_adset));
                    extra.custom_extra = true;
                    extra.name = extra.value;
                    extra.bold = false;
                    standard.unshift(extra);
                }
            }

            standard.unshift({ name: 'Custom group', value: '', type: 'custom', custom: true, bold: true });

            return standard;
        }
    };

    render() {
        let frames = [];
        if (this.state.item.carousel || this.state.item.dynamic) {
            frames = this.state.item.items.filter((item) => {
                return (item.creative.ratio === '1:1' || item.loading)
            }).map((item, index) => {
                if (item.image) {
                    return (
                        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                            <img key={index} src={item.creative.url} style={{ width: '100%', height: '100%' }} />
                            {
                                item.loading &&
                                <div
                                    className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-loading">
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={5}
                                        color={'#060534'}
                                        loading={true}
                                    />
                                </div>
                            }
                        </div>
                    )
                } else if (item.video) {
                    return (
                        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                            <video key={index} controls={false} muted={true} autoPlay={true} loop={true}
                                style={{ width: '100%', height: '100%' }}>
                                <source src={item.creative.url} type="video/mp4" />
                            </video>
                            {
                                item.loading &&
                                <div
                                    className="Creative-ads-item-bottom-inner-right-item-inner-item-top-preview-loading">
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={5}
                                        color={'#060534'}
                                        loading={true}
                                    />
                                </div>
                            }
                        </div>
                    )
                }
            });
        }
        return (
            <div className="flex flex-1 flex-col">
                <div className={(!this.props.last ? "border-b" : "") + " relative creative-ads__block bg-white grey-inputs"}>
                    {
                        this.state.item.loading &&
                        <div style={{ right: "60px" }} className="left-0 top-0 rounded bottom-0 absolute bg-white flex items-center justify-center">
                            <BeatLoader
                                sizeUnit={"px"}
                                size={10}
                                color={'#060534'}
                                loading={true}
                            />
                        </div>
                    }
                    {
                        <div className="creative-ads__block--inner p-4">
                            <div className="creative-ads__block--inner--body flex flex-row align-middle items-center">
                                <div className="creative-ads__block--inner--body--creatives mr-4 rounded-md">
                                    {
                                        this.state.item.image &&
                                        <div className="bg-cover bg-center rounded-md" style={{
                                            width: "40px",
                                            height: "40px",
                                            backgroundImage: `url(${this.state.item.creative.url})`
                                        }} />
                                    }
                                    {
                                        /*
                                        this.state.item.post &&
                                        <div className="bg-cover bg-center rounded-md" style={{
                                            width: "40px",
                                            height: "40px",
                                            backgroundImage: `url(${this.state.item.creative.url})`
                                        }} />
                                        */
                                    }
                                    {
                                        this.state.item.event && this.state.item.facebook_event && this.state.item.facebook_event.cover && this.state.item.facebook_event.cover.source &&
                                        <div className="bg-cover bg-center rounded-md" style={{
                                            width: "40px",
                                            height: "40px",
                                            backgroundImage: `url(${this.state.item.facebook_event.cover.source})`
                                        }} />
                                    }
                                    {
                                        (this.state.item.video || this.state.item.carousel || this.state.item.dynamic) &&
                                        <div style={{ width: "40px", height: "40px" }} className="relative rounded-md overflow-hidden">
                                            {
                                                this.state.item.video &&
                                                <video controls={false} loop={true} autoPlay={true} muted={true} style={{ width: '100%', height: '100%' }}>
                                                    <source src={this.state.item.creative.url} type="video/mp4" />
                                                </video>
                                            }
                                            {
                                                (this.state.item.carousel || this.state.item.dynamic) &&
                                                <Carousel interval={5000} loop auto frames={frames} />
                                            }
                                        </div>
                                    }
                                </div>
                                {
                                    <div className="flex flex-row">
                                        <div style={{ width: "250px" }} className="creative-ads__block--inner--body--info text-xs px-4">
                                            <div className="truncate whitespace-no-wrap"><span className="font-bold text-xs">Name:</span>{" "}{!this.state.item.error ? this.state.item.title : this.state.item.error_message}
                                            </div>
                                            {
                                                !this.state.item.event && !this.state.item.post &&
                                                <div><span
                                                    className="font-bold text-xs">{this.state.item.video ? 'Video' : (this.state.item.carousel || this.state.item.dynamic ? 'Carousel' : 'Image')} ratio:</span> {this.renders.ratio(this.state.item)}
                                                </div>
                                            }
                                        </div>
                                        <div className="ml-4 mr-4">
                                            <div className="border-r h-full" />
                                        </div>
                                        <div style={{ width: "250px" }} className="creative-ads__block--inner--body--info text-xs px-4">
                                            {
                                                (this.state.item.image || this.state.item.video) &&
                                                <div className="truncate whitespace-no-wrap"><span className="font-bold text-xs">Headline:</span>{" "}{this.state.item.headline}
                                                </div>
                                            }
                                            {
                                                (this.state.item.carousel || this.state.item.dynamic) &&
                                                <div><span
                                                    className="font-bold text-xs truncate">Website:</span>{" "}{this.state.item.website}
                                                </div>
                                            }
                                            {
                                                <div className="truncate whitespace-no-wrap"><span className="font-bold text-xs truncate">Body:</span>{" "}{this.state.item.body}</div>
                                            }
                                        </div>
                                        <div className="ml-4 mr-4">
                                            <div className="border-r h-full" />
                                        </div>
                                        <div style={{ width: "250px" }} className="creative-ads__block--inner--body--info text-xs px-4">
                                            {
                                                (this.state.item.image || this.state.item.video) &&
                                                <div className="truncate whitespace-no-wrap"><span
                                                    className="font-bold text-xs">Website:</span>{" "}{this.state.item.website}
                                                </div>
                                            }
                                            {
                                                (this.state.item.image || this.state.item.video) &&
                                                <div className="truncate whitespace-no-wrap"><span
                                                    className="font-bold text-xs">Call to Action:</span>{" "}{typeof this.state.item.call_to_action === "object" ? this.state.item.call_to_action.name : "None"}
                                                </div>
                                            }
                                            {
                                                this.state.item.carousel && Array.isArray(this.state.item.items) &&
                                                <div><span
                                                    className="font-bold text-xs">Slides:</span>{" "}{this.state.item.items.length}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.errors.filter((item) => {
                                        return item.ad === this.state.item.id
                                    }).length > 0 && false &&
                                    <div className="flex items-center bg-red-100 rounded-md h-10 px-8 pr-6">
                                        <ul className="list-disc">
                                            <li className="text-xs text-red-500 font-bold" key={1}>
                                                {this.state.errors.filter((item) => {
                                                    return item.ad === this.state.item.id
                                                }).length} errors
                                            </li>
                                        </ul>
                                    </div>
                                }
                                {
                                    <div className="edit-button ml-auto self-end flex flex-row">
                                        {
                                            <button className="bg-red-200 text-red-500 mr-2 py-3 px-3 rounded"
                                                onClick={() => {
                                                    this.functions.remove(this.state.item.id);
                                                }}>
                                                <FeatherIcon className="stroke-current" size={16} icon={'trash'} />
                                            </button>
                                        }
                                        {
                                            !this.state.item.error && !this.state.item.loading &&
                                            <button
                                                className="bg-gray-200 text-gray-900 h-10 w-10 flex justify-center items-center rounded"
                                                onClick={() => {
                                                    if (this.state.item.show && !this.state.item.error) {
                                                        this.state.item.edit = !this.state.item.edit;
                                                        this.state.item.selected_channel = Object.keys(this.state.channels)[0];
                                                        this.state.item.selected_language = null;
                                                        this.setState({
                                                            content: this.state.content
                                                        }, () => {
                                                            this.props.onChange(this.state.content);
                                                        });
                                                    }
                                                }}>
                                                {
                                                    !this.state.item.edit &&
                                                    <FeatherIcon className="stroke-current" size={16}
                                                        icon={"edit"} />
                                                }
                                                {
                                                    this.state.item.edit &&
                                                    <FeatherIcon className="stroke-current" size={16}
                                                        icon={"chevron-up"} />
                                                }
                                            </button>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {
                        !this.state.item.loading &&
                        this.state.errors.filter((item) => {
                            return item.ad === this.state.item.id
                        }).length > 0 &&
                        <div className="px-4 pb-4">
                            <ErrorAlert
                                noMargin={true}
                                items={this.state.errors.filter((item) => {
                                    return item.ad === this.state.item.id
                                })}
                            />
                        </div>
                    }
                    <SlideDown closed={!this.state.item.edit}>
                        {
                            this.state.item.edit &&
                            <AdvertisingCreativesAdsItemCreative
                                adset={this.state.adset}
                                item={this.state.item}
                                campaign={this.state.campaign}
                                creatives={this.state.creatives}
                                channels={this.state.channels}
                                content={this.state.content}
                                errors={this.state.errors.filter((item) => {
                                    return item.ad === this.state.item.id
                                })}
                                catalogs={this.state.catalogs}
                                dynamic={this.state.dynamic}
                                languages={this.state.languages}
                                client={this.state.client}
                                site={this.state.site}
                                updateTitle={(value) => {
                                    try {
                                        this.props.updateTitle(value);
                                    } catch (e) {
                                    }
                                }}
                                updateBody={(value) => {
                                    try {
                                        this.props.updateBody(value);
                                    } catch (e) {
                                    }
                                }}
                                updateDescription={(value) => {
                                    try {
                                        this.props.updateDescription(value);
                                    } catch (e) {
                                    }
                                }}
                                onChange={(value) => {
                                    console.log(value);
                                    this.state.content = value;
                                    this.setState({
                                        content: this.state.content
                                    }, () => {
                                        this.props.onChange(this.state.content);
                                    })
                                }}
                                onRemove={(value) => {
                                    this.functions.remove(value);
                                }}
                            />
                        }
                    </SlideDown>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesAdsItem;
