import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getToken(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/googleanalytics/code";
        return apiRegister.call(options, url);
    }

    static getAccounts(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/googleanalytics/accounts";
        return apiRegister.call(options, url);
    }

    static getProperties(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/googleanalytics/properties";
        return apiRegister.call(options, url);
    }

    static getProfiles(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/googleanalytics/profiles";
        return apiRegister.call(options, url);
    }

    static create(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/client/googleanalytics";
        return apiRegister.call(options, url);
    }

    static remove(client) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/client/googleanalytics/" + client;
        return apiRegister.call(options, url);
    }

}

export {calls}