import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getRegions(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/"+data.client+'/'+data.channels+'?query='+data.query;
        return apiRegister.call(options, url);
    }

    static getRegion(data) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/region/"+data.client+'/'+data.channels+'/region?region='+data.region+'&country='+data.country;
        return apiRegister.call(options, url);
    }

}

export {calls}