import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getAd(internal, id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/ad/" + internal + '/' + id;
        return apiRegister.call(options, url);
    }

    static getCampaign(id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/campaign/" + id + '/overview';
        return apiRegister.call(options, url);
    }

    static removeCampaign(id) {
        let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
        let url = calls.url.api + "/campaign/" + id;
        return apiRegister.call(options, url);
    }

    static pauseCampaign(id) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
        let url = calls.url.api + "/campaign/" + id + '?status=paused';
        return apiRegister.call(options, url);
    }

    static activateCampaign(id) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
        let url = calls.url.api + "/campaign/" + id + '?status=active';
        return apiRegister.call(options, url);
    }

    static getDraft(id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/draft/" + id + '?campaign=true';
        return apiRegister.call(options, url);
    }

}

export {calls}