import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import Switch from 'react-ios-switch';
import IconFacebook from "../../icons/facebook";
import IconInstagram from "../../icons/instagram";
import IconGoogle from "../../icons/google";

class TargetingWeekdays extends Component {

    constructor(props) {
        super(props);
        this.state = {
            targeting: {}
        };
    };

    componentWillMount() {}

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            targeting: nextProps.targeting?nextProps.targeting:{}
        });
    }

    componentDidMount() {
        this.setState({
            targeting: this.props.targeting?this.props.targeting:{}
        });
    }

    init = {};

    functions = {};

    validators = {};

    renders = {};

    render() {
        return (
            <div style={{paddingLeft: '0px', paddingRight: '0px'}} className="">
                <div className="WeekDaysTitle">
                    <div className="InputContainerTitleText font-bold text-xs">
                        Weekdays to show ads
                    </div>
                </div>
                {
                    typeof this.state.targeting.weekdays === "object" &&
                    <div style={{backgroundColor: "#fcfcfc"}} className="WeekDaysItems">
                        <div className="WeekDaysItems-Item">
                            <FeatherIcon color={"#333333"} size={22} icon="calendar"/>
                            <div className="WeekDaysItems-ItemTitle">
                                All week
                            </div>
                            <div className="WeekDaysItems-ItemSlider">
                                <Switch
                                    onColor="#24bda8"
                                    checked={this.state.targeting.weekdays.all}
                                    onChange={(checked) => {
                                        if(typeof this.state.targeting.weekdays === "object"){
                                            this.state.targeting.weekdays.all = checked;
                                            for(let key in this.state.targeting.weekdays){
                                                if(key !== 'all'){
                                                    this.state.targeting.weekdays[key] = !checked;
                                                }
                                            }
                                            this.props.onChange(this.state.targeting);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {
                            !this.state.targeting.weekdays.all &&
                            <div className="WeekDaysItems-Item-Border"/>
                        }
                        {
                            typeof this.state.targeting.weekdays === "object" &&
                            Object.keys(this.state.targeting.weekdays).filter((item) => {
                                return !this.state.targeting.weekdays.all && item !== 'all'
                            }).map((day, index) => {
                                return(
                                    <div style={{with: '100%'}}>
                                        <div className="WeekDaysItems-Item">
                                            <div className="WeekDaysItems-ItemIcon">
                                                <FeatherIcon color={"#333333"} size={22} icon="corner-down-right"/>
                                            </div>
                                            <div className="WeekDaysItems-ItemTitle">
                                                {day}
                                            </div>
                                            <div className="WeekDaysItems-ItemSlider">
                                                <Switch
                                                    onColor="#24bda8"
                                                    checked={this.state.targeting.weekdays[day]}
                                                    onChange={(checked) => {
                                                        this.state.targeting.weekdays[day] = checked;
                                                        this.props.onChange(this.state.targeting);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {
                                            index !== (Object.keys(this.state.targeting.weekdays).filter((item) => {
                                                return !this.state.targeting.weekdays.all && item !== 'all'
                                            }).length - 1) &&
                                            <div className="WeekDaysItems-Item-Border"/>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

export default TargetingWeekdays;
