import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import { SlideDown } from 'react-slidedown'
import { dataRegister } from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import { BeatLoader } from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import { calls } from "./calls";
import { tokenRegister } from "../../services/tokenRegister";
import ClientFacebookPixel from "../client-facebook-pixel";
import ClientGooglePixel from "../client-google-pixel";
import ClientFacebookAdaccount from "../client-facebook-adaccount";
import { apiRegister } from '../../services/apiRegister';

class ClientBidtheatre extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            accessToken: null,
            refreshToken: null,
            networkId: null,
            open: false,
            loading: false,
            clients: [],
            advertisers: [],
            client: {},
            advertiser: {},
            submenu: 0
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item ? nextProps.item : {}
        });
    }

    init = {};

    functions = {
        login: () => {
            let self = this;
            if (!self.state.loading_login) {
                self.setState({
                    loading_login: true
                }, () => {
                    self.calls.login({
                        username: self.state.username,
                        password: self.state.password
                    }).then((response) => {
                        if (response.code == 200) {
                            self.setState({
                                networkId: response.auth.defaultNetwork,
                                accessToken: response.auth.token,
                                refreshToken: response.auth.refreshToken,
                                loading_login: false
                            }, () => {
                                this.functions.advertisers();
                            })
                        } else {
                            self.setState({
                                loading_login: false
                            })
                        }
                    }, (error) => {
                        self.setState({
                            loading_login: false
                        })
                    });
                })
            }
        },
        remove: () => {
            if (!this.state.loading) {
                this.setState({
                    loading_remove: true,
                }, () => {
                    let data = {
                        channel: "adcredo",
                        function: "removeExternalPlatform",
                        data: {
                            channel: "bidtheatre"
                        },
                        params: {
                            client: this.state.item.id
                        }
                    };
                    this.calls.batch([data]).then((response) => {
                        delete this.state.item.bidtheatre;
                        this.setState({
                            item: this.state.item,
                            loading_remove: false
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading_remove: false
                        })
                    });
                })
            }
        },
        create: () => {
            if (!this.state.loading_create) {
                this.setState({
                    loading_create: true
                }, () => {
                    let data = {
                        channel: "adcredo",
                        function: "connectExternalPlatform",
                        data: {
                            channel: "bidtheatre",
                            networkId: this.state.networkId,
                            accessToken: this.state.accessToken,
                            refreshToken: this.state.refreshToken,
                            advertiserId: this.state.advertiser.id,
                            advertiserName: this.state.advertiser.name
                        },
                        params: {
                            client: this.state.item.id
                        }
                    };
                    this.calls.batch([data]).then((response) => {
                        this.state.item.bidtheatre = {
                            networkId: this.state.networkId,
                            accessToken: this.state.accessToken,
                            refreshToken: this.state.refreshToken,
                            advertiserId: this.state.advertiser.id,
                            advertiserName: this.state.advertiser.name
                        };
                        this.setState({
                            networkId: null,
                            accessToken: null,
                            refreshToken: null,
                            email: "",
                            password: "",
                            advertiser: {},
                            item: this.state.item,
                            loading_create: false,
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading_create: false
                        })
                    });
                })
            }
        },
        advertisers: () => {
            this.setState({
                advertiser_loading: true
            }, () => {
                this.calls.getAdvertisers().then((response) => {
                    this.setState({
                        advertisers: response.advertisers,
                        advertiser_loading: false
                    })
                }, (error) => {
                    console.log(error);
                    this.setState({
                        advertiser_loading: false
                    })
                });
            })
        }
    };

    renders = {
        submenus: () => {
            let array = [
                { id: 0, name: "Add account" }
            ];
            return array;
        }
    };

    calls = {
        login: (data) => {
            let options = apiRegister.options(null, 'POST', data);
            //let url = apiRegister.url.api + "/v2/controllers/batchFunctions";
            let url = "https://asx-api.bidtheatre.com/v2.0/api/auth";
            return apiRegister.call(options, url);
        },
        getClients: () => {
            return apiRegister.call({
                method: "get",
                headers: {
                    "Authorization": this.state.accessToken
                }
            }, "https://asx-api.bidtheatre.com/v2.0/api/" + this.state.networkId + "/client");
        },
        getAdvertisers: () => {
            return apiRegister.call({
                method: "get",
                headers: {
                    "Authorization": this.state.accessToken
                }
            }, "https://asx-api.bidtheatre.com/v2.0/api/" + this.state.networkId + "/advertiser");
        },
        batch: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = calls.url.api + "/v2/controllers/batchFunctions";
            return apiRegister.call(options, url);
        }
    };

    render() {
        return (
            <div>
                <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                    <ul className="flex flex-row items-end">
                        {this.renders.submenus().map((item, j) => {
                            return (
                                <li key={j}
                                    className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                    onClick={() => {
                                        this.setState({
                                            submenu: item.id
                                        });
                                    }}>
                                    {item.name}
                                </li>
                            )
                        })
                        }
                        <li className="flex-grow border-b-2 border-gray-200"></li>
                    </ul>
                </nav>
                {
                    this.state.submenu === 0 &&
                    <div className="bg-white rounded-md px-4 py-4">
                        {
                            !this.state.accessToken &&
                            !this.state.item.bidtheatre &&
                            <div className="Section flex flex-row" style={{ paddingTop: '0px' }}>
                                <div className="flex flex-1 mr-2">
                                    <Input
                                        title={'Username'}
                                        type={'text'}
                                        disabled={false}
                                        onChange={(event) => {
                                            this.setState({
                                                username: event.target.value
                                            })
                                        }}
                                        value={this.state.username}
                                        size={'full'}
                                    />
                                </div>
                                <div className="flex flex-1 ml-2">
                                    <Input
                                        title={'Password'}
                                        type={'text'}
                                        disabled={false}
                                        onChange={(event) => {
                                            this.setState({
                                                password: event.target.value
                                            })
                                        }}
                                        value={this.state.password}
                                        size={'full'}
                                    />
                                </div>
                            </div>
                        }
                        {
                            !this.state.accessToken && !this.state.item.bidtheatre &&
                            <div className="Section">
                                <div onClick={() => {
                                    this.functions.login()
                                }} className="Button ButtonFull">
                                    {
                                        !this.state.loading_login &&
                                        <div>Login</div>
                                    }
                                    {
                                        this.state.loading_login &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.accessToken && !this.state.item.bidtheatre &&
                            <div className="Section" style={{ paddingTop: '0px' }}>
                                <Dropdown
                                    filter={false}
                                    title={'Select advertiser'}
                                    size={'full'}
                                    placeholder={(!this.state.advertiser_loading && this.state.advertisers.length < 1) ? "No advertisers available" : (this.state.advertiser_loading ? "Loading advertisers" : "Click here ..")}
                                    error={this.state.error === "manager" && !this.state.advertiser.name}
                                    options={this.state.advertisers}
                                    loading={this.state.advertiser_loading}
                                    removeArrow={!this.state.advertiser_loading && this.state.advertisers.length < 1}
                                    value={this.state.advertiser}
                                    onChange={(value) => this.setState({ advertiser: value, client: {} }, () => {
                                        //this.functions.properties();
                                    })}
                                />
                            </div>
                        }
                        {
                            !this.state.item.bidtheatre && this.state.accessToken && this.state.advertiser.id &&
                            <div className="Section">
                                <div onClick={() => {
                                    this.functions.create()
                                }} className="Button ButtonFull">
                                    {
                                        !this.state.loading_create &&
                                        <div>Add bidtheatre</div>
                                    }
                                    {
                                        this.state.loading_create &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.item.bidtheatre &&
                            <div className="Section flex flex-row" style={{ paddingTop: '0px' }}>
                                <Input
                                    title={'Advertiser Name'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.bidtheatre.advertiserName}
                                    size={'full'}
                                />
                            </div>
                        }
                        {
                            this.state.item.bidtheatre &&
                            <div className="Section flex flex-row">
                                <Input
                                    title={'Network ID'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.bidtheatre.networkId}
                                    size={'full'}
                                />
                            </div>
                        }
                        {
                            this.state.item.bidtheatre &&
                            <div className="Section">
                                <div onClick={() => {
                                    this.functions.remove();
                                }} className="Button ButtonFull">
                                    {
                                        !this.state.loading_remove &&
                                        <div>Logout bidtheatre</div>
                                    }
                                    {
                                        this.state.loading_remove &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default ClientBidtheatre;
