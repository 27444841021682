import React, {Component} from 'react';
import './style.css';
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Switch from "react-ios-switch";
import Input from "../input";
import Accordion from "../accordion";

class ClientDescription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: {},
            loading: true,
            view: null
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            client: nextProps.client,
            loading: nextProps.loading,
            view: nextProps.view
        });
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            client: this.props.client,
            loading: this.props.loading,
            view: this.props.view
        })
    }

    init = {};

    functions = {};

    renders = {};

    render() {
        return (
            <div className='campaign-description'>
                <div className="campaign-description-inner">
                    {
                        !this.state.loading &&
                        <div className="campaign-description-id">
                            {this.state.client.name}
                        </div>
                    }
                    {
                        this.state.loading &&
                        <div className="campaign-description-id-skeleton"></div>
                    }
                    {
                        !this.state.loading &&
                        <div className="campaign-description-title">
                            Company: <b style={{textTransform: 'capitalize'}}>{this.state.client.company}</b>
                        </div>
                    }
                    {
                        this.state.loading &&
                        <div className="campaign-description-title-skeleton"></div>
                    }
                    {
                        this.state.loading &&
                        <div style={{top: '0', position: 'absolute', right: '0', left: '0', bottom: '0'}}>
                            <span className="skeleton-box"></span>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default ClientDescription;
