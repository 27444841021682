import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import { BeatLoader } from "react-spinners";
import { calls } from "./calls";
import User from "../../components/user";
import { userRegister } from "../../services/userRegister";
import TopNavigationAgent from '../../moduleFiles/topNavigationAgent';
import { UserAddIcon, UserIcon } from '@heroicons/react/outline';

class UserUsersAllClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            email: "",
            loading: true,
        };
    };

    componentWillMount() { }

    componentDidMount() {
        this.init.users();
        window.scrollTo(0, 0);
    }

    init = {
        users: () => {
            calls.getUsers(userRegister.get().agency, userRegister.get().client).then((response) => {
                this.setState({
                    users: response.data,
                    loading: false
                })
            }, (error) => { });
        }
    };

    functions = {
        remove: (id) => {
            for (let i = 0; i < this.state.users.length; i++) {
                if (this.state.users[i]._id === id) {
                    this.state.users.splice(i, 1);
                    break;
                }
            }
            this.setState({
                users: this.state.users
            })
        }
    };

    renders = {};

    validators = {};

    render() {
        return (
            <div className="relative h-full">

                <TopNavigationAgent
                    onButton={(type) => {
                        if (type === "create") {
                            this.props.history.push("/v2/users/new");
                        }
                    }}
                    history={this.props.history}
                    breadcrumb={"Settings"}
                    buttons={[
                        { text: "New user", icon: UserIcon, value: "create", loading: false, show: !this.state.loading }
                    ]}
                    buttonTypes={{}}
                    buttonLoaders={{}}
                />

                {
                    !this.state.loading &&
                    <div className="p-4">
                        {
                            this.state.users.filter((item) => {
                                return item.email.indexOf(this.state.email) !== -1;
                            }).sort((a, b) => {
                                return b.createdAt - a.createdAt;
                            }).map((item) => {
                                return (
                                    <User
                                        item={item}
                                        onRemove={(id) => {
                                            this.functions.remove(id);
                                        }}
                                    />
                                )
                            })
                        }
                    </div>
                }

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading users ...</div>
                    </div>
                }

            </div>
        )
    }
}

export default UserUsersAllClient;
