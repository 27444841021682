import moment from 'moment';

class campaignGoogle {
    static validate(campaign) {
        let error = {
            budget: false,
            location: false,
            start_date: false,
            end_date: false,
            bing: false,
        };
        if (!campaign.budget || (campaign.budget && (campaign.budget === "" || parseInt(campaign.budget) < 1 || isNaN(parseInt(campaign.budget))))) {
            error.budget = true;
        }
        if (campaign.bingSync) {
            if (campaign.bingSync.schedule.value === "Once" && !campaign.bingSync.date) {
                error.bing = true;
            } else if (campaign.bingSync.schedule.value === "Weekly" && !campaign.bingSync.week) {
                error.bing = true;
            } else if (campaign.bingSync.schedule.value === "Monthly" && !campaign.bingSync.day) {
                error.bing = true;
            }

            if (!campaign.bingSync.time || !campaign.bingSync.timeZone) {
                error.bing = true;
            }
        }
        if (!campaign.locations || campaign.locations.filter((item) => { return item.included }).length < 1) {
            error.location = true;
        }
        if (campaign.start_date && !campaign.status) {
            if (new Date(campaign.start_date).getTime() < new Date().getTime()) {
                error.start_date = true;
            }
        }
        if (!campaign.status && campaign.end_date) {
            if (new Date(campaign.end_date).getTime() <= new Date(campaign.start_date).getTime() || new Date(campaign.end_date).getTime() <= new Date().getTime()) {
                error.end_date = true;
            }
        }
        return error;
    }
}

export { campaignGoogle }