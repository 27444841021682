import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from "../services/userRegister";
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, CubeIcon, ExclamationCircleIcon, PlusIcon, TrashIcon, UserIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import classNames from 'classnames';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import CreateOrder from '../moduleFiles/createOrder';
import SideNavigationAgent from '../moduleFiles/sideNavigationAgent';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WizardModal from '../moduleFiles/wizardModal';
import CreateAgent from '../moduleFiles/createAgent';
import UpdateAgent from '../moduleFiles/updateAgent';
import { get } from 'immutable';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import MultiDropdownTailwind from '../moduleFiles/multiDropdownTailwind';
import MigrateClients from '../moduleFiles/migrateClients';
import SideNotification from '../moduleFiles/sideNotification';

class AgentList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            user: {},
            meta: {},
            limit: 10,
            page: 1,
            sort: "createdAt",
            direction: "descending",
            create_wizard: false,
            wizard_name: "",
            orders: [],
            columns: [
                { name: 'name', value: 'name', min_width: '250px' },
                { name: 'email', value: 'email', min_width: '250px' },
                { name: 'level', value: 'agencyManager', min_width: '250px' },
                { name: 'created', value: 'createdAt', min_width: '250px' },
                { name: 'status', value: 'status', min_width: '250px' },
                { name: 'orders', value: 'orders', min_width: '250px' },
                { name: 'campaigns', value: 'campaigns', min_width: '250px' }
            ],
            status: { id: 0, name: "All", value: "total" },
            client: { id: 0, name: "All", value: "all" },
            clients: [
                { id: 1, name: "All", value: "all" }
            ],
            filters: [],
            open_migrate: false,
            loading_migrate: false,
            show_warning_migrate: false,
            error_migrate: null,
            loading_affected: false,
            migrate_search: "",
            migrate_from: { id: 0, name: "Search ..." },
            migrate_to: null,
            affected_clients: {
                total: 0,
                channels: {}
            },
        }
    };

    componentDidMount() {
        this.functions.agents(true);
        this.functions.user();
    }

    functions = {
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get()
            });
        },
        agents: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.agents();
                await this.promisedSetState({ orders: response.data, meta: response.meta });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        update: async (data) => {
            this.state.orders.map((item) => { if (item.id === data.id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.update(data);
                await this.promisedSetState({
                    orders: this.state.orders.map((item) => {
                        if (item.id == data.id) {
                            item.loading = false;
                            item.agencyManager = data.agencyManager;
                            item.userRole = data.userRole;
                        }
                        return item
                    })
                });
            } catch (error) {
                this.state.orders.map((item) => { if (item.id === data.id) { item.loading = false } return item });
                await this.promisedSetState({ orders: this.state.orders });
            }
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ orders: this.state.orders.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ orders: this.state.orders });
            }
        },
        getAffectedClients: async (agent) => {
            this.state.loading_affected = true;
            this.state.error_migrate = null;
            await this.promisedSetState({ loading_affected: this.state.loading_affected });
            try {
                let response = await this.calls.getAffectedClients(agent);
                if (response.data.total) {
                    this.state.affected_clients.total = response.data.total;
                    this.state.affected_clients.channels = response.data.channels;
                }
                this.state.migrate_to = agent;
                await this.promisedSetState({
                    affected_clients: this.state.affected_clients,
                    migrate_to: this.state.migrate_to
                });
            } catch (error) {
                this.state.error_migrate = error.body && error.body.message ? error.body.message : "An error occured";
                await this.promisedSetState({ error_migrate: this.state.error_migrate });
            }
            this.state.loading_affected = false;
            await this.promisedSetState({ loading_affected: this.state.loading_affected });
        },
        handleMigrateClients: async () => {
            this.state.loading_migrate = true;
            await this.promisedSetState({ loading_migrate: this.state.loading_migrate });
            try {
                if (this.state.migrate_to !== null) {
                    let data = {
                        migrate_from: this.state.migrate_from.id,
                        migrate_to: this.state.migrate_to,
                        channels: this.state.affected_clients.channels.filter(channel => channel.value === true)
                    }
                    let response = await this.calls.migrateClients(data);
                    if (response) {
                        this.refs.SideNotification.functions.trigger();
                    }
                    this.state.open_migrate = false;
                    this.state.show_warning_migrate = false;
                    this.state.affected_clients = { total: 0, channels: {} };
                }

            } catch (error) {
                this.state.error_migrate = error.body && error.body.message ? error.body.message : "An error occured";
                this.state.show_warning_migrate = false;
                await this.promisedSetState({ error_migrate: this.state.error_migrate, show_warning_migrate: this.state.show_warning_migrate });
            }
            this.state.loading_migrate = false;
            await this.promisedSetState({
                loading_migrate: this.state.loading_migrate,
                show_warning_migrate: this.state.show_warning_migrate

            });
        }
    };

    calls = {
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentRoles?id=" + data.id;
            return apiRegister.call(options, url);
        },
        agents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&status=" + this.state.status.value + (this.state.client.id !== 0 ? "&client=" + this.state.client.id : "");
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgencyAgent?id=" + id;
            return apiRegister.call(options, url);
        },
        getAffectedClients: (migrate_to) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAffectedClients?migrate_from=" + this.state.migrate_from.id + "&migrate_to=" + migrate_to;
            return apiRegister.call(options, url);
        },
        migrateClients: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/migrateAgentClients";
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                {
                    this.state.user && (this.state.user.userRole === "admin" || this.state.user.manager) &&
                    <div className="relative flex flex-1 flex-col">

                        <div className="sticky z-51 top-0">
                            <TopNavigationAgent
                                onButton={(type) => {
                                    if (type === "new") {
                                        this.setState({
                                            create_order: true
                                        })
                                    }
                                }}
                                onLimit={async (value) => {
                                    await this.promisedSetState({
                                        page: 1,
                                        limit: value
                                    })
                                    this.functions.agents();
                                }}
                                onNext={async (value) => {
                                    await this.promisedSetState({
                                        page: value
                                    })
                                    this.functions.agents();
                                }}
                                onPrevious={async (value) => {
                                    await this.promisedSetState({
                                        page: value
                                    })
                                    this.functions.agents();
                                }}
                                limit={this.state.limit}
                                page={this.state.page}
                                total={this.state.meta ? this.state.meta[this.state.status.value] : 0}
                                showPaginaton={!this.state.loading}
                                hideUserDropdown={true}
                                breadcrumb={"Agents"}
                                buttons={[
                                    { text: "Create new", icon: UserIcon, value: "new", loading: false, show: !this.state.loading }
                                ]}
                                buttonTypes={{}}
                                buttonLoaders={{}}
                            />
                        </div>

                        {/*LOADING VIEW*/}
                        {
                            this.state.loading &&
                            <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                                <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                <div className="font-semibold mt-3">Loading agents ...</div>
                            </div>
                        }

                        {/*CREATE ORDER*/}
                        <SlideoutTailwind
                            title={"Update Agent"}
                            submitButtonText={"Update"}
                            large={true}
                            secondaryButton={false}
                            open={this.state.update_agent ? true : false}
                            loader={this.state.update_agent_loading}
                            onCancel={() => {
                                this.promisedSetState({ update_agent: false });
                            }}
                            onSubmit={async () => {
                                await this.promisedSetState({
                                    update_agent_loading: true
                                });
                                this.refs.UpdateAgent.functions.updateAgent();
                            }}
                        >
                            {
                                this.state.update_agent &&
                                <UpdateAgent
                                    ref="UpdateAgent"
                                    agent={this.state.update_agent}
                                    onCreated={async (order) => {
                                        await this.promisedSetState({
                                            update_agent: false,
                                            update_agent_loading: false
                                        });
                                    }}
                                    onError={async (message) => {
                                        await this.promisedSetState({
                                            update_agent_loading: false
                                        });
                                    }}
                                />
                            }
                        </SlideoutTailwind>

                        {/*CREATE ORDER*/}
                        <SlideoutTailwind
                            title={"Create Agent"}
                            submitButtonText={"Create"}
                            //medium={true}
                            secondaryButton={false}
                            open={this.state.create_order ? true : false}
                            loader={this.state.create_order_loading}
                            onCancel={() => {
                                this.promisedSetState({ create_order: false, order: {} });
                            }}
                            onSubmit={async () => {
                                await this.promisedSetState({
                                    create_order_loading: true
                                });
                                this.refs.CreateAgent.functions.createAgent();
                            }}
                        >
                            {
                                this.state.create_order &&
                                <CreateAgent
                                    ref="CreateAgent"
                                    onCreated={async (order) => {
                                        await this.promisedSetState({
                                            create_order: false,
                                            create_order_loading: false
                                        });
                                        this.functions.agents();
                                    }}
                                    onError={async (message) => {
                                        await this.promisedSetState({
                                            create_order_loading: false
                                        });
                                    }}
                                />
                            }
                        </SlideoutTailwind>

                        {/*MIGRATE ORDERS*/}
                        <SlideoutTailwind
                            title={"Reassign clients"}
                            submitButtonText={"Update"}
                            secondaryButton={false}
                            open={this.state.open_migrate ? true : false}
                            loader={this.state.loading_migrate}
                            onCancel={() => {
                                this.state.open_migrate = false;
                                this.state.error_migrate = null;
                                this.state.migrate_to = null;
                                this.state.affected_clients = { total: 0, channels: {} };
                                this.promisedSetState({ open_migrate: this.state.open_migrate, error_migrate: this.state.error_migrate, migrate_to: this.state.migrate_to, affected_clients: this.state.affected_clients });
                            }}
                            onSubmit={async () => {
                                this.state.show_warning_migrate = true;
                                await this.promisedSetState({
                                    show_warning_migrate: this.state.show_warning_migrate
                                });
                            }}
                        >
                            <MigrateClients
                                agents={this.state.orders ? this.state.orders : []}
                                migrate_from={this.state.migrate_from ? this.state.migrate_from : { id: 0, name: "Search ..." }}
                                affected_clients={this.state.affected_clients ? this.state.affected_clients : { total: 0, channels: {} }}
                                error={this.state.error_migrate}
                                loading_affected={this.state.loading_affected}
                                loading={this.state.loading_migrate}
                                migrate_search={this.state.migrate_search}
                                getAffectedClients={this.functions.getAffectedClients}

                            />
                        </SlideoutTailwind>

                        {/*REMOVE MODAL*/}
                        <WarningModalTailwind
                            open={this.state.remove ? true : false}
                            title={"Delete"}
                            description={'Are you sure you want to delete ? This action cannot be undone.'}
                            cancelButtonText={"Cancel"}
                            submitButtonText={"Remove"}
                            disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                            showInput={true}
                            inputTitle={"Type Name to remove"}
                            copyInput={this.state.remove ? this.state.remove.name : ""}
                            inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                            onClose={async (value) => {
                                await this.promisedSetState({ remove: null });
                                await this.promisedSetState({ remove_name: "" });
                            }}
                            onSubmit={(value) => {
                                if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                                    this.functions.remove();
                                }
                            }}
                            onInput={(value) => {
                                this.setState({
                                    remove_name: value
                                })
                            }}
                        />

                        {/* MIGRATE WARNING */}
                        <WarningModalTailwind
                            open={this.state.show_warning_migrate ? true : false}
                            title={"Reassign clients"}
                            description={"Are you sure you want to reassign clients?"}
                            cancelButtonText={"Cancel"}
                            submitButtonText={"Yes"}
                            decisionModal={true}
                            loading={this.state.loading_migrate}
                            onClose={async () => {
                                this.state.show_warning_migrate = false;
                                await this.promisedSetState({ show_warning_migrate: this.state.show_warning_migrate });
                            }}
                            onSubmit={() => {
                                this.functions.handleMigrateClients()
                            }}
                        />

                        <SideNotification
                            ref={"SideNotification"}
                            icon={CheckIcon}
                            title={"Success"}
                            text={"Clients reassigned to new agent"}
                        />

                        {
                            !this.state.loading &&
                            <div className="p-4 flex flex-1 flex-col">
                                <div className="shadow bg-white rounded-lg w-full h-full overflow-hidden">

                                    {/*NO ORDERS*/}
                                    {
                                        this.state.orders.length < 1 &&
                                        <div className="border-t border-gray-200 h-full flex justify-center items-center relative">
                                            <div className="text-center flex justity-center items-center flex-col">
                                                <div className="mt-2 text-ls font-medium text-gray-500">No agents available</div>
                                            </div>
                                            {
                                                this.state.loading_partial &&
                                                <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    {/*TABLE*/}
                                    {
                                        this.state.orders.length > 0 &&
                                        <div className="relative overflow-x-auto table-overflow h-full">

                                            {/*LOADER PARTIAL*/}
                                            {
                                                this.state.loading_partial &&
                                                <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }

                                            <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                                <thead className="bg-white">
                                                    <tr>
                                                        <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className=""></th>
                                                        {this.state.columns.map((item, index) => {
                                                            return (
                                                                <th
                                                                    onClick={async () => {
                                                                        if (!item.noSort) {
                                                                            await this.promisedSetState({
                                                                                page: 1,
                                                                                sort: item.value,
                                                                                direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                            });
                                                                            this.functions.agents();
                                                                        }
                                                                    }}
                                                                    style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                    scope="col"
                                                                    className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                                >
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="mr-4">{item.name}</div>
                                                                        {
                                                                            !item.noSort &&
                                                                            <div className="flex flex-col">
                                                                                <ArrowDownIcon className={`h-4 w-4 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-gray-300">
                                                    {
                                                        this.state.orders.map((item, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr className="border-b" key={item.id}>
                                                                        <td style={{ width: "100px", maxWidth: "100px" }} className={"px-4 sm:px-6 py-4 flex flex-row  border-gray-300"}>
                                                                            <Menu as="div" className="relative inline-block text-left">
                                                                                <div>
                                                                                    <Menu.Button className={"cursor-pointer flex relative h-10 w-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                        {
                                                                                            item.loading &&
                                                                                            <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                            </div>
                                                                                        }
                                                                                    </Menu.Button>
                                                                                </div>
                                                                                <Transition
                                                                                    as={Fragment}
                                                                                    enter="transition ease-out duration-100"
                                                                                    enterFrom="transform opacity-0 scale-95"
                                                                                    enterTo="transform opacity-100 scale-100"
                                                                                    leave="transition ease-in duration-75"
                                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                                    leaveTo="transform opacity-0 scale-95"
                                                                                >
                                                                                    <Menu.Items className={`border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                                                                        {/* <Menu.Items className={`${this.state.orders.length - (index + 1) < 5 && this.state.orders.length > 5 ? "bottom-100 left-100" : ""} border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}> */}
                                                                                        <div className="py-1">
                                                                                            {
                                                                                                ["Change to Admin", "Change to Manager", "Change to Regular", "Change to Sales", "Remove", "Activate URL", "Reassign clients"].filter((option) => {
                                                                                                    if (option === "Change to Admin" && this.state.user && this.state.user.userRole === "admin" && ((item.userRole && item.userRole !== "admin") || !item.userRole)) {
                                                                                                        return true;
                                                                                                    } else if (option === "Change to Manager" && this.state.user && (this.state.user.userRole === "admin" || this.state.user.manager) && item.userRole !== "admin" && !item.agencyManager) {
                                                                                                        return true;
                                                                                                    } else if (["Change to Regular", "Change to Sales"].includes(option) && this.state.user && (this.state.user.userRole === "admin" || this.state.user.manager) && item.userRole !== "admin") {
                                                                                                        return true;
                                                                                                    } if (option === "Remove") {
                                                                                                        if (this.state.user && this.state.user.userRole === "admin") {
                                                                                                            return true
                                                                                                        } else {
                                                                                                            if (item.userRole && item.userRole === "admin") {
                                                                                                                return false;
                                                                                                            } else {
                                                                                                                return true;
                                                                                                            }
                                                                                                        }
                                                                                                    } else if (option === "Activate URL" && item.resetPasswordToken) {
                                                                                                        return true;
                                                                                                    } else if (option === "Reassign clients") {
                                                                                                        return this.state.user && this.state.user.userRole === "admin";
                                                                                                    } else {
                                                                                                        return false;
                                                                                                    }
                                                                                                }).map((option) => {
                                                                                                    return (
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={async () => {
                                                                                                                        if (option === "Change to Admin") {
                                                                                                                            await this.functions.update({ id: item.id, userRole: "admin", agencyManager: false });
                                                                                                                        } else if (option === "Change to Manager") {
                                                                                                                            await this.functions.update({ id: item.id, userRole: "manager", agencyManager: true });
                                                                                                                        } else if (option === "Change to Regular") {
                                                                                                                            await this.functions.update({ id: item.id, userRole: "regular", agencyManager: false });
                                                                                                                        } else if (option === "Change to Sales") {
                                                                                                                            await this.functions.update({ id: item.id, userRole: "sales", agencyManager: false });
                                                                                                                        } else if (option === "Remove") {
                                                                                                                            this.setState({
                                                                                                                                remove: item
                                                                                                                            })
                                                                                                                        } else if (option === "Activate URL") {
                                                                                                                            if (window.location.hostname === 'localhost') {
                                                                                                                                window.open("http://localhost:5001/set-password/" + item.resetPasswordToken);
                                                                                                                            } else if (window.location.hostname === 'app.adcredo.io') {
                                                                                                                                window.open("https://app.adcredo.io/set-password/" + item.resetPasswordToken);
                                                                                                                            } else if (window.location.hostname === 'dev.adcredo.io') {
                                                                                                                                window.open("https://dev.adcredo.io/set-password/" + item.resetPasswordToken);
                                                                                                                            }
                                                                                                                        } else if (option === "Reassign clients") {
                                                                                                                            this.state.migrate_from = item;
                                                                                                                            this.state.open_migrate = true;
                                                                                                                            this.setState({
                                                                                                                                open_migrate: this.state.open_migrate,
                                                                                                                                migrate_from: this.state.migrate_from
                                                                                                                            })
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    className={cn(
                                                                                                                        active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                        'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                                    )}
                                                                                                                >
                                                                                                                    {option}
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </Menu.Items>
                                                                                </Transition>
                                                                            </Menu>
                                                                        </td>
                                                                        <td className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300"}>
                                                                            {item.name ? item.name : "-"}
                                                                        </td>
                                                                        <td className={(this.state.sort === 'email' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300"}>
                                                                            {item.email ? item.email : "-"}
                                                                        </td>
                                                                        <td className={(this.state.sort === 'agencyManager' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                            <span
                                                                                className={classNames("px-4 py-1 inline-flex text-xs font-bold rounded-full bg-purple-100 text-purple-500")}>
                                                                                {item.userRole && item.userRole === "sales" ? "Sales" : (item.userRole && item.userRole === "admin" ? "Admin" : (item.agencyManager ? "Manager" : "Regular"))}
                                                                            </span>
                                                                        </td>
                                                                        <td className={(this.state.sort === 'createdAt' ? "bg-gray-50" : "") + " px-4 sm:px-6 font-medium py-4 items-center text-sm border-gray-300"}>
                                                                            {item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD hh:mm a') : '-'}
                                                                        </td>
                                                                        <td className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm border-gray-300"}>
                                                                            <span
                                                                                className={classNames("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                                    {
                                                                                        ["bg-green-100 text-green-500"]: item.status === "active",
                                                                                        ["bg-orange-100 text-orange-500 bg-opacity-50"]: item.status === "pending"
                                                                                    })
                                                                                }>
                                                                                {item.status ? item.status : "-"}
                                                                            </span>
                                                                        </td>
                                                                        <td className={(this.state.sort === 'orders' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300"}>
                                                                            {item.orders ? item.orders : "-"}
                                                                        </td>
                                                                        <td className={(this.state.sort === 'campaigns' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300"}>
                                                                            {item.campaigns ? item.campaigns : "-"}
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>

                            </div>
                        }

                    </div>
                }
            </>
        )
    }
}

export default AgentList;
