import React, { Component, Fragment } from 'react';
import { HomeIcon, SearchIcon, PlusIcon, ChatIcon, UserIcon, HeartIcon } from '@heroicons/react/solid'
// import { ChevronRightIcon, ReplyIcon } from '@heroicons/react/outline';
import { DotsVerticalIcon, CameraIcon } from '@heroicons/react/solid'
import { BookmarkIcon, ChevronRightIcon } from '@heroicons/react/outline';

class PreviewSnapchatStories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: {},
            video: null,
            body: "",
            cta: {},
            title: "",
        }
    }

    async componentDidMount() {
        await this.promisedSetState({
            page: this.props.page,
            video: this.props.video,
            body: this.props.body,
            cta: this.props.cta,
            title: this.props.title,
        })

        setTimeout(() => {
            try {
                if (this.refs.video) {
                    this.refs.video.setAttribute("muted", true);
                }
            } catch (error) {
                console.log(error)
            }
        }, 200)
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            page: nextProps.page,
            video: nextProps.video,
            body: nextProps.body,
            cta: nextProps.cta,
            title: nextProps.title,
            ratio: nextProps.ratio,
        })
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    truncText(string, maxLength) {
        if (string.length > maxLength) {
            return string.substring(0, maxLength) + "..."
        }
        else {
            return string
        }
    }

    render() {
        return (
            <div
                style={{ height: 568 }}
                className="relative flex flex-col justify-center w-80 rounded-md overflow-hidden bg-black">
                <div className="absolute top-0 flex p-2 w-full">
                    <div style={{ width: 50 }}
                        className="mr-2"
                    >
                        <img src={this.state.page.image}
                            className="rounded-full"
                            alt={this.state.page.name}
                        />
                    </div>
                    <div className="flex flex-col text-sm text-white">
                        <div>
                            {this.state.page.name}
                        </div>
                        <div className="text-xxs">Sunday</div>
                    </div>
                    <div className="flex w-full justify-end text-white">
                        <BookmarkIcon className='w-5 mr-3' />
                        <DotsVerticalIcon className='w-5' />
                    </div>
                </div>
                <div>
                    <video src={this.state.video} autoplay={false} controls={false} muted loop className='h-full rounded-md' ref="video" />
                </div>
                <div className='absolute bottom-0 flex flex-col w-full rounded-t-lg pb-4 bg-black bg-opacity-50'>
                    <div className='bg-gray-500 rounded-full h-1 w-10 mx-auto my-1.5'></div>
                    <div className="flex justify-between items-center px-2">
                        <div className="py-3 px-4 rounded-full bg-gray-200 bg-opacity-25">
                            <CameraIcon className='w-5 text-white' />
                        </div>
                        <div className="py-3 px-14 rounded-full text-white font-semibold text-sm bg-gray-200 bg-opacity-25">
                            {this.state.cta ? this.state.cta.name : ''} ! SPOTLIGHT PREVIEW !
                        </div>
                        <div className="py-3 px-4 rounded-full bg-gray-200 bg-opacity-25">
                            <ChevronRightIcon className='w-5 text-white' />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewSnapchatStories;
