import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton, TumblrIcon, TumblrShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";
import TextArea from "../text-area";
import Dropdown from "../../components/dropdown";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Datepicker from "../datepicker";
import IconGoogle from "../advertising-budget";
import CampaignStatus from "../campaign-status";
import DashboardListItem from "../dashboard-list-item";
var moment = require('moment');

class DashboardList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            type: "",
            total: 0
        };
    };

    componentWillMount() {}

    componentDidMount() {
        this.init.values();
    }

    init = {
        values: () => {
            this.setState({
                campaigns: this.props.campaigns,
                type: this.props.type,
                total: this.props.total
            })
        }
    };

    functions = {};

    renders = {};

    render() {
        return (
            <div className="DashboardList-Container">
                {
                    this.state.type === 'active' && this.state.campaigns.length > 0 &&
                    <div className="DashboardList-ContainerTitle DashboardList-ContainerTitleActive">
                        Active campaigns
                    </div>
                }
                {
                    this.state.type === 'upcoming' && this.state.campaigns.length > 0 &&
                    <div className="DashboardList-ContainerTitle DashboardList-ContainerTitleUpcoming">
                        Upcoming campaigns
                    </div>
                }
                {
                    this.state.type === 'paused' && this.state.campaigns.length > 0 &&
                    <div className="DashboardList-ContainerTitle DashboardList-ContainerTitlePaused">
                        Paused campaigns
                    </div>
                }
                {
                    this.state.campaigns.length > 0 &&
                    this.state.campaigns.map((item) => {
                        return(
                            <DashboardListItem
                                item={item}
                            />
                        )
                    })
                }
                {
                    this.state.campaigns.length < 1 &&
                    <div className="DashboardList-ContainerEmpty">
                        No
                        {
                            this.state.type === 'active' &&
                            <span className="DashboardList-ContainerTitleActive">&nbsp;<b>Active</b>&nbsp;</span>
                        }
                        {
                            this.state.type === 'paused' &&
                            <span className="DashboardList-ContainerTitlePaused">&nbsp;<b>Paused</b>&nbsp;</span>
                        }
                        {
                            this.state.type === 'upcoming' &&
                            <span className="DashboardList-ContainerTitleUpcoming">&nbsp;<b>Upcoming</b>&nbsp;</span>
                        }
                        campaigns available
                    </div>
                }
                {
                    this.state.campaigns.length > 0 && this.state.total > this.state.campaigns.length &&
                    <div style={{paddingTop: '0px'}} className="Section">
                        <div className="Button ButtonNeutral ButtonFull">Load more</div>
                    </div>
                }
            </div>
        )
    }
}

export default DashboardList;
