import React, { Component } from 'react';
import { DotsHorizontalIcon, GlobeIcon } from '@heroicons/react/solid'
import { ThumbUpIcon, AnnotationIcon, ShareIcon, XIcon } from '@heroicons/react/outline';

class PreviewFacebookCollectionAd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cta: {},
            page: {},
            slides: {},
            video: null,
            thumbnail: null,
            image: "",
            body: "",
            description: "",
            title: "",
            link: "",
            caption: "",
            adData: {},
            read_more: false,
            for_report: false
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            cta: this.props.cta,
            page: this.props.page,
            slides: this.props.slides,
            video: this.props.video,
            thumbnail: this.props.thumbnail,
            image: this.props.image,
            body: this.props.body,
            description: this.props.description,
            title: this.props.title,
            link: this.props.link,
            caption: this.props.caption,
            adData: this.props.adData,
            for_report: this.props.for_report
        })

        setTimeout(() => {
            try {
                if (this.refs.videoOne) {
                    this.refs.videoOne.setAttribute("muted", true);
                }
                if (this.refs.videoTwo) {
                    this.refs.videoTwo.setAttribute("muted", true);
                }
            } catch (error) {
                console.log(error);
            }
        }, 200);
    }


    componentWillReceiveProps(nextProps) {
        this.setState({
            cta: nextProps.cta,
            page: nextProps.page,
            slides: nextProps.slides,
            video: nextProps.video,
            thumbnail: nextProps.thumbnail,
            image: nextProps.image,
            body: nextProps.body,
            description: nextProps.description,
            title: nextProps.title,
            link: nextProps.link,
            caption: nextProps.caption,
            adData: nextProps.adData,
            for_report: nextProps.for_report
        })
    }
    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    truncText(string, maxLength) {
        if (string.length > maxLength) {
            return string.substring(0, maxLength) + "..."
        }
        else {
            return string
        }
    }

    render() {
        return (
            <div className='flex flex-col justify-center'>
                <div className='w-80 py-3 rounded-lg border shadow-xl bg-white'>
                    <div className='px-3 pb-3 border-b border-gray-200'>
                        <div className='flex pb-3'>
                            <div className='flex flex-row flex-1'>
                                {
                                    this.state.page.image &&
                                    <div>
                                        <div className='h-10 w-10 mr-2 border-gray-200'>
                                            <img src={this.state.page.image} alt={this.state.page.name}
                                                className='rounded-full'
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    !this.state.page.image &&
                                    <div className='h-10 w-10 mr-2 border rounded-full'></div>
                                }
                                <div className='flex flex-1 flex-col'>
                                    <div className='text-sm font-bold'>{this.state.page.name}</div>
                                    <div className='flex items-center text-xs text-gray-500'>
                                        Sponsored
                                        <span className='mx-1'>&#183;</span>
                                        <span><GlobeIcon style={{ width: "14px" }} /></span>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <div className='flex flex-row'>
                                    <DotsHorizontalIcon className='w-5 mr-3' />
                                    <XIcon className='w-7' />
                                </div>
                                <div className='flex flex-1'></div>
                            </div>
                        </div>
                        <div className='text-sm'>
                            {this.state.body && !this.state.read_more ? this.truncText(this.state.body, 125) : (this.state.body && this.state.read_more ? this.state.body : "Body...")}
                            {
                                !this.state.read_more &&
                                this.truncText(this.state.body, 125).length > 125 &&
                                <span
                                    onClick={async () => {
                                        await this.promisedSetState({
                                            read_more: true
                                        });
                                    }} className="cursor-pointer font-semibold text-sm text-center pb-1">
                                    {" "}See more
                                </span>
                            }
                        </div>
                    </div>
                    {
                        !this.state.for_report &&
                        <div className='h-full w-full'>
                            <div
                                style={this.state.adData && this.state.adData.file && this.state.adData.file.ratio === "9:16" ? { height: 318 } : { height: "100%" }}
                            >
                                {this.state.image && this.state.image !== "" &&
                                    <img src={this.state.image} alt={this.state.name} className="w-full h-full object-cover" />
                                }
                                {this.state.video && this.state.video !== "" &&
                                    <video src={this.state.video} autoplay={false} controls={true} muted loop alt={this.state.name} className="h-full w-full object-cover" ref="videoOne" />
                                }
                            </div>
                        </div>
                    }
                    {
                        this.state.for_report &&
                        <div
                        // className='h-full w-full'
                        >
                            <div
                            // style={this.state.adData && this.state.adData.file && this.state.adData.file.ratio === "9:16" ? { height: 318 } : { height: "100%" }}
                            >
                                {this.state.image && this.state.image !== "" &&
                                    <img src={this.state.image} alt={this.state.name} className="w-full h-full object-cover" />
                                }
                                {this.state.video && this.state.video !== "" &&
                                    <video src={this.state.video} autoplay={false} controls={true} muted loop alt={this.state.name} className="h-full w-full object-cover" ref="videoTwo" />
                                }
                            </div>
                        </div>
                    }
                    <div className='flex w-full py-1.5 px-2 justify-between items-center border-b border-gray-200 bg-gray-100'>
                        <div className='flex flex-col flex-0 truncate whitespace-no-wrap'>
                            <div className='text-sm font-bold'>{this.state.title ? this.state.title : "Headline..."}</div>
                        </div>
                    </div>
                    <div className='w-full py-2 justify-between items-center grid grid-cols-9'>
                        <div className='col-span-3 border border-gray-200 h-full'>
                            {
                                Array.isArray(this.state.slides) && this.state.slides.length > 0 && this.state.slides[0] &&
                                <img src={this.state.slides[0].picture} alt={this.state.slides[0].name} className="w-full h-full object-cover" />
                            }
                        </div>
                        <div className='col-span-3 border border-gray-200 h-full'>
                            {
                                Array.isArray(this.state.slides) && this.state.slides.length > 0 && this.state.slides[1] &&
                                <img src={this.state.slides[1].picture} alt={this.state.slides[1].name} className="w-full h-full object-cover" />
                            }
                        </div>
                        <div className='col-span-3 border border-gray-200 relative items-center h-full'>
                            {
                                Array.isArray(this.state.slides) && this.state.slides.length > 0 && this.state.slides[2] &&
                                <img src={this.state.slides[2].picture} alt={this.state.slides[2].name} className="w-full h-full object-cover" />
                            }
                            <div className=' absolute flex items-center justify-center bg-gray-900 z-10 w-full h-full bg-opacity-50 top-0 left-0'>
                                <span className=' text-white font-semibold text-base'>More</span>
                            </div>

                        </div>
                    </div>
                    <div className='pt-4'>
                        <div className='flex justify-around text-xms text-gray-600 font-bold border-t pt-3 mx-3 border-gray-300'>
                            <div className='flex items-center'><span><ThumbUpIcon className='w-5 mr-1' /></span>Like</div>
                            <div className='flex items-center'><span><AnnotationIcon className='w-5 mr-1' /></span>Comment</div>
                            <div className='flex items-center'><span><ShareIcon className='w-5 mr-1' /></span>Share</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PreviewFacebookCollectionAd;
