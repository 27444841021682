import React from 'react';
import './style.css';

function notFound() {
    return (
        <div className="NotFound">
            <div className="NotFound-Text">
                404 - Not Found
            </div>
        </div>
    );
}

export default notFound;
