import React, { Component, Fragment } from 'react';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon, ExternalLinkIcon, FolderIcon, NewspaperIcon, PencilIcon, PlusCircleIcon, SortAscendingIcon, SortDescendingIcon, ThumbDownIcon, TrashIcon, ViewGridIcon } from '@heroicons/react/outline';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import EditFacebookCampaign from '../moduleFiles/editFacebookCampaign';
import EditFacebookAdset from '../moduleFiles/editFacebookAdset';
import EditFacebookAd from '../moduleFiles/editFacebookAd';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import EditGoogleCampaign from '../moduleFiles/editGoogleCampaign';
import CreateGoogleNegativeKeyword from '../moduleFiles/createGoogleNegativeKeyword';
import CreateGoogleKeyword from '../moduleFiles/createGoogleKeyword';
import EditGoogleAd from '../moduleFiles/editGoogleAd';
import EditLinkedinCampaign from '../moduleFiles/editLinkedinCampaign';
import EditLinkedinAdgroup from '../moduleFiles/editLinkedinAdgroup';
import InputTailwind from '../moduleFiles/inputTailwind';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';

const channels = [
    {
        name: "Facebook",
        channel: 'facebook',
        campaigns: [
            { name: 'Lindsay Walton' },
            { name: 'Courtney Henry' }
        ],
    },
    {
        name: "Tik Tok",
        channel: 'tiktok',
        campaigns: [
            { name: 'Lindsay Walton' },
            { name: 'Courtney Henry' }
        ],
    }
]

class Optimize extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: null,
            stats: {
                google: [
                    {
                        title: "Total campaign",
                        value: "-",
                        key: "campaigns"
                    },
                    {
                        title: "Total search terms",
                        value: "-",
                        key: "search_terms"
                    },
                    {
                        title: "Total adgroups",
                        value: "-",
                        key: "adgroups"
                    },
                    {
                        title: "Total ads",
                        value: "-",
                        key: "ads"
                    },
                    {
                        title: "Total keywords",
                        value: "-",
                        key: "keywords"
                    },
                    {
                        title: "Total negative keywords",
                        value: "-",
                        key: "negative_keywords"
                    }
                ],
                facebook: [
                    {
                        title: "Total campaign",
                        value: "-",
                        key: "campaigns"
                    },
                    {
                        title: "Total adgroups",
                        value: "-",
                        key: "adgroups"
                    },
                    {
                        title: "Total ads",
                        value: "-",
                        key: "ads"
                    },
                ],
                twitter: [
                    {
                        title: "Total campaign",
                        value: "-",
                        key: "campaigns"
                    },
                    {
                        title: "Total adgroups",
                        value: "-",
                        key: "adgroups"
                    },
                    {
                        title: "Total ads",
                        value: "-",
                        key: "ads"
                    },
                ],
                tiktok: [
                    {
                        title: "Total campaign",
                        value: "-",
                        key: "campaigns"
                    },
                    {
                        title: "Total adgroups",
                        value: "-",
                        key: "adgroups"
                    },
                    {
                        title: "Total ads",
                        value: "-",
                        key: "ads"
                    },
                ],
                linkedin: [
                    {
                        title: "Total campaign",
                        value: "-",
                        key: "campaigns"
                    },
                    {
                        title: "Total adgroups",
                        value: "-",
                        key: "adgroups"
                    },
                    {
                        title: "Total ads",
                        value: "-",
                        key: "ads"
                    },
                ]
            },
            tabs: {
                google: [
                    { name: "Campaigns", value: "campaigns", new: false, default_sort: "name" },
                    { name: "Search terms", value: "search_terms", new: false, default_sort: "text" },
                    { name: "Adgroups", value: "adgroups", new: false, default_sort: "name" },
                    { name: "Ads", value: "ads", new: false, default_sort: "campaign_name" },
                    { name: "Keywords", value: "keywords", new: true, default_sort: "keyword_text" },
                    { name: "Negative keywords", value: "negative_keywords", new: true, new: true, default_sort: "keyword_text" }
                ],
                facebook: [
                    { name: "Campaigns", value: "campaigns", new: false, default_sort: "name" },
                    { name: "Adgroups", value: "adgroups", new: false, default_sort: "name" },
                    { name: "Ads", value: "ads", new: false, default_sort: "name" },
                ],
                twitter: [
                    { name: "Campaigns", value: "campaigns", new: false, default_sort: "name" },
                    { name: "Adgroups", value: "adgroups", new: false, default_sort: "name" },
                    { name: "Ads", value: "ads", new: false, default_sort: "name" },
                ],
                tiktok: [
                    { name: "Campaigns", value: "campaigns", new: false, default_sort: "name" },
                    { name: "Adgroups", value: "adgroups", new: false, default_sort: "name" },
                    { name: "Ads", value: "ads", new: false, default_sort: "name" },
                ],
                linkedin: [
                    { name: "Campaigns", value: "campaigns", new: false, default_sort: "name" },
                    { name: "Adgroups", value: "adgroups", new: false, default_sort: "name" },
                    { name: "Ads", value: "ads", new: false, default_sort: "name" },
                ]
            },
            columns: {
                facebook: {
                    campaigns: [
                        { name: "campaign", value: "name" },
                        { name: "status", value: "effective_status" },
                        { name: "start date", value: "start_time" },
                        { name: "end date", value: "stop_time" },
                        { name: "budget", value: "daily_budget" }
                    ],
                    adgroups: [
                        { name: "adset", value: "name" },
                        { name: "status", value: "effective_status" },
                        { name: "campaign", value: "campaign_name" },
                        { name: "start date", value: "start_time" },
                        { name: "end date", value: "stop_time" },
                        { name: "budget", value: "daily_budget" }
                    ],
                    ads: [
                        { name: "ad", value: "name" },
                        { name: "status", value: "effective_status" },
                        { name: "campaign", value: "campaign_name" },
                        { name: "adset", value: "adset_name" },
                    ]
                },
                twitter: {
                    campaigns: [
                        { name: "campaign", value: "name" },
                        //{ name: "status", value: "effective_status" },
                    ],
                    adgroups: [
                        { name: "adset", value: "name" },
                        { name: "status", value: "effective_status" },
                        { name: "campaign", value: "campaign_name" },
                    ],
                    ads: [
                        { name: "ad", value: "name" },
                        //{ name: "status", value: "effective_status" },
                        { name: "campaign", value: "campaign_name" },
                        { name: "adset", value: "adset_name" },
                    ]
                },
                tiktok: {
                    campaigns: [
                        { name: "campaign", value: "name" },
                        //{ name: "status", value: "effective_status" },
                    ],
                    adgroups: [
                        { name: "adset", value: "name" },
                        //{ name: "status", value: "effective_status" },
                        { name: "campaign", value: "campaign_name" },
                    ],
                    ads: [
                        { name: "ad", value: "name" },
                        //{ name: "status", value: "effective_status" },
                        { name: "campaign", value: "campaign_name" },
                        { name: "adset", value: "adset_name" },
                    ]
                },
                linkedin: {
                    campaigns: [
                        { name: "campaign", value: "name", type: "char" },
                        { name: "status", value: "status", min_width: "150px", type: "char" },
                        { name: "start date", value: "start_date", min_width: "150px", type: "date" },
                        { name: "spent", value: "spent", min_width: "150px", type: "number" },
                        { name: "impressions", value: "impressions", min_width: "150px", type: "number" },
                        { name: "clicks", value: "clicks", min_width: "150px", type: "number" },
                        { name: "Average CTR", value: "ctr", min_width: "150px", type: "number" },
                        { name: "Bid", value: "bid", min_width: "150px", type: "number" },
                        { name: "Average CPM", value: "cpm", min_width: "150px", type: "number" },
                        { name: "Average CPC", value: "cpc", min_width: "150px", type: "number" },
                        { name: "Conversions", value: "conversions", min_width: "150px", type: "number" },
                        { name: "Cost Per Conversion", value: "cost_per_conversion", min_width: "150px", type: "number" },
                        { name: "Leads", value: "leads", min_width: "150px", type: "number" },
                        { name: "Cost Per Lead", value: "cost_per_lead", min_width: "150px", type: "number" },
                        { name: "Event Registrations", value: "event_reg", min_width: "150px", type: "number" },
                        //{ name: "end date", value: "end_date" },
                        //{ name: "daily budget", value: "daily_budget" },
                        //{ name: "total budget", value: "total_budget" }
                    ],
                    adgroups: [
                        { name: "adset", value: "name", min_width: "150px" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "spent", value: "spent", min_width: "150px" },
                        { name: "Key Results", value: "key_results", min_width: "150px" },
                        { name: "Cost Per Result", value: "cost_per_result", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "Average CTR", value: "ctr", min_width: "150px" },
                        { name: "Bid", value: "bid", min_width: "150px" },
                        { name: "Average CPM", value: "cpm", min_width: "150px" },
                        { name: "Average CPC", value: "cpc", min_width: "150px" },
                        { name: "Conversions", value: "conversions", min_width: "150px" },
                        { name: "Cost Per Conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "Leads", value: "leads", min_width: "150px" },
                        { name: "Cost Per Lead", value: "cost_per_lead", min_width: "150px" },
                        { name: "Event Registrations", value: "event_reg", min_width: "150px" },
                        //{ name: "start date", value: "start_date" },
                        //{ name: "end date", value: "end_date" },
                        //{ name: "daily budget", value: "daily_budget" },
                        //{ name: "total budget", value: "total_budget" }
                    ],
                    ads: [
                        { name: "ad", value: "name" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "adset", value: "adgroup_name", min_width: "150px" },
                        { name: "Key Results", value: "key_results", min_width: "150px" },
                        { name: "Cost Per Result", value: "cost_per_result", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "Average CTR", value: "ctr", min_width: "150px" },
                        { name: "Bid", value: "bid", min_width: "150px" },
                        { name: "Average CPM", value: "cpm", min_width: "150px" },
                        { name: "Average CPC", value: "cpc", min_width: "150px" },
                        { name: "Conversions", value: "conversions", min_width: "150px" },
                        { name: "Cost Per Conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "Leads", value: "leads", min_width: "150px" },
                        { name: "Cost Per Lead", value: "cost_per_lead", min_width: "150px" },
                        { name: "Event Registrations", value: "event_reg", min_width: "150px" },
                    ]
                },
                google: {
                    campaigns: [
                        { name: "campaign", value: "name" },
                        { name: "budget", value: "budget_value", min_width: "150px" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "serving status", value: "serving_status", min_width: "150px" },
                        { name: "campaign type", value: "advertising_type", min_width: "150px" },
                        { name: "optimisation score", value: "optimisation_score", min_width: "150px" },
                        { name: "conversions", value: "conversions", min_width: "150px" },
                        { name: "cost per conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "ctr", value: "ctr", min_width: "150px" },
                        { name: "avg. cpc", value: "cpc", min_width: "150px" },
                        { name: "cost", value: "spend", min_width: "150px" },
                        { name: "bid strategty type", value: "strategy_type", min_width: "150px" },
                        //{ name: "start date", value: "start_date" },
                        //{ name: "end date", value: "end_date" },

                    ],
                    adgroups: [
                        { name: "adgroup", value: "name" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "Adgroup type", value: "adgroup_type", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "conversions", value: "conversions", min_width: "150px" },
                        { name: "cost per conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "ctr", value: "ctr", min_width: "150px" },
                        { name: "avg. cpc", value: "cpc", min_width: "150px" },
                        { name: "cost", value: "spend", min_width: "150px" },
                    ],
                    ads: [
                        { name: "ad", value: "name" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "adgroup", value: "adgroup_name", min_width: "150px" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "ad strength", value: "ad_strength", min_width: "150px" },
                        { name: "ad type", value: "advertising_type", min_width: "150px" },
                        { name: "conversions", value: "conversions", min_width: "150px" },
                        { name: "cost per conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "ctr", value: "ctr", min_width: "150px" },
                        { name: "avg. cpc", value: "cpc", min_width: "150px" },
                        { name: "cost", value: "spend", min_width: "150px" },
                    ],
                    keywords: [
                        { name: "keyword", value: "keyword_text", min_width: "150px" },
                        { name: "match type", value: "match_type", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "adgroup", value: "adgroup_name", min_width: "150px" },
                        { name: "status", value: "status", min_width: "150px" },
                        { name: "approval status", value: "approval_status", min_width: "150px" },
                        { name: "conversions", value: "conversions", min_width: "150px" },
                        { name: "cost per conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "ctr", value: "ctr", min_width: "150px" },
                        { name: "avg. cpc", value: "cpc", min_width: "150px" },
                        { name: "cost", value: "spend", min_width: "150px" },
                    ],
                    negative_keywords: [
                        { name: "keyword", value: "keyword_text", min_width: "150px" },
                        { name: "match type", value: "match_type", min_width: "150px" },
                        { name: "level", value: "level", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "adgroup", value: "adgroup_name", min_width: "150px" },
                    ],
                    search_terms: [
                        { name: "search term", value: "text", min_width: "150px" },
                        { name: "added/excluded", value: "status", min_width: "150px" },
                        { name: "campaign", value: "campaign_name", min_width: "150px" },
                        { name: "adgroup", value: "adgroup_name", min_width: "150px" },
                        { name: "keyword", value: "keyword_text", min_width: "150px" },
                        { name: "match type", value: "match_type", min_width: "150px" },
                        { name: "conversions", value: "conversions", min_width: "150px" },
                        { name: "cost per conversion", value: "cost_per_conversion", min_width: "150px" },
                        { name: "clicks", value: "clicks", min_width: "150px" },
                        { name: "impressions", value: "impressions", min_width: "150px" },
                        { name: "ctr", value: "ctr", min_width: "150px" },
                        { name: "avg. cpc", value: "cpc", min_width: "150px" },
                        { name: "cost", value: "spend", min_width: "150px" },
                    ]
                }
            },
            clients: [],
            client: { id: 0, name: "Click here ..." },
            channel: { id: 0, name: "Click here ..." },
            campaign: { id: 0, name: "Click here ..." },
            campaigns: {},
            overview: null,
            tab: { name: "Campaigns", value: "campaigns" },
            page_limit: 10,
            page: 1,
            edit: false,
            create_new: false,
            selected: {},
            sort: "clicks",
            order: "ascending"
        }
    };

    componentDidMount() {
        this.functions.query();
    }

    functions = {
        query: async () => {
            let campaign = null;
            let client = null;
            let channel = null;
            let queries = window.location.search.replace("?", "").split("&");
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].indexOf("campaign=") !== -1) {
                    campaign = queries[i].replace("campaign=", "");
                }
                if (queries[i].indexOf("channel=") !== -1) {
                    channel = queries[i].replace("channel=", "");
                }
                if (queries[i].indexOf("client=") !== -1) {
                    client = queries[i].replace("client=", "");
                }
            }
            if (campaign && channel && client) {
                await this.promisedSetState({
                    channel: { name: channel, value: channel },
                    client: { id: client },
                    campaign: { id: campaign }
                });
                this.functions.overview();
            } else {
                this.functions.clients();
            }
        },
        clients: async (init) => {
            await this.promisedSetState({ loading_clients: true, loading: false });
            try {
                let response = await this.calls.clients();
                await this.promisedSetState({ clients: response.data });
            } catch (error) { }
            await this.promisedSetState({ loading_clients: false });
        },
        overview: async (channel) => {
            await this.promisedSetState({ loading_overview: true, overview: null });
            try {
                let response = await this.calls.optimize();
                if (response) {
                    await this.promisedSetState({ overview: response.data });
                }
            } catch (error) { }
            await this.promisedSetState({ loading_overview: false, loading: false });
        },
        overviewPartial: async (functions, tab_value) => {
            await this.promisedSetState({ loading_partial: tab_value ? tab_value : this.state.tab.value });
            try {
                let response = await this.calls.campaignOverview(functions);
                if (response) {
                    this.state.overview.channels[this.state.channel.value][(tab_value ? tab_value : this.state.tab.value)] = response.data.channels[this.state.channel.value][(tab_value ? tab_value : this.state.tab.value)];
                    await this.promisedSetState({ overview: this.state.overview });
                }
            } catch (error) { }
            await this.promisedSetState({ loading_partial: false });
        },
        remove: async () => {
            if (this.state.channel.value == "facebook") {
                this.functions.removeFacebook();
            } else if (this.state.channel.value == "google") {
                this.functions.removeGoogle();
            } else if (this.state.channel.value == "linkedin") {
                this.functions.removeGoogle();
            }

        },
        removeGoogle: async () => {
            if (this.state.tab.value == "campaigns") {
                let property_id = this.state.remove.resource_name;
                this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                    if (item.resource_name == property_id) {
                        item.loading = true;
                    }
                    return item;
                });
                await this.promisedSetState({ overview: this.state.overview, remove: null, remove_name: "" });
                try {
                    let response = null;
                    response = await this.calls.removeGoogleCampaign({
                        operations: [
                            { "remove": property_id }
                        ]
                    });
                    if (response && response.data) {
                        this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item) => {
                            return item.resource_name !== property_id
                        });
                        await this.promisedSetState({ overview: this.state.overview });
                    }
                } catch (error) {
                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                        if (item.resource_name == property_id) {
                            item.loading = false;
                        }
                        return item;
                    });
                    await this.promisedSetState({ overview: this.state.overview });
                }
            } else if (this.state.tab.value == "negative_keywords" || this.state.tab.value == "keywords") {
                let property_id = this.state.remove.resource_name;
                let property_type = this.state.tab.value == "negative_keywords" ? this.state.remove.level : "adgroup";
                this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                    if (item.resource_name == property_id) {
                        item.loading = true;
                    }
                    return item;
                });
                await this.promisedSetState({ overview: this.state.overview, remove: null, remove_name: "" });
                try {
                    let response = null;
                    if (property_type == "campaign") {
                        response = await this.calls.removeGoogleCampaignCriteria({
                            operations: [
                                { "remove": property_id }
                            ]
                        });
                    } else if (property_type == "adgroup") {
                        response = await this.calls.removeGoogleAdgroupCriteria({
                            operations: [
                                { "remove": property_id }
                            ]
                        });
                    }
                    if (response && response.data) {
                        this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item) => {
                            return item.resource_name !== property_id
                        });
                        await this.promisedSetState({ overview: this.state.overview });
                    }
                } catch (error) {
                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                        if (item.resource_name == property_id) {
                            item.loading = false;
                        }
                        return item;
                    });
                    await this.promisedSetState({ overview: this.state.overview });
                }
            } else if (this.state.tab.value == "ads") {
                let property_id = this.state.remove.adgroup_ad_resource_name;
                this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                    if (item.adgroup_ad_resource_name == property_id) {
                        item.loading = true;
                    }
                    return item;
                });
                await this.promisedSetState({ overview: this.state.overview, remove: null, remove_name: "" });
                try {
                    let response = null;
                    response = await this.calls.removeGoogleAd({
                        operations: [
                            { "remove": property_id }
                        ]
                    });
                    if (response && response.data) {
                        this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item) => {
                            return item.adgroup_ad_resource_name !== property_id
                        });
                        await this.promisedSetState({ overview: this.state.overview });
                    }
                } catch (error) {
                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                        if (item.adgroup_ad_resource_name == property_id) {
                            item.loading = false;
                        }
                        return item;
                    });
                    await this.promisedSetState({ overview: this.state.overview });
                }
            }
        },
        removeFacebook: async () => {
            let property_id = this.state.remove.id;
            this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                if (item.id == property_id) {
                    item.loading = true;
                }
                return item;
            });
            await this.promisedSetState({ overview: this.state.overview, remove: null, remove_name: "" });
            try {
                let response = await this.calls.removeFacebook(property_id);
                if (response && response.data) {
                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item) => {
                        return item.id !== property_id
                    });
                    await this.promisedSetState({ overview: this.state.overview });
                }
            } catch (error) {
                this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                    if (item.id == property_id) {
                        item.loading = false;
                    }
                    return item;
                });
                await this.promisedSetState({ overview: this.state.overview });
            }
        },
        removeLinkedin: async () => {
            let property_id = this.state.remove.id;
            this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                if (item.id == property_id) {
                    item.loading = true;
                }
                return item;
            });
            await this.promisedSetState({ overview: this.state.overview, remove: null, remove_name: "" });
            try {
                let response = await this.calls.removeFacebook(property_id);
                if (response && response.data) {
                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].filter((item) => {
                        return item.id !== property_id
                    });
                    await this.promisedSetState({ overview: this.state.overview });
                }
            } catch (error) {
                this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                    if (item.id == property_id) {
                        item.loading = false;
                    }
                    return item;
                });
                await this.promisedSetState({ overview: this.state.overview });
            }
        }
    };

    open = {
        facebook: (level, item) => {
            if (level === "campaigns") {
                window.open("https://business.facebook.com/adsmanager/manage/campaigns/edit?act=" + item.ad_account + "&business_id=" + item.business_manager + "&selected_campaign_ids=" + item.id, "_blank");
            } else if (level === "adgroups") {
                window.open("https://business.facebook.com/adsmanager/manage/adsets/edit?act=" + item.ad_account + "&business_id=" + item.business_manager + "&selected_campaign_ids=" + item.campaign_id + "&selected_adset_ids=" + item.id, "_blank");
            } else if (level === "ads") {
                window.open("https://business.facebook.com/adsmanager/manage/ads/edit?act=" + item.ad_account + "&business_id=" + item.business_manager + "&selected_campaign_ids=" + item.campaign_id + "&selected_adset_ids=" + item.adset_id + "&selected_ad_ids=" + item.id, "_blank");
            }
        },
        linkedin: (level, item) => {
            if (level === "campaigns") {
                window.open("https://www.linkedin.com/campaignmanager/accounts/" + item.account + "/campaign-groups/" + item.id, "_blank");
            } else if (level === "adgroups") {
                window.open("https://www.linkedin.com/campaignmanager/accounts/" + item.account + "/campaigns/" + item.id + "/details", "_blank");
            } else if (level === "ads") {
                window.open("https://www.linkedin.com/campaignmanager/accounts/" + item.account + "/campaigns/" + item.adgroup + "/creatives/" + item.id + "?action=edit", "_blank");
            }
        },
        twitter: (level, item) => {
            if (level === "campaigns") {
                window.open("https://ads.twitter.com/campaign_form/" + item.account + "/campaign/" + item.ui_id + "/edit", "_blank");
            } else if (level === "adgroups") {
                window.open("https://ads.twitter.com/campaign_form/" + item.account + "/campaign/" + item.campaign + "/adgroup/" + item.ui_id + "/adgroup_details/edit", "_blank");
            } else if (level === "ads") {
                window.open("https://ads.twitter.com/campaign_form/" + item.account + "/campaign/" + item.campaign + "/adgroup/" + item.adgroup + "/creative_form/" + item.index + "/edit", "_blank");
            }
        },
        tiktok: (level, item) => {
            if (level === "campaigns") {
                window.open("https://ads.tiktok.com/i18n/perf?aadvid=" + item.account + "&keyword=" + item.id + "", "_blank");
            } else if (level === "adgroups") {
                window.open("https://ads.tiktok.com/i18n/perf/adgroup?aadvid=" + item.account + "&keyword=" + item.id + "&search_type=2", "_blank");
            } else if (level === "ads") {
                window.open("https://ads.tiktok.com/i18n/perf/creative?aadvid=" + item.account + "&keyword=" + item.id + "&search_type=3", "_blank");
            }
        }
    };

    calls = {
        clients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients";
            return apiRegister.call(options, url);
        },
        optimize: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/optimize?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        campaignOverview: (functions) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/campaignOverview?client=" + this.state.client.id + "&channel=" + this.state.channel.value + "&campaign=" + this.state.campaign.id + (functions ? functions : "");
            return apiRegister.call(options, url);
        },
        removeFacebook: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/facebook/remove?client=" + this.state.client.id + "&id=" + id;
            return apiRegister.call(options, url);
        },
        removeGoogleCampaignCriteria: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeCampaignCriteria?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeGoogleAdgroupCriteria: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeAdgroupCriteria?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeGoogleAd: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeAd?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        removeGoogleCampaign: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google/removeCampaign?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        creativeFacebook: (item) => {
            let creative = null;
            try {
                if (item.creative.object_story_spec.video_data) {
                    creative = item.creative.object_story_spec.video_data.image_url;
                }
            } catch (eror) { }
            try {
                if (item.creative.object_story_spec.link_data.picture) {
                    creative = item.creative.object_story_spec.link_data.picture;
                }
            } catch (eror) { }
            try {
                if (Array.isArray(item.creative.object_story_spec.link_data.child_attachments)) {
                    creative = item.creative.object_story_spec.link_data.child_attachments;
                }
            } catch (eror) { }
            return creative;
        },
        typeFacebook: (item) => {
            let type = null;
            try {
                if (item.creative.object_story_spec.video_data) {
                    type = "video";
                }
            } catch (eror) { }
            try {
                if (item.creative.object_story_spec.link_data.picture || item.creative.object_story_spec.link_data.image_hash) {
                    type = "image";
                }
            } catch (eror) { }
            try {
                if (Array.isArray(item.creative.object_story_spec.link_data.child_attachments)) {
                    type = "carousel";
                }
            } catch (eror) { }
            return type;
        },
        sort: (list) => {

            let sortNumber = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sort] ? x.row_values[this.state.sort] : 0) : (x[this.state.sort] ? x[this.state.sort] : 0);
                    let y_value = y.row_values ? (y.row_values[this.state.sort] ? y.row_values[this.state.sort] : 0) : (y[this.state.sort] ? y[this.state.sort] : 0);
                    if (this.state.order == 'descending') {
                        return y_value - x_value;
                    } else {
                        return x_value - y_value;
                    }
                });
            }

            let sortCharacter = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sort] ? x.row_values[this.state.sort] : "") : (x[this.state.sort] ? x[this.state.sort] : "");
                    let y_value = y.row_values ? (y.row_values[this.state.sort] ? y.row_values[this.state.sort] : "") : (y[this.state.sort] ? y[this.state.sort] : "");
                    let a = x_value.toUpperCase(),
                        b = y_value.toUpperCase();
                    if (this.state.order == 'descending') {
                        return a == b ? 0 : a > b ? -1 : 1;
                    } else {
                        return a == b ? 0 : a > b ? 1 : -1;
                    }
                });
            }

            let sortDate = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sort] ? x.row_values[this.state.sort] : new Date()) : (x[this.state.sort] ? x[this.state.sort] : new Date());
                    let y_value = y.row_values ? (y.row_values[this.state.sort] ? y.row_values[this.state.sort] : new Date()) : (y[this.state.sort] ? y[this.state.sort] : new Date());
                    let a = new Date(x_value),
                        b = new Date(y_value);
                    if (this.state.order == 'descending') {
                        return a - b;
                    } else {
                        return b - a;
                    }
                });
            }

            if (this.state.sort === "name" || this.state.sort === "status") {
                return sortCharacter(list);
            } else if (this.state.sort === "start_date" || this.state.sort === "end_date") {
                return sortDate(list);
            } else {
                return sortNumber(list);
            }

        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        let row_campaign = (item) => {
            return (
                <td className={"px-4 sm:px-6 py-4 whitespace-nowrap border-r-1.5"}>
                    <div style={{ whiteSpace: "nowrap" }} className="flex flex-row">
                        <div className="h-10 w-10 rounded-full flex justify-center items-center bg-gray-100">
                            <FolderIcon className="h-5 w-5 text-gray-700" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{item.campaign_name ? item.campaign_name : item.name}</p>
                            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">CAMPAIGN #{item.campaign_id ? item.campaign_id : item.id}</p>
                        </div>
                    </div>
                </td>
            )
        }

        let row_adgroup = (item) => {
            return (
                <td className={"px-4 sm:px-6 py-4 whitespace-nowrap border-r-1.5"}>
                    <div style={{ whiteSpace: "nowrap" }} className="flex flex-row">
                        <div className="h-10 w-10 rounded-full flex justify-center items-center bg-gray-100">
                            <ViewGridIcon className="h-5 w-5 text-gray-700" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{item.adset_name ? item.adset_name : item.name}</p>
                            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">ADSET #{item.adset_id ? item.adset_id : item.id}</p>
                        </div>
                    </div>
                </td>
            )
        }

        let row_ad_facebook = (item) => {
            return (
                <td className={"px-4 sm:px-6 py-4 whitespace-nowrap border-r-1.5"}>
                    <div style={{ whiteSpace: "nowrap" }} className="flex items-center flex-row">
                        <div>
                            {
                                this.renders.typeFacebook(item) == "carousel" &&
                                <div className="flex -space-x-4 relative z-0 overflow-hidden">
                                    {
                                        this.renders.creativeFacebook(item).map((item) => {
                                            return (
                                                <img
                                                    className="relative z-30 inline-block h-10 w-10 rounded-full ring-2 ring-white"
                                                    src={item.picture}
                                                    alt=""
                                                />
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                this.renders.typeFacebook(item) == "image" &&
                                <div className="flex items-center justify-center h-10 w-10 rounded-full overflow-hidden border bg-gray-100">
                                    <img
                                        className="w-full"
                                        src={this.renders.creativeFacebook(item)}
                                        alt=""
                                    />
                                </div>
                            }
                            {
                                this.renders.typeFacebook(item) == "video" &&
                                <div className="flex items-center justify-center h-10 w-10 rounded-full overflow-hidden border bg-gray-100">
                                    <video poster={this.renders.creativeFacebook(item)} controls={false} loop={true} autoPlay={true} muted={true} style={{ width: '100%', height: '100%' }}>
                                        <source src={""} type="video/mp4" />
                                    </video>
                                </div>
                            }
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{item.name}</p>
                            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700"><span className="uppercase">{this.renders.typeFacebook(item)}</span> #{item.id}</p>
                        </div>
                    </div>
                </td>
            )
        }

        let row_ad_google = (item) => {
            return (
                <td className={"px-4 sm:px-6 py-4 whitespace-nowrap border-r-1.5"}>
                    <div style={{ maxWidth: "250px" }} className="">
                        <div className="flex flex-row text-xxs text-purple-500 flex-wrap">
                            {item.headlines.filter((item, index) => { return index < 3 }).map((item) => { return item.text }).join(" | ") + (item.headlines.length > 3 ? (" +" + (item.headlines.length - 3)) : "")}
                        </div>
                        {
                            Array.isArray(item.final_urls) && item.final_urls.length > 0 &&
                            <div className="flex truncate text-xxs text-green-600">
                                {item.final_urls[0]}
                            </div>
                        }
                        <div className="flex flex-row truncate">
                            <div className="text-xxs truncate">
                                {item.descriptions.map((item) => { return item.text }).join(". ")}
                            </div>
                        </div>
                    </div>
                </td>
            )
        }

        let row_ad_linkedin = (item) => {
            return (
                <td className={"px-4 sm:px-6 py-4 whitespace-nowrap border-r-1.5"}>

                </td>
            )
        }

        let row_default = (value, property, last, min_width) => {
            if (property == "start_time" || property == "stop_time") {
                value = value ? moment(value).format("YYYY-MM-DD") : "No date";
            } else if (property == "effective_status" && value) {
                value = value.replace("_", " ");
            }
            return (
                <td style={min_width ? { minWidth: min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }} className={(this.state.sort === property ? "bg-gray-100 " : "") + (!last ? " border-r-1.5" : "") + " px-4 sm:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"}>
                    {value}
                </td>
            )
        }

        return (
            <div className="relative h-full">

                <TopNavigationAgent
                    onButton={(type) => {

                    }}
                    breadcrumb={"Optimize"}
                    history={this.props.history}
                />


                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading campaign ...</div>
                    </div>
                }

                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={this.state.remove_name != "123456"}
                    showInput={true}
                    inputTitle={"Type 123456 to remove"}
                    inputError={(this.state.remove_name != "123456") ? "Name is not matching" : false}
                    onClose={(value) => {
                        this.setState({
                            remove: null,
                            remove_name: ""
                        })
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove_name == "123456") {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {
                    this.state.channel.id !== 0 && this.state.tab.id !== 0 && this.state.overview &&
                    <SlideoutTailwind
                        title={"Update"}
                        large={true}
                        submitButtonText={"Save"}
                        secondaryButton={this.state.channel.value === "facebook" && this.state.tab.value === "ads"}
                        secondaryButtonText={"Create new"}
                        loader={this.state.edit_loader}
                        loaderSecondary={this.state.duplicate_loader}
                        open={(this.state.selected.id || this.state.create_new) ? true : false}
                        error={this.state.slideoutError}
                        onError={(value) => {
                            this.setState({
                                slideoutError: value
                            })
                        }}
                        success={this.state.slideoutSuccess}
                        onSuccess={(value) => {
                            this.setState({
                                slideoutSuccess: value
                            })
                        }}
                        onCancel={() => {
                            this.setState({
                                selected: {},
                                create_new: null,
                                selected_search_term: null
                            })
                        }}
                        onSubmit={() => {
                            if (this.state.channel.value === "facebook") {
                                if (this.state.tab.value === "campaigns") {
                                    this.refs.EditFacebookCampaign.functions.update();
                                } else if (this.state.tab.value === "adgroups") {
                                    this.refs.EditFacebookAdset.functions.update();
                                } else if (this.state.tab.value === "ads") {
                                    this.refs.EditFacebookAd.functions.update();
                                }
                            } else if (this.state.channel.value == "google") {
                                if (this.state.tab.value === "campaigns") {
                                    this.refs.EditGoogleCampaign.functions.update();
                                } else if ((this.state.tab.value === "negative_keywords" || this.state.tab.value === "search_terms") && this.state.create_new) {
                                    this.refs.CreateGoogleNegativeKeyword.functions.create();
                                } else if (this.state.tab.value === "keywords" && this.state.create_new) {
                                    this.refs.CreateGoogleKeyword.functions.create();
                                } else if (this.state.tab.value === "ads") {
                                    this.refs.EditGoogleAd.functions.update();
                                }
                            } else if (this.state.channel.value === "linkedin") {
                                if (this.state.tab.value == "campaigns") {
                                    this.refs.EditLinkedinCampaign.functions.update();
                                } else if (this.state.tab.value === "adgroups") {
                                    this.refs.EditLinkedinAdgroup.functions.update();
                                }
                            }
                        }}
                        onSecondarySubmit={() => {
                            if (this.state.channel.value === "facebook" && this.state.tab.value === "ads") {
                                this.refs.EditFacebookAd.functions.update(true);
                            }
                        }}
                    >

                        {/* LINKEDIN */}
                        {
                            this.state.channel.value === "linkedin" && this.state.tab.value === "campaigns" &&
                            <EditLinkedinCampaign
                                ref="EditLinkedinCampaign"
                                client={this.state.client}
                                campaign={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    /*
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                    */
                                }}
                            />
                        }
                        {
                            this.state.channel.value === "linkedin" && this.state.tab.value === "adgroups" &&
                            <EditLinkedinAdgroup
                                ref="EditLinkedinAdgroup"
                                client={this.state.client}
                                adgroup={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    /*
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                    */
                                }}
                            />
                        }

                        {/* FACEBOOK */}
                        {
                            this.state.channel.value == "facebook" && this.state.tab.value == "campaigns" &&
                            <EditFacebookCampaign
                                ref="EditFacebookCampaign"
                                client={this.state.client}
                                campaign={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                }}
                            />
                        }
                        {
                            this.state.channel.value == "facebook" && this.state.tab.value == "adgroups" &&
                            <EditFacebookAdset
                                ref="EditFacebookAdset"
                                client={this.state.client}
                                adset={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                }}
                            />
                        }
                        {
                            this.state.channel.value == "facebook" && this.state.tab.value == "ads" &&
                            <EditFacebookAd
                                ref="EditFacebookAd"
                                client={this.state.client}
                                ad={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onLoaderSecondary={(value) => { this.setState({ duplicate_loader: value }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                }}
                                onAdd={(value) => {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value].push(value);
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                }}
                            />
                        }

                        {/* GOOGLE */}
                        {
                            this.state.channel.value == "google" && this.state.tab.value == "campaigns" &&
                            <EditGoogleCampaign
                                ref="EditGoogleCampaign"
                                client={this.state.client}
                                campaign={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                }}
                            />
                        }
                        {
                            this.state.channel.value == "google" && this.state.tab.value == "ads" &&
                            <EditGoogleAd
                                ref="EditGoogleAd"
                                client={this.state.client}
                                ad={this.state.selected}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onSubmit={(value) => { this.setState({ selected: {} }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onUpdate={(value) => {
                                    /*
                                    this.state.overview.channels[this.state.channel.value][this.state.tab.value] = this.state.overview.channels[this.state.channel.value][this.state.tab.value].map((item) => {
                                        if (item.id == value.id) {
                                            item = value;
                                        }
                                        return item;
                                    });
                                    this.setState({
                                        overview: this.state.overview
                                    })
                                    */
                                }}
                            />
                        }
                        {
                            this.state.channel.value == "google" && (this.state.tab.value == "negative_keywords" || this.state.tab.value == "search_terms") &&
                            <CreateGoogleNegativeKeyword
                                ref="CreateGoogleNegativeKeyword"
                                client={this.state.client}
                                searchTerm={this.state.selected_search_term}
                                campaigns={(this.state.overview.channels[this.state.channel.value] && Array.isArray(this.state.overview.channels[this.state.channel.value].campaigns)) ? this.state.overview.channels[this.state.channel.value].campaigns : []}
                                adgroups={(this.state.overview.channels[this.state.channel.value] && Array.isArray(this.state.overview.channels[this.state.channel.value].adgroups)) ? this.state.overview.channels[this.state.channel.value].adgroups : []}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onCreate={async (value) => {
                                    await this.promisedSetState({
                                        selected: {},
                                        selected_search_term: null,
                                        create_new: false,
                                        overview: this.state.overview
                                    });
                                    if (this.state.tab.value == "negative_keywords") {
                                        this.functions.overviewPartial("&functions=negativeadgroupkeywords,negativecampaignkeywords");
                                    }
                                }}
                            />
                        }
                        {
                            this.state.channel.value == "google" && this.state.tab.value == "keywords" &&
                            <CreateGoogleKeyword
                                ref="CreateGoogleKeyword"
                                client={this.state.client}
                                adgroups={(this.state.overview.channels[this.state.channel.value] && Array.isArray(this.state.overview.channels[this.state.channel.value].adgroups)) ? this.state.overview.channels[this.state.channel.value].adgroups : []}
                                onLoader={(value) => { this.setState({ edit_loader: value }) }}
                                onError={(value) => { this.setState({ slideoutError: value }) }}
                                onSuccess={(value) => { this.setState({ slideoutSuccess: value }) }}
                                onCreate={async (value) => {
                                    await this.promisedSetState({
                                        selected: {},
                                        create_new: false,
                                        overview: this.state.overview
                                    });
                                    this.functions.overviewPartial("&functions=keywords");
                                }}
                            />
                        }
                    </SlideoutTailwind>
                }

                {
                    !this.state.loading &&
                    <div className="bg-white border-b">
                        <div className="p-6 pt-4">
                            <div className="grid grid-cols-12 gap-4">
                                <div className="col-span-12">
                                    <DropdownTailwind
                                        label={"Client"}
                                        searchInput={true}
                                        selected={this.state.client}
                                        loader={this.state.loading_clients}
                                        options={Array.isArray(this.state.clients) ? this.state.clients.map((item) => { item.image = item.logo; return item }) : []}
                                        onChange={async (value) => {
                                            await this.promisedSetState({
                                                client: value
                                            });
                                            this.functions.overview();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*LOADING VIEW*/}
                {
                    this.state.loading_overview &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading campaigns ...</div>
                    </div>
                }

                {/* CAMPAIGNS OVERVIEW */}
                {
                    //!this.state.loading && !this.state.loading_overview && this.state.client.id !== 0 &&
                    <div className="p-4">
                        <div className="flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full">
                                            <thead className="bg-white">
                                                <tr>
                                                    <th style={{ width: "100px" }} scope="col" className="relative p-4">
                                                        <span className="sr-only">Options</span>
                                                    </th>
                                                    <th scope="col" className="p-4 py-3 text-sm text-left font-semibold text-gray-900">
                                                        Campaign name
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {
                                                    channels.map((channel) => (
                                                        <Fragment key={channel.name}>
                                                            <tr className="border-t border-gray-200">
                                                                <td
                                                                    className="bg-gray-50 p-4 py-3 text-left text-sm font-semibold text-gray-900"
                                                                >
                                                                    {channel.name}
                                                                </td>
                                                                <td
                                                                    className="bg-gray-50 p-4 py-3 text-left text-sm font-semibold text-gray-900"
                                                                >
                                                                    <div className="bg-red-500 flex flex-row justify-end">
                                                                        adada
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {
                                                                channel.campaigns.map((campaign, personIdx) => (
                                                                    <tr
                                                                        key={campaign.id}
                                                                        className={cn(personIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                                                    >
                                                                        <td style={{ width: "100px" }} className="relative whitespace-nowrap p-4 py-3 text-left text-sm font-medium sm:pr-6">
                                                                            <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                                                Edit<span className="sr-only">, {campaign.name}</span>
                                                                            </a>
                                                                        </td>
                                                                        <td className="whitespace-nowrap p-4 py-3 text-left text-sm font-medium text-gray-900">
                                                                            {campaign.name}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </Fragment>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default Optimize;
