import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static createRequest(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data);
        let url = calls.url.api + "/request";
        return apiRegister.call(options, url);
    }

    static uploadImage(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
        let url = calls.url.api + "/image";
        return apiRegister.call(options, url);
    }

    static uploadVideo(data) {
        let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
        let url = calls.url.api + "/video?dummy=true";
        return apiRegister.call(options, url);
    }

}

export {calls}