import React, { Component } from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import { SlideDown } from 'react-slidedown'
import { dataRegister } from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import { BeatLoader } from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import { calls } from "./calls";
import { tokenRegister } from "../../services/tokenRegister";
import ClientFacebookPixel from "../client-facebook-pixel";
import ClientGooglePixel from "../client-google-pixel";
import ClientFacebookAdaccount from "../client-facebook-adaccount";
import DropdownTailwind from '../../moduleFiles/dropdownTailwind';

class ClientGoogleAnalytics extends Component {

    googleSDK = () => {
        window['googleSDKLoaded'] = () => {
            window['gapi'].load('auth2', () => {
                window.test_auth2 = window['gapi'].auth2.init({
                    client_id: '72023551936-ei41vqh9tbve3iotrl3e00ou5oku3fpr.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin',
                    scope: 'https://www.googleapis.com/auth/analytics.readonly'
                });
                //console.log(window.test_auth2);
            });
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
    };

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            token: null,
            open: false,
            loading: false,
            managers: [],
            clients: [],
            profiles: [],
            manager: {},
            client: {},
            profile: {},
            managers_loading: false,
            clients_loading: false,
            submenu: 0
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        }, () => {
            this.googleSDK();
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item ? nextProps.item : {}
        });
    }

    init = {};

    functions = {
        login: () => {
            let self = this;
            if (!self.state.loading) {
                self.setState({
                    loading: true
                }, () => {
                    window.test_auth2.grantOfflineAccess().then(res => {
                        calls.getToken({ code: res.code }).then((response) => {
                            //console.log(response);
                            self.setState({
                                token: response.data,
                                loading: false
                            }, () => {
                                self.functions.accounts();
                            })
                        }, (error) => {
                            self.setState({
                                loading: false
                            })
                        });
                    }).catch(function (error) {
                        console.log(error);
                        self.setState({
                            loading: false
                        })
                    })
                })
            }
        },
        logout: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    remove_modal: false
                }, () => {
                    calls.remove(this.state.item.id).then((response) => {
                        delete this.state.item.google_analytics;
                        this.setState({
                            item: this.state.item,
                            loading: false
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        create: () => {
            if (!this.state.loading_create) {
                this.setState({
                    loading_create: true
                }, () => {
                    calls.create({
                        client: this.state.item.id,
                        account: this.state.manager,
                        property: this.state.client,
                        profile: this.state.profile,
                        token: this.state.token
                    }).then((response) => {
                        this.state.item.google_analytics = response.data;
                        this.setState({
                            token: null,
                            customer: "",
                            manager: "",
                            item: this.state.item,
                            loading_create: false,
                            open: false
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading_create: false
                        })
                    });
                })
            }
        },
        accounts: () => {
            this.setState({
                managers_loading: true
            }, () => {
                calls.getAccounts({ token: this.state.token }).then((response) => {
                    this.setState({
                        user: response.data.user,
                        managers: response.data.accounts,
                        managers_loading: false
                    })
                }, (error) => {
                    console.log(error);
                    this.setState({
                        managers_loading: false
                    })
                });
            })
        },
        properties: () => {
            this.setState({
                clients_loading: true
            }, () => {
                calls.getProperties({ token: this.state.token, id: this.state.manager.id }).then((response) => {
                    this.setState({
                        clients: response.data.properties,
                        clients_loading: false
                    })
                }, (error) => {
                    this.setState({
                        clients_loading: false
                    })
                });
            })
        },
        profiles: () => {
            this.setState({
                profiles_loading: true
            }, () => {
                calls.getProfiles({ token: this.state.token, account_id: this.state.manager.id, property_id: this.state.client.id }).then((response) => {
                    this.setState({
                        profiles: response.data,
                        profiles_loading: false
                    })
                }, (error) => {
                    this.setState({
                        profiles_loading: false
                    })
                });
            })
        }
    };

    renders = {
        submenus: () => {
            let array = [
                { id: 0, name: "Add adaccount" }
            ];
            return array;
        }
    };

    render() {
        return (
            <div>
                <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                    <ul className="flex flex-row items-end">
                        {this.renders.submenus().map((item, j) => {
                            return (
                                <li key={j}
                                    className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                    onClick={() => {
                                        this.setState({
                                            submenu: item.id
                                        });
                                    }}>
                                    {item.name}
                                </li>
                            )
                        })
                        }
                        <li className="flex-grow border-b-2 border-gray-200"></li>
                    </ul>
                </nav>
                <SweetAlert
                    show={this.state.remove_modal}
                    title="Signout Google Analytics"
                    type="warning"
                    text={"You wont be able access any Google campaigns created with this account"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.logout();
                    }}
                    onCancel={() => {
                        this.setState({ remove_modal: false });
                    }}
                />
                {
                    this.state.submenu === 0 &&
                    <div className="bg-white rounded-md px-4 py-4">
                        {
                            !this.state.item.google_analytics && !this.state.token && false &&
                            <div className="Section">
                                <div onClick={() => this.functions.login()} className="Button ButtonFull ButtonGoogle">
                                    {
                                        !this.state.loading &&
                                        <div>login Google Analytics</div>
                                    }
                                    {
                                        this.state.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {
                            !this.state.item.google_analytics && !this.state.token &&
                            <div className="w-full justify-center align-middle items-center flex">
                                <img onClick={() => this.functions.login()} style={{ width: "200px" }} className="cursor-pointer" src={require("../..//assets/images/google_signin_button.png")} />
                            </div>
                        }
                        {
                            false &&
                            this.state.token && !this.state.item.google_analytics &&
                            <div className="Section" style={{ paddingTop: '0px' }}>
                                <Dropdown
                                    filter={true}
                                    title={'Select manager'}
                                    size={'full'}
                                    //searchList={true}
                                    placeholder={(!this.state.managers_loading && this.state.managers.length < 1) ? "No managers available" : (this.state.managers_loading ? "Loading managers" : "Click here ..")}
                                    error={this.state.error === "manager" && !this.state.manager.name}
                                    options={this.state.managers}
                                    loading={this.state.managers_loading}
                                    removeArrow={!this.state.managers_loading && this.state.managers.length < 1}
                                    value={this.state.manager}
                                    onChange={(value) => this.setState({ manager: value, client: {} }, () => {
                                        this.functions.properties();
                                    })}
                                />
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.google_analytics &&
                            <DropdownTailwind
                                searchInput={true}
                                label={"Select manager"}
                                selected={this.state.manager}
                                options={this.state.managers}
                                placeholder={"Search managers"}
                                loader={this.state.managers_loading}
                                onChange={(value) => {
                                    this.setState({
                                        manager: value,
                                        client: {}
                                    }, () => {
                                        this.functions.properties();
                                    })
                                }}
                            />
                        }
                        {
                            this.state.item.google_analytics &&
                            <div className="Section" style={{ paddingTop: '0px' }}>
                                <Input
                                    title={'Account'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.google_analytics.accountName + ' - ' + this.state.item.google_analytics.accountId}
                                    size={'full'}
                                />
                            </div>
                        }
                        {
                            false &&
                            this.state.token && !this.state.item.google_analytics && this.state.manager && this.state.manager.name &&
                            <div className="Section">
                                <Dropdown
                                    filter={true}
                                    title={'Select client'}
                                    size={'full'}
                                    placeholder={(!this.state.clients_loading && this.state.clients.length < 1) ? "No clients available" : (this.state.clients_loading ? "Loading clients" : "Click here ..")}
                                    error={this.state.error === "client" && !this.state.client.name}
                                    options={this.state.clients}
                                    loading={this.state.clients_loading}
                                    removeArrow={!this.state.clients_loading && this.state.clients.length < 1}
                                    value={this.state.client}
                                    onChange={(value) => this.setState({ client: value }, () => {
                                        this.functions.profiles();
                                    })}
                                />
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.google_analytics && this.state.manager && this.state.manager.name &&
                            <div className="mt-4">
                                <DropdownTailwind
                                    searchInput={true}
                                    label={"Select client"}
                                    selected={this.state.client}
                                    options={this.state.clients}
                                    placeholder={"Search clients"}
                                    loader={this.state.clients_loading}
                                    onChange={(value) => {
                                        this.setState({
                                            client: value
                                        }, () => {
                                            this.functions.profiles();
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.item.google_analytics &&
                            <div className="Section">
                                <Input
                                    title={'Manager account'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.google_analytics.propertyName + ' - ' + this.state.item.google_analytics.propertyId}
                                    size={'full'}
                                />
                            </div>
                        }
                        {
                            false &&
                            this.state.token && !this.state.item.google_analytics && this.state.client && this.state.client.name && this.state.manager && this.state.manager.name &&
                            <div className="Section">
                                <Dropdown
                                    filter={true}
                                    title={'Select profile (optional)'}
                                    size={'full'}
                                    placeholder={(!this.state.profiles_loading && this.state.profiles.length < 1) ? "No profiles available" : (this.state.profiles_loading ? "Loading profiles" : "Click here ..")}
                                    error={this.state.error === "profile" && !this.state.profile.name}
                                    options={this.state.profiles}
                                    loading={this.state.profiles_loading}
                                    removeArrow={!this.state.profiles_loading && this.state.profiles.length < 1}
                                    value={this.state.profile}
                                    onChange={(value) => this.setState({ profile: value }, () => {
                                        //this.functions.profiles();
                                    })}
                                />
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.google_analytics && this.state.client && this.state.client.name && this.state.manager && this.state.manager.name &&
                            <div className="mt-4">
                                <DropdownTailwind
                                    searchInput={true}
                                    label={"Select profile (optional)"}
                                    selected={this.state.profile}
                                    options={this.state.profiles}
                                    placeholder={"Search profiles"}
                                    loader={this.state.profiles_loading}
                                    onChange={(value) => {
                                        this.setState({
                                            profile: value
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.item.google_analytics && this.state.item.google_analytics.profileId &&
                            <div className="Section">
                                <Input
                                    title={'Profile account'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.google_analytics.profileId + ' - ' + this.state.item.google_analytics.profileName}
                                    size={'full'}
                                />
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.google_analytics && this.state.manager.id && this.state.client.id &&
                            <div className="Section">
                                <div onClick={() => this.functions.create()} className="Button ButtonFull">
                                    {
                                        !this.state.loading_create &&
                                        <div>add Google Analytics</div>
                                    }
                                    {
                                        this.state.loading_create &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        <div>
                            {
                                this.state.item.google_analytics &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        remove_modal: true
                                    })} className="Button ButtonFull ButtonGoogle">
                                        {
                                            !this.state.loading &&
                                            <div>signout Google Analytics</div>
                                        }
                                        {
                                            this.state.loading &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={'#ffffff'}
                                                loading={true}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.token && !this.state.item.google_analytics &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        token: null,
                                        manager: {
                                            input: "",
                                            checked: false,
                                            loading: false,
                                            data: {}
                                        },
                                        client: {
                                            input: "",
                                            checked: false,
                                            loading: false,
                                            data: {}
                                        }
                                    })} className="Button ButtonFull ButtonGoogle">
                                        signout Google
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ClientGoogleAnalytics;
