import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import Input from "../input";
import Dropdown from "../dropdown";
import phone_codes from '../../assets/json/phone_codes';
import FeatherIcon from "feather-icons-react";
import {SlideDown} from "react-slidedown";

class AdvertisingCreativesCall extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: {},
            campaign: {},
            phone_code: {},
            number: "",
            phone_codes: phone_codes,
            new: true
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            search: nextProps.search,
            campaign: nextProps.campaign
        })
    }

    componentDidMount() {
        this.setState({
            search: this.props.search,
            campaign: this.props.campaign
        }, () => {
            if(this.state.search && Array.isArray(this.state.search.phone_numbers)){
                this.setState({
                    new: this.state.search.phone_numbers.length < 1
                })
            }
        })
    }

    init = {};

    functions = {
        remove: (item) => {
            this.state.search.phone_numbers =  this.state.search.phone_numbers.filter((inner_item) => {
                return inner_item.id !== item.id
            });
            this.setState({
                search: this.state.search,
                new: true
            }, () => {
                this.props.onChange(this.state.search);
            })
        }
    };

    validators = {};

    renders = {
        options: () => {
            let codes = [];
            for(let key in this.state.phone_codes){
                codes.push({
                    name: '+' + this.state.phone_codes[key] + " (" +key+")",
                    country: key,
                    value: this.state.phone_codes[key]
                });
            }
            return codes;
        },
        characters: (item) => {
            try{
                return item.toString().length
            }catch (e) {
                return 0
            }
        }
    };

    render() {
        return (
            <div className="mt-4">
                {
                    <div className="bg-white rounded-md">
                        <div className="px-4 py-4 flex-row flex">
                            <div className="flex flex-1 flex-col">
                                <h3 className="mb-0 font-bold">
                                    Call extension
                                </h3>
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Avg. number of search per month in parantheses
                                </p>
                            </div>
                            <div className="flex flex-row">
                                {
                                    Array.isArray(this.state.search.phone_numbers) && this.state.search.phone_numbers.length > 0 &&
                                    <div className="bg-gray-200 text-gray-900 h-10 w-10 mr-2 flex justify-center items-center rounded font-bold text-sm">
                                        {Array.isArray(this.state.search.phone_numbers) && this.state.search.phone_numbers.length}
                                    </div>
                                }
                                <button className="bg-gray-200 text-gray-900 h-10 w-10 flex justify-center items-center rounded"
                                        onClick={() => {
                                            this.setState({
                                                call: !this.state.call
                                            })
                                        }}>
                                    {
                                        !this.state.call &&
                                        <FeatherIcon className="stroke-current" size={16} icon={"chevron-down"}/>
                                    }
                                    {
                                        this.state.call &&
                                        <FeatherIcon className="stroke-current" size={16} icon={"chevron-up"}/>
                                    }
                                </button>
                            </div>
                        </div>
                        <SlideDown closed={!this.state.call}>
                            <div className="w-full border-t"/>
                            {
                                Array.isArray(this.state.search.phone_numbers) && this.state.search.phone_numbers.length > 0 &&
                                <div className="p-4">
                                    {
                                        this.state.search.phone_numbers.filter((item) => {
                                            return item.campaign_id === this.state.campaign.id
                                        }).map((item, index) => {
                                            return(
                                                <div className="flex flex-row flex-1 items-center mb-2 bg-white px-4 py-3 py-1 rounded-md border">
                                                    <div className="flex flex-1 flex-col">
                                                        <div className="text-sm font-bold">+{item.code}<span className="ml-1 font-medium">{item.number}</span></div>
                                                    </div>
                                                    <div className="flex flex-1"></div>
                                                    <button className="bg-red-200 text-red-500 ml-3 h-10 w-10 flex justify-center items-center rounded" onClick={() => {this.functions.remove(item);}}>
                                                        <FeatherIcon className="stroke-current" size={16} icon={'trash'}/>
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {
                                this.state.new &&
                                <div className="flex flex-1 flex-col bg-white rounded-md px-4 py-4">
                                    <div className="flex flex-row">
                                        <div className="flex flex-1 pr-1">
                                            <Dropdown
                                                filter={true}
                                                white={true}
                                                searchFilter={false}
                                                search={false}
                                                error={this.state.code_error && !this.state.phone_code.name}
                                                disabled={Array.isArray(this.state.search.phone_numbers) && this.state.search.phone_numbers.filter((item) => {
                                                    return item.campaign_id === this.state.campaign.id
                                                }).length > 0}
                                                noTitle={false}
                                                title={"Country phone code"}
                                                size={'full'}
                                                removeArrow={Array.isArray(this.state.search.phone_numbers) && this.state.search.phone_numbers.filter((item) => {
                                                    return item.campaign_id === this.state.campaign.id
                                                }).length > 0}
                                                placeholder={"Select phone code"}
                                                options={this.renders.options()}
                                                value={this.state.phone_code?this.state.phone_code:{}}
                                                onChange={(value) => {
                                                    console.log(value);
                                                    this.setState({
                                                        phone_code: value
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="flex flex-1 flex-row pl-1">
                                            <div className="flex flex-1">
                                                <Input
                                                    noTitle={false}
                                                    white={true}
                                                    value={this.state.phone_number}
                                                    error={this.state.number_error && (!this.state.phone_number || this.state.phone_number === "")}
                                                    placeholder={"Phone number"}
                                                    disabled={Array.isArray(this.state.search.phone_numbers) && this.state.search.phone_numbers.filter((item) => {
                                                        return item.campaign_id === this.state.campaign.id
                                                    }).length > 0}
                                                    title={"Phone number"}
                                                    size={'full'}
                                                    type={"text"}
                                                    onChange={(value) => {
                                                        console.log(value);
                                                        this.setState({
                                                            phone_number: value.target.value
                                                        })
                                                    }}
                                                    showKeyDown={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-1 justify-end mt-3">
                                        <div>
                                            <button onClick={() => {
                                                if(!this.state.search.phone_numbers || (Array.isArray(this.state.search.phone_numbers) && this.state.search.phone_numbers.filter((item) => {
                                                    return item.campaign_id === this.state.campaign.id
                                                }).length < 1)){
                                                    if(!this.state.search.phone_numbers){
                                                        this.state.search.phone_numbers = [];
                                                    }
                                                    this.setState({
                                                        code_error: !this.state.phone_code.name,
                                                        number_error: !this.state.phone_number || this.state.phone_number === ""
                                                    }, () => {
                                                        if(!this.state.code_error && !this.state.number_error){
                                                            this.state.search.phone_numbers.push({
                                                                code: this.state.phone_code.value,
                                                                country: this.state.phone_code.country,
                                                                number: this.state.phone_number,
                                                                campaign_id: this.state.campaign.id
                                                            });
                                                            this.setState({
                                                                new: false,
                                                                phone_code: {},
                                                                phone_number: "",
                                                                search: this.state.search
                                                            }, () => {
                                                                this.props.onChange(this.state.search);
                                                            })
                                                        }
                                                    })
                                                }
                                            }} className="transition-all duration-200 py-2 px-4 rounded-full flex items-center text-xs font-bold focus:outline-none bg-green-500 text-white hover:bg-green-100 hover:text-green-500">
                                                <FeatherIcon className="stroke-current mr-1" size={12} icon="plus"/> Add number
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </SlideDown>
                    </div>
                }
            </div>
        )
    }
}

export default AdvertisingCreativesCall;
