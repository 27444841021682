import React, {Component} from 'react';
import './style.css';
import AccordionStatic from "../../components/accordion-static";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {dataRegister} from "../../services/dataRegister";
import {tokenRegister} from "../../services/tokenRegister";
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";

class UserProfilePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: "",
            new_password: "",
            error_message: "",
            open: false,
            success_modal: false,
            error_modal: false,
            button_loading: false,
        };
    };

    componentWillMount() {}

    init = {};

    functions = {
        update: () => {
            if(!this.state.button_loading){
                this.setState({button_loading: true}, () => {
                    dataRegister.updatePassword({
                        password: this.state.password,
                        new_password: this.state.new_password
                    }).then((response) => {
                        this.setState({
                            button_loading: false,
                            success_modal: true,
                            password: "",
                            new_password: ""
                        });
                    }, (error) => {
                        this.setState({
                            button_loading: false,
                            error_modal: true,
                            error_message: error.body.message
                        })
                        /*
                        if(error.status !== 401){
                            this.setState({
                                button_loading: false,
                                error_modal: true,
                                error_message: error.body.message
                            })
                        }else{
                            tokenRegister.remove();
                            this.props.history.push("/login");
                        }
                        */
                    });
                });
            }
        }
    };

    renders = {};

    render(){
        return (
            <div className="">
                <SweetAlert
                    show={this.state.success_modal}
                    title="Updated"
                    type="success"
                    text="Your password has been updated"
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ success_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ error_modal: false});
                    }}
                />
                <div className="flex flex-1">
                    <Input
                        size={'full'}
                        title={'Old password'}
                        type={'password'}
                        value={this.state.password}
                        onChange={(event) => this.setState({password: event.target.value})}
                    />
                </div>
                <div className="flex flex-1 mt-4">
                    <Input
                        size={'full'}
                        title={'New password'}
                        type={'password'}
                        value={this.state.new_password}
                        onChange={(event) => this.setState({new_password: event.target.value})}
                    />
                </div>
                <div className="flex flex-1 mt-4">
                    <div onClick={() => {
                        this.functions.update();
                    }} className="Button ButtonFull">
                        {
                            !this.state.button_loading &&
                            <div>Update</div>
                        }
                        {
                            this.state.button_loading &&
                            <BeatLoader
                                sizeUnit={"px"}
                                size={12}
                                color={'#ffffff'}
                                loading={true}
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default UserProfilePassword;
