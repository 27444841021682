import React, { Component } from 'react';
import Input from "../components/input";
import currencies from '../assets/json/currencies';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';

class ClientNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            company: "",
            logo: "",
            error: null,
            currencies: currencies,
            error_message: ""
        };
    };

    componentWillMount() { }

    componentDidMount() {

    }

    functions = {
        create: () => {
            this.setState({
                loading: true,
                error: null,
                error_message: ""
            }, () => {
                this.calls.createClient({
                    name: this.state.name,
                    email: this.state.email,
                    company: this.state.company,
                    logo: this.state.logo
                }).then((response) => {
                    this.props.history.push('/v2/client/' + response.data.id);
                }, (error) => {
                    this.setState({
                        loading: false,
                        error: error.body.name,
                        error_message: error.body.message
                    })
                });
            });
        }
    };

    calls = {
        createClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/client";
            return apiRegister.call(options, url);
        }
    };

    renders = {};

    validators = {
        email: (value) => {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(value).toLowerCase());
        }
    };

    render() {
        return (
            <section className="relative h-full flex flex-col">

                <TopNavigationAgent
                    onButton={(type) => {
                        if (type === "create") {

                        }
                    }}
                    breadcrumb={"New client"}
                    history={this.props.history}
                    buttonTypes={{
                        //create_campaign: !this.state.loading
                    }}
                />

                <div className="p-4">
                    <div className="rounded-md bg-white p-4">
                        <div className="mt-4">
                            <Input
                                title={'Name'}
                                value={this.state.name}
                                error={this.state.error === "name" && this.state.name === ''}
                                type={"text"}
                                size={'full'}
                                maxLength={40}
                                onChange={(value) => this.setState({ name: value.target.value }, () => {

                                })}
                            />
                        </div>
                        <div className="mt-4">
                            <Input
                                title={'Company name'}
                                value={this.state.company}
                                error={this.state.error === "company" && this.state.company === ''}
                                type={"text"}
                                size={'full'}
                                maxLength={40}
                                onChange={(value) => this.setState({ company: value.target.value }, () => {

                                })}
                            />
                        </div>
                        <div className="mt-4">
                            <Input
                                title={'Email'}
                                value={this.state.email}
                                error={this.state.error === "email" && !this.validators.email(this.state.email)}
                                type={"text"}
                                size={'full'}
                                maxLength={40}
                                onChange={(value) => this.setState({ email: value.target.value }, () => {

                                })}
                            />
                        </div>
                        <div className="mt-4">
                            <Input
                                title={'Phone (optional)'}
                                value={this.state.phone}
                                error={this.state.error === "phone" && this.state.phone === ''}
                                type={"text"}
                                size={'full'}
                                maxLength={40}
                                onChange={(value) => this.setState({ phone: value.target.value }, () => {

                                })}
                            />
                        </div>
                        {
                            this.state.error &&
                            <div className="Section SectionError">
                                {this.state.error_message}
                            </div>
                        }
                        <div className="mt-4 flex flex-row">
                            <div className="flex flex-1">

                            </div>
                            <div className="justify-end">
                                <button onClick={() => {
                                    this.functions.create();
                                }} className={"flex flex-row items-center py-2 px-3 rounded-full font-bold text-xs transition-all duration-200 bg-green-500 bg-opacity-100 text-white"}>
                                    <span>Create client</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

export default ClientNew;
