import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import IconGoogle from "../icons/google";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";

class TextArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            success: false,
            type: "",
            value: this.props.value,
            placeholder: this.props.placeholder,
            size: this.props.size,
            maxLength: this.props.maxLength,
            error: this.props.error,
            icons: this.props.icons,
            tiny: this.props.tiny,
            channels: this.props.channels,
            sign: this.props.sign,
            white: this.props.white,
            sign_title: this.props.signTitle,
            click_to_copy: this.props.clickToCopy,
            rightTitle: this.props.rightTitle,
            rightTitleText: this.props.rightTitleText
        };
    };

    componentDidMount() {
        this.setState({
            value: this.props.value,
            error: this.props.error,
            size: this.props.size,
            tiny: this.props.tiny,
            maxLength: this.props.maxLength,
            icons: this.props.icons,
            channels: this.props.channels,
            sign: this.props.sign,
            white: this.props.white,
            sign_title: this.props.signTitle,
            click_to_copy: this.props.clickToCopy,
            rightTitle: this.props.rightTitle,
            rightTitleText: this.props.rightTitleText
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            disabled: nextProps.disabled,
            value: nextProps.value,
            placeholder: nextProps.placeholder,
            success: nextProps.success,
            size: nextProps.size,
            tiny: nextProps.tiny,
            icons: nextProps.icons,
            white: nextProps.white,
            error: nextProps.error,
            maxLength: nextProps.maxLength,
            channels: nextProps.channels,
            sign: this.props.sign,
            sign_title: this.props.signTitle,
            rightTitle: nextProps.rightTitle,
            rightTitleText: nextProps.rightTitleText,
        });
    }

    init = {};

    renders = {};

    render(){
        return (
            <div className={
                cx("TextAreaContainer",
                    {
                        ["TextAreaContainerHalf"]: this.state.size === "half",
                        ["TextAreaContainerTiny"]: this.state.tiny
                    })
            }>
                {
                    !this.props.noTitle &&
                    <div className={cx("TextAreaContainerTitle mb-1")}>
                        <div style={{paddingTop: "1px"}} className={
                            cx("font-bold text-xs",
                                {
                                    ["text-red-500"]: this.state.error
                                })
                        }>
                            {this.props.title}
                        </div>
                        {
                            false &&
                            this.state.icons &&
                            <div className={cx("InputContainerTitleIcons")}>
                                {
                                    this.state.channels.map((item) => {
                                        if(item === 'google'){
                                            return(
                                                <div style={{marginLeft: '3px'}}>
                                                    <IconGoogle noMargin={true}/>
                                                </div>
                                            )
                                        }
                                        if(item === 'facebook'){
                                            return(
                                                <div style={{marginLeft: '3px'}}>
                                                    <IconFacebook noMargin={true}/>
                                                </div>
                                            )
                                        }
                                        if(item === 'instagram'){
                                            return(
                                                <div style={{marginLeft: '3px'}}>
                                                    <IconInstagram noMargin={true}/>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.icons &&
                            <div className="flex flex-row ml-2">
                                {
                                    this.state.channels.map((item) => {
                                        if(item === 'google'){
                                            return(
                                                <div className={'w-5 h-5 bg-google-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                    <img className="w-2" src={require('../../assets/images/google_icon.png')}/>
                                                </div>
                                            )
                                        }
                                        if(item === 'facebook'){
                                            return(
                                                <div className="flex-row flex">
                                                    <div className={'w-5 h-5 bg-facebook-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                        <img style={{width: "4px"}} src={require('../../assets/images/facebook_icon.svg')}/>
                                                    </div>
                                                    <div className={'w-5 h-5 bg-instagram-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                        <img className="w-2" src={require('../../assets/images/instagram_icon.svg')}/>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        if(item === 'dont_show'){
                                            return(
                                                <div className={'w-5 h-5 bg-instagram-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                    <img className="w-2" src={require('../../assets/images/instagram_icon.svg')}/>
                                                </div>
                                            )
                                        }
                                        if(item === 'linkedin'){
                                            return(
                                                <div className={'w-5 h-5 bg-linkedin-500 rounded-full border border-white flex justify-center align-middle items-center border-white -ml-1'}>
                                                    <img className="w-2" src={require('../../assets/images/linkedin_icon.svg')}/>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.rightTitle &&
                            <div className="font-bold text-xs flex flex-1 justify-end text-purple-500">
                                <div onClick={() => {
                                    try{
                                        this.props.onRightTitleClick();
                                    }catch (e) {}
                                }} className="cursor-pointer">
                                    {this.state.rightTitleText}
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    this.state.success &&
                    <div className="TextAreaContainerCheckmark">
                        <FeatherIcon color={"#15CD72"} size={25} icon="check"/>
                    </div>
                }
                <div onClick={() => {
                    if(this.state.click_to_copy){
                        var copyText = document.getElementById("textArea");
                        copyText.select();
                        copyText.setSelectionRange(0, 99999);
                        document.execCommand("copy");
                    }
                }} className="rounded-md TextAreaContainerInputContainer relative overflow-hidden">
                    <textarea
                        id="textArea"
                        readOnly={this.state.click_to_copy}
                        maxLength={this.state.maxLength}
                        rows={4}
                        onBlur={() => {
                            try{
                                this.props.onBlur();
                            }catch (e) {}
                        }}
                        disabled={this.state.disabled || this.state.success}
                        type={this.state.type}
                        value={this.state.value}
                        placeholder={this.state.placeholder}
                        onChange={this.props.onChange}
                        style={!this.state.white?{backgroundColor:"#fcfcfc"}:{}}
                        className={
                            cx("TextAreaContainerInput rounded-md",
                                {
                                    ["InputContainerInputError"]: this.state.error,
                                    ["bg-white"]: this.state.white,
                                    ["TextAreaContainerInputContainerClickToCopy"]: this.state.click_to_copy,
                                    ["border-0"]: this.props.noBorder,
                                    ["border-1.5"]: !this.props.noBorder
                                })
                        }
                    />
                    {
                        this.state.sign &&
                        <div className={
                            cx("TextAreaContainerInputContainerSign",
                                {
                                    ["TextAreaContainerInputContainerSignError"]: this.state.error,
                                })
                        }>
                            {this.state.sign_title}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default TextArea;
