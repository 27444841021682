import {apiRegister} from "../../services/apiRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getFacebookAudience(token, adaccount, data) {
        let options = apiRegister.options(null, 'GET', null);
        let url = 'https://graph.facebook.com/v16.0/'+adaccount+'/reachestimate?targeting_spec='+data+'&access_token=' + token;
        return apiRegister.call(options, url);
    }

}

export {calls}