import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import {calls} from "./calls";
import Loader from "../loader";
import ClientAudienceItem from "../client-audience-item";

class ClientAudience extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: null,
            item: {},
            channel: "",
            conversion_type: {},
            conversion_days: {},
            audience: {},
            audiences: [],
            loading: false,
            loading_text: "",
            error_modal: false,
            error_message: ""
        };
    };

    componentDidMount() {
        this.setState({
            client: this.props.client,
            channel: this.props.channel,
            item: this.props.item
        }, () => {
            this.functions.getAudiences();
        })
    }

    init = {};

    functions = {
        getAudiences: () => {
            let channels = {};
            channels[this.state.channel] = true;
            calls.getAudiences({channels: JSON.stringify(channels), client: this.state.client}).then((response) => {
                this.setState({
                    audiences: response.data[this.state.channel]
                });
            }, (error) => {
                this.setState({
                    loading: false
                })
            });
        },
        createAudience: (item) => {
            if(!this.state.conversion_type.value || !this.state.conversion_days.value){
                this.setState({
                    error: true
                });
            }else{
                this.setState({
                    pixel: {},
                    loading: true,
                    error: false
                }, () => {
                    calls.createAudience({
                        channel: this.state.channel,
                        client: this.state.client,
                        days: this.state.conversion_days.value,
                        category: this.state.conversion_type.value
                    }).then((response) => {
                        this.state.audiences.push(response.data);
                        this.setState({
                            loading: false,
                            audiences: this.state.audiences,
                            conversion_days: {},
                            conversion_type: {}
                        })
                    }, (error) => {
                        this.setState({
                            error_modal: true,
                            error_message: error.body.message,
                            loading: false
                        })
                    });
                })
            }
        }
    };

    renders = {
        days: () => {
            let days = [];
            for(let i = 1; i < 181; i++){
                if(i === 1){
                    days.push({name: i + ' day', value: i});
                }else{
                    days.push({name: i + ' days', value: i});
                }
            }
            return days;
        }
    };

    render() {
        return (
            <div style={{padding: '0px', width: '100%'}}>
                {
                    this.state.loading &&
                    <Loader
                        title={"Creating audience"}
                    />
                }
                <SweetAlert
                    show={this.state.error_modal}
                    title="Error"
                    type="error"
                    text={this.state.error_message}
                    confirmButtonText="OK"
                    onConfirm={() => {
                        this.setState({ error_modal: false});
                    }}
                />
                <SweetAlert
                    show={this.state.warning_modal}
                    title="Remove pixel"
                    type="warning"
                    text={"Are you sure ?"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.setState({
                            warning_modal: false
                        }, () => {
                            this.functions.removePixel();
                        })
                    }}
                    onCancel={() => {
                        this.setState({warning_modal: false});
                    }}
                />
                <div className="Audience-add">
                    <div className="Audience-add-title">
                        Create custom audience
                    </div>
                    <div className="Audience-add-inner">
                        <div className="Audience-add-explain">
                            Target users who
                        </div>
                        <div className="Audience-add-type">
                            <Dropdown
                                filter={false}
                                noTitle={true}
                                size={'full'}
                                error={this.state.error && !this.state.conversion_type.value}
                                placeholder={'Select conversion type'}
                                options={[
                                    {name: "Made a purchase", value: "PURCHASED"},
                                    {name: "Visited website", value: "WEBSITE_VISIT"},
                                    {name: "Initiated checkout", value: "INITIATED_CHECKOUT"},
                                    {name: "Added to cart", value: "ADDED_TO_CART"},
                                    {name: "Registered/Signed up", value: "REGISTER"}
                                ]}
                                value={this.state.conversion_type}
                                onChange={(value) => {
                                    this.setState({
                                        conversion_type: value
                                    })
                                }}
                            />
                        </div>
                        <div className="Audience-add-explain">
                            Within the last
                        </div>
                        <div className="Audience-add-type">
                            <Dropdown
                                filter={false}
                                noTitle={true}
                                size={'full'}
                                placeholder={'Select days'}
                                error={this.state.error && !this.state.conversion_days.value}
                                options={this.renders.days()}
                                value={this.state.conversion_days}
                                onChange={(value) => {
                                    this.setState({
                                        conversion_days: value
                                    })
                                }}
                            />
                        </div>
                        <div style={{minWidth: '55px'}}>
                            <div onClick={() => {
                                this.functions.createAudience();
                            }} className="Button ButtonNeutral ButtonFull">
                                <FeatherIcon color={"#333333"} size={23} icon="plus"/>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.audiences.length > 0 &&
                    <div className="Audience-add">
                        <div className="Audience-add-title">
                            Your audiences
                        </div>
                        {
                            this.state.audiences.map((item) => {
                                return(
                                    <ClientAudienceItem
                                        item={item}
                                        channel={this.state.channel}
                                        client={this.state.client}
                                        onChange={(value) => {
                                            let newAudiences = this.state.audiences.map((inner_item) => {
                                                if(inner_item.id === value.id){
                                                    inner_item = value;
                                                }
                                                return inner_item;
                                            });
                                            this.setState({
                                                audiences: newAudiences
                                            }, () => {
                                                //console.log(this.state.audiences);
                                            });
                                        }}
                                        onRemove={(value) => {
                                            this.state.audiences = this.state.audiences.filter((inner_item) => {
                                                return inner_item.id !== value.id
                                            });
                                            this.setState({
                                                audiences: this.state.audiences
                                            });
                                        }}
                                    />
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

export default ClientAudience;
