import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import {BeatLoader} from "react-spinners";
import AccordionFixed from "../facebook";
import Dropdown from "../dropdown";
import Input from "../input";
import {calls} from "./calls";
import {tokenRegister} from "../../services/tokenRegister";
import ClientFacebookPixel from "../client-facebook-pixel";
import ClientGooglePixel from "../client-google-pixel";
import ClientFacebookAdaccount from "../client-facebook-adaccount";

class ClientGoogleShopping extends Component {

    googleSDK = () => {
        window['googleSDKLoaded'] = () => {
            window['gapi'].load('auth2', () => {
                window.test_auth2 = window['gapi'].auth2.init({
                    client_id: '843093771211-s9vg2b2kou6iqtleoinv0aksn9ufdifs.apps.googleusercontent.com',
                    cookiepolicy: 'single_host_origin',
                    scope: 'https://www.googleapis.com/auth/content'
                });
            });
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
    };

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            token: null,
            open: false,
            loading: false,
            managers: [],
            accounts: [],
            manager: {},
            account: {},
            managers_loading: false,
            clients_loading: false,
            submenu: 0
        };
    };

    componentDidMount() {
        this.setState({
            item: this.props.item
        }, () => {
            this.googleSDK();
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            item: nextProps.item?nextProps.item:{}
        });
    }

    init = {};

    functions = {
        login: () => {
            let self = this;
            if (!self.state.loading) {
                self.setState({
                    loading: true
                }, () => {
                    window.test_auth2.grantOfflineAccess().then(res => {
                        calls.getToken({code: res.code}).then((response) => {
                            self.setState({
                                token: response.data,
                                loading: false
                            }, () => {
                                self.functions.merchants();
                            })
                        }, (error) => {
                            self.setState({
                                loading: false
                            })
                        });
                    }).catch(function(error) {
                        console.log(error);
                        self.setState({
                            loading: false
                        })
                    })
                })
            }
        },
        logout: () => {
            if (!this.state.loading) {
                this.setState({
                    loading: true,
                    remove_modal: false
                }, () => {
                    calls.remove(this.state.item.id).then((response) => {
                        delete this.state.item.google_shopping;
                        this.setState({
                            item: this.state.item,
                            loading: false
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading: false
                        })
                    });
                })
            }
        },
        create: () => {
            if (!this.state.loading_create) {
                this.setState({
                    loading_create: true
                }, () => {
                    calls.create({
                        client: this.state.item.id,
                        merchant: this.state.manager,
                        account: this.state.account,
                        token: this.state.token
                    }).then((response) => {
                        this.state.item.google_shopping = response.data;
                        this.setState({
                            token: null,
                            manager: {},
                            account: {},
                            item: this.state.item,
                            loading_create: false,
                            open: false
                        }, () => {
                            this.props.onUpdate(this.state.item);
                        })
                    }, (error) => {
                        this.setState({
                            loading_create: false
                        })
                    });
                })
            }
        },
        merchants: () => {
            this.setState({
                managers_loading: true
            }, () => {
                calls.getMerchants({token: this.state.token}).then((response) => {
                    this.setState({
                        managers: response.data.accounts,
                        managers_loading: false
                    })
                }, (error) => {
                    this.setState({
                        managers_loading: false
                    })
                });
            })
        },
        accounts: () => {
            this.setState({
                accounts_loading: true
            }, () => {
                calls.getAccounts({token: this.state.token, merchant: this.state.manager.id}).then((response) => {
                    if(Array.isArray(response.data.accounts)){
                        response.data.accounts.push(this.state.manager);
                    }
                    this.setState({
                        accounts: Array.isArray(response.data.accounts)?response.data.accounts:[],
                        accounts_loading: false
                    })
                }, (error) => {
                    this.setState({
                        accounts_loading: false
                    })
                });
            })
        },
        account: () => {
            this.setState({
                account_loading: true
            }, () => {
                calls.getAccount({
                    token: this.state.token,
                    merchant: this.state.manager.id,
                    account: this.state.account.id
                }).then((response) => {
                    this.setState({
                        account: response.data,
                        account_loading: false
                    })
                }, (error) => {
                    this.setState({
                        account_loading: false
                    })
                });
            })
        }
    };

    renders = {
        submenus: () => {
            let array = [
                {id: 0, name: "Add adaccount"}
            ];
            return array;
        }
    };

    render() {
        return (
            <div>
                <nav role="navigation" aria-label="Preview sub navigation" className="mb-4">
                    <ul className="flex flex-row items-end">
                        {this.renders.submenus().map((item, j) => {
                            return (
                                <li key={j}
                                    className={"font-bold flex flex-row text-xs py-4 px-4 cursor-pointer border-b-2 " + (this.state.submenu == item.id ? 'border-purple-500 text-opacity-100 text-purple-900' : 'border-gray-200 text-gray-500')}
                                    onClick={() => {
                                        this.setState({
                                            submenu: item.id
                                        });
                                    }}>
                                    {item.name}
                                </li>
                            )
                        })
                        }
                        <li className="flex-grow border-b-2 border-gray-200"></li>
                    </ul>
                </nav>
                <SweetAlert
                    show={this.state.remove_modal}
                    title="Signout Google Shopping"
                    type="warning"
                    text={"You wont be able access any campaigns created with this account"}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                    showCancelButton={true}
                    onConfirm={() => {
                        this.functions.logout();
                    }}
                    onCancel={() => {
                        this.setState({remove_modal: false});
                    }}
                />
                {
                    this.state.submenu === 0 &&
                    <div className="bg-white rounded-md px-4 py-4">
                        {
                            !this.state.item.google_shopping && !this.state.token && false &&
                            <div onClick={() => this.functions.login()} className="Button ButtonFull bg-googleshopping-500">
                                {
                                    !this.state.loading &&
                                    <div>login Google Shopping</div>
                                }
                                {
                                    this.state.loading &&
                                    <BeatLoader
                                        sizeUnit={"px"}
                                        size={12}
                                        color={'#ffffff'}
                                        loading={true}
                                    />
                                }
                            </div>
                        }
                        {
                            !this.state.item.google_shopping && !this.state.token &&
                            <div className="w-full justify-center align-middle items-center flex">
                                <img onClick={() => this.functions.login()} style={{width: "200px"}} className="cursor-pointer" src={require("../..//assets/images/google_signin_button.png")}/>
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.google_shopping &&
                            <div className="Section" style={{paddingTop: '0px'}}>
                                <Dropdown
                                    filter={false}
                                    title={'Select merchent'}
                                    size={'full'}
                                    placeholder={(!this.state.managers_loading && this.state.managers.length < 1)?"No accounts available":(this.state.managers_loading?"Loading accounts":"Click here ..")}
                                    error={this.state.error === "manager" && !this.state.manager.name}
                                    options={this.state.managers}
                                    loading={this.state.managers_loading}
                                    removeArrow={!this.state.managers_loading && this.state.managers.length < 1}
                                    value={this.state.manager}
                                    onChange={(value) => {
                                        this.setState({
                                            manager: value
                                        }, () => {
                                            this.functions.accounts();
                                        });
                                    }}
                                />
                            </div>
                        }
                        {
                            (this.state.item.google_shopping) &&
                            <div className="Section" style={{paddingTop: '0px'}}>
                                <Input
                                    title={'Merchent'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.google_shopping.merchantId}
                                    size={'full'}
                                />
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.google_shopping && this.state.manager.id &&
                            <div className="Section">
                                <Dropdown
                                    filter={false}
                                    title={'Select account'}
                                    size={'full'}
                                    placeholder={(!this.state.account_loading && this.state.managers.length < 1)?"No accounts available":(this.state.account_loading?"Loading accounts":"Click here ..")}
                                    options={this.state.accounts}
                                    loading={this.state.account_loading}
                                    removeArrow={!this.state.account_loading && this.state.accounts.length < 1}
                                    value={this.state.account}
                                    onChange={(value) => {
                                        this.setState({
                                            account: value
                                        }, () => {
                                            this.functions.account();
                                        });
                                    }}
                                />
                            </div>
                        }
                        {
                            (this.state.item.google_shopping) &&
                            <div className="Section">
                                <Input
                                    title={'Account'}
                                    type={'text'}
                                    disabled={true}
                                    value={this.state.item.google_shopping.accountId + ' - ' + this.state.item.google_shopping.accountName}
                                    size={'full'}
                                />
                            </div>
                        }
                        {
                            this.state.token && !this.state.item.google_shopping && this.state.manager.id && this.state.account.id && this.state.account.websiteUrl &&
                            <div className="Section">
                                <div onClick={() => this.functions.create()} className="Button ButtonFull">
                                    {
                                        !this.state.loading_create &&
                                        <div>add Google Shopping</div>
                                    }
                                    {
                                        this.state.loading_create &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={12}
                                            color={'#ffffff'}
                                            loading={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        <div>
                            {
                                this.state.item.google_shopping &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        remove_modal: true
                                    })} className="Button ButtonFull bg-googleshopping-500">
                                        {
                                            !this.state.loading &&
                                            <div>signout Google Shopping</div>
                                        }
                                        {
                                            this.state.loading &&
                                            <BeatLoader
                                                sizeUnit={"px"}
                                                size={12}
                                                color={'#ffffff'}
                                                loading={true}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.token && !this.state.item.google_shopping &&
                                <div className="Section">
                                    <div onClick={() => this.setState({
                                        token: null,
                                        manager: {},
                                        account: {}
                                    })} className="Button ButtonFull bg-googleshopping-500">
                                        signout Google shopping
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ClientGoogleShopping;
