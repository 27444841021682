import React, { Component, Fragment } from 'react';
import { ChatIcon, HeartIcon, SwitchVerticalIcon, UploadIcon } from '@heroicons/react/outline';
import { ExternalLinkIcon } from '@heroicons/react/solid';

class PreviewTwitterFeed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cta: {},
            page: {},
            headline: "",
            body: "",
            video: null,
            thumbnail: null,
            read_more: false,
            for_report: false,
            ad: {},
            is_post: false,
        }
    };

    componentDidMount() {
        this.setState({
            cta: this.props.cta,
            page: this.props.page,
            headline: this.props.headline,
            body: this.props.body,
            video: this.props.video,
            image: this.props.image,
            link: this.props.link,
            thumbnail: this.props.thumbnail,
            for_report: this.props.for_report,
            ad: this.props.ad,
            is_post: this.props.is_post,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            cta: nextProps.cta,
            page: nextProps.page,
            headline: nextProps.headline,
            body: nextProps.body,
            video: nextProps.video,
            image: nextProps.image,
            link: nextProps.link,
            thumbnail: nextProps.thumbnail,
            for_report: nextProps.for_report,
            ad: nextProps.ad,
            is_post: nextProps.is_post,
        })

        setTimeout(() => {
            try {
                if (this.refs.video) {
                    this.refs.video.setAttribute("muted", true);
                }
            } catch (error) {
                console.log(error)
            }
        }, 200)
    }

    renders = {
        body: () => {
            try {
                let body = this.state.body !== "" ? this.state.body : "Body ...";
                if (!this.state.read_more && body.length > 125) {
                    body = body.substring(0, 125) + "...";
                }
                return body;
            } catch (e) {
                return ""
            }
        },
        headline: () => {
            try {
                let headline = this.state.headline !== "" ? this.state.headline : "Headline ...";
                if (!this.state.read_more && headline.length > 125) {
                    headline = headline.substring(0, 125) + "...";
                }
                return headline;
            } catch (e) {
                return ""
            }
        },
        link: () => {
            try {
                let string = this.state.link.replace("https://", "");
                string = string.replace("http://", "");
                string = string.split("/");
                return string[0];
            } catch (error) {
                return this.state.link
            }
        }
    };

    render() {
        return (
            <div style={{ width: "400px" }} className="w-full flex items-center justify-center">
                <div className={`overflow-hidden bg-white p-0 w-full flex flex-row ${this.state.for_report ? "border-t border-b py-4 rounded-none" : "rounded-md"}`}>
                    <div className="w-12 h-12 overflow-hidden mr-2">
                        {
                            this.state.page && this.state.page.id !== 0 &&
                            <img src={this.state.page.image} className="w-full h-full" />
                        }
                    </div>

                    <div className="flex flex-1 flex-col mr-3">

                        <div className="flex flex-row flex-1 mb-2">
                            <div className="flex flex-col flex-1">
                                <div className="flex text-sm flex-row">
                                    <div className="font-bold mr-1">{this.state.page.name}</div>
                                    <div className="font-medium text-gray-600">@{this.state.page.profile_name}</div>
                                </div>
                                <div className="p-3">
                                    <div dangerouslySetInnerHTML={{ __html: this.renders.body() }} className="font-medium text-sm pb-0 whitespace-pre-wrap">
                                    </div>
                                    <div>
                                        {
                                            !this.state.read_more && this.renders.body().length > 125 &&
                                            <div onClick={() => {
                                                this.setState({
                                                    read_more: true
                                                });
                                            }} className=" font-semibold text-sm text-center text-purple-500 ">
                                                Read more
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-lg border w-full overflow-hidden">
                            <div className="w-full">
                                {
                                    this.state.video &&
                                    <video src={this.state.video} poster={this.state.thumbnail} autoplay={false} muted={true} controls={true} className="w-full" ref="video" />
                                }
                                {
                                    this.state.image &&
                                    <img src={this.state.image} className="w-full h-full" />
                                }
                            </div>
                            <div className={`w-full ${this.state.is_post && this.state.link === "" ? "p-0" : "p-4"}`}>
                                <div className="font-medium text-sm text-gray-600">
                                    {this.renders.link()}
                                </div>
                                {
                                    !this.state.is_post &&
                                    <div className="font-medium text-sm">
                                        {this.renders.headline()}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="grid grid-cols-4 col-gap-4 mt-4">
                            <div className="col-span-1">
                                <ChatIcon className="w-5 h-5 text-gray-600" />
                            </div>
                            <div className="col-span-1">
                                <SwitchVerticalIcon className="w-5 h-5 text-gray-600" />
                            </div>
                            <div className="col-span-1">
                                <HeartIcon className="w-5 h-5 text-gray-600" />
                            </div>
                            <div className="col-span-1">
                                <UploadIcon className="w-5 h-5 text-gray-600" />
                            </div>
                        </div>

                        <div className="flex flex-row mt-3 text-xs text-gray-600">
                            Promoted
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default PreviewTwitterFeed;
