import React, { Component } from 'react';
import { ArrowSmDownIcon, ArrowSmLeftIcon, ArrowSmRightIcon, ArrowSmUpIcon, CameraIcon, CheckCircleIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, GiftIcon, PlusCircleIcon, SwitchVerticalIcon, VideoCameraIcon, XCircleIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import languages from '../assets/json/languages.json';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import DropdownTailwind from './dropdownTailwind';
import SwitchTailwind from './switchTailwind';
import { Bar, Doughnut, Line } from "react-chartjs-2";
import PreviewGoogleSearch from '../previews/google/search';
import TextAreaTailwind from './textAreaTailwind';
import PhoneCodeWithCountry from '../assets/json/phone-countries.json';
import snippetHeaderLangueages from '../assets/json/snippet_languages.json';
import occasions from '../assets/json/occasions.json';
import UploadImageTailwind from './uploadImageTailwind';
import moment from 'moment';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/solid';
import { extensionGoogle } from '../validators/extensionGoogle';
import { update } from 'draft-js/lib/DefaultDraftBlockRenderMap';
import { text } from 'body-parser';
const fuzzysort = require('fuzzysort');



class EditLiveGoogleAssets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            promotion_types: [
                { id: 1, name: "Monetary discount" },
                { id: 2, name: "Percent discount" },
                { id: 3, name: "Up to monetary discount" },
                { id: 4, name: "Up to percent discount" }
            ],
            price_types: [
                { id: 1, name: "Brands", value: "BRANDS" },
                { id: 2, name: "Events", value: "EVENTS" },
                { id: 3, name: "Locations", value: "LOCATIONS" },
                { id: 4, name: "Neighborhoods", value: "NEIGHBORHOODS" },
                { id: 1, name: "Product categories", value: "PRODUCT_CATEGORIES" },
                { id: 2, name: "Product tiers", value: "PRODUCT_TIERS" },
                { id: 3, name: "Services", value: "SERVICES" },
                { id: 4, name: "Services categories", value: "SERVICE_CATEGORIES" },
                { id: 4, name: "Services tiers", value: "SERVICE_TIERS" },
            ],
            price_qualifier_options: [
                { id: 1, name: "No qualifier", value: "UNSPECIFIED", preview: "" },
                { id: 2, name: "From", value: "FROM", preview: "From " },
                { id: 3, name: "Up to", value: "UP_TO", preview: "Up to " },
                { id: 4, name: "Average", value: "AVERAGE", preview: "Avg " }
            ],
            unit_types: [
                { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" },
                { id: 2, name: "Per hour", value: "PER_HOUR", preview: "/hr" },
                { id: 3, name: "Per day", value: "PER_DAY", preview: "/day" },
                { id: 4, name: "Per week", value: "PER_WEEK", preview: "/wk" },
                { id: 5, name: "Per month", value: "PER_MONTH", preview: "/mo" },
                { id: 6, name: "Per year", value: "PER_YEAR", preview: "/yr" },
                { id: 7, name: "Per night", value: "PER_NIGHT", preview: "/night" }
            ],
            schedule: false,
            headers: [],
            currencies: ["AED", "ARS", "AUD", "BGN", "BND", "BOB", "BRL", "CAD", "CHF", "CLP", "CNY", "COP", "CZK", "DKK", "EGP", "EUR", "FJD", "GBP", "HKD", "HRK", "HUF", "IDR", "ILS", "INR", "JPY", "KES", "KRW", "MAD", "MXN", "MYR", "NOK", "NZD", "PEN", "PHP", "PKR", "PLN", "RON", "RSD", "RUB", "SAR", "SEK", "SGD", "THB", "TRY", "TWD", "UAH", "USD", "VND", "ZAR"],
            codes: PhoneCodeWithCountry,
            languages: snippetHeaderLangueages,
            occasions: occasions,
            groups: [],
            asset: {},
            updated: {},
            asset_schedule: {
                days: []
            },
            group: {
                channel: "google",
                currency: { id: 47, name: "USD", value: "USD" },
                promotion_value: "",
                promotion_description: "",
                promotion_url: "",
                occasion: { id: 0, name: "None" },
                promotion_detail: { id: 0, name: "None", value: "" },
                promotion_detail_value: "",
                promotion: { id: 1, name: "Monetary discount" },
                promotion_start_date: null,
                promotion_end_date: null,
                tracking: false,
                tracking_options: {
                    template: "",
                    suffix: "",
                    mobile_url: ""
                },
                business_logo: "",
                business_name: "",
                price_qualifier: { id: 1, name: "No qualifier", value: "UNSPECIFIED", preview: "" },
                price_type: { id: 1, name: "Brands", value: "BRANDS" },
                mobile: false,
                schedule: false,
                start_date: null,
                end_date: null,
                language: { id: 0, name: "Select language" },
                header: { id: 0, name: "Select header" },
                days: [
                    { id: Math.floor((Math.random() * 9999999999) + 1), name: "Choose day", time: { id: 0, name: "Not specified" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } }
                ],
                items: [{ id: Math.floor((Math.random() * 9999999999) + 1), callout: "", country_code: { id: 0, name: "Select code" }, number: "", text: "", description1: "", description2: "", key: "", value: "", url: "", mobile_url: "", unit: { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" } }],
                tracking_items: [{ id: Math.floor((Math.random() * 9999999999) + 1), key: "", value: "" }]
            },
            client: {},
            hours_options: [
                { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, { id: 2, name: "00:15", hourValue: 0, minuteValue: "FIFTEEN" }, { id: 3, name: "00:30", hourValue: 0, minuteValue: "THIRTY" }, { id: 4, name: "00:45", hourValue: 0, minuteValue: "FORTY_FIVE" },
                { id: 5, name: "01:00", hourValue: 1, minuteValue: "ZERO" }, { id: 6, name: "01:15", hourValue: 1, minuteValue: "FIFTEEN" }, { id: 7, name: "01:30", hourValue: 1, minuteValue: "THIRTY" }, { id: 8, name: "01:45", hourValue: 1, minuteValue: "FORTY_FIVE" },
                { id: 9, name: "02:00", hourValue: 2, minuteValue: "ZERO" }, { id: 10, name: "02:15", hourValue: 2, minuteValue: "FIFTEEN" }, { id: 11, name: "02:30", hourValue: 2, minuteValue: "THIRTY" }, { id: 12, name: "02:45", hourValue: 2, minuteValue: "FORTY_FIVE" },
                { id: 13, name: "03:00", hourValue: 3, minuteValue: "ZERO" }, { id: 14, name: "03:15", hourValue: 3, minuteValue: "FIFTEEN" }, { id: 15, name: "03:30", hourValue: 3, minuteValue: "THIRTY" }, { id: 16, name: "03:45", hourValue: 3, minuteValue: "FORTY_FIVE" },
                { id: 17, name: "04:00", hourValue: 4, minuteValue: "ZERO" }, { id: 18, name: "04:15", hourValue: 4, minuteValue: "FIFTEEN" }, { id: 19, name: "04:30", hourValue: 4, minuteValue: "THIRTY" }, { id: 20, name: "04:45", hourValue: 4, minuteValue: "FORTY_FIVE" },
                { id: 21, name: "05:00", hourValue: 5, minuteValue: "ZERO" }, { id: 22, name: "05:15", hourValue: 5, minuteValue: "FIFTEEN" }, { id: 23, name: "05:30", hourValue: 5, minuteValue: "THIRTY" }, { id: 24, name: "05:45", hourValue: 5, minuteValue: "FORTY_FIVE" },
                { id: 25, name: "06:00", hourValue: 6, minuteValue: "ZERO" }, { id: 26, name: "06:15", hourValue: 6, minuteValue: "FIFTEEN" }, { id: 27, name: "06:30", hourValue: 6, minuteValue: "THIRTY" }, { id: 28, name: "06:45", hourValue: 6, minuteValue: "FORTY_FIVE" },
                { id: 29, name: "07:00", hourValue: 7, minuteValue: "ZERO" }, { id: 30, name: "07:15", hourValue: 7, minuteValue: "FIFTEEN" }, { id: 31, name: "07:30", hourValue: 7, minuteValue: "THIRTY" }, { id: 32, name: "07:45", hourValue: 7, minuteValue: "FORTY_FIVE" },
                { id: 33, name: "08:00", hourValue: 8, minuteValue: "ZERO" }, { id: 34, name: "08:15", hourValue: 8, minuteValue: "FIFTEEN" }, { id: 35, name: "08:30", hourValue: 8, minuteValue: "THIRTY" }, { id: 36, name: "08:45", hourValue: 8, minuteValue: "FORTY_FIVE" },
                { id: 37, name: "09:00", hourValue: 9, minuteValue: "ZERO" }, { id: 38, name: "09:15", hourValue: 9, minuteValue: "FIFTEEN" }, { id: 39, name: "09:30", hourValue: 9, minuteValue: "THIRTY" }, { id: 40, name: "09:45", hourValue: 9, minuteValue: "FORTY_FIVE" },
                { id: 41, name: "10:00", hourValue: 10, minuteValue: "ZERO" }, { id: 42, name: "10:15", hourValue: 10, minuteValue: "FIFTEEN" }, { id: 43, name: "10:30", hourValue: 10, minuteValue: "THIRTY" }, { id: 44, name: "10:45", hourValue: 10, minuteValue: "FORTY_FIVE" },
                { id: 45, name: "11:00", hourValue: 11, minuteValue: "ZERO" }, { id: 46, name: "11:15", hourValue: 11, minuteValue: "FIFTEEN" }, { id: 47, name: "11:30", hourValue: 11, minuteValue: "THIRTY" }, { id: 48, name: "11:45", hourValue: 11, minuteValue: "FORTY_FIVE" },
                { id: 49, name: "12:00", hourValue: 12, minuteValue: "ZERO" }, { id: 50, name: "12:15", hourValue: 12, minuteValue: "FIFTEEN" }, { id: 51, name: "12:30", hourValue: 12, minuteValue: "THIRTY" }, { id: 52, name: "12:45", hourValue: 12, minuteValue: "FORTY_FIVE" },
                { id: 53, name: "13:00", hourValue: 13, minuteValue: "ZERO" }, { id: 54, name: "13:15", hourValue: 13, minuteValue: "FIFTEEN" }, { id: 55, name: "13:30", hourValue: 13, minuteValue: "THIRTY" }, { id: 56, name: "13:45", hourValue: 13, minuteValue: "FORTY_FIVE" },
                { id: 57, name: "14:00", hourValue: 14, minuteValue: "ZERO" }, { id: 58, name: "14:15", hourValue: 14, minuteValue: "FIFTEEN" }, { id: 59, name: "14:30", hourValue: 14, minuteValue: "THIRTY" }, { id: 60, name: "14:45", hourValue: 14, minuteValue: "FORTY_FIVE" },
                { id: 61, name: "15:00", hourValue: 15, minuteValue: "ZERO" }, { id: 62, name: "15:15", hourValue: 15, minuteValue: "FIFTEEN" }, { id: 63, name: "15:30", hourValue: 15, minuteValue: "THIRTY" }, { id: 64, name: "15:45", hourValue: 15, minuteValue: "FORTY_FIVE" },
                { id: 65, name: "16:00", hourValue: 16, minuteValue: "ZERO" }, { id: 66, name: "16:15", hourValue: 16, minuteValue: "FIFTEEN" }, { id: 67, name: "16:30", hourValue: 16, minuteValue: "THIRTY" }, { id: 68, name: "16:45", hourValue: 16, minuteValue: "FORTY_FIVE" },
                { id: 69, name: "17:00", hourValue: 17, minuteValue: "ZERO" }, { id: 70, name: "17:15", hourValue: 17, minuteValue: "FIFTEEN" }, { id: 71, name: "17:30", hourValue: 17, minuteValue: "THIRTY" }, { id: 72, name: "17:45", hourValue: 17, minuteValue: "FORTY_FIVE" },
                { id: 73, name: "18:00", hourValue: 18, minuteValue: "ZERO" }, { id: 74, name: "18:15", hourValue: 18, minuteValue: "FIFTEEN" }, { id: 75, name: "18:30", hourValue: 18, minuteValue: "THIRTY" }, { id: 76, name: "18:45", hourValue: 18, minuteValue: "FORTY_FIVE" },
                { id: 77, name: "19:00", hourValue: 19, minuteValue: "ZERO" }, { id: 78, name: "19:15", hourValue: 19, minuteValue: "FIFTEEN" }, { id: 79, name: "19:30", hourValue: 19, minuteValue: "THIRTY" }, { id: 80, name: "19:45", hourValue: 19, minuteValue: "FORTY_FIVE" },
                { id: 81, name: "20:00", hourValue: 20, minuteValue: "ZERO" }, { id: 82, name: "20:15", hourValue: 20, minuteValue: "FIFTEEN" }, { id: 83, name: "20:30", hourValue: 20, minuteValue: "THIRTY" }, { id: 84, name: "20:45", hourValue: 20, minuteValue: "FORTY_FIVE" },
                { id: 85, name: "21:00", hourValue: 21, minuteValue: "ZERO" }, { id: 86, name: "21:15", hourValue: 21, minuteValue: "FIFTEEN" }, { id: 87, name: "21:30", hourValue: 21, minuteValue: "THIRTY" }, { id: 88, name: "21:45", hourValue: 21, minuteValue: "FORTY_FIVE" },
                { id: 89, name: "22:00", hourValue: 22, minuteValue: "ZERO" }, { id: 90, name: "22:15", hourValue: 22, minuteValue: "FIFTEEN" }, { id: 91, name: "22:30", hourValue: 22, minuteValue: "THIRTY" }, { id: 92, name: "22:45", hourValue: 22, minuteValue: "FORTY_FIVE" },
                { id: 93, name: "23:00", hourValue: 23, minuteValue: "ZERO" }, { id: 94, name: "23:15", hourValue: 23, minuteValue: "FIFTEEN" }, { id: 95, name: "23:30", hourValue: 23, minuteValue: "THIRTY" }, { id: 96, name: "23:45", hourValue: 23, minuteValue: "FORTY_FIVE" },
                { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" }
            ],
            days: [
                { id: 1, name: "Monday", value: "MONDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 2, name: "Tuesday", value: "TUESDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 3, name: "Wednesday", value: "WEDNESDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 4, name: "Thursday", value: "THURSDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 5, name: "Friday", value: "FRIDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 6, name: "Saturday", value: "SATURDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 7, name: "Sunday", value: "SUNDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } }
            ],
            start_date: null,
            end_date: null,
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            asset: this.props.asset,
            client: this.props.client
        });

        //this.props.onDisable(true);
        if (this.state.asset.type === "PROMOTION" || this.state.asset.type === "CALLOUT" || this.state.asset.type === "CALL" || this.state.asset.type === "SITELINK") {
            let start_date = null;
            let end_date = null;


            ["promotionAsset", "callAsset", "calloutAsset", "sitelinkAsset"].map((item) => {
                if (this.state.asset.data.asset[item]) {
                    if (this.state.asset.data.asset[item].startDate) {
                        start_date = moment(this.state.asset.data.asset[item].startDate).format("YYYY-MM-DD");
                    }
                    if (this.state.asset.data.asset[item].endDate) {
                        end_date = moment(this.state.asset.data.asset[item].endDate).format("YYYY-MM-DD");
                    }
                    if (Array.isArray(this.state.asset.data.asset[item].adScheduleTargets)) {
                        this.state.asset.data.asset[item].adScheduleTargets.map((item) => {
                            if (this.state.days.find((day) => { return day.value === item.dayOfWeek })) {
                                if (!this.state.asset.data.days) {
                                    this.state.asset.data.days = []
                                }
                                let buffer = this.state.days.find((day) => { return day.value === item.dayOfWeek });
                                buffer.start = this.state.hours_options.find((hour) => { return hour.hourValue === item.startHour && hour.minuteValue === item.startMinute });
                                buffer.end = this.state.hours_options.find((hour) => { return hour.hourValue === item.endHour && hour.minuteValue === item.endMinute });
                                this.state.asset.data.days.push(buffer);
                                //this.state.days.find((day) => { return day.value === item.dayOfWeek }).time = { id: 2, name: "Start - End" };

                            }
                        });
                    }
                }
            });
            await this.promisedSetState({
                asset: this.state.asset,
                start_date: start_date,
                end_date: end_date,
                schedule: (start_date || end_date || this.state.asset.data.days) ? true : false,
            });
        }

        console.log("ASSET", this.state.asset);
        //console.log(this.renders.businessLogo());


    }

    functions = {
        update: async () => {
            if (this.state.updated && Object.keys(this.state.updated).length > 0) {
                this.props.onDisable(true);
                let body = {};
                let operations = [];
                try {


                    if (this.state.asset.type === "CALL") {
                        let body = JSON.parse(JSON.stringify(this.state.asset.data.asset.callAsset));
                        body.phoneNumber = this.renders.call()[0].number;
                        if (this.renders.call()[0].country_code && this.renders.call()[0].country_code.iso) {
                            body.countryCode = this.renders.call()[0].country_code.iso;
                        }
                        if (this.renders.days().length > 0) {
                            body.adScheduleTargets = this.renders.days().filter((item) => { return item.time.id === 2 }).map((item) => {
                                return {
                                    dayOfWeek: item.value,
                                    startHour: item.start.hourValue,
                                    startMinute: item.start.minuteValue,
                                    endHour: item.end.hourValue,
                                    endMinute: item.end.minuteValue
                                }
                            });
                        }
                        operations.push({
                            updateMask: Object.keys(body).map((item) => { return "callAsset." + item }).toString(),
                            update: {
                                resourceName: this.state.asset.data.asset.resourceName,
                                callAsset: body
                            }
                        });

                    } else if (this.state.asset.type === "PRICE") {
                        let body = JSON.parse(JSON.stringify(this.state.asset.data.asset.priceAsset));
                        if (this.renders.price().language && this.renders.price().language.Locale) {
                            body.languageCode = this.renders.price().language.Locale;
                        }
                        if (this.renders.price().price_type && this.renders.price().price_type.value) {
                            body.type = this.renders.price().price_type.value;
                        }
                        if (this.renders.price().price_qualifier && this.renders.price().price_qualifier.id && this.renders.price().price_qualifier.id !== 1) {
                            body.priceQualifier = this.renders.price().price_qualifier.value;
                        }
                        body.priceOfferings = this.renders.price().items.map((item) => {
                            return {
                                header: item.text,
                                description: item.description1,
                                finalUrl: item.url,
                                ...(item.mobile_url && { finalMobileUrl: item.mobile_url }),
                                price: {
                                    amountMicros: item.number * 1000000,
                                    currencyCode: this.renders.price().currency.value
                                },
                                unit: item.unit.value
                            }
                        });

                        operations.push({
                            updateMask: Object.keys(body).map((item) => { return "priceAsset." + item }).toString(),
                            update: {
                                resourceName: this.state.asset.data.asset.resourceName,
                                priceAsset: body
                            }
                        });



                    } else if (this.state.asset.type === "SITELINK") {
                        body = JSON.parse(JSON.stringify(this.state.asset.data.asset.sitelinkAsset));
                        body.linkText = this.renders.sitelinks()[0].text;
                        body.description1 = this.renders.sitelinks()[0].description1;
                        body.description2 = this.renders.sitelinks()[0].description2;
                        if (this.state.start_date) {
                            body.startDate = this.state.start_date;
                        } else {
                            delete body.startDate;
                        }
                        if (this.state.end_date) {
                            body.endDate = this.state.end_date;
                        } else {
                            delete body.endDate;
                        }
                        if (this.renders.days().length > 0) {
                            body.adScheduleTargets = this.renders.days().filter((item) => { return item.time.id === 2 }).map((item) => {
                                return {
                                    dayOfWeek: item.value,
                                    startHour: item.start.hourValue,
                                    startMinute: item.start.minuteValue,
                                    endHour: item.end.hourValue,
                                    endMinute: item.end.minuteValue
                                }
                            });
                        }
                        operations.push({
                            updateMask: Object.keys(body).map((item) => { return "sitelinkAsset." + item }).toString() + ",finalUrls,sitelinkAsset.startDate,sitelinkAsset.endDate",
                            update: {
                                resourceName: this.state.asset.data.asset.resourceName,
                                finalUrls: [this.renders.sitelinks()[0].url],
                                sitelinkAsset: body
                            }
                        });

                    } else if (this.state.asset.type === "STRUCTURED_SNIPPET") {
                        body = JSON.parse(JSON.stringify(this.state.asset.data.asset.structuredSnippetAsset));
                        if (this.renders.snippet_header() && this.renders.snippet_header().id && this.renders.snippet_header().id !== 0) {
                            body.header = this.renders.snippet_header().name;
                        }
                        body.values = this.renders.snippet_items().map((item) => {
                            return item.text;
                        });
                        operations.push({
                            updateMask: Object.keys(body).map((item) => { return "structuredSnippetAsset." + item }).toString(),
                            update: {
                                resourceName: this.state.asset.data.asset.resourceName,
                                structuredSnippetAsset: body
                            }
                        });
                    } else if (this.state.asset.type === "CALLOUT") {
                        body = JSON.parse(JSON.stringify(this.state.asset.data.asset.calloutAsset));
                        body.calloutText = this.renders.callout();
                        if (this.state.start_date) {
                            body.startDate = this.state.start_date;
                        } else {
                            delete body.startDate;
                        }
                        if (this.state.end_date) {
                            body.endDate = this.state.end_date;
                        } else {
                            delete body.endDate;
                        }
                        if (this.renders.days().length > 0) {
                            body.adScheduleTargets = this.renders.days().filter((item) => { return item.time.id === 2 }).map((item) => {
                                return {
                                    dayOfWeek: item.value,
                                    startHour: item.start.hourValue,
                                    startMinute: item.start.minuteValue,
                                    endHour: item.end.hourValue,
                                    endMinute: item.end.minuteValue
                                }
                            });
                        }
                        operations.push({
                            updateMask: Object.keys(body).map((item) => { return "calloutAsset." + item }).toString() + ",calloutAsset.startDate,calloutAsset.endDate",
                            update: {
                                resourceName: this.state.asset.data.asset.resourceName,
                                calloutAsset: body
                            }
                        });
                    } else if (this.state.asset.type === "PROMOTION") {
                        body = JSON.parse(JSON.stringify(this.state.asset.data.asset.promotionAsset));
                        if (this.renders.promotion().id > 2) {
                            body.discountModifier = "UP_TO";
                        } else {
                            delete body.discountModifier;
                        }
                        if (this.renders.promotion_language() && this.renders.promotion_language().id && this.renders.promotion_language().id !== 0) {
                            body.languageCode = this.renders.promotion_language().value.toLowerCase();
                        }
                        body.promotionTarget = this.renders.promotion_description();
                        if (this.renders.occasions() && this.renders.occasions().id && this.renders.occasions().id !== 0) {
                            body.occasion = this.renders.occasions().value;
                        }
                        if (this.renders.promotion_start_date()) {
                            body.redemptionStartDate = this.renders.promotion_start_date();
                        } else {
                            delete body.redemptionStartDate;
                        }
                        if (this.renders.promotion_end_date()) {
                            body.redemptionEndDate = this.renders.promotion_end_date();
                        } else {
                            delete body.redemptionEndDate;
                        }
                        if (this.state.start_date) {
                            body.startDate = this.state.start_date;
                        } else {
                            delete body.startDate;
                        }
                        if (this.state.end_date) {
                            body.endDate = this.state.end_date;
                        } else {
                            delete body.endDate;
                        }
                        if (this.renders.promotion().id && this.renders.promotion().id !== 0) {
                            if (this.renders.promotion().id === 1 || this.renders.promotion().id === 3) {
                                delete body.percentOff;
                                body.moneyAmountOff = {
                                    amountMicros: this.renders.promotion_value() * 1000000,
                                    currencyCode: this.renders.currency().id !== 0 ? this.renders.currency().value : ""
                                };
                            } else if (this.renders.promotion().id === 2 || this.renders.promotion().id === 4) {
                                delete body.moneyAmountOff;
                                body.percentOff = parseInt(this.renders.promotion_value()) * 10000;
                            }
                        }
                        if (this.renders.promotion_detail().id !== 0) {
                            if (this.renders.promotion_detail().id === 1) {
                                delete body.promotionCode;
                                body.ordersOverAmount = {
                                    amountMicros: this.renders.promotion_detail_value() * 1000000,
                                    currencyCode: this.renders.currency().id !== 0 ? this.renders.currency().value : ""
                                }
                            } else if (this.renders.promotion_detail().id === 2) {
                                delete body.ordersOverAmount;
                                body.promotionCode = this.renders.promotion_detail_value();
                            }

                        } else {
                            delete body.ordersOverAmount;
                            delete body.promotionCode;
                        }
                        if (this.renders.days().length > 0) {
                            body.adScheduleTargets = this.renders.days().filter((item) => { return item.time.id === 2 }).map((item) => {
                                return {
                                    dayOfWeek: item.value,
                                    startHour: item.start.hourValue,
                                    startMinute: item.start.minuteValue,
                                    endHour: item.end.hourValue,
                                    endMinute: item.end.minuteValue
                                }
                            });
                        }

                        operations.push({
                            updateMask: Object.keys(body).map((item) => {
                                if (item === "percentOff") {
                                    return "promotionAsset." + item + ",promotionAsset.moneyAmountOff"
                                } else if (item === "moneyAmountOff") {
                                    return "promotionAsset." + item + ".amountMicros,promotionAsset." + item + ".currencyCode" + ",promotionAsset.percentOff"
                                } else if (item === "ordersOverAmount") {
                                    return "promotionAsset." + item + ".amountMicros,promotionAsset." + item + ".currencyCode"
                                } else {
                                    return "promotionAsset." + item
                                }
                            }).toString() + ",finalUrls,promotionAsset.startDate,promotionAsset.endDate",
                            update: {
                                resourceName: this.state.asset.data.asset.resourceName,
                                finalUrls: [this.renders.promotion_url()],
                                promotionAsset: body
                            }
                        });
                        if (!operations[0].updateMask.includes("redemptionStartDate")) {
                            operations[0].updateMask += ",promotionAsset.redemptionStartDate";
                        }
                        if (!operations[0].updateMask.includes("redemptionEndDate")) {
                            operations[0].updateMask += ",promotionAsset.redemptionEndDate";
                        }



                    }
                } catch (error) {
                    console.log(error, "ERROR");
                }
                if (operations.length > 0) {
                    try {

                        let response = await this.calls.updateLiveAsset({ operations: operations });
                        if (response.message && !response.data.resourceName) {
                            await this.promisedSetState({
                                error: response.message
                            });
                        } else {
                            this.props.onSuccess(true);
                        }
                    } catch (error) {
                        await this.promisedSetState({
                            error: error.body.message ? error.body.message : "Something went wrong"
                        });
                        //this.props.onError(error.error ? error.error : "Something went wrong");
                    }
                }
                this.props.onDisable(false);

            }
        },
        save: async () => {
            if (this.props.onSave) {
                this.props.onSave(this.state.group);
            }
        },
        loadHeaders: async (language) => {
            try {
                await this.promisedSetState({ loading_headers: true });
                let headers = [];
                snippetHeaderLangueages.map((item) => {
                    if (item.Locale === language) {
                        //console.log(Object.keys(item).filter((inner_item) => { return inner_item !== "Locale" && inner_item !== "Language" && inner_item !== "Language" && inner_item !== "Language" }));
                        Object.keys(item).filter((inner_item) => { return inner_item !== "Locale" && inner_item !== "Language" && inner_item !== "id" && inner_item !== "name" }).map((inner_item, index) => {
                            headers.push({ id: index, name: item[inner_item], value: item[inner_item] })

                        })
                    }
                });
                console.log(headers);
                this.promisedSetState({ loading_headers: false, headers: headers })
            } catch (error) { }
        }
    };

    calls = {
        updateLiveAsset: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/updateAsset?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
    };

    renders = {
        days: () => {
            try {
                if ('days' in this.state.updated) {
                    return this.state.updated.days;
                } else if (this.state.asset && this.state.asset.data && this.state.asset.data.days) {
                    return this.state.asset.data.days;
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        startHour: (index) => {
            try {
                if (this.renders.days().length > 0) {
                    return this.renders.days()[index].start;
                } else {
                    return this.state.days[index].start;
                }
            } catch (error) {
                return 0;
            }
        },
        endHour: (index) => {
            try {
                if (this.renders.days().length > 0) {
                    return this.renders.days()[index].end;
                } else {
                    return this.state.days[index].end;
                }
            } catch (error) {
                return 0;
            }
        },
        start_end: (index) => {
            try {
                if (this.renders.days().length > 0) {
                    if (this.renders.days()[index].time.id === 2) {
                        return false
                    } else return true
                } else {
                    if (this.state.days[index].time.id === 2) {
                        return false
                    } else return true
                }
            } catch (error) {
                return 0;
            }
        },
        dayTime: (index) => {
            try {
                if ('days' in this.state.group) {
                    return this.state.group.days[index].time;
                } else {
                    return this.state.days[index].time;
                }
            } catch (error) {
                return 0;
            }
        },
        sitelinks: () => {
            try {
                if ("sitelink" in this.state.updated) {
                    return this.state.updated.sitelink;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "SITELINK") {
                    return [{
                        id: 1,
                        url: this.state.asset.data.asset.finalUrls[0],
                        text: this.state.asset.data.asset.sitelinkAsset.linkText,
                        description1: this.state.asset.data.asset.sitelinkAsset.description1,
                        description2: this.state.asset.data.asset.sitelinkAsset.description2
                    }];
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        call: () => {
            try {
                if ("call" in this.state.updated) {
                    return this.state.updated.call;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "CALL") {
                    return [{
                        id: 1,
                        country_code: this.state.codes.filter((item) => item.iso == this.state.asset.data.asset.callAsset.countryCode).length > 0 ? this.state.codes.map((item) => { item.name = item.country + " (+" + item.code + ")"; return item }).filter((item) => item.iso === this.state.asset.data.asset.callAsset.countryCode)[0] : { id: 0, name: "Select code" },
                        number: this.state.asset.data.asset.callAsset.phoneNumber,
                    }];
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        price: () => {
            try {
                if ("price" in this.state.updated) {
                    return this.state.updated.price;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PRICE") {
                    return {
                        language: this.state.languages.filter((item) => { return item.Locale === this.state.asset.data.asset.priceAsset.languageCode }).length > 0 ? this.state.languages.map((item, index) => { item.id = index + 1; item.name = item.Language; return item }).filter((item) => { return item.Locale === this.state.asset.data.asset.priceAsset.languageCode })[0] : { id: 0, name: "Select language" },
                        price_type: this.state.price_types.filter((item) => { return item.value === this.state.asset.data.asset.priceAsset.type }).length > 0 ? this.state.price_types.filter((item) => { return item.value === this.state.asset.data.asset.priceAsset.type })[0] : { id: 0, name: "Select" },
                        currency: Array.isArray(this.state.asset.data.asset.priceAsset.priceOfferings) && this.state.asset.data.asset.priceAsset.priceOfferings.length > 0 ? { id: 999, name: this.state.asset.data.asset.priceAsset.priceOfferings[0].price.currencyCode, value: this.state.asset.data.asset.priceAsset.priceOfferings[0].price.currencyCode } : { id: 0, name: "Select", value: "" },
                        price_qualifier: this.state.asset.data.asset.priceAsset.priceQualifier && this.state.price_qualifier_options.filter((item) => { return item.value === this.state.asset.data.asset.priceAsset.priceQualifier }).length > 0 ? this.state.price_qualifier_options.filter((item) => { return item.value === this.state.asset.data.asset.priceAsset.priceQualifier })[0] : { id: 1, name: "No qualifier", value: "UNSPECIFIED", preview: "" },
                        ...(Array.isArray(this.state.asset.data.asset.priceAsset.priceOfferings) && this.state.asset.data.asset.priceAsset.priceOfferings.length > 0 && {
                            items: this.state.asset.data.asset.priceAsset.priceOfferings.map((item, index) => {
                                return {
                                    id: index + 1,
                                    text: item.header,
                                    description1: item.description,
                                    url: item.finalUrl,
                                    number: parseInt(item.price.amountMicros) / 1000000,
                                    unit: this.state.unit_types.filter((unit) => { return unit.value === item.unit }).length > 0 ? this.state.unit_types.filter((unit) => { return unit.value === item.unit })[0] : { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" },
                                    mobile_url: item.finalMobileUrl ? item.finalMobileUrl : ""
                                }
                            })
                        }),
                    }
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        callout: () => {
            try {
                if ("callout" in this.state.updated) {
                    return this.state.updated.callout;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "CALLOUT") {
                    return this.state.asset.data.asset.calloutAsset.calloutText;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        businessLogo: () => {
            try {
                if ("business_logo" in this.state.updated) {
                    return this.state.updated.business_logo;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "BUSINESS_LOGO") {
                    return this.state.asset.data.asset.imageAsset.fullSize.url;
                } else {
                    return "";
                }
            } catch (error) {
                //console.log(error);
                return "";
            }
        },
        snipet_language: () => {
            try {
                if ("snipet_language" in this.state.updated) {
                    return this.state.updated.snipet_language;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "STRUCTURED_SNIPPET") {
                    let buffer = 0;
                    let language = false;
                    snippetHeaderLangueages.map((item) => {
                        Object.keys(item).map((inner_item, index) => {
                            if (item[inner_item] === this.state.asset.data.asset.structuredSnippetAsset.header) {
                                buffer = buffer + 1;
                                language = { id: index, name: item.Language, value: item.Locale };
                            }
                        })
                    })
                    if (buffer === 0) {
                        return { id: 0, name: "Select language" };
                    } else if (buffer === 1) {
                        if (this.state.headers.length === 0) {
                            this.functions.loadHeaders(language.value);
                        }
                        return language;
                    } else {
                        return { id: 0, name: "Select language" };
                    }
                    //return this.state.languages.filter((item) => { return item.Locale === this.state.asset.data.asset.snippetAsset.languageCode }).length > 0 ? this.state.languages.map((item, index) => { item.id = index + 1; item.name = item.Language; return item }).filter((item) => { return item.Locale === this.state.asset.data.asset.snippetAsset.languageCode })[0] : { id: 0, name: "Select language" };
                } else {
                    return { id: 0, name: "Select language" };
                }
            } catch (error) {
                return { id: 0, name: "Select language" };
            }
        },
        snippet_header: () => {
            try {
                if ("snippet_header" in this.state.updated) {
                    return this.state.updated.snippet_header;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "STRUCTURED_SNIPPET") {
                    let buffer = 0;
                    let header = false;
                    snippetHeaderLangueages.map((item) => {
                        Object.keys(item).map((inner_item, index) => {
                            if (item[inner_item] === this.state.asset.data.asset.structuredSnippetAsset.header) {
                                buffer = buffer + 1;
                                header = { id: index, name: item.Language, value: item[inner_item] };
                            }
                        })
                    })
                    if (buffer === 0) {
                        return { id: 0, name: "Select header" };
                    } else if (buffer === 1) {
                        return header;
                    } else {
                        return { id: 999, name: this.state.asset.data.asset.structuredSnippetAsset.header };
                    }
                    //return this.state.headers.filter((item) => { return item.value === this.state.asset.data.asset.snippetAsset.header }).length > 0 ? this.state.headers.filter((item) => { return item.value === this.state.asset.data.asset.snippetAsset.header })[0] : { id: 0, name: "Select header" };
                } else {
                    return { id: 0, name: "Select header" };
                }
            } catch (error) {
                return { id: 0, name: "Select header" };
            }
        },
        snippet_items: () => {
            try {
                if ("snippet" in this.state.updated) {
                    return this.state.updated.snippet;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "STRUCTURED_SNIPPET") {
                    return this.state.asset.data.asset.structuredSnippetAsset.values.map((item) => {
                        return {
                            text: item
                        }
                    });
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        promotion: () => {
            try {
                if ("promotion" in this.state.updated) {
                    return this.state.updated.promotion;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PROMOTION") {
                    if (this.state.asset.data.asset.promotionAsset.discountModifier !== "UNKNOWN") {
                        if (this.state.asset.data.asset.promotionAsset.moneyAmountOff) {
                            return { id: 3, name: "Up to monetary discount" };
                        } else if (this.state.asset.data.asset.promotionAsset.percentOff) {
                            return { id: 4, name: "Up to percent discount" };
                        } else {
                            return { id: 0, name: "UNKOWN" };
                        }
                    } else {
                        if (this.state.asset.data.asset.promotionAsset.moneyAmountOff) {
                            return { id: 1, name: "Monetary discount" };
                        } else if (this.state.asset.data.asset.promotionAsset.percentOff) {
                            return { id: 2, name: "Percent discount" };
                        } else {
                            return { id: 0, name: "UNKOWN" };
                        }
                    }
                }
            } catch (error) {
                return { id: 0, name: "UNKOWN" };
            }
        },
        promotion_value: () => {
            try {
                if ("promotion_value" in this.state.updated) {
                    return this.state.updated.promotion_value;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PROMOTION") {
                    if (this.state.asset.data.asset.promotionAsset.moneyAmountOff) {
                        return parseInt(this.state.asset.data.asset.promotionAsset.moneyAmountOff.amountMicros) / 1000000;
                    } else if (this.state.asset.data.asset.promotionAsset.percentOff) {
                        return parseInt(this.state.asset.data.asset.promotionAsset.percentOff) / 10000;
                    } else {
                        return "";
                    }
                }
            } catch (error) {
                return ""
            }
        },
        currency: () => {
            try {
                if ("currency" in this.state.updated) {
                    return this.state.updated.currency;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PROMOTION") {
                    if (this.state.asset.data.asset.promotionAsset.moneyAmountOff) {
                        let name = false;
                        if (this.state.currencies.filter((item) => { return item === this.state.asset.data.asset.promotionAsset.moneyAmountOff.currencyCode }).length > 0) {
                            name = this.state.currencies.filter((item) => { return item === this.state.asset.data.asset.promotionAsset.moneyAmountOff.currencyCode })[0];
                        }
                        return name ? { id: 999, name: name, value: name } : { id: 0, name: "Select currency" };
                    } else {
                        return { id: 0, name: "Select currency" };
                    }
                }
            } catch (error) {
                return { id: 0, name: "Select currency" }
            }
        },
        promotion_language: () => {
            try {
                if ("promotion_language" in this.state.updated) {
                    return this.state.updated.promotion_language;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PROMOTION") {
                    let language = false;
                    if (this.state.languages.filter((item) => { return item.Locale === this.state.asset.data.asset.promotionAsset.languageCode }).length > 0) {
                        this.state.languages.filter((item) => { return item.Locale === this.state.asset.data.asset.promotionAsset.languageCode }).map((item, index) => {
                            language = { id: index, name: item.Language, value: item.Locale }
                        });
                    }
                    return language ? language : { id: 0, name: "Select language" }
                }
            } catch (error) {
                return { id: 0, name: "Select language" }
            }
        },
        occasions: () => {
            try {
                if ("occasions" in this.state.updated) {
                    return this.state.updated.occasions;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PROMOTION") {
                    if (this.state.occasions.map((item, index) => { return { id: index, name: item.name, value: item.value, start: item.start, end: item.end } }).filter((item) => { return item.value === this.state.asset.data.asset.promotionAsset.occasion }).length > 0) {
                        return this.state.occasions.map((item, index) => { return { id: index, name: item.name, value: item.value, start: item.start, end: item.end } }).filter((item) => { return item.value === this.state.asset.data.asset.promotionAsset.occasion })[0];
                    } else {
                        return { id: 0, name: "Select occasion" };
                    }
                }
            } catch (error) {
                return { id: 0, name: "None", value: "" }
            }
        },
        promotion_description: () => {
            try {
                if ("promotion_description" in this.state.updated) {
                    return this.state.updated.promotion_description;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PROMOTION") {
                    return this.state.asset.data.asset.promotionAsset.promotionTarget;
                } else {
                    return "";
                }
            } catch (error) {
                return ""
            }
        },
        promotion_url: () => {
            try {
                if ("promotion_url" in this.state.updated) {
                    return this.state.updated.promotion_url;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PROMOTION") {
                    return this.state.asset.data.asset.finalUrls[0];
                } else {
                    return "";
                }
            } catch (error) {
                return ""
            }
        },
        promotion_detail: () => {
            try {
                if ("promotion_detail" in this.state.updated) {
                    return this.state.updated.promotion_detail;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PROMOTION") {
                    if (this.state.asset.data.asset.promotionAsset.ordersOverAmount) {
                        return { id: 1, name: "Orders over" }
                    } else if (this.state.asset.data.asset.promotionAsset.promotionCode) {
                        return { id: 2, name: "Promotion code" }
                    } else {
                        return { id: 0, name: "None", value: "" }
                    }
                }
            } catch (error) {
                return { id: 0, name: "None", value: "" }
            }
        },
        promotion_detail_value: () => {
            try {
                if ("promotion_detail_value" in this.state.updated) {
                    return this.state.updated.promotion_detail_value;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PROMOTION") {
                    if (this.state.asset.data.asset.promotionAsset.ordersOverAmount) {
                        return parseInt(this.state.asset.data.asset.promotionAsset.ordersOverAmount.amountMicros) / 1000000;
                    } else if (this.state.asset.data.asset.promotionAsset.promotionCode) {
                        return this.state.asset.data.asset.promotionAsset.promotionCode;
                    } else {
                        return ""
                    }
                }
            } catch (error) {
                return ""
            }
        },
        promotion_start_date: () => {
            try {
                if ("promotion_start_date" in this.state.updated) {
                    return this.state.updated.promotion_start_date;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PROMOTION") {
                    if (this.state.asset.data.asset.promotionAsset.redemptionStartDate) {
                        return this.state.asset.data.asset.promotionAsset.redemptionStartDate;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } catch (error) {
                return false
            }
        },
        promotion_end_date: () => {
            try {
                if ("promotion_end_date" in this.state.updated) {
                    return this.state.updated.promotion_end_date;
                } else if (this.state.asset && this.state.asset.type && this.state.asset.type === "PROMOTION") {
                    if (this.state.asset.data.asset.promotionAsset.redemptionEndDate) {
                        return this.state.asset.data.asset.promotionAsset.redemptionEndDate;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } catch (error) {
                return false
            }
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="flex flex-1 h-full">
                <div className="flex flex-row flex-1">
                    <div className="flex flex-1 flex-col h-full pb-4">
                        <div className="text-sm font-medium mb-4 bg-white">
                            Preview extension
                        </div>
                        <div className="flex flex-1 overflow-scroll">
                            <div className="w-full relative">
                                <div className="mb-4">
                                    {
                                        this.state.asset && this.state.asset.type &&
                                        <PreviewGoogleSearch
                                            headlineFirst={"First headline"}
                                            headlineSecond={"Second headline"}
                                            headlineThird={"Third headline"}
                                            bodyOne={"Description 1"}
                                            bodySecond={"Description 2"}
                                            link={"https://your-website.com"}
                                            sitelinks={Array.isArray(this.renders.sitelinks()) && this.state.asset.type === "SITELINK" ? this.renders.sitelinks() : []}
                                            path1={""}
                                            path2={""}
                                            businessName={this.state.asset && this.state.asset.type === "BUSINESS_NAME" ? this.state.asset.name : false}
                                            promotion={
                                                this.state.asset && this.state.asset.type === "PROMOTION" ?
                                                    {
                                                        promotion_start_date: this.renders.promotion_start_date(),
                                                        promotion_end_date: this.renders.promotion_end_date(),
                                                        promotion_value: this.renders.promotion_value(),
                                                        promotion: this.renders.promotion(),
                                                        promotion_description: this.renders.promotion_description(),
                                                        occasion: this.renders.occasions(),
                                                        promotion_detail: this.renders.promotion_detail(),
                                                        promotion_detail_value: this.renders.promotion_detail_value(),
                                                        currency: this.renders.currency(),
                                                    }
                                                    :
                                                    false
                                            }
                                            price={this.state.asset.type && this.state.asset.type === "PRICE" ? this.renders.price() : false}
                                            header={this.state.asset.type && this.state.asset.type === "STRUCTURED_SNIPPET" && this.renders.snippet_header().id !== 0 ? this.renders.snippet_header().name : "Header"}//this.state.group.header.id !== 0 ? this.state.group.header.value : "Header"}
                                            callout={this.state.asset.type && this.state.asset.type === "CALLOUT" ? [{ callout: this.renders.callout() }] : []}//Array.isArray(this.state.group.items) && this.state.group.subtype && this.state.group.subtype.value === "callout" ? this.state.group.items : []}
                                            snippet={this.state.asset.type && this.state.asset.type === "STRUCTURED_SNIPPET" ? this.renders.snippet_items() : []}//Array.isArray(this.state.group.items) && this.state.group.subtype && this.state.group.subtype.value === "snippet" ? this.state.group.items : []}
                                            phone={Array.isArray(this.renders.call()) && this.state.asset.type === "CALL" ? this.renders.call()[0] : []}//Array.isArray(this.state.group.items) && this.state.group.subtype && this.state.group.subtype.value === "call" ? (this.state.group.items[(this.state.group.combi_index ? this.state.group.combi_index : 0)]) : {}}
                                            image={false}//this.state.group.picture && this.state.group.subtype && this.state.group.subtype.value === "image" ? this.state.group.picture : false}
                                            businessLogo={this.state.asset.type && this.state.asset.type === "BUSINESS_LOGO" ? this.renders.businessLogo() : false}//this.state.group.business_logo !== "" && this.state.group.subtype && this.state.group.subtype.value === "business_logo" ? this.state.group.business_logo : false} 
                                        />
                                    }
                                </div>
                                {
                                    false &&
                                    this.state.group.subtype && this.state.group.subtype.value === "call" &&
                                    Array.isArray(this.state.group.items) &&
                                    this.state.group.items.length > 1 &&
                                    <div className="flex flex-row">
                                        <div onClick={() => {
                                            if (this.state.group.combi_index > 0) {
                                                this.state.group.combi_index = this.state.group.combi_index - 1;
                                                this.setState({
                                                    group: this.state.group
                                                })
                                            }
                                        }} className={(!("combi_index" in this.state.group) || this.state.group.combi_index === 0 ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-12 w-12 mr-4 rounded-full flex items-center justify-center"}>
                                            <ArrowSmLeftIcon className="w-5 h-5" />
                                        </div>
                                        <div onClick={() => {
                                            if (!this.state.group.combi_index) {
                                                this.state.group.combi_index = 0;
                                            }
                                            if (this.state.group.combi_index < (this.state.group.items.length - 1)) {
                                                this.state.group.combi_index = this.state.group.combi_index + 1;
                                                this.setState({
                                                    group: this.state.group
                                                })
                                            }
                                        }} className={(this.state.group.combi_index === (this.state.group.items.length - 1) ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-12 w-12 rounded-full flex items-center justify-center"}>
                                            <ArrowSmRightIcon className="w-5 h-5" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="px-4">
                        <div style={{ minHeight: "100%" }} className="border-r h-full"></div>
                    </div>
                    <div className="flex flex-1 flex-col h-full pb-4">
                        <div className="text-sm font-medium mb-4">
                            Settings
                        </div>
                        <div className="flex flex-1 overflow-scroll">
                            <div className="w-full">
                                <div className="grid grid-cols-12 gap-4 mb-4 w-full">
                                    {
                                        this.state.error &&
                                        <div className="col-span-12">
                                            <div className='text-red-500 text-sm p-4 col-span-6 bg-red-100 rounded-md'>{this.state.error}</div>
                                        </div>
                                    }
                                    <div className="col-span-12">
                                        <DropdownTailwind
                                            label={"Extension type"}
                                            locked={true}
                                            selected={{ id: 0, name: this.state.asset && this.state.asset.asset_type ? this.state.asset.asset_type : "Select type" }}
                                            options={[
                                            ]}
                                            onChange={async (value) => {

                                            }}
                                        />
                                    </div>
                                    {
                                        <div className="col-span-12">
                                            <InputTailwind
                                                value={this.state.asset.campaign ? this.state.asset.campaign : this.state.asset.adgroup}
                                                label={this.state.asset.campaign ? "Campaign asset" : "Adgroup asset"}
                                                locked={true}
                                            />
                                        </div>
                                    }

                                    {/*NUMBERS*/}
                                    {
                                        this.state.asset.type === "CALL" &&
                                        (Array.isArray(this.renders.call()) ? this.renders.call() : [{ id: Math.floor((Math.random() * 9999999999) + 1), country_code: { id: 0, name: "Select code" }, number: "" }]).map((item, index) => {
                                            return (
                                                <div className="col-span-12 grid grid-cols-12 gap-4">
                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            //error={item.country_code.id === 0 && extensionGoogle.validate(this.state.group).phone ? true : false}
                                                            label={"Country"}
                                                            searchInput={true}
                                                            selected={item.country_code ? item.country_code : { id: 0, name: "Select code" }}
                                                            options={this.state.codes.map((item) => { item.name = item.country + " (+" + item.code + ")"; return item })}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.call) {
                                                                    this.state.updated.call = JSON.parse(JSON.stringify(this.renders.call()));
                                                                }
                                                                this.state.updated.call[0].country_code = value;
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })


                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-8">
                                                        <InputTailwind
                                                            //error={item.number.length < 1 && extensionGoogle.validate(this.state.group).phone ? true : false}
                                                            value={item.number}
                                                            label={"Number"}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.call) {
                                                                    this.state.updated.call = JSON.parse(JSON.stringify(this.renders.call()));
                                                                }
                                                                this.state.updated.call[0].number = value;
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}

                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    {/*SITELINKS*/}
                                    {
                                        this.state.asset.type === "SITELINK" &&
                                        (Array.isArray(this.renders.sitelinks()) ? this.renders.sitelinks() : [{ id: Math.floor((Math.random() * 9999999999) + 1), text: "", description1: "", description2: "" }]).map((item, index) => {
                                            return (
                                                <div className="col-span-12 grid grid-cols-12 gap-4">
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            //error={(item.url == "" || !item.url.includes("https://")) && extensionGoogle.validate(this.state.group).sitelink ? true : false}
                                                            value={item.url}
                                                            //label={"Link " + (index + 1)}
                                                            label={"Website (ex www.your-website.com)"}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.sitelink) {
                                                                    this.state.updated.sitelink = JSON.parse(JSON.stringify(this.renders.sitelinks()));
                                                                }
                                                                this.state.updated.sitelink[0].url = value;
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })

                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            //error={item.text == "" && extensionGoogle.validate(this.state.group).sitelink ? true : false}
                                                            value={item.text}
                                                            label={"Title (ex Contact us)"}
                                                            maxLength={25}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.sitelink) {
                                                                    this.state.updated.sitelink = JSON.parse(JSON.stringify(this.renders.sitelinks()));
                                                                }
                                                                this.state.updated.sitelink[0].text = value;
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            value={item.description1}
                                                            maxLength={35}
                                                            label={"Description 1"}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.sitelink) {
                                                                    this.state.updated.sitelink = JSON.parse(JSON.stringify(this.renders.sitelinks()));
                                                                }
                                                                this.state.updated.sitelink[0].description1 = value;
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            value={item.description2}
                                                            maxLength={35}
                                                            label={"Description 2"}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.sitelink) {
                                                                    this.state.updated.sitelink = JSON.parse(JSON.stringify(this.renders.sitelinks()));
                                                                }
                                                                this.state.updated.sitelink[0].description2 = value;
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }


                                    {/*PRICE*/}
                                    {
                                        this.state.asset.type === "PRICE" &&
                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label={"Language"}
                                                    searchInput={true}
                                                    selected={this.renders.price() ? this.renders.price().language : { id: 0, name: "Select language" }}
                                                    options={this.state.languages.map((item, index) => { item.id = index + 1; item.name = item.Language; return item })}
                                                    onChange={async (value) => {
                                                        if (!this.state.updated.price) {
                                                            this.state.updated.price = JSON.parse(JSON.stringify(this.renders.price()));
                                                        }
                                                        this.state.updated.price.language = value;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                        this.functions.loadHeaders(value.Locale);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label={"Type"}
                                                    selected={this.renders.price() ? this.renders.price().price_type : { id: 0, name: "Select ..." }}
                                                    options={this.state.price_types}
                                                    onChange={async (value) => {
                                                        if (!this.state.updated.price) {
                                                            this.state.updated.price = JSON.parse(JSON.stringify(this.renders.price()));
                                                        }
                                                        this.state.updated.price.price_type = value;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label={"Currency"}
                                                    searchInput={true}
                                                    selected={this.renders.price() ? this.renders.price().currency : { id: 0, name: "Select ...", }}
                                                    options={this.state.currencies.map((item, index) => { return { id: index + 1, name: item, value: item } })}
                                                    onChange={async (value) => {
                                                        if (!this.state.updated.price) {
                                                            this.state.updated.price = JSON.parse(JSON.stringify(this.renders.price()));
                                                        }
                                                        this.state.updated.price.currency = value;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })

                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label={"Price quilifier"}
                                                    selected={this.renders.price() ? this.renders.price().price_qualifier : { id: 1, name: "No qualifier", value: "UNSPECIFIED", preview: "" }}
                                                    options={this.state.price_qualifier_options}
                                                    onChange={async (value) => {
                                                        if (!this.state.updated.price) {
                                                            this.state.updated.price = JSON.parse(JSON.stringify(this.renders.price()));
                                                        }
                                                        this.state.updated.price.price_qualifier = value;
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })

                                                    }}
                                                />
                                            </div>

                                        </div>
                                    }


                                    {
                                        this.state.asset.type === "PRICE" &&
                                        (this.renders.price() && Array.isArray(this.renders.price().items) ? this.renders.price().items : [{ id: Math.floor((Math.random() * 9999999999) + 1), text: "", description1: "", description2: "", key: "", value: "", url: "", mobile_url: "", unit: { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" } }]).map((item, index) => {
                                            return (
                                                <div className="col-span-12 grid grid-cols-12 gap-4">
                                                    <div className="col-span-12 border-t pt-2">
                                                        <InputTailwind
                                                            //error={item.text == "" && extensionGoogle.validate(this.state.group).price ? true : false}
                                                            value={item.text}
                                                            maxLength={25}
                                                            label={"Price asset"}
                                                            placeholder={"Header"}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.price) {
                                                                    this.state.updated.price = JSON.parse(JSON.stringify(this.renders.price()));
                                                                }
                                                                if (!Array.isArray(this.state.updated.price.items)) {
                                                                    this.state.updated.price.items = [item];
                                                                }
                                                                this.state.updated.price.items = JSON.parse(JSON.stringify(this.state.updated.price.items)).map((inner_item) => {
                                                                    if (inner_item.id === item.id) {
                                                                        inner_item.text = value;
                                                                    }
                                                                    return inner_item
                                                                });
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                            rightLabel={false}
                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                            rightLabelText={"Remove"}
                                                            onRightLabelClick={() => {
                                                                this.state.group.items = this.state.group.items.filter((number) => { return number.id !== item.id });
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            //error={item.description1 == "" && extensionGoogle.validate(this.state.group).price ? true : false}
                                                            value={item.description1}
                                                            placeholder={"Discription"}
                                                            maxLength={25}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.price) {
                                                                    this.state.updated.price = JSON.parse(JSON.stringify(this.renders.price()));
                                                                }
                                                                if (!Array.isArray(this.state.updated.price.items)) {
                                                                    this.state.updated.price.items = [item];
                                                                }
                                                                this.state.updated.price.items = JSON.parse(JSON.stringify(this.state.updated.price.items)).map((inner_item) => {
                                                                    if (inner_item.id === item.id) {
                                                                        inner_item.description1 = value;
                                                                    }
                                                                    return inner_item
                                                                });
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <InputTailwind
                                                            value={item.number}
                                                            label={"Amount"}
                                                            type={"number"}
                                                            leftSection={(this.renders.price() && this.renders.price().currency && this.renders.price().currency.value ? true : false)}
                                                            leftSectionLabel={(this.renders.price() && this.renders.price().currency && this.renders.price().currency.value ? this.renders.price().currency.value : "")}
                                                            maxLength={35}
                                                            placeholder={"0 " + this.renders.price() && this.renders.price().currency && this.renders.price().currency.value ? this.renders.price().currency.value : ""}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.price) {
                                                                    this.state.updated.price = JSON.parse(JSON.stringify(this.renders.price()));
                                                                }
                                                                if (!Array.isArray(this.state.updated.price.items)) {
                                                                    this.state.updated.price.items = [item];
                                                                }
                                                                this.state.updated.price.items = JSON.parse(JSON.stringify(this.state.updated.price.items)).map((inner_item) => {
                                                                    if (inner_item.id === item.id) {
                                                                        inner_item.number = value < 0 ? 0 : value;
                                                                    }
                                                                    return inner_item
                                                                });
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <DropdownTailwind
                                                            label={"Unit"}
                                                            selected={item.unit ? item.unit : { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" }}
                                                            options={this.state.unit_types}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.price) {
                                                                    this.state.updated.price = JSON.parse(JSON.stringify(this.renders.price()));
                                                                }
                                                                if (!Array.isArray(this.state.updated.price.items)) {
                                                                    this.state.updated.price.items = [item];
                                                                }
                                                                this.state.updated.price.items = JSON.parse(JSON.stringify(this.state.updated.price.items)).map((inner_item) => {
                                                                    if (inner_item.id === item.id) {
                                                                        inner_item.unit = value;
                                                                    }
                                                                    return inner_item
                                                                });
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            //error={(item.url == "" || !item.url.includes("https://")) && extensionGoogle.validate(this.state.group).price ? true : false}

                                                            label={"Final URL"}
                                                            value={item.url}
                                                            placeholder={"Final URL"}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.price) {
                                                                    this.state.updated.price = JSON.parse(JSON.stringify(this.renders.price()));
                                                                }
                                                                if (!Array.isArray(this.state.updated.price.items)) {
                                                                    this.state.updated.price.items = [item];
                                                                }
                                                                this.state.updated.price.items = JSON.parse(JSON.stringify(this.state.updated.price.items)).map((inner_item) => {
                                                                    if (inner_item.id === item.id) {
                                                                        inner_item.url = value;
                                                                    }
                                                                    return inner_item
                                                                });
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            label={"Mobile final URL (optional)"}
                                                            value={item.mobile_url}
                                                            placeholder={"Mobile final URL (optional)"}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.price) {
                                                                    this.state.updated.price = JSON.parse(JSON.stringify(this.renders.price()));
                                                                }
                                                                if (!Array.isArray(this.state.updated.price.items)) {
                                                                    this.state.updated.price.items = [item];
                                                                }
                                                                this.state.updated.price.items = JSON.parse(JSON.stringify(this.state.updated.price.items)).map((inner_item) => {
                                                                    if (inner_item.id === item.id) {
                                                                        inner_item.mobile_url = value;
                                                                    }
                                                                    return inner_item
                                                                });
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        false &&
                                        this.state.group.subtype && this.state.group.subtype.value === "price" &&
                                        <div onClick={() => {
                                            this.state.group.items.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "", description1: "", description2: "", key: "", value: "", url: "", mobile_url: "", unit: { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" } });
                                            this.setState({
                                                group: this.state.group
                                            });
                                        }} className="col-span-12 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                            Add a price asset
                                        </div>
                                    }



                                    {/*IMAGE*/}
                                    {
                                        false &&
                                        this.state.group.subtype && this.state.group.subtype.value === "image" &&
                                        <div className="col-span-12">
                                            <UploadImageTailwind
                                                error={extensionGoogle.validate(this.state.group).picture}
                                                label={"Upload image"}
                                                client={this.state.client}
                                                onChange={(value) => {
                                                    this.state.group.picture = value.url;
                                                    this.setState({
                                                        group: this.state.group
                                                    })
                                                }}
                                            />
                                        </div>
                                    }

                                    {/*BUISNESS LOGO*/}
                                    {
                                        false &&
                                        this.state.group.subtype && this.state.group.subtype.value === "business_logo" &&
                                        <div className="col-span-12">
                                            <UploadImageTailwind
                                                //error={extensionGoogle.validate(this.state.group).business_logo}
                                                label={"Upload image"}
                                                client={this.state.client}
                                                onChange={async (value) => {
                                                    this.state.updated.business_logo = value.url;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    }

                                    {/*CALLOUT*/}
                                    {
                                        this.state.asset.type === "CALLOUT" &&
                                        <div className="col-span-12">
                                            <div className="col-span-12 mt-2 grid grid-cols-12 gap-4">
                                                <div className="col-span-12">
                                                    <InputTailwind
                                                        //error={item.callout == "" && extensionGoogle.validate(this.state.group).callout ? true : false}
                                                        value={this.renders.callout()}
                                                        label={"Callout"}
                                                        maxLength={25}
                                                        onChange={async (value) => {
                                                            this.state.updated.callout = value;
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {
                                                false &&
                                                this.state.group.subtype && this.state.group.subtype.value === "callout" && this.state.group.items.length < 20 &&
                                                <div onClick={() => {
                                                    this.state.group.items.push({ id: Math.floor((Math.random() * 9999999999) + 1), callout: "" });
                                                    this.setState({
                                                        group: this.state.group
                                                    });
                                                }} className="col-span-12 mt-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                    Add new callout
                                                </div>
                                            }
                                        </div>
                                    }

                                    {/*SNIPPET*/}
                                    {
                                        this.state.asset && this.state.asset.type === "STRUCTURED_SNIPPET" &&
                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    //error={this.state.group.language.id === 0 && extensionGoogle.validate(this.state.group).language ? true : false}
                                                    label={"Language"}
                                                    searchInput={true}
                                                    selected={this.renders.snipet_language()}
                                                    options={this.state.languages.map((item, index) => { item.id = index + 1; item.name = item.Language; return item })}
                                                    onChange={async (value) => {
                                                        this.state.updated.snipet_language = value
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });
                                                        this.functions.loadHeaders(value.Locale);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    //error={this.state.group.header.id === 0 && extensionGoogle.validate(this.state.group).header ? true : false}
                                                    label={"Header"}
                                                    selected={this.renders.snippet_header()}
                                                    options={this.state.headers}
                                                    onChange={async (value) => {
                                                        this.state.updated.snippet_header = value
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {
                                                Array.isArray(this.renders.snippet_items()) &&
                                                this.renders.snippet_items().map((item, index) => {
                                                    return (
                                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                                            <div className="col-span-12">
                                                                <InputTailwind
                                                                    //error={item.text == "" && extensionGoogle.validate(this.state.group).snippet ? true : false}
                                                                    value={item.text}
                                                                    maxLength={25}
                                                                    label={"Structured snippet value"}
                                                                    onChange={async (value) => {
                                                                        if (!this.state.updated.snippet) {
                                                                            this.state.updated.snippet = JSON.parse(JSON.stringify(this.renders.snippet_items()));
                                                                        }
                                                                        this.state.updated.snippet[index].text = value;
                                                                        //item.text = value;

                                                                        await this.promisedSetState({
                                                                            updated: this.state.updated
                                                                        })
                                                                    }}
                                                                    rightLabel={this.renders.snippet_items().length > 3}
                                                                    rightLabelColor={"hover:text-red-700 text-red-500"}
                                                                    rightLabelText={"Remove"}
                                                                    onRightLabelClick={async () => {
                                                                        if (!this.state.updated.snippet) {
                                                                            this.state.updated.snippet = JSON.parse(JSON.stringify(this.renders.snippet_items()));
                                                                        }
                                                                        this.state.updated.snippet[index] = false;
                                                                        this.state.updated.snippet = this.state.updated.snippet.filter((snippet) => { return snippet });
                                                                        //this.state.group.items = this.state.group.items.filter((snippet) => { return snippet.id !== item.id });
                                                                        await this.promisedSetState({
                                                                            updated: this.state.updated
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.asset && this.state.asset.type === "STRUCTURED_SNIPPET" &&
                                        this.renders.snippet_items().length < 10 &&
                                        <div onClick={async () => {
                                            this.state.updated.snippet = JSON.parse(JSON.stringify(this.renders.snippet_items()));
                                            this.state.updated.snippet.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
                                            //this.state.group.items.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
                                        }} className="col-span-12 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                            Add new values
                                        </div>
                                    }

                                    {/*PROMOTION*/}
                                    {
                                        this.state.asset && this.state.asset.type === "PROMOTION" &&
                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                                <div className="col-span-4">
                                                    <DropdownTailwind
                                                        label={"Promotion type"}
                                                        selected={this.renders.promotion()}
                                                        options={this.state.promotion_types}
                                                        onChange={async (value) => {
                                                            this.state.updated.promotion = value
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-4">
                                                    <InputTailwind
                                                        //error={(this.state.group.promotion_value === "" || this.state.group.promotion_value < 1) && extensionGoogle.validate(this.state.group).promotion ? true : false}
                                                        maxLength={(this.renders.promotion().id === 2 || this.renders.promotion().id === 4 ? 2 : 20)}
                                                        value={this.renders.promotion_value()}
                                                        type={"number"}
                                                        label={"Value"}
                                                        onChange={async (value) => {
                                                            if (value < 0) value = 0;

                                                            this.state.updated.promotion_value = value
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    (this.renders.promotion().id === 1 || this.renders.promotion().id === 3) &&
                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            label={"Currency"}
                                                            searchInput={true}
                                                            selected={this.renders.currency()}
                                                            options={this.state.currencies.map((item, index) => { return { id: index + 1, name: item, value: item } })}
                                                            onChange={async (value) => {
                                                                this.state.updated.currency = value
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        //error={this.state.group.language.id === 0 && extensionGoogle.validate(this.state.group).language ? true : false}
                                                        label={"Language"}
                                                        searchInput={true}
                                                        selected={this.renders.promotion_language()}
                                                        options={this.state.languages.map((item, index) => { item.id = index + 1; item.name = item.Language; return item })}
                                                        onChange={async (value) => {
                                                            this.state.updated.promotion_language = value
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Occasion"}
                                                        selected={this.renders.occasions()}//: { id: 0, name: "None", value: "" }}
                                                        options={this.state.occasions.map((item, index) => { return { id: index, name: item.name, value: item.value, start: item.start, end: item.end } })}
                                                        onChange={async (value) => {
                                                            this.state.updated.occasion = value
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {
                                                this.renders.occasions() && this.renders.occasions().id > 0 && this.renders.occasions().start !== "" &&
                                                <div className="col-span-12">
                                                    <div className="text-gray-600 text-xs">
                                                        {this.renders.occasions().name} promotions are only eligible to show from {this.renders.occasions().start} - {this.renders.occasions().end}.
                                                        You can narrow this date range in "Advanced option" below.
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                                <div className="col-span-12">
                                                    <InputTailwind
                                                        //error={this.state.group.promotion_description === "" && extensionGoogle.validate(this.state.group).description ? true : false}
                                                        label={"Item"}
                                                        value={this.renders.promotion_description()}
                                                        maxLength={20}
                                                        onChange={async (value) => {
                                                            this.state.updated.promotion_description = value
                                                            this.setState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                                <div className="col-span-12">
                                                    <InputTailwind
                                                        //error={(this.state.group.promotion_url === "" || !this.state.group.promotion_url.includes("https://")) && extensionGoogle.validate(this.state.group).link ? true : false}
                                                        value={this.renders.promotion_url()}
                                                        label={"Final url"}
                                                        onChange={async (value) => {
                                                            this.state.updated.promotion_url = value
                                                            this.setState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Promotion details"}
                                                        selected={this.renders.promotion_detail()}
                                                        options={[{ id: 0, name: "None", value: "" }, { id: 1, name: "On orders over", value: "On orders over" }, { id: 2, name: "Promo code", value: "Code" }]}
                                                        onChange={async (value) => {
                                                            this.state.updated.promotion_detail = value
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    this.renders.promotion_detail().id !== 0 &&
                                                    <div className="col-span-6">
                                                        <InputTailwind
                                                            maxLength={20}
                                                            value={this.renders.promotion_detail_value()}
                                                            label={this.renders.promotion_detail().name}
                                                            onChange={async (value) => {
                                                                this.state.updated.promotion_detail_value = value
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-span-12">
                                                <div className="col-span-6">
                                                    <div className="grid grid-cols-6 gap-4">
                                                        <div className="col-span-3">
                                                            <InputDatepickerTailwind
                                                                label={"Displayed start date"}
                                                                labelRight={this.renders.promotion_start_date() ? "Enabled" : "Disabled"}
                                                                placeholder={!this.renders.promotion_start_date() ? "No start date" : ""}
                                                                disabled={!this.renders.promotion_start_date()}
                                                                value={this.renders.promotion_start_date() ? moment(this.renders.promotion_start_date()).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                                                                onChange={async (value) => {
                                                                    this.state.updated.promotion_start_date = value;
                                                                    await this.promisedSetState({
                                                                        updated: this.state.updated
                                                                    })
                                                                }}
                                                                onLabelRightClick={async (value) => {
                                                                    if (this.renders.promotion_start_date()) {
                                                                        this.state.updated.promotion_start_date = null;
                                                                    } else {
                                                                        this.state.updated.promotion_start_date = moment().format("YYYY-MM-DD");
                                                                    }
                                                                    await this.promisedSetState({
                                                                        updated: this.state.updated
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-span-3">
                                                            <InputDatepickerTailwind
                                                                label={"Displayed end date"}
                                                                labelRight={this.renders.promotion_end_date() ? "Enabled" : "Disabled"}
                                                                placeholder={!this.renders.promotion_end_date() ? "No end date" : ""}
                                                                disabled={!this.renders.promotion_end_date()}
                                                                value={moment(this.renders.promotion_end_date()).format("YYYY-MM-DD")}
                                                                onChange={async (value) => {
                                                                    this.state.updated.promotion_end_date = value;
                                                                    await this.promisedSetState({
                                                                        updated: this.state.updated
                                                                    })
                                                                }}
                                                                onLabelRightClick={async (value) => {
                                                                    if (this.renders.promotion_end_date()) {
                                                                        this.state.updated.promotion_end_date = null;
                                                                    } else {
                                                                        let new_date = moment(this.renders.promotion_start_date()).add(1, 'days');
                                                                        this.state.updated.promotion_end_date = moment(new_date).format("YYYY-MM-DD");
                                                                    }
                                                                    await this.promisedSetState({
                                                                        updated: this.state.updated
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/*BUISNESSNAME*/}
                                    {

                                        this.state.asset && this.state.asset.type === "BUSINESS_NAME" &&
                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                            <div className="col-span-12">
                                                <InputTailwind
                                                    //error={this.state.group.business_name === "" && extensionGoogle.validate(this.state.group).business_name ? true : false}
                                                    label={"Business name"}
                                                    locked={true}
                                                    value={this.state.asset.name}
                                                    maxLength={20}
                                                    onChange={async (value) => {
                                                        /*this.state.updated.business_name = value
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })*/
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        false &&
                                        this.state.group.subtype && (this.state.group.subtype.value === "sitelink" || this.state.group.subtype.value === "promotion" || this.state.group.subtype.value === "price") &&
                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                            <div onClick={() => {
                                                if (this.state.group.tracking === true) {
                                                    this.state.group.tracking = false;
                                                } else {
                                                    this.state.group.tracking = true;
                                                }

                                                this.setState({
                                                    group: this.state.group
                                                });
                                            }} className="col-span-12 flex flex-row items-center font-medium text-xs cursor-pointer text-purple-500 hover:text-purple-700">
                                                Url options
                                                {
                                                    this.state.group.tracking === true &&
                                                    <ChevronDoubleUpIcon className="w-3 h-3 ml-2" />
                                                }
                                                {
                                                    this.state.group.tracking === false &&
                                                    <ChevronDoubleDownIcon className="w-3 h-3 ml-2" />
                                                }
                                            </div>

                                            {
                                                this.state.group.tracking &&
                                                <div className="col-span-12 grid grid-cols-12 gap-4">
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            value={this.state.group.tracking_options.template}
                                                            label={"Tracking template"}
                                                            onChange={async (value) => {
                                                                this.state.group.tracking_options.template = value
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            value={this.state.group.tracking_options.suffix}
                                                            label={"Final URL suffix"}
                                                            onChange={async (value) => {
                                                                this.state.group.tracking_options.suffix = value
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        (Array.isArray(this.state.group.tracking_items) ? this.state.group.tracking_items : [{ id: Math.floor((Math.random() * 9999999999) + 1), key: "", value: "" }]).map((item, index) => {
                                                            return (
                                                                <div className="col-span-12 grid grid-cols-12 gap-4">
                                                                    <div className="col-span-3">
                                                                        <InputTailwind
                                                                            value={item.key}
                                                                            label={"Key"}
                                                                            onChange={async (value) => {
                                                                                item.key = value;
                                                                                if (!Array.isArray(this.state.group.tracking_items)) {
                                                                                    this.state.group.tracking_items = [item];
                                                                                }
                                                                                this.setState({
                                                                                    group: this.state.group
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-span-9">
                                                                        <InputTailwind
                                                                            value={item.value}
                                                                            label={"Value"}
                                                                            onChange={async (value) => {
                                                                                item.value = value;
                                                                                if (!Array.isArray(this.state.group.tracking_items)) {
                                                                                    this.state.group.tracking_items = [item];
                                                                                }
                                                                                this.setState({
                                                                                    group: this.state.group
                                                                                })
                                                                            }}
                                                                            rightLabel={Array.isArray(this.state.group.tracking_items) && this.state.group.tracking_items.length > 1}
                                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                                            rightLabelText={"Remove"}
                                                                            onRightLabelClick={() => {
                                                                                this.state.group.tracking_items = this.state.group.tracking_items.filter((url) => { return url.id !== item.id });
                                                                                this.setState({
                                                                                    group: this.state.group
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    <div className="col-span-12">
                                                        <div className="inline-flex">
                                                            <div onClick={() => {
                                                                this.state.group.tracking_items.push({ id: Math.floor((Math.random() * 9999999999) + 1), key: "", value: "" });
                                                                this.setState({
                                                                    group: this.state.group
                                                                });
                                                            }} className="bg-purple-100 flex items-center flex-row rounded-full px-4 py-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                                Add new parameter
                                                                <PlusCircleIcon className="w-3 h-3 ml-1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {

                                                        this.state.group.subtype.value !== "price" &&
                                                        <div onClick={() => {
                                                            if (this.state.group.mobile === true) {
                                                                this.state.group.mobile = false;
                                                            } else {
                                                                this.state.group.mobile = true;
                                                            }
                                                            this.setState({
                                                                group: this.state.group
                                                            });
                                                        }} className="col-span-12 flex flex-row items-center font-medium text-xxs cursor-pointer text-purple-500 hover:text-purple-700">

                                                            {
                                                                this.state.group.mobile === true &&
                                                                <CheckCircleIcon className="w-3 h-3 mr-1" />
                                                            }
                                                            {
                                                                this.state.group.mobile === false &&
                                                                <XCircleIcon className="w-3 h-3 mr-1" />
                                                            }
                                                            Use a different final URL for mobile
                                                        </div>
                                                    }
                                                    {
                                                        this.state.group.mobile &&
                                                        <div className="col-span-12">
                                                            <InputTailwind
                                                                value={this.state.group.tracking_options.mobile_url}
                                                                label={"Mobile URL"}
                                                                onChange={async (value) => {
                                                                    this.state.group.tracking_options.mobile_url = value
                                                                    this.setState({
                                                                        group: this.state.group
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }

                                    {
                                        this.state.asset && (this.state.asset.type === "PROMOTION" || this.state.asset.type === "CALLOUT" || this.state.asset.type === "CALL" || this.state.asset.type === "SITELINK") &&
                                        //this.state.group.subtype && this.state.group.subtype.value !== "snippet" && this.state.group.subtype.value !== "price" && this.state.group.subtype.value !== "business_logo" && this.state.group.subtype.value !== "business_name" &&
                                        <div className="col-span-12 mt-2">
                                            <div onClick={() => {
                                                if (this.state.schedule === true) {
                                                    this.state.schedule = false;
                                                } else {
                                                    this.state.schedule = true;
                                                }

                                                this.setState({
                                                    schedule: this.state.schedule
                                                });
                                            }} className="col-span-12 flex flex-row items-center font-medium text-xs cursor-pointer text-purple-500 hover:text-purple-700">
                                                Advanced options
                                                {
                                                    this.state.schedule === true &&
                                                    <ChevronDoubleUpIcon className="w-3 h-3 ml-2" />
                                                }
                                                {
                                                    this.state.schedule === false &&
                                                    <ChevronDoubleDownIcon className="w-3 h-3 ml-2" />
                                                }
                                            </div>
                                        </div>
                                    }



                                    {

                                        this.state.schedule && this.state.asset && (this.state.asset.type === "PROMOTION" || this.state.asset.type === "CALLOUT" || this.state.asset.type === "SITELINK") &&
                                        <div className="col-span-12 mt-2">
                                            <div className="grid grid-cols-6 gap-4">
                                                <div className="col-span-3">
                                                    <InputDatepickerTailwind
                                                        label={"Start date"}
                                                        labelRight={this.state.start_date ? "Enabled" : "Disabled"}
                                                        placeholder={!this.state.start_date ? "No start date" : ""}
                                                        disabled={!this.state.start_date}
                                                        value={this.state.start_date ? moment(this.state.start_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            this.state.start_date = value;
                                                            this.state.updated.fake = true;
                                                            await this.promisedSetState({
                                                                start_date: this.state.start_date,
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                        onLabelRightClick={async (value) => {
                                                            if (this.state.start_date) {
                                                                this.state.start_date = null;
                                                            } else {
                                                                this.state.start_date = moment().format("YYYY-MM-DD");
                                                            }

                                                            this.state.updated.fake = true;
                                                            await this.promisedSetState({
                                                                start_date: this.state.start_date,
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-3">
                                                    <InputDatepickerTailwind
                                                        label={"End date"}
                                                        labelRight={this.state.end_date ? "Enabled" : "Disabled"}
                                                        placeholder={!this.state.end_date ? "No end date" : ""}
                                                        disabled={!this.state.end_date}
                                                        value={moment(this.state.end_date).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            this.state.end_date = value;

                                                            this.state.updated.fake = true;
                                                            await this.promisedSetState({
                                                                end_date: this.state.end_date,
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                        onLabelRightClick={async (value) => {
                                                            if (this.state.end_date) {
                                                                this.state.end_date = null;
                                                            } else {
                                                                let new_date = moment(this.state.start_date).add(1, 'days');
                                                                this.state.end_date = moment(new_date);
                                                            }

                                                            this.state.updated.fake = true;
                                                            await this.promisedSetState({
                                                                end_date: this.state.end_date,
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    }




                                    {

                                        this.state.schedule && this.state.asset && (this.state.asset.type === "PROMOTION" || this.state.asset.type === "CALLOUT" || this.state.asset.type === "CALL" || this.state.asset.type === "SITELINK") &&
                                        Array.isArray(this.renders.days()) && this.renders.days().length > 0 &&
                                        this.renders.days().map((item, index) => {
                                            return (
                                                <div className="col-span-12 mt-2 grid grid-cols-12 gap-4">

                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            label={"Day"}
                                                            selected={item}
                                                            options={this.state.days.filter((inner_item) => {
                                                                let buffer = true;
                                                                this.renders.days().map((inner_inner_item) => {
                                                                    if (inner_inner_item.value === inner_item.value) {
                                                                        buffer = false
                                                                    }
                                                                })
                                                                return buffer
                                                            })}
                                                            onChange={async (value) => {
                                                                if (!this.state.updated.days) {
                                                                    this.state.updated.days = JSON.parse(JSON.stringify(this.renders.days()));
                                                                }
                                                                this.state.updated.days[index].value = value.value;
                                                                this.state.updated.days[index].name = value.name;
                                                                this.state.updated.days[index].time = value.time;
                                                                //console.log(this.state.updated.days, "DAYSSS")
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                            rightLabel={Array.isArray(this.renders.days()) && this.renders.days().length > 1}
                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                            rightLabelText={"Remove"}
                                                            onRightLabelClick={() => {
                                                                this.state.updated.days = this.renders.days().filter((number) => { return number.id !== item.id });
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            label={"Start time"}
                                                            locked={this.renders.start_end(index)}
                                                            selected={this.renders.startHour(index)}
                                                            options={this.state.hours_options.slice(0, this.renders.days().length > 0 ? this.renders.days()[index].end.id : -1)}
                                                            onChange={(value) => {
                                                                if (!this.state.updated.days) {
                                                                    this.state.updated.days = JSON.parse(JSON.stringify(this.renders.days()));
                                                                }
                                                                this.state.updated.days[index].start = value;
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            label={"End time"}
                                                            locked={this.renders.start_end(index)}
                                                            selected={this.renders.endHour(index)}
                                                            options={this.state.hours_options.slice(this.renders.days().length > 0 ? this.renders.days()[index].start.id : 1, this.state.hours_options.length)}
                                                            onChange={(value) => {
                                                                if (!this.state.updated.days) {
                                                                    this.state.updated.days = JSON.parse(JSON.stringify(this.renders.days()));
                                                                }
                                                                this.state.updated.days[index].end = value;
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })


                                    }

                                    {
                                        this.state.schedule && this.state.asset && (this.state.asset.type === "PROMOTION" || this.state.asset.type === "CALLOUT" || this.state.asset.type === "CALL" || this.state.asset.type === "SITELINK") && this.renders.days().length < 7 &&
                                        <div onClick={() => {
                                            if (!this.state.updated.days) {
                                                this.state.updated.days = JSON.parse(JSON.stringify(this.renders.days()));
                                            }
                                            this.state.updated.days.push({ id: Math.floor((Math.random() * 9999999999) + 1), name: "Choose day", time: { id: 0, name: "Not specified" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } });
                                            this.setState({
                                                updated: this.state.updated
                                            });
                                        }} className="col-span-12 mt-4 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                            Add new schedule
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditLiveGoogleAssets;
