import {apiRegister} from "../../services/apiRegister";
import {tokenRegister} from "../../services/tokenRegister";

class calls {

    static url = {
        api: apiRegister.url.api,
    };

    static getAd(internal, id) {
        let options = apiRegister.options(tokenRegister.get(), 'GET', null);
        let url = calls.url.api + "/ad/" + internal + '/' + id;
        return apiRegister.call(options, url);
    }

    static updateAd(internal, id, status) {
        let options = apiRegister.options(tokenRegister.get(), 'PUT', null);
        let url = calls.url.api + "/ad/" + internal + '/' + id + '?status=' + status;
        return apiRegister.call(options, url);
    }

}

export {calls}