import React, { Component, Fragment } from 'react';
import FeatherIcon from "feather-icons-react";
import moment from 'moment';
import fuzzysort from 'fuzzysort';
import SlideDown from 'react-slidedown';
import { BeatLoader } from 'react-spinners';
import 'react-slidedown/lib/slidedown.css';
import 'sweetalert/dist/sweetalert.css';
import { CirclePicker } from 'react-color';
import SingleDatepicker from "../../../modules/singleDatepicker";
import CreateCustomMetric from "../../../modules/createCustomMetric";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import GoogleAnalyticsDimensions from '../../../assets/json/dimensions/google_analytics.json';
import GoogleAnalytics4Dimensions from '../../../assets/json/dimensions/google_analytics_4.json';

class ReportComponentsSlideIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            all_metrics: {},
            datasource: true,
            data: {},
            tab: {},
            default_metrics: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_campaigns: {},
            selected_adgroups: {},
            selected_ads: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            premium: false,
            celltype: true,
            custom_metrics: [],
            calculated_metrics: [],
            channel_breakdowns: [],
            analytics_breakdowns: [],
            ga_dimensions: GoogleAnalyticsDimensions,
            ga4_dimensions: GoogleAnalytics4Dimensions
        };
    };

    componentDidMount() {
        this.setState({
            closed: this.props.closed,
            all_metrics: this.props.metrics,
            premium: this.props.premium,
            campaigns: this.props.campaigns,
            adgroups: this.props.adgroups,
            ads: this.props.ads,
            template: this.props.template,
            default_metrics: this.props.default_metrics,
        }, () => {

            let custom_metrics = [];
            if (this.state.all_metrics.custom) {
                custom_metrics = custom_metrics.concat(this.state.all_metrics.custom);
            }
            if (this.state.all_metrics.calculated) {
                custom_metrics = custom_metrics.concat(this.state.all_metrics.calculated);
            }
            this.setState({
                custom_metrics: custom_metrics
            })

        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            closed: nextProps.closed,
            all_metrics: nextProps.metrics,
            premium: nextProps.premium,
            template: nextProps.template,
            campaigns: nextProps.campaigns,
            adgroups: nextProps.adgroups,
            ads: nextProps.ads,
        }, () => {

            let custom_metrics = [];
            if (this.state.all_metrics.custom) {
                custom_metrics = custom_metrics.concat(this.state.all_metrics.custom);
            }
            if (this.state.all_metrics.calculated) {
                custom_metrics = custom_metrics.concat(this.state.all_metrics.calculated);
            }
            this.setState({
                custom_metrics: custom_metrics
            })

        });
    }

    functions = {
        initiate: (campaigns, adgroups, ads, group) => {

            this.renders.channelBreakdowns();

            this.setState({
                datasource: !this.state.premium,
                breakdown: false,
                daterange: false,
                piechart: false,
                settings: false,
                init_tab: { ...this.props.tab },
                tab: { ...this.props.tab },
                selected_campaigns_internal: JSON.parse(JSON.stringify(campaigns)),
                selected_adgroups_internal: JSON.parse(JSON.stringify(adgroups)),
                selected_ads_internal: JSON.parse(JSON.stringify(ads)),
            }, () => {

                let temp_data = this.state.tab.cells;
                delete this.state.tab.cells;
                let tab = JSON.parse(JSON.stringify(this.state.tab));
                tab.cells = temp_data;

                if (!this.state.init_tab.channel_breakdowns) {
                    tab.channel_breakdowns = {
                        "google_analytics": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "google_analytics_4": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "adform": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "facebook": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "google": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "tiktok": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "linkedin": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "twitter": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "snapchat": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "bing": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "dv360": { "overview": { name: "Overview", value: "overview", parent: "Overview" } },
                        "basic": {
                            "overview": {
                                title: "Overview",
                                subtitle: "Agregated data",
                                icon: "globe",
                                value: "overview",
                                supported_channels: { "twitter": true, "tiktok": true, "snapchat": true, "google": true, "linkedin": true, "facebook": true, "google_analytics": true, "google_analytics_4": true, "google_shopping": true, "adform": true }
                            }
                        }
                    };
                }

                if (this.state.init_tab.channel_breakdowns && !this.state.init_tab.channel_breakdowns.google_analytics_4) {
                    tab.channel_breakdowns.google_analytics_4 = { "overview": { name: "Overview", value: "overview", parent: "Overview" } };
                }

                //tab.table_metrics = {};
                //tab.table_metrics_index = [];

                /*
                if (tab.metrics && tab.metrics.custom) {
                    tab.table_metrics = {};
                    tab.table_metrics_index = [];
                    for (let metric in tab.metrics.custom) {
                        tab.metrics.custom[metric].metric_type = "custom";
                        tab.table_metrics[metric] = tab.metrics.custom[metric];
                        tab.table_metrics_index.push(tab.metrics.custom[metric]);
                    }
                }
                */

                /*
                if (this.state.tab.metrics) {
                    this.state.channel_metrics = [];
                    for (let channel in this.state.tab.metrics) {
                        if (channel !== "custom") {
                            for (let metric in this.state.tab.metrics[channel]) {
                                this.state.tab.metrics[channel][metric].channels = {};
                                this.state.tab.metrics[channel][metric].code = 'code';
                                this.state.tab.metrics[channel][metric].title = this.state.tab.metrics[channel][metric].name;
                                this.state.tab.metrics[channel][metric].channels[channel] = JSON.parse(JSON.stringify(this.state.tab.metrics[channel][metric]));
                                this.state.channel_metrics.push(this.state.tab.metrics[channel][metric]);
                            }
                        }
                    }
                }
                */

                if (!tab.piechart) {
                    tab.piechart = {
                        "impressions": {
                            title: "Impressions",
                            subtitle: "Show total impressions per platform",
                            icon: "pie-chart",
                            value: "impressions"
                        },
                        "click": {
                            title: "Clicks",
                            subtitle: "Show total click per platform",
                            icon: "pie-chart",
                            value: "click"
                        },
                        "ctr": {
                            title: "Avg. CTR",
                            subtitle: "Show average CTR per platform",
                            icon: "pie-chart",
                            value: "ctr"
                        },
                        "cpc": {
                            title: "Avg. CPC",
                            subtitle: "Show average CPC per platform",
                            icon: "pie-chart",
                            value: "cpc"
                        },
                        "spend": {
                            title: "Spend",
                            subtitle: "Show total spend per platform",
                            icon: "pie-chart",
                            value: "spend"
                        }
                    };
                }

                this.setState({
                    datasource: this.state.datasource,
                    channel_metrics: this.state.channel_metrics ? this.state.channel_metrics : [],
                    tab: tab
                })

            })
        }
    };

    renders = {
        metrics: (items) => {
            if (Array.isArray(items)) {
                items = items.map((item) => {
                    item.title = item.name;
                    item.subtitle = "Summarize all " + item.name;
                    item.icon = "code";
                    item.value = item.id + item.name;
                    return item;
                });
                return Array.isArray(items) ? items : [];
            } else {
                return [];
            }
        },
        analyticsBreakDowns: () => {
            let breakdowns = [
                {
                    title: "User",
                    values: [
                        { name: "User Type", value: "ga:userType" },
                        { name: "Count of Sessions", value: "ga:sessionCount" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdowns: () => {
            let breakdowns = [
                {
                    title: "Overview",
                    subtitle: "Agregated data",
                    icon: "globe",
                    value: "overview",
                    supported_channels: { "tiktok": true, "twitter": true, "google": true, "linkedin": true, "facebook": true, "google_analytics": true, "google_analytics_4": true, "google_shopping": true, "adform": true }
                },
                {
                    title: "Daily",
                    subtitle: "Agregated data per 24h",
                    icon: "sun",
                    value: "daily",
                    supported_channels: { "tiktok": true, "twitter": true, "google": true, "linkedin": true, "facebook": true, "google_analytics": true, "google_analytics_4": true, "google_shopping": true, "adform": true }
                },
                {
                    title: "Gender",
                    subtitle: "Agregated data based on gender",
                    icon: "users",
                    value: "gender",
                    supported_channels: { "google": true, "linkedin": false, "facebook": true, "google_analytics": false, "google_analytics_4": false, "google_shopping": false, "adform": false }
                },
                {
                    title: "Age",
                    subtitle: "Agregated data based on age",
                    icon: "watch",
                    value: "age",
                    supported_channels: { "google": true, "linkedin": false, "facebook": true, "google_analytics": false, "google_analytics_4": false, "google_shopping": false, "adform": false }
                },
                {
                    title: "Region",
                    subtitle: "Agregated data per region",
                    icon: "navigation",
                    value: "region",
                    supported_channels: { "google": true, "linkedin": false, "facebook": true, "google_analytics": false, "google_analytics_4": false, "google_shopping": false, "adform": false }
                },
                {
                    title: "Products",
                    subtitle: "Agregated data per product (shopping)",
                    icon: "gift",
                    value: "products",
                    supported_channels: { "google": false, "linkedin": false, "facebook": true, "google_analytics": false, "google_analytics_4": false, "google_shopping": true, "adform": false }
                }
            ];
            return breakdowns;
        },
        channelBreakdowns: () => {
            let channels = [];
            channels.push({ channel: "bing", breakdowns: this.renders.breakdownsBing() });
            channels.push({ channel: "google", breakdowns: this.renders.breakdownsGoogle() });
            channels.push({ channel: "adform", breakdowns: this.renders.breakdownsAdform() });
            channels.push({ channel: "facebook", breakdowns: this.renders.breakdownsFacebook() });
            channels.push({ channel: "tiktok", breakdowns: this.renders.breakdownsTiktok() });
            channels.push({ channel: "snapchat", breakdowns: this.renders.breakdownsSnapchat() });
            channels.push({ channel: "linkedin", breakdowns: this.renders.breakdownsLinkedin() });
            channels.push({ channel: "twitter", breakdowns: this.renders.breakdownsTwitter() });
            channels.push({ channel: "dv360", breakdowns: this.renders.breakdownsDv360() });
            channels.push({ channel: "google_analytics", breakdowns: this.state.ga_dimensions });
            channels.push({ channel: "google_analytics_4", breakdowns: this.state.ga4_dimensions });
            this.setState({
                channel_breakdowns: channels
            });
        },
        breakdownsTwitter: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" },
                        { name: "Date", value: "date", parent: "Popular" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsTiktok: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" },
                        { name: "Date", value: "date", parent: "Popular" },
                        { name: "Age", value: "age", parent: "Popular" },
                        { name: "Gender", value: "gender", parent: "Popular" },
                        { name: "Country code", value: "country_code", parent: "Popular" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsLinkedin: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" },
                        { name: "Daily", value: "date", parent: "Popular" }
                    ]
                },
                {
                    title: "Compnay",
                    values: [
                        { name: "Company", value: "MEMBER_COMPANY", parent: "Popular" },
                        { name: "Company Industry", value: "MEMBER_INDUSTRY", parent: "Popular" },
                        { name: "Company Size", value: "MEMBER_COMPANY_SIZE", parent: "Popular" },
                    ]
                },
                {
                    title: "Job",
                    values: [
                        { name: "Job Title", value: "MEMBER_JOB_TITLE", parent: "Popular" },
                        { name: "Job Function", value: "MEMBER_JOB_FUNCTION", parent: "Popular" },
                        { name: "Job Seniority", value: "MEMBER_SENIORITY", parent: "Popular" },
                    ]
                },
                {
                    title: "Location",
                    values: [
                        { name: "Location", value: "MEMBER_REGION_V2", parent: "Popular" },
                        { name: "Country/Region", value: "MEMBER_COUNTRY_V2", parent: "Popular" },
                        { name: "County", value: "MEMBER_COUNTY", parent: "Popular" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsGoogle: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular" },
                        { name: "Gender", value: "gender", parent: "Popular" },
                        { name: "Age", value: "age", parent: "Popular" },
                        { name: "Device", value: "device", parent: "Popular" },
                        { name: "Region", value: "region", parent: "Popular" },
                        { name: "Date", value: "date", parent: "Popular" },
                        { name: "Conversion", value: "conversion", parent: "Popular" },
                        { name: "Search terms", value: "search_terms", parent: "Popular" },
                        { name: "Keywords", value: "keyword", parent: "Popular" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsSnapchat: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" },
                        { name: "Gender", value: "gender", parent: "basic" },
                        { name: "Age", value: "age_bucket", parent: "basic" },
                        { name: "Country", value: "country", parent: "basic" },
                        { name: "Region", value: "region", parent: "basic" },
                        { name: "Dma", value: "dma", parent: "basic" },
                        { name: "Date", value: "date", parent: "basic" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsBing: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" },
                        { name: "Keywords", value: "keyword", parent: "basic" },
                        { name: "Search Terms", value: "search_terms", parent: "basic" },
                        { name: "Device", value: "device", parent: "basic" },
                        { name: "Age & Gender", value: "age_gender", parent: "basic" },
                        //{ name: "Gender", value: "gender", parent: "basic" },
                        { name: "Country", value: "country", parent: "basic" },
                        { name: "City", value: "city", parent: "basic" },
                        //{ name: "Age", value: "age", parent: "basic" },
                        { name: "Date", value: "date", parent: "basic" }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsAdform: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Popular", description: "" }]
                },
                {
                    title: "Banner", values: [
                        { name: "Banner/Adgroups", value: "banner", parent: "Banner", description: "" },
                        { name: "Banner ID", value: "bannerID", parent: "Banner", description: "" },
                        { name: "Banner Size", value: "bannerSize", parent: "Banner", description: "" },
                        { name: "Banner type", value: "bannerType", parent: "Banner", description: "" },
                        { name: "Banner type ID", value: "bannerTypeID", parent: "Banner", description: "" },
                        { name: "Banner format", value: "bannerFormat", parent: "Banner", description: "" },
                        { name: "Banner format ID", value: "bannerFormatID", parent: "Banner", description: "" },
                        { name: "Banner member", value: "bannerMember", parent: "Banner", description: "" },
                        { name: "Backup banner", value: "bannerBackup", parent: "Banner", description: "" },
                        { name: "Backup banner", value: "bannerBackup", parent: "Banner", description: "" },
                    ]
                },
                {
                    title: "Site Tracking", values: [
                        { name: "Page", value: "page", parent: "Site Tracking", description: "Website tracking dimension. Page is a definable content unit in advertiser's site. Usually one tracking point is implemented per page." },
                    ]
                },
                {
                    title: "Time", values: [
                        { name: "Date", value: "date", parent: "Time", decription: "This dimension groups information by date when a transaction happened." },
                    ]
                },
                {
                    title: "Technical", values: [
                        { name: "Device Type", value: "deviceType", parent: "Technical", description: "Technical dimension, groups data by device type, which can be: Desktop and Laptop, Small Screen Phone, Smart Phone, Tablet, Other Mobile, Media Hub, Console, TV, eReader, Smart Watch, Smart Speaker. It shows what share of traffic comes from different device types." },
                    ]
                },
                {
                    title: "RTB", values: [
                        { name: "RTB Domain", value: "rtbDomain", parent: "RTB", description: "This dimension groups data by RTB domain. RTB domains show 2nd level URLs of publisher sites (e.g. cnn.com), where a banner was displayed when impression was registered. RTB domain values are tracked and sent to Adform by AdExchanges." },
                        { name: "RTB Audience", value: "rtbAudience", parent: "RTB", description: "This dimension groups information by RTB audience selected in the Targeting step. An audience is a customized segment of users to be targeted, e.g. women from Denmark using Android devices." }
                    ]
                },
            ];
            return breakdowns;
        },
        breakdownsGoogleAnalytics: () => {
            return this.state.ga_dimensions;
        },
        breakdownsGoogleAnalytics4: () => {
            return this.state.ga4_dimensions;
        },
        breakdownsFacebook: () => {
            let beakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "Basic" },
                        { name: "Ad Format Asset", value: "ad_format_asset", parent: "Basic" },
                        { name: "App ID", value: "app_id", parent: "Basic" },
                        { name: "Body Asset", value: "body_asset", parent: "Basic" },
                        { name: "Call To Action Asset", value: "call_to_action_asset", parent: "Basic" },
                        { name: "Date", value: "date", parent: "Basic" },
                        { name: "Description Asset", value: "description_asset", parent: "Basic" },
                        { name: "Image Asset", value: "image_asset", parent: "Basic" },
                        { name: "Link URL Asset", value: "link_url_asset", parent: "Basic" },
                        { name: "Skan Conversion ID", value: "skan_conversion_id", parent: "Basic" },
                        { name: "Title Asset", value: "title_asset", parent: "Basic" },
                        { name: "Video Asset", value: "video_asset", parent: "Basic" },
                        { name: "DMA", value: "dma", parent: "Basic" },
                        { name: "Frequency Value", value: "frequency_value", parent: "Basic" },
                        { name: "Hourly stats by advertiser timezone", value: "hourly_stats_aggregated_by_advertiser_time_zone", parent: "Basic" },
                        { name: "Hourly stats by audience timezone", value: "hourly_stats_aggregated_by_audience_time_zone", parent: "Basic" },
                        { name: "Place Page ID", value: "place_page_id", parent: "Basic" },
                        { name: "Country", value: "country", parent: "Basic" },
                        { name: "Region", value: "region", parent: "Basic" },
                        { name: "Age", value: "age", parent: "Basic" },
                        { name: "Gender", value: "gender", parent: "Basic" },
                        { name: "Platform posistion", value: "platform_position", parent: "Basic" },
                        { name: "Publisher Platform", value: "publisher_platform", parent: "Basic" },
                        { name: "Impression Device", value: "impression_device", parent: "Basic" },
                        { name: "Product ID", value: "product_id", parent: "Basic" }
                    ]
                }
            ];
            return beakdowns;
        },
        breakdownsDv360: () => {
            let breakdowns = [
                {
                    title: "Basic",
                    values: [
                        { name: "Overview", value: "overview", parent: "basic" },
                        //{ name: "Browser", value: "FILTER_BROWSER", parent: "basic" },
                        { name: "App/URL", value: "FILTER_APP_URL", parent: "basic" },
                        { name: "Device Model", value: "FILTER_DEVICE_MODEL", parent: "basic" },
                        { name: "Device Type", value: "FILTER_DEVICE_TYPE", parent: "basic" },
                        { name: "Device Make", value: "FILTER_DEVICE_MAKE", parent: "basic" },
                        { name: "Ad Type", value: "FILTER_AD_TYPE", parent: "basic" },
                        { name: "Platform", value: "FILTER_PLATFORM", parent: "basic" },
                        { name: "Platform", value: "FILTER_PLATFORM", parent: "basic" },
                        { name: "Country", value: "FILTER_COUNTRY", parent: "basic" },
                        { name: "Insertion Order Status", value: "FILTER_INSERTION_ORDER_STATUS", parent: "basic" },
                        { name: "Insertion Order Integration Code", value: "FILTER_INSERTION_ORDER_INTEGRATION_CODE", parent: "basic" },
                        { name: "Line Item Status", value: "FILTER_LINE_ITEM_STATUS", parent: "basic" },
                        { name: "Line Item Integration code", value: "FILTER_LINE_ITEM_INTEGRATION_CODE", parent: "basic" },
                        { name: "Targeted Data Providers", value: "FILTER_TARGETED_DATA_PROVIDERS", parent: "basic" },
                        { name: "Creative Status", value: "FILTER_CREATIVE_STATUS", parent: "basic" },
                        { name: "Creative Source", value: "FILTER_CREATIVE_SOURCE", parent: "basic" },
                        { name: "Creative Integration code", value: "FILTER_CREATIVE_INTEGRATION_CODE", parent: "basic" },
                        { name: "Creative Type", value: "FILTER_CREATIVE_TYPE", parent: "basic" },
                        { name: "Creative Size", value: "FILTER_CREATIVE_SIZE", parent: "basic" },
                        { name: "Exchange", value: "FILTER_EXCHANGE", parent: "basic" },
                        { name: "Playback Method", value: "FILTER_PLAYBACK_METHOD", parent: "basic" },
                        { name: "Inventory Source Type", value: "FILTER_INVENTORY_SOURCE_TYPE", parent: "basic" },
                        { name: "Video Player Size", value: "FILTER_VIDEO_PLAYER_SIZE", parent: "basic" },
                        { name: "Ad Position", value: "FILTER_AD_POSITION", parent: "basic" },
                        { name: "City", value: "FILTER_CITY_NAME", parent: "basic" },
                        { name: "Inventory Delivery Method", value: "FILTER_INVENTORY_DELIVERY_METHOD", parent: "basic" },
                        { name: "Inventory Rate Type", value: "FILTER_INVENTORY_RATE_TYPE", parent: "basic" },
                        { name: "Region", value: "FILTER_REGION_NAME", parent: "basic" },
                        { name: "Variant Version", value: "FILTER_VARIANT_VERSION", parent: "basic" },
                        { name: "Date", value: "FILTER_DATE", parent: "basic" },
                        { name: "Week", value: "FILTER_WEEK", parent: "basic" },
                        { name: "Month", value: "FILTER_MONTH", parent: "basic" },
                        { name: "Year", value: "FILTER_YEAR", parent: "basic" },
                        { name: "Conversion Source", value: "FILTER_CONVERSION_SOURCE", parent: "basic" },
                        { name: "Creative Height", value: "FILTER_CREATIVE_HEIGHT", parent: "basic" },
                        { name: "Creative Width", value: "FILTER_CREATIVE_WIDTH", parent: "basic" },
                        { name: "Line Item Daily Frequency", value: "FILTER_LINE_ITEM_DAILY_FREQUENCY", parent: "basic" },
                        { name: "Data Source", value: "FILTER_DATA_SOURCE", parent: "basic" },
                        { name: "Public Inventory", value: "FILTER_PUBLIC_INVENTORY", parent: "basic" },
                        { name: "Exchange Id", value: "FILTER_EXCHANGE_ID", parent: "basic" },
                        { name: "Position in content", value: "FILTER_POSITION_IN_CONTENT", parent: "basic" },
                        { name: "Variant ID", value: "FILTER_VARIANT_ID", parent: "basic" },
                        { name: "Time of Day", value: "FILTER_TIME_OF_DAY", parent: "basic" },
                        { name: "Day of Week", value: "FILTER_DAY_OF_WEEK", parent: "basic" },
                        { name: "Companion creative ID", value: "FILTER_COMPANION_CREATIVE_ID", parent: "basic" },
                        { name: "Order ID", value: "FILTER_ORDER_ID", parent: "basic" },
                    ]
                },
            ];
            return breakdowns;
        },
        cellTypes: () => {
            let celltypes = [
                {
                    title: "Image/Video",
                    subtitle: "Upload an image/video to the cell",
                    icon: "image",
                    value: "image"
                },
                {
                    title: "Text",
                    subtitle: "Write text in the cell",
                    icon: "file-text",
                    value: "text"
                },
                {
                    title: "Aggregated number",
                    subtitle: "Summarize data from datasources",
                    icon: "tag",
                    value: "number"
                },
                {
                    title: "Chart",
                    subtitle: "Show data in a chart",
                    icon: "pie-chart",
                    value: "chart"
                },
                {
                    title: "Table",
                    subtitle: "Show data in a table",
                    icon: "align-justify",
                    value: "table"
                }
            ];
            return celltypes;
        },
        chartTypes: () => {
            let chartTypes = [
                {
                    title: "Donut",
                    subtitle: "Display data in a donut chart",
                    icon: "pie-chart",
                    value: "donut",
                    disabled: true
                },
                {
                    title: "Bar",
                    subtitle: "Display data in a bar chart",
                    icon: "bar-chart-2",
                    value: "bar"
                },
                {
                    title: "Line",
                    subtitle: "Display data in a line chart",
                    icon: "activity",
                    value: "line"
                }
            ];
            return chartTypes;
        },
        selectedDataSources: () => {
            let sources = [];
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    sources.push(this.state.selected_campaigns_internal[key]);
                }
            }
            if (this.state.selected_adgroups_internal) {
                for (let key in this.state.selected_adgroups_internal) {
                    sources.push(this.state.selected_adgroups_internal[key]);
                }
            }
            if (this.state.selected_ads_internal) {
                for (let key in this.state.selected_ads_internal) {
                    sources.push(this.state.selected_ads_internal[key]);
                }
            }
            return sources;
        },
        selectedDataSourcesChannells: () => {
            let channels = {};
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    channels[this.state.selected_campaigns_internal[key].channel] = true;
                }
            }
            if (this.state.selected_adgroups_internal) {
                for (let key in this.state.selected_adgroups_internal) {
                    channels[this.state.selected_adgroups_internal[key].channel] = true;
                }
            }
            if (this.state.selected_ads_internal) {
                for (let key in this.state.selected_ads_internal) {
                    channels[this.state.selected_ads_internal[key].channel] = true;
                }
            }
            return channels;
        },
        analyticsSelected: () => {
            let channels = {};
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    channels[this.state.selected_campaigns_internal[key].channel] = true;
                }
            }
            return channels["google_analytics"];
        },
        anythingButAnalyticsSelected: () => {
            let channels = {};
            if (this.state.selected_campaigns_internal) {
                for (let key in this.state.selected_campaigns_internal) {
                    if (this.state.selected_campaigns_internal[key].channel !== "google_analytics" && this.state.selected_campaigns_internal[key].channel !== "google_analytics_4") {
                        channels[this.state.selected_campaigns_internal[key].channel] = true;
                    }
                }
            }
            if (this.state.selected_adgroups_internal) {
                for (let key in this.state.selected_adgroups_internal) {
                    if (this.state.selected_adgroups_internal[key].channel !== "google_analytics" && this.state.selected_adgroups_internal[key].channel !== "google_analytics_4") {
                        channels[this.state.selected_adgroups_internal[key].channel] = true;
                    }
                }
            }
            if (this.state.selected_ads_internal) {
                for (let key in this.state.selected_ads_internal) {
                    if (this.state.selected_ads_internal[key].channel !== "google_analytics" && this.state.selected_ads_internal[key].channel !== "google_analytics_4") {
                        channels[this.state.selected_ads_internal[key].channel] = true;
                    }
                }
            }
            return Object.keys(channels).length > 0;
        },
        customMetrics: (options, search) => {
            if (search && search !== "") {
                if (Array.isArray(options)) {
                    let metrics = [];
                    let result = fuzzysort.go(search, options.map((item) => { return item.name }));
                    result.map((item) => {
                        for (let i = 0; i < options.length; i++) {
                            if (item.target && item.target.toLowerCase() === options[i].name.toLowerCase()) {
                                metrics.push(options[i]);
                            }
                        }
                    });
                    return metrics;
                } else {
                    return [];
                }
            } else {
                return Array.isArray(options) ? options : [];
            }
        },
        channelMetrics: () => {
            return Array.isArray(this.state.channel_metrics) ? this.state.channel_metrics : [];
        },
        piecharts: () => {
            let piecharts = [
                {
                    title: "Impressions",
                    subtitle: "Show total impressions per platform",
                    icon: "pie-chart",
                    value: "impressions"
                },
                { title: "Clicks", subtitle: "Show total click per platform", icon: "pie-chart", value: "click" },
                { title: "Avg. CTR", subtitle: "Show average CTR per platform", icon: "pie-chart", value: "ctr" },
                { title: "Avg. CPC", subtitle: "Show average CPC per platform", icon: "pie-chart", value: "cpc" },
                { title: "Spend", subtitle: "Show total spend per platform", icon: "pie-chart", value: "spend" },
            ];
            return piecharts;
        },
        dateranges: () => {
            let dateranges = [
                {
                    title: "Lifetime",
                    subtitle: "From start date until today",
                    icon: "calendar",
                    value: "lifetime"
                },
                {
                    title: "Last 10 days",
                    subtitle: ("From " + moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                    icon: "calendar",
                    value: "10_days",
                    disabled: false
                },
                {
                    title: "last 20 days",
                    subtitle: ("From " + moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                    icon: "calendar",
                    value: "20_days",
                    disabled: false
                },
                {
                    title: "Last 30 days",
                    subtitle: ("From " + moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment(new Date()).format("YYYY-MM-DD")),
                    icon: "calendar",
                    value: "30_days",
                    disabled: false
                },
                {
                    title: "Custom dates",
                    subtitle: "Add custom start date and end date",
                    icon: "calendar",
                    value: "custom_dates",
                    disabled: false
                }
            ];
            return dateranges;
        },
        valid: () => {
            return (
                (this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates")
                ||
                (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)
            ) &&
                this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                (this.state.tab.metrics && this.state.tab.metrics.custom && Object.keys(this.state.tab.metrics.custom).length > 0) &&
                (
                    (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) ||
                    (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) ||
                    (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                )
        },
        stepValid: (step) => {
            if (step === "datasource") {
                return true;
            } else if (step === "breakdown") {
                return this.renders.anythingSelected();
            } else if (step === "analytics_breakdown") {
                return this.renders.anythingSelected();
            } else if (step === "chart") {
                return this.renders.stepValid('breakdown') && this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0; //this.state.tab.breakdown && this.state.tab.breakdown.value;
            } else if (step === "piechart") {
                return this.renders.anythingSelected() &&
                    !this.renders.lowerLevelThanCampaign() &&
                    //this.state.tab.breakdown &&
                    //this.state.tab.breakdown.value &&
                    this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                    this.state.tab.daterange &&
                    ((this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) || this.state.tab.daterange.value !== "custom_dates")
            } else if (step === "daterange") {
                return (
                    this.renders.anythingSelected() && !this.state.premium && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0
                )
            } else if (step === "metric") {
                return (
                    this.renders.anythingSelected() && !this.state.premium && this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0
                )
            } else if (step === "settings") {
                return (this.renders.anythingSelected() && this.state.tab.metrics && this.state.tab.metrics.custom && Object.keys(this.state.tab.metrics.custom).length > 0)
            } else {
                return false
            }
        },
        anythingSelected: () => {
            return ((this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) ||
                (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) ||
                (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0))
        },
        multipleLevels: () => {
            let total = 0;
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 1
        },
        lowerLevelThanCampaign: () => {
            return (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) ||
                (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
        },
        groups: () => {
            let groups = [];
            let unique_groups = {};
            if (this.state.tab && Array.isArray(this.state.tab.grid_data)) {
                this.state.tab.grid_data.map((item) => {
                    if (!item.group) {
                        item.group = 0;
                    }
                    if (!unique_groups[item.group]) {
                        unique_groups[item.group] = [];
                    }
                    unique_groups[item.group].push(item);
                });
                for (let key in unique_groups) {
                    groups.push({ id: key, items: unique_groups[key].length });
                }
                groups.sort((a, b) => {
                    if (a > b) return 1;
                    if (b > a) return -1;
                    return 0;
                });
            }
            return groups;
        }
    };

    render() {

        const SortableItem = SortableElement(({ item, index }) => {
            return (
                <div style={{ zIndex: 99999999 }}
                    className="w-full flex flex-row justify-center cursor-pointer align-middle items-center mb-2">
                    <div
                        className="text-purple-500 text-xs font-bold bg-purple-100 h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                        {item.index + 1}
                    </div>
                    <div className="text-xs flex flex-1 ml-2">
                        <div className="flex flex-1 flex-col truncate">
                            <div className="font-bold text-xs truncate">
                                {item.alias ? item.alias : item.name}
                            </div>
                            <div className="text-xxs capitalize">
                                {item.calculated ? "Calculated metric" : "Custom metric"}
                            </div>
                        </div>
                    </div>
                    <div
                        className="text-purple-500 text-xs font-bold cursor-pointer bg-purple-100 h-8 w-8 flex rounded justify-center align-middle items-center">
                        <FeatherIcon className="stroke-current transform rotate-90"
                            size={14}
                            icon={'code'}
                        />
                    </div>
                </div>
            )
        });

        const SortableList = SortableContainer(({ items }) => (
            <div className="">
                {items.map((item, index) => {
                    item.index = index;
                    if (item) {
                        return (
                            <SortableItem
                                index={index}
                                item={item}
                            />
                        )
                    }
                })}
            </div>
        ));

        const onSortEnd = ({ oldIndex, newIndex }) => {
            this.state.tab.table_metrics_index = arrayMove(this.state.tab.table_metrics_index, oldIndex, newIndex);
            this.setState({
                tab: this.state.tab
            });
        };

        return (
            <div className="slideover--wrapper fixed inset-0 overflow-hidden w-screen h-screen"
                style={!this.state.closed ? { zIndex: '9999' } : { zIndex: '-1' }}>
                <div className="absolute inset-0 overflow-hidden">
                    <div
                        className={`absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity ease-in-out duration-500 ${this.props.closed ? 'opacity-0' : 'opacity-100'}`}></div>

                    <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                        <div
                            className={`relative w-screen ${(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "edit_metric" || this.state.tab.metric.value === "temp_metric")) ? 'max-w-4xl' : 'max-w-md'} transform transition ease-in-out duration-500 sm:duration-700 ${this.props.closed ? 'translate-x-full' : 'translate-x-0'}`}
                            ref={this.props.slideOver && this.props.slideOver}>
                            {
                                false &&
                                <div className="absolute right-100 md:pr-4 md:pt-4 flex">
                                    <button aria-label="Close panel"
                                        className="bg-red-500 text-white font-bold text-xs flex items-center rounded-full py-2 px-4 hover:bg-red-700 transition ease-in-out duration-150"
                                        onClick={() => {
                                            this.props.close();
                                        }}
                                    >
                                        <FeatherIcon className="stroke-current mr-1" size={16} icon="x" /> <span>Close</span>
                                    </button>
                                </div>
                            }
                            <div className="absolute right-100 pr-6 pt-6 flex">
                                <button aria-label="Close panel"
                                    className="bg-red-500 text-white border-white font-bold text-xs flex items-center rounded justify-center h-8 w-8 hover:bg-red-700 transition ease-in-out duration-150"
                                    onClick={() => {
                                        this.props.close();
                                    }}
                                >
                                    <FeatherIcon className="stroke-current" size={16} icon="x" />
                                </button>
                            </div>
                            <div
                                className={`flex flex-row-reverse bg-white h-full shadow-xl ${this.props.slideOver && this.props.rounded ? this.props.rounded : ''}`}>

                                {
                                    <div
                                        className={((this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "edit_metric" || this.state.tab.metric.value === "temp_metric")) ? "w-full" : "w-0") + " h-full duration-300 flex transition-all flex-col pb-6 max-w-md overflow-hidden"}>
                                        <header className="px-4 sm:px-6 py-6 bg-gray-100 flex flex-row">
                                            <div className="flex flex-1 items-center">
                                                {
                                                    this.state.premium && this.state.tab.metric && this.state.tab.metric.value === "temp_metric" &&
                                                    <h2 className="text-lg leading-7 text-gray-900">
                                                        Create temporary metric
                                                    </h2>
                                                }
                                                {
                                                    !this.state.premium && this.state.tab.metric && this.state.tab.metric.value === "channel_metric" &&
                                                    <h2 className="text-lg leading-7 text-gray-900">
                                                        Select channel metrics
                                                    </h2>
                                                }
                                                {
                                                    this.state.tab.metric && this.state.tab.metric.value === "new_metric" && !this.state.edit_metric &&
                                                    <h2 className="text-lg leading-7 text-gray-900">
                                                        Create custom metric
                                                    </h2>
                                                }
                                                {
                                                    this.state.tab.metric && this.state.tab.metric.value === "edit_metric" && this.state.edit_metric &&
                                                    <h2 className="text-lg leading-7 text-gray-900">
                                                        Edit custom metric
                                                    </h2>
                                                }
                                            </div>
                                            <div>
                                                <div onClick={() => {
                                                    //this.props.close();
                                                    delete this.state.tab.metric;
                                                    this.setState({
                                                        edit_metric: false,
                                                        tab: this.state.tab
                                                    });
                                                }}
                                                    className="transition ease-in-out duration-150 bg-red-500 text-white cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                        icon={"x"} />
                                                </div>
                                            </div>
                                        </header>
                                        <div className="overflow-scroll">
                                            <div className="w-full h-6" />
                                            <CreateCustomMetric
                                                ref={'customMetric'}
                                                view={"report"}
                                                premium={this.state.premium}
                                                channels={this.renders.selectedDataSources().map((item) => {
                                                    return item.channel
                                                })}
                                                channelMetric={this.state.tab.metric && this.state.tab.metric.value === "channel_metric"}
                                                metrics={this.state.all_metrics}
                                                metric={this.state.edit_metric}
                                                option={this.state.tab.metric}
                                                clients={[]}
                                                datasources={this.renders.selectedDataSources()}
                                                close={(value, type) => {



                                                    if (type === "create_metric") {
                                                        //this.state.tab.metric = {};
                                                        //this.props.getMetrics();
                                                        //this.state.tab.metric = value;
                                                        value.title = value.name;
                                                        value.new = true;
                                                        value.icon = 'code';
                                                        //this.state.custom_metrics.unshift(JSON.parse(JSON.stringify(value)));

                                                        if (this.props.updateMetrics) {
                                                            this.props.updateMetrics(value);
                                                        }

                                                        delete this.state.tab.metric;
                                                    }




                                                    /*
                                                    if (type === "create_metric") {
                                                        this.state.tab.metric = value;
                                                        this.state.tab.metric.title = value.name;
                                                        this.state.tab.metric.icon = 'code';
                                                        this.state.custom_metrics.unshift(JSON.parse(JSON.stringify(this.state.tab.metric)));
                                                        this.state.tab.metric = {};
                                                    } else if (type === "edit_metric") {
                                                        this.state.tab.metric = {};
                                                        this.state.custom_metrics = this.state.custom_metrics.map((item) => {
                                                            if (item.id === value.id) {
                                                                item = value;
                                                            }
                                                            return item;
                                                        })
                                                    } else if (type === "temp_metric") {
                                                        this.state.tab.metric = value;
                                                        this.state.tab.metric.title = value.name;
                                                        this.state.tab.metric.icon = 'code';
                                                        this.state.tab.metric.temp = true;
                                                        this.state.tab.metric.value = value.name;
                                                        this.state.custom_metrics.unshift(JSON.parse(JSON.stringify(this.state.tab.metric)));
                                                    } else if (type === 'channel_metric') {
                                                        this.state.tab.metric = {};
                                                        if (!Array.isArray(this.state.channel_metrics)) {
                                                            this.state.channel_metrics = [];
                                                        }
                                                        for (let channel in value) {
                                                            for (let metric in value[channel]) {
                                                                let item = JSON.parse(JSON.stringify(value[channel][metric]))
                                                                item.title = value[channel][metric].name;
                                                                item.channels = {};
                                                                item.channels[channel] = JSON.parse(JSON.stringify(value[channel][metric]));
                                                                item.icon = "code";
                                                                this.state.channel_metrics.push(item);

                                                                if (!this.state.tab.table_metrics) {
                                                                    this.state.tab.table_metrics = {};
                                                                }
                                                                if (!this.state.tab.table_metrics_index) {
                                                                    this.state.tab.table_metrics_index = [];
                                                                }
                                                                if (item.name in this.state.tab.table_metrics) {

                                                                } else {
                                                                    item.metric_type = "channel";
                                                                    this.state.tab.table_metrics[item.name] = item;
                                                                    this.state.tab.table_metrics_index.push(item);
                                                                }
                                                            }
                                                        }
                                                    }
                                                    */

                                                    if (this.state.premium) {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = {
                                                                title: ""
                                                            };
                                                        }
                                                        if (this.state.tab.metric) {
                                                            //this.state.tab.settings.title = this.state.tab.metric.alias ? this.state.tab.metric.alias : this.state.tab.metric.title;
                                                        }
                                                    }

                                                    this.setState({
                                                        tab: this.state.tab,
                                                        channel_metrics: this.state.channel_metrics,
                                                        custom_metrics: this.state.custom_metrics,
                                                        edit_metric: false
                                                    });

                                                }}
                                            />
                                        </div>
                                    </div>
                                }


                                <div className={"flex w-full flex-col pb-6 max-w-md border-r overflow-scroll h-full relative"}>
                                    <header className="px-4 sm:px-6 mb-6 bg-gray-100 py-6 flex flex-row">
                                        <div className="flex flex-1 items-center">
                                            {
                                                this.state.premium &&
                                                <h2 className="text-lg leading-7 text-gray-900">
                                                    Create new cell
                                                </h2>
                                            }
                                            {
                                                !this.state.premium &&
                                                <h2 className="text-lg leading-7 text-gray-900">
                                                    Update tab
                                                </h2>
                                            }
                                        </div>
                                    </header>
                                    {
                                        this.state.premium &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50" : "") + " px-4 sm:px-6 pb-6 border-b"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        celltype: !this.state.celltype
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select cell type
                                                    </h3>
                                                    {
                                                        !this.state.tab.celltype &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.renders.cellTypes().length}</span> cell
                                                            types
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.celltype && this.state.tab.celltype.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.celltype.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                <div className="flex flex-row">
                                                    {
                                                        this.state.tab.celltype &&
                                                        <div
                                                            className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"check"} />
                                                        </div>
                                                    }
                                                    <div onClick={() => {
                                                        this.setState({
                                                            celltype: !this.state.celltype
                                                        })
                                                    }}
                                                        className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                        {
                                                            !this.state.celltype &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-down"} />
                                                        }
                                                        {
                                                            this.state.celltype &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-up"} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <SlideDown closed={!this.state.celltype}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        this.renders.cellTypes().map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div
                                                                        className={("cursor-pointer") + (i !== (this.renders.cellTypes().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                        <div
                                                                            className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon={item.icon} />
                                                                        </div>
                                                                        <div onClick={() => {
                                                                            this.state.tab.celltype = item;
                                                                            if (!this.state.tab.group) {
                                                                                this.state.tab.group = 0;
                                                                            }

                                                                            this.setState({
                                                                                tab: this.state.tab,
                                                                                celltype: false,
                                                                                datasource: (item.value !== "image" && item.value !== "text"),
                                                                                settings: (item.value === "image" || item.value === "text")
                                                                            })
                                                                        }}
                                                                            className={((this.state.tab.celltype && this.state.tab.celltype.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.tab.celltype && this.state.tab.celltype.value === item.value &&
                                                                                <div
                                                                                    className="w-full h-full rounded-full bg-purple-500" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {item.title}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                {item.subtitle}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.tab.celltype && this.state.tab.celltype.value &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                celltype: false,
                                                                datasource: (this.state.tab.celltype.value !== "image" && this.state.tab.celltype.value !== "text"),
                                                                settings: (this.state.tab.celltype.value === "image" || this.state.tab.celltype.value === "text")
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer mt-5 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    {
                                        (!this.state.premium || (this.state.premium && this.state.tab.celltype && (this.state.tab.celltype.value === "number" || this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "table"))) &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50" : "") + (this.renders.stepValid('datasource') ? " " : " opacity-50 ") + (this.state.premium ? "pt-6" : "") + " px-4 sm:px-6 pb-6 border-b"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        datasource: !this.state.datasource
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select data source
                                                    </h3>
                                                    {
                                                        (
                                                            (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                            ||
                                                            (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                            ||
                                                            (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                        )
                                                        &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected
                                                            {
                                                                (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) &&
                                                                <span
                                                                    className="font-bold ml-1">{Object.keys(this.state.selected_campaigns_internal).length} campaign
                                                                    {
                                                                        Object.keys(this.state.selected_campaigns_internal).length > 1 &&
                                                                        <span>s</span>
                                                                    }
                                                                    {
                                                                        ((this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                                            || (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                                        ) &&
                                                                        <span>,</span>
                                                                    }
                                                                </span>
                                                            }
                                                            {
                                                                (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) &&
                                                                <span
                                                                    className="font-bold ml-1">{Object.keys(this.state.selected_adgroups_internal).length} adgroup
                                                                    {
                                                                        Object.keys(this.state.selected_adgroups_internal).length > 1 &&
                                                                        <span>s</span>
                                                                    }
                                                                    {
                                                                        (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) &&
                                                                        <span>,</span>
                                                                    }
                                                                </span>
                                                            }
                                                            {
                                                                (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) &&
                                                                <span
                                                                    className="font-bold ml-1">{Object.keys(this.state.selected_ads_internal).length} ad
                                                                    {
                                                                        Object.keys(this.state.selected_ads_internal).length > 1 &&
                                                                        <span>s</span>
                                                                    }
                                                                </span>
                                                            }
                                                        </p>
                                                        ||
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Display data from selected data sources
                                                        </p>
                                                    }
                                                </div>
                                                <div className="flex flex-row">
                                                    {
                                                        (
                                                            (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                            ||
                                                            (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                            ||
                                                            (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                        )
                                                        &&
                                                        <div
                                                            className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"check"} />
                                                        </div>
                                                    }
                                                    <div onClick={() => {
                                                        this.setState({
                                                            datasource: !this.state.datasource
                                                        })
                                                    }}
                                                        className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                        {
                                                            !this.state.datasource &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-down"} />
                                                        }
                                                        {
                                                            this.state.datasource &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"chevron-up"} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <SlideDown closed={!this.state.datasource}>
                                                <div className="w-full h-6" />
                                                {
                                                    this.state.campaigns.map((campaign, index) => {
                                                        return (
                                                            <Fragment>
                                                                <div
                                                                    className="flex flex-1 flex-row justify-center mb-2">
                                                                    <div onClick={() => {
                                                                        if (campaign.channel !== "google_analytics" && campaign.channel !== "google_analytics_4") {
                                                                            if (!campaign.open) {
                                                                                this.props.getAdgroups(campaign);
                                                                                campaign.open = true;
                                                                            } else {
                                                                                campaign.open = false;
                                                                            }
                                                                            this.setState({
                                                                                campaigns: this.state.campaigns
                                                                            })
                                                                        }
                                                                    }}
                                                                        className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                        {
                                                                            campaign.channel !== "google_analytics" &&
                                                                            campaign.channel !== "google_analytics_4" &&
                                                                            !campaign.open && !campaign.loading &&
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon="plus" />
                                                                        }
                                                                        {
                                                                            campaign.channel !== "google_analytics" &&
                                                                            campaign.channel !== "google_analytics_4" &&
                                                                            campaign.open && !campaign.loading &&
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon="minus" />
                                                                        }
                                                                        {
                                                                            campaign.channel !== "google_analytics" &&
                                                                            campaign.channel !== "google_analytics_4" &&
                                                                            campaign.loading &&
                                                                            <BeatLoader
                                                                                sizeUnit={"px"}
                                                                                size={4}
                                                                                color={'#060534'}
                                                                                loading={true}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        if (!campaign.is_loading) {
                                                                            if (this.state.selected_campaigns_internal[campaign.id]) {
                                                                                delete this.state.selected_campaigns_internal[campaign.id]
                                                                            } else {
                                                                                let colors = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"];
                                                                                let index = (Math.floor(Math.random() * (17 - 0)) + 0);
                                                                                let color = "";
                                                                                if (campaign.channel === "facebook") {
                                                                                    color = "#3A5998";
                                                                                } else if (campaign.channel === "google") {
                                                                                    color = "#4384F4";
                                                                                } else if (campaign.channel === "linkedin") {
                                                                                    color = "#0D65A1";
                                                                                } else if (campaign.channel === "google_analytics") {
                                                                                    color = "#e8710a";
                                                                                } else if (campaign.channel === "google_analytics_4") {
                                                                                    color = "#e8710a";
                                                                                } else if (campaign.channel === "google_shopping") {
                                                                                    color = "#34A853";
                                                                                } else if (campaign.channel === "adform") {
                                                                                    color = "#60C1A4";
                                                                                } else if (campaign.channel === "tiktok") {
                                                                                    color = "#000000";
                                                                                } else if (campaign.channel === "twitter") {
                                                                                    color = "#50ABF1";
                                                                                } else if (campaign.channel === "bing") {
                                                                                    color = "#0C8484";
                                                                                } else {
                                                                                    color = colors[index];
                                                                                }
                                                                                campaign.hex = color;
                                                                                campaign.rgb = campaign.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                                                                                    , (m, r, g, b) => '#' + r + r + g + g + b + b)
                                                                                    .substring(1).match(/.{2}/g)
                                                                                    .map(x => parseInt(x, 16));
                                                                                if (Array.isArray(campaign.rgb)) {
                                                                                    try {
                                                                                        let rgb = campaign.rgb;
                                                                                        campaign.rgb = {
                                                                                            r: rgb[0],
                                                                                            g: rgb[1],
                                                                                            b: rgb[2]
                                                                                        };
                                                                                    } catch (e) {
                                                                                    }
                                                                                }
                                                                                this.state.selected_campaigns_internal[campaign.id] = campaign;
                                                                            }
                                                                            this.setState({
                                                                                selected_campaigns_internal: this.state.selected_campaigns_internal
                                                                            })
                                                                        }
                                                                    }}
                                                                        className={(this.state.selected_campaigns_internal[campaign.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                        {
                                                                            this.state.selected_campaigns_internal[campaign.id] &&
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon="check" />
                                                                        }
                                                                        {
                                                                            campaign.is_loading &&
                                                                            <div
                                                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                <BeatLoader
                                                                                    sizeUnit={"px"}
                                                                                    size={4}
                                                                                    color={'#060534'}
                                                                                    loading={true}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        campaign.channel === "google" &&
                                                                        <div
                                                                            className="bg-google-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../../assets/images/google_icon.svg')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "google_shopping" &&
                                                                        <div
                                                                            className="bg-googleshopping-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../../assets/images/google-shopping.png')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "linkedin" &&
                                                                        <div
                                                                            className="bg-linkedin-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../../assets/images/linkedin_icon.svg')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "facebook" &&
                                                                        <div
                                                                            className="bg-facebook-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img style={{ width: '0.4rem' }}
                                                                                src={require('../../../assets/images/facebook_icon.svg')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "google_analytics" &&
                                                                        <div
                                                                            className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../../assets/images/google_analytics_icon.png')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "google_analytics_4" &&
                                                                        <div
                                                                            className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../../assets/images/ga_icon.png')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "adform" &&
                                                                        <div
                                                                            className="bg-adform-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../../assets/images/adform_icon.png')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "twitter" &&
                                                                        <div
                                                                            className="bg-twitter-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../../assets/images/twitter_icon.svg')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "tiktok" &&
                                                                        <div
                                                                            className="bg-tiktok-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../../assets/images/tiktok_icon.png')} />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        campaign.channel === "bing" &&
                                                                        <div
                                                                            className="bg-bing-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                            <img className="w-3"
                                                                                src={require('../../../assets/images/bing-logo.png')} />
                                                                        </div>
                                                                    }
                                                                    <div className="flex flex-1 flex-col truncate">
                                                                        <div className="font-bold text-xs truncate">
                                                                            {campaign.name}
                                                                        </div>
                                                                        <div className="text-xxs flex flex-row">
                                                                            CAMPAIGN
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.state.adgroups.filter((adgroup) => {
                                                                        return adgroup.campaign == campaign.id && campaign.open
                                                                    }).length > 0 &&
                                                                    <div
                                                                        className="flex flex-1 flex-row justify-center pl-10 mb-2">
                                                                        <div
                                                                            className="opacity-0 h-8 w-8 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500"></div>
                                                                        <div onClick={() => {
                                                                            if (this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                            }).length !== this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign == campaign.id
                                                                            }).length) {

                                                                                //SELECT ALL
                                                                                this.state.adgroups.filter((adgroup) => {
                                                                                    return adgroup.campaign == campaign.id
                                                                                }).map((adgroup) => {
                                                                                    this.state.selected_adgroups_internal[adgroup.id] = adgroup;
                                                                                });

                                                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                    this.state.tab.breakdown = {
                                                                                        title: "Overview",
                                                                                        subtitle: "Agregated data",
                                                                                        icon: "globe",
                                                                                        value: "overview"
                                                                                    };
                                                                                }

                                                                            } else {
                                                                                //REMOVE ALL
                                                                                this.state.adgroups.filter((adgroup) => {
                                                                                    return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                                }).map((adgroup) => {
                                                                                    delete this.state.selected_adgroups_internal[adgroup.id];
                                                                                });
                                                                            }
                                                                            this.setState({
                                                                                tab: this.state.tab,
                                                                                selected_adgroups_internal: this.state.selected_adgroups_internal
                                                                            })
                                                                        }}
                                                                            className={(this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                            }).length === this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign == campaign.id
                                                                            }).length ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.adgroups.filter((adgroup) => {
                                                                                    return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                                }).length === this.state.adgroups.filter((adgroup) => {
                                                                                    return adgroup.campaign == campaign.id
                                                                                }).length &&
                                                                                <FeatherIcon
                                                                                    className={'stroke-current'}
                                                                                    size={14}
                                                                                    icon="check" />
                                                                            }
                                                                            {
                                                                                this.state.adgroups.filter((adgroup) => {
                                                                                    return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id] && adgroup.is_loading
                                                                                }).length > 0 &&
                                                                                <div
                                                                                    className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                    <BeatLoader
                                                                                        sizeUnit={"px"}
                                                                                        size={4}
                                                                                        color={'#060534'}
                                                                                        loading={true}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                            }).length === this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign == campaign.id
                                                                            }).length &&
                                                                            <div
                                                                                className="flex flex-1 flex-col truncate">
                                                                                <div
                                                                                    className="font-bold text-xs truncate">
                                                                                    Deselect all
                                                                                </div>
                                                                                <div className="text-xxs">
                                                                                    Click here to deselect all adgroups
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign == campaign.id && this.state.selected_adgroups_internal[adgroup.id]
                                                                            }).length !== this.state.adgroups.filter((adgroup) => {
                                                                                return adgroup.campaign == campaign.id
                                                                            }).length &&
                                                                            <div
                                                                                className="flex flex-1 flex-col truncate">
                                                                                <div
                                                                                    className="font-bold text-xs truncate">
                                                                                    Select all
                                                                                </div>
                                                                                <div className="text-xxs">
                                                                                    Click here to select all adgroups
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.adgroups.filter((adgroup) => {
                                                                        return adgroup.campaign == campaign.id && campaign.open
                                                                    }).map((adgroup) => {
                                                                        return (
                                                                            <Fragment>
                                                                                <div
                                                                                    className="flex flex-1 flex-row justify-center pl-10 mb-2">
                                                                                    <div onClick={() => {
                                                                                        if (!adgroup.open) {
                                                                                            this.props.getAds(adgroup);
                                                                                            adgroup.open = true;
                                                                                        } else {
                                                                                            adgroup.open = false;
                                                                                        }
                                                                                        this.setState({
                                                                                            adgroups: this.state.adgroups
                                                                                        })
                                                                                    }}
                                                                                        className="cursor-pointer h-8 w-8 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500">
                                                                                        {
                                                                                            !adgroup.open && !adgroup.loading &&
                                                                                            <FeatherIcon
                                                                                                className={'stroke-current'}
                                                                                                size={14} icon="plus" />
                                                                                        }
                                                                                        {
                                                                                            adgroup.open && !adgroup.loading &&
                                                                                            <FeatherIcon
                                                                                                className={'stroke-current'}
                                                                                                size={14} icon="minus" />
                                                                                        }
                                                                                        {
                                                                                            adgroup.loading &&
                                                                                            <BeatLoader
                                                                                                sizeUnit={"px"}
                                                                                                size={4}
                                                                                                color={'#060534'}
                                                                                                loading={true}
                                                                                            />
                                                                                        }
                                                                                    </div>
                                                                                    <div onClick={() => {
                                                                                        if (!adgroup.is_loading) {
                                                                                            if (this.state.selected_adgroups_internal[adgroup.id]) {
                                                                                                delete this.state.selected_adgroups_internal[adgroup.id];
                                                                                            } else {
                                                                                                let colors = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"];
                                                                                                let index = (Math.floor(Math.random() * (17 - 0)) + 0);
                                                                                                let color = "";
                                                                                                if (adgroup.channel === "facebook") {
                                                                                                    color = "#3A5998";
                                                                                                } else if (adgroup.channel === "google") {
                                                                                                    color = "#4384F4";
                                                                                                } else if (adgroup.channel === "linkedin") {
                                                                                                    color = "#0D65A1";
                                                                                                } else if (adgroup.channel === "google_analytics") {
                                                                                                    color = "#e8710a";
                                                                                                } else if (adgroup.channel === "google_analytics_4") {
                                                                                                    color = "#e8710a";
                                                                                                } else if (adgroup.channel === "google_shopping") {
                                                                                                    color = "#34A853";
                                                                                                } else if (adgroup.channel === "adform") {
                                                                                                    color = "#60C1A4";
                                                                                                } else if (adgroup.channel === "tiktok") {
                                                                                                    color = "#000000";
                                                                                                } else if (adgroup.channel === "twitter") {
                                                                                                    color = "#50ABF1";
                                                                                                } else if (adgroup.channel === "bing") {
                                                                                                    color = "#0C8484";
                                                                                                } else {
                                                                                                    color = colors[index];
                                                                                                }
                                                                                                adgroup.hex = color;
                                                                                                adgroup.rgb = adgroup.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                                                                                                    , (m, r, g, b) => '#' + r + r + g + g + b + b)
                                                                                                    .substring(1).match(/.{2}/g)
                                                                                                    .map(x => parseInt(x, 16));
                                                                                                if (Array.isArray(adgroup.rgb)) {
                                                                                                    try {
                                                                                                        let rgb = adgroup.rgb;
                                                                                                        adgroup.rgb = {
                                                                                                            r: rgb[0],
                                                                                                            g: rgb[1],
                                                                                                            b: rgb[2]
                                                                                                        };
                                                                                                    } catch (e) {
                                                                                                    }
                                                                                                }
                                                                                                this.state.selected_adgroups_internal[adgroup.id] = adgroup;

                                                                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                    this.state.tab.breakdown = {
                                                                                                        title: "Overview",
                                                                                                        subtitle: "Agregated data",
                                                                                                        icon: "globe",
                                                                                                        value: "overview"
                                                                                                    };
                                                                                                }

                                                                                            }
                                                                                            this.setState({
                                                                                                tab: this.state.tab,
                                                                                                selected_adgroups_internal: this.state.selected_adgroups_internal
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                        className={(this.state.selected_adgroups_internal[adgroup.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                        {
                                                                                            this.state.selected_adgroups_internal[adgroup.id] &&
                                                                                            <FeatherIcon
                                                                                                className={'stroke-current'}
                                                                                                size={14}
                                                                                                icon="check" />
                                                                                        }
                                                                                        {
                                                                                            adgroup.is_loading &&
                                                                                            <div
                                                                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                                <BeatLoader
                                                                                                    sizeUnit={"px"}
                                                                                                    size={4}
                                                                                                    color={'#060534'}
                                                                                                    loading={true}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className="flex flex-1 flex-col truncate">
                                                                                        <div
                                                                                            className="font-bold text-xs truncate">
                                                                                            {adgroup.name}
                                                                                        </div>
                                                                                        <div className="text-xxs">
                                                                                            ADGROUP
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    this.state.ads.filter((ad) => {
                                                                                        return ad.adgroup == adgroup.id && adgroup.open
                                                                                    }).length > 0 &&
                                                                                    <div
                                                                                        className="flex flex-1 flex-row justify-center pl-10 mb-2">
                                                                                        <div
                                                                                            className="opacity-0 h-8 w-8 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500" />
                                                                                        <div
                                                                                            className="opacity-0 h-8 w-8 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500" />
                                                                                        <div onClick={() => {
                                                                                            if (this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup == adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                            }).length !== this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup == adgroup.id
                                                                                            }).length) {

                                                                                                //SELECT ALL
                                                                                                this.state.ads.filter((ad) => {
                                                                                                    return ad.adgroup == adgroup.id
                                                                                                }).map((ad) => {
                                                                                                    this.state.selected_ads_internal[ad.id] = ad;
                                                                                                });

                                                                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                    this.state.tab.breakdown = {
                                                                                                        title: "Overview",
                                                                                                        subtitle: "Agregated data",
                                                                                                        icon: "globe",
                                                                                                        value: "overview"
                                                                                                    };
                                                                                                }

                                                                                            } else {
                                                                                                //REMOVE ALL
                                                                                                this.state.ads.filter((ad) => {
                                                                                                    return ad.adgroup == adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                                }).map((ad) => {
                                                                                                    delete this.state.selected_ads_internal[ad.id];
                                                                                                });
                                                                                            }
                                                                                            this.setState({
                                                                                                tab: this.state.tab,
                                                                                                selected_ads_internal: this.state.selected_ads_internal
                                                                                            })
                                                                                        }}
                                                                                            className={(this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup == adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                            }).length === this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup == adgroup.id
                                                                                            }).length ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                            {
                                                                                                this.state.ads.filter((ad) => {
                                                                                                    return ad.adgroup == adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                                }).length === this.state.ads.filter((ad) => {
                                                                                                    return ad.adgroup == adgroup.id
                                                                                                }).length &&
                                                                                                <FeatherIcon
                                                                                                    className={'stroke-current'}
                                                                                                    size={14}
                                                                                                    icon="check" />
                                                                                            }
                                                                                            {
                                                                                                this.state.ads.filter((ad) => {
                                                                                                    return ad.adgroup == adgroup.id && this.state.selected_ads_internal[ad.id] && ad.is_loading
                                                                                                }).length > 0 &&
                                                                                                <div
                                                                                                    className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                                    <BeatLoader
                                                                                                        sizeUnit={"px"}
                                                                                                        size={4}
                                                                                                        color={'#060534'}
                                                                                                        loading={true}
                                                                                                    />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup == adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                            }).length === this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup == adgroup.id
                                                                                            }).length &&
                                                                                            <div
                                                                                                className="flex flex-1 flex-col truncate">
                                                                                                <div
                                                                                                    className="font-bold text-xs truncate">
                                                                                                    Deselect all
                                                                                                </div>
                                                                                                <div
                                                                                                    className="text-xxs">
                                                                                                    Click here to
                                                                                                    deselect
                                                                                                    all
                                                                                                    ads
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup == adgroup.id && this.state.selected_ads_internal[ad.id]
                                                                                            }).length !== this.state.ads.filter((ad) => {
                                                                                                return ad.adgroup == adgroup.id
                                                                                            }).length &&
                                                                                            <div
                                                                                                className="flex flex-1 flex-col truncate">
                                                                                                <div
                                                                                                    className="font-bold text-xs truncate">
                                                                                                    Select all
                                                                                                </div>
                                                                                                <div
                                                                                                    className="text-xxs">
                                                                                                    Click here to select
                                                                                                    all
                                                                                                    ads
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    this.state.ads.filter((ad) => {
                                                                                        return ad.adgroup == adgroup.id && adgroup.open
                                                                                    }).map((ad) => {
                                                                                        return (
                                                                                            <div
                                                                                                className="flex flex-1 flex-row justify-center pl-20 mb-2">
                                                                                                <div
                                                                                                    className="h-8 w-8 flex rounded mr-2"></div>
                                                                                                <div onClick={() => {
                                                                                                    if (!ad.is_loading) {
                                                                                                        if (this.state.selected_ads_internal[ad.id]) {
                                                                                                            delete this.state.selected_ads_internal[ad.id];
                                                                                                        } else {
                                                                                                            let colors = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"];
                                                                                                            let index = (Math.floor(Math.random() * (17 - 0)) + 0);
                                                                                                            let color = "";
                                                                                                            if (ad.channel === "facebook") {
                                                                                                                color = "#3A5998";
                                                                                                            } else if (ad.channel === "google") {
                                                                                                                color = "#4384F4";
                                                                                                            } else if (ad.channel === "linkedin") {
                                                                                                                color = "#0D65A1";
                                                                                                            } else if (ad.channel === "google_analytics") {
                                                                                                                color = "#e8710a";
                                                                                                            } else if (ad.channel === "google_analytics_4") {
                                                                                                                color = "#e8710a";
                                                                                                            } else if (ad.channel === "google_shopping") {
                                                                                                                color = "#34A853";
                                                                                                            } else if (ad.channel === "adform") {
                                                                                                                color = "#60C1A4";
                                                                                                            } else if (ad.channel === "tiktok") {
                                                                                                                color = "#000000";
                                                                                                            } else if (ad.channel === "twitter") {
                                                                                                                color = "#50ABF1";
                                                                                                            } else if (ad.channel === "bing") {
                                                                                                                color = "#0C8484";
                                                                                                            } else {
                                                                                                                color = colors[index];
                                                                                                            }
                                                                                                            ad.hex = color;
                                                                                                            ad.rgb = ad.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                                                                                                                , (m, r, g, b) => '#' + r + r + g + g + b + b)
                                                                                                                .substring(1).match(/.{2}/g)
                                                                                                                .map(x => parseInt(x, 16));

                                                                                                            if (Array.isArray(ad.rgb)) {
                                                                                                                try {
                                                                                                                    let rgb = ad.rgb;
                                                                                                                    ad.rgb = {
                                                                                                                        r: rgb[0],
                                                                                                                        g: rgb[1],
                                                                                                                        b: rgb[2]
                                                                                                                    };
                                                                                                                } catch (e) {
                                                                                                                }
                                                                                                            }

                                                                                                            this.state.selected_ads_internal[ad.id] = ad;

                                                                                                            //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                                                                            if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                                                                this.state.tab.breakdown = {
                                                                                                                    title: "Overview",
                                                                                                                    subtitle: "Agregated data",
                                                                                                                    icon: "globe",
                                                                                                                    value: "overview"
                                                                                                                };
                                                                                                            }

                                                                                                        }
                                                                                                        this.setState({
                                                                                                            tab: this.state.tab,
                                                                                                            selected_ads_internal: this.state.selected_ads_internal
                                                                                                        })
                                                                                                    }
                                                                                                }}
                                                                                                    className={(this.state.selected_ads_internal[ad.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                                    {
                                                                                                        this.state.selected_ads_internal[ad.id] &&
                                                                                                        <FeatherIcon
                                                                                                            className={'stroke-current'}
                                                                                                            size={14}
                                                                                                            icon="check" />
                                                                                                    }
                                                                                                    {
                                                                                                        ad.is_loading &&
                                                                                                        <div
                                                                                                            className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                                                            <BeatLoader
                                                                                                                sizeUnit={"px"}
                                                                                                                size={4}
                                                                                                                color={'#060534'}
                                                                                                                loading={true}
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex flex-1 flex-col truncate">
                                                                                                    <div
                                                                                                        className="font-bold text-xs truncate">
                                                                                                        {ad.name}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="text-xxs truncate">
                                                                                                        {Array.isArray(ad.final_urls) ? (ad.final_urls.length > 0 ? ad.final_urls[0] : "AD") : "AD"}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    (this.state.campaigns.length - 1) !== index &&
                                                                    <div className="border-t flex-1 flex mb-2" />
                                                                }
                                                            </Fragment>
                                                        )
                                                    })
                                                }

                                                {
                                                    (
                                                        (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                        ||
                                                        (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                        ||
                                                        (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                    )
                                                    &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                datasource: false,
                                                                daterange: this.state.premium ? (this.state.tab.celltype.value !== "chart" && this.state.tab.celltype.value !== "table") : false,
                                                                breakdown: this.state.premium ? (this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "table") : true
                                                            })
                                                        }}
                                                            className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-3">NEXT
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.renders.anythingSelected() &&
                                                    this.renders.multipleLevels() &&
                                                    <div className="flex flex-1 bg-yellow-100 rounded-md p-4 mt-4">
                                                        <div className="text-xs text-yellow-500 font-bold">
                                                            OBS! You have selected multiple levels of datasources
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }
                                    {
                                        false &&
                                        this.renders.analyticsSelected() &&
                                        Array.isArray(this.state.analytics_breakdowns) &&
                                        <div className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50 " : "") + (this.renders.stepValid('analytics_breakdown') ? "" : "opacity-50 ") + " px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div className="flex flex-col flex-1">
                                                    <h3 className="mb-0 font-bold">
                                                        Select analytics breakdown
                                                    </h3>
                                                    {
                                                        !this.state.tab.analytics_breakdown &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.state.analytics_breakdowns.length}</span> breakdowns
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.analytics_breakdown.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    (
                                                        (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                        ||
                                                        (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                        ||
                                                        (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                    ) &&
                                                    <div className="flex flex-row">
                                                        {
                                                            this.state.tab.analytics_breakdown &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                analytics_breakdown: !this.state.analytics_breakdown
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.analytics_breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.analytics_breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.analytics_breakdown}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        this.state.analytics_breakdowns.map((item, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <div className={(index !== (this.state.analytics_breakdowns.length - 1) ? "mb-2 " : "")}>
                                                                        <div
                                                                            className={"cursor-pointer flex flex-1 flex-row justify-center"}>
                                                                            <div onClick={() => {
                                                                                item.open = !item.open;
                                                                                this.setState({
                                                                                    analytics_breakdowns: this.state.analytics_breakdowns
                                                                                })
                                                                            }}
                                                                                className="text-purple-500 mr-2 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                                {
                                                                                    !item.open &&
                                                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                                                        icon={"plus"} />
                                                                                }
                                                                                {
                                                                                    item.open &&
                                                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                                                        icon={"minus"} />
                                                                                }
                                                                            </div>
                                                                            {
                                                                                false &&
                                                                                <div onClick={() => {
                                                                                    this.state.tab.analytics_breakdown = item;
                                                                                    this.setState({
                                                                                        tab: this.state.tab,
                                                                                        analytics_breakdown: false,
                                                                                        breakdown: true
                                                                                    })
                                                                                }}
                                                                                    className={((this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                    {
                                                                                        this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value === item.value &&
                                                                                        <div
                                                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div className="flex flex-1 flex-col truncate">
                                                                                <div className="font-bold text-xs truncate">
                                                                                    {item.title}
                                                                                </div>
                                                                                <div className="text-xxs">
                                                                                    There are <span
                                                                                        className="font-bold">{item.values.length}</span> breakdowns
                                                                                    available
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <SlideDown closed={!item.open}>
                                                                            {
                                                                                item.values.map((item_inner) => {
                                                                                    return (
                                                                                        <div className={"cursor-pointer flex flex-1 flex-row justify-center mt-2 pl-10"}>
                                                                                            {
                                                                                                <div onClick={() => {

                                                                                                    this.state.analytics_breakdowns.map((item) => {
                                                                                                        if (item_inner.parent !== item.title) {
                                                                                                            item.open = false;
                                                                                                        }
                                                                                                    })

                                                                                                    this.state.tab.analytics_breakdown = item_inner;
                                                                                                    this.setState({
                                                                                                        analytics_breakdown: this.state.analytics_breakdown,
                                                                                                        tab: this.state.tab,
                                                                                                        analytics_breakdown: false,
                                                                                                        breakdown: true
                                                                                                    })
                                                                                                }}
                                                                                                    className={((this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value === item_inner.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                                    {
                                                                                                        this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value === item_inner.value &&
                                                                                                        <div
                                                                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                            <div className="flex flex-1 flex-col truncate">
                                                                                                <div className="font-bold text-xs truncate">
                                                                                                    {item_inner.name}
                                                                                                </div>
                                                                                                <div className="text-xxs">
                                                                                                    {item_inner.value}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </SlideDown>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                {
                                                    this.state.tab.analytics_breakdown && this.state.tab.analytics_breakdown.value &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                analytics_breakdown: false,
                                                                breakdown: true
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer mt-4 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }
                                    {/*SELECT BREAKDOWN*/}
                                    {
                                        (!this.state.premium || (this.state.premium && this.state.tab.celltype && (this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "table"))) &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50 " : "") + (this.renders.stepValid('breakdown') ? "" : "opacity-50 ") + " px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        breakdown: !this.state.breakdown
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select breakdown
                                                    </h3>
                                                    {
                                                        !this.state.tab.breakdown &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.state.channel_breakdowns.length}</span> breakdowns
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.breakdown && this.state.tab.breakdown.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.breakdown.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    (
                                                        (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                        ||
                                                        (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                        ||
                                                        (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                    ) &&
                                                    <div className="flex flex-row">
                                                        {
                                                            this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                breakdown: !this.state.breakdown
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.breakdown}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    <div className={((this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns["basic"]) ? "opacity-25" : "") + " text-xs mb-1 font-bold "}>
                                                        Breakdowns
                                                    </div>
                                                    {
                                                        this.state.channel_breakdowns.filter((item) => {
                                                            let channels = this.renders.selectedDataSourcesChannells();
                                                            return channels[item.channel];
                                                        }).map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div className={""}>
                                                                        {
                                                                            item.breakdowns.map((inner_item) => {
                                                                                return (
                                                                                    <Fragment key={i}>
                                                                                        <div
                                                                                            className="flex flex-1 flex-row justify-center mb-2">
                                                                                            <div onClick={() => {
                                                                                                inner_item.open = !inner_item.open;
                                                                                                this.setState({
                                                                                                    channel_breakdowns: this.state.channel_breakdowns
                                                                                                })
                                                                                            }}
                                                                                                className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                                                {
                                                                                                    !item.open &&
                                                                                                    <FeatherIcon className={'stroke-current'}
                                                                                                        size={14}
                                                                                                        icon="plus" />
                                                                                                }
                                                                                                {
                                                                                                    item.open &&
                                                                                                    <FeatherIcon className={'stroke-current'}
                                                                                                        size={14}
                                                                                                        icon="minus" />
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                item.channel === "google" &&
                                                                                                <div
                                                                                                    className="bg-google-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../../assets/images/google_icon.svg')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "google_shopping" &&
                                                                                                <div
                                                                                                    className="bg-googleshopping-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../../assets/images/google-shopping.png')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "linkedin" &&
                                                                                                <div
                                                                                                    className="bg-linkedin-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../../assets/images/linkedin_icon.svg')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "facebook" &&
                                                                                                <div
                                                                                                    className="bg-facebook-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img style={{ width: '0.4rem' }}
                                                                                                        src={require('../../../assets/images/facebook_icon.svg')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "google_analytics" &&
                                                                                                <div
                                                                                                    className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../../assets/images/google_analytics_icon.png')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "google_analytics_4" &&
                                                                                                <div
                                                                                                    className="bg-googleanalytics-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../../assets/images/ga_icon.png')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "adform" &&
                                                                                                <div
                                                                                                    className="bg-adform-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../../assets/images/adform_icon.png')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "twitter" &&
                                                                                                <div
                                                                                                    className="bg-twitter-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../../assets/images/twitter_icon.svg')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "tiktok" &&
                                                                                                <div
                                                                                                    className="bg-tiktok-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../../assets/images/tiktok_icon.png')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "bing" &&
                                                                                                <div
                                                                                                    className="bg-bing-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../../assets/images/bing-logo.png')} />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                item.channel === "snapchat" &&
                                                                                                <div
                                                                                                    className="bg-snapchat-500 mr-2 h-8 w-8 rounded flex justify-center align-middle items-center">
                                                                                                    <img className="w-3"
                                                                                                        src={require('../../../assets/images/snapchat.svg')} />
                                                                                                </div>
                                                                                            }
                                                                                            <div className="flex flex-1 flex-col truncate capitalize">
                                                                                                <div className="font-bold text-xs truncate">
                                                                                                    {inner_item.title}
                                                                                                </div>
                                                                                                <div className="text-xxs">
                                                                                                    {inner_item.values.length} available
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <SlideDown closed={!inner_item.open}>
                                                                                            {
                                                                                                inner_item.values.map((dimension) => {
                                                                                                    return (
                                                                                                        <div className="ml-10 flex flex-1 flex-row mb-2">
                                                                                                            <div onClick={() => {
                                                                                                                if (!this.state.tab.channel_breakdowns) {
                                                                                                                    this.state.tab.channel_breakdowns = {};
                                                                                                                    this.state.tab.channel_breakdowns[item.channel] = {};
                                                                                                                }
                                                                                                                if (this.state.tab.channel_breakdowns[item.channel]) {
                                                                                                                    this.state.tab.channel_breakdowns[item.channel] = {};
                                                                                                                }
                                                                                                                this.state.tab.channel_breakdowns[item.channel][dimension.value] = dimension;
                                                                                                                this.setState({
                                                                                                                    tab: this.state.tab
                                                                                                                });
                                                                                                            }}
                                                                                                                className={(this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel] && this.state.tab.channel_breakdowns[item.channel][dimension.value] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                                                {
                                                                                                                    this.state.tab && this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns[item.channel] && this.state.tab.channel_breakdowns[item.channel][dimension.value] &&
                                                                                                                    <FeatherIcon
                                                                                                                        className={'stroke-current'}
                                                                                                                        size={14}
                                                                                                                        icon="check" />
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="flex flex-1 flex-col truncate capitalize">
                                                                                                                <div className="font-bold text-xs truncate">
                                                                                                                    {dimension.name}
                                                                                                                </div>
                                                                                                                <div className="text-xxs">
                                                                                                                    {item.channel}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </SlideDown>
                                                                                    </Fragment>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            (this.state.channel_breakdowns.filter((item) => {
                                                                                let channels = this.renders.selectedDataSourcesChannells();
                                                                                return channels[item.channel];
                                                                            }).length - 1) !== i &&
                                                                            <div className="border-t flex-1 flex mb-2" />
                                                                        }
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.tab.channel_breakdowns && Object.keys(this.state.tab.channel_breakdowns).length > 0 &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                breakdown: false,
                                                                daterange: !this.state.premium || (this.state.tab.celltype.value === "table"),
                                                                chart: this.state.premium && this.state.tab.celltype.value === "chart"
                                                            })
                                                        }}
                                                            className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-4">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }
                                    {/*SELECT BREAKDOWN*/}
                                    {
                                        false &&
                                        this.renders.anythingButAnalyticsSelected() &&
                                        (!this.state.premium || (this.state.premium && this.state.tab.celltype && (this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "table"))) &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50 " : "") + (this.renders.stepValid('breakdown') ? "" : "opacity-50 ") + " px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div className="flex flex-col flex-1">
                                                    <h3 className="mb-0 font-bold">
                                                        Select breakdown
                                                    </h3>
                                                    {
                                                        !this.state.tab.breakdown &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.renders.breakdowns().length}</span> breakdowns
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.breakdown && this.state.tab.breakdown.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.breakdown.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    (
                                                        (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0)
                                                        ||
                                                        (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0)
                                                        ||
                                                        (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0)
                                                    ) &&
                                                    <div className="flex flex-row">
                                                        {
                                                            this.state.tab.breakdown &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                breakdown: !this.state.breakdown
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.breakdown &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.breakdown}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        this.renders.breakdowns().map((item, i) => {
                                                            let channels_selected = this.renders.selectedDataSourcesChannells();
                                                            //REMOVE GOOGLE ANALYTICS
                                                            delete channels_selected.google_analytics;
                                                            delete channels_selected.google_analytics_4;
                                                            let enable_breakdown = (Object.keys(item.supported_channels).filter((channel) => { return item.supported_channels[channel] && channels_selected[channel] }).length) === (Object.keys(channels_selected).filter((channel) => { return channels_selected[channel] }).length);
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div
                                                                        className={((item.disabled || (this.renders.lowerLevelThanCampaign() && item.value !== "overview") || !enable_breakdown) ? "opacity-25 cursor-not-allowed" : "cursor-pointer") + (i !== (this.renders.breakdowns().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                        <div
                                                                            className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon={item.icon} />
                                                                        </div>
                                                                        <div onClick={() => {
                                                                            if (!item.disabled && (!this.renders.lowerLevelThanCampaign() || item.value === "overview") && enable_breakdown) {
                                                                                this.state.tab.breakdown = item;
                                                                                if (item.value === "daily" && this.state.tab.daterange && this.state.tab.daterange.value === "lifetime") {
                                                                                    this.state.tab.daterange = {};
                                                                                }

                                                                                this.state.tab.chart = null;

                                                                                this.setState({
                                                                                    tab: this.state.tab,
                                                                                    breakdown: false,
                                                                                    //daterange: !this.state.premium,
                                                                                    daterange: !this.state.premium || (this.state.tab.celltype.value === "table"),
                                                                                    chart: this.state.premium && this.state.tab.celltype.value === "chart"
                                                                                })
                                                                            }
                                                                        }}
                                                                            className={((this.state.tab.breakdown && this.state.tab.breakdown.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.tab.breakdown && this.state.tab.breakdown.value === item.value &&
                                                                                <div
                                                                                    className="w-full h-full rounded-full bg-purple-500" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {item.title}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                {item.subtitle}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>

                                                {
                                                    this.state.tab.breakdown && this.state.tab.breakdown.value &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                breakdown: false,
                                                                daterange: !this.state.premium || (this.state.tab.celltype.value === "table"),
                                                                chart: this.state.premium && this.state.tab.celltype.value === "chart"
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer mt-4 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    {
                                        this.state.premium && this.state.tab.celltype && this.state.tab.celltype.value === "chart" &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === 'temp_metric') ? "opacity-50 " : "") + (this.renders.stepValid('chart') ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        chart: !this.state.chart
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select chart type
                                                    </h3>
                                                    {
                                                        !this.state.tab.chart &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.renders.chartTypes().length}</span> metrics
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.chart && this.state.tab.chart.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.chart.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    this.renders.stepValid('chart') &&
                                                    <div className="flex flex-row">
                                                        {
                                                            this.state.tab.chart &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                chart: !this.state.chart
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.chart &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.chart &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.chart}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        //className={((item.disabled || (this.renders.lowerLevelThanCampaign() && item.value !== "overview")) ? "opacity-25 cursor-not-allowed" : "cursor-pointer") + (i !== (this.renders.breakdowns().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                        this.renders.chartTypes().map((item, i) => {

                                                            //CHECK IF DAILY BREAKDOWN IS SELECTED
                                                            let date = null;
                                                            if (this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns["basic"] && this.state.tab.channel_breakdowns["basic"]["daily"]) {
                                                                date = true;
                                                            }

                                                            return (
                                                                <Fragment key={i}>
                                                                    <div
                                                                        className={((item.disabled || (item.value === "line" && !date)) ? "opacity-25 cursor-not-allowed" : "cursor-pointer") + (i !== (this.renders.chartTypes().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                        <div
                                                                            className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon={item.icon} />
                                                                        </div>
                                                                        <div onClick={() => {
                                                                            if ((item.disabled || (item.value === "line" && !date))) {

                                                                            } else {
                                                                                this.state.tab.chart = item;
                                                                                if (!this.state.tab.settings) {
                                                                                    this.state.tab.settings = {
                                                                                        title: ""
                                                                                    };
                                                                                }
                                                                                //this.state.tab.settings.title = "table";
                                                                                this.setState({
                                                                                    tab: this.state.tab,
                                                                                    chart: false,
                                                                                    daterange: true
                                                                                })
                                                                            }

                                                                        }}
                                                                            className={((this.state.tab.chart && this.state.tab.chart.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.tab.chart && this.state.tab.chart.value === item.value &&
                                                                                <div
                                                                                    className="w-full h-full rounded-full bg-purple-500" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {item.title}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                {item.subtitle}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.tab.chart && this.state.tab.chart.value &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                chart: false,
                                                                daterange: true,
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer mt-5 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    {
                                        (!this.state.premium || (this.state.premium && this.state.tab.celltype && (this.state.tab.celltype.value === "number" || this.state.tab.celltype.value === "chart" || this.state.tab.celltype.value === "table"))) &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === 'temp_metric') ? "opacity-50 " : "") + (this.renders.stepValid('daterange') ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        daterange: !this.state.daterange
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select date range
                                                    </h3>
                                                    {
                                                        !this.state.tab.daterange &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.renders.dateranges().length}</span> breakdowns
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.daterange && this.state.tab.daterange.value &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected <span
                                                                className="font-bold ml-1 mr-1">{this.state.tab.daterange.title}</span>
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    this.renders.stepValid('daterange') &&
                                                    <div className="flex flex-row">
                                                        {
                                                            (
                                                                (this.state.tab.daterange && this.state.tab.daterange.value !== "custom_dates")
                                                                ||
                                                                (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)
                                                            ) &&
                                                            <div
                                                                className="mr-2 border-green-500 border-2 text-green-500 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                daterange: !this.state.daterange
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.daterange &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.daterange &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.daterange}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        this.renders.dateranges().map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div
                                                                        className={((item.disabled || (this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns["basic"] && this.state.tab.channel_breakdowns["basic"]["daily"] && item.value === "lifetime")) ? "opacity-25 cursor-not-allowed" : "cursor-pointer") + (i !== (this.renders.breakdowns().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                        <div onClick={() => {

                                                                        }}
                                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14} icon={item.icon} />
                                                                        </div>
                                                                        <div onClick={() => {
                                                                            if (!item.disabled && !(this.state.tab.channel_breakdowns && this.state.tab.channel_breakdowns["basic"] && this.state.tab.channel_breakdowns["basic"]["daily"] && item.value === "lifetime")) {
                                                                                this.state.tab.daterange = item;
                                                                                this.setState({
                                                                                    tab: this.state.tab,
                                                                                    daterange: item.value === "custom_dates",
                                                                                    //piechart: item.value !== "custom_dates",
                                                                                    metric: item.value !== "custom_dates"
                                                                                })
                                                                            }
                                                                        }}
                                                                            className={((this.state.tab.daterange && this.state.tab.daterange.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.tab.daterange && this.state.tab.daterange.value === item.value &&
                                                                                <div
                                                                                    className="w-full h-full rounded-full bg-purple-500" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {item.title}
                                                                            </div>
                                                                            {
                                                                                (item.value === "custom_dates" && this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) ?
                                                                                    (<div className="text-xxs">
                                                                                        From {this.state.tab.daterange.start_date} to {this.state.tab.daterange.end_date}
                                                                                    </div>) :
                                                                                    (<div className="text-xxs">
                                                                                        {item.subtitle}
                                                                                    </div>)
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" &&
                                                    <div className="w-full">
                                                        <SingleDatepicker
                                                            maxDate={new Date()}
                                                            from={this.state.tab.daterange.start_date}
                                                            to={this.state.tab.daterange.end_date}
                                                            onChange={(data) => {
                                                                this.state.tab.daterange.start_date = data.start_date;
                                                                this.state.tab.daterange.end_date = data.end_date;
                                                                if (this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) {
                                                                    this.state.daterange = false;
                                                                    //this.state.piechart = true;
                                                                    this.state.metric = true;
                                                                }
                                                                this.setState({
                                                                    metric: this.state.metric,
                                                                    piechart: this.state.piechart,
                                                                    daterange: this.state.daterange,
                                                                    tab: this.state.tab
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    this.state.tab.daterange && this.state.tab.daterange.value && (this.state.tab.daterange.value !== "custom_dates" || (this.state.tab.daterange.value == "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date)) &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                daterange: false,
                                                                metric: true,
                                                                //piechart: !this.state.premium,
                                                            })
                                                        }}
                                                            className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-4">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    {
                                        <div
                                            className={(this.renders.stepValid('metric') ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        metric: !this.state.metric
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Select metrics
                                                    </h3>
                                                </div>
                                                {
                                                    this.renders.stepValid('metric') &&
                                                    <div className="flex flex-row">
                                                        {
                                                            this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length > 0 &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                metric: !this.state.metric
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.metric &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.metric &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.metric}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        Array.isArray(this.state.custom_metrics) &&
                                                        this.state.custom_metrics.length > 0 &&
                                                        ["custom", "calculated"].map((category) => {
                                                            return (
                                                                <Fragment>
                                                                    <div className="">
                                                                        <div
                                                                            className={"cursor-pointer flex flex-1 flex-row justify-center mb-2"}>
                                                                            <div onClick={() => {
                                                                                this.state.tab["open_metric_" + category] = !this.state.tab["open_metric_" + category];
                                                                                this.setState({
                                                                                    tab: this.state.tab
                                                                                })
                                                                            }}
                                                                                className="text-purple-500 mr-2 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                                {
                                                                                    !this.state.tab["open_metric_" + category] &&
                                                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                                                        icon={"plus"} />
                                                                                }
                                                                                {
                                                                                    this.state.tab["open_metric_" + category] &&
                                                                                    <FeatherIcon className={'stroke-current'} size={14}
                                                                                        icon={"minus"} />
                                                                                }
                                                                            </div>

                                                                            <div className="flex flex-1 flex-col truncate">
                                                                                <div className="font-bold text-xs truncate capitalize">
                                                                                    {category}
                                                                                </div>
                                                                                <div className="text-xxs">
                                                                                    There are <span
                                                                                        className="font-bold">{this.renders.customMetrics(this.state.custom_metrics.filter((item) => {
                                                                                            return (!item.calculated && category === "default" && item.default) || (!item.calculated && category === "custom" && !item.default) || (item.calculated && category === "calculated")
                                                                                        }), "").filter((item) => {
                                                                                            if (category !== "calculated") {
                                                                                                if (this.state.premium && this.state.tab.celltype.value !== "table") {
                                                                                                    let channels = this.renders.selectedDataSourcesChannells();
                                                                                                    return item.channels && Object.keys(item.channels).filter((channel) => {
                                                                                                        return channels[channel]
                                                                                                    }).length >= Object.keys(channels).length
                                                                                                } else {
                                                                                                    let channels = this.renders.selectedDataSourcesChannells();
                                                                                                    return item.channels && Object.keys(item.channels).filter((channel) => {
                                                                                                        return channels[channel]
                                                                                                    }).length > 0
                                                                                                }
                                                                                            } else {
                                                                                                return true;
                                                                                            }
                                                                                        }).length}</span> metrics
                                                                                    available
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <SlideDown closed={!this.state.tab["open_metric_" + category]}>
                                                                            <div className="text-xs font-bold mb-1 pl-10">
                                                                                Search {category}
                                                                            </div>
                                                                            <div className="flex flex-1 flex-row justify-center mb-2 pl-10">
                                                                                <div className="flex flex-1">
                                                                                    <input
                                                                                        onChange={(event) => {
                                                                                            if (!this.state.search) {
                                                                                                this.state.search = {};
                                                                                            }
                                                                                            this.state.search["search_custom" + category] = event.target.value;
                                                                                            this.setState({
                                                                                                search: this.state.search
                                                                                            });
                                                                                        }}
                                                                                        placeholder={"Search ..."}
                                                                                        value={this.state.search ? (this.state.search["search_custom" + category] ? this.state.search["search_custom" + category] : "") : ""}
                                                                                        className="h-10 w-full rounded text-purple-500 border-2 flex pl-4 pr-4 box-border"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.renders.customMetrics(this.state.custom_metrics.filter((item) => {
                                                                                    return (!item.calculated && category === "default" && item.default) || (!item.calculated && category === "custom" && !item.default) || (item.calculated && category === "calculated")
                                                                                }), (this.state.search ? (this.state.search["search_custom" + category] ? this.state.search["search_custom" + category] : "") : "")).filter((item) => {
                                                                                    if (category !== "calculated") {
                                                                                        let channels = this.renders.selectedDataSourcesChannells();
                                                                                        return item.channels && Object.keys(item.channels).filter((channel) => {
                                                                                            return channels[channel]
                                                                                        }).length > 0
                                                                                    } else {
                                                                                        return true;
                                                                                    }
                                                                                }).sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((item, i) => {

                                                                                    let channels = this.renders.selectedDataSourcesChannells();

                                                                                    return (
                                                                                        <Fragment key={i}>
                                                                                            <div className="flex flex-row pl-10">
                                                                                                <div
                                                                                                    className={((this.renders.selectedDataSources().filter((source) => {
                                                                                                        if (category === "custom" || category === "default") {
                                                                                                            return item.channels && item.channels[source.channel];
                                                                                                        } else {
                                                                                                            if (item.metrics) {
                                                                                                                for (let metric in item.metrics) {
                                                                                                                    return item.metrics[metric].channels && item.metrics[metric].channels[source.channel];
                                                                                                                }
                                                                                                            } else {
                                                                                                                return false;
                                                                                                            }
                                                                                                        }
                                                                                                    }).length > 0) ? "cursor-pointer " : "opacity-50 cursor-not-allowed") + " mb-2 flex flex-1 flex-row justify-center"}>
                                                                                                    {
                                                                                                        <div onClick={() => {

                                                                                                            if (!this.state.tab.table_metrics_index) {
                                                                                                                this.state.tab.table_metrics_index = [];
                                                                                                            }

                                                                                                            if (!this.state.tab.metrics || (this.state.tab.metrics && !this.state.tab.metrics.custom)) {
                                                                                                                this.state.tab.metrics = {
                                                                                                                    custom: {}
                                                                                                                };
                                                                                                                this.state.tab.metrics.custom[item.name] = item;
                                                                                                                this.state.tab.table_metrics_index.push(item);
                                                                                                            } else if (item.name in this.state.tab.metrics.custom) {
                                                                                                                delete this.state.tab.metrics.custom[item.name];
                                                                                                                this.state.tab.table_metrics_index = this.state.tab.table_metrics_index.filter((metric) => {
                                                                                                                    return metric.name !== item.name
                                                                                                                });
                                                                                                            } else {
                                                                                                                this.state.tab.metrics.custom[item.name] = item;
                                                                                                                this.state.tab.table_metrics_index.push(item);
                                                                                                            }

                                                                                                            //CHECK IF TABLE METRICS CONTAINS SOMETHING IS SHOULDNT
                                                                                                            for (let metric in this.state.tab.metrics.custom) {
                                                                                                                let exist = false;
                                                                                                                this.state.custom_metrics.map((item) => {
                                                                                                                    if (metric == item.name) {
                                                                                                                        exist = true;
                                                                                                                    }
                                                                                                                });
                                                                                                                if (!exist) {
                                                                                                                    delete this.state.tab.metrics.custom[metric];
                                                                                                                }
                                                                                                            }

                                                                                                            this.state.tab.table_metrics_index = this.state.tab.table_metrics_index.filter((metric) => {
                                                                                                                return this.state.tab.metrics.custom[metric.name]
                                                                                                            });

                                                                                                            console.log(this.state.tab.table_metrics_index);
                                                                                                            console.log(this.state.tab.metrics.custom);

                                                                                                            /*
                                                                                                            if (!this.state.tab.table_metrics || !this.state.tab.table_metrics_index) {
                                                                                                                this.state.tab.table_metrics = {};
                                                                                                                this.state.tab.table_metrics_index = [];
                                                                                                                item.metric_type = "custom";
                                                                                                                this.state.tab.table_metrics[item.name] = item;
                                                                                                                this.state.tab.table_metrics_index.push(item);
                                                                                                            } else {
                                                                                                                if (item.name in this.state.tab.table_metrics) {
                                                                                                                    delete this.state.tab.table_metrics[item.name];
                                                                                                                    this.state.tab.table_metrics_index = this.state.tab.table_metrics_index.filter((metric) => {
                                                                                                                        return metric.name !== item.name
                                                                                                                    });
                                                                                                                } else {
                                                                                                                    item.metric_type = "custom";
                                                                                                                    this.state.tab.table_metrics[item.name] = item;
                                                                                                                    this.state.tab.table_metrics_index.push(item);
                                                                                                                }
                                                                                                            }
                                                                                                            */

                                                                                                            this.setState({
                                                                                                                tab: this.state.tab,
                                                                                                            })

                                                                                                            /*
                                                                                                            if (this.renders.selectedDataSources().filter((source) => {
                                                                                                                if (category === "custom" || category === "default") {
                                                                                                                    return item.channels && item.channels[source.channel];
                                                                                                                } else {
                                                                                                                    if (item.metrics) {
                                                                                                                        for (let metric in item.metrics) {
                                                                                                                            return item.metrics[metric].channels && item.metrics[metric].channels[source.channel];
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                }
                                                                                                            }).length > 0) {

                                                                                                                if (!this.state.tab.metrics || (this.state.tab.metrics && !this.state.tab.metrics.custom)) {
                                                                                                                    this.state.tab.metrics = {
                                                                                                                        custom: {}
                                                                                                                    };
                                                                                                                    this.state.tab.metrics.custom[item.name] = item;
                                                                                                                } else if (item.name in this.state.tab.metrics.custom) {
                                                                                                                    delete this.state.tab.metrics.custom[item.name];
                                                                                                                } else {
                                                                                                                    this.state.tab.metrics.custom[item.name] = item;
                                                                                                                }

                                                                                                                if (!this.state.tab.table_metrics || !this.state.tab.table_metrics_index) {
                                                                                                                    this.state.tab.table_metrics = {};
                                                                                                                    this.state.tab.table_metrics_index = [];
                                                                                                                    item.metric_type = "custom";
                                                                                                                    this.state.tab.table_metrics[item.name] = item;
                                                                                                                    this.state.tab.table_metrics_index.push(item);
                                                                                                                } else {
                                                                                                                    if (item.name in this.state.tab.table_metrics) {
                                                                                                                        delete this.state.tab.table_metrics[item.name];
                                                                                                                        this.state.tab.table_metrics_index = this.state.tab.table_metrics_index.filter((metric) => {
                                                                                                                            return metric.name !== item.name
                                                                                                                        });
                                                                                                                    } else {
                                                                                                                        item.metric_type = "custom";
                                                                                                                        this.state.tab.table_metrics[item.name] = item;
                                                                                                                        this.state.tab.table_metrics_index.push(item);
                                                                                                                    }
                                                                                                                }

                                                                                                                this.setState({
                                                                                                                    tab: this.state.tab,
                                                                                                                })

                                                                                                                
                                                                                                                if (!this.state.tab.table_metrics) {
                                                                                                                    this.state.tab.table_metrics = {};
                                                                                                                }
                                                                                                                if (!this.state.tab.table_metrics_index) {
                                                                                                                    this.state.tab.table_metrics_index = [];
                                                                                                                }
                                                                                                                if (item.name in this.state.tab.table_metrics) {
                                                                                                                    delete this.state.tab.table_metrics[item.name];
                                                                                                                    this.state.tab.table_metrics_index = this.state.tab.table_metrics_index.filter((metric) => {
                                                                                                                        return metric.name !== item.name
                                                                                                                    });
                                                                                                                } else {
                                                                                                                    item.metric_type = "custom";
                                                                                                                    this.state.tab.table_metrics[item.name] = item;
                                                                                                                    this.state.tab.table_metrics_index.push(item);
                                                                                                                }
                                                                                                                if (!this.state.tab.settings) {
                                                                                                                    this.state.tab.settings = {
                                                                                                                        title: ""
                                                                                                                    };
                                                                                                                    //this.state.tab.settings.title = "Table";
                                                                                                                }
                                                                                                                this.setState({
                                                                                                                    tab: this.state.tab,
                                                                                                                })
                                                                                                                
                                                                                                            }
                                                                                                            */
                                                                                                        }}
                                                                                                            className={(this.state.tab.metrics && this.state.tab.metrics.custom && this.state.tab.metrics.custom[item.name] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                                            {
                                                                                                                this.state.tab.metrics && this.state.tab.metrics.custom && this.state.tab.metrics.custom[item.name] &&
                                                                                                                <FeatherIcon
                                                                                                                    className={'stroke-current'}
                                                                                                                    size={14}
                                                                                                                    icon="check" />
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                    <div
                                                                                                        className="flex flex-1 flex-col truncate">
                                                                                                        <div
                                                                                                            className="font-bold text-xs truncate">
                                                                                                            {item.alias ? item.alias : item.name}
                                                                                                        </div>
                                                                                                        <div className="text-xxs capitalize">
                                                                                                            {
                                                                                                                item.channels &&
                                                                                                                Object.keys(item.channels).map((channel, index) => {
                                                                                                                    let last = index === (Object.keys(item.channels).length - 1);
                                                                                                                    if (channels[channel]) {
                                                                                                                        return (<span className="font-bold mr-1">{channel.replace("_", " ")}{!last ? "," : ""}</span>)
                                                                                                                    } else {
                                                                                                                        return (<span className="mr-1">{channel.replace("_", " ")}{!last ? "," : ""}</span>)
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </SlideDown>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    ((
                                                        (this.state.premium && this.state.tab.metric && this.state.tab.metric.value !== 'new_metric' && this.state.tab.metric.value !== 'channel_metric' && this.state.tab.celltype.value !== "table")
                                                        ||
                                                        (this.state.premium && this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length > 0 && this.state.tab.celltype.value === "table")
                                                    )
                                                        ||
                                                        (!this.state.premium && this.state.tab.table_metrics && Object.keys(this.state.tab.table_metrics).length > 0)
                                                    ) &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                metric: false,
                                                                piechart: !this.state.premium,
                                                                settings: this.state.premium
                                                            })
                                                        }}
                                                            className="font-bold rounded flex justify-center align-middle items-center text-xs text-white bg-purple-500 h-8 px-8 cursor-pointer mt-4">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }
                                    {
                                        !this.renders.lowerLevelThanCampaign() &&
                                        !this.state.premium &&
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric") ? "opacity-50 " : "") + (this.renders.stepValid('piechart') ? " " : " opacity-50 ") + " px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        piechart: !this.state.piechart
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    <h3 className="mb-0 font-bold">
                                                        Show pie charts
                                                    </h3>
                                                    {
                                                        (!this.state.tab.piechart || (this.state.tab.piechart && Object.keys(this.state.tab.piechart).length < 1)) &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            There are <span
                                                                className="font-bold ml-1 mr-1">{this.renders.breakdowns().length}</span> breakdowns
                                                            available
                                                        </p>
                                                    }
                                                    {
                                                        this.state.tab.piechart && Object.keys(this.state.tab.piechart).length > 0 &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            You have selected
                                                            {
                                                                Object.keys(this.state.tab.piechart).length !== this.renders.piecharts().length &&
                                                                <span
                                                                    className="font-bold ml-1 mr-1">{Object.keys(this.state.tab.piechart).map((key) => {
                                                                        return this.state.tab.piechart[key].title
                                                                    }).join(", ")}</span>
                                                            }
                                                            {
                                                                Object.keys(this.state.tab.piechart).length === this.renders.piecharts().length &&
                                                                <span className="font-bold ml-1 mr-1">All charts</span>
                                                            }
                                                        </p>
                                                    }
                                                </div>
                                                <div className="flex flex-row">
                                                    {
                                                        this.renders.stepValid('piechart') &&
                                                        <div
                                                            className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                icon={"check"} />
                                                        </div>
                                                    }
                                                    {
                                                        this.renders.stepValid('piechart') &&
                                                        <div onClick={() => {
                                                            this.setState({
                                                                piechart: !this.state.piechart
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.piechart &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.piechart &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <SlideDown closed={!this.state.piechart}>
                                                <div className="w-full h-6" />
                                                <div className="">
                                                    {
                                                        this.renders.piecharts().map((item, i) => {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <div
                                                                        className={(item.disabled ? "opacity-25 cursor-not-allowed" : "cursor-pointer") + (i !== (this.renders.piecharts().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                                        <div
                                                                            className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                            <FeatherIcon className={'stroke-current'}
                                                                                size={14}
                                                                                icon={item.icon} />
                                                                        </div>
                                                                        <div onClick={() => {
                                                                            if (!item.disabled) {
                                                                                if (!this.state.tab.piechart) {
                                                                                    this.state.tab.piechart = {};
                                                                                }
                                                                                if (this.state.tab.piechart[item.value]) {
                                                                                    delete this.state.tab.piechart[item.value];
                                                                                } else {
                                                                                    this.state.tab.piechart[item.value] = item;
                                                                                }
                                                                                this.setState({
                                                                                    tab: this.state.tab
                                                                                })
                                                                            }
                                                                        }}
                                                                            className={((this.state.tab.piechart && this.state.tab.piechart[item.value]) ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                            {
                                                                                this.state.tab.piechart && this.state.tab.piechart[item.value] &&
                                                                                <FeatherIcon
                                                                                    className={'stroke-current'}
                                                                                    size={14}
                                                                                    icon="check" />
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col truncate">
                                                                            <div className="font-bold text-xs truncate">
                                                                                {item.title}
                                                                            </div>
                                                                            <div className="text-xxs">
                                                                                {item.subtitle}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    !this.state.premium &&
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                piechart: false,
                                                                settings: true
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer mt-5 h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    {
                                        <div
                                            className={(this.state.tab.metric && (this.state.tab.metric.value === "new_metric" || this.state.tab.metric.value === "channel_metric" || this.state.tab.metric.value === "temp_metric") ? "opacity-50 " : "") + (this.renders.stepValid('settings') ? " " : "opacity-50 ") + "px-4 sm:px-6 pb-6 border-b pt-6"}>
                                            <div className="flex flex-1 flex-row justify-center items-center">
                                                <div onClick={() => {
                                                    this.setState({
                                                        settings: !this.state.settings
                                                    })
                                                }} className="flex flex-col flex-1 cursor-pointer">
                                                    {
                                                        this.state.premium &&
                                                        <h3 className="mb-0 font-bold">
                                                            Settings
                                                        </h3>
                                                    }
                                                    {
                                                        !this.state.premium &&
                                                        <h3 className="mb-0 font-bold">
                                                            Table settings
                                                        </h3>
                                                    }
                                                    {
                                                        this.state.premium &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Select color of each data source and edit data
                                                        </p>
                                                    }
                                                    {
                                                        !this.state.premium &&
                                                        <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                                            Set order of columns in table
                                                        </p>
                                                    }
                                                </div>
                                                {
                                                    this.renders.stepValid('settings') &&
                                                    <div className="flex flex-row">
                                                        {
                                                            this.renders.stepValid('settings') &&
                                                            <div
                                                                className="mr-2 text-green-500 border-green-500 border-2 bg-green-100 cursor-pointer h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"check"} />
                                                            </div>
                                                        }
                                                        <div onClick={() => {
                                                            this.setState({
                                                                settings: !this.state.settings
                                                            })
                                                        }}
                                                            className="text-purple-500 bg-purple-100 cursor-pointer h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                            {
                                                                !this.state.settings &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-down"} />
                                                            }
                                                            {
                                                                this.state.settings &&
                                                                <FeatherIcon className={'stroke-current'} size={14}
                                                                    icon={"chevron-up"} />
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <SlideDown closed={!this.state.settings}>
                                                <div className="w-full h-6" />
                                                {
                                                    <div className="mb-4">
                                                        <div className="text-xs font-bold mb-1">
                                                            Show inline values
                                                        </div>
                                                        {
                                                            [
                                                                { title: "Disabled", subtitle: "Inline values will only show on hover", value: "disabled", default: true },
                                                                { title: "Enabled", subtitle: "Inline values will be visible", value: "enabled" }
                                                            ].map((item, i) => {
                                                                return (
                                                                    <Fragment key={i}>
                                                                        <div
                                                                            className={(i !== 1 ? " mb-2" : "") + " cursor-pointer flex flex-1 flex-row justify-center"}>
                                                                            <div onClick={() => {
                                                                                if (!this.state.tab.settings) {
                                                                                    this.state.tab.settings = { title: "" };
                                                                                }
                                                                                this.state.tab.settings.chart_numbers = item;
                                                                                this.setState({
                                                                                    tab: this.state.tab
                                                                                });
                                                                            }}
                                                                                className={(((!(this.state.tab.settings && this.state.tab.settings.chart_numbers && this.state.tab.settings.chart_numbers.value) && item.default) ||
                                                                                    (this.state.tab.settings && this.state.tab.settings.chart_numbers && this.state.tab.settings.chart_numbers.value === item.value)) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                                                {
                                                                                    ((!(this.state.tab.settings && this.state.tab.settings.chart_numbers && this.state.tab.settings.chart_numbers.value) && item.default) ||
                                                                                        (this.state.tab.settings && this.state.tab.settings.chart_numbers && this.state.tab.settings.chart_numbers.value === item.value)) &&
                                                                                    <div
                                                                                        className="w-full h-full rounded-full bg-purple-500" />
                                                                                }
                                                                            </div>
                                                                            <div className="flex flex-1 flex-col truncate">
                                                                                <div className="font-bold text-xs truncate">
                                                                                    {item.title}
                                                                                </div>
                                                                                <div className="text-xxs">
                                                                                    {item.subtitle}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                                {
                                                    <div className="flex flex-1 flex-col justify-center mb-4">
                                                        <div className="text-xs font-bold mb-2">
                                                            Order table headers
                                                        </div>
                                                        <div>
                                                            {
                                                                [
                                                                    {
                                                                        title: "preview",
                                                                        description: "Shows preview of ad",
                                                                        value: "remove_preview"
                                                                    },
                                                                    {
                                                                        title: "channel",
                                                                        description: "Shows channel of datasource",
                                                                        value: "remove_channel"
                                                                    },
                                                                    {
                                                                        title: "breakdown",
                                                                        description: "Show data breakdown",
                                                                        value: "remove_breakdown"
                                                                    },
                                                                    {
                                                                        title: "breakdown value",
                                                                        description: "Show data breakdown value",
                                                                        value: "remove_breakdown_value"
                                                                    },
                                                                    {
                                                                        title: "level",
                                                                        description: "Shows if datasource is campaign, adgroup or ad",
                                                                        value: "remove_level"
                                                                    },
                                                                    {
                                                                        title: "id",
                                                                        description: "Shows id of datasource",
                                                                        value: "remove_id"
                                                                    },
                                                                    {
                                                                        title: "name",
                                                                        description: "Shows name of datasource",
                                                                        value: "remove_name"
                                                                    }
                                                                ].map((item) => {
                                                                    return (
                                                                        <div
                                                                            className="w-full flex flex-row justify-center cursor-pointer align-middle items-center mb-2">
                                                                            <div
                                                                                className="text-purple-500 text-xs font-bold bg-purple-100 h-8 w-8 flex rounded font-bold justify-center align-middle items-center">
                                                                                <FeatherIcon
                                                                                    className={'stroke-current'}
                                                                                    size={14}
                                                                                    icon="lock" />
                                                                            </div>
                                                                            <div className="text-xs flex flex-1 ml-2">
                                                                                <div
                                                                                    className="flex flex-1 flex-col truncate">
                                                                                    <div
                                                                                        className="font-bold text-xs truncate capitalize">
                                                                                        {item.title}
                                                                                    </div>
                                                                                    <div className="text-xxs">
                                                                                        {item.description}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div onClick={() => {
                                                                                this.state.tab[item.value] = !this.state.tab[item.value];
                                                                                this.setState({
                                                                                    tab: this.state.tab
                                                                                });
                                                                            }}
                                                                                className={((!this.state.tab[item.value]) ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer h-8 w-8 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                                                {
                                                                                    !this.state.tab[item.value] &&
                                                                                    <FeatherIcon
                                                                                        className={'stroke-current'}
                                                                                        size={14}
                                                                                        icon="check" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div>
                                                            <SortableList
                                                                items={Array.isArray(this.state.tab.table_metrics_index) ? this.state.tab.table_metrics_index : []}
                                                                onSortEnd={onSortEnd}
                                                                axis="y"
                                                                distance={1}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    <div className="flex justify-center items-center">
                                                        <div onClick={() => {
                                                            this.setState({
                                                                settings: false,
                                                            })
                                                        }}
                                                            className="font-bold text-xs text-white cursor-pointer h-8 px-8 justify-center items-center rounded flex bg-purple-500">NEXT
                                                        </div>
                                                    </div>
                                                }
                                            </SlideDown>
                                        </div>
                                    }

                                    <div className="px-4 sm:px-6 mt-6">
                                        <div onClick={() => {
                                            if (this.renders.valid()) {

                                                if (this.state.tab.daterange && this.state.tab.daterange.value === "10_days") {
                                                    this.state.tab.daterange.start_date = moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD");
                                                    this.state.tab.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                                                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "20_days") {
                                                    this.state.tab.daterange.start_date = moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD");
                                                    this.state.tab.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                                                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "30_days") {
                                                    this.state.tab.daterange.start_date = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
                                                    this.state.tab.daterange.end_date = moment(new Date()).format("YYYY-MM-DD");
                                                } else if (this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates") {

                                                } else {
                                                    this.state.tab.daterange = {};
                                                    this.state.tab.daterange.value = "lifetime";
                                                }

                                                if (!this.state.tab.breakdown) {
                                                    this.state.tab.breakdown = {};
                                                    this.state.tab.breakdown.value = "overview";
                                                }

                                                if (!this.state.tab.channel_breakdowns) {
                                                    this.state.tab.channel_breakdowns = {};
                                                    //this.state.tab.breakdown.value = "overview";
                                                }

                                                /*
                                                if (!this.state.tab.analytics_breakdown) {
                                                    this.state.tab.analytics_breakdown = {};
                                                    this.state.tab.analytics_breakdown.value = null;
                                                }

                                                //CHECK ORIGINAL TAB
                                                if (Object.keys(this.state.init_tab.data_sources.campaigns).length < 1 &&
                                                    Object.keys(this.state.init_tab.data_sources.adgroups).length < 1 &&
                                                    Object.keys(this.state.init_tab.data_sources.ads).length < 1) {

                                                }
                                                */

                                                /*
                                                this.state.tab.metrics = {
                                                    custom: {}
                                                };
                                                */

                                                /*
                                                for (let metric in this.state.tab.table_metrics) {
                                                    if (this.state.tab.table_metrics[metric].metric_type === "custom") {
                                                        if (!this.state.tab.metrics.custom) {
                                                            this.state.tab.metrics.custom = {};
                                                        }
                                                        this.state.tab.metrics.custom[metric] = this.state.tab.table_metrics[metric];
                                                    }
                                                }
                                                */

                                                if (this.renders.lowerLevelThanCampaign()) {
                                                    this.state.tab.piechart = {};
                                                }

                                                /*
                                                //SET DEFAULT METRICS
                                                this.state.default_metrics.map((item) => {
                                                    if (this.state.tab.piechart && this.state.tab.piechart.click && item.name.toLowerCase() === "clicks") {
                                                        this.state.tab.metrics["custom"][item.name] = item;
                                                    }
                                                    if (this.state.tab.piechart && this.state.tab.piechart.impressions && item.name.toLowerCase() === "impressions") {
                                                        this.state.tab.metrics["custom"][item.name] = item;
                                                    }
                                                    if (this.state.tab.piechart && this.state.tab.piechart.ctr && item.name.toLowerCase() === "avg. ctr") {
                                                        this.state.tab.metrics["custom"][item.name] = item;
                                                    }
                                                    if (this.state.tab.piechart && this.state.tab.piechart.cpc && item.name.toLowerCase() === "avg. cpc") {
                                                        this.state.tab.metrics["custom"][item.name] = item;
                                                    }
                                                    if (this.state.tab.piechart && this.state.tab.piechart.spend && item.name.toLowerCase() === "spend") {
                                                        this.state.tab.metrics["custom"][item.name] = item;
                                                    }
                                                });
                                                */


                                                //UPDATE CAMPAIGNS
                                                this.props.updateData(
                                                    this.state.selected_campaigns_internal,
                                                    this.state.selected_adgroups_internal,
                                                    this.state.selected_ads_internal,
                                                    this.state.tab
                                                );

                                                this.props.toggle();

                                            } else {
                                                this.setState({
                                                    error: true
                                                })
                                            }
                                        }}
                                            className={(this.renders.valid() ? "bg-green-500 text-white cursor-pointer" : "bg-gray-100 text-gray-500 cursor-not-allowed") + " py-3 px-4 transition-all duration-200 rounded-full font-bold w-full text-sm text-center"}>
                                            {
                                                !this.state.premium &&
                                                <span>
                                                    Update data
                                                </span>
                                            }
                                            {
                                                this.state.premium && !this.state.update_cell &&
                                                <span>
                                                    Add cell
                                                </span>
                                            }
                                            {
                                                this.state.premium && this.state.update_cell &&
                                                <span>
                                                    Update cell
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }

}

export default ReportComponentsSlideIn;