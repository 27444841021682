import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingTargetingFacebook from "../advertising-targeting-facebook";
import languages from '../../assets/json/languages';
import {calls} from "./calls";
import ReactTooltip from "react-tooltip";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";
import CSVReader from 'react-csv-reader'
import AdvertisingKeywordsIdeas from "../advertising-keywords-ideas";
import AdvertisingKeywordsLanguage from "../advertising-keywords-language";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import {SlideDown} from "react-slidedown";
import Loader from "../loader";

class AdvertisingKeywordsPositiveAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            site: {},
            channels: {},
            keywords: [],
            languages: [],
            creatives: {},
            language: {},
            keywordsdata: {},
            exact: true,
            phrase: true,
            broad: false,
            theInputKey: "",
            cpc: 0,
            loading: false
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            client: nextProps.client,
            site: nextProps.site,
            channels: nextProps.channels,
            creatives: nextProps.creatives,
            campaign: nextProps.campaign,
            keywordsdata: nextProps.keywordsdata,
            website: nextProps.website,
            language: nextProps.language,
            keywords: nextProps.keywords ? nextProps.keywords : [],
            ideas: nextProps.ideas ? nextProps.ideas : []
        });
    }

    componentDidMount() {
        this.setState({
            client: this.props.client,
            site: this.props.site,
            channels: this.props.channels,
            creatives: this.props.creatives,
            campaign: this.props.campaign,
            keywordsdata: this.props.keywordsdata,
            website: this.props.website,
            language: this.props.language,
            keywords: this.props.keywords ? this.props.keywords : [],
            ideas: this.props.ideas ? this.props.ideas : []
        })
    }

    init = {};

    functions = {
        main: (keyword, extras) => {

            if (keyword && keyword !== "") {
                let id = Math.floor((Math.random() * 9999999999) + 1);
                keyword = keyword.trim();
                let main = {
                    id: id,
                    campaign_id: this.state.campaign.id,
                    index: 0,
                    name: keyword,
                    url: this.state.campaign.website,
                    path1: this.state.campaign.path1,
                    path2: this.state.campaign.path2,
                    exact: this.state.exact,
                    broad: this.state.broad,
                    phrase: this.state.phrase,
                    prefix: "",
                    suffix: "",
                    longtails: [],
                    negative: [],
                    cpc: 0,
                    loading: true
                };
                if (extras) {
                    if (extras.prefix) {
                        main.prefix = extras.prefix
                    }
                    if (extras.suffix) {
                        main.suffix = extras.suffix
                    }
                    if (extras.exact) {
                        main.exact = extras.exact
                    }
                    if (extras.broad) {
                        main.broad = extras.broad
                    }
                    if (extras.phrase) {
                        main.phrase = extras.phrase
                    }
                    if (extras.url) {
                        main.url = extras.url
                    }
                    if (extras.path1) {
                        main.path1 = extras.path1
                    }
                    if (extras.path2) {
                        main.path2 = extras.path2
                    }
                    if (extras.cpc) {
                        main.cpc = extras.cpc
                    }
                    if(extras.skip_insert_keyword){
                        main.skip_insert_keyword = extras.skip_insert_keyword
                    }
                }

                //CHECK IF KEYWORD IS TO LONG FOR KEYWORD INSERT
                if(typeof main.name === "string" && main.name.length > 30){
                    main.skip_insert_keyword = true;
                }

                if(!main.cpc || main.cpc === 0){
                    try{
                        if( this.state.campaign.budget['google'].focus.value === "manual_cpc" || this.state.campaign.budget['google'].focus.value === "enhanced_manual_cpc"){
                            main.cpc = this.state.campaign.budget['google'].target_cost;
                        }
                    }catch (e) {}
                }

                if(Array.isArray(this.state.keywords) && this.state.keywords.filter((item) => {
                    return item.name.toLowerCase() === keyword.toLowerCase() && item.campaign_id === this.state.campaign.id
                }).length < 1){
                    let item = JSON.parse(JSON.stringify(main));
                    item.id = Math.floor((Math.random() * 9999999999) + 1);
                    item.index = this.state.keywords.filter((item) => {
                        return item.campaign_id === this.state.campaign.id
                    }).length + 1;
                    this.state.keywords.unshift(item);
                }

                this.props.onChange(this.state.keywords);
                this.setState({
                    main: "",
                    exact: this.state.exact,
                    phrase: this.state.phrase,
                    broad: this.state.broad
                }, () => {

                    //DONT REMOVE!!!!!!!!!!!!!!!!!!
                    //DONT REMOVE!!!!!!!!!!!!!!!!!!
                    //DONT REMOVE!!!!!!!!!!!!!!!!!!
                    //DONT REMOVE!!!!!!!!!!!!!!!!!!
                    //DONT REMOVE!!!!!!!!!!!!!!!!!!

                    /*
                    //ADD KEYWORDS TO KEYWORDPLANNER
                    if(this.state.keywordsdata.keyword_campaign_resource){
                        let items = [];
                        if(main.exact){
                            items.push({id: main.id, type: 'exact', keyword: main.name, cpc: main.cpc?main.cpc:0});
                        }
                        if(main.broad){
                            items.push({id: main.id, type: 'broad', keyword: main.name, cpc: main.cpc?main.cpc:0});
                        }
                        if(main.phrase){
                            items.push({id: main.id, type: 'phrase', keyword: main.name, cpc: main.cpc?main.cpc:0});
                        }
                        calls.addKeywordPlannerItems({
                            client: this.state.client?this.state.client.id:null,
                            keyword_plan: this.state.keywordsdata.keyword_campaign_resource,
                            keywords: items}).then((item) => {
                            calls.getKeywordPlannerReport({
                                client: this.state.client?this.state.client.id:null,
                                keyword_plan: this.state.keywordsdata.keyword_plan}).then((response) => {
                            }, (error) => {

                            });
                        }, (error) => {

                        })
                    }
                    */

                    //DONT REMOVE!!!!!!!!!!!!!!!!!!
                    //DONT REMOVE!!!!!!!!!!!!!!!!!!
                    //DONT REMOVE!!!!!!!!!!!!!!!!!!
                    //DONT REMOVE!!!!!!!!!!!!!!!!!!
                    //DONT REMOVE!!!!!!!!!!!!!!!!!!

                });
            }
        },
        upload: (list) => {

            if(!this.state.loading){
                this.setState({
                    loading: true
                }, () => {

                    //REMOVE DUPLICATES
                    let temp_list = {};
                    try{
                        list.map((item) => {
                            if(item.keyword && item.keyword !== "" && typeof item.keyword === "string"){
                                let item_ref = JSON.parse(JSON.stringify(item));
                                item_ref.keyword = item_ref.keyword.trim();
                                item_ref.keyword = item_ref.keyword.toLowerCase();
                                item_ref.keyword = item_ref.keyword.replace(/^\s+/g, '');
                                item_ref.keyword = item_ref.keyword.replace(/\s+$/g, '');
                                let exist = false;
                                for(let keyword in temp_list){
                                    if(keyword == item_ref.keyword){
                                        exist = true;
                                    }
                                }
                                if(!(item_ref.keyword in temp_list) && !exist){
                                    temp_list[item_ref.keyword] = item;
                                }
                            }
                        });
                        list = [];
                        for(let key in temp_list){
                            list.push(temp_list[key]);
                        }
                    }catch (e) {}

                    try {
                        for (let i = 0; i < list.length; i++) {
                            let id = Math.floor((Math.random() * 9999999999) + 1);
                            let item = {
                                id: id,
                                keyword: null,
                                prefix: "",
                                suffix: "",
                                exact: true,
                                phrase: true,
                                broad: false,
                                url: this.state.campaign.website,
                                display_url: this.state.campaign.display_website,
                                path1: this.state.campaign.path1,
                                path2: this.state.campaign.path2,
                                cpc: 0
                            };
                            for (let key in list[i]) {
                                if (key === 'keyword') {
                                    item.keyword = list[i].keyword;
                                }
                                if (key === 'prefix') {
                                    item.prefix = list[i].prefix;
                                }
                                if (key === 'suffix') {
                                    item.suffix = list[i].suffix;
                                }
                                if (key === 'exact') {
                                    item.exact = list[i].exact;
                                }
                                if (key === 'phrase') {
                                    item.phrase = list[i].phrase;
                                }
                                if (key === 'broad') {
                                    item.broad = list[i].broad;
                                }
                                if (key === 'url') {
                                    item.url = list[i].url;
                                }
                                if (key === 'path1') {
                                    item.path1 = list[i].path1;
                                }
                                if (key === 'path2') {
                                    item.path2 = list[i].path2;
                                }
                                if (key === 'cpc') {
                                    item.cpc = list[i].cpc;
                                }
                            }
                            if(item.keyword){
                                this.functions.main(item.keyword, item);
                            }
                        }
                        const randomString = Math.random().toString(36);
                        this.setState({
                            theInputKey: randomString,
                            loading: false
                        });
                    } catch (e) {
                        this.setState({
                            loading: false
                        });
                    }
                })
            }
        }
    };

    validators = {};

    renders = {
        characters: (item) => {
            try {
                let prefix = item.prefix ? item.prefix.length : 0;
                let keyword = item.name.length;
                let suffix = item.suffix ? item.suffix.length : 0;
                return prefix + keyword + suffix;
            } catch (e) {
                return 0
            }
        }
    };

    render() {
        return (
            <div className="flex flex-col">
                <div className="flex flex-row">
                    <div className="flex flex-row mr-2">
                        <div className="mr-2">
                            <div className="text-xs font-bold mb-1">
                                Exact
                            </div>
                            <div style={{height: "55px", backgroundColor: '#fcfcfc'}}
                                 className="border rounded px-2 h-full flex justify-center items-center">
                                <Switch
                                    onColor="#1AD5BD"
                                    checked={this.state.exact}
                                    disabled={!this.state.phrase && !this.state.broad}
                                    onChange={(checked) => {
                                        this.setState({
                                            exact: checked
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mr-2">
                            <div className="text-xs font-bold mb-1">
                                Phrase
                            </div>
                            <div style={{height: "55px", backgroundColor: '#fcfcfc'}}
                                 className="border rounded px-2 h-full flex justify-center items-center">
                                <Switch
                                    onColor="#1AD5BD"
                                    checked={this.state.phrase}
                                    disabled={!this.state.exact && !this.state.broad}
                                    onChange={(checked) => {
                                        this.setState({
                                            phrase: checked
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mr-2">
                            <div className="text-xs font-bold mb-1">
                                Broad
                            </div>
                            <div style={{height: "55px", backgroundColor: '#fcfcfc'}}
                                 className="border rounded px-2 h-full flex justify-center items-center">
                                <Switch
                                    onColor="#1AD5BD"
                                    checked={this.state.broad}
                                    disabled={!this.state.exact && !this.state.phrase}
                                    onChange={(checked) => {
                                        this.setState({
                                            broad: checked
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-1">
                        <Input
                            noTitle={false}
                            title={"Keyword"}
                            type={'text'}
                            placeholder={'Add keyword text here'}
                            value={this.state.main}
                            size={'full'}
                            onChange={(event) => {
                                this.setState({main: event.target.value})
                            }}
                            showKeyDown={true}
                            onKeyDown={(value) => {
                                if (value === 13) {
                                    this.functions.main(this.state.main);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-row flex-1 mt-4 px-2 pl-0">
                    <div className="flex flex-1 overflow-hidden pr-10">
                        <AdvertisingKeywordsIdeas
                            ideas={this.state.ideas ? this.state.ideas : []}
                            website={this.state.website ? this.state.website : null}
                            client={this.state.client ? this.state.client : {}}
                            language={this.state.language ? this.state.language : {}}
                            updateIdeas={(value) => {
                                this.props.updateIdeas(value);
                            }}
                            addKeyword={(value) => {
                                this.functions.main(value.name);
                            }}
                        />
                    </div>
                    <div className="">
                        <button key={this.state.theInputKey || ''} className="relative px-3 py-2 text-white bg-google-500 rounded-full text-xs text-center font-bold flex flex-row justify-center items-center">
                            Upload keywords
                            <CSVReader
                                inputStyle={{
                                    opacity: 0,
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    width: '100%',
                                    backgroundColor: 'red'
                                }}
                                parserOptions={{
                                    header: true,
                                    dynamicTyping: true,
                                    skipEmptyLines: true,
                                    transformHeader: header =>
                                        header
                                            .toLowerCase()
                                            .replace(/\W/g, '_')
                                }}
                                onFileLoaded={(data, fileInfo) => {
                                    this.functions.upload(data);
                                }}/>
                        </button>
                    </div>
                    <div>
                        <button onClick={() => {
                            this.functions.main(this.state.main);
                        }} className="ml-2 px-3 py-2 text-white bg-google-500 rounded-full text-xs text-center font-bold flex flex-row justify-center items-center">
                            Add keyword
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    /*
    render() {
        return (
            <div className="w-full relative">
                <div className="">
                    <div className="">
                        <div className="flex flex-row items-start justify-between">
                            <div className="flex-1">
                                <div className="font-bold text-xs mb-1">Exact</div>
                                <div className="">
                                    <Switch
                                        onColor="#1AD5BD"
                                        checked={this.state.exact}
                                        disabled={!this.state.phrase && !this.state.broad}
                                        onChange={(checked) => {
                                            this.setState({
                                                exact: checked
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="font-bold text-xs mb-1">Phrase</div>
                                <div className="">
                                    <Switch
                                        onColor="#1AD5BD"
                                        checked={this.state.phrase}
                                        disabled={!this.state.exact && !this.state.broad}
                                        onChange={(checked) => {
                                            this.setState({
                                                phrase: checked
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="font-bold text-xs mb-1">Broad</div>
                                <div className="">
                                    <Switch
                                        onColor="#1AD5BD"
                                        checked={this.state.broad}
                                        disabled={!this.state.exact && !this.state.phrase}
                                        onChange={(checked) => {
                                            this.setState({
                                                broad: checked
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex-1">

                            </div>
                        </div>
                        <div className="mt-3">
                            <Input
                                noTitle={false}
                                title={"Add new keyword"}
                                type={'text'}
                                error={this.state.error}
                                placeholder={'Keyword ...'}
                                value={this.state.main}
                                size={'full'}
                                onChange={(event) => {
                                    this.setState({main: event.target.value})
                                }}
                                showKeyDown={true}
                                onKeyDown={(value) => {
                                    if (value === 13) {
                                        this.functions.main(this.state.main);
                                    }
                                }}
                            />
                        </div>
                        {this.state.main && this.state.main.length > 0 && false &&
                            <div>
                                <button onClick={() => {
                                    this.functions.main(this.state.main);
                                }} data-tip={"Add keyword"} className="mt-3 w-full py-3 px-2 text-white bg-google-500 rounded-full text-xs text-center font-bold flex flex-row justify-center items-center">
                                    <FeatherIcon className="stroke-current" size={16} icon="plus"/> Add keyword
                                    <ReactTooltip/>
                                </button>
                            </div>
                        }
                        <div className="mt-4 flex flex-1 justify-end">
                            <button onClick={() => {
                                this.functions.main(this.state.main);
                            }} className="relative bg-google-500 text-white hover:bg-google-100 hover:text-google-500 flex-row cursor-pointer text-xs px-4 py-2 flex justify-center items-center rounded-full font-bold duration-200 transition-all">
                                Add keyword
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }

     */
}

export default AdvertisingKeywordsPositiveAdd;
