import React, { Component } from 'react';
import InputTailwind from './inputTailwind';
import DropdownTailwind from './dropdownTailwind';
import SwitchTailwind from './switchTailwind';
import TextAreaTailwind from './textAreaTailwind';
import cn from 'classnames';
import moment from 'moment';
import UploadImage from '../components/image-upload';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTimepickerTailwind from './inputTimepickerTailwind';
import TagManager from './tagManager';

class ReportTabEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabs: [{ id: 0, name: "General" }],
            selected_tab: { id: 0, name: "General" },
            tab: {},
            channels: [
                { id: "adform", name: "adform", value: "adform" },
                { id: "google", name: "google", value: "google" },
                { id: "facebook", name: "facebook", value: "facebook" },
                { id: "bing", name: "bing", value: "bing" },
                { id: "linkedin", name: "linkedin", value: "linkedin" },
                { id: "snapchat", name: "snapchat", value: "snapchat" },
                { id: "tiktok", name: "tiktok", value: "tiktok" },
                { id: "dv360", name: "dv360", value: "dv360" },
                { id: "google_analytics_4", name: "Google Analytics 4", value: "google_analytics_4" },
                { id: "bidtheatre", name: "bidtheatre", value: "bidtheatre" },
                { id: "twitter", name: "twitter", value: "twitter" }
            ],
            subtab: { name: "Tags", id: "tags" }
        }
    }

    async componentDidMount() {
        await this.promisedSetState({
            tab: JSON.parse(JSON.stringify(this.props.tab)),
        })
    }

    async componentWillReceiveProps(nextProps) {
        await this.promisedSetState({
            tab: JSON.parse(JSON.stringify(nextProps.tab)),
        })
    }

    functions = {
        update: async () => {
            this.props.onSave(this.state.tab)
        }
    }

    calls = {}

    renders = {}

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className='flex flex-col px-6'>

                <div className='grid grid-cols-3'>

                    <div className={"col-span-3 mb-4"}>
                        <InputTailwind
                            value={this.state.tab.title}
                            locked={this.props.masterTemplate && !this.props.template && !this.state.tab.mastertemplate_overwrite}
                            disabled={this.props.masterTemplate && !this.props.template && !this.state.tab.mastertemplate_overwrite}
                            label='Tab name'
                            placeholder='name'
                            onChange={(value) => {
                                this.state.tab.title = value;
                                this.promisedSetState({
                                    tab: this.state.tab
                                })
                            }}
                        />
                    </div>

                    {
                        this.props.masterTemplate && this.props.template &&
                        <div className="col-span-3">
                            <div className="block overflow-scroll w-ful">
                                <nav className="flex space-x-4" aria-label="Tabs">
                                    {[{ name: "Tags", id: "tags" }, { name: "Channels", id: "channels" }].map((tab) => (
                                        <div
                                            onClick={() => (
                                                this.setState({
                                                    subtab: tab
                                                })
                                            )}
                                            key={tab.name}
                                            style={{ whiteSpace: "nowrap" }}
                                            className={cn(
                                                tab.id == this.state.subtab.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                            )}
                                            aria-current={tab.id == this.state.subtab.id ? 'page' : undefined}
                                        >
                                            {tab.name}
                                        </div>
                                    ))}
                                </nav>
                            </div>
                        </div>
                    }

                    {
                        this.props.masterTemplate &&
                        !this.props.template &&
                        <div className={`col-span-3 flex text-sm items-center border-b justify-center h-14`}>
                            <div className='text-sm font-medium'>Overwrite scale template</div>
                            <div className="flex flex-1"></div>
                            <div className="relative overflow-hidden">
                                <SwitchTailwind
                                    value={this.state.tab.mastertemplate_overwrite}
                                    onSwitch={async () => {
                                        this.state.tab.mastertemplate_overwrite = !this.state.tab.mastertemplate_overwrite;
                                        this.setState({
                                            tab: this.state.tab
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    }

                    {
                        this.props.masterTemplate &&
                        <div className={`col-span-3 relative flex text-sm items-center justify-center border-b h-14`}>
                            {
                                !this.props.template &&
                                !this.state.tab.mastertemplate_overwrite &&
                                <div className="bg-white bg-opacity-75 absolute left-0 right-0 top-0 bottom-0"></div>
                            }
                            <div className='text-sm font-medium'>Only show Active data</div>
                            <div className="flex flex-1"></div>
                            <div className="relative overflow-hidden">
                                <SwitchTailwind
                                    value={this.state.tab.only_active_data}
                                    onSwitch={async () => {
                                        this.state.tab.only_active_data = !this.state.tab.only_active_data;
                                        this.setState({
                                            tab: this.state.tab
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    }

                    {
                        this.props.masterTemplate && this.props.template &&
                        this.state.subtab.id == "tags" &&
                        <div className="text-blue-500 col-span-3 bg-blue-100 p-4 mt-4 rounded-md text-sm font-medium">
                            Auto enable tabs based on tags connected to orders (first priority).
                        </div>
                    }
                    {
                        this.props.masterTemplate && this.props.template &&
                        this.state.subtab.id == "channels" &&
                        <div className="text-blue-500 col-span-3 bg-blue-100 p-4 mt-4 rounded-md text-sm font-medium">
                            Auto enable tabs based on channels connected to orders (second priority).
                        </div>
                    }
                    {
                        this.props.masterTemplate && this.props.template &&
                        this.state.subtab.id == "tags" &&
                        <div className="col-span-3 mt-4">
                            <TagManager
                                report={this.props.report ? this.props.report : {}}
                                label={"Tags"}
                                tags={Array.isArray(this.state.tab.tags) ? this.state.tab.tags : []}
                                tagType={"report"}
                                onUpdateTags={(tags) => {
                                    this.state.tab.tags = tags;
                                    this.setState({
                                        tab: this.state.tab
                                    });
                                }}
                            />
                        </div>
                    }
                    {
                        this.props.masterTemplate && this.props.template &&
                        this.state.subtab.id == "channels" &&
                        this.state.channels.map((item) => {
                            return (
                                <div className={`col-span-3 flex text-sm items-center justify-center border-b h-14`}>
                                    <div className='text-sm font-medium'>{item.name}</div>
                                    <div className="flex flex-1"></div>
                                    <div className="relative overflow-hidden">
                                        <SwitchTailwind
                                            value={this.state.tab.channels && this.state.tab.channels[item.value]}
                                            onSwitch={async () => {
                                                if (!this.state.tab.channels) {
                                                    this.state.tab.channels = {};
                                                }
                                                if (this.state.tab.channels && this.state.tab.channels[item.value]) {
                                                    delete this.state.tab.channels[item.value];
                                                } else {
                                                    this.state.tab.channels[item.value] = true;
                                                }
                                                this.setState({
                                                    tab: this.state.tab
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>

            </div>
        );
    }
}

export default ReportTabEdit;
