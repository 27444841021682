import React, {Component} from 'react';
import './style.css';
import cx from "classnames";
import FeatherIcon from "feather-icons-react";
import {dataRegister} from "../../services/dataRegister";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {BeatLoader} from "react-spinners";

class AdvertisingCreativesGoogleSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creative: this.props.creative,
            client: this.props.client,
            url: this.props.url,
            title: this.props.title,
            body: this.props.body,
            body_extended: this.props.extended
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            creative: nextProps.creative,
            client: nextProps.client,
            title: nextProps.title,
            url: nextProps.url,
            body: nextProps.body,
            body_extended: nextProps.extended
        });
    }

    componentDidMount() {
        this.setState({
            creative: this.props.creative,
            client: this.props.client,
            url: this.props.url,
            title: this.props.title,
            body: this.props.body,
            body_extended: this.props.extended
        });
    }

    functions = {};

    init = {};

    renders = {
        variations: () => {
            try {
                if(this.state.creative.preview.value === 'basic'){
                    return this.state.title
                }else if(this.state.creative.preview.value === 'company'){
                    return this.state.client.name + ' | ' + this.state.title
                }else if(this.state.creative.preview.value === 'custom'){
                    let string = "";
                    try {
                        if(this.state.creative.custom_header_one.length > 0){
                            string = string + this.state.creative.custom_header_one
                        }
                    }catch (e) {}
                    try {
                        if(this.state.creative.custom_header_two.length > 0){
                            string = string + ' | ' + this.state.creative.custom_header_two
                        }
                    }catch (e) {}
                    try {
                        if(this.state.creative.custom_header_three.length > 0) {
                            string = string + ' | ' + this.state.creative.custom_header_three
                        }
                    }catch (e) {}
                    return string;
                }
            }catch (e) {}
        }
    };

    render() {
        return (
            <div className={cx("Google-PreviewContainer")}>
                <div className="Google-PreviewBasicContainerItem">
                    <div className="Google-PreviewBasicContainerItemInner">
                        <div className="Google-PreviewBasicContainerItemInnerTop">
                            <span className="Google-PreviewBasicContainerItemInnerTopAd">
                                Ad
                            </span>
                            <span className="Google-PreviewBasicContainerItemInnerTopUrl">
                                {this.state.url}
                            </span>
                        </div>
                        <div className="Google-PreviewBasicContainerItemInnerTitle">
                            {this.renders.variations()}
                        </div>
                        <div className="Google-PreviewBasicContainerItemInnerBody">
                            {this.state.body}{" "}{this.state.body_extended}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingCreativesGoogleSearch;
