import { PDFDocument } from 'pdf-lib';
import html2canvas from 'html2canvas';
import ReactDOM from 'react-dom';

const exportPdf = async (getReportHtml) => {
    let tempDiv;
    try {
        tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.width = '1366px';
        tempDiv.style.top = '-9999px';
        document.body.appendChild(tempDiv);

        ReactDOM.render(getReportHtml(), tempDiv);

        const pdfDoc = await PDFDocument.create();

        for (let node of tempDiv.childNodes) {
            const imgs = node.querySelectorAll('img');
            const imagePromises = [];

            imgs.forEach(img => {
                if (img.complete) {
                    processImage(img);
                } else {
                    const imgPromise = new Promise((resolve, reject) => {
                        img.onload = () => {
                            processImage(img);
                            resolve();
                        };
                        img.onerror = () => {
                            console.error('Error loading image', img.src);
                            reject(new Error('Error loading image'));
                        };
                    });
                    imagePromises.push(imgPromise);
                }
            });
            await Promise.all(imagePromises);
            const canvas = await html2canvas(node, { useCORS: true, logging: false });
            const imgData = canvas.toDataURL('image/png');
            const page = pdfDoc.addPage([canvas.width, canvas.height]);
            const pngImage = await pdfDoc.embedPng(imgData);
            page.drawImage(pngImage, { x: 0, y: 0, width: canvas.width, height: canvas.height });
        }

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'report.pdf';
        link.click();
    } catch (error) {
        console.error('exportPdf:', error);
    } finally {
        if (tempDiv) {
            document.body.removeChild(tempDiv);
        }
    }
};

function processImage(img) {
    const parent = img.parentNode;
    const scaleFactor = parent.clientHeight / img.naturalHeight;

    img.style.width = `${img.naturalWidth * scaleFactor}px`;
    img.style.height = `${parent.clientHeight}px`;
    parent.style.display = 'flex';
    parent.style.alignItems = 'center';
    parent.style.justifyContent = 'center';
}

export default exportPdf;
