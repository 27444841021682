import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import cx from 'classnames';
import { BeatLoader } from 'react-spinners';
import moment from 'moment';

import { Bar, Doughnut, Line } from "react-chartjs-2";

import { dataRegister } from '../../services/dataRegister';
import Campaign from '../../components/campaign';
import { calls } from './calls';
import { userRegister } from '../../services/userRegister';
import DashboardList from '../../components/dashboard-list';
import DashboardCards from '../../modules/dashboardcards';

import './style.css';
import { SlideDown } from "react-slidedown";
const queryString = require('query-string');

class UserDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: {},
            loading_stats: true,
            dashboardCards: [],
            loading_testDATA: true,
            testDATA: [],

            upcoming_campaigns: [],
            upcoming_loading: true,
            upcoming_loading_more: false,
            upcoming_page: 0,
            upcoming_page_size: 100,
            upcoming_total: 0,
            upcoming_order: 'desc',
            upcoming_sort: 'startDate',
            upcoming_client: {},

            active_campaigns: [],
            active_loading: true,
            active_loading_more: false,
            active_page: 0,
            active_page_size: 10,
            active_total: 0,
            active_order: 'desc',
            active_sort: 'startDate',
            active_client: {},
        };
    }

    componentWillMount() {
        this.init.stats();
        let query = window.location.search;

        if (query) {
            query = queryString.parse(query);
        }
        this.setState(
            {
                order: query.order ? query.order : 'desc',
                sort: query.sort ? query.sort : 'startDate',
            },
            () => {
                this.functions.upcoming_campaigns();
                this.functions.active_campaigns();
            }
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    init = {
        stats: () => {
            calls.getCampaignsStats().then(
                (response) => {
                    this.setState({
                        loading_stats: false,
                        stats: response.data,
                    });
                    this.init.dashCardsData(response.data);
                },
                (error) => { }
            );
        },
        dashCardsData: (resp) => {
            let dashCards = [
                {
                    title: 'Active campaigns',
                    icon: 'active-campaign.svg',
                    stat: resp.active,
                },
                {
                    title: 'Paused campaigns',
                    icon: 'paused-campaign.svg',
                    stat: resp.paused,
                },
                {
                    title: 'Upcoming campaigns',
                    icon: 'completed-campaign.svg',
                    stat: resp.upcoming,
                },
                {
                    title: 'Urgent errors',
                    icon: 'urgent-errors.svg',
                    stat: resp.error,
                },
            ];
            this.setState({
                dashboardCards: dashCards,
            });
        },
    };

    functions = {
        upcoming_campaigns: () => {
            calls.getUpcomingCampaigns(this.state.upcoming_page, this.state.upcoming_sort, this.state.upcoming_order, this.state.upcoming_page_size).then(
                (response) => {
                    let campaigns = this.state.upcoming_campaigns.concat(response.data);
                    this.setState({
                        upcoming_campaigns: campaigns,
                        upcoming_total: response.metadata.count,
                        upcoming_page: response.metadata.page,
                        upcoming_loading: false,
                        upcoming_loading_more: false,
                    });
                },
                (error) => {
                    this.setState({
                        upcoming_campaigns: this.state.campaigns,
                        upcoming_loading: false,
                        upcoming_loading_more: false,
                    });
                }
            );
        },
        active_campaigns: () => {
            calls.getActiveCampaigns(this.state.active_page, this.state.active_sort, this.state.active_order, this.state.active_page_size).then(
                (response) => {
                    let campaigns = this.state.active_campaigns.concat(response.data);
                    this.setState({
                        active_campaigns: campaigns,
                        active_total: response.metadata.count,
                        active_page: response.metadata.page,
                        active_loading: false,
                        active_loading_more: false,
                    });
                },
                (error) => {
                    this.setState({
                        active_campaigns: this.state.active_campaigns,
                        active_loading: false,
                        active_loading_more: false,
                    });
                }
            );
        },
        active_campaign: (item) => {
            for (let i = 0; i < this.state.active_campaigns.length; i++) {
                if (item.id === this.state.active_campaigns[i].id) {
                    this.state.active_campaigns[i].loading = true;
                }
            }
            this.setState({
                active_campaigns: this.state.active_campaigns,
            });
        },
    };

    renders = {
        markdown: (input) => {
            return { __html: input };
        },
    };

    render() {
        return (
            <div className="w-full pt-8">
                {this.state.upcoming_loading && this.state.active_loading ? (
                    <div className="background-Loader">
                        <BeatLoader sizeUnit={'px'} size={24} color={'#060534'} loading={true} />
                    </div>
                ) : (
                    <div className="dashboard--wrapper w-full mb-10 h-full flex flex-col">
                        <div>
                            <DashboardCards cards={this.state.dashboardCards} />
                            <div className="grid grid-cols-6 gap-4 mt-6">

                                <div className="col-span-6 md:col-span-3">
                                    <div className="flex h-full flex-col">
                                        <div className="mb-3 flex justify-between items-center">
                                            <div className="text-medium font-medium text-gray-900">Upcoming campaigns</div>
                                            {!this.state.upcoming_loading && (
                                                <Link to="/user/campaigns/upcoming">
                                                    <span className="font-semibold text-sm text-gray-900 hover:text-purple-500">See more &#187;</span>
                                                </Link>
                                            )}
                                        </div>
                                        {!this.state.upcoming_loading && this.state.upcoming_campaigns.length > 0 ? (
                                            <div className="flex flex-col">
                                                {this.state.upcoming_campaigns.map((u_campaign) => (
                                                    <ListBlock
                                                        id={u_campaign.id}
                                                        created={u_campaign.created}
                                                        name={u_campaign.client}
                                                        campaign={u_campaign.name}
                                                        channels={u_campaign.channels}
                                                        key={u_campaign.id}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="flex h-24 md:flex-1 justify-center items-center bg-white text-sm rounded-md border-1.5">
                                                <div className="">
                                                    You dont have any <span className="font-bold text-purple-500">Upcoming</span> campaigns
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-span-6 md:col-span-3 pb-2">
                                    <div className="flex h-full flex-col">
                                        <div className="mb-3 flex justify-between items-center">
                                            <div className="text-medium font-medium text-gray-900">Active campaigns</div>
                                            {!this.state.active_loading && (
                                                <Link to="/user/campaigns/active">
                                                    <span className="font-semibold text-sm text-gray-900 hover:text-purple-500">See more &#187;</span>
                                                </Link>
                                            )}
                                        </div>
                                        {!this.state.active_loading && this.state.active_campaigns.length > 0 ? (
                                            <div className="flex flex-col">
                                                {this.state.active_campaigns.map((a_campaign) => (
                                                    <ListBlock
                                                        id={a_campaign.id}
                                                        created={a_campaign.created}
                                                        name={a_campaign.client}
                                                        campaign={a_campaign.name}
                                                        channels={a_campaign.channels}
                                                        key={a_campaign.id}
                                                    />
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="flex h-24 md:flex-1 justify-center items-center bg-white text-sm rounded-md border-1.5">
                                                <div className="">
                                                    You dont have any <span className="font-bold text-purple-500">Active</span> campaigns
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="flex flex-1 flex-col mt-4">
                            <div className="text-medium font-medium text-gray-900 mb-3 ">Campaigns live the next 30 days</div>
                            <LineChart />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default UserDashboard;

class LineChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            data: {},
            loading: true,
            line: {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            suggestedMin: 10,
                            beginAtZero: true,
                            precision: 0,
                            max: 5
                        }
                    }]
                }
            },
        };
    };

    componentDidMount() {
        this.functions.campaigns();
    }

    functions = {
        campaigns: () => {
            this.setState({
                loading: true
            }, () => {
                calls.getCampaignChart().then((response) => {
                    this.setState({
                        items: response.data,
                        loading: false
                    })
                }, (error) => {
                    this.setState({
                        items: [],
                        loading: false
                    })
                });
            })
        }
    };

    renders = {
        data: () => {
            let highest_amount_pr_day = 0;
            let data = {
                labels: [],
                datasets: []
            };
            let dataset = {
                label: "Live campaigns",
                data: [],
                backgroundColor: ["rgba(69,63,241, 0.5)"],
                borderColor: ["#453FF1"],
                borderWidth: 1
            };
            let today = moment();
            let today_plus_a_month = moment().add(1, 'months');
            let days = moment(today_plus_a_month).diff(today, 'days');
            for (let i = 0; i < days; i++) {
                let amount_of_campaigns = 0;
                let today_plus_index = moment().add(i, 'days');
                data.labels.push(moment(today_plus_index).format("DD/MM"));
                this.state.items.map((item) => {
                    if (moment(today_plus_index).isBetween(item.startDate, item.endDate)) {
                        amount_of_campaigns = amount_of_campaigns + 1;
                    }
                });

                if (highest_amount_pr_day < amount_of_campaigns) {
                    highest_amount_pr_day = amount_of_campaigns;
                }

                dataset.data.push(amount_of_campaigns);
            }

            if (this.state.line.scales.yAxes[0].ticks.max < highest_amount_pr_day) {
                this.state.line.scales.yAxes[0].ticks.max = highest_amount_pr_day + 5;
                this.setState({
                    line: this.state.line
                });
            }

            data.datasets.push(dataset);
            return data;
        }
    };

    render() {
        return (
            <div style={{ height: "400px" }} className="bg-white rounded-md border-1.5 p-4 relative">
                {
                    !this.state.loading &&
                    <Line
                        options={this.state.line}
                        data={this.renders.data()}
                    />
                }
            </div>
        )
    }

}

const ListBlock = (props) => {
    const { id, created, name, campaign, channels } = props;
    return (
        <div className="w-full bg-white border-1.5 rounded-md mb-2 duration-200">
            <Link to={`/user/campaigns/${id}`}>
                <div className="px-2 py-4">
                    <div className="flex justify-between items-center">
                        <div className="flex">
                            <div className="flex flex-col justify-center mx-3 text-center">
                                <div>
                                    <div className="font-bold text-xl leading-4">{moment(created).format('DD')}</div>
                                    <div className="text-gray-500 font-bold text-xs leading-3 uppercase">{moment(created).format('MMMM').slice(0, 3)}</div>
                                </div>
                            </div>
                            <div className="flex flex-col justify-center mx-3 text-left leading-4">
                                <div className="font-bold text-base leading-4">{name}</div>
                                <div className="text-xs">{campaign}</div>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <BrandsIcons channels={channels} />
                            <FeatherIcon id="svg--more-vertical" color={'#DBD9E5'} size={20} icon="more-vertical" />
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

const BrandsIcons = (props) => {
    const { channels } = props;

    return (
        <div className="flex mx-3">
            <div className="flex flex-row">
                {channels && channels.facebook && (
                    <div className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                        <div
                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                            style={{ backgroundImage: 'url(' + require('../../assets/images/facebook_icon.svg') + ')' }}></div>
                    </div>
                )}
                {channels && channels.instagram && (
                    <div className={'w-8 h-8 bg-instagram-500 rounded-full p-2 border-2 border-white ' + (channels.facebook ? '-ml-2' : '')}>
                        <div
                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                            style={{ backgroundImage: 'url(' + require('../../assets/images/instagram_icon.svg') + ')' }}></div>
                    </div>
                )}
                {channels && channels.google && (
                    <div className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white ' + (channels.facebook || channels.instagram ? '-ml-2' : '')}>
                        <div
                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                            style={{ backgroundImage: 'url(' + require('../../assets/images/google_icon.svg') + ')' }}></div>
                    </div>
                )}
            </div>
        </div>
    );
};
