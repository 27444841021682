import React, { Component, Fragment } from 'react';
import Pusher from 'pusher-js';
import { SparklesIcon, TrashIcon, PhotographIcon, FilmIcon, ChevronUpIcon, SwitchVerticalIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import TextAreaTailwind from './textAreaTailwind';
import FacebookCTA from '../assets/json/facebook_cta.json';
import { adFacebook } from '../validators/adFacebook';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import WarningModalTailwind from './warningModalTailwind';
import { update } from 'immutable';

var pusherService = null;

class EditFacebookAd extends Component {

	constructor(props) {
		super(props);
		this.state = {
			open: false,
			instagram_post: false,
			ad: {},
			updated: {},
			client: {},
			apps: [],
			pixels: [],
			loader: false,
			wizard: false,
			channel: { id: 1, name: "facebook feed" },
			pages: [],
			selectedAsset: 0,
			instagram_accounts: [],
			call_to_actions: FacebookCTA.filter((item) => { return item.objective.length > 0 }),
			wizardId: 0,
			collection_catalogs: [],
			product_sets: [],
			loading_fb_catalogs: false,
			load_fb_product_sets: false,
			lead_forms: [],
			instant_type: { id: 0, name: "Click here ..." },
			instant_types: [
				{ id: 1932289657009030, name: "Store Front", value: "store_front" },
				{ id: 1369752616394017, name: "Look Book", value: "look_book" },
				{ id: 133471657203838, name: "Customer Acquisition", value: "customer_acquisition" }
			],
			carousel_menu: "Main ad",
			preview_data: false,
			open_slide: 0,
			instantExperience: { id: 0, name: "Click here ..." },
			instant_options: [],
			fb_lead_form: false,
			selected_preview: { id: 1, value: "feed", name: "Feed" },
		}
	};

	async componentDidMount() {

		pusherService = new Pusher('a07698cf59a1788c44d4', {
			cluster: 'eu'
		});

		await this.promisedSetState({
			ad: this.props.ad,
			client: this.props.client,
			wizard: this.props.wizard,
			assetItems: this.props.assetItems,
			wizardId: this.props.wizardId,
			preview_data: this.props.preview_data
		})

		if (this.props.updated) {
			await this.promisedSetState({
				updated: this.props.updated
			});
		}

		this.functions.instagram_post();
		this.functions.pixels();
		this.functions.listApps();
		this.functions.listPages();
		this.functions.getFacebookCatalogs();
		if (this.state.updated.campaign_objective && this.state.updated.campaign_objective.id) {
			await this.promisedSetState({
				call_to_actions: this.state.call_to_actions.filter((item) => { return item.objective.includes(this.state.updated.campaign_objective.id) })
			});
		}
		if (this.state.updated.collection_catalog && this.state.updated.collection_catalog.id !== 0) {
			this.functions.getFacebookProductSets();
		}
		if (this.state.wizard && this.state.updated.page && this.state.updated.page.id !== 0) {
			this.functions.listInstagramAccounts(this.state.updated.page.id);
		}

		if (!this.state.wizard && this.renders.type(this.state.ad) == "video") {
			try {
				this.functions.getVideo(this.state.ad.creative.object_story_spec.video_data.video_id);
			} catch (error) { }
		}
		if (this.state.updated.page && this.state.updated.page.id !== 0 && this.state.updated.file && this.state.updated.file.selected_lead && this.state.updated.file.selected_lead.id !== 0) {
			this.state.updated.selected_lead = this.state.updated.file.selected_lead;
			await this.promisedSetState({
				fb_lead_form: true, updated: this.state.updated
			})
			this.functions.listLeadForms();
		}
		if (this.state.updated.page && this.state.updated.page.id !== 0 && this.state.updated.selected_lead && this.state.updated.selected_lead.id !== 0) {
			this.functions.listLeadForms();
		}
		if (this.state.updated.instantExperience && this.state.updated.instantExperience.id !== 0) {
			this.functions.getInstant();
		}
		this.functions.init();

		if (this.state.updated) {
			await this.promisedSetState({
				carousel: this.state.updated && this.state.updated.type === "carousel" ? this.state.updated : null,
				merge: this.state.updated && this.state.updated.type === "merge" ? this.state.updated : null,
			});
		}

		if (this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.length > 0) {
			this.props.onUpdatedChat(this.state.updated.OpenAiResult);
		}

		this.functions.updatePreview();
		this.functions.listInstantExpriences()
	}

	async componentWillReceiveProps(nextProps) {
		await this.promisedSetState({
			ad: nextProps.ad,
			client: nextProps.client,
			wizard: nextProps.wizard,
			assetItems: nextProps.assetItems,
			wizardId: nextProps.wizardId,
			preview_data: nextProps.preview_data
		})

		if (nextProps.updated) {
			this.setState({
				updated: nextProps.updated
			});
		}
		this.renderSortableList(this.state.updated.slides ? this.state.updated.slides : []);
	}

	functions = {
		init: () => {
			window.fbAsyncInit = function () {
				window.FB.init({
					appId: '3033879003330818',
					version: 'v16.0',
					status: true,
					autoLogAppEvents: true,
					xfbml: true,
				});
				window.FB.AppEvents.logPageView();
			}.bind(this);
			(function (d, s, id) {
				var js, fjs = d.getElementsByTagName(s)[0];
				if (d.getElementById(id)) {
					return;
				}
				js = d.createElement(s);
				js.id = id;
				js.src = "https://connect.facebook.net/en_US/sdk.js";
				fjs.parentNode.insertBefore(js, fjs);
			}(document, 'script', 'facebook-jssdk'));
		},
		getFacebookCatalogs: async () => {
			await this.promisedSetState({ loading_fb_catalogs: true });
			try {
				let response = await this.calls.facebookCatalogs();
				this.state.collection_catalogs = response.data;
				await this.promisedSetState({ collection_catalogs: this.state.collection_catalogs });
			} catch (error) { }
			await this.promisedSetState({ loading_fb_catalogs: false });
		},
		getFacebookProductSets: async () => {
			await this.promisedSetState({ loading_fb_productsets: true });
			try {
				let response = await this.calls.facebookProductSets(this.state.updated.collection_catalog.id);
				this.state.product_sets = response.data;
				await this.promisedSetState({ product_sets: this.state.product_sets });
			} catch (error) { }
			await this.promisedSetState({ loading_fb_productsets: false });
		},
		getFacebookProducts: async (product_set) => {
			try {
				let response = await this.calls.facebookProducts(product_set);
				this.state.updated.preview_products = response.data;
				await this.promisedSetState({ updated: this.state.updated });
			} catch (error) { }
		},
		onSelectFile: async (e, slide = false) => {
			let files = e.target.files;
			if (files) {
				for (let i in files) {
					if (files[i].type === 'video/mp4' || files[i].type === 'image/gif' || files[i].type === 'video/quicktime' || files[i].type === 'video/mov') {
						await this.functions.uploadVideo(files[i], slide);
					} else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
						await this.functions.uploadImage(files[i], slide);
					}
					const randomString = Math.random().toString(36);
					await this.promisedSetState({
						theInputKeyOne: randomString + 1
					});
				}
			}
			this.functions.updatePreview();
		},
		listLeadForms: async () => {
			await this.promisedSetState({ loading_leadforms: true });
			try {
				let response = await this.calls.listLeadForms(this.renders.page().id);
				this.state.lead_forms = Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, status: item.status } }) : [];
				this.promisedSetState({ lead_forms: this.state.lead_forms })
			} catch (error) {
			}
			await this.promisedSetState({ loading_leadforms: false });
		},
		instantExperience: () => {
			let self = this;
			//1932289657009030  STORE FRONT
			//1369752616394017 LOOK BOOK
			//133471657203838 CUSTUMER ACQUISITION

			if (self.renders.page() && self.renders.page().id !== 0 && self.state.instant_type.id !== 0) {
				window.FB.ui({
					account_id: this.state.client.channels.filter((item) => { return item.value === "facebook" })[0].facebookAdAccountId.replace("act_", ""),
					page_id: self.renders.page().id,
					business_id: this.state.client.channels.filter((item) => { return item.value === "facebook" })[0].facebookBusinessManagerId,
					display: 'popup',
					method: 'instant_experiences_builder',
					template_id: self.state.instant_type.id,
				}, function (response) {
					try {
						if (response.success) {
							self.functions.instantCreated(response);
						}
						self.setState({ loading_instant: false });
					} catch (e) {
						self.setState({ loading_instant: false });
					}
				})
			}
		},
		instantCreated: async (response) => {
			try {
				this.state.updated.instantExperience = { id: response.id, name: response.id };
				await this.promisedSetState({
					updated: this.state.updated
				});
				this.functions.getInstant();
			} catch (error) {
				console.log(error)
			}
		},
		getInstant: async () => {
			try {
				await this.promisedSetState({ loading_instant: true });
				let response = await this.calls.getInstant();
				if (response.data && ((response.data.photo || response.data.video) && (response.data.carousel || response.data.product_set))) {
					this.state.updated.instantExperience.elements = response.data;
					await this.promisedSetState({
						updated: this.state.updated
					});
					this.functions.updatePreview();
				}
				await this.promisedSetState({ loading_instant: false });
			} catch (error) {
				console.log(error)
			}
		},
		uploadImage: async (file, slide) => {
			return new Promise(async (resolve) => {
				let files_object = {
					id: Math.floor((Math.random() * 9999999999) + 1),
					loading: true,
					name: file.name,
					name_by_channels: {
						facebook: file.name,
						linkedin: file.name,
						tiktok: file.name,
						twitter: file.name,
						snapchat: file.name
					},
					type: 'image'
				};

				files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, snapchat: true };
				files_object.allowed_types = { regular: true };
				files_object.allowed_multiple = true;

				this.state.assetItems.push(files_object);
				//this.state.updated.file = { ...this.state.updated.file,  };
				await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });
				try {
					const formData = new FormData();
					formData.append('file', file);
					let response = await this.calls.image(formData);
					this.state.assetItems = this.state.assetItems.map((item) => {
						if (item.id === files_object.id) {
							item.loading = false;
							item = { ...item, ...response.data };
						}
						return item;
					});
					await this.promisedSetState({ assetItems: this.state.assetItems });
					await this.props.updateAssets(this.state.assetItems);
					if (!slide) {
						this.state.updated.file = { ...this.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
						this.state.updated.picture = response.data.url;
					} else {
						if (!this.state.updated_slides) {
							this.state.updated_slides = JSON.parse(JSON.stringify(this.state.updated.slides)).map((item) => {
								return {}
							});
						}
						this.state.updated.slides = this.state.updated.slides.map((item, index) => {
							if (item.id === slide.id) {
								item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
								item.image = true;
								item.video = false;
								item.picture = response.data.url;
								item.url = response.data.url;
								this.state.updated_slides[index] = item;
								this.state.updated_slides[index].image = response.data.url;
							}
							return item;
						});
						await this.promisedSetState({ updated_slides: this.state.updated_slides });

					}
					await this.promisedSetState({ updated: this.state.updated, selectedAsset: response.data.id, loader: false });

				} catch (error) {
					this.state.assetItems = this.state.assetItems.map((item) => {
						if (item.id === files_object.id) {
							item.loading = false;
							item.error = true;
						}
						return item;
					});
					await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
				}
				resolve()
			});
		},
		uploadVideo: async (file, slide) => {

			let files_object = {
				id: Math.floor((Math.random() * 9999999999) + 1),
				loading: true,
				name: file.name,
				name_by_channels: {
					facebook: file.name,
					linkedin: file.name,
					tiktok: file.name,
					twitter: file.name,
					snapchat: file.name
				},
				type: 'video'
			};

			files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, tiktok: true, snapchat: true };
			files_object.allowed_types = { regular: true };
			files_object.allowed_multiple = true;

			this.state.assetItems.push(files_object);
			//this.state.updated.file = { ...this.state.updated.file, ...files_object };
			await this.promisedSetState({ assetItems: this.state.assetItems, loader: true });

			let channel_id = Math.floor((Math.random() * 9999999999) + 1) + "_videoUpload";
			let self = this;
			var channel = pusherService.subscribe(channel_id);
			channel.bind('videoUpload', async function (response) {
				if (response && response.status === 200) {
					self.state.assetItems = self.state.assetItems.map((item) => {
						if (item.id === files_object.id) {
							item.loading = false;
							item = { ...item, ...response.data };
						}
						return item;
					});
					await self.promisedSetState({ assetItems: self.state.assetItems });
					await self.props.updateAssets(self.state.assetItems);
					if (!slide) {
						self.state.updated.file = { ...self.state.updated.file, ...files_object, ...response.data, ...{ assetId: response.data.id } };
						self.state.updated.video = response.data.url;
					} else {
						if (!self.state.updated_slides) {
							self.state.updated_slides = JSON.parse(JSON.stringify(self.state.updated.slides)).map((item) => {
								return {}
							});
						}
						self.state.updated.slides = self.state.updated.slides.map((item, index) => {
							if (item.id === slide.id) {
								item = { ...item, ...files_object, ...response.data, ...{ assetId: response.data.id } };
								item.url = response.data.url;
								item.image = false;
								item.video = true;
								self.state.updated_slides[index] = item;
							}
							return item;
						});
						await self.promisedSetState({ updated_slides: self.state.updated_slides });
					}
					await self.promisedSetState({ updated: self.state.updated, selectedAsset: response.data.id, loader: false });
					self.functions.updatePreview();

				} else {
					self.state.assetItems = self.state.assetItems.map((item) => {
						if (item.id === files_object.id) {
							item.loading = false;
							item.error = true;
						}
						return item;
					});
					await self.promisedSetState({ assetItems: self.state.assetItems, loader: false });
				}
				try {
					pusherService.unsubscribe(channel_id);
				} catch (error) {
					console.log(error);
				}
			});

			try {
				const formData = new FormData();
				formData.append('file', file);
				await this.calls.video_upload(formData, channel_id);
			} catch (error) {
				this.state.assetItems = this.state.assetItems.map((item) => {
					if (item.id === files_object.id) {
						item.loading = false;
						item.error = true;
					}
					return item;
				});
				await this.promisedSetState({ assetItems: this.state.assetItems, loader: false });
				await this.props.updateAssets(this.state.assetItems);

			}
		},
		update: async (duplicate) => {
			if (!this.state.wizard) {
				let body = {
					creative: {
						object_story_spec: JSON.parse(JSON.stringify(this.state.ad.creative.object_story_spec)),
					},
					ad_name: !this.state.updated.name ? this.state.ad.name : this.state.updated.name,
					ad_id: this.state.ad.id,
					creative_id: this.state.ad.creative.id,
					adset_id: this.state.ad.adset_id,
					status: this.renders.status().value
				};
				if (this.renders.type(this.state.ad) == "image") {
					delete body.creative.object_story_spec.link_data.image_hash;
					if (this.state.updated.body && this.state.updated.body !== "") {
						body.creative.object_story_spec.link_data.message = this.state.updated.body;
					}
					if (this.state.updated.title && this.state.updated.title !== "") {
						body.creative.object_story_spec.link_data.name = this.state.updated.title;
					}
					if (this.state.updated.description && this.state.updated.description !== "") {
						body.creative.object_story_spec.link_data.description = this.state.updated.description;
					}
					if (this.state.updated.picture && this.state.updated.picture !== "") {
						body.creative.object_story_spec.link_data.picture = this.state.updated.picture;
					}
					if (this.state.updated.cta) {
						body.creative.object_story_spec.link_data.call_to_action = {
							type: this.state.updated.cta.type,
							value: {
								link: (this.state.updated.link && this.state.updated.link !== "") ? this.state.updated.link : body.creative.object_story_spec.link_data.link
							}
						};
					}
					if (this.state.updated.link && this.state.updated.link !== "") {
						body.creative.object_story_spec.link_data.link = this.state.updated.link;
						if (body.creative.object_story_spec.link_data.call_to_action) {
							body.creative.object_story_spec.link_data.call_to_action.value.link = this.state.updated.link;
						}
					}
					if (this.state.updated.caption && this.state.updated.caption !== "") {
						body.creative.object_story_spec.link_data.caption = this.state.updated.caption;
					}
				} else if (this.renders.type(this.state.ad) == "video") {
					delete body.creative.object_story_spec.video_data.image_hash;
					if (this.state.updated.body && this.state.updated.body !== "") {
						body.creative.object_story_spec.video_data.message = this.state.updated.body;
					}
					if (this.state.updated.title && this.state.updated.title !== "") {
						body.creative.object_story_spec.video_data.title = this.state.updated.title;
					}
					if (this.state.updated.description && this.state.updated.description !== "") {
						body.creative.object_story_spec.video_data.link_description = this.state.updated.description;
					}
					if (this.state.updated.cta) {
						body.creative.object_story_spec.video_data.call_to_action = {
							type: this.state.updated.cta.type,
							value: {
								link: body.creative.object_story_spec.video_data.call_to_action.value.link
							}
						};
					}
					if (this.state.updated.link && this.state.updated.link !== "") {
						if (body.creative.object_story_spec.video_data.call_to_action) {
							body.creative.object_story_spec.video_data.call_to_action.value.link = this.state.updated.link;
						}
					}
					if (this.state.updated.caption && this.state.updated.caption !== "") {
						body.creative.object_story_spec.video_data.call_to_action.value.link_caption = this.state.updated.caption;
					}
					if (this.state.updated.video && this.state.updated.video.channels && this.state.updated.video.channels.facebook) {
						body.creative.object_story_spec.video_data.image_url = this.state.updated.video.thumbnail;
						body.creative.object_story_spec.video_data.video_id = this.state.updated.video.channels.facebook.id;
					}
				} else if (this.renders.type(this.state.ad) == "carousel") {
					if (this.state.updated.body && this.state.updated.body !== "") {
						body.creative.object_story_spec.link_data.message = this.state.updated.body;
					}
					if (this.state.updated.link && this.state.updated.link !== "") {
						body.creative.object_story_spec.link_data.link = this.state.updated.link;
					}
					if (this.state.updated.caption && this.state.updated.caption !== "") {
						body.creative.object_story_spec.link_data.caption = this.state.updated.caption;
					}
					if (this.state.updated.last_card) {
						body.creative.object_story_spec.link_data.multi_share_end_card = this.state.updated.last_card.value;
					}
					if (this.state.updated.auto_cards) {
						body.creative.object_story_spec.link_data.multi_share_optimized = this.state.updated.auto_cards.value;
					}

					if (Array.isArray(this.state.updated.slides)) {
						body.creative.object_story_spec.link_data.child_attachments = this.state.updated.slides.map((item) => {
							let slide = {};
							slide.description = item.body;
							slide.name = item.headline;
							slide.link = item.link;
							slide.call_to_action = { type: item.cta.type, value: { link: item.link } };
							slide.video_id = item.video_id;
							if (item.video_data && item.video_data.channels && item.video_data.channels.facebook) {
								slide.video_id = item.video_data.channels.facebook.id;
								slide.picture = item.video_data.thumbnail;
							} else {
								if (item.picture) {
									slide.picture = item.picture;
								} else {
									slide.image_hash = item.image_hash;
								}
							}
							return slide;
						});
					} else {
						body.creative.object_story_spec.link_data.child_attachments = body.creative.object_story_spec.link_data.child_attachments.map((item) => {
							if (item.picture && item.image_hash) {
								delete item.image_hash;
							}
							return item;
						});
					}
				}
				if (!duplicate) {
					this.props.onLoader(true);
					try {
						let response = await this.calls.update(body);
						let updated_ad = { ...this.state.ad, ...response.data };
						this.props.onSuccess("Ad updated");
						this.props.onUpdate(updated_ad);
					} catch (error) {
						this.props.onError(error.body ? error.body.message : "Something went wrong");
					}
					this.props.onLoader(false);
				} else {
					this.props.onLoaderSecondary(true);
					try {
						let response = await this.calls.duplicate(body);
						let added_ad = response.data;
						this.props.onSuccess("Ad created");
						this.props.onAdd(added_ad);
					} catch (error) {
						this.props.onError(error.body ? error.body.message : "Something went wrong");
					}
					this.props.onLoaderSecondary(false);
				}
			} else {
				this.props.onUpdate(this.state.updated);
			}
		},
		listPages: async () => {
			try {
				await this.promisedSetState({ loading_pages: true });
				let response = await this.calls.pages();
				await this.promisedSetState({ loading_pages: false, pages: Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.picture.data.url } }) : [] })
				if (!this.state.wizard) {
					this.functions.listInstagramAccounts(this.renders.page(this.state.ad).id);
				}
			} catch (error) { }
		},
		listInstagramAccounts: async (page) => {
			try {
				await this.promisedSetState({ loading_instagram: true });
				let response = await this.calls.instagramAccounts(page);
				this.promisedSetState({ loading_instagram: false, instagram_accounts: Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.profile_pic, profile_pic: item.profile_pic } }) : [] })
			} catch (error) { }
		},
		listInstagramBusinessAccounts: async (page) => {
			try {
				await this.promisedSetState({ loading_instagram: true });
				let response = await this.calls.instagramBusinessAccounts(page);
				this.promisedSetState({ loading_instagram: false, instagram_accounts: Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.profile_pic, profile_pic: item.profile_pic } }) : [] })
			} catch (error) { }
		},
		getVideo: async (video) => {
			try {
				await this.promisedSetState({ loading_video: true });
				let response = await this.calls.video(video);
				this.promisedSetState({ loading_video: false, video: response.data.source })
			} catch (error) { }
		},
		instagram_post: async () => {
			if (this.state.updated.instagram_post) {
				await this.promisedSetState({ instagram_post: true });
			}
		},
		pixels: async () => {
			try {
				this.promisedSetState({ loading_pixels: true });
				let response = await this.calls.pixels();
				response.data.unshift({ id: 0, name: "No pixel", value: null });
				this.promisedSetState({ loading_pixels: false, pixels: response.data });
			} catch (error) {
				this.promisedSetState({
					pixels: []
				})
			}
		},
		listApps: async () => {
			try {
				this.promisedSetState({ loading_apps: true });
				let response = await this.calls.apps();
				let data = [];
				data = data.concat(response.data);
				this.promisedSetState({ loading_apps: false, apps: data });
			} catch (error) {
				this.promisedSetState({
					apps: []
				})
			}
		},
		updatePreview: async () => {
			let preview_data = {
				merge: false,
				carousel: false,
				catalog: false,
				files: [],
				headline_by_channels: { facebook: this.renders.title() },
				body_by_channels: { facebook: this.renders.body() },
				cta: { facebook: this.renders.cta(this.updated) },
				page: { facebook: this.renders.page(this.updated), instagram: this.renders.instagram(this.updated) },
				website_by_channels: { facebook: this.renders.link() },
				description: this.renders.description(),
				caption: this.renders.caption(),
				preview_channel: this.state.updated && this.state.updated.preview_channel ? this.state.updated.preview_channel : "facebook",
				previews: !this.state.merge ?
					{ facebook: [{ id: 1, value: "feed", name: "Feed" }, { id: 2, value: "story", name: "Story" }], instagram: [{ id: 1, value: "feed", name: "Feed" }, { id: 2, value: "story", name: "Story" }] }
					:
					{ facebook: [{ id: 1, value: "feed", name: "Feed (Square)", ratio: "1:1" }, { id: 2, value: "feed", name: "Feed (Horizontal)", ratio: "1.91:1" }, { id: 3, value: "story", name: "Story", ratio: "9:16" }], instagram: [{ id: 1, value: "feed", name: "Feed (Square)", ratio: "1:1" }, { id: 2, value: "feed", name: "Feed (Horizontal)", ratio: "1.91:1" }, { id: 3, value: "story", name: "Story", ratio: "9:16" }] },
				preview: this.state.preview_data ? this.state.preview_data.preview : !this.state.merge ? { id: 1, value: "feed", name: "Feed" } : { id: 1, value: "feed", name: "Feed (Square)", ratio: "1:1" },
				utm_channels: ["facebook"],
				channel_menu: "facebook",
				validation: {
					facebook: Object.keys(adFacebook.validate(this.state.updated)).filter((item) => {
						if (this.state.updated && this.state.updated.selected_lead && this.state.updated.selected_lead.id !== 0 && item === 'website') {
							return false;
						}
						return adFacebook.validate(this.state.updated)[item];
					}).filter((item) => { return item }).length > 0 ? true : false
				},
			};

			//carousel: this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "carousel_ad" ? this.state.carousel : false,
			// this.state.updated && this.state.updated.is_post ? [this.state.updated] : this.state.updated.files && this.state.updated.ad_type && this.state.updated.ad_type.value !== "carousel_ad" ? this.state.updated.files : (this.state.updated.ad_type.value === "carousel_ad" ? [this.state.carousel] : (this.state.updated.file ? [this.state.updated.file] : [])),

			if (this.state.updated) {
				if (this.state.updated.type === "posts") {
					if (this.state.updated.ad_type && this.state.updated.ad_type.value === "carousel_ad") {
						preview_data.carousel = this.state.updated;
					} else {
						preview_data.files = [this.state.updated];
					}
				} else {
					if (this.state.updated.ad_type.value !== "carousel_ad") {
						if (this.state.merge) {
							let buff = { ...this.state.updated, files: this.state.updated.slides };
							preview_data.files = [buff];
							preview_data.merge = buff;
							preview_data.merge.image = this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.type === "image" ? true : false
						}
						if (this.state.updated.ad_type && this.state.updated.ad_type.value === "catalog_ad") {
							let buff = { ...this.state.updated, files: this.state.updated.slides };
							this.state.catalog = true;
							if (this.state.updated.collection_ad) {
								preview_data.instantExperience = this.state.updated.instantExperience;
							}
							preview_data.files = [buff];
							preview_data.carousel = buff;
							if (preview_data.carousel) {
								preview_data.carousel.files = [buff];
							}
							preview_data.catalog = buff;
							await this.promisedSetState({ catalog: this.state.catalog });
						}
						if (this.state.updated.files) {
							preview_data.files = this.state.updated.files;
						} else if (this.state.updated.file) {
							preview_data.files = [this.state.updated.file];
						}
					} else {
						let carousel = JSON.parse(JSON.stringify(this.state.updated));
						carousel.files = carousel.slides;
						preview_data.carousel = carousel;
						preview_data.files = [this.state.updated];
					}
				}
			}

			try {
				this.props.updatePreview(preview_data, this.state.updated)
			} catch (error) {
				console.log(error)
			}
		},
		previewChannel: async (channel) => {
			try {
				this.state.updated.preview_channel = channel;
				await this.promisedSetState({
					updated: this.state.updated
				});
				this.functions.updatePreview();
			} catch (error) {
			}
		},
		updateOpenAi: async (data) => {
			try {
				this.state.updated.OpenAiResult = data;
				await this.promisedSetState({
					updated: this.state.updated
				})
			} catch (error) {
				console.log(error)
			}
		},
		addHeadlines: async (data) => {
			try {
				if (this.state.updated && !this.state.updated.is_post && !this.state.updated.catalog) {
					for (let i = 0; i < data.length; i++) {
						if (data[i].force) {
							if (this.state.merge) {
								this.state.updated.headlines[0] = { value: data[i].value[0] };
							} else if (!this.state.carousel) {
								this.state.updated.title = data[i].value[0];
							}
						} else {
							if (this.state.merge) {
								if (this.state.updated.headlines[0] && this.state.updated.headlines[0].value !== "") {
									this.state.updated.headlines[0].value = data[i].value[0];
								} else if (this.state.updated.headlines[0] && this.state.updated.headlines[0].value === "") {
									this.state.updated.headlines[0].value = data[i].value[0];
								} else if (!this.state.updated.headlines[0]) {
									this.state.updated.headlines[0] = { value: data[i].value[0] };
								}
							}
							if (!this.state.carousel) {
								if (this.renders.title() === "") {
									this.state.updated.title = data[i].value[0];
								}
							}
						}
					}
					await this.promisedSetState({
						updated: this.state.updated
					});
					this.functions.updatePreview();
				} else {
					return;
				}
			} catch (error) {
				console.log(error)
			}
		},
		addDescriptions: async (data) => {
			try {
				if (this.state.updated && !this.state.updated.is_post) {
					for (let i = 0; i < data.length; i++) {
						if (data[i].force) {
							if (this.state.merge) {
								this.state.updated.bodies[0] = { value: data[i].value[0] };
							} else {
								this.state.updated.body = data[i].value[0];
							}
						} else {
							if (this.state.merge) {
								if (this.state.updated.bodies[0] && this.state.updated.bodies[0].value !== "") {
									this.state.updated.bodies[0].value = data[i].value[0];
								} else if (this.state.updated.bodies[0] && this.state.updated.bodies[0].value === "") {
									this.state.updated.bodies[0].value = data[i].value[0];
								} else if (!this.state.updated.bodies[0]) {
									this.state.updated.bodies[0] = { value: data[i].value[0] };
								}
							} else {
								if (this.renders.body() === "") {
									this.state.updated.body = data[i].value[0];
								}
							}
						}
					}
					await this.promisedSetState({
						updated: this.state.updated
					});
					this.functions.updatePreview();
				} else {
					return;
				}
			} catch (error) {
				console.log(error)
			}
		},
		removeDescription: async () => {
			try {
				if (this.state.merge) {
					this.state.updated.bodies[0].value = "";
				}
				this.state.updated.body = "";
				await this.promisedSetState({
					updated: this.state.updated
				});
				this.functions.updatePreview();
			} catch (error) {
				console.log(error)
			}
		},
		removeHeadline: async () => {
			try {
				if (this.state.merge) {
					this.state.updated.headlines[0].value = "";
				}
				this.state.updated.title = "";
				await this.promisedSetState({
					updated: this.state.updated
				});
				this.functions.updatePreview();
			} catch (error) {
				console.log(error)
			}
		},
		deleteSlide: async (slide) => {
			try {
				if (this.state.updated && this.state.updated.files && Array.isArray(this.state.updated.files)) {
					this.state.updated.files = this.state.updated.files.filter((item) => {
						return slide.id !== item.id;
					});
				}
				this.state.updated.slides = this.state.updated.files;
				await this.promisedSetState({
					updated: this.state.updated, open_slide: 0, delete_modal: false
				});
				if (this.props.onChange) {
					this.props.onChange(this.state.updated);
				}
				this.functions.updatePreview();
			} catch (error) {
				console.log(error)
			}
		},
		listInstantExpriences: async () => {
			try {
				let response = await this.calls.listInstantExpriences();
				await this.promisedSetState({
					instant_options: response.data
				});
			} catch (error) {
			}
		},
		leadForm: () => {
			let self = this;
			/*
			account_id: this.state.client.channels.filter((item) => { return item.value === "facebook"})[0].facebookAdAccountId.replace("act_", ""),
					page_id: this.state.page.facebook.id,
					business_id: this.state.client.channels.filter((item) => { return item.value === "facebook"})[0].facebookBusinessManagerId,
					display: 'popup',
					method: 'instant_experiences_builder',
					template_id: "1932289657009030"
			*/
			if (self.state.fb_lead_form && self.state.updated.page.id && self.state.updated.page.id !== 0) {
				window.FB.ui({
					ad_account_id: self.state.client.channels.filter((item) => { return item.value === "facebook" })[0].facebookAdAccountId,
					page_id: self.state.updated.page.id,
					display: 'popup',
					method: 'lead_gen',
				}, function (response) {
					try {
						if (response.status === "success") {
							self.functions.leadCreated(response);
						}

						self.setState({ loading_new_lead: false });
					} catch (e) {
						self.setState({ loading_new_lead: false });
					}
				})
			}
		},
		leadCreated: async (response) => {
			try {
				this.state.updated.selected_lead = { id: response.formID, name: response.name.split("+").join(" "), status: "ACTIVE" };
				this.state.lead_forms.unshift({ id: response.formID, name: response.name.split("+").join(" "), status: "ACTIVE" });
				await this.promisedSetState({
					updated: this.state.updated,
					lead_forms: this.state.lead_forms
				});
			} catch (error) {
			}
		},
	};

	renders = {
		creative: (item, placement_format) => {
			let creative = null;
			try {
				if (this.renders.type(item) === "video") {
					if (this.state.updated.video) {
						if (!this.state.wizard) {
							creative = { thumbnail: this.state.updated.video.thumbnail, video: this.state.updated.video.url };
						} else {
							creative = { thumbnail: this.state.updated.thumbnail, video: this.state.updated.video };
						}
					} else {
						creative = { thumbnail: item.creative.object_story_spec.video_data.image_url, video: this.state.video };
					}
				}
			} catch (eror) { }
			try {
				if (this.renders.type(item) === "posts") {
					if (Array.isArray(this.state.updated.files)) {
						creative = this.state.updated.files.map((inner_item) => {
							let buff = {
								headline: item.headline,
								description: item.body,
								video: false,
								image: false,
								loading: item.loading,
								cta: item.cta,
								creative: { thumbnail: { url: inner_item.url }, url: inner_item.url, ratio: "1:1" }
							}
							if (inner_item.type === "image") {
								buff.image = true;
							} else {
								buff.video = true;
							}
							return buff
						});
					} else if (this.state.updated.ad_type.type === "image") {
						creative = this.state.updated.picture;
					} else if (this.state.updated.ad_type.type === "video") {
						creative = { thumbnail: "", video: this.state.updated.url };
					}
				}
			} catch (eror) { }

			try {
				if (this.renders.type(item) === "image") {
					if (this.state.updated.picture && this.state.updated.picture !== "") {
						creative = this.state.updated.picture;
					} else {
						creative = item.creative.object_story_spec.link_data.picture;
					}
				}
			} catch (eror) { }
			try {
				if (this.renders.type(item) === "merge") {
					this.state.updated.slides.map((item) => {
						if ((item.ratio === '9:16') && placement_format === 'vertical') {
							if (item.type === 'video') {
								creative = { thumbnail: item.thumbnail, url: item.url };
							} else {
								creative = { url: item.url };
							}
						} else if ((item.ratio !== '9:16' && item.ratio !== '1.91:1') && placement_format === 'square') {
							if (item.type === 'video') {
								creative = { thumbnail: item.thumbnail, video: item.url };
							} else {
								creative = item.url;
							}
						}
					});
				}
			} catch (error) { }
			try {
				if (this.renders.type(item) === "carousel") {
					if (Array.isArray(this.state.updated.slides)) {
						creative = this.state.updated.slides.map((item) => {
							return {
								headline: item.headline,
								description: item.body,
								video: item.video_id || item.video,
								image: !item.video_id && !item.video,
								loading: item.loading,
								cta: item.cta,
								creative: { thumbnail: { url: !item.video_data ? item.picture : item.video_data.thumbnail }, url: !item.video_data ? item.picture : item.video_data.url, ratio: "1:1" }
							}
						});
					} else {
						if (Array.isArray(item.creative.object_story_spec.link_data.child_attachments)) {
							creative = item.creative.object_story_spec.link_data.child_attachments.map((item) => {
								return {
									headline: item.name,
									description: item.description,
									video: item.video_id,
									image: !item.video_id,
									creative: { thumbnail: { url: item.picture }, url: item.picture, ratio: "1:1" }
								}
							});
						}
					}
				}
			} catch (eror) { }
			try {
				if (this.renders.type(item) === "catalog") {
					if (Array.isArray(this.state.updated.preview_products)) {
						creative = this.state.updated.preview_products.map((item) => {
							return {
								headline: item.name,
								description: item.price,
								image: true,
								loading: item.loading,
								cta: item.cta,
								creative: { thumbnail: { url: !item.video_data ? item.picture : item.video_data.thumbnail }, url: !item.video_data ? item.image_url : item.video_data.url, ratio: "1:1" }
							}
						});
					} else {
						if (Array.isArray(item.creative.object_story_spec.link_data.child_attachments)) {
							creative = item.creative.object_story_spec.link_data.child_attachments.map((item) => {
								return {
									headline: item.name,
									description: item.price,
									video: item.video_id,
									image: !item.video_id,
									creative: { thumbnail: { url: item.picture }, url: item.picture, ratio: "1:1" }
								}
							});
						}
					}
				}
			} catch (eror) { }
			return creative;
		},
		status: () => {
			try {
				if ("status" in this.state.updated) {
					return this.state.updated.status;
				} else if (this.state.ad.status === 'ACTIVE') {
					return { id: 1, name: 'Active', value: "ACTIVE" }
				} else if (this.state.ad.status === 'PAUSED') {
					return { id: 2, name: 'Paused', value: "PAUSED" }
				} else if (this.state.ad.status === 'DELETED') {
					return { id: 3, name: 'Deleted', value: "DELETED" }
				} else if (this.state.ad.status === 'ARCHIVED') {
					return { id: 4, name: 'Archived', value: "ARCHIVED" }
				} else {
					return { id: 2, name: 'Paused', value: "PAUSED" }
				}
			} catch (error) {
				return { id: 2, name: 'Paused', value: "PAUSED" }
			}
		},
		lead: () => {
			try {
				if ("selected_lead" in this.state.updated) {
					return this.state.updated.selected_lead;
				} else {
					return { id: 0, name: 'Select ...', value: null }
				}
			} catch (error) {
				return { id: 0, name: 'Select ...', value: null }
			}
		},
		collection_catalog: () => {
			try {
				if ("collection_catalog" in this.state.updated) {
					return this.state.updated.collection_catalog;
				} else {
					return { id: 0, name: 'Select catalog', value: null }
				}
			} catch (error) {
				return { id: 0, name: 'Select catalog', value: null }
			}
		},
		product_set: () => {
			try {
				if ("product_set" in this.state.updated) {
					return this.state.updated.product_set;
				} else {
					return { id: 0, name: 'Select product set', value: null }
				}
			} catch (error) {
				return { id: 0, name: 'Select product set', value: null }
			}
		},
		adType: () => {
			try {
				if ("ad_type" in this.state.updated) {
					return this.state.updated.ad_type;
				} else {
					return { id: 0, name: 'Not specified', value: null }
				}
			} catch (error) {
				return { id: 0, name: 'Not specified', value: null }
			}
		},
		type: (item) => {
			if (!this.state.wizard) {
				let type = null;
				try {
					if (item.creative.object_story_spec.video_data) {
						type = "video";
					}
				} catch (eror) { }
				try {
					if (item.creative.object_story_spec.link_data.picture || item.creative.object_story_spec.link_data.image_hash) {
						type = "image";
					}
				} catch (eror) { }
				try {
					if (Array.isArray(item.creative.object_story_spec.link_data.child_attachments)) {
						type = "carousel";
					}
				} catch (eror) { }
				return type;
			} else {
				return this.state.updated.type;
			}
		},
		name: () => {
			try {
				if ('name' in this.state.updated) {
					return this.state.updated.name;
				} else {
					return this.state.ad.name
				}
			} catch (error) {
				return 0;
			}
		},
		title: () => {
			try {
				if (this.renders.type(this.state.updated) == "merge") {
					let headline = "";
					if (Array.isArray(this.state.updated.headlines)) {
						this.state.updated.headlines.map((item) => {
							headline = item.value;
						});
					}
					return headline;
				} else if ('title' in this.state.updated) {
					return this.state.updated.title;
				} else {
					if (this.renders.type(this.state.ad) == "image") {
						return this.state.ad.creative.object_story_spec.link_data.name;
					} else if (this.renders.type(this.state.ad) == "video") {
						return this.state.ad.creative.object_story_spec.video_data.title;
					} else {
						return "";
					}
				}
			} catch (error) {
				return "";
			}
		},
		button_label: () => {
			try {
				if ('button_label' in this.state.updated) {
					return this.state.updated.button_label;
				} else return "";
			} catch (error) {
				return "";
			}
		},
		header_title: () => {
			try {
				if ('header_title' in this.state.updated) {
					return this.state.updated.header_title;
				} else return "";
			} catch (error) {
				return "";
			}
		},
		body: () => {
			try {
				if (this.state.updated && this.state.updated.is_post) {
					return this.state.updated.body;
				} else if (this.renders.type(this.state.updated) == "merge") {
					let body = "";
					if (Array.isArray(this.state.updated.bodies)) {
						this.state.updated.bodies.map((item) => {
							body = item.value;
						});
					}
					return body;
				} else if ('body' in this.state.updated) {
					return this.state.updated.body;
				} else {
					if (this.renders.type(this.state.ad) == "image") {
						return this.state.ad.creative.object_story_spec.link_data.message;
					} else if (this.renders.type(this.state.ad) == "video") {
						return this.state.ad.creative.object_story_spec.video_data.message;
					} else if (this.renders.type(this.state.ad) == "carousel") {
						return this.state.ad.creative.object_story_spec.link_data.message;
					} else {
						return "";
					}
				}
			} catch (error) {
				return "";
			}
		},
		description: () => {
			try {
				if (this.renders.type(this.state.updated) == "merge") {
					let description = "";
					if (Array.isArray(this.state.updated.descriptions)) {
						this.state.updated.descriptions.map((item) => {
							description = item.value;
						});
					}
					return description;
				} else if ('description' in this.state.updated) {
					return this.state.updated.description;
				} else {
					if (this.renders.type(this.state.ad) == "image") {
						return this.state.ad.creative.object_story_spec.link_data.description;
					} else if (this.renders.type(this.state.ad) == "video") {
						return this.state.ad.creative.object_story_spec.video_data.link_description;
					} else {
						return "";
					}
				}
			} catch (error) {
				return "";
			}
		},
		caption: () => {
			try {
				if ('caption' in this.state.updated) {
					return this.state.updated.caption;
				} else {
					if (this.renders.type(this.state.ad) == "image") {
						return this.state.ad.creative.object_story_spec.link_data.caption;
					} else if (this.renders.type(this.state.ad) == "video") {
						return this.state.ad.creative.object_story_spec.video_data.call_to_action.value.link_caption;
					} else if (this.renders.type(this.state.ad) == "carousel") {
						return this.state.ad.creative.object_story_spec.link_data.caption;
					} else {
						return "";
					}
				}
			} catch (error) {
				return "";
			}
		},
		link: () => {
			try {
				if (this.renders.type(this.state.updated) == "merge") {
					let link = "";
					if (Array.isArray(this.state.updated.links)) {
						this.state.updated.links.map((item) => {
							link = item.value;
						});
					}
					return link;
				} else if ('link' in this.state.updated) {
					return this.state.updated.link;
				} else {
					if (this.renders.type(this.state.ad) == "image") {
						return this.state.ad.creative.object_story_spec.link_data.link;
					} else if (this.renders.type(this.state.ad) == "video") {
						return this.state.ad.creative.object_story_spec.video_data.call_to_action.value.link;
					} else if (this.renders.type(this.state.ad) == "carousel") {
						return this.state.ad.creative.object_story_spec.link_data.link;
					} else {
						return "";
					}
				}
			} catch (error) {
				return "";
			}
		},
		cta: (item) => {
			try {
				if (this.state.updated && this.state.updated.is_post) {
					return this.state.updated.cta;
				} else if (this.renders.type(item) === "merge") {
					if (Array.isArray(this.state.updated.call_to_actions)) {
						return this.state.updated.call_to_actions[0].value;
					} else {
						return { id: 0, name: "No Button", type: "NO_BUTTON" };
					}
				} else if ("cta" in this.state.updated) {
					return this.state.updated.cta;
				} else {
					return { id: 0, name: "No Button", type: "NO_BUTTON" };
				}
			} catch (error) {
				return { id: 0, name: "No Button", type: "NO_BUTTON" };
			}
		},
		// try {
		// 	if (this.renders.type(item) === "merge") {
		// 		let cta = { id: 0, name: "Select ...", type: "NO_BUTTON" };
		// 		if (Array.isArray(this.state.updated.call_to_actions)) {
		// 			this.state.updated.call_to_actions.map((item) => {
		// 				cta = item.value;
		// 			});
		// 		}
		// 		return cta;
		// 	} else if (this.state.updated.cta) {
		// 		return this.state.updated.cta;
		// 	} else {
		// 		let cta = null;
		// 		if (this.renders.type(item) === "image") {
		// 			cta = this.state.call_to_actions.filter((cta) => { return cta.type == item.creative.object_story_spec.link_data.call_to_action.type })[0];
		// 		} else if (this.renders.type(item) === "video") {
		// 			cta = this.state.call_to_actions.filter((cta) => { return cta.type == item.creative.object_story_spec.video_data.call_to_action.type })[0];
		// 		}
		// 		if (cta) {
		// 			return cta;
		// 		} else {
		// 			return { id: 0, name: "Select ...", type: "NO_BUTTON" }
		// 		}
		// 	}
		// } catch (error) {
		// 	console.log(error);
		// 	return { id: 0, name: "Select ...", type: "NO_BUTTON" };
		// }
		app: (item) => {
			try {
				if (this.state.updated.tracking && this.state.updated.tracking.app) {
					return this.state.updated.tracking.app;
				} else {
					return { id: 0, name: "No App", value: null }
				}
			} catch (error) {
				console.log(error);
				return { id: 0, name: "No App", value: null };
			}
		},
		pixel: (item) => {
			try {
				if (this.state.updated.tracking && this.state.updated.tracking.pixel) {
					return this.state.updated.tracking.pixel;
				} else {
					return { id: 0, name: "No Pixel", value: null }
				}
			} catch (error) {
				console.log(error);
				return { id: 0, name: "No Pixel", value: null };
			}
		},
		page: (item) => {
			try {
				// if ("page" in this.state.updated) {
				// 	return this.state.updated.page;
				// } else {
				// 	return { id: 0, name: "Select page" };
				// }
				if (this.state.wizard) {
					return this.state.updated.page ? this.state.updated.page : { id: 0, name: "Select page" }
				} else {
					let page = this.state.pages.filter((page) => { return page.id == item.creative.object_story_spec.page_id })[0];
					if (page) {
						return page
					} else {
						return { id: 0, name: "Select page" };
					}
				}
			} catch (error) {
				return { id: 0, name: "Select page" };
			}
		},
		instagram: (item) => {
			try {
				if (this.state.wizard) {
					return this.state.updated.instagram ? this.state.updated.instagram : { id: 0, name: "Select instagram" };
				} else if (this.renders.type(item) == "image" || this.renders.type(item) == "video" || this.renders.type(item) == "carousel" || this.renders.type(item) == "catalog") {
					if (this.state.updated.instagram) {
						return { name: this.state.updated.instagram.name, id: this.state.updated.instagram.id, image: this.state.updated.instagram.profile_pic }
					} else {
						let instagram = this.state.instagram_accounts.filter((page) => { return page.id == item.creative.object_story_spec.instagram_actor_id })[0];
						return { name: instagram.name, id: instagram.id, image: instagram.profile_pic }
					}
				} else {
					return { id: 0, name: "Select instagram" };
				}
			} catch (error) {
				return { id: 0, name: "Select instagram" };
			}
		},
		autoCards: (item) => {
			try {
				if (this.state.updated.auto_cards) {
					return this.state.updated.auto_cards
				} else {
					if (item.creative.object_story_spec.link_data.multi_share_optimized) {
						return { id: 1, name: "Yes", value: true };
					} else {
						return { id: 1, name: "no", value: false };
					}
				}
			} catch (error) {
				return { id: 1, name: "Yes", value: true };
			}
		},
		lastCard: (item) => {
			try {
				if (this.state.updated.last_card) {
					return this.state.updated.last_card
				} else {
					if (item.creative.object_story_spec.link_data.multi_share_end_card) {
						return { id: 1, name: "Yes", value: true };
					} else {
						return { id: 1, name: "no", value: false };
					}
				}
			} catch (error) {
				return { id: 1, name: "Yes", value: true };
			}
		},
		instantExperience: () => {
			try {
				if (this.state.updated && this.state.updated.instantExperience) {
					return { id: this.state.updated.instantExperience.id, name: this.state.updated.instantExperience.name }
				} else if (this.state.instantExperience) {
					return { id: this.state.instantExperience.id, name: this.state.instantExperience.name }
				} else {
					return { id: 0, name: "Select instant experience" };
				}
			} catch (error) {
				return { id: 0, name: "Select instant experience" };
			}
		}
	};

	calls = {
		image: (data) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
			let url = apiRegister.url.api + "/v3/adcredo/uploadImage?client=" + this.state.client.id + "&wizard=" + this.state.wizardId;
			return apiRegister.call(options, url);
		},
		video_upload: (data, channel_id) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
			let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?client=" + this.state.client.id + "&wizard=" + this.state.wizardId + "&large=true" + "&channel=" + channel_id;
			return apiRegister.call(options, url);
		},
		getInstant: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/getInstant?client=" + this.state.client.id + "&id=" + this.state.updated.instantExperience.id + "&page=" + this.renders.page().id;
			return apiRegister.call(options, url);
		},
		listLeadForms: (page) => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listLeadForms?client=" + this.state.client.id + "&page=" + page;
			return apiRegister.call(options, url);
		},
		update: (data, id) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', data);
			let url = apiRegister.url.api + "/v3/facebook/updateAdCreative?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		duplicate: (data, id) => {
			let options = apiRegister.options(tokenRegister.get(), 'POST', data);
			let url = apiRegister.url.api + "/v3/facebook/duplicateAd?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		facebookCatalogs: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listCatalogs?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		facebookProductSets: (catalog) => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listProductSets?client=" + this.state.client.id + "&id=" + catalog;
			return apiRegister.call(options, url);
		},
		facebookProducts: (product_set) => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listProducts?client=" + this.state.client.id + "&id=" + product_set;
			return apiRegister.call(options, url);
		},
		pages: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listPages?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		instagramAccounts: (page) => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listInstagramAccounts?client=" + this.state.client.id + "&page=" + page;
			return apiRegister.call(options, url);
		},
		instagramBusinessAccounts: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listBusinessInstagramAccounts?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		video: (id) => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/getVideo?client=" + this.state.client.id + "&id=" + id;
			return apiRegister.call(options, url);
		},
		apps: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listApps?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		pixels: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listPixels?client=" + this.state.client.id;
			return apiRegister.call(options, url);
		},
		listInstantExpriences: () => {
			let options = apiRegister.options(tokenRegister.get(), 'GET', null);
			let url = apiRegister.url.api + "/v3/facebook/listInstantExpriences?client=" + this.state.client.id + "&page=" + this.state.updated.page.id;
			return apiRegister.call(options, url);
		},
	};

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

	truncText(string, maxLength) {
		if (string.length > maxLength) {
			return string.substring(0, maxLength) + "..."
		}
		else {
			return string
		}
	}

	SortableItem = SortableElement(({ item, itemIndex }) => {
		return (
			<div
				style={{ zIndex: 99999999 }}
				className="cursor-pointer">
				{
					<>
						<div className={`bg-gray-100 p-4 rounded-md space-y-4 ${this.state.open_slide === itemIndex ? ' h-auto z-55 overflow-y-visible' : ' h-20 overflow-hidden'}`}>
							<div className='flex w-full justify-between'>
								<div className='flex'>
									<div className={"border-1.5 h-12 w-12 mr-2 flex justify-center cursor-pointer items-center rounded-md"}>
										{
											this.state.carousel &&
											<SwitchVerticalIcon className="h-5 w-5" />
										}
										{
											this.state.merge &&
											<div className="text-sm font-medium">
												{item.ratio ? item.ratio : "Unknown ratio"}
											</div>
										}
									</div>
									<div className='flex items-center'>
										<div className='flex items-center justify-center h-12 w-12 rounded-md mr-2'>
											{
												item.type === "image" &&
												<img src={item.url ? item.url : ""} className="w-full object-contain rounded-md"
													style={{ maxHeight: "48px" }}
												/>
											}
											{
												item.type === "video" &&
												<video src={item.url ? item.url : ""} autoPlay={false} muted={true} controls={false} className="rounded-md"
													style={{ maxHeight: "48px" }}
												/>
											}
										</div>
										<div className='flex flex-col'>
											<div className="text-sm font-medium">
												Slide {itemIndex + 1}
											</div>
											<div className="flex text-xs text-gray-600">
												{
													this.state.open_slide === itemIndex ?
														item.type === "image" ?
															<>
																<PhotographIcon className="h-4 w-4 mr-1" />
																Image
															</>
															:
															<>
																<FilmIcon className="h-4 w-4 mr-1" />
																Video
															</>
														:
														<div className='grid grid-cols-2 gap-4 text-xs text-gray-600'>
															<div className='col-span-1'>
																Headline: {item.title && typeof item.title === 'string' ? this.truncText(item.title, 15) : ''}
															</div>
															<div className='col-span-1'>
																Description: {item.body && typeof item.body === 'string' ? this.truncText(item.body, 15) : ''}
															</div>
														</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="flex items-center justify-center space-x-4">
									{
										this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides) && this.state.updated.slides.length > 2 &&
										<div className='bg-red-200 p-2 rounded-md cursor-pointer'
											onClick={async () => {
												await this.promisedSetState({ delete_modal: true, delete_slide: item })
											}}
										>
											<TrashIcon className="h-5 w-5 text-red-500 cursor-pointer" />
										</div>
									}
									<div
										onClick={async () => await this.promisedSetState({ open_slide: this.state.open_slide === itemIndex ? null : itemIndex })}
										className={`bg-gray-200 p-2 cursor-pointer flex rounded font-bold justify-center align-middle items-center`}>
										<ChevronUpIcon className={`h-5 w-5text-grey-600 transform rotate-0 transition-all duration-200 ${this.state.open_slide === itemIndex && "rotate-180"}`} />
									</div >
								</div>
							</div>

							{
								this.state.updated && this.state.updated.type !== "posts" &&
								<div className='flex w-full space-x-4'>
									{console.log("state", this.state.updated)}
									<div className='w-full'>
										<DropdownTailwind
											label={"Select other asset"}
											loader={this.state.loader}
											searchInput={true}
											locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
											selected={
												this.state.updated_slides && this.state.updated_slides[itemIndex] ? this.state.updated_slides[itemIndex] : { id: 0, name: "Select an asset" }
											}
											options={this.state.assetItems && this.state.assetItems.map((item) => {
												return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
											})}
											onChange={async (value) => {
												if (this.state.updated && this.state.updated.slides && Array.isArray(this.state.updated.slides)) {
													let updated_slide = { ...this.state.updated.slides[itemIndex], ...value };
													if (value.image) {
														updated_slide.type = "image";
													} else if (value.video) {
														updated_slide.type = "video";
													}
													this.state.updated.slides[itemIndex] = updated_slide;
												}
												if (!this.state.updated_slides) {
													this.state.updated_slides = JSON.parse(JSON.stringify(this.state.updated.slides)).map((item, index) => {
														return {};
													})
												}
												this.state.updated_slides[itemIndex] = value;
												await this.promisedSetState({
													updated: this.state.updated,
													updated_slides: this.state.updated_slides
												});
												this.functions.updatePreview();
											}}
										/>
									</div>
									<div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
										<div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
											<input
												onChange={(e) => this.functions.onSelectFile(e, item)}
												type="file"
												accept={this.state.updated && this.state.updated.type === "carousel" ? "image/png, image/jpg, image/jpeg, image/gif, video/*" : this.state.updated && this.state.updated.type === "video" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif"}
												className="absolute z-50 top-0 left-0 w-full h-full cursor-pointer opacity-0"
											/>
											{
												this.state.loader &&
												<div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
													<div style={{ borderTopColor: "transparent" }}
														class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
													<div className="ml-2 text-xs">
														Loading ...
													</div>
												</div>
											}
											{
												!this.state.loader &&
												<div className="text-xs text-gray-600">
													<span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
												</div>
											}
										</div>
									</div>
								</div>
							}

							<div className="w-full">
								<InputTailwind
									label={"Website"}
									value={item.link ? item.link : ""}
									error={(!item.link || !item.link.includes("https://")) ? true : false}
									onChange={async (value) => {
										this.state.updated.slides[itemIndex].link = value;
										// this.state.updated.files = this.state.updated.files.map((inner_item) => {
										// 	if (inner_item.id === item.id) {
										// 		inner_item.link = value;
										// 	}
										// 	return inner_item
										// })
										await this.promisedSetState({
											updated: this.state.updated
										})
										this.functions.updatePreview();
									}}
								/>
							</div>
							{
								(!item.link || !item.link.includes("https://")) &&
								<div className="w-full mt-2 p-4 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
									OBS! Prefix https:// is required.
								</div>
							}
							<div className="flex space-x-4 w-full">
								<div className='w-full'>
									<InputTailwind
										label={"Headline"}
										value={item.title ? item.title : ""}
										// error={this.state.utm_channels.filter((item) => { return item === "facebook" }).length > 0 && (!item.title || item.title === "") ? true : false}
										onChange={async (value) => {
											this.state.updated.slides[itemIndex].title = value;
											this.promisedSetState({
												updated: this.state.updated
											})
											this.functions.updatePreview();
										}}
									/>
								</div>
								{
									<div className="w-full">
										<InputTailwind
											label={"Description"}
											value={item.body ? item.body : ""}
											onChange={async (value) => {
												this.state.updated.slides[itemIndex].body = value;
												await this.promisedSetState({
													updated: this.state.updated
												})
												this.functions.updatePreview();
											}}
										/>
									</div>
								}
							</div>
						</div>

					</>
				}
			</div>
		)
	});

	SortableList = SortableContainer(({ items }) => (
		<div className="relative space-y-4">
			{items.map((item, index) => {
				if (item) {
					return (
						<this.SortableItem
							index={index}
							itemIndex={index}
							item={item}
						/>
					)
				}
			})}
		</div>
	));

	onSortEnd = async ({ oldIndex, newIndex }) => {
		await this.promisedSetState({
			array: arrayMove((this.state.updated.slides ? this.state.updated.slides : []), oldIndex, newIndex),
			array_buffer: arrayMove((this.state.updated_slides ? this.state.updated_slides : []), oldIndex, newIndex)
		})
		if (this.state.updated.slides) {
			this.state.updated.slides = this.state.array;
			if (this.state.updated_slides) {
				this.state.updated_slides = this.state.array_buffer;
			}
			// this.state.updated.files = this.state.array;
			await this.promisedSetState({
				updated: this.state.updated
			})
			this.functions.updatePreview()
		}
	};

	renderSortableList() {
		return (
			<div className="relative space-y-4">
				<this.SortableList
					items={this.state.updated.slides}
					onSortEnd={this.onSortEnd}
					axis="y"
					distance={1}
				/>
			</div>
		)
	}

	render() {

		function classNames(...classes) {
			return classes.filter(Boolean).join(' ')
		}

		return (
			<>
				<WarningModalTailwind
					open={this.state.delete_modal ? true : false}
					title={"Delete slide"}
					description={'Are you sure you want to delete this slide ? This action cannot be undone.'}
					cancelButtonText={"Cancel"}
					submitButtonText={"Delete"}
					onClose={async (value) => {
						await this.promisedSetState({ delete_modal: false });
					}}
					onSubmit={() => {
						this.functions.deleteSlide(this.state.delete_slide);
					}}
				/>

				<div className='flex flex-col px-4 space-y-4 bg-gray-50'>
					{
						(this.state.carousel || this.state.fb_lead_form) &&
						<div className="block w-full">
							<div className="border-gray-200 w-full">
								<nav className="-mb-px flex space-x-4" aria-label="Tabs">
									{
										["Main ad", "Slides information", "Lead Form"].map((item) => {
											if (item === "Lead Form" && !this.state.fb_lead_form) {
												return null;
											} else if (item === "Slides information" && !this.state.carousel) {
												return null;
											} else return item
										}).filter((item) => { return item }).map((item) => (
											<div
												onClick={async () => {
													await this.promisedSetState({
														carousel_menu: item
													})
												}}
												key={item}
												className={classNames(
													item === this.state.carousel_menu
														? 'border-purple-500 text-purple-600'
														: ('border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-500 border-gray-400 cursor-pointer'),
													'whitespace-nowrap py-4 px-4 border-b-2 cursor-pointer font-medium text-sm bg-gray-50 border-t-1.5 border-r-1.5 border-l-1.5 rounded-md'
												)}
												aria-current={item === this.state.carousel_menu ? 'page' : undefined}
											>
												{item}
											</div>
										))
									}
								</nav>
							</div>
						</div>
					}

					{
						// this.state.updated && (!this.state.updated.is_post || this.state.updated.type !== "posts") && !this.state.carousel && !this.state.merge && !this.state.catalog && !this.state.fb_lead_form &&
						this.state.updated && !(this.state.updated.is_post || this.state.updated.type === "posts") &&
						!this.state.carousel && !this.state.merge && !this.state.catalog &&
						!this.state.fb_lead_form &&
						<div className="flex flex-row items-center space-x-4">
							<div className='w-full'>
								<DropdownTailwind
									label={"Select other asset"}
									loader={this.state.loader}
									locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
									selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
									options={this.state.assetItems && this.state.assetItems.filter((item) => {
										if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
											return item.type === "image"
										} else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
											return item.type === "video"
										} else return item
									}).map((item) => {
										if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
											return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
										} else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
											return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
										} else if (this.state.updated && this.state.updated.collection_ad) {
											return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
										}
									}).filter((item) => { return item })}
									onChange={async (value) => {
										this.state.updated.file = { ...this.state.updated.file, ...value };
										if (value.image) {
											delete this.state.updated.video;
											this.state.updated.picture = value.image;
											this.state.updated.type = "image";
										} else if (value.video) {
											delete this.state.updated.picture;
											this.state.updated.video = value.video;
											this.state.updated.type = "video";
										}

										await this.promisedSetState({
											updated: this.state.updated,
											selectedAsset: value.id
										});
										this.functions.updatePreview();
									}}
								/>
							</div>
							{
								!this.state.loader &&
								<div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
									<div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
										<input
											onChange={(e) => this.functions.onSelectFile(e)}
											type="file"
											accept={(this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "image_ad" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "video_ad" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
											className="absolute z-55 top-0 left-0 w-full h-full cursor-pointer opacity-0"
										/>
										{
											this.state.loader &&
											<div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
												<div style={{ borderTopColor: "transparent" }}
													class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
												<div className="ml-2 text-xs">
													Loading ...
												</div>
											</div>
										}
										{
											!this.state.loader &&
											<div className="text-xs text-gray-600">
												<span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
											</div>
										}
									</div>
								</div>
							}
						</div>
					}


					{
						this.state.merge && this.state.merge.error &&
						<div className="col-span-12 py-5 text-sm px-5 text-center items-center border-red-600 border-2 bg-red-200 rounded-lg">
							{this.state.merge.message}
						</div>
					}



					{
						this.state.carousel_menu === "Slides information" &&
						<div className="flex flex-col space-y-4">

							{
								this.state.updated && Array.isArray(this.state.updated.slides) && this.state.updated.slides.length > 0 &&
								this.renderSortableList()
							}

						</div>
					}

					{
						(this.state.carousel_menu === "Lead Form") &&
						<div className="w-full space-y-4">
							{
								this.state.updated && this.state.updated.page && this.state.updated.page.id === 0 && this.state.fb_lead_form &&
								<div className="col-span-6 bg-orange-100 rounded-md p-4 text-sm font-medium text-orange-500">
									OBS! Choose a Facebook page to create a lead form
								</div>
							}
							<div className='w-full'>
								<div className="w-full">
									<DropdownTailwind
										channel="facebook"
										locked={this.state.updated && this.state.updated.page && this.state.updated.page.id === 0 && this.state.fb_lead_form ? true : false}
										disabled={this.state.updated && this.state.updated.page && this.state.updated.page.id === 0 && this.state.fb_lead_form ? true : false}
										label={"Lead forms"}
										loader={this.state.loading_leadforms}
										selected={this.state.updated.selected_lead ? this.state.updated.selected_lead : { id: 0, name: "Select a lead form" }}
										options={this.state.lead_forms.length > 0 ? this.state.lead_forms : [{ id: 0, name: "No lead forms available" }]}
										onChange={async (item) => {
											this.state.updated.selected_lead = item;
											if (this.state.updated && this.state.updated.cta && this.state.updated.cta.id && this.state.updated.cta.id !== 0 && [{ name: "Subscribe", id: "SUBSCRIBE", type: "SUBSCRIBE", objective: [1, 2, 3, 4, 5, 6] },
											{ name: "Apply Now", id: "APPLY_NOW", type: "APPLY_NOW", objective: [1, 2, 3, 4, 6] },
											{ name: "Book now", id: "BOOK_TRAVEL", type: "BOOK_TRAVEL", objective: [] },
											{ name: "Download", id: "DOWNLOAD", type: "DOWNLOAD", objective: [] },
											{ name: "Get offer", id: "GET_OFFER", type: "GET_OFFER", objective: [2, 3, 4, 6] },
											{ name: "Get quote", id: "GET_QUOTE", type: "GET_QUOTE", objective: [1, 2, 3, 6] },
											{ name: "Learn more", id: "LEARN_MORE", type: "LEARN_MORE", objective: [1, 2, 3, 4, 5, 6] },
											{ name: "Sign up", id: "SIGN_UP", type: "SIGN_UP", objective: [1, 2, 3, 4, 5, 6] }].filter((item) => { return item.id === this.state.updated.cta.id }).length < 1) {
												this.state.updated.cta = { id: 0, name: "Click here ..." };
											}
											this.state.updated.link = "";

											await this.promisedSetState({
												updated: this.state.updated
											});
										}}
									/>
								</div>
							</div>
							<div className="">
								<button
									onClick={async () => {
										if (this.state.updated && this.state.updated.page && this.state.updated.page.id === 0 && this.state.fb_lead_form) {
											// SOMETHING HERE ?
										} else {
											await this.promisedSetState({
												loading_new_lead: true
											});
											this.functions.leadForm();
										}
									}}
									className={(this.state.updated && this.state.updated.page && this.state.updated.page.id === 0 && this.state.fb_lead_form ? "bg-gray-500 hover:bg-gray-600 text-white cursor-not-allowed" : "bg-purple-500 hover:bg-purple-600 text-white") + "  py-3 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 inline-flex shadow relative justify-center rounded-md border border-transparent "}
								>
									Create new
									{
										this.state.loading_new_lead &&
										<div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
											<div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
										</div>
									}
								</button>
							</div>

						</div>
					}

					{
						this.state.updated && this.state.updated.collection_ad &&
						<>
							<div className='flex space-x-4'>
								{
									["Main ad", "Instant Experience"].map((item) => {
										return (
											<div
												onClick={async () => {
													await this.promisedSetState({
														carousel_menu: item
													})
												}}
												className={classNames(
													item === this.state.carousel_menu
														? 'border-purple-500 text-purple-600'
														: ('border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-500 border-gray-400 cursor-pointer'),
													'whitespace-nowrap py-4 px-4 border-b-2 cursor-pointer font-medium text-sm bg-gray-50 border-t-1.5 border-r-1.5 border-l-1.5 rounded-md')}
											>
												{item}
											</div>
										)
									})
								}
							</div>
							{
								this.state.carousel_menu === "Instant Experience" &&
								<>
									<div className='w-full'>
										<DropdownTailwind
											channel="facebook"
											label={"Instant Experience"}
											locked={this.state.updated && this.state.updated && this.state.updated.page ? this.state.updated.page.id === 0 ? true : false : true}
											disabled={this.state.updated && this.state.updated && this.state.updated.page ? this.state.updated.page.id === 0 ? true : false : true}
											placeholder={"Click to search ..."}
											searchInput={true}
											selected={this.renders.instantExperience()}
											options={this.state.instant_options}
											onChange={async (instant) => {
												this.state.instantExperience = instant;
												this.state.updated.instantExperience = instant;
												await this.promisedSetState({
													updated: this.state.updated, instantExperience: this.state.instantExperience
												})
												this.functions.getInstant();
											}}
										/>
									</div>

									<div className='space-y-4'>
										<div className="col-span-4 text-lg font-semibold">
											New Instant Experience
										</div>
										<div className='w-full'>
											<DropdownTailwind
												channel="facebook"
												label={"Select type"}
												locked={this.state.updated && this.state.updated && this.state.updated.page ? this.state.updated.page.id === 0 ? true : false : true}
												disabled={this.state.updated && this.state.updated && this.state.updated.page ? this.state.updated.page.id === 0 ? true : false : true}
												selected={this.state.instant_type}
												options={this.state.instant_types}
												onChange={async (instant) => {
													this.state.instant_type = instant;
													this.state.updated.instant_type = instant;
													await this.promisedSetState({
														updated: this.state.updated, instant_type: this.state.instant_type
													})
													this.functions.updatePreview();
												}}
											/>
										</div>
										<button
											onClick={async () => {
												if (this.state.updated.page.id !== 0 && this.state.instant_type.id !== 0) {
													await this.promisedSetState({
														loading_instant: true
													});
													this.functions.instantExperience();
												}
											}}
											className={(this.state.updated.page.id === 0 || this.state.instant_type.id === 0 ? "bg-gray-500 hover:bg-gray-600 text-white cursor-not-allowed" : "bg-purple-500 hover:bg-purple-600 text-white") + " py-3 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 inline-flex shadow relative justify-center rounded-md border border-transparent"}
										>
											Create new
											{
												this.state.loading_instant &&
												<div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
													<div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
												</div>
											}
										</button>
									</div>
								</>
							}
						</>
					}

					{
						this.state.carousel_menu === "Main ad" &&
						<>
							{
								this.state.fb_lead_form &&
								<div className="flex flex-row items-center space-x-4">
									<div className='w-full'>
										<DropdownTailwind
											label={"Select other asset"}
											loader={this.state.loader}
											locked={(this.state.updated && this.state.updated.is_post) || !this.state.wizard}
											selected={this.state.assetItems && this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset }).length > 0 ? this.state.assetItems.filter((item) => { return item.id === this.state.selectedAsset })[0] : { id: 0, name: "Select an asset" }}
											options={this.state.assetItems && this.state.assetItems.filter((item) => {
												if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
													return item.type === "image"
												} else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
													return item.type === "video"
												} else return item
											}).map((item) => {
												if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "image_ad" || this.state.updated.ad_type === "image")) {
													return { id: item.id, name: item.name, image: item.url, assetId: item.id, url: item.url }
												} else if (this.state.updated && this.state.updated.ad_type && (this.state.updated.ad_type.value === "video_ad" || this.state.updated.ad_type === "video")) {
													return { id: item.id, name: item.name, video: item.url, assetId: item.id, url: item.url }
												} else if (this.state.updated && this.state.updated.collection_ad) {
													return { id: item.id, name: item.name, assetId: item.id, url: item.url, ...(item.type === "video" && { video: item.url }), ...(item.type === "image" && { image: item.url }) };
												}
											}).filter((item) => { return item })}
											onChange={async (value) => {
												this.state.updated.file = { ...this.state.updated.file, ...value };
												if (value.image) {
													delete this.state.updated.video;
													this.state.updated.picture = value.image;
													this.state.updated.type = "image";
												} else if (value.video) {
													delete this.state.updated.picture;
													this.state.updated.video = value.video;
													this.state.updated.type = "video";
												}

												await this.promisedSetState({
													updated: this.state.updated,
													selectedAsset: value.id
												});
												this.functions.updatePreview();
											}}
										/>
									</div>
									{
										!this.state.loader &&
										<div key={this.state.theInputKeyOne || ''} className="w-1/3 pt-5.5">
											<div className="flex items-center justify-center h-12 relative w-full bg-custom-input rounded-md text-left focus:outline-none sm:text-sm hover:border-gray-500 border-gray-300 border-2 border-dashed">
												<input
													onChange={(e) => this.functions.onSelectFile(e)}
													type="file"
													accept={(this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "image_ad" ? "image/png, image/jpg, image/jpeg, image/gif" : (this.state.updated && this.state.updated.ad_type && this.state.updated.ad_type.value === "video_ad" ? "video/*" : "image/png, image/jpg, image/jpeg, image/gif, video/*"))}
													className="absolute z-55 top-0 left-0 w-full h-full cursor-pointer opacity-0"
												/>
												{
													this.state.loader &&
													<div className="bg-white z-50 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
														<div style={{ borderTopColor: "transparent" }}
															class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
														<div className="ml-2 text-xs">
															Loading ...
														</div>
													</div>
												}
												{
													!this.state.loader &&
													<div className="text-xs text-gray-600">
														<span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
													</div>
												}
											</div>
										</div>
									}
								</div>
							}

							<div className="flex w-full space-x-4">
								<div className='w-full'>
									<InputTailwind
										label={"Name"}
										channel="facebook"
										error={adFacebook.validate(this.state.updated).name}
										value={this.renders.name()}
										onChange={async (value) => {
											this.state.updated.name = value;
											await this.promisedSetState({
												updated: this.state.updated
											})
											this.functions.updatePreview();
										}}
									/>
								</div>
								<div className='w-1/3'>
									<InputTailwind
										label={"Type"}
										channel="facebook"
										value={this.renders.type() && typeof this.renders.type() === 'string' ? this.renders.type().charAt(0).toUpperCase() + this.renders.type().slice(1) : ''}
										disabled={true}

									/>
								</div>

							</div>

							<div className="col-span-4 text-lg font-semibold">
								Select identity
							</div>

							<div className='flex space-x-4 w-full'>
								<div className='w-full'>
									<DropdownTailwind
										label={"Facebook page"}
										channel="facebook"
										error={adFacebook.validate(this.state.updated).page}
										loader={this.state.loading_page}
										locked={!this.state.wizard}
										selected={this.renders.page(this.state.ad)}
										options={this.state.wizard ? this.state.pages : []}
										searchInput={true}
										onChange={async (value) => {
											this.state.updated.page = value;
											await this.promisedSetState({
												updated: this.state.updated
											});
											if (!this.state.instagram_post) this.functions.listInstagramAccounts(this.renders.page(this.state.ad).id);
											this.functions.updatePreview();
										}}
									/>
								</div>

								<div className='w-full'>
									<DropdownTailwind
										label={"Instagram Account"}
										channel="instagram"
										loader={this.state.loading_instagram}
										// locked={this.state.updated && this.state.updated.is_post}
										selected={this.renders.instagram(this.state.ad)}
										options={this.state.wizard ? this.state.instagram_accounts : []}
										searchInput={true}
										onChange={async (value) => {
											this.state.updated.instagram = value;
											await this.promisedSetState({
												updated: this.state.updated
											});
											this.functions.updatePreview();
										}}
									/>
								</div>

							</div>

							<div className="col-span-4 text-lg font-semibold">
								Ad settings
							</div>

							{
								this.state.carousel && this.state.carousel_menu === "Main ad" &&
								<div className='flex w-full space-x-4'>
									<div className="w-full">
										<DropdownTailwind
											label={"Automatically show the best performing cards first"}
											selected={this.renders.autoCards(this.state.ad)}
											options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
											onChange={(value) => {
												this.state.updated.auto_cards = value;
												this.setState({
													updated: this.state.updated
												})
											}}
										/>
									</div>
									<div className="w-full">
										<DropdownTailwind
											label={"Add a card at the end with your Page profile picture"}
											selected={this.renders.lastCard(this.state.ad)}
											options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
											onChange={(value) => {
												this.state.updated.last_card = value;
												this.setState({
													updated: this.state.updated
												})
											}}
										/>
									</div>
								</div>
							}

							{
								this.state.updated &&
								!this.state.updated.collection_ad &&
								<>
									<div className="w-full">
										<InputTailwind
											label={this.state.carousel ? "See more URL" : "Website"}
											// value={this.state.updated.link}
											locked={this.state.updated && this.state.updated.selected_lead && this.state.updated.selected_lead.id !== 0 ? true : false}
											disabled={this.state.updated && this.state.updated.selected_lead && this.state.updated.selected_lead.id !== 0 ? true : false}
											value={this.state.updated && this.state.updated.selected_lead && this.state.updated.selected_lead.id !== 0 ? "" : this.renders.link()}
											error={this.state.updated && this.state.updated.selected_lead && this.state.updated.selected_lead.id !== 0 ? false : adFacebook.validate(this.state.updated).website}
											rightLabel={false}
											rightLabelText={"Remove"}
											rightLabelColor={"text-red-500 hover:text-red-700"}
											onRightLabelClick={() => {
												// this.state.updated.links = this.state.updated.links.filter((inner_item) => { return item.id !== inner_item.id });
												// this.promisedSetState({
												// 	updated: this.state.updated
												// })
											}}
											onChange={async (value) => {
												if (this.renders.type(this.state.updated) === "merge") {
													this.state.updated.links = [{ id: 1, value: value }]
												} else {
													this.state.updated.link = value;
												}
												this.promisedSetState({
													updated: this.state.updated
												})
												this.functions.updatePreview();
											}}
										/>
									</div>

									{
										(!this.state.updated || !this.state.updated.selected_lead || this.state.updated.selected_lead.id === 0) &&
										adFacebook.validate(this.state.updated).website &&
										<div className="w-full mt-4 p-3 rounded-md font-medium text-sm text-orange-500 bg-orange-100 items-center inline-flex">
											OBS! Prefix https:// is required.
										</div>
									}

								</>
							}

							{
								//!this.state.merge &&
								!this.state.carousel &&
								!this.state.updated.collection_ad &&
								!this.state.catalog &&
								!this.state.updated.selected_lead &&
								!(this.state.updated.type === "posts" && this.state.updated.files && this.state.updated.files.length > 1) &&
								<div className="w-full">
									<InputTailwind
										label={this.renders.type(this.state.updated.type) !== "carousel" ? "Display link" : "See more display link"}
										value={this.renders.caption()}
										locked={this.state.updated && this.state.updated.is_post}
										disabled={this.state.updated && this.state.updated.is_post}
										onChange={async (value) => {
											this.state.updated.caption = value;
											await this.promisedSetState({
												updated: this.state.updated
											})
											this.functions.updatePreview();

										}}
									/>
								</div>
							}
							{
								!this.state.instagram_post &&
								!this.state.carousel &&
								(!this.state.catalog || this.state.catalog && this.state.updated.collection_ad) &&
								<div className="w-full relative">
									<InputTailwind
										label={"Headline"}
										error={adFacebook.validate(this.state.updated).headline}
										locked={(this.state.updated && this.state.updated.is_post) || (this.state.updated && this.state.updated.type === "posts") ? true : false}
										disabled={(this.state.updated && this.state.updated.is_post) || (this.state.updated && this.state.updated.type === "posts") ? true : false}
										value={this.renders.title()}
										onChange={async (value) => {
											if (this.renders.type(this.state.updated) === "merge") {
												this.state.updated.headlines = [{ id: 1, value: value }]
											} else {
												this.state.updated.title = value;
											}
											await this.promisedSetState({
												updated: this.state.updated
											})
											this.functions.updatePreview();
										}}
									/>
									{
										this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.filter((result) => { return result.fake && result.headlines && result.headlines.filter((inner_item) => { return inner_item.channel === "facebook" && inner_item.value.includes(this.renders.title()) }).length > 0 }).length > 0 &&
										<div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
											<SparklesIcon className="h-5 w-5 text-purple-500" />
										</div>
									}
								</div>
							}

							<div className='flex space-x-4 w-full'>
								{
									<div className="w-full relative">
										<TextAreaTailwind
											label={"Body"}
											error={adFacebook.validate(this.state.updated).body}
											value={this.renders.body()}
											locked={(this.state.updated && this.state.updated.is_post) || (this.state.updated && this.state.updated.type === "posts") ? true : false}
											disabled={(this.state.updated && this.state.updated.is_post) || (this.state.updated && this.state.updated.type === "posts") ? true : false}
											onChange={async (value) => {
												if (this.renders.type(this.state.updated) === "merge") {
													this.state.updated.bodies = [{ id: 1, value: value }]
												} else {
													this.state.updated.body = value;
												}
												await this.promisedSetState({
													updated: this.state.updated
												})
												this.functions.updatePreview();
											}}
										/>
										{
											this.state.updated && Array.isArray(this.state.updated.OpenAiResult) && this.state.updated.OpenAiResult.filter((result) => { return result.fake && result.descriptions && result.descriptions.filter((inner_item) => { return inner_item.channel === "facebook" && inner_item.value.includes(this.renders.body()) }).length > 0 }).length > 0 &&
											<div className="absolute right-0 top-0 mt-5.5 w-8 h-12 flex items-center justify-items-center">
												<SparklesIcon className="h-5 w-5 text-purple-500" />
											</div>
										}
									</div>
								}

								{
									!this.state.instagram_post &&
									!this.state.carousel &&
									!this.state.catalog &&
									!this.state.updated.collection_ad &&
									<div className="w-full">
										<TextAreaTailwind
											label={"Description"}
											value={this.renders.description()}
											locked={(this.state.updated && this.state.updated.is_post) || (this.state.updated && this.state.updated.type === "posts") ? true : false}
											disabled={(this.state.updated && this.state.updated.is_post) || (this.state.updated && this.state.updated.type === "posts") ? true : false}
											onChange={async (value) => {
												if (this.renders.type(this.state.updated) === "merge") {
													this.state.updated.descriptions = [{ id: 1, value: value }]
												} else {
													this.state.updated.description = value;
												}
												this.promisedSetState({
													updated: this.state.updated
												})
												this.functions.updatePreview();
											}}
										/>
									</div>
								}
							</div>

							{
								!this.state.updated.collection_ad &&
								<div className="col-span-4 text-lg font-semibold">
									Select CTA
								</div>
							}
							{
								!this.state.updated.collection_ad &&
								<div className="w-full">
									<DropdownTailwind
										searchInput={true}
										error={adFacebook.validate(this.state.updated).cta}
										label={"CTA button"}
										selected={this.renders.cta()}
										options={this.state.updated && this.state.updated.selected_lead ? [
											{ name: "Subscribe", id: "SUBSCRIBE", type: "SUBSCRIBE", objective: [1, 2, 3, 4, 5, 6] },
											{ name: "Apply Now", id: "APPLY_NOW", type: "APPLY_NOW", objective: [1, 2, 3, 4, 6] },
											{ name: "Book now", id: "BOOK_TRAVEL", type: "BOOK_TRAVEL", objective: [] },
											{ name: "Download", id: "DOWNLOAD", type: "DOWNLOAD", objective: [] },
											{ name: "Get offer", id: "GET_OFFER", type: "GET_OFFER", objective: [2, 3, 4, 6] },
											{ name: "Get quote", id: "GET_QUOTE", type: "GET_QUOTE", objective: [1, 2, 3, 6] },
											{ name: "Learn more", id: "LEARN_MORE", type: "LEARN_MORE", objective: [1, 2, 3, 4, 5, 6] },
											{ name: "Sign up", id: "SIGN_UP", type: "SIGN_UP", objective: [1, 2, 3, 4, 5, 6] },
										] : this.state.call_to_actions}
										onChange={async (value) => {
											if (this.renders.type(this.state.updated) === "merge") {
												this.state.updated.call_to_actions = [{ id: 1, value: value }]
											} else {
												this.state.updated.cta = value;
											}
											if (Array.isArray(this.state.updated.preview_products)) {
												this.state.updated.preview_products = this.state.updated.preview_products.map((item) => {
													item.cta = value;
													return item
												})
											}
											if (Array.isArray(this.state.updated.files)) {
												this.state.updated.files = this.state.updated.files.map((item) => {
													item.cta = value;
													return item
												})
											}
											await this.promisedSetState({
												updated: this.state.updated,
											})
											this.functions.updatePreview()
										}}
									/>
								</div>
							}

							<div className="col-span-4 text-lg font-semibold">
								Tracking settings
							</div>

							<div className='w-full pb-4'>

								<div className="flex space-x-4 w-full">
									<div className="w-full">
										<DropdownTailwind
											label={"Facebook tracking website pixel"}
											loader={this.state.loading_pixels}
											locked={!this.state.wizard}
											selected={this.renders.pixel(this.state.ad)}
											options={this.state.wizard ? this.state.pixels : []}
											onChange={async (value) => {
												if (!this.state.updated.tracking) {
													this.state.updated.tracking = { pixel: {} }
												}
												this.state.updated.tracking.pixel = value;
												await this.promisedSetState({
													updated: this.state.updated
												});
											}}
										/>
									</div>

									<div className="w-full">
										<DropdownTailwind
											label={"Facebook tracking app"}
											locked={!this.state.wizard}
											loader={this.state.loading_apps}
											selected={this.renders.app(this.state.ad)}
											options={this.state.wizard ? this.state.apps : []}
											onChange={async (value) => {
												if (!this.state.updated.tracking) {
													this.state.updated.tracking = { app: {} }
												}
												this.state.updated.tracking.app = value;
												await this.promisedSetState({
													updated: this.state.updated
												});
											}}
										/>
									</div>
								</div>

							</div>

						</>
					}
				</div>

			</>
		)
	}
}

export default EditFacebookAd;
