import React, {Component, Fragment} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import AccordionFixed from "../accordion-fixed";
import Switch from 'react-ios-switch';
import {dataRegister} from "../../services/dataRegister";
import {BeatLoader} from "react-spinners";
import Input from "../input";
import Dropdown from "../dropdown";
import AdvertisingTargetingFacebook from "../advertising-targeting-facebook";
import languages from '../../assets/json/languages';
import {calls} from "./calls";
import ReactTooltip from "react-tooltip";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import IconGoogle from "../icons/google";
import CSVReader from 'react-csv-reader'
import AdvertisingKeywordsIdeas from "../advertising-keywords-ideas";
import AdvertisingKeywordsLanguage from "../advertising-keywords-language";
import AdvertisingKeywordsPositivePreview from "../advertising-keywords-positive-preview";
import {SlideDown} from "react-slidedown";
import Loader from "../loader";
import {campaignRegister} from "../../services/campaignRegister";
import {eventRegister} from "../../services/eventRegister";

class AdvertisingKeywordsNegativeList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: {},
            campaign: {},
            search: {},
            sort: "index",
            order: "asc",
            total: 0,
            pagesize: 0
        };
    };

    componentWillMount() {}

    componentDidMount() {
        let information = campaignRegister.get();
        this.setState({
            campaign: this.props.campaign,
            search: information ? (information.search ? (information.search.value ? information.search.value : {}) : {}) : {},
            sort: this.props.sort?this.props.sort:"index",
            order: this.props.order?this.props.order:"asc",
            filter: this.props.filter?this.props.filter:"",
            total: this.props.total
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            campaign: nextProps.campaign,
            sort: nextProps.sort?nextProps.sort:"index",
            order: nextProps.order?nextProps.order:"asc",
            filter: nextProps.filter?nextProps.filter:"",
            total: nextProps.total
        })
    }

    functions = {
        get: () => {
            let information = campaignRegister.get();
            this.setState({
                search: information ? (information.search ? (information.search.value ? information.search.value : {}) : {}) : {}
            })
        },
        save: (key) => {
            let information = campaignRegister.get();
            if (!information) {
                information = {};
            }
            if (!information[key]) {
                information[key] = {};
            }
            information[key].value = this.state[key];
            campaignRegister.set(information);
            eventRegister.emit('CAMPAIGN_UPDATED', true, false);
        }
    };

    renders = {
        keywords: () => {
            try{
                return this.state.search.negatives.filter((item) => {
                    return item.campaign_id === this.state.campaign.id;
                })
            }catch (e) {
                return [];
            }
        }
    };

    render() {
        return (
            <div className="-my-2 overflow-x-auto">
                <div className="py-2 align-middle inline-block min-w-full">
                    <div className="overflow-hidden shadow-lg rounded-md">
                        <div className="px-4 py-4 flex-row flex border-b bg-white">
                            <div className="flex flex-1 flex-col">
                                <h3 className="mb-0 font-bold">
                                    {this.renders.keywords().length} keywords
                                </h3>
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Your have selected {this.renders.keywords().filter((item) => {
                                    return item.selected
                                }).length} keywords
                                </p>
                            </div>
                        </div>
                        <table className="min-w-full divide-y divide-gray-200 bg-white">
                            <thead>
                            <tr>
                                <th className="transition-all px-6 py-4 duration-200 text-left text-xs leading-4 font-medium uppercase tracking-wider">
                                    <div className="flex flex-row items-center justify-start">
                                        <div onClick={() => {
                                            if(this.renders.keywords().filter((item) => {
                                                return item.selected
                                            }).length === this.renders.keywords().length){
                                                this.renders.keywords().map((item) => {
                                                    item.selected = false;
                                                });
                                            }else{
                                                this.renders.keywords().map((item) => {
                                                    item.selected = true;
                                                });
                                            }
                                            this.setState({
                                                search: this.state.search
                                            }, () => {
                                                this.functions.save('search');
                                            });
                                        }} className={(this.renders.keywords().filter((item) => {
                                            return item.selected
                                        }).length === this.renders.keywords().length?"border-purple-500":"border-purple-100")+ " cursor-pointer mr-8 h-8 w-8 bg-purple-100 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                            {
                                                this.renders.keywords().filter((item) => {
                                                    return item.selected
                                                }).length === this.renders.keywords().length &&
                                                <FeatherIcon className={'stroke-current'} size={14}
                                                             icon="check"/>
                                            }
                                        </div>
                                        {
                                            this.renders.keywords().filter((item) => {
                                                return item.selected
                                            }).length > 0 &&
                                            <button onClick={() => {
                                                this.state.search.negatives = this.state.search.negatives.filter((keyword) => {
                                                    return (!keyword.selected && keyword.campaign_id === this.state.campaign.id) || keyword.campaign_id !== this.state.campaign.id;
                                                });
                                                this.setState({
                                                    search: this.state.search
                                                }, () => {
                                                    this.functions.save('search');
                                                });
                                            }} className={"bg-red-200 w-24 whitespace-no-wrap text-red-500 flex flex-row items-center flex justify-center items-center px-3 py-2 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white"}>
                                                <FeatherIcon className="stroke-current mr-2" size={15} icon="trash"/>
                                                <span>Remove</span>
                                            </button>
                                        }
                                    </div>
                                </th>
                                {
                                    false &&
                                    <th onClick={() => {
                                        this.setState({
                                            sort: 'index',
                                            order: this.state.order === 'asc' ? 'desc' : 'asc'
                                        }, () => {
                                            this.props.onEditTable(this.state.sort, this.state.order, this.state.filter);
                                        });
                                    }} className={"cursor-pointer transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'index' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                        <div className="flex flex-row items-center justify-start">
                                            Index{<div className="sorting-arrows ml-2"></div>}
                                        </div>
                                    </th>
                                }
                                <th onClick={() => {
                                    this.setState({
                                        sort: 'keyword',
                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                    }, () => {
                                        this.props.onEditTable(this.state.sort, this.state.order, this.state.filter);
                                    });
                                }} className={"cursor-pointer transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'keyword' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center justify-start">
                                        Keyword{<div className="sorting-arrows ml-2"></div>}
                                    </div>
                                </th>
                                <th onClick={() => {
                                    this.setState({
                                        sort: 'type',
                                        order: this.state.order === 'asc' ? 'desc' : 'asc'
                                    }, () => {
                                        this.props.onEditTable(this.state.sort, this.state.order, this.state.filter);
                                    });
                                }} className={"cursor-pointer transition-all duration-200 px-6 py-3 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (this.state.sort === 'type' ? 'text-gray-700 ' + (this.state.order === 'asc' ? 'sort-asc' : 'sort-desc') : 'text-gray-500')}>
                                    <div className="flex flex-row items-center">
                                        Type{<div className="sorting-arrows ml-2"></div>}
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {this.renders.keywords().sort((a, b) => {
                                if (this.state.sort) {
                                    var nameA = null;
                                    var nameB = null;
                                    try {
                                        nameA = a[this.state.sort].toLowerCase();
                                    } catch (e) {
                                        nameA = a[this.state.sort];
                                    }
                                    try {
                                        nameB = b[this.state.sort].toLowerCase();
                                    } catch (e) {
                                        nameB = b[this.state.sort];
                                    }
                                    if (this.state.order === 'desc') {
                                        if (nameA > nameB)
                                            return -1;
                                        if (nameA < nameB)
                                            return 1;
                                        return 0
                                    } else {
                                        if (nameA < nameB)
                                            return -1;
                                        if (nameA > nameB)
                                            return 1;
                                        return 0
                                    }
                                } else {
                                    return 1
                                }
                            }).map((item, i) => {
                                return (
                                    <Fragment key={i}>
                                        <tr>
                                            <td className="px-6 py-4 whitespace-no-wrap">
                                                <div className="flex flex-row justify-start relative items-center">
                                                    <div onClick={() => {
                                                        item.selected = ! item.selected;
                                                        this.setState({
                                                            search: this.state.search
                                                        }, () => {
                                                            this.functions.save('search');
                                                        });
                                                    }} className={(item.selected?"border-purple-500":"border-purple-100")+ " cursor-pointer mr-8 h-8 w-8 bg-purple-100 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                        {
                                                            item.selected &&
                                                            <FeatherIcon className={'stroke-current'} size={14}
                                                                         icon="check"/>
                                                        }
                                                    </div>
                                                    <div className="flex flex-row justify-start relative items-center">
                                                        <button onClick={() => {
                                                            if(this.renders.keywords().filter((item) => {
                                                                return item.selected
                                                            }).length < 2){
                                                                this.state.search.negatives = this.state.search.negatives.filter((keyword) => {
                                                                    return keyword.id !== item.id;
                                                                });
                                                                this.setState({
                                                                    search: this.state.search
                                                                }, () => {
                                                                    this.functions.save('search');
                                                                });
                                                            }
                                                        }} className={(this.renders.keywords().filter((item) => {
                                                            return item.selected
                                                        }).length > 1?"opacity-0 cursor-default":"") + "bg-red-200 w-24 whitespace-no-wrap text-red-500 flex flex-row items-center flex justify-center items-center px-3 py-2 rounded-full font-bold text-xs transition-all duration-200 hover:bg-red-500 hover:bg-opacity-100 hover:text-white"}>
                                                            <FeatherIcon className="stroke-current mr-2" size={15} icon="trash"/>
                                                            <span>Remove</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                            {
                                                false &&
                                                <td className="px-6 py-4 whitespace-no-wrap">
                                                    <div className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'index'?'font-bold':'')}>
                                                        {item.index}
                                                    </div>
                                                </td>
                                            }
                                            <td className="px-6 py-4 whitespace-no-wrap">
                                                <div className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'keyword'?'font-bold':'')}>
                                                    {item.name}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-no-wrap">
                                                <div className={"text-sm leading-5 text-gray-900 truncate " + (this.state.sort === 'type'?'font-bold':'')}>
                                                    {
                                                        item.exact &&
                                                        <div
                                                            className={`px-3 py-1 inline-flex items-center text-xs font-bold rounded-full leading-tight capitalize ${item.exact ? 'bg-orange-100 text-orange-500' : ''}`}>
                                                            Exact
                                                        </div>
                                                    }
                                                    {
                                                        item.phrase &&
                                                        <div
                                                            className={`px-3 py-1 inline-flex items-center text-xs font-bold rounded-full leading-tight capitalize ${item.phrase ? 'bg-purple-100 text-purple-500' : ''}`}>
                                                            Phrase
                                                        </div>
                                                    }
                                                    {
                                                        item.broad &&
                                                        <div
                                                            className={`px-3 py-1 inline-flex items-center text-xs font-bold rounded-full leading-tight capitalize ${item.phrase ? 'bg-green-100 text-green-500' : ''}`}>
                                                            Broad
                                                        </div>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </Fragment>
                                )
                            })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdvertisingKeywordsNegativeList;
