class adFacebook {

    static validate(ad) {
        let error = {
            name: false,
            headline: false,
            body: false,
            page: false,
            website: false,
            slides: false,
            cta: false
        };

        if (ad) {
            if (!ad.name || ad.name === "") {
                error.name = true;
            }
            if (!ad.page || ad.page.id === 0) {
                error.page = true;
            }
            if (ad.type && ad.type !== "merge" && ad.type !== "posts" && ad.type !== "catalog" && ad.type !== "carousel") {
                if (!ad.title || ad.title === "") {
                    error.headline = true;
                }
            }
            if (ad.type && ad.type !== "merge") {
                if (!ad.body || ad.body === "") {
                    error.body = true;
                }
                if (!ad.link || ad.link === "" || !ad.link.includes("https://")) {
                    // if (ad.type !== "posts") {
                    //     error.website = true;
                    // } else if (ad.ad_type.value === "catalog_ad" && ad.collection_ad) {
                    //     error.website = false
                    // }
                    error.website = true;
                    if (ad.ad_type.value === "catalog_ad" && ad.collection_ad) {
                        error.website = false
                    }
                    if (ad.selected_lead && ad.selected_lead.id !== 0) {
                        error.website = false
                    }
                }
                if (!ad.cta || ad.cta.id === 0) {
                    if (ad.ad_type.value === "catalog_ad" && ad.collection_ad) {
                        error.cta = false
                    } else {
                        error.cta = true;
                    }
                }
            } else if (ad.type && ad.type === "merge") {
                if (!ad.links || !Array.isArray(ad.links) || ad.links[0].value === "" || !ad.links[0].value.includes("https://")) {
                    error.website = true;
                }
                if (!ad.bodies || !Array.isArray(ad.bodies) || ad.bodies[0].value === "") {
                    error.body = true;
                }
                if (!ad.headlines || !Array.isArray(ad.headlines) || ad.headlines[0].value === "") {
                    error.headline = true;
                }
                if (!ad.call_to_actions || ad.call_to_actions[0].value.id === 0) {
                    error.cta = true;
                }

            }

            if (ad.type && ad.type === "carousel") {
                if (!ad.slides || ad.slides.length < 1 || ad.slides.map((item) => { if (!item.link || !item.link.includes("https://")) { return true } else return false }).filter((item) => { return item }).length > 0) {
                    error.slides = true;
                }
            }

        }

        //carousel need website for each image or video
        // console.log("id:", ad.id, " error", error,);
        // console.log("AD", ad);

        return error;
    }
}
export { adFacebook }