import React, {Component} from 'react';
import FeatherIcon from 'feather-icons-react';
import AgencyUsers from '../agencyusers';

class SlideOver extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    };

    componentWillMount() {}

    componentDidMount() {}

    functions = {};

    renders = {};

    render(){
        return (
            <div className="slideover--wrapper fixed inset-0 overflow-hidden w-screen h-screen" style={!this.props.closed ? {zIndex:'9999'} : {zIndex: '-1'}}>
                <div className="absolute inset-0 overflow-hidden">
                    <div className={`absolute inset-0 bg-gray-900 bg-opacity-50 transition-opacity ease-in-out duration-500 ${this.props.closed ? 'opacity-0': 'opacity-100'}`}></div>
                    <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
                        <div className={`relative w-screen ${this.props.maxWidth && this.props.maxWidth ? this.props.maxWidth : 'max-w-md'} transform transition ease-in-out duration-500 sm:duration-700 ${this.props.closed ? 'translate-x-full': 'translate-x-0'}`} ref={this.props.slideOver && this.props.slideOver}>
                            <div className="absolute right-100 md:pr-4 md:pt-4 flex">
                                <button aria-label="Close panel" className="bg-red-500 text-white font-bold text-xs flex items-center rounded-full py-2 px-4 hover:bg-red-700 transition ease-in-out duration-150"
                                    onClick={this.props.onClick}
                                    >
                                    <FeatherIcon className="stroke-current mr-1" size={16} icon="x"/> <span>Close</span>
                                </button>
                            </div>
                            <div className={`h-full flex flex-col space-y-6 py-6 bg-white shadow-xl overflow-y-scroll ${this.props.slideOver && this.props.rounded ? this.props.rounded : ''}`}>
                                <header className="px-4 sm:px-6">
                                    <h2 className="text-lg leading-7 font-medium text-gray-900">
                                        {this.props.headline}
                                    </h2>
                                </header>
                                <div className="relative flex-1 px-4 sm:px-6">
                                    {this.props.content}
                                    {this.props.agencyusers && 
                                        <AgencyUsers
                                        item={this.props.agencyusers}
                                           />
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default SlideOver;