import React, {Component} from 'react';
import './style.css';
import FeatherIcon from 'feather-icons-react';
import cx from "classnames";
import {SlideDown} from 'react-slidedown'
import {dataRegister} from "../../services/dataRegister";
import {
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    PinterestIcon,
    PinterestShareButton, TumblrIcon, TumblrShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css'
import {BeatLoader} from "react-spinners";
import Input from "../../components/input";
import TextArea from "../text-area";
import Dropdown from "../../components/dropdown";
import IconFacebook from "../icons/facebook";
import IconInstagram from "../icons/instagram";
import Datepicker from "../datepicker";
import CampaignStatus from "../campaign-status";
import IconGoogle from "../icons/google";
import ReactTooltip from 'react-tooltip'
import {calls} from "./calls";
import CampaignOverview from "../campaign-overview";
import CampaignDonuts from "../campaign-donuts";
import CampaignEdit from "../campaign-edit";
import {campaignRegister} from "../../services/campaignRegister";
var moment = require('moment');

class Campaign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            item: this.props.item,
            open: false,
            data: null,
            loading: true
        };
    };

    componentWillMount() {}

    componentDidMount() {}

    init = {};

    functions = {
        open: () => {
            this.props.history.push('/user/campaigns/' + this.state.item.id);
        },
        campaign: () => {
            this.setState({
                loading: true
            }, () => {
                if(!this.state.data){
                    calls.getCampaign({internal: this.state.item.id, levels: {campaign: true, adset: true, ad: true}}).then((response) => {
                        this.setState({
                            data: response.data,
                            loading: false
                        })
                    }, (error) => {
                        this.setState({
                            error: true,
                            loading: false
                        })
                    });
                }else{
                    this.setState({
                        loading: false
                    })
                }
            })
        }
    };

    renders = {
        created: (date) => {
            try {
                return moment(date).format('DD.MM.YYYY');
            }catch (e) {}
        },
        date: (date) => {
            try {
                return moment(date).format('DD.MM.YYYY - LT');
            }catch (e) {}
        },
        channel: (channel) => {
            try{
                return this.state.item.channels[channel]
            }catch (e) {}
        },
        status: () => {
            try{
                return this.state.item.status
            }catch (e) {}
        }
    };

    render() {
        return (
            <div className="bg-white mb-2 rounded shadow-lg">
                <div className="Site-InnerContainerInner">
                    <div className={cx("Site-InnerContainerInnerTop")}>
                        {
                            false &&
                            <div className="Campaign-InnerContainerInnerTopTitle Campaign-InnerContainerInnerTopTitleId">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    ID
                                </div>
                                <div className="Site-InnerContainerInnerTopTitleValue">
                                    #{this.state.item.alternative_id}
                                </div>
                            </div>
                        }
                        <div className="Campaign-InnerContainerInnerTopTitle Campaign-InnerContainerInnerTopTitleName">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Name
                            </div>
                            <div data-tip={this.state.item.name} className="Site-InnerContainerInnerTopTitleValue">
                                {this.state.item.name}
                                <ReactTooltip />
                            </div>
                        </div>
                        <div className="Campaign-InnerContainerInnerTopTitle Campaign-InnerContainerInnerTopTitleClient">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Client
                            </div>
                            <div className="Site-InnerContainerInnerTopTitleValue">
                                {this.state.item.client}
                            </div>
                        </div>
                        <div className="Campaign-InnerContainerInnerTopTitle Campaign-InnerContainerInnerTopTitleDate">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Start
                            </div>
                            <div className="Site-InnerContainerInnerTopTitleValue">
                                {this.renders.date(this.state.item.startDate)}
                            </div>
                        </div>
                        <div className="Campaign-InnerContainerInnerTopTitle Campaign-InnerContainerInnerTopTitleDate">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                End
                            </div>
                            <div className="Site-InnerContainerInnerTopTitleValue">
                                {this.renders.date(this.state.item.endDate)}
                            </div>
                        </div>
                        <div className="Campaign-InnerContainerInnerTopTitle Campaign-InnerContainerInnerTopTitleChannels">
                            <div className="Client-InnerContainerInnerTopTitleText">
                                Channels
                            </div>
                            <div className="Site-InnerContainerInnerTopTitleValueIcons">
                                {
                                    this.renders.channel('facebook') &&
                                    <IconFacebook noMargin={true}/>
                                }
                                {
                                    this.renders.channel('instagram') &&
                                    <IconInstagram/>
                                }
                                {
                                    this.renders.channel('google') &&
                                    <IconGoogle/>
                                }
                            </div>
                        </div>
                        {
                            this.state.type === 'all' &&
                            <div className="Campaign-InnerContainerInnerTopTitle Campaign-InnerContainerInnerTopTitleDate">
                                <div className="Client-InnerContainerInnerTopTitleText">
                                    Status
                                </div>
                                <div className="Site-InnerContainerInnerTopTitleValue">
                                    {this.renders.status()}
                                </div>
                            </div>
                        }
                        {
                            this.renders.status() !== 'pending' &&
                            <div style={{display: 'flex', flex: '1', justifyContent: 'flex-end', flexDirection: 'row'}}>
                                {
                                    this.renders.status() !== 'error' &&
                                    <div style={{width: '55px', height: '55px', marginRight: '10px'}}>
                                        <div onClick={() => {
                                            this.setState({
                                                open: !this.state.open
                                            }, () => {
                                                if(this.state.open){
                                                    this.functions.campaign();
                                                }
                                            })
                                        }} className="Button ButtonFull ButtonNeutral">
                                            {
                                                this.state.open &&
                                                <FeatherIcon color={"#333333"} size={25} icon="chevron-up"/>
                                            }
                                            {
                                                !this.state.open &&
                                                <FeatherIcon color={"#333333"} size={25} icon="chevron-down"/>
                                            }
                                        </div>
                                    </div>
                                }
                                <div style={{width: '55px', height: '55px'}}>
                                    <div onClick={() => {
                                        this.functions.open();
                                    }} className="Button ButtonFull ButtonNeutral">
                                        <FeatherIcon color={"#333333"} size={20} icon="external-link"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="p-4">
                            <CampaignDonuts
                                data={this.state.data?this.state.data:{}}
                                loading={this.state.loading}
                                showchannels={true}
                                noShadow={true}
                            />
                        </div>
                        <div style={{position: 'relative'}}>
                            <CampaignOverview
                                data={this.state.data?this.state.data:{}}
                                loading={this.state.loading}
                            />
                        </div>
                    </SlideDown>
                </div>
            </div>
        )
    }
}

export default Campaign;
