import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, PlusCircleIcon, PlusIcon, FolderIcon, PaperClipIcon, ArrowLeftIcon, XIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { orderRegister } from '../services/orderRegister';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SuccessModal from '../moduleFiles/successModal';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import InputTimepickerTailwind from '../moduleFiles/inputTimepickerTailwind';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import { isNumber, round } from '@turf/turf';
import { userRegister } from '../services/userRegister';

class CreateOrderInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            order: {},
            name: "",
            client: { id: 0 },
            documents: [],
            loading: false,
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: null,
            public: true,
            budget_split: false,
            total_budget: 0,
            google_budget: 0,
            bing_budget: 0,
            user: {},
            throttling: {},
            agents: [],
            agent_options: [],
            loading_agents_search: "",
            loading_agents_pagination: 0,
            loading_agents: false,
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            search: "",
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.form.get();
        this.functions.getUser();
    }

    form = {
        set: async () => {
            orderRegister.set("name", this.state.name);
            orderRegister.set("agents", this.state.agents);
            orderRegister.set("startDate", this.state.startDate);
            orderRegister.set("endDate", this.state.endDate);
            orderRegister.set("documents", this.state.documents);
        },
        get: async () => {
            if (orderRegister.get()) {
                await this.promisedSetState({
                    client: orderRegister.get()["client"] ? orderRegister.get()["client"] : this.state.client,
                    agents: Array.isArray(orderRegister.get()["agents"]) ? orderRegister.get()["agents"] : this.state.agents,
                    name: orderRegister.get()["name"] ? orderRegister.get()["name"] : this.state.name,
                    startDate: orderRegister.get()["startDate"] ? orderRegister.get()["startDate"] : this.state.startDate,
                    endDate: orderRegister.get()["endDate"] ? orderRegister.get()["endDate"] : this.state.endDate,
                    documents: orderRegister.get()["documents"] ? orderRegister.get()["documents"] : this.state.documents
                });
            }
            if (this.state.client.id == 0) {
                this.props.history.push("/v2/orders/new");
            }
            if (this.state.client.budgetSplit) {
                await this.promisedSetState({
                    budget_split: this.state.client.budgetSplit.value,
                    total_budget: this.state.client.budgetSplit.budget,
                    google_budget: this.state.client.budgetSplit.google_budget,
                    bing_budget: this.state.client.budgetSplit.bing_budget,
                });
            }
        }
    };

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
        },
        createOrder: async () => {
            await this.promisedSetState({ loading: true }, true);
            let order = {
                name: this.state.name,
                crmId: this.state.crmId,
                crmRef: this.state.crmRef,
                crmBudget: this.state.total_budget,
                ...(this.state.client.id && { client: this.state.client.id }),
                ...(this.state.startDate && { startDate: this.state.startDate }),
                endDate: this.state.endDate,
                documents: this.state.documents,
                agents: this.state.agents,
                //public: this.state.public,
                //client_status: { id: 3, name: "Pending", value: "pending", step: 1 },
                ...(this.state.client.budget_split && { budget_split: { value: this.state.budget_split, budget: this.state.total_budget, bing_budget: this.state.bing_budget, google_budget: this.state.google_budget } }),
            };
            try {
                let response = await this.calls.createOrder(order);
                orderRegister.remove();
                await this.promisedSetState({ order: response.data });
                await this.promisedSetState({ loading: false });
                this.props.history.push("/v2/orders/" + this.state.order.id);
            } catch (error) {
                await this.promisedSetState({ loading: false, error: error.body.message });
            }
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agent_options: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agent_options = this.state.agent_options.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agent_options: this.state.agent_options,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        },
        onSelectFile: async (e) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i] && files[i].name && files[i].size) {
                        this.functions.uploadDocument(files[i]);
                    }
                }
            }
            const randomString = Math.random().toString(36);
            this.promisedSetState({
                theInputKeyOne: randomString + 1
            });
        },
        uploadDocument: async (file) => {
            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name
            };
            this.state.documents.push(files_object);
            await this.promisedSetState({ documents: this.state.documents });
            try {
                const formData = new FormData();
                formData.append('file', file);
                let response = await this.calls.uploadDocument(formData);
                this.state.documents = this.state.documents.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item = { ...item, ...response.data };
                    }
                    return item;
                });
                await this.promisedSetState({ documents: this.state.documents });
            } catch (error) {
                this.state.documents = this.state.documents.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                    }
                    return item;
                });
                await this.promisedSetState({ documents: this.state.documents });
            }
        }
    };

    calls = {
        createOrder: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, false);
            let url = apiRegister.url.api + "/v3/adcredo/createOrder";
            return apiRegister.call(options, url);
        },
        uploadDocument: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadFile?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative h-full">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === "cancel") {
                                orderRegister.remove();
                                if (this.state.order && this.state.order.id) {
                                    this.props.history.push("/v2/orders/" + this.state.order.id + "/drafts");
                                } else {
                                    this.props.history.push("/v2/orders/active");
                                }
                            }
                        }}
                        buttons={[
                            { text: "Cancel", icon: XIcon, value: "cancel", loading: false, show: !this.state.loading, red: true }
                        ]}
                        hideUserDropdown={true}
                        history={this.props.history}
                        breadcrumb={"Set order information"}
                        buttonTypes={{}}
                        showClient={true}
                        lockClient={true}
                        client={{ name: this.state.client.name, image: this.state.client.logo }}
                    />
                </div>

                <div className="p-4">
                    <div className="shadow bg-white rounded-lg flex flex-col">

                        {/*CONTENT*/}
                        <div className="flex flex-1 p-6 ">
                            <div className="w-full">
                                <div className="grid-cols-12 gap-4 grid">

                                    <div className={"col-span-4"}>
                                        <InputTailwind
                                            label={"Name"}
                                            error={this.state.name === "" && this.state.error}
                                            errorMessage={"Name is not valid"}
                                            value={this.state.name}
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    name: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={"col-span-4"}>
                                        <DropdownTailwind
                                            label={"Client"}
                                            selected={{ name: this.state.client.name, image: this.state.client.logo }}
                                            locked={true}
                                            options={[]}
                                            onChange={async (value) => {

                                            }}
                                        />
                                    </div>
                                    <div className={"col-span-2"}>
                                        <InputTailwind
                                            label={"Crm ID (optional)"}
                                            value={this.state.crmId}
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    crmId: value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={"col-span-2"}>
                                        <InputTailwind
                                            label={"Crm Ref (optional)"}
                                            value={this.state.crmRef}
                                            onChange={(value) => {
                                                this.promisedSetState({
                                                    crmRef: value
                                                })
                                            }}
                                        />
                                    </div>

                                    {
                                        <div className='col-span-12'>
                                            <div className="w-full">
                                                <div className="grid grid-cols-6 gap-6">
                                                    <div className="col-span-6">
                                                        <DropdownTailwind
                                                            label={"Agents"}
                                                            small={false}
                                                            skipInternalSearch={true}
                                                            loader={this.state.loading_agents}
                                                            loadingPagination={this.state.loading_agents_pagination}
                                                            loadingSearch={this.state.loading_agents_search}
                                                            total={this.state.total}
                                                            searchInput={true}
                                                            placeholder={"..."}
                                                            pagination={this.state.total > (this.state.page * this.state.limit)}
                                                            selected={{ id: 0, name: "Select agent" }}
                                                            options={this.state.agent_options}
                                                            onChange={async (value) => {
                                                                this.state.agents.unshift({
                                                                    email: value.name,
                                                                    id: value.id
                                                                });
                                                                await this.promisedSetState({
                                                                    client: this.state.client
                                                                });
                                                            }}
                                                            onPagination={async () => {
                                                                if (!this.state.loading_agents_pagination) {
                                                                    await this.promisedSetState({
                                                                        page: this.state.page + 1
                                                                    });
                                                                    this.functions.getAgents(false, true, false);
                                                                }
                                                            }}
                                                            onSearch={async (value) => {
                                                                if (value && value === "") {
                                                                    await this.promisedSetState({
                                                                        search: "",
                                                                        page: 1
                                                                    });
                                                                    this.functions.getAgents(false, false, true);
                                                                } else {
                                                                    if (this.state.throttling.current) {
                                                                        //SKIP
                                                                    } else {
                                                                        if (value && value === "") {
                                                                            await this.promisedSetState({
                                                                                search: value,
                                                                                page: 1
                                                                            });
                                                                            this.functions.getAgents(false, false, true);
                                                                        } else {
                                                                            this.state.throttling.current = true;
                                                                            await this.promisedSetState({
                                                                                search: value,
                                                                                throttling: this.state.throttling
                                                                            });
                                                                            setTimeout(async () => {
                                                                                this.state.throttling.current = false;
                                                                                await this.promisedSetState({
                                                                                    throttling: this.state.throttling
                                                                                });
                                                                                await this.promisedSetState({
                                                                                    page: 1
                                                                                });
                                                                                this.functions.getAgents(false, false, true);
                                                                            }, 300);
                                                                        }
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        Array.isArray(this.state.agents) && this.state.agents.length > 0 &&
                                                        <div className="col-span-6 -mt-4">
                                                            {
                                                                this.state.agents.map((item, index) => {
                                                                    return (
                                                                        <Fragment>
                                                                            <div className={"bg-indigo-100 text-indigo-700 mt-2 flex flex-1 rounded-md items-center p-3 text-sm font-medium "}>
                                                                                <div className="flex flex-1">{item.email}</div>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        this.state.agents = this.state.agents.filter((agent) => {
                                                                                            return agent.id !== item.id;
                                                                                        });
                                                                                        this.setState({
                                                                                            client: this.state.client
                                                                                        });
                                                                                    }}
                                                                                    type="button"
                                                                                    className={"focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 flex-shrink-0 ml-1 h-8 w-8 rounded-md inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                                                                >
                                                                                    <span className="sr-only">Remove agent</span>
                                                                                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                                                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        false &&
                                        <div className='col-span-3'>
                                            <div className='flex flex-col w-full'>
                                                <div className={`-mb-4 text-xs font-medium text-gray-700 flex flex-1`}>Public</div>
                                                <div className='flex items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 text-sm ml-5">
                                                        {this.state.public ? "Enabled" : "Disabled"}
                                                    </div>
                                                    <div className="relative overflow-hidden mr-5">
                                                        <SwitchTailwind
                                                            value={this.state.public ? true : false}
                                                            onSwitch={async () => {
                                                                await this.promisedSetState({
                                                                    public: !this.state.public
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className={"col-span-12 md:col-span-6 grid grid-cols-6 col-gap-2"}>
                                        <div className="col-span-3">
                                            <InputDatepickerTailwind
                                                label={"Start date"}
                                                value={moment(this.state.startDate).format("YYYY-MM-DD")}
                                                onChange={async (value) => {
                                                    let time = moment(this.state.startDate).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    await this.promisedSetState({
                                                        startDate: moment(new_date)
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <InputTimepickerTailwind
                                                label={"Start time"}
                                                value={moment(this.state.startDate).format("HH:mm")}
                                                onChange={async (value) => {
                                                    let date = moment(this.state.startDate).format("YYYY-MM-DD");
                                                    let new_date = date + " " + value;
                                                    await this.promisedSetState({
                                                        startDate: moment(new_date)
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className={"col-span-12 md:col-span-6 grid grid-cols-6 col-gap-2"}>
                                        <div className={(this.state.endDate ? "col-span-3" : "col-span-6")}>
                                            <InputDatepickerTailwind
                                                label={"End date"}
                                                disabled={!this.state.endDate}
                                                labelRight={this.state.endDate ? "Disable" : "Enable"}
                                                placeholder={!this.state.endDate ? "No end date" : ""}
                                                value={moment(this.state.endDate).format("YYYY-MM-DD")}
                                                onChange={async (value) => {
                                                    let time = moment(this.state.endDate).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    await this.promisedSetState({
                                                        endDate: moment(new_date)
                                                    })
                                                }}
                                                onLabelRightClick={async (value) => {
                                                    if (this.state.endDate) {
                                                        this.state.endDate = null;
                                                    } else {
                                                        let new_date = moment(this.state.startDate).add(1, 'days');
                                                        this.state.endDate = new_date;
                                                    }
                                                    await this.promisedSetState({
                                                        endDate: this.state.endDate
                                                    });
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.endDate &&
                                            <div className="col-span-3">
                                                <InputTimepickerTailwind
                                                    label={"End time"}
                                                    placeholder={!this.state.endDate ? "No end time" : ""}
                                                    disabled={!this.state.endDate}
                                                    value={moment(this.state.endDate).format("HH:mm")}
                                                    onChange={async (value) => {
                                                        let date = moment(this.state.endDate).format("YYYY-MM-DD");
                                                        let new_date = date + " " + value;
                                                        await this.promisedSetState({
                                                            endDate: moment(new_date)
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.client.budget_split &&
                                        <div className={"col-span-12"}>
                                            <div className='flex transform transition-all duration-500 ease-in-out'>
                                                <div className='flex flex-col flex-1'>
                                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Budget split</div>
                                                    <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                        <div className="flex flex-1 ml-5">
                                                            {this.state.budget_split ? "Active" : "Disabled"}
                                                        </div>
                                                        <div className="relative overflow-hidden mr-5">
                                                            <SwitchTailwind
                                                                value={this.state.budget_split}
                                                                onSwitch={async () => {
                                                                    this.state.budget_split = !this.state.budget_split;
                                                                    await this.promisedSetState({
                                                                        budget_split: this.state.budget_split
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'flex  transform transition-all duration-500 ease-in-out ' + (this.state.budget_split ? " flex-1 px-2" : " w-0 overflow-hidden")}>
                                                    <InputTailwind
                                                        full={true}
                                                        label={"Monthly budget"}
                                                        error={false}
                                                        value={+this.state.total_budget}
                                                        onChange={(value) => {
                                                            if (isNumber(value) && value >= 0) {
                                                                this.promisedSetState({
                                                                    total_budget: +value
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className={'flex transform transition-all duration-500 ease-in-out' + (this.state.budget_split ? " flex-1 px-2" : " w-0 overflow-hidden")}>
                                                    <InputTailwind
                                                        channel={"google"}
                                                        full={true}
                                                        label={"Google budget"}
                                                        value={+this.state.google_budget}
                                                        leftSection={true}
                                                        leftSectionLabel={"%"}
                                                        onChange={(value) => {
                                                            if (+value >= 0 && +value <= 100) {
                                                                //if (+value + +this.state.bing_budget > 100) {
                                                                this.state.bing_budget = 100 - +value;
                                                                //}
                                                                this.promisedSetState({
                                                                    google_budget: +value,
                                                                    bing_budget: this.state.bing_budget
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className={'flex transform transition-all duration-500 ease-in-out' + (this.state.budget_split ? " flex-1 px-2" : " w-0 overflow-hidden")}>
                                                    <InputTailwind
                                                        channel={"google"}
                                                        full={true}
                                                        label={"Monthly budget"}
                                                        value={round(+this.state.total_budget * (+this.state.google_budget / 100))}
                                                        locked={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className={'flex  transform transition-all duration-500 ease-in-out' + (this.state.budget_split ? " flex-1 pl-2" : " w-0 overflow-hidden")}>
                                                    <InputTailwind
                                                        channel={"bing"}
                                                        full={true}
                                                        label={"Bing budget"}
                                                        leftSection={true}
                                                        leftSectionLabel={"%"}
                                                        value={+this.state.bing_budget}
                                                        onChange={(value) => {
                                                            if (+value >= 0 && +value <= 100) {
                                                                //if (+value + +this.state.google_budget > 100) {
                                                                this.state.google_budget = 100 - +value;
                                                                //}
                                                                this.promisedSetState({
                                                                    bing_budget: +value,
                                                                    google_budget: this.state.google_budget
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className={'flex transform transition-all duration-500 ease-in-out' + (this.state.budget_split ? " flex-1 px-2" : " w-0 overflow-hidden")}>
                                                    <InputTailwind
                                                        channel={"bing"}
                                                        full={true}
                                                        label={"Monthly budget"}
                                                        value={round(+this.state.total_budget * (+this.state.bing_budget / 100))}
                                                        locked={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className={"col-span-12"}>
                                        <div className="mb-1 text-xs font-medium text-gray-700">
                                            Upload documents
                                        </div>
                                        <div key={this.state.theInputKeyOne || ''} className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input">
                                            {
                                                !this.state.success &&
                                                <input
                                                    onChange={(e) => this.functions.onSelectFile(e)}
                                                    type="file"
                                                    multiple
                                                    className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0 z-50"
                                                />
                                            }
                                            <div className="space-y-1 text-center">
                                                <svg
                                                    className="mx-auto h-12 w-12 text-gray-400"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    viewBox="0 0 48 48"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <div className="flex text-sm text-gray-600">
                                                    <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a document</span>
                                                    <p className="pl-1">or drag and drop</p>
                                                </div>
                                                <p className="text-xs text-gray-500">PDF, TXT, ODT, DOC, PPT up to 100MB</p>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        this.state.documents.length > 0 &&
                                        <div className={"col-span-12"}>
                                            <div className="mb-1 text-xs font-medium text-gray-700">
                                                Documents
                                            </div>
                                            {
                                                this.state.documents.map((item, index) => {
                                                    let last = (this.state.documents.length - 1) === index;
                                                    return (
                                                        <div className={(last ? "" : "border-b") + " flex flex-1 flex-row py-2"}>
                                                            <div className="relative">
                                                                <PaperClipIcon className="w-5 h-5 text-gray-400" />
                                                                {
                                                                    item.loading &&
                                                                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full bg-white bg-opacity-50 absolute top-0">
                                                                        <div style={{ borderTopColor: "transparent" }} class="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="flex flex-1 text-sm ml-4 truncate overflow-hidden font-medium flex-row">
                                                                {item.name}
                                                                {
                                                                    item.error &&
                                                                    <div className="text-red-400 ml-4">
                                                                        Error, something went wrong
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div onClick={() => {
                                                                this.state.documents = this.state.documents.filter((inner_item, inner_index) => {
                                                                    return inner_index !== index;
                                                                });
                                                                this.setState({
                                                                    documents: this.state.documents
                                                                });
                                                            }} className="text-sm text-red-400 hover:text-red-600 font-medium cursor-pointer">Remove</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-4 flex flex-row">
                        <div className="flex flex-1">
                            <button
                                type="button"
                                onClick={async () => {
                                    if (!this.state.loading) {
                                        this.form.set();
                                        this.props.history.push("/v2/orders/new");
                                    }
                                }}
                                className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none shadow focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                Back
                            </button>
                        </div>
                        <div className="flex flex-1"></div>
                        <div className="flex justify-center items-center">
                            <div className="">
                                <button
                                    onClick={() => {
                                        if (!this.state.loading && this.state.name !== "" && this.state.client.id) {
                                            this.functions.createOrder();
                                        }
                                    }}
                                    className={(this.state.name !== "" && this.state.client.id ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                >
                                    <span>Create order</span>
                                    <PlusIcon className="ml-2 h-5 w-5" />
                                    {
                                        this.state.loading &&
                                        <div className="w-full h-full absolute inset-0 bg-purple-600 flex justify-center items-center z-20 rounded-lg">
                                            <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default CreateOrderInformation;